import { FC, memo, MutableRefObject, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  TableViewType,
} from "../../../../../graphql/operations";
import { getRows, Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../constants";
import {
  mapSensorProfileData,
  columnVisibilityModel,
  ProfilesData,
  batteryTableKeyList,
  batteryTableColumns,
  MappedSensorProfile,
} from "../profileUtils";

export interface BatteriesProfileTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  profilesData: ProfilesData;
}

const BatteriesProfileTable: FC<BatteriesProfileTableProps> = ({
  tableType,
  onRowClick,
  apiRef,
  profilesData,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const [tableRows, setTableRows] = useState<SensorProfileResult[]>([]);

  const fileName = useExportedFileName("Profiles");

  useMemo(() => {
    const mappedProfiles = mapSensorProfileData(profilesData.data ?? []);

    setTableRows(
      getRows<MappedSensorProfile[]>(
        mappedProfiles,
        batteryTableColumns,
        "",
        "",
        "_id"
      )
    );
    return mappedProfiles;
  }, [profilesData]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={batteryTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        loading={profilesData.isFetchingProfile}
        error={profilesData.isErrorProfile}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={batteryTableKeyList}
        sortKeys={batteryTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="profiles"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(BatteriesProfileTable);
