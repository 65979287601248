import { useMemo } from "react";
import { Box, Grid, ThemeProvider } from "@mui/material";
import {
  LiteSentryGammaObservationData,
  MergedLiteSentryGammaSensorConfig,
  SensorStatusUppercase,
  useFindAssetByIdQuery,
  useGetMergedProfileForAssetQuery,
} from "../../../../../graphql/operations";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext/AssetsDataContext";
import { useOtherSensorsTabPanelTheme } from "../shared";
import LightCircuits from "./components/LightCircuits";
import LightOutDetectionInformation from "./components/LightOutDetectionInformation";
import {
  LightsChart,
  LightsChartObservationType,
} from "./components/lightsTabCharts/LightsChart";
import { LITE_SENTRY_LIGHT_TYPES } from "./components/lightsTabCharts/helpers";
import {
  LightCircuitMergedDataResult,
  getCircuitsData,
  getLightsSensorsHealthData,
  sortLiteSentryGammaSensorHealthObject,
} from "./utils/utils";

const LightsTabPanel = () => {
  const { selectedAssetId } = useAssetsDataContext();

  const { data: assetData } = useFindAssetByIdQuery(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const selectedAsset = useMemo(() => assetData?.findAssetById, [assetData]);

  const assetObjectId = selectedAsset?._id ?? "";
  const { data: profileData } = useGetMergedProfileForAssetQuery(
    {
      input: { assetId: assetObjectId },
    },
    { enabled: Boolean(assetObjectId) }
  );

  const lightSensorObservations =
    selectedAsset?.sensors?.liteSentryGamma?.data?.observations;
  const lightsSensorData: LiteSentryGammaObservationData[] = (
    lightSensorObservations ?? []
  ).map((observation) => observation ?? {}); // Convert null values to empty objects

  const lightsSensorHealthData = getLightsSensorsHealthData(lightsSensorData);

  // get array of all light secenario statuses, sorted by severity
  const liteSentryGammaScenarios =
    sortLiteSentryGammaSensorHealthObject(lightsSensorData);

  // if the first status is Unknown, there is no valid status inside the array ( in this case we shouldn`t show anything in the lights tab )
  const liteSentryGammaMostCriticalStatus =
    liteSentryGammaScenarios?.[0]?.statusDeviation ??
    SensorStatusUppercase.Unknown;

  const lightsProfileData: MergedLiteSentryGammaSensorConfig =
    profileData?.getMergedProfileForAsset?.sensorProfile?.configuration
      ?.liteSentryGamma ?? {};

  const theme = useOtherSensorsTabPanelTheme();
  const lightsData: LightCircuitMergedDataResult = getCircuitsData(
    lightsSensorData,
    lightsSensorHealthData,
    lightsProfileData
  );

  const hasLightsSensorData =
    lightSensorObservations &&
    liteSentryGammaMostCriticalStatus !== SensorStatusUppercase.Unknown;
  return (
    <ThemeProvider theme={theme}>
      {hasLightsSensorData ? (
        <Box className="w-full p-4" data-testid="light-sensors-tab-panel">
          <Grid container spacing={2} className="h-full w-full ">
            <Grid xl={6} lg={12} xs={12} sm={12} item className="grid">
              <Box
                sx={{ borderRadius: "8px" }}
                className="flex justify-center bg-dashboard_subheader__bg "
              >
                <Grid
                  spacing={0}
                  justify-content="flex-end"
                  container
                  className="text-sm w-full p-4"
                  data-testid="light-circuit-statuses"
                >
                  <LightCircuits data={lightsData} />
                </Grid>
              </Box>
            </Grid>

            <Grid xl={6} lg={12} xs={12} sm={12} item className="sm:grid">
              <Box
                sx={{ borderRadius: "8px" }}
                className="flex justify-center bg-dashboard_subheader__bg "
              >
                <Grid
                  spacing={0}
                  justify-content="flex-end"
                  container
                  className="text-sm w-full p-4"
                  data-testid="light-circuit-status-values"
                >
                  <LightOutDetectionInformation data={lightsData} />
                </Grid>
              </Box>
            </Grid>
            {!!selectedAsset && (
              <>
                <Grid xl={6} lg={12} xs={12} sm={12} item className="grid">
                  <LightsChart
                    asset={selectedAsset}
                    chartTitle="Brake Light Observed Current"
                    yAxisText="Observed Current (mA)"
                    lightSensorType={LITE_SENTRY_LIGHT_TYPES.BrakeLight}
                    observationType={LightsChartObservationType.ObservedCurrent}
                  />

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Left Turn Signal Observed Current"
                      yAxisText="Observed Current (mA)"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.LeftTurnSignal}
                      observationType={
                        LightsChartObservationType.ObservedCurrent
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Right Turn Signal Observed Current"
                      yAxisText="Observed Current (mA)"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.RightTurnSignal}
                      observationType={
                        LightsChartObservationType.ObservedCurrent
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Marker Observed Current"
                      yAxisText="Observed Current (mA)"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.Marker}
                      observationType={
                        LightsChartObservationType.ObservedCurrent
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="License Observed Current"
                      yAxisText="Observed Current (mA)"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.License}
                      observationType={
                        LightsChartObservationType.ObservedCurrent
                      }
                    />
                  </Box>
                </Grid>

                <Grid xl={6} lg={12} xs={12} sm={12} item className="grid">
                  <LightsChart
                    asset={selectedAsset}
                    chartTitle="Brake Light % Deviation"
                    yAxisText="% Deviation"
                    lightSensorType={LITE_SENTRY_LIGHT_TYPES.BrakeLight}
                    observationType={
                      LightsChartObservationType.DeviationPercentage
                    }
                  />

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Left Turn Signal % Deviation"
                      yAxisText="% Deviation"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.LeftTurnSignal}
                      observationType={
                        LightsChartObservationType.DeviationPercentage
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Right Turn Signal % Deviation"
                      yAxisText="% Deviation"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.RightTurnSignal}
                      observationType={
                        LightsChartObservationType.DeviationPercentage
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="Marker % Deviation"
                      yAxisText="% Deviation"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.Marker}
                      observationType={
                        LightsChartObservationType.DeviationPercentage
                      }
                    />
                  </Box>

                  <Box className="pt-4">
                    <LightsChart
                      asset={selectedAsset}
                      chartTitle="License % Deviation"
                      yAxisText="% Deviation"
                      lightSensorType={LITE_SENTRY_LIGHT_TYPES.License}
                      observationType={
                        LightsChartObservationType.DeviationPercentage
                      }
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      ) : (
        <Box
          className="text-sm bg-dashboard_subheader__bg p-6 m-2 text-asset-info-subheader"
          data-testid="other-sensors-tab-panel-no-data"
        >
          No Sensors Available.
        </Box>
      )}
    </ThemeProvider>
  );
};

export default LightsTabPanel;
