import { FC, MutableRefObject } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Typography, Chip } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import {
  BAR_HEIGHT,
  CargoData,
  calculateCargoStatusInLocationsLast30Days,
} from "./utils";

export interface CargoStatusInLocationsProps {
  data: Array<CargoData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const CargoStatusInLocations: FC<CargoStatusInLocationsProps> = ({
  data,
  parentRef,
}) => {
  const {
    emptyCargoDaysInsideGeofence,
    emptyCargoDaysOutsideGeofence,
    loadedCargoDaysInsideGeofence,
    loadedCargoDaysOutsideGeofence,
  } = calculateCargoStatusInLocationsLast30Days(data);

  const legendKeys: string[] = ["Inside Geofence", "Outside Geofence"];
  const legendLabels = ["Inside Geofence", "Outside Geofence"];
  const legendColors = ["var(--primary-blue)", "var(--indigo)"];

  const { width } = useContainerDimensions(parentRef);
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  // layout grid parent dimensions
  const gridItemPaddings = 32;
  const gridItemGapDistance = 16;

  // card layout dimensions
  const labelWidth = 55;
  const margins = 16;
  const paddings = 16 + gridItemPaddings + gridItemGapDistance;
  const barsGap = 8;

  let emptyBarMaxWidth = width - (labelWidth + margins + paddings);
  let loadedBarMaxWidth = width - (labelWidth + margins + paddings);

  // loaded days ( inside and outside geofence )
  const loadedDaysInsideGeofence: number = loadedCargoDaysInsideGeofence;
  const loadedDaysOutsideGeofence: number = loadedCargoDaysOutsideGeofence;

  // empty days ( inside and outside geofence )
  const emptyDaysInsideGeofence: number = emptyCargoDaysInsideGeofence;
  const emptyDaysOutsideGeofence: number = emptyCargoDaysOutsideGeofence;

  // sum of empty and loaded days
  const loadedGeofenceDaysSum =
    loadedDaysInsideGeofence + loadedDaysOutsideGeofence;
  const emptyGeofenceDaysSum =
    emptyDaysInsideGeofence + emptyDaysOutsideGeofence;

  // calculate percentage from possible days for the bars
  const emptyDaysInsideGeofenceInPercentage =
    (emptyDaysInsideGeofence / emptyGeofenceDaysSum) * 100;
  const emptyDaysOutsideGeofenceInPercentage =
    (emptyDaysOutsideGeofence / emptyGeofenceDaysSum) * 100;

  const loadedDaysInsideGeofenceInPercentage =
    (loadedDaysInsideGeofence / loadedGeofenceDaysSum) * 100;

  const loadedDaysOutsideGeofenceInPercentage =
    (loadedDaysOutsideGeofence / loadedGeofenceDaysSum) * 100;

  // align bar width when one of the bars is not visible
  if (loadedDaysInsideGeofence === 0 || loadedDaysOutsideGeofence === 0) {
    loadedBarMaxWidth = loadedBarMaxWidth + barsGap;
  }
  if (emptyDaysInsideGeofence === 0 || emptyDaysOutsideGeofence === 0) {
    emptyBarMaxWidth = emptyBarMaxWidth + barsGap;
  }

  // calculate percentage based on the place that we have for the bars in the container
  const emptyDaysInsideGeofenceBarPercentage =
    (emptyDaysInsideGeofenceInPercentage / 100) * emptyBarMaxWidth;
  const emptyDaysOutsideGeofenceBarPercentage =
    (emptyDaysOutsideGeofenceInPercentage / 100) * emptyBarMaxWidth;

  const loadedDaysInsideGeofenceBarPercentage =
    (loadedDaysInsideGeofenceInPercentage / 100) * loadedBarMaxWidth;
  const loadedDaysOutsideGeofenceBarPercentage =
    (loadedDaysOutsideGeofenceInPercentage / 100) * loadedBarMaxWidth;

  return (
    <Box
      className="text-sm w-full p-4"
      data-testid="last-30-days-loaded-unloaded-in-locations"
    >
      <SensorHeader title="Cargo Sensor">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="battery-widget-label"
      >
        Cargo Status in Locations Last 30 Days
      </Typography>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Empty
        </Typography>
        {emptyDaysOutsideGeofence > 0 && (
          <Box
            data-testid="empty-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${emptyDaysOutsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyDaysOutsideGeofence}
          </Box>
        )}
        {emptyDaysInsideGeofence > 0 && (
          <Box
            data-testid="empty-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${emptyDaysInsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {emptyDaysInsideGeofence}
          </Box>
        )}
      </Box>
      <Box className="!mb-3 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Loaded
        </Typography>
        {loadedDaysOutsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${loadedDaysOutsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedDaysOutsideGeofence}
          </Box>
        )}
        {loadedDaysInsideGeofence > 0 && (
          <Box
            data-testid="loaded-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${loadedDaysInsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {loadedDaysInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="justify gap-2">
        {legendKeys.map((key, index) => (
          <Chip
            key={key}
            label={legendLabels[index]}
            icon={
              <Circle
                sx={{
                  width: "16px",
                  fill: legendColors[index],
                }}
              />
            }
            className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
            sx={{
              color: "var(--mid-charcoal)",
              backgroundColor: "var(--grayscale-feather-gray)",
              "& .MuiChip-label": {
                color: "var(--mid-charcoal)",
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CargoStatusInLocations;
