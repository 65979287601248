import {
  AssetSensorProfileSensorInput,
  SensorProfileConfigType,
  SensorThresholdShort,
  TemperatureInternalThresholds,
  TpmsPressureThresholds,
} from "../../../graphql/operations";

// Default slider values
export const tpmsPressureMinHealthy = 1400;
export const tpmsPressureMaxHealthy = 12400;
export const tpmsPressureDefaultValues = [
  1700, 3400, 5200, 7000, 8600, 10300, 12000,
];
export const tpmsTemperatureDefaultValues = [50, 125, 200];
export const airbagDefaultValues = [3500, 7000, 10500];
export const tpmsImbalanceDefaultValues = [350, 700, 1050];
export const batteryDefaultValues = [3.2, 3.41, 3.61];
export const batteryLiftgateDefaultValues = [5, 9, 13];
export const wheenEndTemperatureDefaultValues = [70, 130, 170];
export const regulatorDefaultValues = [1400, 3500, 5500];
export const airTankMinSpeed = 1;
export const airTankMaxSpeed = 200;
export const airTankPressureDefaultValues = [3000, 5900, 8900];
export const internalTemperatureDefaultValues = [6, 15, 25, 32, 37, 43, 48];
export const atisDefaultValues = [50, 125, 200];
export const liteSentryGammaDefaultValues = [-70, -50, 10];
export const cargoCameraDefaultValues = [25, 50, 75];
export const wheelEndDefaultValues = [80, 141, 191];
export const batteryStep = 0.01;

/****************************************************************************************
 * DATA EXTRACTION UTILS                                                                *
 ***************************************************************************************/

/**
 * Extract the slider pointers values
 * @param thresholds object of SensorThresholdShort objects (4)
 * @returns array of numbers (3)
 */
export const extractRules = (
  thresholds: SensorThresholdShort | undefined | null
) => {
  if (!thresholds) return null;
  return [
    thresholds.healthy?.healthyTo ?? 0,
    thresholds.warning?.warningTo ?? 0,
    thresholds.critical?.criticalFrom ?? 0,
  ];
};

/**
 * Extracts REVERSED SINGLE threshold rules and returns three values .
 *
 * @param thresholds array of SensorThreshold objects (4)
 * @returns array of numbers (3)
 */

export const extractReversedRules = (
  thresholds: SensorThresholdShort | undefined | null
) => {
  if (!thresholds) return null;
  return [
    thresholds.critical?.criticalTo ?? 0,
    thresholds.warning?.warningFrom ?? 0,
    thresholds.healthy?.healthyFrom ?? 0,
  ];
};

const extractTpmsThresholds = (thresholds: TpmsPressureThresholds) => [
  thresholds?.warning?.underTo ?? 0,
  thresholds?.alert?.underTo ?? 0,
  thresholds?.critical?.underTo ?? 0,
  thresholds?.healthy?.underTo ?? 0,
  thresholds?.healthy?.overTo ?? 0,
  thresholds?.warning?.overTo ?? 0,
  thresholds?.critical?.overFrom ?? 0,
];

const extractInternalThresholds = (
  thresholds: TemperatureInternalThresholds
) => [
  thresholds?.warning?.coldSideTo ?? 0,
  thresholds?.alert?.coldSideTo ?? 0,
  thresholds?.critical?.coldSideTo ?? 0,
  thresholds?.healthy?.coldSideTo ?? 0,
  thresholds?.healthy?.hotSideTo ?? 0,
  thresholds?.warning?.hotSideTo ?? 0,
  thresholds?.critical?.hotSideFrom ?? 0,
];

/**
 * Extracts DOUBLE ("or", "and") threshold rules and returns the first seven values in a array.
 * The first three are the lowest values and the last three are the highest.
 * The fourth value is the middle threshold (start of the first slider and end for the other).
 *
 * @param thresholds array of SensorThreshold objects (4)
 * @returns array of numbers (7)
 */
export const extractDoubleRules = (
  thresholds:
    | TemperatureInternalThresholds
    | TpmsPressureThresholds
    | undefined
    | null,
  sensorType: SensorProfileConfigType
) => {
  if (!thresholds || !sensorType) return null;
  switch (sensorType) {
    case "tpmsBeta":
      return extractTpmsThresholds(thresholds as TpmsPressureThresholds);
    case "internal" as SensorProfileConfigType:
      return extractInternalThresholds(
        thresholds as TemperatureInternalThresholds
      );
    default:
      return null;
  }
};

export const getMaxValueFromThresholds = (
  thresholds: SensorThresholdShort | undefined | null
) => {
  if (!thresholds) return null;
  return Number(thresholds.critical?.criticalTo);
};

/****************************************************************************************
 * PAYLOAD PREPARATION UTILS                                                            *
 ***************************************************************************************/

export const prepareRulesPayload = (
  sensor: string | undefined,
  values: number[],
  min: number,
  max: number,
  step: number = 1
): AssetSensorProfileSensorInput => {
  return {
    ...(sensor && { sensor }),
    thresholds: [
      {
        healthyFrom: Number(min.toFixed(2)),
        healthyTo: Number(values[0].toFixed(2)),
        warningFrom: Number((values[0] + step).toFixed(2)),
        warningTo: Number(values[1].toFixed(2)),
        alertFrom: Number((values[1] + step).toFixed(2)),
        alertTo: Number((values[2] - step).toFixed(2)),
        criticalFrom: Number(values[2].toFixed(2)),
        criticalTo: Number(max.toFixed(2)),
      },
    ],
  };
};

export const prepareReversedPayload = (
  sensor: string | undefined,
  values: number[],
  min: number,
  max: number,
  step: number = 1
): AssetSensorProfileSensorInput => {
  return {
    ...(sensor && { sensor }),
    thresholds: [
      {
        criticalFrom: Number(min.toFixed(2)),
        criticalTo: Number(values[0].toFixed(2)),
        alertFrom: Number((values[0] + step).toFixed(2)),
        alertTo: Number((values[1] - step).toFixed(2)),
        warningFrom: Number(values[1].toFixed(2)),
        warningTo: Number((values[2] - step).toFixed(2)),
        healthyFrom: Number(values[2].toFixed(2)),
        healthyTo: Number(max.toFixed(2)),
      },
    ],
  };
};

export const prepareDoubleRulesPayload = (
  sensor: string | undefined,
  lowerValuesArray: number[],
  higherValuesArray: number[],
  threshold: number,
  min: number,
  max: number,
  step: number = 1
): AssetSensorProfileSensorInput => {
  return {
    ...(sensor && { sensor }),
    thresholds: [
      {
        criticalFrom: Number(min.toFixed(2)),
        criticalTo: Number(lowerValuesArray[0].toFixed(2)),
        alertFrom: Number((lowerValuesArray[0] + step).toFixed(2)),
        alertTo: Number(lowerValuesArray[1].toFixed(2)),
        warningFrom: Number((lowerValuesArray[1] + step).toFixed(2)),
        warningTo: Number(lowerValuesArray[2].toFixed(2)),
        healthyFrom: Number((lowerValuesArray[2] + step).toFixed(2)),
        healthyTo: Number(threshold.toFixed(2)),
      },
      {
        criticalFrom: Number(higherValuesArray[2].toFixed(2)),
        criticalTo: Number(max.toFixed(2)),
        alertFrom: Number((higherValuesArray[1] + step).toFixed(2)),
        alertTo: Number((higherValuesArray[2] - step).toFixed(2)),
        warningFrom: Number((higherValuesArray[0] + step).toFixed(2)),
        warningTo: Number(higherValuesArray[1].toFixed(2)),
        healthyFrom: Number(threshold.toFixed(2)),
        healthyTo: Number(higherValuesArray[0]),
      },
    ],
  };
};

/****************************************************************************************
 * OTHER UTILS                                                                          *
 ***************************************************************************************/

/**
 * Creates an array of objects with value and label for the slider marks.
 *
 * @param min number
 * @param max number
 * @param unit string
 * @returns array with of objects with value and label (2)
 */
export const sliderMarks = (min: number, max: number, unit: string) => [
  {
    value: min,
    label: `${min} ${unit}`,
  },
  {
    value: max,
    label: `${max} ${unit}`,
  },
];

/**
 * Returns an array of three values that represent the quarter, half, and three-quarters of the provided range.
 *
 * @param min number
 * @param max number
 * @returns array of numbers (3)
 */
export const returnEvenValuesFromRange = (
  min: number,
  max: number
): number[] => {
  const range = max - min;
  const quarter = Math.round(min + range * 0.25);
  const half = Math.round(min + range * 0.5);
  const threeQuarters = Math.round(min + range * 0.75);
  return [quarter, half, threeQuarters];
};

/**
 * Returns a value if it is within the provided min and max range, otherwise returns the limit.
 *
 * @param value number
 * @param min number
 * @param max number
 * @returns number
 */
export const returnDynamicLimit = (value: number, min: number, max: number) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};
