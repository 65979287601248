import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import { HorizontalBarChart } from "../../../../../shared/components/DashboardWidgets/HorizontalBarChart";
import { DataItem } from "../../../../../shared/components/DashboardWidgets/PieChartWidget";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../shared/utils";

type Category = {
  count: number;
};

export type DashboardWidgetGeofenceTypesData = {
  categories: Record<string, Category>;
  total: number;
};

export type GeofenceTypesData = {
  name: string;
  count: number;
};

export interface DashboardWidgetGeofenceTypes
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetGeofenceTypesData;
}

export interface GeofenceTypesWidgetProps {
  widget: DashboardWidgetGeofenceTypes;
}

export const mapWidgetData = (
  data: DashboardWidgetGeofenceTypesData
): DataItem[] => {
  if (!data || !data.categories) {
    return [];
  }

  const dataSet = Object.keys(data.categories).map((key, index) => {
    const category = data.categories[key];
    return {
      name: key,
      value: category.count,
      color: mapChartColors(key, index),
    };
  });

  return dataSet;
};

const mapChartColors = (data: string, index: number): string => {
  if (data === "Other") {
    return "var(--concrete)";
  }

  const colors = [
    "rechartsTeal",
    "rechartsPurple",
    "rechartsViolet",
    "rechartsMarineBlue",
    "rechartsCoral",
  ];

  return `var(--${colors[index]})`;
};

export const GeofenceTypesWidget: React.FC<GeofenceTypesWidgetProps> = ({
  widget,
}) => {
  const title = widget.name ?? "";

  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetGeofenceTypesData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData);
    const total = parsedData?.total ?? 0;

    return mappedData;
  }, [getDashboardWidgetData]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--geofence-types"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the geofence types
          widget! Please try again later.
        </Typography>
      ) : (
        <HorizontalBarChart
          title={title}
          showTotal={false}
          isLoading={isLoading}
          showPercentages={true}
          totalRecords={widget?.data?.total}
          data={data}
          showXAxis={false}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
