import React, { FC, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  FAULT_CODE_UPLOAD_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { BatchTemplate } from "../../../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../../../shared/components/UploadFileDialog/UploadResult";
import { ParseFileContentError } from "../../../../../utils";
import {
  BatchUploadDialogProps,
  useBatchUploadForm,
} from "../../../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import {
  parseFaultCodeFile,
  mapFileDataToCreateFaultCodeInput,
  faultTypeTemplateToEnumValueMap,
} from "./helpers";
import { ValidatedFaultCode } from "./types";

export const UploadFaultCodeDialog: FC<BatchUploadDialogProps> = ({
  title,
  customerOrg,
  dialogFields,
  isOpen,
  onClose,
  onUpload,
}: BatchUploadDialogProps) => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useBatchUploadForm(title, customerOrg);

  const resetState = () => {
    setIsFaultCodeUploading(false);
    setIsFaultCodeUploaded(false);
    setParseFileResult({ validatedFaultCodes: [], validationErrors: [] });
  };

  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isFaultCodeUploading, setIsFaultCodeUploading] = useState(false);
  const [isFaultCodeUploaded, setIsFaultCodeUploaded] = useState(false);
  const [parseFileResult, setParseFileResult] = useState<{
    validatedFaultCodes: ValidatedFaultCode[];
    validationErrors: ParseFileContentError[];
  }>({ validatedFaultCodes: [], validationErrors: [] });

  const handleSubmit = async (file: File) => {
    setIsFaultCodeUploading(true);

    const {
      org_select: { id: orgId, label: orgName },
      batch_name,
    } = getValues();

    try {
      const { fileData, errors: parseErrors } = await parseFaultCodeFile(file);
      const fileDataWithFaultTypeFormated = fileData.map((data) => ({
        ...data,
        faultCodeType: data.faultCodeType
          ? faultTypeTemplateToEnumValueMap[data.faultCodeType]
          : data.faultCodeType,
      }));
      const { validatedFaultCodes, validationErrors } =
        mapFileDataToCreateFaultCodeInput(
          fileDataWithFaultTypeFormated,
          orgId,
          title,
          orgName
        );
      setParseFileResult({
        validatedFaultCodes,
        validationErrors: [...validationErrors, ...parseErrors],
      });

      const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
      setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

      const faultCodes = validatedFaultCodes.map(({ faultCode }) => faultCode);
      const jsonFile = parseContentAsJsonBlobFile(faultCodes, fileName);

      await uploadBatchFileToS3(
        jsonFile,
        BatchTemplate.CreateFaultCodes,
        batch_name,
        orgId
      );
    } catch (error) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Fault Code Uploading Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
      setIsFaultCodeUploading(false);
      setIsFaultCodeUploaded(true);
    }

    setIsFaultCodeUploading(false);
    setIsFaultCodeUploaded(true);

    if (onUpload) {
      onUpload();
    }

    queryClient.invalidateQueries(["getConfigurationSets"]);
  };

  const onFilesUploadDialogClose = () => {
    onClose();
    resetState();
  };

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      onClose={onFilesUploadDialogClose}
      handleSubmit={handleSubmit}
      isDataUploading={isFaultCodeUploading}
      isDataUploaded={isFaultCodeUploaded}
      downloadTemplateFilePath={FAULT_CODE_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={parseFileResult.validationErrors}
          itemName={"fault code"}
        />
      }
    />
  );
};
