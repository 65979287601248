import { FormFieldDropdownOption } from "../types";
import type { UserRoleDropdownItem } from "../views/AdminPanel/tabs/Users/helpers";

interface rolesTypes {
  [key: string]: string;
}

export const USER_ROLES = {
  SUPER_ADMIN: "superadmin",
  CLIENT_ADMIN: "clientadmin",
  INSTALLER: "installer",
  FLEET_VIEWER: "fleetviewer",
  FLEET_MANAGER: "fleetmanager",
  SUPERVISOR: "supervisor",
  EQUIPMENT_MANAGER: "equipmentmanager",
  LEASE_AGENT: "leaseagent",
  LOAD_PLANNER: "loadplanner",
  DRIVER: "driver",
  MAINTENANCE_MANAGER: "maintenancemanager",
  SHOP_TECHNICIAN: "shoptechnician",
  CUSTOMER_SERVICE: "customerservice",
};

export const roleToHumanReadableMapper: rolesTypes = {
  superadmin: "Super Admin",
  clientadmin: "Client Admin",
  installer: "Installer",
  fleetviewer: "Fleet Viewer",
  fleetmanager: "Fleet Manager",
  supervisor: "Supervisor",
  equipmentmanager: "Equipment Manager",
  leaseagent: "Lease Agent",
  loadplanner: "Load Planner",
  customerservice: "Customer Service",
  driver: "Driver",
  shoptechnician: "Shop Technician",
  maintenancemanager: "Maintenance Manager",
};

// TODO: Remove if confirmed not used anymore (will use value from nomenclatures collection)
// export const USER_ROLES_ALLOWED_FOR_CLIENT_ADMIN = [
//   {
//     value: "clientadmin",
//     label: "Client Admin",
//   },
//   {
//     value: "customerservice",
//     label: "Customer Service",
//   },
//   {
//     value: "equipmentmanager",
//     label: "Equipment Manager",
//   },
//   {
//     value: "fleetmanager",
//     label: "Fleet Manager",
//   },
//   {
//     value: "supervisor",
//     label: "Supervisor",
//   },
//   {
//     value: "leaseagent",
//     label: "Lease Agent",
//   },
//   {
//     value: "loadplanner",
//     label: "Load Planner",
//   },
//   {
//     value: "fleetviewer",
//     label: "Fleet Viewer",
//   },
//   {
//     value: "installer",
//     label: "Installer",
//   },
//   {
//     value: "driver",
//     label: "Driver",
//   },
//   {
//     value: "maintenancemanager",
//     label: "Maintenance Manager",
//   },
//   {
//     value: "shoptechnician",
//     label: "Shop Technician",
//   },
// ];

// TODO: Remove if confirmed not used anymore (will use value from nomenclatures collection)
// export const USER_ROLES_ALLOWED_FOR_SUPER_ADMIN = [
//   {
//     value: "superadmin",
//     label: "Super Admin",
//   },
//   ...USER_ROLES_ALLOWED_FOR_CLIENT_ADMIN,
// ];

export const USER_LOAD_FAILED_PAYLOAD = {
  title: "User load failed!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const USER_RESEND_CONFIRMATION_FAILED_PAYLOAD = {
  title: "Unable to send the Resend!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const USER_DELETION_SUCCESS_PAYLOAD = {
  title: "User removed successfully.",
  text: "You can find the updated users in the table below",
  severity: "success",
};

export const USER_DELETION_FAILED_PAYLOAD = {
  title: "Failed to remove the user.",
  text: "Something went wrong",
  severity: "error",
};

export const USER_DEACTIVATION_SUCCESS_PAYLOAD = {
  title: "User deactivated successfully.",
  text: "You can find the updated users in the table below",
  severity: "success",
};

export const USER_DEACTIVATION_FAILED_PAYLOAD = {
  title: "Failed to deactivate the user.",
  text: "Something went wrong",
  severity: "error",
};

export const USER_ACTIVATION_FAILED_PAYLOAD = {
  title: "Failed to activate the user.",
  text: "Something went wrong",
  severity: "error",
};

export const USER_ACTIVATION_SUCCESS_PAYLOAD = {
  title: "User activated successfully.",
  text: "You can find the updated users in the table below",
  severity: "success",
};

export const USER_UPDATE_SUCCESS_PAYLOAD = {
  title: "User updated successfully!",
  text: "Your changes will be visible soon",
  severity: "success",
};

export const USER_UPDATE_FAILED_PAYLOAD = {
  title: "Update user Failed",
  severity: "error",
};

export const USER_IMPERSONATION_SUCCESS_PAYLOAD = {
  title: "Success!",
  text: "Impersonation started successfully!",
  severity: "success",
};

export const USER_IMPERSONATION_FAILED_PAYLOAD = {
  title: "Impersonation request failed!",
  severity: "error",
};

export const USER_FORM_FIELDS = [
  {
    name: "customer_orgs_id",
    type: "autocomplete",
    label: "Organization Name",
    options: [] as FormFieldDropdownOption[],
    required: true,
  },
  {
    name: "lastName",
    type: "text",
    label: "Last Name",
    required: true,
  },
  {
    name: "firstName",
    type: "text",
    label: "First Name",
    required: true,
  },
  {
    name: "name",
    type: "text",
    label: "Username",
    required: true,
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    required: true,
  },
  {
    name: "phoneNumber",
    type: "phoneNumber",
    label: "Phone Number",
    hint: "Phone number must be 10 digits",
  },
  {
    name: "groups",
    type: "autocomplete",
    label: "Role",
    options: [] as UserRoleDropdownItem[],
    required: true,
  },
];

export const USER_GROUPS_UPDATED = "USER_GROUPS_UPDATED";

export const USER_TIME_ZONE_PREFERENCE_UPDATED =
  "USER_TIME_ZONE_PREFERENCE_UPDATED";

export const TERMS_ACCEPTED = "T&C_ACCEPTED";
export const USER_IMPERSONATION_START = "USER_IMPERSONATION_START";
export const USER_IMPERSONATION_END = "USER_IMPERSONATION_END";
export const USER_STATUS_UPDATED = "USER_STATUS_UPDATED";
export const USER_PHONE_REMOVED = "USER_PHONE_REMOVED";

export interface UserEventsMapper {
  [key: string]: string;
}

export const USER_EVENTS_MAPPER: UserEventsMapper = {
  USER_FIRST_NAME_UPDATED: "First name",
  USER_LAST_NAME_UPDATED: "Last name",
  USER_PHONE_UPDATED: "Phone number",
  USER_EMAIL_UPDATED: "Email",
  USER_STATUS_UPDATED: "User status",
  USER_GROUPS_UPDATED: "User role",
  USER_ORG_UPDATED: "User's company",
  "T&C_ACCEPTED": "Terms & Conditions Accepted",
  USER_IMPERSONATION_START: "User started being impersonated",
  USER_IMPERSONATION_END: "User stopped being impersonated",
  USER_TIME_ZONE_PREFERENCE_UPDATED: "Time zone",
  USER_PHONE_REMOVED: "Phone number is removed",
};

export const USER_TABLE_SORT_KEYS: string[] = [
  "firstName",
  "lastName",
  "email",
  "username",
  "orgKeys",
  "phoneNumber",
  "role",
  "status",
  "createdDate",
  "updatedDate",
];

export enum UserStatus {
  active = "active",
  deactivated = "deactivated",
  pending = "pending",
}
