import { FC, useCallback, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import slug from "slug";
import { AlertType } from "../../graphql/operations";
import Page from "../../shared/components/Page";
import { SubHeader } from "../../shared/components/SubHeader";
import { SubHeaderActionType } from "../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { AlertView } from "../AlertView";
import { AlertsTable } from "./components/AlertsTable";
import { CreateAlertDialog } from "./components/CreateAlertDialog/CreateAlertDialog";
import { AlertTypeTitle } from "./constants";

export const AlertsList: FC = () => {
  const page = useRef();
  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const handleDialogClose = useCallback(
    () => setIsCreateDialogOpen(false),
    [setIsCreateDialogOpen]
  );

  const createAlert = useCallback(
    (reportType: AlertType, isCustomTemplate: boolean, templateId?: string) => {
      navigate(
        `${NavigationRoutes.Alerts}/${slug(
          // @ts-ignore
          AlertTypeTitle[reportType]
        )}/template`,
        { state: { templateId: templateId ?? "", isCustomTemplate } }
      );
    },
    [navigate]
  );

  return (
    <Page ref={page} title="" className={"bg-background"}>
      <SubHeader
        title="Alerts"
        actions={[
          {
            type: SubHeaderActionType.Button,
            primary: true,
            title: "New Alert",
            handler: () => setIsCreateDialogOpen(true),
            accessScope: "alerts.create",
          },
        ]}
        hideHeaderBorder={true}
        hideOrgName
      />
      <AlertsTable />
      <CreateAlertDialog
        open={isCreateDialogOpen}
        onClose={handleDialogClose}
        onSubmit={createAlert}
      />
    </Page>
  );
};

export const AlertsView = () => (
  <Routes>
    <Route path="*" element={<AlertsList />} />
    <Route path=":name/:id" element={<AlertView />} />
  </Routes>
);
