import { FC, memo, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Input,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as DualImbalanceSensorDark } from "../../../../../../assets/svgs/dualImbalanceSensorDark.svg";
import { ReactComponent as DualImbalanceSensorLight } from "../../../../../../assets/svgs/dualImbalanceSensorLight.svg";
import { ReactComponent as TemperatureSensorDark } from "../../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../../assets/svgs/temperatureSensorLight.svg";
import { ReactComponent as TPMSSensorDark } from "../../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../../assets/svgs/tpmsSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  AssetWithSensors,
  AssignOrgProfileToAssetsInput,
  SensorProfileType,
  ProfileConfigProperty,
  SetAssetSensorProfilesInput,
  useGetSensorProfileNamesWithConfigurationQuery,
  SensorProfileConfigType,
  SensorThresholdShort,
  TpmsPressureThresholds,
  PressureUnit,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { Label } from "../../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  extractRules,
  extractDoubleRules,
  prepareRulesPayload,
  prepareDoubleRulesPayload,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  sliderMarks,
  tpmsTemperatureDefaultValues,
  tpmsImbalanceDefaultValues,
  tpmsPressureMinHealthy,
  tpmsPressureMaxHealthy,
  tpmsPressureDefaultValues,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../../shared/components/Text";
import WithAsterisk from "../../../../../../shared/components/WithAsterisk";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import { useGetPressureUnitPreference } from "../../../../../../shared/hooks/useGetPressureUnitPreference";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  getConvertedPressureValue,
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressurePayload,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../../utils/convertPressure";
import "../../../Profiles/Drawers/TpmsDrawer";
import {
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import { AssetInformation } from "../Components/AssetInformation";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import { TPMS_FORM_FIELDS, tpmsSensorSchema } from "./tpmsDrawer.utils";

export type TpmsDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
  currentOrgId?: string;
};

const TpmsDrawer: FC<TpmsDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const pressureUnit = useGetPressureUnitPreference();

  // Used to show all required data from the current selected
  const currentAssetForEdit = selectedRecordsData?.length
    ? selectedRecordsData[0]
    : undefined;

  const [tpmsPressureMinHealthyConverted, tpmsPressureMaxHealthyConverted] =
    getConvertedPressureValues(
      [tpmsPressureMinHealthy, tpmsPressureMaxHealthy],
      pressureUnit
    );

  const [minTpmsConverted, maxTpmsConverted] = getConvertedPressureValues(
    [MinValuesBySensorType.tpmsPressure, MaxValuesBySensorType.tpmsPressure],
    pressureUnit
  );
  const [minImbalanceConverted, maxImbalanceConverted] =
    getConvertedPressureValues(
      [MinValuesBySensorType.imbalance, MaxValuesBySensorType.imbalance],
      pressureUnit
    );
  const tpmsImbalanceDefaultValuesConverted = getConvertedPressureValues(
    tpmsImbalanceDefaultValues,
    pressureUnit
  );
  const tpmsPressureDefaultValuesConverted = getConvertedPressureValues(
    tpmsPressureDefaultValues,
    pressureUnit
  );
  // Extract Sensor values from the current selected asset
  const temperatureData =
    extractRules(currentAssetForEdit?.temperature) ||
    tpmsTemperatureDefaultValues;
  const imbalanceData =
    extractRules(currentAssetForEdit?.imbalance) ||
    tpmsImbalanceDefaultValuesConverted;
  const pressureData =
    extractDoubleRules(
      currentAssetForEdit?.pressure,
      SensorProfileConfigType.TpmsBeta
    ) || tpmsPressureDefaultValuesConverted;
  const underPressureData = pressureData.slice(0, 3);
  const overPressureData = pressureData.slice(4, 7);
  const healthyPressureData = pressureData?.slice(3, 4)[0];

  // State
  const [imbalance, setImbalance] = useState(imbalanceData);
  const [temperature, setTemperature] = useState(temperatureData);
  const [underPressure, setUnderPressure] = useState(underPressureData);
  const [overPressure, setOverPressure] = useState(overPressureData);
  const [healthyPressure, setHealthyPressure] = useState(healthyPressureData);
  const [healthyPressureError, setHealthyPressureError] = useState<string>("");
  const [isTypeOfAssigmentProfile, setIsTypeOfAssigmentProfile] =
    useState(false);
  const [profileMutationInput, setProfileMutationInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileName, setSelectedProfileName] = useState<string>("");
  const [dropdownTpmsProfiles, setDropdownTpmsProfiles] = useState<
    SensorProfileDropdownItem[]
  >([]);
  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);

  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };

  // will set the options when a type of assigment is profile
  // this fixes an issue with missing dropdown options when drawer is opened for the first time
  if (isTypeOfAssigmentProfile) {
    TPMS_FORM_FIELDS[1].options = dropdownTpmsProfiles;
  }

  let defaultTypeOfAssigment = "custom";
  let defaultTpmsSensorProfile = undefined;
  let preselectedProfile = null;
  const queryClient = useQueryClient();

  if (
    currentAssetForEdit?.sensorProfile?.configuration?.tpmsBeta?.temperature
      ?.profileName &&
    currentAssetForEdit?.sensorProfile?.configuration?.tpmsBeta?.temperature
      ?.type !== SensorProfileType.Asset
  ) {
    defaultTypeOfAssigment = "profile";
    defaultTpmsSensorProfile =
      currentAssetForEdit?.sensorProfile?.configuration?.tpmsBeta?.temperature
        ?.profileId ?? "";
    preselectedProfile =
      currentAssetForEdit?.sensorProfile?.configuration?.tpmsBeta?.temperature
        ?.profileName;
  }

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const mainForm = useForm({
    resolver: yupResolver(tpmsSensorSchema),
    defaultValues: {
      typeOfAssigment: defaultTypeOfAssigment,
      tpmsSensorProfile: defaultTpmsSensorProfile,
    },
  });

  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible(!isConfirmProfileAssignmentVisible);
    if (!confirmed) {
      return;
    }

    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileMutationInput) {
      mutateProfile.mutate({ input: profileMutationInput });
    }
  };

  // GraphQL
  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.TpmsBeta,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  const organizationSensorProfiles = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  useEffect(() => {
    const tpmsSensorProfiles: SensorProfileDropdownItem[] =
      organizationSensorProfiles.map(
        (item) =>
          ({
            label: item.name,
            value: item._id,
            id: item._id,
            type: item.type,
            configuration: item.configuration,
          } as SensorProfileDropdownItem)
      );

    setDropdownTpmsProfiles(
      sortSensorProfileNameDropdownItems(tpmsSensorProfiles)
    );
  }, [organizationSensorProfiles]);

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Sensor Settings Updating Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const handleClose = () => {
    setProfileMutationInput(undefined);
    mainForm.reset();
    setOpen(false);
  };

  const handleInputChange = (e: {
    name: string;
    value: string;
    id: string;
  }) => {
    const assignmentOption = e?.id ?? undefined;

    // Map sensors data to default values when switching from profile to custom
    if (assignmentOption === "custom") {
      setTemperature(temperatureData);
      setImbalance(imbalanceData);
      setOverPressure(overPressureData);
      setUnderPressure(underPressureData);
      setHealthyPressure(healthyPressureData);
    }
    setIsTypeOfAssigmentProfile(Boolean(assignmentOption === "profile"));
    setHealthyPressureError("");
  };

  const handleProfileChange = (
    profileConfiguration: SensorProfileDropdownItem
  ) => {
    const temperatureData =
      extractRules(
        profileConfiguration?.configuration?.tpmsBeta
          ?.temperature as unknown as SensorThresholdShort
      ) || tpmsTemperatureDefaultValues;

    const imbalanceData =
      extractRules(
        profileConfiguration?.configuration?.tpmsBeta
          ?.imbalance as unknown as SensorThresholdShort
      ) || tpmsImbalanceDefaultValues;

    const pressureData =
      extractDoubleRules(
        profileConfiguration?.configuration?.tpmsBeta
          ?.pressure as TpmsPressureThresholds,
        SensorProfileConfigType.TpmsBeta
      ) || tpmsPressureDefaultValues;
    const underPressureData = pressureData.slice(0, 3);
    const overPressureData = pressureData.slice(4, 7);
    const healthyPressureData = pressureData?.slice(3, 4)[0];

    setTemperature(temperatureData);
    setImbalance(imbalanceData);
    setOverPressure(overPressureData);
    setUnderPressure(underPressureData);
    setHealthyPressure(healthyPressureData);
    setHealthyPressureError("");
    setSelectedProfileName(profileConfiguration?.label ?? "");
  };

  const onHealthyValueChange = (e: { target: { value: string } }) => {
    const num = Number(e.target.value);
    if (Number.isNaN(num)) return;

    const pressureErrorMsg = `Number must be between ${tpmsPressureMinHealthyConverted} and ${tpmsPressureMaxHealthyConverted}.`;
    let evenUnder = returnEvenValuesFromRange(minTpmsConverted, num);
    let evenOver = returnEvenValuesFromRange(num, maxTpmsConverted);
    let errorMessage = "";

    if (num < tpmsPressureMinHealthyConverted) {
      evenUnder = returnEvenValuesFromRange(
        minTpmsConverted,
        tpmsPressureMinHealthyConverted
      );
      evenOver = returnEvenValuesFromRange(
        tpmsPressureMinHealthyConverted,
        maxTpmsConverted
      );
      errorMessage = pressureErrorMsg;
    } else if (num > tpmsPressureMaxHealthy) {
      evenUnder = returnEvenValuesFromRange(
        minTpmsConverted,
        tpmsPressureMaxHealthy
      );
      evenOver = returnEvenValuesFromRange(
        tpmsPressureMaxHealthy,
        maxTpmsConverted
      );
      errorMessage = pressureErrorMsg;
    }

    setUnderPressure(evenUnder);
    setOverPressure(evenOver);
    setHealthyPressure(num);
    setHealthyPressureError(errorMessage);
  };

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const mutateCustomProfile = useMutateCustomProfile({
    dispatchErrorMessage,
    dispatchSuccessMessage,
    queryClient,
    handleClose,
  });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const mutateProfile = useMutateProfile({
    dispatchErrorMessage,
    dispatchSuccessMessage,
    queryClient,
    handleClose,
  });

  useEffect(() => {
    if (
      currentAssetForEdit?.sensorProfile?.configuration.tpmsBeta?.pressure
        ?.type !== SensorProfileType.Asset ||
      currentAssetForEdit?.sensorProfile?.configuration.tpmsBeta?.temperature
        ?.type !== SensorProfileType.Asset
    ) {
      setIsTypeOfAssigmentProfile(true);
    }

    // Check if user has clicked dialog checkbox to not show again
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [currentAssetForEdit]);

  const onSubmit = async () => {
    const valid = await mainForm.trigger();
    const isProfileAndInvalid = isTypeOfAssigmentProfile && !valid;
    if (isProfileAndInvalid || !!healthyPressureError) return;

    const mainFormData = mainForm.getValues();

    if (mainFormData.typeOfAssigment === "profile") {
      const selectedAssetIds = selectedRecordsData?.map(
        (record) => record._id
      ) as string[];
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedAssetIds,
        profileId: mainFormData?.tpmsSensorProfile ?? "",
      };
      // if we have dontShowConfirmationDialog again clicked
      // and submitted to local storage, don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile.mutate({ input });
      } else {
        setProfileMutationInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    } else {
      const selectedAssetImeis = selectedRecordsData?.map(
        (element) => element?.imei
      ) as string[];

      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          tpmsBeta: {
            pressure: prepareDoubleRulesPayload(
              ProfileConfigProperty.Pressure,
              prepareConvertedMbarPressuresPayload(underPressure, pressureUnit),
              prepareConvertedMbarPressuresPayload(overPressure, pressureUnit),
              prepareConvertedMbarPressurePayload(
                healthyPressure,
                pressureUnit
              ),
              MinValuesBySensorType.tpmsPressure,
              MaxValuesBySensorType.tpmsPressure
            ),
            temperature: prepareRulesPayload(
              ProfileConfigProperty.Temperature,
              temperature,
              MinValuesBySensorType.temperature,
              MaxValuesBySensorType.temperature
            ),
            imbalance: prepareRulesPayload(
              ProfileConfigProperty.Imbalance,
              prepareConvertedMbarPressuresPayload(imbalance, pressureUnit),
              MinValuesBySensorType.imbalance,
              MaxValuesBySensorType.imbalance
            ),
          },
        },
      };

      mutateCustomProfile.mutate({ input });
    }
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  const dynamicPsiLimit = returnDynamicLimit(
    healthyPressure,
    tpmsPressureMinHealthyConverted,
    tpmsPressureMaxHealthyConverted
  );

  const underPressureMarks = sliderMarks(
    minTpmsConverted,
    dynamicPsiLimit,
    getPressureUnitLabel(pressureUnit)
  );
  const overPressureMarks = sliderMarks(
    dynamicPsiLimit,
    maxTpmsConverted,
    getPressureUnitLabel(pressureUnit)
  );

  const temperatureMarks = sliderMarks(
    MinValuesBySensorType.temperature,
    MaxValuesBySensorType.temperature,
    "F"
  );

  const imbalanceMarks = sliderMarks(
    minImbalanceConverted,
    maxImbalanceConverted,
    getPressureUnitLabel(pressureUnit)
  );

  const isLoading = mutateProfile.isLoading || mutateCustomProfile.isLoading;
  const isAnySliderDirty =
    temperature.toString() !== temperatureData.toString() ||
    overPressure.toString() !== overPressureData.toString() ||
    underPressure.toString() !== underPressureData.toString() ||
    imbalance.toString() !== imbalanceData.toString() ||
    healthyPressure !== healthyPressureData;
  const isSubmitDisabled =
    isLoading ||
    !!healthyPressureError ||
    (isTypeOfAssigmentProfile && !selectedProfileName) ||
    (!preselectedProfile && !isAnySliderDirty) ||
    (!!preselectedProfile && preselectedProfile === selectedProfileName);

  return (
    <Drawer
      testId="tpms-sensors-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />

      <DrawerContent>
        <div className="flex w-full flex-initial justify-between border-b border-concrete bg-background p-6">
          {selectedRecordsData?.length === 1 && (
            <AssetInformation
              orgName={currentAssetForEdit?.org_name}
              assetId={currentAssetForEdit?.asset_id}
              assetNickname={currentAssetForEdit?.name}
              imei={currentAssetForEdit?.imei}
              prdCde={currentAssetForEdit?.prd_cde}
            />
          )}
          {(selectedRecordsData?.length ?? 0) > 1 && (
            <Box>
              <Text fontSize={14} fontWeight="bold" classes="!text-primary">
                Number of assets selected: {selectedRecordsData?.length}
              </Text>
            </Box>
          )}
        </div>

        <Box className="pt-6">
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            className="justify-left px-6"
          ></Grid>
          <SensorProfileAssignmentForm
            isTypeOfAssignmentProfile={isTypeOfAssigmentProfile}
            form={mainForm}
            SENSOR_FORM_FIELDS={TPMS_FORM_FIELDS}
            handleAssignmentTypeInputChange={handleInputChange}
            handleSensorProfileInputChange={handleProfileChange}
            isProfileDataLoading={isSensorProfileNamesLoading}
          />

          <Box className="px-6" data-testid="tpms-sensors-wrapper">
            <Box className="mb-16">
              <Typography sx={{ fontWeight: "bold" }}>
                Tire Temperature Settings
              </Typography>
              {isLightTheme ? (
                <TemperatureSensorDark
                  style={{ ...svgIconSettings, marginTop: "0.75rem" }}
                />
              ) : (
                <TemperatureSensorLight
                  style={{ ...svgIconSettings, marginTop: "0.75rem" }}
                />
              )}
            </Box>
            <SensorSlider
              values={temperature}
              min={MinValuesBySensorType.temperature}
              max={MaxValuesBySensorType.temperature}
              marks={temperatureMarks}
              disabled={isLoading || isTypeOfAssigmentProfile}
              onChange={setTemperature}
            />

            <Box className="pt-12 pb-2">
              <Typography sx={{ fontWeight: "bold", marginBottom: "0.75rem" }}>
                Tire Pressure Settings
              </Typography>
              {isLightTheme ? (
                <TPMSSensorDark style={{ ...svgIconSettings }} />
              ) : (
                <TPMSSensorLight style={{ ...svgIconSettings }} />
              )}
            </Box>

            <Box className="pb-4">
              <FormControl sx={{ width: "100%" }}>
                <WithAsterisk>
                  <Label htmlFor="Healthy Value">Healthy Value</Label>
                </WithAsterisk>

                <Input
                  value={healthyPressure}
                  onChange={onHealthyValueChange}
                  data-testid="tpms-sensors-healthy-pressure-input"
                  id="pressureHealthyVal"
                  disabled={isLoading || isTypeOfAssigmentProfile}
                  sx={{ width: "100%" }}
                />
                {healthyPressureError && (
                  <FormHelperText
                    data-testid="tpms-sensors-healthy-pressure-error-text"
                    error={true}
                  >
                    {healthyPressureError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box className="py-8">
              <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
                Over Inflation Settings
              </Typography>
              <SensorSlider
                values={overPressure}
                min={dynamicPsiLimit}
                max={maxTpmsConverted}
                marks={overPressureMarks}
                disabled={isLoading || isTypeOfAssigmentProfile}
                onChange={setOverPressure}
              />
            </Box>

            <Box className="py-8">
              <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
                Under Inflation Settings
              </Typography>
              <SensorSlider
                values={underPressure}
                min={minTpmsConverted}
                max={dynamicPsiLimit}
                marks={underPressureMarks}
                disabled={isLoading || isTypeOfAssigmentProfile}
                onChange={setUnderPressure}
                reversed
              />
            </Box>

            <Box className="py-8">
              <Typography sx={{ fontWeight: "bold", marginBottom: "4rem" }}>
                Dual Imbalance Settings
                {isLightTheme ? (
                  <DualImbalanceSensorDark
                    style={{ ...svgIconSettings, marginTop: "0.75rem" }}
                  />
                ) : (
                  <DualImbalanceSensorLight
                    style={{ ...svgIconSettings, marginTop: "0.75rem" }}
                  />
                )}
              </Typography>

              <SensorSlider
                values={imbalance}
                min={minImbalanceConverted}
                max={maxImbalanceConverted}
                marks={imbalanceMarks}
                disabled={isLoading || isTypeOfAssigmentProfile}
                onChange={setImbalance}
              />
            </Box>
          </Box>
        </Box>

        <DrawerActions disabled={isLoading} onCancel={handleClose} />
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={isSubmitDisabled}
        testId="tpms-sensors-drawer-submit-btn"
        submit={() => onSubmit()}
      />

      {isTypeOfAssigmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileName}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default memo(TpmsDrawer);
