import {
  Config,
  ImmutableTree,
  Utils as QbUtils,
} from "@react-awesome-query-builder/core";
import { roleToHumanReadableMapper } from "../../../../../constants/users";
import { AlertReminderV2, Maybe } from "../../../../../graphql/operations";
import { prepareQueryBuilderConfig } from "../../../../../shared/components/QueryBuilder";
import { assetQueryBuilderFields } from "../../../../../shared/components/QueryBuilder/config";
import { UsersFullNameMap } from "../../../../../shared/hooks/useAvailableUserNames";
import { getUserFullName } from "../../../../../utils";

export const ASSET_ALERT_PAGE_SIZE = 25;

export const buildRecipientString = (
  reminder: Maybe<AlertReminderV2>,
  usersMap: UsersFullNameMap
): string => {
  if (!reminder) {
    return "";
  }

  const recipients: string[] = [];

  reminder.subscribers.roles.forEach((role) => {
    recipients.push(roleToHumanReadableMapper[role]);
  });
  reminder.subscribers.users.forEach((user) => {
    recipients.push(getUserFullName(usersMap[user]));
  });
  reminder.subscribers.emails.forEach((email) => {
    recipients.push(email);
  });

  return recipients.filter((name) => name).join(", ");
};

export const buildHumanReadableQueryString = (
  trigger: Maybe<string>
): string => {
  if (!trigger) {
    return "";
  }
  const queryBuilderConfig = prepareQueryBuilderConfig(assetQueryBuilderFields);
  const tree = buildQueryBuilderTree(JSON.parse(trigger), queryBuilderConfig);
  return tree ? buildHumanReadableQuery(tree, queryBuilderConfig) : "";
};

const isJsonLogic = (data: unknown): data is Object =>
  QbUtils.isJsonLogic(data);

const buildQueryBuilderTree = (
  data: unknown,
  config: Config
): ImmutableTree | undefined => {
  if (isJsonLogic(data)) {
    const jsonLogicTree = QbUtils.loadFromJsonLogic(data, config);
    return jsonLogicTree ? QbUtils.checkTree(jsonLogicTree, config) : undefined;
  }
  return undefined;
};

const buildHumanReadableQuery = (tree: ImmutableTree, config: Config): string =>
  QbUtils.queryString(tree, config, true) ?? "";
