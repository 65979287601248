import { MediaTableData } from "../../../../../graphql/operations";
import { MediaActivityItem } from "../../Assets/CargoTabPanel/InternalCameraStatus/interface";
import { generateUniqueId } from "./MediaTab";
import { MediaTabTableFloorUsageStatus } from "./interface";

export const processMediaTableData = (
  data: MediaTableData[]
): MediaActivityItem[] =>
  data.map((item) => {
    const isLoaded = item.floorUsageStatus?.toLowerCase().includes("loaded");

    return {
      ...(item as MediaActivityItem),
      floorUsageStatus: isLoaded
        ? MediaTabTableFloorUsageStatus.Loaded
        : MediaTabTableFloorUsageStatus.Empty,
      id: generateUniqueId(),
    };
  });
