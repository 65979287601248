import { memo, FC } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ReportIcon from "@mui/icons-material/Report";
import { Typography, Box, List, ListItem } from "@mui/material";
import { ParseFileContentError } from "../../../utils";

export type UploadResultProps = {
  uploadedMessage?: string;
  errors?: ParseFileContentError[];
  processedRecordsCount?: number;
  itemName?: string;
};

const UploadResult: FC<UploadResultProps> = ({
  uploadedMessage,
  errors = [],
  itemName,
  processedRecordsCount,
}: UploadResultProps) => {
  const itemsWithErrorsCount = Object.keys(
    errors.reduce((rows, err) => ({ ...rows, [err.row]: "" }), {})
  ).length;
  const errorsText = `${itemsWithErrorsCount} ${
    itemsWithErrorsCount === 1 ? `${itemName}` : `${itemName}s`
  } encountered issues (${errors.length} total)`;
  const successText = `${processedRecordsCount} ${
    processedRecordsCount === 1 ? `${itemName}` : `${itemName}s`
  } processed successfully (${processedRecordsCount} total)`;

  return (
    <Box className="max-h-[220px]">
      {!errors.length && (
        <Box className="mb-5 flex">
          <Typography className="!text-lg !font-bold">
            {uploadedMessage}
          </Typography>
        </Box>
      )}
      {processedRecordsCount && processedRecordsCount > 0 && (
        <Box className="flex">
          <Box className="mr-4 text-success">
            <CheckBoxIcon />
          </Box>
          <Typography className="!text-lg !font-bold">{successText}</Typography>
        </Box>
      )}
      {errors.length > 0 && (
        <Box>
          <Box className="flex">
            <Box className="mr-4 text-error">
              <ReportIcon />
            </Box>
            <Typography className="!text-lg !font-bold">
              {errorsText}
            </Typography>
          </Box>
          <List className="!pl-10">
            {errors.map(({ row, message }) => (
              <ListItem
                key={`${row}-${message}`}
                disablePadding
                className="!py-1 !text-base !font-normal"
              >{`Row ${row} - ${message}`}</ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default memo(UploadResult);
