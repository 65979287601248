import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ASSETS_LIST_WIDTH } from "../../../../../constants/map";
import { Asset, GeofenceData } from "../../../../../graphql/operations";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import AssetsList from "./AssetsList";

interface AssetsDrawerProps {
  isGeofencesListVisible: boolean;
  isOpen?: boolean;
}

const AssetsDrawer = memo(
  ({ isGeofencesListVisible, isOpen }: AssetsDrawerProps) => {
    const { setSelectedAssetId, setSelectedGeofence } = useAssetsDataContext();
    const navigate = useNavigate();

    const onGeofenceClick = useCallback(
      (geofence: GeofenceData) => {
        setSelectedAssetId(null);
        navigate(`${NavigationRoutes.Geofences}/${geofence._id}`);
      },
      [setSelectedAssetId, navigate]
    );
    const onFeatureClick = useCallback(
      (asset: Asset) => {
        setSelectedAssetId(asset._id);
        setSelectedGeofence(null);
      },
      [setSelectedAssetId, setSelectedGeofence]
    );

    const wrapperStyles = `${
      isOpen ? "fixed" : "hidden"
    } overflow-hidden bg-background text-primary relative shadow-2xl`;
    const wrapperHeight = isGeofencesListVisible
      ? "calc(100% - 11rem)"
      : "inherit";

    return (
      <Box
        className={wrapperStyles}
        width={ASSETS_LIST_WIDTH}
        height={wrapperHeight}
        data-testid="map-assets-drawer"
      >
        <Box className="absolute inset-0 flex">
          <AssetsList
            onFeatureClick={onFeatureClick}
            onGeofenceClick={onGeofenceClick}
          />
        </Box>
      </Box>
    );
  }
);

export default AssetsDrawer;
