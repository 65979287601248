import { Typography } from "@mui/material";
import BaseDashboardWidget from "../../../../../shared/components/DashboardWidgets/BaseDashboardWidget";

export type NoPermissionWidgetProps = {
  title: string;
};

const NoPermissionWidget = ({ title }: NoPermissionWidgetProps) => {
  return (
    <BaseDashboardWidget title={title}>
      <div className="flex items-center justify-center">
        <Typography className="!font-medium">No data available</Typography>
      </div>
    </BaseDashboardWidget>
  );
};

export default NoPermissionWidget;
