import {
  useState,
  memo,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { GridRowsProp, GridValidRowModel } from "@mui/x-data-grid-premium";
import Fuse from "fuse.js";
import { debounce, throttle, get } from "lodash";
import { matchSorter } from "match-sorter";
import { ASSETS_LIST_TABLE_SEARCH_SET_LOADING } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { TableSearch as TableSearchInput } from "./TableSearch";

const TABLES_TO_SKIP = ["alerts-list"];

export interface TableClientSideSearchProps<T extends GridValidRowModel> {
  rows: GridRowsProp<T>;
  searchKeys: string[];
  sortKeys: string[] | undefined;
  searchMinLength: number;
  setFoundRows: Dispatch<SetStateAction<GridRowsProp<T>>>;
  className: string;
  searchThreshold: number | undefined;
  searchExactMatch: boolean | undefined;
  initialSearch: string | undefined;
  onSearch: ((searchQuery: string) => void) | undefined;
  isDataRefetching: boolean | undefined;
  setIsSearchTriggered: Dispatch<SetStateAction<boolean>>;
  tableName: string;
  useExtendedSearch: boolean;
}

const TableClientSideSearch = <T extends GridValidRowModel>({
  rows,
  searchKeys,
  sortKeys,
  searchMinLength,
  setFoundRows,
  className,
  initialSearch,
  onSearch,
  isDataRefetching,
  setIsSearchTriggered,
  tableName,
  useExtendedSearch,
  searchThreshold = 0.2,
  searchExactMatch = false,
}: TableClientSideSearchProps<T>) => {
  const {
    state: { appConfig },
    dispatch,
  } = useAppContext();

  const [query, setQuery] = useState<string>("");
  const [queryLengthWarning, setQueryLengthWarning] = useState<boolean>(false);

  const fuseIndex = useMemo(
    () => Fuse.createIndex(searchKeys, rows),
    [rows, searchKeys]
  );

  // Memorise and configure fuse instance
  const fuse = useMemo(
    () =>
      new Fuse(
        rows,
        {
          findAllMatches: true,
          minMatchCharLength: searchMinLength,
          keys: searchKeys,
          useExtendedSearch,
          threshold: searchThreshold,
          includeScore: true,
        },
        fuseIndex
      ),
    [
      rows,
      searchMinLength,
      searchKeys,
      useExtendedSearch,
      searchThreshold,
      fuseIndex,
    ]
  );

  useEffect(() => {
    // if date in table is refetching clear the search field
    if (isDataRefetching) {
      setQuery("");
    }
  }, [isDataRefetching, setQuery]);

  useEffect(() => {
    if (initialSearch) {
      setQuery(initialSearch);
    }
  }, [initialSearch]);

  // Throttle query updates
  const onQueryUpdated = useMemo(
    () =>
      throttle(
        (
          query: string,
          rows: TableClientSideSearchProps<T>["rows"],
          fuse: Fuse<T>
        ) => {
          const filterRowsByExactSearch = (
            dataList: TableClientSideSearchProps<T>["rows"] = rows || [],
            searchKey: string = query.toLowerCase() || ""
          ): T[] => {
            const _foundRows: T[] = [];
            dataList.forEach((row) => {
              let rowMatched = false;
              for (let i = 0; i < searchKeys.length; i++) {
                if (rowMatched) break;
                if (
                  ("" + get(row, searchKeys[i]))
                    ?.toLowerCase()
                    .includes(searchKey)
                ) {
                  rowMatched = true;
                  _foundRows.push(row);
                }
              }
            });

            return sortKeys == null || sortKeys.length <= 0
              ? _foundRows
              : matchSorter(_foundRows, query, {
                  keys: sortKeys,
                  threshold: matchSorter.rankings.CONTAINS,
                  sorter: (matchItems) => {
                    return matchItems.sort((x, y) => {
                      if (x.keyIndex === 0 && y.keyIndex !== 0) {
                        return -1;
                      } else if (x.keyIndex !== 0 && y.keyIndex === 0) {
                        return 1;
                      }
                      if (x.rank !== y.rank) {
                        return y.rank - x.rank;
                      } else {
                        return 0;
                      }
                    });
                  },
                });
          };

          const getFuseSearchResults = () => {
            const results = fuse.search(query);
            const perfectMatches = results.filter(
              (result) =>
                typeof result?.score === "number" && result.score < 0.05
            );
            return perfectMatches.length > 0
              ? perfectMatches.map((result) => result.item)
              : results.map((result) => result.item);
          };

          if (onSearch instanceof Function) {
            if (query.length === 0 || query.length >= searchMinLength) {
              onSearch(query.toLowerCase());
            }
          } else {
            query.length >= searchMinLength
              ? setIsSearchTriggered(true)
              : setIsSearchTriggered(false);
            setFoundRows(
              query.length >= searchMinLength
                ? searchExactMatch
                  ? filterRowsByExactSearch(rows, query.toLowerCase())
                  : getFuseSearchResults()
                : rows
            );
          }

          dispatch({
            type: ASSETS_LIST_TABLE_SEARCH_SET_LOADING,
            payload: {
              isTableSearchLoading: false,
            },
          });
        },
        1000
      ),
    [
      dispatch,
      onSearch,
      sortKeys,
      searchKeys,
      searchMinLength,
      setFoundRows,
      searchExactMatch,
      setIsSearchTriggered,
    ]
  );

  // Trigger search when one of these dependencies are updated
  useEffect(() => {
    // we don't use isTableSearchLoading in alerts table, and it reduces the number of rerenders
    if (!TABLES_TO_SKIP.includes(tableName)) {
      dispatch({
        type: ASSETS_LIST_TABLE_SEARCH_SET_LOADING,
        payload: {
          isTableSearchLoading: true,
        },
      });
    }
    onQueryUpdated(query, rows, fuse);
  }, [onQueryUpdated, query, rows, fuse, dispatch, tableName]);

  const warn = useMemo(
    () =>
      debounce(
        (query) =>
          setQueryLengthWarning(query && query.length < searchMinLength),
        // We don't want to wait when warning already has been displayed.
        // 1 sec is enough to slowly type 3 characters.
        queryLengthWarning ? 0 : appConfig.debounceTimeLong
      ),
    [queryLengthWarning, searchMinLength, appConfig.debounceTimeLong]
  );

  // Trigger warning debouncer
  useEffect(() => warn(query), [warn, query]);
  return (
    <TableSearchInput
      searchText={query}
      onChange={setQuery}
      className={className}
      showMinTextLengthWarning={queryLengthWarning}
    />
  );
};

export default memo(TableClientSideSearch);
