import { ReactElement, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import * as yup from "yup";
import {
  FILTERS_LIST_WIDTH,
  ASSETS_LIST_WIDTH,
  SMALLEST_SCREEN_WIDTH,
} from "../../constants/map";
import useBreakpoint from "../../shared/hooks/useBreakpoint";
import AssetsDrawer from "./MapView/Shared/AssetsDrawer/AssetsDrawer";
import FiltersDrawer from "./MapView/Shared/FiltersDrawer/FiltersDrawer";
import { useAssetsDataContext } from "./shared/AssetsDataContext/AssetsDataContext";

interface AssetsViewContainerProps {
  children: ReactElement;
  view?: string;
}

export type TFieldValues = {
  minDays: number;
  maxDays: number;
};

export const getViewFromUrl = (pathname: string) => {
  if (pathname.includes("table")) return "table";
  if (pathname.includes("gallery")) return "gallery";
  if (pathname.includes("geofences")) return "geofences";
  if (pathname.includes("invitations")) return "invitations";
  return "map";
};

export const schema = yup.object({
  maxDays: yup.number().min(0),
  minDays: yup
    .number()
    .min(0)
    .test({
      name: "min-max-validation",
      message: "Min Value can not be greater than Max",
      test: (minDays, { parent }) => {
        let { maxDays } = parent;
        if (maxDays === undefined) maxDays = 0;
        if (minDays === undefined) minDays = 0;
        if (minDays > maxDays) return false;
        return true;
      },
    }),
});

const AssetsViewContainer = ({ children, view }: AssetsViewContainerProps) => {
  const {
    isGeofencesListVisible,
    isFiltersDrawerOpen,
    setIsFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsDrawerOpen,
    addGeofenceDrawerOpen,
    isDrawingGeofence,
    selectedAssetId,
  } = useAssetsDataContext();
  const isSmallerThanFilters = useBreakpoint("down", SMALLEST_SCREEN_WIDTH);
  const isMobile = useBreakpoint("down", "sm");
  const methods = useForm<TFieldValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // State
  const [wasMobile, setWasMobile] = useState(isMobile);

  // Effects
  useEffect(() => {
    // Handle drawers auto closing on mobile
    if (isMobile && !wasMobile) {
      if (isFiltersDrawerOpen || isAssetsDrawerOpen) {
        setIsFiltersDrawerOpen(false);
        setIsAssetsDrawerOpen(false);
      }
    }

    // Handle drawers auto opening on desktop
    if (!isMobile && wasMobile) {
      if (!isFiltersDrawerOpen || !isAssetsDrawerOpen) {
        setIsFiltersDrawerOpen(true);
        setIsAssetsDrawerOpen(true);
      }
    }

    setWasMobile(isMobile);
  }, [
    isMobile,
    wasMobile,
    isFiltersDrawerOpen,
    isAssetsDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
  ]);

  // Handlers
  const onToggleAssetsDrawer = () => {
    if (isMobile && !isAssetsDrawerOpen) {
      setIsFiltersDrawerOpen(false);
    }
    setIsAssetsDrawerOpen(!isAssetsDrawerOpen);
  };

  const onToggleFiltersDrawer = () => {
    if (isMobile && !isFiltersDrawerOpen) {
      setIsAssetsDrawerOpen(false);
    }
    setIsFiltersDrawerOpen(!isFiltersDrawerOpen);
  };

  const currentView = getViewFromUrl(window.location.pathname);

  const commonArrowsStyles =
    "absolute top-[46%] h-[53px] w-[23px] flex flex-col justify-center text-black bg-white  hover:cursor-pointer ";
  // Hide all kind of drawers on invitations view
  if (currentView === "invitations") return children;

  return (
    <Box className="relative w-full h-full flex flex-1 overflow-hidden">
      <FormProvider {...methods}>
        <FiltersDrawer isOpen={isFiltersDrawerOpen} view={currentView} />
      </FormProvider>

      {/* Hide children on smallest screen and open drawer */}
      {isSmallerThanFilters && isFiltersDrawerOpen && view !== "map"
        ? null
        : children}

      {/* show Assets Drawer only on map view */}
      {(view === "map" || currentView === "geofences") && (
        <AssetsDrawer
          isOpen={isAssetsDrawerOpen}
          isGeofencesListVisible={isGeofencesListVisible}
        />
      )}

      {/* Filters Drawer Arrow Icon */}
      {!addGeofenceDrawerOpen && !isDrawingGeofence && (
        <Box
          className={
            commonArrowsStyles +
            " rounded-tl-none rounded-tr-lg rounded-br-lg rounded-bl-none"
          }
          onClick={onToggleFiltersDrawer}
          sx={{
            left: isFiltersDrawerOpen ? FILTERS_LIST_WIDTH : 0.5,
            boxShadow: "0 0 16px 0px var(--box-shadow)",
          }}
        >
          {isFiltersDrawerOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Box>
      )}

      {/* Assets Drawer Arrow Icon */}
      {(view === "map" || currentView === "geofences") && (
        <Box
          className={
            commonArrowsStyles +
            " rounded-tl-lg rounded-tr-none rounded-br-none rounded-bl-lg"
          }
          onClick={onToggleAssetsDrawer}
          sx={{
            right: isAssetsDrawerOpen ? ASSETS_LIST_WIDTH : 0.5,
          }}
        >
          {isAssetsDrawerOpen ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </Box>
      )}
    </Box>
  );
};

export default AssetsViewContainer;
