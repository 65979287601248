import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { QueryClient } from "@tanstack/react-query";
import { isEmpty, isNil, omitBy } from "lodash";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../constants";
import {
  DEVICE_DELETE_SUCCESS_PAYLOAD,
  DEVICE_DELETE_FAILED_PAYLOAD,
  DEVICE_UPDATE_FAILED_PAYLOAD,
  DEVICE_FORM_NO_CHANGES,
} from "../../constants/device";
import { FormModes } from "../../enums/formModes";
import {
  CreateDevicesInput,
  useDeleteDeviceMutation,
  CreateDevicesErrorMsg,
  DevicesTableData,
} from "../../graphql/operations";
import {
  TableColumnProps,
  TableGridColDef,
  getTableColumn,
} from "../../shared/components/Table";
import {
  arrayValueFormatter,
  associatedValueFormatter,
  columnTimezoneDateTimeFormatParams,
  DATE_FORMAT_GLUED,
  formatDate,
  imeiValueFormatter,
  parseFileContent,
  ParseFileContentError,
  transformers,
} from "../../utils";
import { BatchFormFieldsNames } from "../BatchesView/BatchManagementUtils";
import { InstallDevicesPayload } from "./InstallDevicesDialog/InstallDevicesDialog";

/**
 * Validate provided devices for required fields
 *
 * @returns Devices that passed validation successfully and an errors array with explanations why devices didn't pass validation
 */
export const validateAndMapDataForDevices = (
  devices: CreateDevicesInput[],
  { orgName, orgId }: { orgName: string; orgId: string }
) => {
  const errors: ParseFileContentError[] = [];
  let validationFailed = false;

  if (!devices.length) {
    validationFailed = true;
    errors.push({ row: 0, message: "Uploaded file is empty" });
  }

  const validatedDevices: CreateDevicesInput[] = devices.reduce<
    CreateDevicesInput[]
  >((filtered, device, currentIndex) => {
    const row = currentIndex + 1; // Because index starts with 0
    // Check for all device required fields
    device.org_name = orgName;
    device.customer_orgs_id = orgId;

    if (isEmpty(device.org_name?.toString())) {
      validationFailed = true;
      errors.push({ row, message: "Organization Name required" });
    }

    if (isEmpty(device.order_num?.toString())) {
      validationFailed = true;
      errors.push({ row, message: "Order Number required" });
    }
    if (device.order_num) {
      device.order_num = device.order_num.toString();
    }

    if (isEmpty(device.imei?.toString())) {
      validationFailed = true;
      errors.push({ row, message: "Device ID required" });
    } else {
      if (!Number.isInteger(Number(device.imei))) {
        validationFailed = true;
        errors.push({ row, message: "Device ID should be number" });
      }
      device.imei = device.imei.toString();
      if (device.imei.length < 14 || device.imei.length > 16) {
        validationFailed = true;
        errors.push({
          row,
          message: "Device ID should be min 14 and max 16 characters length",
        });
      }
    }
    if (!validationFailed) {
      filtered.push(device);
    }
    return filtered;
  }, []);

  return {
    validatedDevices,
    errors,
  };
};

export const parseDevicesFile = async (
  file: File
): Promise<{
  devices: CreateDevicesInput[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof CreateDevicesInput } = {
    "Device ID*": "imei",
    "Asset ID": "assets_id",
    "Order Group": "box_id",
    Tags: "tags",
    "Order Number*": "order_num",
    "SIM ID": "sim_num",
    "Packing List": "packing_list",
  };

  try {
    const { data, errors } = await parseFileContent<CreateDevicesInput>({
      file,
      map,
      dynamicTyping: false,
    });
    return { devices: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { devices: [], errors: [{ row: 0, message }] };
  }
};

export const parseInstallDevicesFile = async (
  file: File
): Promise<{
  fileData: InstallDevicesPayload[];
  errors: ParseFileContentError[];
}> => {
  const map: { [key: string]: keyof InstallDevicesPayload } = {
    "Device ID*": "imei",
    "Asset ID*": "asset_id",
    VIN: "vin",
  };

  try {
    const { data, errors } = await parseFileContent<InstallDevicesPayload>({
      file,
      map,
      dynamicTyping: false,
    });
    return { fileData: data, errors };
  } catch (error) {
    let message = "Error parsing file.";
    if (error instanceof Error) {
      message = error.message;
    }
    return { fileData: [], errors: [{ row: 0, message }] };
  }
};

export const getDuplicateDevicesErrors = (
  devices: CreateDevicesInput[],
  duplicateDevices: string[] = []
) => {
  const errors: { row: number; message: string }[] = [];
  duplicateDevices.forEach((imei) => {
    const index = devices?.findIndex(
      (a) => a.imei?.toString() === imei?.toString()
    );
    if (index > -1) {
      errors.push({ row: index + 1, message: "Device ID duplicate" });
    }
  });
  return errors;
};

export const getValidationDevicesErrors = (
  devices: CreateDevicesInput[],
  validationErrors: CreateDevicesErrorMsg[] = []
) => {
  const errors: { row: number; message: string }[] = [];
  validationErrors.forEach((err) => {
    const index = devices?.findIndex(
      (a) => a.imei?.toString() === err.imei?.toString()
    );
    if (index > -1) {
      errors.push({ row: index + 1, message: err.message });
    }
  });
  return errors;
};
export async function handleDeleteDevicesSuccess(
  queryClient: QueryClient,
  setIsDataLoading: Function,
  setDeviceToEdit: Function,
  refetchDeviceData: Function,
  refetchOrgData: Function,
  dispatch: Function
) {
  await handleDataInvalidation(queryClient, refetchDeviceData, refetchOrgData);
  setIsDataLoading(false);
  setDeviceToEdit({});
  dispatch({
    type: PAGE_SNACKBAR,
    payload: DEVICE_DELETE_SUCCESS_PAYLOAD,
  });
}

export function handleDeleteDevicesError(
  setIsDataLoading: Function,
  dispatch: Function,
  setDrawerState: Function,
  error: any,
  reqData: any
) {
  setDrawerState(false);
  setIsDataLoading(false);
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      ...DEVICE_DELETE_FAILED_PAYLOAD,
      text:
        error?.message === "ERROR_MISSING_DEVICE"
          ? `Device with Device ID ${reqData?.input?.imei} is missing in the database`
          : DEVICE_UPDATE_FAILED_PAYLOAD.text,
    },
  });
}

export function useDeleteDeviceHook(
  queryClient: QueryClient,
  setIsDataLoading: Function,
  setDeviceToEdit: Function,
  refetchDeviceData: Function,
  refetchOrgData: Function,
  dispatch: Function,
  setDrawerState: Function
) {
  return useDeleteDeviceMutation({
    onSuccess: async () => {
      await handleDeleteDevicesSuccess(
        queryClient,
        setIsDataLoading,
        setDeviceToEdit,
        refetchDeviceData,
        refetchOrgData,
        dispatch
      );
    },
    onError: (error: any, reqData: any) => {
      handleDeleteDevicesError(
        setIsDataLoading,
        dispatch,
        setDrawerState,
        error,
        reqData
      );
    },
  });
}

/**
 * Function validates & prepares payload for update/create of device, mainly created as utility for easier unit testing/coverage
 * @param componentFns utility fns from the parent component
 */
export function validateAndSubmitFormAction(
  data: any,
  dirtyFields: any,
  componentFns: {
    setIsDataLoading: Function;
    setDrawerState: Function;
    createDeviceMutate: Function;
    updateDeviceMutate: Function;
    dispatch: Function;
  },
  formMode: FormModes
) {
  if (Object.keys(dirtyFields).length === 0) {
    componentFns.dispatch({
      type: PAGE_SNACKBAR,
      payload: DEVICE_FORM_NO_CHANGES,
    });
    return;
  }
  componentFns.setIsDataLoading(true);
  componentFns.setDrawerState(false);

  const payload: any = {};
  for (const field in dirtyFields) {
    payload[field] = data[field];
    switch (field) {
      case "assets_id":
        payload["assets_id"] = data?.assets_id || null;
        payload["asset_name"] = data?.asset_name || null;
        break;
      case "customer_orgs_id":
        payload["customer_orgs_id"] = data?.customer_orgs_id;
        payload["org_name"] = data?.org_name;
        break;
      case "tags":
        if (data?.tags?.length > 0) {
          payload["tags"] = data?.tags?.split(",");
        } else {
          payload["tags"] = [];
        }
        break;
    }
  }

  if (formMode === FormModes.create) {
    componentFns.createDeviceMutate({ input: payload });
  } else if (formMode === FormModes.edit) {
    payload["_id"] = data._id;
    componentFns.updateDeviceMutate({ input: payload });
  }
}

export const columnVisibilityModel = {
  org_name: true,
  imei: true,
  asset_name: true,
  box_id: true,
  tags: true,
  added_date: true,
  updated_date: true,
  assets_id: true,
  firmwareVersion: true,
  config: true,
  order_num: true,
  sim_id: true,
  packing_list: true,
  install_dt: true,
  installer: true,
};

export const getDeviceColumns = (
  timezone: string
): TableGridColDef<DevicesTableData>[] => {
  const columns: TableColumnProps<DevicesTableData>[] = [
    {
      field: "org_name",
      headerName: "Organization Name",
      options: {
        filterable: false,
      },
    },
    {
      field: "imei",
      headerName: "Device ID",
      options: {
        flex: 1,
        valueFormatter: imeiValueFormatter,
      },
    },
    { field: "asset_name", headerName: "Asset ID" },
    { field: "box_id", headerName: "Order Group" },
    {
      field: "tags",
      headerName: "Device Tags",
      options: {
        valueFormatter: arrayValueFormatter,
      },
    },
    {
      field: "added_date",
      headerName: "Created Date",
      type: "date",
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "updated_date",
      headerName: "Updated Date",
      type: "date",
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    {
      field: "assets_id",
      headerName: "Associated",
      options: {
        valueFormatter: associatedValueFormatter,
      },
    },
    {
      field: "firmwareVersion",
      headerName: "Firmware Version",
    },
    { field: "order_num", headerName: "Order #" },
    { field: "sim_num", headerName: "SIM ID" },
    { field: "packing_list", headerName: "Packing List" },
    {
      field: "install_dt",
      headerName: "Installation Date",
      type: "date",
      options: {
        ...columnTimezoneDateTimeFormatParams(timezone),
      },
    },
    { field: "installer", headerName: "Installer" },
  ];

  return columns.map(getTableColumn);
};

export const handleDeviceExport = (
  gridApiRef: React.MutableRefObject<GridApiPremium>,
  fileName: string
) => {
  gridApiRef.current.setSelectionModel([]);
  gridApiRef.current.exportDataAsExcel({ fileName });
};

export const devicesUploadFormSchema = yup.object().shape({
  [BatchFormFieldsNames.BatchName]: yup
    .string()
    .required("Batch Name is required")
    .transform(transformers.string),
  [BatchFormFieldsNames.AddToOrganization]: yup
    .object()
    .required("Company Name is required")
    .transform(transformers.string),
});

export const useDeviceBatchUploadForm = (action: string, orgName?: string) => {
  const batch_name = orgName
    ? `${orgName} ${action} - ${formatDate(new Date(), DATE_FORMAT_GLUED)}`
    : "";
  const form = useForm({
    resolver: yupResolver(devicesUploadFormSchema),
    values: omitBy(
      {
        [BatchFormFieldsNames.BatchName]: batch_name,
        [BatchFormFieldsNames.AddToOrganization]: "",
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => devicesUploadFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues, devicesUploadFormSchema };
};

export const handleDataInvalidation = async (
  queryClient: QueryClient,
  refetchDeviceData: Function,
  refetchOrgData: Function
): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      queryClient.invalidateQueries(["getDevicesTableData"]).then(() => {
        refetchDeviceData();
        refetchOrgData();
        resolve();
      });
    }, 3500);
  }).catch((error) => {
    console.error("Error invalidating data:", error);
  });
