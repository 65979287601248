import { createFilterOptions } from "@mui/material";
import isString from "lodash/isString";
import startCase from "lodash/startCase";
import {
  DetentionRuleClockStarts,
  DetentionRuleDayOfDrop,
} from "../../../../../../graphql/operations";
import { Option } from "../../configurationsUtils";
import { CompanyOption } from "./interfaces";

export const DEFAULT_VALUES = {
  name: "",
  company: undefined,
  geofenceType: undefined,
  geofenceSubType: undefined,
  geofenceName: undefined,
  priority: 0,
  clockStarts: DetentionRuleClockStarts.DayOfDrop,
  dayOfDrop: DetentionRuleDayOfDrop.EntryTime,
  endOnEmpty: undefined,
  endOnLoaded: undefined,
};

export const BREAKPOINTS = { xs: 12 };

export const filter = createFilterOptions<CompanyOption>();

export const checkOptionEquality = (
  option: Option,
  value: Option | string | null | undefined
) =>
  isString(value)
    ? option?.value.toString() === value.toString()
    : option?.value.toString() === value?.value.toString();

export const getOptionLabelEnum = (option: string | Option) => {
  // Value selected with enter, right from the input
  if (isString(option))
    return startCase(option.replaceAll("_", " ").toLowerCase());

  // Regular option
  return startCase(option.label.replaceAll("_", " ").toLowerCase());
};
