export default {
  firstBucket: {
    value: "0%",
    title: "0-30k",
    subTitle: "mi",
  },
  secondBucket: {
    value: "0%",
    title: "30-60k",
    subTitle: "mi",
  },
  thirdBucket: {
    value: "0%",
    title: "60k+",
    subTitle: "mi",
  },
};
