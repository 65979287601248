import { FC, memo, MutableRefObject, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  TableViewType,
} from "../../../../../graphql/operations";
import { getRows, Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../constants";
import {
  columnVisibilityModel,
  atisTableColumns,
  ProfilesData,
  atisTableKeyList,
  mapAtisSensorProfileData,
  MappedSensorProfile,
} from "../profileUtils";

export interface AtisProfileTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  profilesData: ProfilesData;
}

const AirTankProfileTable: FC<AtisProfileTableProps> = (
  props: AtisProfileTableProps
) => {
  const { onRowClick, apiRef, profilesData, tableType } = props;

  const {
    state: { appConfig },
  } = useAppContext();

  const [tableRows, setTableRows] = useState<SensorProfileResult[]>([]);

  const fileName = useExportedFileName("Atis-Profiles");
  useEffect(() => {
    const mappedProfiles = mapAtisSensorProfileData(profilesData.data ?? []);
    setTableRows(
      getRows<MappedSensorProfile[]>(
        mappedProfiles,
        atisTableColumns,
        "",
        "",
        "_id"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesData]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={atisTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        loading={profilesData.isFetchingProfile}
        error={profilesData.isErrorProfile}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={atisTableKeyList}
        sortKeys={atisTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="atis-profiles"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(AirTankProfileTable);
