import {
  ConfigurationSetType,
  GeofenceTypeData,
  Maybe,
  useGetConfigurationSetsQuery,
} from "../../../../graphql/operations";

interface UseGetTypeOptionsProps {
  watchOrgName: Maybe<string> | undefined;
}

export const useGetTypeOptions = ({ watchOrgName }: UseGetTypeOptionsProps) => {
  const { data: dataConfigurations, isFetching: isConfigurationFetching } =
    useGetConfigurationSetsQuery(
      {
        input: {
          orgId: watchOrgName ?? "",
          type: ConfigurationSetType?.GeofenceType,
        },
      },
      {
        enabled: !!watchOrgName,
      }
    );

  const configsList = dataConfigurations?.getConfigurationSets ?? [];

  const mapTypesToOptions = (data: GeofenceTypeData[]) => {
    return data
      .map((item) => ({
        id: item._id,
        label: item.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const geofenceTypesOpts = mapTypesToOptions(
    configsList as GeofenceTypeData[]
  );

  const getSubTypeOptions = (typeId?: string | null) => {
    if (!typeId) return [];

    const childTypes = configsList.filter(
      (configItem) => configItem.parentId === typeId
    );
    return mapTypesToOptions(childTypes as GeofenceTypeData[]);
  };

  return {
    geofenceTypesOpts,
    isConfigurationFetching,
    getSubTypeOptions,
  };
};
