import {
  LiteSentryGammaSensorConfigInput,
  LiteSentryGammaProfileSensorInput,
} from "../../../../../../graphql/operations";

export const convertLiteSentryGammaConfigToSensorInput = (
  config: LiteSentryGammaSensorConfigInput & {
    [key: string]: any;
  }
): LiteSentryGammaProfileSensorInput => {
  const liteSentryGammaSensorConfigInput = { ...config };
  for (const key in liteSentryGammaSensorConfigInput) {
    liteSentryGammaSensorConfigInput[key].thresholds =
      liteSentryGammaSensorConfigInput[key].match.thresholds;
    delete liteSentryGammaSensorConfigInput[key].match;
  }
  return liteSentryGammaSensorConfigInput as LiteSentryGammaProfileSensorInput;
};
