import { FC } from "react";
import { Grid } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { ReactComponent as AirbagSensorDark } from "../../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../../../assets/svgs/airbagSensorLight.svg";
import { useAppContext } from "../../../../../../../context/AppContext";
import {
  AirBagSensorDetails,
  AirBagSensorMeasures,
  PressureUnit,
} from "../../../../../../../graphql/operations";
import SensorHeader from "../../../../../../../shared/components/SensorHeader/SensorHeader";
import { Table } from "../../../../../../../shared/components/Table";
import { useDateInPreferredTimezone } from "../../../../../../../shared/hooks/useDateInPreferredTimezone";
import { useGetPressureUnitPreference } from "../../../../../../../shared/hooks/useGetPressureUnitPreference";
import { axleLabels } from "../AirBagStatusCards";
import { SEARCH_KEYS, columnVisibilityModel, columns } from "../columns";
import { getAirBagPositionLabel } from "../helpers";

interface AirBagStatusTableProps {
  airbag?: AirBagSensorDetails | null;
}

const AirBagTable: FC<AirBagStatusTableProps> = ({ airbag }) => {
  const pressureUnit = useGetPressureUnitPreference();

  const measures: (AirBagSensorMeasures | null)[] | undefined = (airbag?.data
    ?.measures ?? []) as (AirBagSensorMeasures | null)[];

  const timezoneDate = useDateInPreferredTimezone(
    airbag?.lastReported,
    "MM/dd/yy h:mm a"
  );

  const gridApiRef = useGridApiRef();
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  if (measures) {
    // Filter out null values to ensure only AirBagSensorMeasures are passed
    const nonNullMeasures = measures.filter(
      (measure): measure is AirBagSensorMeasures => measure !== null
    );

    // Function to create row data from measure
    const createRowData = (measure: AirBagSensorMeasures, index: number) => {
      return {
        id: index,
        tire: `${axleLabels.get(index)} - ${getAirBagPositionLabel(
          measure.location_code_hex_str
        )}`,
        pressure:
          pressureUnit === PressureUnit.Psi
            ? measure.tire_pressure_psi
            : measure.tire_pressure_mbar,
        status: measure?.statusTire_pressure_mbar,
        lastUpdated: timezoneDate,
      };
    };

    const tables = [];
    for (let i = 0; i < nonNullMeasures.length; i += 2) {
      const rows = [createRowData(nonNullMeasures[i], i)];

      if (i + 1 < nonNullMeasures.length) {
        rows.push(createRowData(nonNullMeasures[i + 1], i + 1));
      }

      const tableKey = `table-${i}`;

      const tableStructure = (
        <Grid
          className="mb-10"
          key={tableKey}
          data-testid={`airbag-table-grid-${i}`}
        >
          <Grid item xs={6}>
            <SensorHeader
              title={axleLabels.get(i) ?? "No data"}
              secondTitle="Air Bags"
              noBorder
            >
              {isLightTheme ? (
                <AirbagSensorDark style={svgIconSettings} />
              ) : (
                <AirbagSensorLight style={svgIconSettings} />
              )}
            </SensorHeader>
          </Grid>
          <Table
            tableName="airbag"
            columns={columns}
            rows={rows}
            searchKeys={SEARCH_KEYS}
            tableHeight={"160px"}
            tableMinHeight={"160px"}
            apiRef={gridApiRef}
            pagination={false}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              sorting: {
                sortModel: [{ field: "lastUpdated", sort: "desc" }],
              },
            }}
          />
        </Grid>
      );

      tables.push(tableStructure);
    }

    return <>{tables}</>;
  } else {
    return null;
  }
};

export default AirBagTable;
