import { memo, ReactElement } from "react";
import { useDrag, useDrop, DropTargetHookSpec } from "react-dnd";
import { Card, CardContent, Grid } from "@mui/material";
import { ItemTypes } from "../constants";
import { Tile } from "../interfaces";

export interface DashboardTileProps {
  url: string;
  clickHandler: (url: string) => void;
  moveTile: (id: string, atIndex: number) => void;
  findTile: (id: string) => { tile: Tile; index: number };
  children: ReactElement;
}

const DashboardTile: React.FC<DashboardTileProps> = ({
  clickHandler,
  url,
  moveTile,
  findTile,
  children,
}) => {
  const originalIndex = findTile(url)?.index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.TILE,
      item: { url, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { url: droppedId, originalIndex } = item;
        if (!monitor.didDrop()) {
          moveTile(droppedId, originalIndex);
        }
      },
    }),
    [url, originalIndex, moveTile]
  );
  const [, drop] = useDrop(
    () =>
      ({
        accept: ItemTypes.TILE,
        hover({ url: draggedId }) {
          if (draggedId !== url) {
            const { index: overIndex } = findTile(url);
            moveTile(draggedId, overIndex);
          }
        },
      } as DropTargetHookSpec<unknown, unknown, unknown>),
    [findTile, moveTile]
  );
  return (
    <Grid item className="text-center">
      <Card
        data-testid="dashboard-tile-component"
        elevation={0}
        onClick={() => clickHandler(url)}
        className={`flex min-h-[14rem] w-72 cursor-pointer items-center justify-center border border-primary p-3.5 hover:!opacity-70 sm:w-96 md:p-0 ${
          isDragging ? "opacity-0" : "opacity-100"
        }`}
        ref={(node) => drag(drop(node))}
      >
        <CardContent className="!p-0">{children}</CardContent>
      </Card>
    </Grid>
  );
};

export default memo(DashboardTile);
