import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  Scatter,
  YAxis,
  CartesianGrid,
  XAxis,
  ScatterChart,
} from "recharts";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import { formatDate, getStartOfDay } from "../../../../../../../utils/date";
import { chartTickStyle } from "../../../SummaryTabPanel/Charts/chartUtils";
import {
  BRAKE_LIGHT_SCENARIO_COLORS,
  BrakeLightScenarioTitlesMap,
  LeftTurnLightScenarioTitlesMap,
  LicenseLightScenarioTitlesMap,
  LightsMappedValues,
  LITE_SENTRY_LIGHT_TYPES,
  MarkerLightScenarioTitlesMap,
  OtherLightScenarioColors,
  RightTurnLightScenarioTitlesMap,
} from "./helpers";

export interface LightsChartSectionProps {
  lightSensorType: LITE_SENTRY_LIGHT_TYPES;
  yAxisText: string;
  hiddenDots: number[];
  data: LightsMappedValues[];
  xAxisdomain: number[] | string[];
}

const chartContainerHeight = 530;
const scatterFilter = `drop-shadow(1px 2px 1px ${ColorsPalette.RichBlackOpacity16})`;

const groupByDay = (data: LightsMappedValues[]) => {
  return data.reduce((acc, item) => {
    const day = getStartOfDay(new Date(item.date)).getTime();
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {} as { [key: number]: LightsMappedValues[] });
};

const createScatterComponents = (
  lightSensorType: LITE_SENTRY_LIGHT_TYPES,
  hiddenDots: number[],
  groupedData: { [key: number]: LightsMappedValues[] },
  titlesMap: Map<number, string>,
  colorsMap: Map<number, string>
) => {
  return Array.from(titlesMap.keys()).flatMap((value) =>
    hiddenDots.includes(value)
      ? []
      : Object.keys(groupedData).map((day) => (
          <Scatter
            key={`${value}-${day}`}
            yAxisId="yaxis"
            strokeWidth={2}
            line
            stroke={ColorsPalette.BrightWhite}
            filter={scatterFilter}
            fill={colorsMap.get(value)}
            data={groupedData[parseInt(day)]
              .map((d) => ({
                date: d.date,
                value: d[value]?.value,
              }))
              .filter((d) => d.value !== undefined)}
          />
        ))
  );
};

export const LightsChartScatterSection: React.FC<LightsChartSectionProps> = ({
  lightSensorType,
  hiddenDots,
  data,
  xAxisdomain,
  yAxisText,
}) => {
  const formatXAxis = (tickItem: string) => {
    return formatDate(new Date(tickItem), "MM/dd");
  };

  const groupedData = groupByDay(data);

  return (
    <>
      <Box
        className="relative !text-xs !font-medium"
        data-testid="lights-chart-section-container"
        style={{ height: chartContainerHeight }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
            <CartesianGrid vertical />
            <XAxis
              dataKey="date"
              tickFormatter={formatXAxis}
              scale="time"
              minTickGap={15}
              axisLine={false}
              tickLine={false}
              type="number"
              domain={xAxisdomain}
            />
            <YAxis
              yAxisId="yaxis"
              type={"number"}
              dataKey={"value"}
              axisLine={false}
              tickLine={false}
              tick={chartTickStyle}
              label={{
                value: yAxisText,
                angle: -90,
                style: { ...chartTickStyle },
                position: "insideLeft",
              }}
            />

            {lightSensorType === LITE_SENTRY_LIGHT_TYPES.BrakeLight &&
              createScatterComponents(
                lightSensorType,
                hiddenDots,
                groupedData,
                BrakeLightScenarioTitlesMap,
                BRAKE_LIGHT_SCENARIO_COLORS
              )}

            {lightSensorType === LITE_SENTRY_LIGHT_TYPES.LeftTurnSignal &&
              createScatterComponents(
                lightSensorType,
                hiddenDots,
                groupedData,
                LeftTurnLightScenarioTitlesMap,
                OtherLightScenarioColors
              )}

            {lightSensorType === LITE_SENTRY_LIGHT_TYPES.RightTurnSignal &&
              createScatterComponents(
                lightSensorType,
                hiddenDots,
                groupedData,
                RightTurnLightScenarioTitlesMap,
                OtherLightScenarioColors
              )}

            {lightSensorType === LITE_SENTRY_LIGHT_TYPES.License &&
              createScatterComponents(
                lightSensorType,
                hiddenDots,
                groupedData,
                LicenseLightScenarioTitlesMap,
                OtherLightScenarioColors
              )}

            {lightSensorType === LITE_SENTRY_LIGHT_TYPES.Marker &&
              createScatterComponents(
                lightSensorType,
                hiddenDots,
                groupedData,
                MarkerLightScenarioTitlesMap,
                OtherLightScenarioColors
              )}
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};
