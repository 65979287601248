import { Box, Typography } from "@mui/material";
import { TooltipProps } from "recharts";

export interface RegulatorTooltipProps {
  payload?: TooltipProps<string, string>["payload"];
}

export const RegulatorChartTooltip: React.FC<RegulatorTooltipProps> = ({
  payload,
}) => {
  if (!payload?.length) {
    return null;
  }

  const { tooltipDate } = payload[0].payload;
  const { min, max } = payload[0].payload;

  return (
    <Box
      className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3"
      data-testid="regulator-chart-tooltip-container"
    >
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 400,
          color: "var(--typography-tooltip)",
          marginBottom: "5px",
        }}
      >
        {tooltipDate}
      </Typography>

      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 600,
          color: "var(--primary-blue)",
          marginBottom: "5px",
        }}
      >
        Regulator
      </Typography>

      <Box
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 400,
          color: "var(--typography-tooltip)",
          marginBottom: "5px",
        }}
      >
        Pressure:
        <Typography
          sx={{
            fontWeight: 700,
            marginLeft: "4px",
            display: "inline-block",
          }}
        >
          <span>{`${min}PSI`}</span> - <span>{`${max}PSI`}</span>
        </Typography>
      </Box>
    </Box>
  );
};
