import { FC, ReactElement, useCallback, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box } from "@mui/system";

interface AccordionWrapperProps {
  showDetailsHeader: boolean;
  accordionSummaryTitle?: string;
  children: ReactElement;
  classNames?: string;
}

export const AccordionWrapper: FC<AccordionWrapperProps> = ({
  showDetailsHeader,
  accordionSummaryTitle,
  children,
  classNames = "",
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const handleAccordionChange = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded);
  }, [isAccordionExpanded, setIsAccordionExpanded]);

  return (
    <>
      {showDetailsHeader ? (
        <Accordion
          className="w-full"
          expanded={isAccordionExpanded}
          onChange={handleAccordionChange}
          data-testid="asset-settings-accordion"
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            className="assetSettingsAccordionSummaryTitleContainer"
            classes={{ content: "assetSettingsAccordionSummaryTitle" }}
            data-testid="asset-settings-accordion-content"
          >
            {accordionSummaryTitle ?? ""}
          </AccordionSummary>
          <AccordionDetails className={classNames ?? ""}>
            {children}
          </AccordionDetails>
        </Accordion>
      ) : (
        children
      )}
    </>
  );
};
