import { MutableRefObject } from "react";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid-premium";
import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { cloneDeep, isEmpty } from "lodash";
import * as yup from "yup";
import {
  Brand,
  CreateBrandInput,
  S3UploadObject,
  UpdateBrandInput,
} from "../../../../graphql/operations";
import ColorPreviewComponent from "../../../../shared/components/ColorPreview/ColorPreview.component";
import { isValidHexColor } from "../../../../shared/components/ColorPreview/ColorPreviewUtils";
import {
  parsePhoneNumber,
  shouldNoteHaveTrimSpaces,
  validatePhoneNumber,
} from "../../../../utils";

export enum BrandsDrawerState {
  edit = "edit",
  create = "create",
}

export type s3FilesUploadType = {
  logo: S3UploadObject;
  eulaDocument: S3UploadObject;
  backgroundImage: S3UploadObject;
};

export type uploadedFiles = {
  logo?: File;
  backgroundImage?: File;
  eulaDocument?: File;
};

export const BRAND_DELETE_SUCCESS_PAYLOAD = {
  title: "Brand deleted Successfully!",
  text: "Brand is now deleted!",
  severity: "success",
};
export const BRAND_DELETE_FAILED_PAYLOAD = {
  title: "Brand delete Failed!",
  text: "Brand delete Failed, Please try again.",
  severity: "error",
};
export const BRAND_UPDATE_SUCCESS_PAYLOAD = {
  title: "Brand updated Successfully.",
  text: "You can find the updated brand in the table",
  severity: "success",
};
export const BRAND_UPDATE_FAILED_PAYLOAD = {
  title: "Brand update Failed, Please try again.",
  text: "",
  severity: "error",
};
export const BRAND_CREATE_SUCCESS_PAYLOAD = {
  title: "Brand created Successfully.",
  text: "It is available in the table",
  severity: "success",
};
export const BRAND_CREATE_FAILED_PAYLOAD = {
  title: "Brand create Failed, Please try again.",
  text: "",
  severity: "error",
};
export const BRAND_FILES_UPLOAD_FAILURE = {
  title: "Unable to upload brand files!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const BRAND_COLUMNS: GridColDef<Brand>[] = [
  {
    field: "name",
    headerName: "Brand Name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "color",
    headerName: "Brand Color",
    renderCell: (params) => <ColorPreviewComponent hexColor={params.value} />,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "supportEmail",
    headerName: "Support Email",
    renderCell: (params) => params.value ?? "",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "supportPhoneNumber",
    headerName: "Support Phone Number",
    renderCell: (params) => params.value ?? "",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "linkStolen",
    headerName: "Report Stolen Link",
    renderCell: (params) => params.value ?? "",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "linkGooglePlay",
    headerName: "Link to Google Play",
    renderCell: (params) => params.value ?? "",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "linkAppleStore",
    headerName: "Link to Apple Store",
    renderCell: (params) => params.value ?? "",
    flex: 1,
    minWidth: 120,
  },
];

export const BRAND_PAGE_SIZE = 100;

export const BRAND_VISIBLE_COLUMNS = {
  name: true,
  color: true,
  supportEmail: true,
  supportPhoneNumber: true,
  linkStolen: true,
  linkGooglePlay: true,
  linkAppleStore: true,
};

export const SEARCH_KEYS = [
  "name",
  "color",
  "supportEmail",
  "supportPhoneNumber",
  "linkStolen",
  "linkGooglePlay",
  "linkAppleStore",
];

export const prepareBrandFilters = (filters: GridFilterModel) => {
  const copyOfFilters = cloneDeep(filters);
  copyOfFilters.items = copyOfFilters.items.map((item) => {
    item.value = item.value ? JSON.stringify(item.value) : item.value;
    return item;
  });
  return copyOfFilters;
};

export const BRAND_FORM_FIELDS = [
  {
    name: "name",
    type: "text",
    label: "Brand Name",
    dataTestid: "input-brand-name",
    required: true,
  },
  {
    name: "color",
    type: "colorInput",
    label: "Brand Color",
    dataTestid: "input-color-element",
    required: true,
  },
  {
    name: "supportEmail",
    type: "email",
    label: "Support Email",
    dataTestid: "input-support-email",
    required: true,
  },
  {
    name: "supportPhoneNumber",
    type: "phoneNumber",
    label: "Support Phone Number",
    dataTestid: "input-support-phone-num",
    required: false,
  },
  {
    name: "linkStolen",
    type: "text",
    label: "Report Stolen Link",
    dataTestid: "input-link-stolen-link",
    required: false,
  },
  {
    name: "linkGooglePlay",
    type: "text",
    label: "Link to Google Play",
    dataTestid: "input-link-google",
    required: false,
  },
  {
    name: "linkAppleStore",
    type: "text",
    label: "Link to Apple Store",
    dataTestid: "input-link-apple",
    required: false,
  },
];

yup.addMethod(yup.mixed, "shouldNoteHaveTrimSpaces", shouldNoteHaveTrimSpaces);

export const brandSchema = {
  name: yup.string().required("Field is required!").shouldNoteHaveTrimSpaces(),
  color: yup
    .string()
    .required("Field is required!")
    .test(
      "isValidHexColor",
      "Please provide a valid Hex Color",
      (value: string | undefined) => Boolean(value && isValidHexColor(value))
    )
    .shouldNoteHaveTrimSpaces(),
  supportEmail: yup.string().email().required("Field is required!"),
  supportPhoneNumber: yup
    .string()
    .notRequired()
    .nullable()
    .test(
      "isValidPhoneNumber",
      "Phone number must be 10 digits",
      (input: string | undefined | null) => {
        const number = parsePhoneNumber(input as string);
        return isEmpty(number) ? true : validatePhoneNumber(number) !== null;
      }
    ),
  linkStolen: yup
    .string()
    .notRequired()
    .nullable()
    .url("Not a valid url!")
    .trim(),
  linkGooglePlay: yup
    .string()
    .notRequired()
    .nullable()
    .url("Not a valid url!")
    .trim(),
  linkAppleStore: yup
    .string()
    .notRequired()
    .nullable()
    .url("Not a valid url!")
    .trim(),
};

/**
 * Transfer uploaded files to s3 & return all files with correct metadata for DB save
 */
export const uploadAndPrepareBrandFiles = async (
  files: uploadedFiles,
  s3UploadObjects: s3FilesUploadType
) => {
  const uploadedFiles: Record<string, any> = {};

  for (const property of Object.keys(files)) {
    const key = property as keyof uploadedFiles;
    if (files[key] && s3UploadObjects[key]?.uploadUrl) {
      await uploadBrandFile(
        s3UploadObjects[key].uploadUrl as string,
        files[key] as File
      );
      uploadedFiles[key] = {
        bucket: s3UploadObjects[key].bucket,
        key: s3UploadObjects[key].key,
        region: s3UploadObjects[key].region,
        file_name: files[key]?.name,
        content_type: files[key]?.type,
      };
    }
  }

  return uploadedFiles;
};

export const uploadBrandFile = async (
  s3Url: string,
  file: File
): Promise<AxiosResponse> => {
  const config = {
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": file?.type,
    },
  };
  return axios.put(s3Url, file, config);
};

/**
 * Download file from S3 with signed url, as blob
 */
export const downloadBrandFile = (s3Url: string, fileType: string) => {
  const config: AxiosRequestConfig<any> = {
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": fileType,
    },
    responseType: "blob",
  };
  return axios.get(s3Url, config);
};

/**
 * Creates Url for browser to render image so it can be embeded in img
 */
export const prepareFileDataForPreview = (
  fileData: any,
  setFilePreview: (value: any) => void
) => {
  const objectUrl = URL.createObjectURL(fileData);
  setFilePreview(objectUrl);

  // Remove from memory & unlink ref usage
  return () => URL.revokeObjectURL(objectUrl);
};

/**
 * Simple functionality for download on click
 * FileUrl has to be correctly created with URL.createObjectURL
 */
export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link?.parentNode?.removeChild(link);
};

type onSubmitProps = {
  data: any;
  trigger: Function;
  s3UploadUrls: s3FilesUploadType | undefined;
  dispatchUploadFailure: Function;
  logo: any;
  eulaDocument: any;
  backgroundImage: any;
  setMissingFileErrors: Function;
  missingFilesErrors: any;
};

/**
 * Prepares data for either update or create & executes the wanted mutation
 */
export const handleMutationData = (
  data: any,
  drawerState: any,
  brandId: string = "",
  createBrandMutation: Function,
  dirtyFields: any,
  updateBrandMutation: Function
) => {
  // dispatch post api call to create brand with payload as formValues

  if (drawerState === BrandsDrawerState.create) {
    createBrandMutation({
      input: data as CreateBrandInput,
    });
  }
  if (drawerState === BrandsDrawerState.edit) {
    const updatePayload: Record<string, any> = {
      _id: brandId,
      files: data.files,
    };
    for (let updatedField in dirtyFields) {
      updatePayload[updatedField] = data[updatedField];
    }
    updateBrandMutation({ input: updatePayload as UpdateBrandInput });
  }
};

export const onSubmit = async ({
  data,
  trigger,
  s3UploadUrls,
  dispatchUploadFailure,
  logo,
  eulaDocument,
  backgroundImage,
  setMissingFileErrors,
  missingFilesErrors,
}: onSubmitProps) => {
  const valid = await trigger();
  if (!valid) return;

  // Throw error if s3 urls are missing
  if (
    !s3UploadUrls?.logo?.uploadUrl ||
    !s3UploadUrls?.eulaDocument?.uploadUrl ||
    !s3UploadUrls?.backgroundImage?.uploadUrl
  ) {
    dispatchUploadFailure();
    return null;
  }

  if (!logo) {
    setMissingFileErrors({ ...missingFilesErrors, logo: true });
    return null;
  }
  if (!eulaDocument) {
    setMissingFileErrors({ ...missingFilesErrors, pdf: true });
    return null;
  }
  if (!backgroundImage) {
    setMissingFileErrors({ ...missingFilesErrors, image: true });
    return null;
  }
  // The bucket conditions uploads files that are not already in s3
  // Makes sure update doesn't trigger file update everytime
  const files = await uploadAndPrepareBrandFiles(
    {
      ...(!logo?.bucket && { logo }),
      ...(!eulaDocument?.bucket && { eulaDocument }),
      ...(!backgroundImage?.bucket && { backgroundImage }),
    },
    s3UploadUrls as s3FilesUploadType
  );

  return {
    ...data,
    ...(Object.values(files)?.length && { files }),
  };
};

export const handleRefresh = (
  queryClient: QueryClient,
  refreshBrands: MutableRefObject<any>
) => {
  queryClient.invalidateQueries({ queryKey: ["getBrands"] });
  queryClient.invalidateQueries({ queryKey: ["getBrandFiles"] });
  refreshBrands?.current();
};

export const setSelectedBrand = (
  data: any,
  setBrandToEdit: Function,
  setFormMode: Function,
  setDrawerOpened: Function
) => {
  if (data?.row) {
    setBrandToEdit(data.row);

    setFormMode(BrandsDrawerState.edit);
    setDrawerOpened(true);
  }
};
