import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/material";
import { GridRowGroupingModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  TableViewType,
  useGetDwellAssetsHistoricalDataQuery,
} from "../../../../../graphql/operations";
import { Table } from "../../../../../shared/components/Table";
import { Row } from "../../../../../shared/components/Table/Table";
import { TableGridColDef } from "../../../../../shared/components/Table/types";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { resolvePromiseSafely } from "../../../../../utils";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../AdminPanel/tabs/constants";
import { getColumns } from "../../Shared/DwellTap/columns";
import { columnVisibilityModel } from "./columns";

const SEARCH_KEYS = [
  "asset_id",
  "timestamp",
  "arrival_cargo_state",
  "arrival_time",
  "departure_cargo_state",
  "departure_time",
  "geofence_id",
  "geofence_name",
  "imei",
  "totalDwelling",
];

type GeofenceDwellHistoryProps = {
  geofenceId: string;
};

export const GeofenceDwellHistory: FC<GeofenceDwellHistoryProps> = memo(
  ({ geofenceId }) => {
    const {
      state: { appConfig },
    } = useAppContext();
    const gridApiRef = useGridApiRef();
    const userTimezone = usePreferredTimezone();
    const fileName = useExportedFileName("Dwell");

    const [dwellHistoryPagination, setDwellHistoryPagination] =
      useState<number>(0);
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);

    const rowsPerPage = appConfig.table.geofenceDwellHistoryPerPage;

    const {
      data,
      isLoading: isDwellDataLoading,
      isRefetching: isDwellDataRefetching,
      isSuccess: isDwellDataSuccess,
    } = useGetDwellAssetsHistoricalDataQuery({
      input: {
        geofenceId,
      },
    });

    const dwellHistory = useMemo(() => {
      const rawData = data?.searchHistoricalEventHistory?.data ?? [];
      return rawData
        .filter((item) => item?.dwellAssets !== null)
        .map((item) => ({
          _id: item!.dwellAssets!.uuid,
          id: item!.dwellAssets!.uuid,
          uuid: item!.dwellAssets!.uuid,
          timestamp: item!.dwellAssets!.timestamp,
          arrival_cargo_state: item!.dwellAssets!.arrival_cargo_state,
          arrival_time: item!.dwellAssets!.arrival_time,
          asset_id: item!.dwellAssets!.asset_id,
          departure_cargo_state: item!.dwellAssets!.departure_cargo_state,
          departure_time: item!.dwellAssets!.departure_time,
          geofence_id: item!.dwellAssets!.geofence_id,
          geofence_name: item!.dwellAssets!.geofence_name,
          imei: item!.dwellAssets!.imei,
          total_dwell: item!.dwellAssets!.total_dwell,
          unload_duration: item!.dwellAssets!.unload_duration,
        })) as Row[];
    }, [data]);

    const rowCount = dwellHistory.length;
    const [rowCountState, setRowCountState] = useState<number>(rowCount);

    useEffect(() => {
      setRowCountState((prevRowCountState: number) =>
        !isDwellDataLoading ? rowCount : prevRowCountState
      );
    }, [rowCount, setRowCountState, isDwellDataLoading]);

    const onFilterChange = () => {
      setCurrentPageNo(1);
      setDwellHistoryPagination(0);
    };

    const onPageChange = useCallback(
      (page: number) => {
        setCurrentPageNo(page);
        setDwellHistoryPagination(rowsPerPage * (page - 1));
      },
      [rowsPerPage]
    );

    const isTableDataLoading = isDwellDataLoading || isDwellDataRefetching;

    const columns = useMemo(
      () => getColumns(userTimezone, "geofence"),
      [userTimezone]
    );

    const [rowGroupingModel, setRowGroupingModel] =
      useState<GridRowGroupingModel>();

    const exportHandler = () => {
      gridApiRef.current.setSelectionModel([]);
      resolvePromiseSafely(gridApiRef.current.exportDataAsExcel({ fileName }));
    };

    return (
      <Box
        className="bg-background h-full overflow-auto"
        data-testid="geofence-dwell-history-table"
      >
        <Box className="flex h-full w-full p-8 pt-2 md:px-6 md:pb-10 lg:px-16 lg:pb-20">
          <Table
            apiRef={gridApiRef}
            tableType={TableViewType.GeofenceDwell}
            onPageChange={onPageChange}
            currentPage={currentPageNo}
            onFilterModelChange={onFilterChange}
            onPaginationModelChange={onPageChange}
            rowCount={rowCountState}
            tableHeight="570px"
            tableName="dwell_table"
            columns={columns as TableGridColDef<Row>[]}
            rows={dwellHistory}
            showToolbar
            disableRowGrouping // TEMPORARY DISABLED UNTIL WE HAVE A BE GROUPING SOLUTION
            getRowId={(row) => row._id}
            allowExport={true}
            enableSearch={true}
            searchMinLength={appConfig.searchMinLength}
            searchExactMatch={true}
            exportProps={{
              csvOptions: { fileName },
              excelOptions: { fileName },
              printOptions: { fileName },
            }}
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            pageSize={rowsPerPage}
            loading={isTableDataLoading}
            error={!isTableDataLoading && !isDwellDataSuccess ? true : null}
            columnVisibilityModel={columnVisibilityModel}
            searchKeys={SEARCH_KEYS}
            rowGroupingModel={rowGroupingModel}
            onRowGroupingModelChange={(model: GridRowGroupingModel) => {
              setRowGroupingModel(model);
              gridApiRef.current.setSortModel([
                {
                  field: "__row_group_by_columns_group__",
                  sort: "asc",
                },
              ]);
            }}
            mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
          />
        </Box>
      </Box>
    );
  }
);
