import { Box } from "@mui/material";
import {
  ComposedChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  YAxis,
  XAxis,
} from "recharts";
import ChartLegend from "../ChartLegend/ChartLegend";
import { ChartType, ChartTooltip } from "../ChartTooltip/ChartTooltip";

const containerHeight = 330;

const OdometerChart = <TValue extends Record<string, any>>({
  data,
  chartColors,
  tickStyle,
}: {
  data: TValue[];
  chartColors: string[];
  tickStyle: any;
}) => {
  const legendKeys: string[] = [
    "Primary Voltage",
    "Secondary Voltage",
    "No Power",
  ];
  const dateLabelAngle = data.length > 20 ? -45 : 0;

  return (
    <Box>
      <Box className="relative" style={{ height: containerHeight }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} stackOffset="none" barGap={0} barSize={24}>
            <CartesianGrid stroke="#DFDFDF" />
            <XAxis
              dataKey="date"
              tick={tickStyle}
              tickLine={false}
              angle={dateLabelAngle}
            />
            <YAxis
              yAxisId="odometer"
              type="number"
              orientation="left"
              label={{
                value: "Odometer reading",
                angle: -90,
                fontFamily: "Montserrat",
                position: "insideLeft",
                style: { textAnchor: "middle", ...tickStyle },
              }}
              tick={tickStyle}
            />
            <Tooltip
              offset={-5}
              content={<ChartTooltip type={ChartType.OdometerTrends} />}
            />
            <Bar
              yAxisId="odometer"
              dataKey="travelDistanceWithPrimaryPower"
              fill="#3C88CC"
              stackId="a"
            />
            <Bar
              yAxisId="odometer"
              dataKey="travelDistanceWithSecondaryPower"
              fill="#1E3768"
              stackId="a"
            />
            <Bar
              yAxisId="odometer"
              dataKey="travelDistanceWithNoPower"
              fill="#808080"
              stackId="a"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      {
        <Box className="my-12 flex justify-center gap-8">
          {legendKeys.map((key, index) => (
            <ChartLegend
              key={key}
              label={key}
              checked={false}
              color={chartColors[3]}
              chipColor={chartColors[index]}
              labelColor="#464646"
            />
          ))}
        </Box>
      }
    </Box>
  );
};

export default OdometerChart;
