import { FC } from "react";
import { Divider } from "@mui/material";
import { Asset } from "../../../../graphql/operations";
import { AssetShortTooltipSensors } from "../../MapView/Assets/AssetShortTooltip/components/AssetShortTooltipSensors/AssetShortTooltipSensors";
import { LastEventChip } from "../../MapView/Assets/AssetShortTooltip/components/LastEventChip/LastEventChip";
import { formatSensorEventName } from "../../MapView/Assets/AssetShortTooltip/helpers";
import { useGetEnabledSensors } from "../../MapView/Assets/AssetShortTooltip/hooks/useGetEnabledSensors";
import { AssetIcon } from "../../TableView/components";

interface AssetGalleryCardProps {
  asset: Asset;
  isFetchingAssetById?: boolean;
  handleAssetClick: (asset: Asset) => void;
}
export const AssetGalleryCard: FC<AssetGalleryCardProps> = ({
  asset,
  isFetchingAssetById,
  handleAssetClick,
}) => {
  const { enabledSensors } = useGetEnabledSensors(asset);
  const handleCardClick = () => {
    handleAssetClick(asset);
  };

  return (
    <button
      className="grid w-[100%] text-start gap-2 text-typography-tooltip p-4 rounded cursor-pointer grow"
      onClick={handleCardClick}
      data-testid={`asset-gallery-card-${asset._id}`}
      style={{ backgroundColor: "var(--gallery-cards-background)" }}
    >
      <div className="flex justify-between items-start">
        <div className="flex items-center" data-testid="asset-icon">
          <AssetIcon
            dwellingDays={Number(asset.dwelling?.dwellingDays)}
            signal={Boolean(asset.tripStatus?.signal)}
            cargo_state={String(asset?.sensors?.chassis?.data?.cargoState)}
            trip_st={String(asset?.tripStatus?.tripState)}
            svgProps={{ height: 48, width: 48 }}
          />
        </div>
        <div>
          <div className="flex items-center justify-between gap-2 text-2xs pb-2">
            <div className="cursor-pointer">
              <p
                title={`${asset.category} ${asset.name?.toUpperCase()}`}
                data-testid="asset-name-category"
                className="font-bold text-base text-sub-header-text leading-none capitalize relative text-ellipsis overflow-hidden whitespace-nowrap w-[120px]"
              >
                {asset.category && <span>{asset.category}&nbsp;</span>}#
                {asset.name?.toUpperCase()}
              </p>
            </div>
          </div>
          <div className="font-medium text-[10px] max-w-[150px] pb-2">{`${asset.fullAddress?.city}, ${asset.fullAddress?.state}`}</div>
          <div className="font-medium text-[10px]">
            {formatSensorEventName(asset.event_name ?? "")}
          </div>
        </div>
        <div className="h-4">
          <LastEventChip lastEvent={new Date(asset.lst_evnt_t)} />
        </div>
      </div>
      <Divider className="opacity-50 !my-2" />
      <AssetShortTooltipSensors
        sensors={enabledSensors}
        isFetchingAssetById={isFetchingAssetById}
      />
    </button>
  );
};
