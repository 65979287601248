import { memo, useMemo, useState, useCallback, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import AddCircle from "@mui/icons-material/AddCircle";
import FileUpload from "@mui/icons-material/FileUpload";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  popoverClasses,
  Typography,
  ThemeProvider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty, isEqual, debounce } from "lodash";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { BatchTitles } from "../../../../../constants/batches";
import { FILTERS_LIST_WIDTH } from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetFilter,
  useFindOrgsQuery,
  CreateAssetFilterMutation,
  useCreateAssetFilterMutation,
  useUpdateAssetFilterMutation,
  useDeleteAssetFilterMutation,
  UpdateAssetFilterMutation,
  DeleteAssetFilterMutation,
} from "../../../../../graphql/operations";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../../../views/BatchesView/BatchManagementUtils";
import { AddAssetDrawer } from "../../../TableView/components/AddAssetDrawer";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import {
  AssetFilters,
  initialFilterState,
  useAssetsDataContext,
} from "../../../shared/AssetsDataContext/AssetsDataContext";
import AssetsFilters from "../../../shared/AssetsFilterControls/Filters/AssetFilters/AssetFilters";
import MoreFilters from "../../../shared/AssetsFilterControls/Filters/MoreFilters";
import SensorsFilters from "../../../shared/AssetsFilterControls/Filters/SensorsFilters";
import StatusFilters from "../../../shared/AssetsFilterControls/Filters/StatusFilters";
import FilterItem from "../../../shared/AssetsFilterControls/MoreActions/FilterItem";
import FilterModal, {
  ModalModeMap,
} from "../../../shared/AssetsFilterControls/MoreActions/FilterModal";
import { UploadAssetsDialog } from "../../../shared/UploadAssetsDialog/UploadAssetsDialog";
import { GeofenceFilters } from "../GeofenceFilters/GeofenceFilters";

export enum Modes {
  Save = "Save",
  Update = "Update",
  Delete = "Delete",
}

const menuActionsOptions = [
  {
    title: "New Asset",
    action: "create",
    icon: <AddCircle fontSize="inherit" />,
  },
  {
    title: "Upload Asset",
    action: "upload",
    icon: <FileUpload fontSize="inherit" />,
  },
];

interface FiltersDrawerProps {
  isOpen?: boolean;
  view?: string;
}

const FiltersDrawer = memo(({ isOpen, view }: FiltersDrawerProps) => {
  const queryClient = useQueryClient();

  const {
    dispatch,
    state: {
      appConfig,
      theme: { theme },
    },
  } = useAppContext();

  const {
    reset,
    formState: { errors },
  } = useFormContext();

  const {
    savedFilters,
    currentFilter: criteria,
    removeAssetFilterCache,
    refetchAssetFilters,
    onChangeFilters,
    isFetchingUserFilters,
    assetsSearchInput,
    showAddAsset,
    setShowAddAsset,
    isFileUploadDialogOpen,
    setIsFileUploadDialogOpen,
    setAssetsSearchInput,
    onPageChange,
  } = useAssetsDataContext();

  const assetManagementTheme = useAssetManagementTheme(); // in context of Map View we will not have same theme as we have in AssetManagement View, so we need to set it explicitly

  const userData = useUserData();

  const { data: orgData } = useFindOrgsQuery();
  const orgs = mapOrgs(orgData?.findOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  const [mode, setMode] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<AssetFilter | null>(
    null
  );
  const [name, setName] = useState<string>(selectedFilter?.name ?? "");
  const [isPublic, setPublic] = useState<boolean>(
    selectedFilter?.is_public || false
  );

  const userMadeChange = !isEqual(criteria, initialFilterState);
  const disabled = !userMadeChange || !isEmpty(errors);

  const { publicFilters, userFilters } = useMemo(() => {
    const filtersSorted = savedFilters
      ?.filter((filter) => filter.is_public)
      .sort((a, b) => a.name.localeCompare(b.name));
    const publicFilters = filtersSorted;
    const userFilters = filtersSorted;

    return { publicFilters, userFilters };
  }, [savedFilters]);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-actions-popup",
  });

  const onCloseModal = useCallback(() => {
    setMode("");
  }, []);

  const onReset = useCallback(() => {
    popupState.close();
    reset();
    onChangeFilters(initialFilterState);
    setSelectedFilter(null);
  }, [onChangeFilters, popupState, reset]);

  const onSuccess = useCallback(
    async (
      data:
        | CreateAssetFilterMutation
        | UpdateAssetFilterMutation
        | DeleteAssetFilterMutation
        | AssetFilter
    ) => {
      if (mode === Modes.Save && "createAssetFilter" in data) {
        data.createAssetFilter?.criteria &&
          onChangeFilters(
            JSON.parse(
              data.createAssetFilter?.criteria
            ) as Partial<AssetFilters>
          );
        setSelectedFilter(data.createAssetFilter);
      }
      if (mode === Modes.Update && "updateAssetFilter" in data) {
        onChangeFilters(
          JSON.parse(data.updateAssetFilter?.criteria) as Partial<AssetFilters>
        );
      }
      if (mode === Modes.Delete && "deleteAssetFilter" in data) {
        onReset(); // reset assets back to original state when selected filter is deleted
      }
      onCloseModal();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode}d `,
          text: "Success!",
          severity: "success",
          onClose: () => {},
        },
      });
      if (removeAssetFilterCache && refetchAssetFilters) {
        removeAssetFilterCache();
        refetchAssetFilters();
      }
    },
    [
      removeAssetFilterCache,
      refetchAssetFilters,
      mode,
      dispatch,
      onChangeFilters,
      onCloseModal,
      name,
      onReset,
    ]
  );

  const onError = useCallback(
    (error: unknown) => {
      onCloseModal();
      console.error(error);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode} Failed`,
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
    [mode, dispatch, onCloseModal, name]
  );

  const { mutate: createAssetFilter, isLoading: isCreatingAssetFilter } =
    useCreateAssetFilterMutation({ onSuccess, onError });

  const { mutate: updateAssetFilter, isLoading: isUpdatingAssetFilter } =
    useUpdateAssetFilterMutation({
      onSuccess,
      onError,
    });

  const { mutate: deleteAssetFilter, isLoading: isDeletingAssetFilter } =
    useDeleteAssetFilterMutation({
      onSuccess,
      onError,
    });

  const filterAlreadyExists = useCallback(() => {
    if (!name || !savedFilters?.length) {
      return false;
    }
    return savedFilters.some((filter) => filter.name === name);
  }, [name, savedFilters]);

  const handleAssetSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setAssetsSearchInput(e.target.value);
    onPageChange(1);
  }, appConfig.debounceTime);

  const isUpdatingName = useMemo(
    () => mode === Modes.Update && name !== selectedFilter?.name,
    [selectedFilter, mode, name]
  );

  const handleHelperText = useCallback((): string => {
    if ((mode === Modes.Save || isUpdatingName) && filterAlreadyExists()) {
      return "This filter name already exists.";
    }
    return "";
  }, [mode, isUpdatingName, filterAlreadyExists]);

  const handleFilterMenuClick = (clickMode: string, filter?: AssetFilter) => {
    popupState.close();
    // If user made any change and clicked edit then use that change for Edit Else
    const filterToApply: AssetFilter =
      userMadeChange && clickMode
        ? ({ ...filter, criteria: JSON.stringify(criteria) } as AssetFilter)
        : (filter as AssetFilter);
    // either Save, Update or Delete
    if (clickMode) {
      setMode(clickMode);
    }
    // filter == true means Update or Delete mode, filter === false means Save mode
    if (filter) {
      setSelectedFilter(filterToApply);
      setName(filterToApply?.name);
      setPublic(filterToApply?.is_public || false);
      onChangeFilters(JSON.parse(filterToApply?.criteria));
    } else {
      setName("");
      setPublic(false);
      setSelectedFilter(null);
    }
  };

  const handleActionMenuClick = (action: string) => {
    if (!action) return;
    if (action === "create") setShowAddAsset(true);
    if (action === "upload") setIsFileUploadDialogOpen(true);
  };

  const isSaveFilterDisabled = filterAlreadyExists();

  const modalModeMapping: ModalModeMap = useMemo(
    () => ({
      Save: {
        title: "Save New Filter",
        action: () => {
          createAssetFilter({
            input: {
              name,
              is_public: isPublic,
              criteria: JSON.stringify(criteria),
            },
          });
        },
        disabled: isSaveFilterDisabled,
      },
      Update: {
        title: "Update Filter",
        action: () => {
          selectedFilter &&
            updateAssetFilter({
              input: {
                ...(({ email, ...rest }) => rest)(selectedFilter),
                name,
                is_public: isPublic,
              },
            });
        },
      },
      Delete: {
        title: "Delete Filter",
        action: () => {
          selectedFilter && deleteAssetFilter({ id: selectedFilter._id });
        },
      },
    }),
    [
      selectedFilter,
      createAssetFilter,
      updateAssetFilter,
      deleteAssetFilter,
      name,
      isPublic,
      criteria,
      isSaveFilterDisabled,
    ]
  );

  const visible = isOpen ? "fixed" : "hidden";
  const commonStyles = `${visible} h-full !x-overflow-hidden bg-background text-primary relative`;
  const borderColor = theme === "dark" ? "gray" : "var(--border-color)";

  if (!isOpen) return null;

  return (
    <Box
      data-testid="map-filters-drawer"
      className={commonStyles}
      sx={{
        width: FILTERS_LIST_WIDTH,
        minWidth: FILTERS_LIST_WIDTH,
        borderRight: `1px solid ${borderColor}`,
      }}
    >
      {view !== "geofences" && (
        <>
          <Box
            className="h-14 px-2.5 flex justify-between items-center"
            sx={{
              borderBottom: `1px solid ${borderColor}`,
            }}
          >
            <ListAltIcon
              className="hover: cursor-pointer"
              {...bindTrigger(popupState)}
            />

            <Menu
              {...bindMenu(popupState)}
              id="filters-operations-menu"
              data-testid="filters-operations-menu"
              className="!text-xs"
              sx={{
                [`& .${popoverClasses.paper}`]: {
                  backgroundColor: "var(--background)",
                  minWidth: "240px",
                  maxWidth: "280px",
                },
                "& .MuiList-root": {
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  fontSize: ".75rem",
                  "*": {
                    fontWeight: "500 !important",
                  },
                },
              }}
            >
              {isFetchingUserFilters ? (
                <span
                  color="var(--secondary)"
                  className="px-4 py-2 text-inherit"
                >
                  <CircularProgress size={25} color="inherit" />
                </span>
              ) : (
                <div>
                  <>
                    <Typography
                      key="actions-title"
                      className="px-4 !text-inherit text-menu-group"
                      variant="subtitle2"
                    >
                      Actions
                    </Typography>
                    {menuActionsOptions.map((option) => (
                      <MenuItem
                        key={option.title}
                        className="flex !justify-between !text-inherit"
                        onClick={() => handleActionMenuClick(option.action)}
                      >
                        <p>{option.title}</p>
                        {option.icon}
                      </MenuItem>
                    ))}
                  </>
                  <Divider />

                  {!!userFilters?.length && (
                    <>
                      <Typography
                        key="my-filters-title"
                        className="px-4 !text-inherit text-menu-group"
                        variant="subtitle2"
                      >
                        My Filters
                      </Typography>
                      {userFilters?.map((filter) => (
                        <FilterItem
                          key={`id-${filter?._id ?? ""}-${filter.name}`}
                          filter={filter}
                          onClick={handleFilterMenuClick}
                          selectedFilter={selectedFilter}
                        />
                      ))}
                    </>
                  )}
                  {userFilters?.length > 0 && publicFilters?.length > 0 && (
                    <Divider />
                  )}
                  {!!publicFilters?.length && (
                    <>
                      <Typography
                        key="public-filters-title"
                        className="px-4 !text-inherit text-menu-group"
                        variant="subtitle2"
                      >
                        Shared Filters
                      </Typography>
                      {publicFilters?.map((filter, index) => (
                        <FilterItem
                          key={`id-${filter?._id ?? ""}-${filter.name}`}
                          filter={filter}
                          onClick={handleFilterMenuClick}
                          selectedFilter={selectedFilter}
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
            </Menu>

            <TextField
              id="assets-search-field"
              label=""
              defaultValue={assetsSearchInput ?? ""}
              placeholder="Assets Search"
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              onChange={handleAssetSearch}
              sx={{
                width: "88%",
              }}
              data-testid="assets-search-field"
            />
          </Box>

          <Box className="h-full p-5 absolute justify-center items-center overflow-x-hidden overflow-y-visible">
            <AssetsFilters />
            <StatusFilters />
            <SensorsFilters />
            <MoreFilters />

            <Box className="pt-8 pb-16 flex justify-center">
              <Button
                className="!bg-brand global-btn !rounded-[100px] !py-2 !px-6 !text-sm !font-bold !text-white"
                type="button"
                data-testid="filters-drawer-save-filter-btn"
                disabled={disabled}
                onClick={() => handleFilterMenuClick("Save")}
                title="Save"
              >
                Save
              </Button>
            </Box>
          </Box>

          {mode && (
            <FilterModal
              open={Boolean(mode)}
              mode={(mode || "") as string}
              onClose={onCloseModal}
              modalModeMapping={modalModeMapping}
              name={name}
              isPublic={isPublic}
              originalIsPublic={selectedFilter?.is_public ?? isPublic}
              setPublic={setPublic}
              setName={setName}
              isCreatingAssetFilter={isCreatingAssetFilter}
              isUpdatingAssetFilter={isUpdatingAssetFilter}
              isDeletingAssetFilter={isDeletingAssetFilter}
              helperText={handleHelperText()}
            />
          )}

          <ThemeProvider theme={assetManagementTheme}>
            <AddAssetDrawer open={showAddAsset} setOpen={setShowAddAsset} />
          </ThemeProvider>

          <UploadAssetsDialog
            title={BatchTitles.CreateUpdateAssets}
            customerOrg={userData?.customerOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isFileUploadDialogOpen}
            onClose={() => setIsFileUploadDialogOpen(false)}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        </>
      )}
      {
        // Geofence Filters
        view === "geofences" && <GeofenceFilters />
      }
    </Box>
  );
});

export default FiltersDrawer;
