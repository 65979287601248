import { useCallback, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Asset } from "../../../graphql/operations";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { AssetDashboard } from "../TableView/components/AssetDashboard";
import { useAssetManagementTheme } from "../TableView/hooks/useAssetManagementTheme";
import { useAssetsDataContext } from "../shared/AssetsDataContext";
import { AssetsGallery } from "./components/AssetsGallery";
import { useGetAssetsGallery } from "./hooks/useGetAssetsGallery";

export const GalleryView = () => {
  const assetManagementTheme = useAssetManagementTheme();

  const assetId = Object.values(useParams())[0];
  const [shouldShowGallery, setShouldShowGallery] = useState(!assetId);
  const [totalAssetsLength, setTotalAssetsLength] = useState<number>(0);
  const [assetsList, setAssetsList] = useState<Asset[]>([]);
  const { setSelectedAssetId } = useAssetsDataContext();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: assetDataTable,
    isLoading: isAssetDataTableLoading,
    isRefetching: isAssetDataTableRefetching,
  } = useGetAssetsGallery();

  useEffect(() => {
    if (assetDataTable?.assets) {
      setAssetsList((assetDataTable.assets as Asset[]) ?? []);
      setTotalAssetsLength(assetDataTable.total ?? 0);
    }

    setShouldShowGallery(location.pathname === NavigationRoutes.AssetGallery);
  }, [assetDataTable, location.pathname]);

  const handleNavigateBack = useCallback(() => {
    setSelectedAssetId(null);
    setShouldShowGallery(true);
    const queryParams = new URLSearchParams(location.search);
    navigate(`${NavigationRoutes.AssetGallery}?${queryParams}`);
  }, [location.search, navigate, setSelectedAssetId]);

  const handleAssetClick = useCallback(
    (asset: Asset) => {
      setSelectedAssetId(asset._id ?? null);
      const queryParams = new URLSearchParams(location.search);
      setShouldShowGallery(false);
      navigate(`${asset._id}?${queryParams}`);
    },
    [location.search, navigate, setSelectedAssetId]
  );

  // show spinner only on first loading
  useSpinner(
    (isAssetDataTableLoading || isAssetDataTableRefetching) &&
      assetsList.length === 0
  );

  return (
    <ThemeProvider theme={assetManagementTheme}>
      {shouldShowGallery ? (
        <AssetsGallery
          assetsList={assetsList}
          isAssetDataTableRefetching={isAssetDataTableRefetching}
          isAssetDataTableLoading={isAssetDataTableLoading}
          handleAssetClick={handleAssetClick}
          totalAssetsLength={totalAssetsLength}
        />
      ) : (
        <Routes>
          <Route
            path=":id"
            element={
              <AssetDashboard
                onClose={handleNavigateBack}
                isAssetRefetching={isAssetDataTableRefetching}
                backButtonText="Back"
              />
            }
          />
        </Routes>
      )}
    </ThemeProvider>
  );
};
