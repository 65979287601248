import {
  ASSET_MARKER_COLOR_MAPPING,
  DWELL_TYPE_ARC_LINE_MAP_GM,
} from "../../constants/map";

const generateColor = (colorValues: string, opacity: number) => {
  return `rgba(${colorValues}, ${opacity})`;
};

const dwellArcs = (
  context: CanvasRenderingContext2D,
  dwellType: string,
  size: number,
  type: string
) => {
  const dwellTypeValue = DWELL_TYPE_ARC_LINE_MAP_GM.get(dwellType);
  if (dwellTypeValue) {
    const { arcPos, lineDashed } = dwellTypeValue;
    const opacity = 1;
    context.moveTo(size / 2, size / 2);
    context.beginPath();
    context.arc(size / 2, size / 2, 17, arcPos, 360);
    context.lineWidth = 3;
    context.setLineDash(lineDashed);
    const colorValues = ASSET_MARKER_COLOR_MAPPING.get(type);
    if (colorValues) {
      context.strokeStyle = generateColor(colorValues, opacity);
    }
    context.stroke();
    context.closePath();
  }
};

const mainCircle = (
  context: CanvasRenderingContext2D,
  opacity: number,
  radius: number,
  t: number,
  size: number,
  type: string,
  animate: boolean
) => {
  context.moveTo(size / 2, size / 2);
  context.beginPath();
  context.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
  const colorValues = ASSET_MARKER_COLOR_MAPPING.get(type);
  if (colorValues) {
    context.fillStyle = generateColor(colorValues, opacity);
  }
  if (animate) {
    context.strokeStyle = "white";
    context.lineWidth = 2 + 4 * (1 - t);
    context.stroke();
  }
  context.fill();
  context.closePath();
};

const innerCircle = (
  context: CanvasRenderingContext2D,
  innerRadius: number,
  size: number,
  type: string
) => {
  const isLoaded = /loaded/.test(type);
  const isEmpty = /empty/.test(type);
  const isSignaling = /(?=\w+-(?!not))^(\W|\w)+$/.test(type);
  const isNotSignaling = /(?=\w+-(?=not-signaling))^(\W|\w)+$/.test(type);
  let color = "";
  if (isSignaling) {
    if (isLoaded) {
      color = "#0E49A3";
    } else if (isEmpty) {
      color = "#FFFFFF";
    }
  } else if (isNotSignaling) {
    if (isLoaded) {
      color = "#000000";
    } else if (isEmpty) {
      color = "#FFFFFF";
    }
  }
  context.moveTo(size / 2, size / 2);
  context.beginPath();
  context.arc(size / 2, size / 2, innerRadius, 0, Math.PI * 2);
  context.fillStyle = color;
  context.fill();
  context.closePath();
};

export const getGMFeatureIcon = (
  size: number,
  type: string,
  isBeacon: boolean
) => {
  const canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");
  const duration = 1000;
  const isEmptyOrLoaded = /^(empty|loaded)/.test(type);
  const isDwelling = /-dwell-/.test(type);
  const t = (performance.now() % duration) / duration;
  let opacity = 0.2;
  const radius = 10;
  const innerRadius = 4;
  if (context) {
    // Draw the outer arc only when dwelling/parked.
    if (isDwelling) {
      const filteredResult = /-dwell-(?:(\w+))/.exec(type);
      if (filteredResult) {
        const dwellType = filteredResult[1];
        dwellArcs(context, dwellType, size, type);
      }
    }
    // Draw the inner circle.
    opacity = 1;
    mainCircle(context, opacity, radius, t, size, type, isBeacon);
    // Draw additional white inner circle for empty load and dwelling asset
    if (isEmptyOrLoaded) {
      innerCircle(context, innerRadius, size, type);
    }

    const imageData = context.canvas.toDataURL();

    return {
      type,
      imageData,
      isBeacon,
    };
  }
  return {
    type,
    imageData: "",
    isBeacon,
  };
};

export const getGMClusterSVG = (
  countAbbreviated: string,
  count: number
): string => {
  let circleSize = 40;

  if (count > 500 && count <= 1000) {
    circleSize = 60;
  } else if (count > 1000) {
    circleSize = 80;
  }

  return `<svg xmlns="http://www.w3.org/2000/svg" width="${circleSize}" height="${circleSize}">
    <circle cx="${circleSize / 2}" cy="${circleSize / 2}" r="${
    circleSize / 2
  }" fill="#000000" opacity="0.5" />

    <text
      x="${circleSize / 2}"
      y="${circleSize / 2}"
      text-anchor="middle"
      font-family="Roboto, sans-serif"
      fill="white"
      font-size="16"
      dy=".35em"
    >
      ${countAbbreviated}
    </text>
  </svg>`;
};
