import {
  cloneDeep,
  Dictionary,
  isEmpty,
  isNil,
  isNumber,
  omitBy,
} from "lodash";
import {
  CUSTOM_FILTER_FIELD_MAP,
  TRIP_STATUS,
  FILTER_OPERATOR_VALUE,
  DWELL_STATUS_MAPPING,
  DWELL_STATUS_RANGE_MAPPING,
  RangeFilterOption,
  LAST_REPORTED_DATE_OPTIONS_MAP,
  LastReportedDateOptions,
} from "../../constants/map";
import {
  AssetType,
  AssetsSummary,
  GeofenceShape,
} from "../../graphql/operations";
import {
  FilterDaysRange,
  AssetFilters,
  FilterPayload,
  SignalFilter,
  IGeofenceFilters,
  GeofenceFiltersPayload,
  InstalledDateFilter,
  BatteryPrimaryVoltageRange,
  BatterySecondaryVoltageRange,
  BackupBatteryVoltageRange,
  SolarAmperageRange,
  InternalCameraFloorUsagePercentageFilter,
  FilterPayloadValueRange,
  GeofenceDateRange,
  GeofenceTimeRange,
} from "../../views/AssetsView/shared/AssetsDataContext";
import { AssetsStatusCount } from "../../views/AssetsView/shared/AssetsFilterControls";

export enum AssetFilterType {
  Status = "status",
  Signals = "signals",
  InstallStatus = "installStatus",
  InstalledDate = "installedDate",
  InstallStartDate = "installStartDate",
  InstallEndDate = "installEndDate",
  BatteryPrimaryVoltage = "batteryPrimaryVoltage",
  BatterySecondaryVoltage = "batterySecondaryVoltage",
  BackupBatteryVoltage = "backupBatteryVoltage",
  SolarAmperage = "solarAmperage",
  InternalCameraStatus = "internalCameraStatus",
  InternalCameraFloorUsagePercentage = "internalCameraFloorUsagePercentage",
  DoorState = "doorState",
  CargoUltrasonic = "cargoUltrasonicState",
  WeightStatus = "weightStatus",
  TotalMileage = "totalMileage",
  AssetYear = "assetYear",
  LastReportedDateRange = "lastReportedDateRange",
  GeofenceNames = "geofenceNames",
  GeofenceCategories = "geofenceCategories",
}

export const getStatusCounts = (
  assetsSummary?: Partial<AssetsSummary>
): AssetsStatusCount => ({
  Moving: assetsSummary?.moving || 0,
  Parked: assetsSummary?.parked || 0,
  Low: assetsSummary?.dwell?.low || 0,
  Medium: assetsSummary?.dwell?.medium || 0,
  High: assetsSummary?.dwell?.high || 0,
  Mega: assetsSummary?.dwell?.mega || 0,
});

export const generateAssetFiltersPayload = (
  filters: Partial<AssetFilters>,
  oldPayload: FilterPayload[] | null,
  customerOrgId?: string | null
): FilterPayload[] => {
  let payload: FilterPayload[] = [];

  const filtersKeys = Object.keys(filters) as (keyof AssetFilters)[];

  filtersKeys.forEach((key) => {
    switch (key) {
      case AssetFilterType.Status: {
        payload = [...payload, ...prepareStatusFilterPayload(filters.status!)];
        break;
      }
      case AssetFilterType.Signals: {
        const signalsPayload = prepareSignalFilterPayload(filters.signals!);
        if (signalsPayload) {
          payload = [...payload, signalsPayload];
        }
        break;
      }
      case AssetFilterType.InstallStatus: {
        const installStatusPayload = prepareInstallStatusFilterPayload(
          filters.installStatus!
        );
        if (installStatusPayload) {
          payload = [...payload, installStatusPayload];
        }
        break;
      }

      case AssetFilterType.InstalledDate: {
        const installedDateFilter = filters.installedDate;
        const installStatusPayload = prepareInstalledFilterPayload(
          installedDateFilter ?? { startDate: null, endDate: null }
        );
        if (installStatusPayload) {
          payload = [...payload, installStatusPayload];
        }
        break;
      }

      case AssetFilterType.BatteryPrimaryVoltage: {
        const batteryPrimaryVoltageFilter = filters.batteryPrimaryVoltage;
        const batteryPrimaryVoltagePayload =
          prepareBatteryPrimaryVoltageFilterPayload(
            batteryPrimaryVoltageFilter
          );

        if (batteryPrimaryVoltagePayload) {
          payload = [...payload, batteryPrimaryVoltagePayload];
        }
        break;
      }

      case AssetFilterType.BatterySecondaryVoltage: {
        const batterySecondaryVoltageFilter = filters.batterySecondaryVoltage;
        const batterySecondaryVoltagePayload =
          prepareBatterySecondaryVoltageFilterPayload(
            batterySecondaryVoltageFilter
          );
        if (batterySecondaryVoltagePayload) {
          payload = [...payload, batterySecondaryVoltagePayload];
        }
        break;
      }

      case AssetFilterType.BackupBatteryVoltage: {
        const backupBatteryVoltageFilter = filters.backupBatteryVoltage;
        const backupBatteryVoltagePayload =
          prepareBackupBatteryVoltageFilterPayload(backupBatteryVoltageFilter);

        if (backupBatteryVoltagePayload) {
          payload = [...payload, backupBatteryVoltagePayload];
        }
        break;
      }

      case AssetFilterType.SolarAmperage: {
        const solarAmperageFilter = filters.solarAmperage;
        const solarAmperagePayload =
          prepareSolarAmperageFilterPayload(solarAmperageFilter);

        if (solarAmperagePayload) {
          payload = [...payload, solarAmperagePayload];
        }
        break;
      }

      case AssetFilterType.InternalCameraFloorUsagePercentage: {
        const internalCameraFloorUsagePercentagePayload =
          prepareInternalCameraFloorUsagePercentageFilterPayload(
            filters?.internalCameraFloorUsagePercentage ?? null
          );

        if (internalCameraFloorUsagePercentagePayload) {
          payload = [...payload, internalCameraFloorUsagePercentagePayload];
        }
        break;
      }

      case AssetFilterType.WeightStatus:
      case AssetFilterType.TotalMileage:
      case AssetFilterType.AssetYear: {
        const valueRangePayload = prepareValueRangeFilterPayload(
          cloneDeep(CUSTOM_FILTER_FIELD_MAP.get(key)),
          filters[key]
        );

        if (valueRangePayload) {
          payload = [...payload, valueRangePayload];
        }
        break;
      }

      case AssetFilterType.LastReportedDateRange: {
        payload = [
          ...payload,
          prepareLastReportedDateRangePayload(filters.lastReportedDateRange),
        ];

        break;
      }

      case AssetFilterType.GeofenceNames: {
        const geofenceNamesPayload = cloneDeep(
          CUSTOM_FILTER_FIELD_MAP.get("geofenceNames")
        );
        if (geofenceNamesPayload) {
          geofenceNamesPayload.value = filters.geofenceNames;
          payload = [...payload, geofenceNamesPayload];
        }
        break;
      }

      case AssetFilterType.GeofenceCategories: {
        const geofenceCategoriesPayload = cloneDeep(
          CUSTOM_FILTER_FIELD_MAP.get("geofenceCategories")
        );
        if (geofenceCategoriesPayload) {
          geofenceCategoriesPayload.value = (
            filters.geofenceCategories ?? []
          ).map(({ id }) => id);
          payload = [...payload, geofenceCategoriesPayload];
        }
        break;
      }

      default: {
        const otherPayload = cloneDeep(CUSTOM_FILTER_FIELD_MAP.get(key));
        if (otherPayload) {
          otherPayload.value = getCorrectFiltersValue(filters, key);
          if (
            otherPayload.columnField === "customer_orgs_id" &&
            customerOrgId
          ) {
            otherPayload.value = { value: customerOrgId };
          }
          if (otherPayload.columnField === "num_of_axles") {
            otherPayload.value = (otherPayload.value as string[]).map(Number);
          }

          if (otherPayload.columnField === "wheel_config") {
            otherPayload.value = (otherPayload.value as number[]).map(Number);
          }

          payload = [...payload, otherPayload];
        }
        break;
      }
    }
  });
  if (oldPayload) {
    payload = mergeAssetFiltersPayloads(payload, oldPayload);
  }
  return filterEmptyPayload(payload);
};

export const mergeAssetFiltersPayloads = (
  payload: FilterPayload[],
  oldPayload: FilterPayload[]
): FilterPayload[] => {
  return oldPayload.reduce(
    (mergedPayload: FilterPayload[], oldFilter: FilterPayload) => {
      const isExistingFilter = payload.some((filter) => {
        const isSameColumnField = filter.columnField === oldFilter.columnField;
        const hasElemMatchField = Boolean(filter.elemMatchField);
        const isSameElemMatchField =
          filter.elemMatchField === oldFilter.elemMatchField;

        return (
          (isSameColumnField && !hasElemMatchField) ||
          (isSameColumnField && hasElemMatchField && isSameElemMatchField) // if the filter has an 'elemMatchField', both 'columnField' and 'elemMatchField' should match to consider it as the same filter
        );
      });
      if (isExistingFilter) {
        // if the old filter exists in the new payload, will replace it with the new filter
        return mergedPayload;
      }

      // if the filter is not in the new payload, will add it to the merged payload
      return [...mergedPayload, oldFilter];
    },
    [...payload]
  );
};

export const filterEmptyPayload = (
  payload: FilterPayload[]
): FilterPayload[] => {
  // keep only the filters that have a value or valueRange
  const filteredPayload = payload.filter((filter) => {
    // we have filters whose actual value is only operatorValue and they do not have a value or valueRange
    const isOperatorValueFilter =
      !filter.value && !filter.valueRange && !isEmpty(filter.operatorValue);
    const hasValue =
      (filter.value && !isEmpty(filter.value)) ||
      (filter.value && isNumber(filter.value));
    const hasValueRange = !isEmpty(filter.valueRange);

    return Boolean(hasValue || hasValueRange || isOperatorValueFilter);
  });

  return filteredPayload;
};

export const prepareStatusFilterPayload = (
  values: string[] | FilterDaysRange
): FilterPayload[] => {
  const payload: FilterPayload[] = [];

  if (Array.isArray(values)) {
    // Status filter selected
    const statusPayload = cloneDeep(CUSTOM_FILTER_FIELD_MAP.get("status"));

    const dwellPayload = {
      ...cloneDeep(CUSTOM_FILTER_FIELD_MAP.get("dwell")),
      valueRanges: [] as RangeFilterOption[],
    } as FilterPayload;
    values.forEach((value: string) => {
      if (
        statusPayload &&
        Object.values(TRIP_STATUS).includes(value as TRIP_STATUS)
      ) {
        (statusPayload.value as string[]).push(value);
      } else if (
        dwellPayload?.valueRanges &&
        Array.isArray(dwellPayload?.valueRanges) &&
        value in DWELL_STATUS_MAPPING
      ) {
        dwellPayload.valueRanges.push(
          DWELL_STATUS_RANGE_MAPPING[value as DWELL_STATUS_MAPPING]
        );
      }
    });

    if (statusPayload) {
      payload.push(statusPayload);
    }
    if (dwellPayload) {
      payload.push(dwellPayload);
    }
  } else {
    // Dwell range filter selected
    const { minDays, maxDays } = values;
    const dwellRangePayload = cloneDeep(
      CUSTOM_FILTER_FIELD_MAP.get("dwellRange")
    );

    if (dwellRangePayload && minDays <= maxDays) {
      dwellRangePayload.valueRange = { startValue: minDays, endValue: maxDays };

      payload.push(dwellRangePayload);
    }
  }

  return payload;
};

export const prepareSignalFilterPayload = (
  signalFilters: SignalFilter
): FilterPayload | undefined => {
  const signalsPayload = cloneDeep(CUSTOM_FILTER_FIELD_MAP.get("signals"));
  if (!signalsPayload) {
    return;
  }

  if (signalFilters === "all") {
    signalsPayload.operatorValue = undefined;
  } else {
    signalsPayload.operatorValue = signalFilters
      ? FILTER_OPERATOR_VALUE.IsTrue
      : FILTER_OPERATOR_VALUE.IsFalse;
  }

  return signalsPayload;
};

export const prepareInstalledFilterPayload = (
  installedDate: InstalledDateFilter
): FilterPayload | undefined => {
  const installedDatePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("installedDate")
  );

  if (
    installedDatePayload?.dateRange &&
    installedDate?.startDate &&
    installedDate?.endDate
  ) {
    installedDatePayload.dateRange.startDate = installedDate?.startDate;
    installedDatePayload.dateRange.endDate = installedDate?.endDate;
  }
  return installedDatePayload;
};

export const prepareInstallStatusFilterPayload = (
  installedStatus: boolean
): FilterPayload | undefined => {
  const installStatusPayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("installStatus")
  );

  if (installStatusPayload) {
    installStatusPayload.operatorValue = installedStatus
      ? FILTER_OPERATOR_VALUE.IsNotEmpty
      : FILTER_OPERATOR_VALUE.IsEmpty;
  }

  return installStatusPayload;
};

export const prepareBatteryVoltageFilterPayload = (
  filterPayload: FilterPayload | undefined,
  values: { minVoltage: number; maxVoltage: number }
): FilterPayload | undefined => {
  const { minVoltage, maxVoltage } = values;

  if (filterPayload && minVoltage <= maxVoltage) {
    filterPayload.valueRange = {
      startValue: minVoltage,
      endValue: maxVoltage,
    };
  }

  return filterPayload;
};

export const prepareBatteryPrimaryVoltageFilterPayload = (
  primaryVoltageValues?: BatteryPrimaryVoltageRange | null
): FilterPayload | undefined => {
  const batteryPrimaryVoltageRangePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("batteryPrimaryVoltage")
  );

  let primaryVoltageResultPayload = batteryPrimaryVoltageRangePayload;
  if (
    typeof primaryVoltageValues?.minVoltage === "number" &&
    typeof primaryVoltageValues?.maxVoltage === "number" &&
    primaryVoltageValues.minVoltage <= primaryVoltageValues.maxVoltage
  ) {
    primaryVoltageResultPayload = prepareBatteryVoltageFilterPayload(
      batteryPrimaryVoltageRangePayload,
      primaryVoltageValues
    );
  }

  return primaryVoltageResultPayload;
};

export const prepareBatterySecondaryVoltageFilterPayload = (
  secondaryVoltageValues?: BatterySecondaryVoltageRange | null
): FilterPayload | undefined => {
  const batterySecondaryVoltageRangePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("batterySecondaryVoltage")
  );

  let secondaryVoltageResultPayload = batterySecondaryVoltageRangePayload;
  if (
    typeof secondaryVoltageValues?.minVoltage === "number" &&
    typeof secondaryVoltageValues?.maxVoltage === "number" &&
    secondaryVoltageValues.minVoltage <= secondaryVoltageValues.maxVoltage
  ) {
    secondaryVoltageResultPayload = prepareBatteryVoltageFilterPayload(
      batterySecondaryVoltageRangePayload,
      secondaryVoltageValues
    );
  }

  return secondaryVoltageResultPayload;
};

export const prepareBackupBatteryVoltageFilterPayload = (
  backupBatteryVoltageValues?: BackupBatteryVoltageRange | null
): FilterPayload | undefined => {
  const backupBatteryVoltageRangeVoltageRangePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("backupBatteryVoltage")
  );

  let backupBatteryVoltagePayload =
    backupBatteryVoltageRangeVoltageRangePayload;

  if (
    typeof backupBatteryVoltageValues?.minVoltage === "number" &&
    typeof backupBatteryVoltageValues?.maxVoltage === "number" &&
    backupBatteryVoltageValues.minVoltage <=
      backupBatteryVoltageValues.maxVoltage
  ) {
    backupBatteryVoltagePayload = prepareBatteryVoltageFilterPayload(
      backupBatteryVoltageRangeVoltageRangePayload,
      backupBatteryVoltageValues
    );
  }

  return backupBatteryVoltagePayload;
};

export const prepareSolarAmperageFilterPayload = (
  solarAmperage?: SolarAmperageRange | null
): FilterPayload | undefined => {
  const solarAmperageRangePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("solarAmperage")
  );

  if (
    solarAmperageRangePayload &&
    typeof solarAmperage?.minAmperage === "number" &&
    typeof solarAmperage?.maxAmperage === "number" &&
    solarAmperage.minAmperage <= solarAmperage.maxAmperage
  ) {
    solarAmperageRangePayload.valueRange = {
      startValue: solarAmperage.minAmperage,
      endValue: solarAmperage.maxAmperage,
    };
  }

  return solarAmperageRangePayload;
};

export const prepareInternalCameraFloorUsagePercentageFilterPayload = (
  internalCameraFloorUsagePercentage?: InternalCameraFloorUsagePercentageFilter
): FilterPayload | undefined => {
  const internalCameraFloorUsagePercentagePayload = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("internalCameraFloorUsagePercentage")
  );

  if (!internalCameraFloorUsagePercentagePayload) {
    return;
  }
  if (internalCameraFloorUsagePercentage) {
    internalCameraFloorUsagePercentagePayload.value =
      internalCameraFloorUsagePercentage;
  }

  return internalCameraFloorUsagePercentagePayload;
};

export const prepareValueRangeFilterPayload = (
  filterPayload: FilterPayload | undefined,
  values: FilterPayloadValueRange | undefined
): FilterPayload | undefined => {
  const { startValue, endValue } = values ?? {};

  if (filterPayload && !isNil(startValue) && !isNil(endValue)) {
    filterPayload.valueRange = { startValue, endValue };
  }

  return filterPayload;
};

export const prepareLastReportedDateRangePayload = (
  lastReportedDateRange: LastReportedDateOptions | null | undefined
): FilterPayload => {
  const payloadBase = cloneDeep(
    CUSTOM_FILTER_FIELD_MAP.get("lastReportedDateRange")
  )!;

  if (lastReportedDateRange) {
    return {
      ...payloadBase,
      dateRange: LAST_REPORTED_DATE_OPTIONS_MAP.get(lastReportedDateRange),
    };
  }

  return payloadBase;
};

export const getCorrectFiltersValue = (
  filters: Partial<AssetFilters>,
  key: keyof AssetFilters
) => {
  const filter = filters[key];
  if (typeof filter === "boolean") {
    return filter;
  }
  if (Array.isArray(filter)) {
    return filter.map((filterValue) =>
      // filterValue is object for some filters like productNames
      typeof filterValue === "object" ? filterValue.value : filterValue
    );
  } else if (filter) {
    return [filter] as string[];
  }

  return [] as string[];
};

const getRangeUpdateValue = <T>(value?: T) => {
  if (!value) {
    return;
  }
  const updatedValue: Dictionary<GeofenceDateRange | GeofenceTimeRange> =
    omitBy(value as Object, isNil);
  return updatedValue as T;
};

type FilterKeys = keyof IGeofenceFilters;

export function convertGeofenceFilters(
  filters: Partial<IGeofenceFilters>
): GeofenceFiltersPayload {
  const result: GeofenceFiltersPayload = {};

  // Iterate through each key in filters object
  (Object.keys(filters) as FilterKeys[]).forEach((key) => {
    const value = filters[key];

    // Check if the value is defined
    if (value !== undefined) {
      // Handle specific cases based on the key
      switch (key) {
        case "orgIds":
        case "typeIds":
          const typeIdsArr = value as { id: string; label: string }[];
          result[key] = typeIdsArr!.map((item) => item.id);
          break;
        case "locationCodes":
          result[key] = value as string[];
          break;
        case "shapes":
          result[key] =
            value === "all"
              ? [GeofenceShape.Polygon, GeofenceShape.Circle]
              : [value as GeofenceShape];
          break;
        case "hasAssets":
          result[key] = value === "all" ? undefined : (value as boolean);
          break;
        case "underMin":
        case "overMax":
          const valueArr = value as { id: string; label: string }[];
          result[key] = valueArr!.map((item) => item.label) as AssetType[];
          break;
        case "filters":
          result[key] = value as string;
          break;
        case "timezones": {
          const timezoneArr = value as { id: string; label: string }[];
          result[key] = timezoneArr.map((item) => item.id);
          break;
        }
        case "operatingHoursRange":
          result[key] = getRangeUpdateValue<GeofenceTimeRange>(
            value as GeofenceTimeRange
          );
          break;
        case "createdRange":
        case "updatedRange":
          result[key] = getRangeUpdateValue<GeofenceDateRange>(
            value as GeofenceDateRange
          );
          break;
        default:
          // For other keys, directly assign the value to result
          const otherArr = value as { id: string; label: string }[];
          result[key] = otherArr.map((item) => item.label);
      }
    }
  });

  return result;
}

export function mergePayloads(
  oldPayload: GeofenceFiltersPayload,
  newPayload: GeofenceFiltersPayload
): GeofenceFiltersPayload {
  const mergedObj = { ...newPayload } as GeofenceFiltersPayload;
  (Object.keys(oldPayload) as FilterKeys[]).forEach((key) => {
    if (key in newPayload) {
      if (key === "hasAssets") {
        mergedObj[key] = newPayload[key] as boolean;
      } else if (Array.isArray(newPayload[key])) {
        if (Array.isArray(newPayload[key]) && (newPayload[key] as [])?.length) {
          if (key === "shapes") {
            mergedObj[key] = [...(newPayload[key] as GeofenceShape[])];
          } else if (key === "underMin" || key === "overMax") {
            mergedObj[key] = [...(newPayload[key] as AssetType[])];
          } else {
            // @ts-ignore
            mergedObj[key] = [...(newPayload[key] as string[])];
          }
        } else {
          delete mergedObj[key];
        }
      }
    } else if (oldPayload[key]) {
      mergedObj[key] = oldPayload[key] as
        | (string[] & GeofenceShape[] & AssetType[] & boolean)
        | undefined
        | any;
    }
  });
  return mergedObj;
}

export const getCustomGeofenceFilterPayload = (
  filters: Partial<IGeofenceFilters>,
  oldPayload: GeofenceFiltersPayload | null
): GeofenceFiltersPayload => {
  let payload: GeofenceFiltersPayload = convertGeofenceFilters(filters);
  if (oldPayload) {
    const result = mergePayloads(oldPayload, payload);
    return result;
  } else {
    return payload;
  }
};

export const removeInvalidDateRangeFilters = (filtersList: FilterPayload[]) => {
  const filteredFilterList = filtersList.filter(
    (filter) =>
      filter?.dateRange?.startDate !== null &&
      filter?.dateRange?.endDate !== null
  );
  return filteredFilterList;
};

export const removeInvalidValueRangeFilters = (
  filtersList: FilterPayload[]
) => {
  const filteredFilterList = filtersList.filter(
    (filter) =>
      filter?.valueRange?.startValue !== null &&
      filter?.valueRange?.endValue !== null
  );
  return filteredFilterList;
};

export const removeInvalidValueRangesFilters = (
  filtersList: FilterPayload[]
) => {
  const filteredFilterList = filtersList.filter((filter) => {
    if (filter?.valueRanges && Array.isArray(filter.valueRanges)) {
      // Loop through each valueRange element and check startValue and endValue
      for (const range of filter.valueRanges) {
        if (range.startValue === null || range.endValue === null) {
          // If startValue or endValue is null, filter out the filter
          return false;
        }
      }
    }
    return true;
  });
  return filteredFilterList;
};
