import axios from "axios";
import { env } from "../../../env";
import { BatchTemplate } from "../../../graphql/operations";
import { getS3SignedUploadUrl } from "../../../services/aws/aws";
import { useUserData } from "../../../shared/hooks/useUserData";

const CONTENT_TYPE = "application/json";
const AXIOS_CONFIG = {
  headers: {
    "Content-Type": CONTENT_TYPE,
  },
};

export const useUploadJsonBatchFileToS3 = () => {
  const userData = useUserData();

  /**
   * Uploads a JSON batch file to S3
   *
   * @param {File} file
   * @param {BatchTemplate} batchTemplate
   * @param {string} batchName
   * @param {string} orgId
   * @returns {Promise<unknown>} Axios response
   */
  const uploadBatchFileToS3 = async (
    file: File,
    batchTemplate: BatchTemplate,
    batchName: string = "",
    orgId: string = ""
  ): Promise<unknown> => {
    // Handle errors
    if (!userData) {
      throw new Error("User data is missing.");
    }

    if (!file.name.endsWith(".json")) {
      throw new Error("Only JSON files are allowed for upload.");
    }
    const uploadUrl = getS3SignedUploadUrl({
      fileName: file.name,
      contentType: CONTENT_TYPE,
      bucket: env.REACT_APP_BULK_S3_BUCKET_NAME,
      bucketDirectory: "raw",
      metadata: {
        orgId,
        batchName,
        batchTemplate,
        userEmail: userData.email,
        userFirstName: userData.firstName!,
        userId: userData._id,
      },
    });

    const s3Response = await axios.put(uploadUrl, file, AXIOS_CONFIG);
    return s3Response;
  };

  return {
    uploadBatchFileToS3,
  };
};
