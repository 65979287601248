import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import {
  SharedAssetsInvitationResult,
  useGetSharedAssetsInvitationQuery,
} from "../../../graphql/operations";
import { ShareAssetsInvitation } from "./ShareAssetsInvitation";
import { ShareAssetsInvitationError } from "./ShareAssetsInvitationError";
import { SharedAssetInvitationServerErrors } from "./helpers";

export const ShareAssetsView: FC = () => {
  const { id: invitationId } = useParams();

  const {
    data: invitationData,
    isLoading: isInvitationLoading,
    isError,
    error,
  } = useGetSharedAssetsInvitationQuery(
    {
      input: {
        id: invitationId ?? "",
      },
    },
    { enabled: Boolean(invitationId) }
  );

  const invitation = useMemo(
    () => invitationData?.getSharedAssetsInvitation,
    [invitationData?.getSharedAssetsInvitation]
  );

  if (isError) {
    switch ((error as Error).message) {
      case SharedAssetInvitationServerErrors.InvitationNotFound:
        return (
          <ShareAssetsInvitationError
            title="Invitation not found"
            children={
              <Box>The invitation you are looking for does not exist.</Box>
            }
          />
        );
      case SharedAssetInvitationServerErrors.UnauthorizedAccess:
        return (
          <ShareAssetsInvitationError
            title="Unauthorized access"
            children={
              <Box>You do not have permissions to view this invitation.</Box>
            }
          />
        );
      case SharedAssetInvitationServerErrors.InvitationExpired:
        return (
          <ShareAssetsInvitationError
            title="Invitation has expired"
            children={
              <>
                <Box sx={{ marginBottom: "50px" }}>
                  The invitation you are trying to access has expired.
                </Box>
                <Box>
                  Reach out to your contact from the other organization to
                  create a new invitation.
                </Box>
              </>
            }
          />
        );
      default:
        return (
          <ShareAssetsInvitationError
            title="Error occurred"
            children={<Box>Please refresh the page and try again.</Box>}
          />
        );
    }
  }

  return (
    <Box
      className="flex flex-col w-full h-full"
      sx={{ padding: { xs: "0 16px", md: "0 64px" } }}
    >
      {isInvitationLoading ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="shared-assets-invitation-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ShareAssetsInvitation
          invitation={invitation as SharedAssetsInvitationResult}
        />
      )}
    </Box>
  );
};
