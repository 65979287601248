import cloneDeep from "lodash/cloneDeep";
import { AssetsSummary } from "../../../../graphql/operations";
import legendObject from "./legend";

function calculateFieldPercentage(count: number, total: number) {
  return Math.round((count / total) * 100);
}

export function calculateBucketPercentage(assetsSummary: AssetsSummary) {
  const currentMetrics = cloneDeep(legendObject);
  const totalLength = assetsSummary.total || 0;

  let firstBucketCount = assetsSummary.miles?.tillThirty || 0;
  let secondBucketCount = assetsSummary.miles?.thirtyToSixty || 0;
  let thirdBucketCount = assetsSummary.miles?.sixtyPlus || 0;

  if (totalLength) {
    currentMetrics.firstBucket.value = `${calculateFieldPercentage(
      firstBucketCount,
      totalLength
    )}%`;
    currentMetrics.secondBucket.value = `${calculateFieldPercentage(
      secondBucketCount,
      totalLength
    )}%`;
    currentMetrics.thirdBucket.value = `${calculateFieldPercentage(
      thirdBucketCount,
      totalLength
    )}%`;
  }

  return currentMetrics;
}
