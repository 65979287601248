import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { useCurrentTheme } from "../../../shared/hooks/theme/useCurrentTheme";

export const useFavoriteDashboardsDropdownTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
              fontWeight: "bold",
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              marginRight: "0.25rem",
              height: "18px",
              width: "18px",
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              marginTop: "1rem",
              minWidth: "240px",
              maxWidth: "280px",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              margin: "0.25rem 0.5rem",
              borderRadius: "0.5rem",
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontSize: "14px",
              fontWeight: "bold",
            },
          },
        },
      },
    })
  );
};
