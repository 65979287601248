import { FC, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { TileComponentProps } from "../../interfaces";
import DashboardTile from "../index";
import { calculateTotalDistance } from "./helpers";

// This component is currently hidden, because the lack of data
// Some parts of this component might be partially updated in future
const TotalDistance: FC<TileComponentProps> = ({
  tile,
  moveTile,
  findTile,
  clickHandler,
}) => {
  const formattedTotal: string = useMemo(() => calculateTotalDistance([]), []);

  return (
    <DashboardTile
      clickHandler={clickHandler}
      url={tile.url}
      moveTile={moveTile}
      findTile={findTile}
    >
      <Grid container data-testid="dashboard-tile-total-distance">
        <Grid container item className="!flex-col">
          <Grid item>
            <Typography
              data-testid="total-distance-value"
              className="!mb-1 !text-5xl !font-normal !text-blue"
            >
              {formattedTotal}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="!mb-1 !font-bold">
              Total Miles Traveled
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="!text-sm !font-normal">
              Last 24 Hours
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </DashboardTile>
  );
};

export default TotalDistance;
