import {
  ChangeEvent,
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { isArray } from "lodash";
import { FilterDaysRange, useAssetsDataContext } from "../../AssetsDataContext";

type StatusesTypes = {
  moving: boolean;
  parked: boolean;
  low: boolean;
  medium: boolean;
  high: boolean;
  mega: boolean;
  [key: string]: boolean;
};

export const capLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const StatusFilters: React.FC = () => {
  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const { onChangeFilters, searchParams, pageType } = useAssetsDataContext();

  const initialState: StatusesTypes = useMemo(
    () => ({
      moving: false,
      parked: false,
      low: false,
      medium: false,
      high: false,
      mega: false,
    }),
    []
  );

  const [statuses, setStatuses] = useState<StatusesTypes>(initialState);

  useEffect(() => {
    const filters = JSON.parse(searchParams.get("filters") ?? "{}");
    const selectedStatuses: string[] = filters?.status || [];

    if (isArray(selectedStatuses)) {
      const newState = Object.keys(initialState).reduce(
        (acc, key) => ({
          ...acc,
          [key]:
            selectedStatuses.includes(key) ||
            selectedStatuses.includes(capLetter(key)),
        }),
        initialState
      );

      setStatuses(newState);

      const { minDays, maxDays } = getValues();
      if (minDays) setValue("minDays", 0);
      if (maxDays) setValue("maxDays", 0);

      return;
    }

    const { minDays, maxDays } = selectedStatuses;

    if (minDays) setValue("minDays", minDays, { shouldValidate: true });
    if (maxDays) setValue("maxDays", maxDays, { shouldValidate: true });

    setStatuses(initialState);
  }, [searchParams, initialState, getValues, setValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...statuses,
      [event.target.name]: event.target.checked,
    };

    setStatuses(newState);

    const selectedStatuses = Object.keys(newState)
      .filter((key) => newState[key])
      .map((key) =>
        ["moving", "parked"].includes(key) ? key : capLetter(key)
      );

    onChangeFilters({
      status: selectedStatuses,
    });
  };

  const onChangeDays: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      async function exec() {
        const noError = await trigger(["minDays", "maxDays"]);
        e.stopPropagation();
        if (noError) {
          onChangeFilters({
            status: getValues() as FilterDaysRange,
          });
        }
      }
      void exec();
    },
    [onChangeFilters, trigger, getValues]
  );

  return (
    <Box className="mt-8 w-full">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Status</Box>
      </Box>

      <Box className="mb-3 flex flex-col">
        {Object.keys(statuses).map((label) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={statuses[label]}
                onChange={handleChange}
                name={label}
              />
            }
            label={capLetter(label)}
            data-testid={`status-filter-${label}`}
          />
        ))}
      </Box>

      <form>
        <Grid container spacing={1} columns={12}>
          <Grid item xs={6} data-testid="min-dwell-range">
            <Controller
              name="minDays"
              control={control}
              render={({ field }) => (
                <TextField
                  id="min-dwell-range"
                  placeholder="Min Days"
                  type="number"
                  inputProps={{ min: 0, "data-testid": "min-days" }}
                  InputLabelProps={{
                    shrink: true,
                    className: "!text-typography",
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(Number(e.target.value));
                    onChangeDays(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} data-testid="max-dwell-range">
            <Controller
              name="maxDays"
              control={control}
              render={({ field }) => (
                <TextField
                  id="max-dwell-range"
                  placeholder="Max Days"
                  type="number"
                  inputProps={{ min: 0, "data-testid": "max-days" }}
                  InputLabelProps={{
                    shrink: true,
                    className: "!text-typography",
                  }}
                  {...field}
                  onChange={(e) => {
                    field.onChange(Number(e.target.value));
                    onChangeDays(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} data-testid="days-range-error">
            <p className="h-5 text-[12px] text-error">{`${
              errors?.minDays?.message || ""
            }`}</p>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(StatusFilters);
