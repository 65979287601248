import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import { round } from "lodash";
import {
  AssetTableData,
  TableColumnFormat,
} from "../../../../../graphql/operations";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import {
  getTableColumn,
  TableColumnProps,
  TableGridColDef,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { AssetIcon, getIconType } from "../AssetIcon";

export enum IconTypeComplete {
  EMPTY,
  MOVING,
  LOW_DWELL,
  MEDIUM_DWELL,
  HIGH_DWELL,
  MEGA_DWELL,
  MOVING_ONLINE,
  LOW_DWELL_ONLINE,
  MEDIUM_DWELL_ONLINE,
  HIGH_DWELL_ONLINE,
  MEGA_DWELL_ONLINE,
  MOVING_EMPTY,
  LOW_DWELL_EMPTY,
  MEDIUM_DWELL_EMPTY,
  HIGH_DWELL_EMPTY,
  MEGA_DWELL_EMPTY,
  MOVING_ONLINE_EMPTY,
  LOW_DWELL_ONLINE_EMPTY,
  MEDIUM_DWELL_ONLINE_EMPTY,
  HIGH_DWELL_ONLINE_EMPTY,
  MEGA_DWELL_ONLINE_EMPTY,
  MOVING_LOADED,
  LOW_DWELL_LOADED,
  MEDIUM_DWELL_LOADED,
  HIGH_DWELL_LOADED,
  MEGA_DWELL_LOADED,
  MOVING_ONLINE_LOADED,
  LOW_DWELL_ONLINE_LOADED,
  MEDIUM_DWELL_ONLINE_LOADED,
  HIGH_DWELL_ONLINE_LOADED,
  MEGA_DWELL_ONLINE_LOADED,
}

export const columnVisibilityModel = {
  name: true,
  assetIdentifier: true,
  imei: true,
  associated: true,
  orgName: true,
  productName: true,
  category: true,
  city: true,
  state: true,
  zip: false,
  vin: false,
  tags: false,
  batteryStatus: true,
  dwellingDays: true,
  doorType: false,
  tripStatus: true,
  cargoState: true,
  batteryVolt: true,
  length: false,
  lastEventTime: true,
  lastGpsTime: false,
  numOfTires: false,
  numOfAxles: false,
  lon: false,
  lat: false,
  signal: false,
};

export const getIconValue = (
  params: GridValueGetterParams<any, AssetTableData>
) => {
  const iconType = getIconType(
    params.row.tripStatus,
    params.row.dwellingDays,
    params.row.signal,
    params.row.cargoState
  );
  const value = IconTypeComplete[iconType as keyof typeof IconTypeComplete];
  return value;
};

export const getColumns = (
  timezone: string
): TableGridColDef<AssetTableData>[] => {
  const columns: TableColumnProps<AssetTableData>[] = [
    { field: "orgName", headerName: "Organization Name" },
    {
      field: "icon",
      headerName: "Icon",
      options: {
        valueGetter: getIconValue,
        renderCell: (params) => (
          <AssetIcon
            dwellingDays={Number(params.row.dwellingDays)}
            signal={Boolean(params.row.signal)}
            cargo_state={String(params.row.cargoState)}
            trip_st={String(params.row.tripStatus)}
          />
        ),
      },
    },
    {
      field: "batteryVolt",
      headerName: "Battery Status",
      options: {
        renderCell: (params) => (
          <BatteryIcon
            chargeLevel={params.value ? round(params.value, 2) : undefined}
            svgProps={{ width: "24px", height: "24px" }}
            status={
              params.row.batteryStatus
                ? params.row.batteryStatus.toLowerCase()
                : ""
            }
          />
        ),
      },
    },
    { field: "assetIdentifier", headerName: "Asset ID" },
    {
      field: "name",
      headerName: "Asset Nickname",
    },
    { field: "imei", headerName: "Device ID" },
    { field: "productName", headerName: "Product Name" },
    {
      field: "lastEventTime",
      headerName: "Last Reported Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    { field: "associated", headerName: "Associated" },
    { field: "tripStatus", headerName: "Trip Status" },
    { field: "city", headerName: "City" },
    { field: "state", headerName: "State" },
    { field: "zip", headerName: "zip" },
    { field: "category", headerName: "Asset Type" },
    { field: "tags", headerName: "Asset Tags" },
    { field: "lat", headerName: "Latitude" },
    { field: "lon", headerName: "Longitude" },
    { field: "vin", headerName: "VIN" },
    { field: "numOfTires", headerName: "# of Tires" },
    { field: "numOfAxles", headerName: "# of Axles" },
    { field: "length", headerName: "Length" },
    { field: "doorType", headerName: "Door Type" },
    {
      field: "lastGpsTime",
      headerName: "GPS Time",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
  ];

  return columns.map(getTableColumn);
};
