import { FC } from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as AirbagSensorDark } from "../../../../../../assets/svgs/airbagSensorDark.svg";
import { ReactComponent as AirbagSensorLight } from "../../../../../../assets/svgs/airbagSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  Asset,
  SensorStatusUppercase,
} from "../../../../../../graphql/operations";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { axleLabels } from "../AirBagStatusCards/AirBagStatusCards";
import { AirBagHexCode } from "../AirBagStatusCards/helpers";
import AirBagStatusWrapperImg from "./AirBagStatusWrapperImg";

interface AirBagStatusProps {
  selectedAsset: Asset;
}

const AirBagStatus: FC<AirBagStatusProps> = ({ selectedAsset }) => {
  const { sensors } = selectedAsset;
  const sensorData = sensors?.airbag?.data?.measures ?? [];

  const leftAirBagStatus = sensorData.find(
    (measure) =>
      measure?.location_code_hex_str === AirBagHexCode.LeftAirBagHexCode
  )?.statusTire_pressure_mbar;
  const rightAirBagStatus = sensorData.find(
    (measure) =>
      measure?.location_code_hex_str === AirBagHexCode.RightAirBagHexCode
  )?.statusTire_pressure_mbar;

  const svgStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block",
  };

  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };

  return (
    <>
      {sensorData.length > 0 && (
        <Grid item xs={12} lg={6} className="w-full flex">
          <Box
            className="mb-4 w-full flex flex-col bg-dashboard_subheader__bg rounded-lg pr-4 pt-4 pl-4 pb-20"
            data-testid="airbag-status-box"
          >
            <Box className="pl-4">
              <SensorHeader title="Air Bag Status" noBorder>
                {isLightTheme ? (
                  <AirbagSensorDark style={svgIconSettings} />
                ) : (
                  <AirbagSensorLight style={svgIconSettings} />
                )}
              </SensorHeader>
            </Box>
            <>
              {sensorData.map((_, index: number) => {
                if (index % 2 === 0 && index < 6) {
                  const axleName = axleLabels.get(index);

                  return (
                    <Grid
                      className="pl-6 pr-6 pt-20"
                      container
                      direction="row"
                      wrap="nowrap"
                      alignItems="center"
                      key={`index-${index}`}
                      data-testid="airbag-status-grid"
                    >
                      <Grid item xs={2} style={svgStyle}>
                        <AirBagStatusWrapperImg
                          status={
                            leftAirBagStatus ?? SensorStatusUppercase.Unknown
                          }
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <div
                          style={{
                            textAlign: "center",
                            color: "var(--Grayscale-Light-Charcoal, #9C9C9C)",
                            borderBottom:
                              "4px solid var(--Grayscale-Feather-Gray, #F2F2F2)",
                          }}
                        >
                          {axleName}
                        </div>
                      </Grid>
                      <Grid item xs={2} style={svgStyle}>
                        <AirBagStatusWrapperImg
                          status={
                            rightAirBagStatus ?? SensorStatusUppercase.Unknown
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                }
                return null;
              })}
            </>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default AirBagStatus;
