import { FC, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  PAGE_SNACKBAR,
  USERS_UPLOAD_FILE_TEMPLATE_PATH,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { BatchTemplate } from "../../../../../graphql/operations";
import { parseContentAsJsonBlobFile } from "../../../../../shared/components/TableActionsMenu/helpers";
import UploadFileDialog from "../../../../../shared/components/UploadFileDialog/UploadFileDialog";
import UploadResult from "../../../../../shared/components/UploadFileDialog/UploadResult";
import { ParseFileContentError } from "../../../../../utils";
import {
  BatchUploadDialogProps,
  useBatchUploadForm,
} from "../../../../BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../../../BatchesView/hooks/useUploadJsonBatchFileToS3";
import { mapFileDataToCreateUserInput, parseUsersFile } from "./helpers";
import { ValidatedUser } from "./types";

export const UploadUsersDialog: FC<BatchUploadDialogProps> = ({
  title,
  customerOrg,
  dialogFields,
  isOpen,
  onClose,
  onUpload,
}: BatchUploadDialogProps) => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useBatchUploadForm(title, customerOrg);

  const resetState = () => {
    setIsUsersUploading(false);
    setIsUsersUploaded(false);
    setParseFileResult({ validatedUsers: [], validationErrors: [] });
  };

  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isUsersUploading, setIsUsersUploading] = useState(false);
  const [isUsersUploaded, setIsUsersUploaded] = useState(false);
  const [parseFileResult, setParseFileResult] = useState<{
    validatedUsers: ValidatedUser[];
    validationErrors: ParseFileContentError[];
  }>({ validatedUsers: [], validationErrors: [] });

  const handleSubmit = async (file: File) => {
    setIsUsersUploading(true);

    const {
      org_select: { id: orgId, label: orgName },
      batch_name,
    } = getValues();

    try {
      const { fileData, errors: parseErrors } = await parseUsersFile(file);

      const { validatedUsers, validationErrors } = mapFileDataToCreateUserInput(
        fileData,
        orgId,
        orgName
      );
      setParseFileResult({
        validatedUsers,
        validationErrors: [...validationErrors, ...parseErrors],
      });

      const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
      setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

      const users = validatedUsers.map(({ user }) => user);
      //parseContentAsJsonBlobFile
      const jsonFile = parseContentAsJsonBlobFile(users, fileName);
      await uploadBatchFileToS3(
        jsonFile,
        BatchTemplate.CreateUsers,
        batch_name,
        orgId
      );
    } catch (error) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Users Uploading Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
      setIsUsersUploading(false);
      setIsUsersUploaded(true);
    }

    setIsUsersUploading(false);
    setIsUsersUploaded(true);

    if (onUpload) {
      onUpload();
    }

    queryClient.invalidateQueries(["findUsers"]);
  };

  const onFilesUploadDialogClose = () => {
    onClose();
    resetState();
  };

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      onClose={onFilesUploadDialogClose}
      handleSubmit={handleSubmit}
      isDataUploading={isUsersUploading}
      isDataUploaded={isUsersUploaded}
      downloadTemplateFilePath={USERS_UPLOAD_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={parseFileResult.validationErrors}
          itemName={"user"}
        />
      }
    />
  );
};
