export const assetsSummary = {
  total: 3130,
  moving: 1000,
  parked: 2000,
  miles: {
    tillThirty: 2000,
    thirtyToSixty: 800,
    sixtyPlus: 220,
  },
  dwell: {
    low: 800,
    medium: 500,
    high: 400,
    mega: 300,
  },
};

export const tileTypes = {
  totalAssets: "totalAssets",
  activeAssets: "activeAssets",
  totalDistance: "totalDistance",
};

export const totalDistanceList = [
  {
    _id: "1",
    tripStatus: {
      odometer: 1200,
    },
  },
  {
    _id: "2",
    tripStatus: {
      odometer: 111200,
    },
  },
  {
    _id: "3",
    tripStatus: {
      odometer: 61009,
    },
  },
  {
    _id: "4",
    tripStatus: {
      odometer: 5555,
    },
  },
  {
    _id: "5",
    tripStatus: {
      odometer: 9999,
    },
  },
  {
    _id: "6",
    tripStatus: {
      odometer: 71888,
    },
  },
  {
    _id: "7",
    tripStatus: {
      odometer: 37892,
    },
  },
  {
    _id: "8",
    tripStatus: {
      odometer: 1,
    },
  },
  {
    _id: "9",
    tripStatus: {
      odometer: 86454,
    },
  },
  {
    _id: "10",
    tripStatus: {
      odometer: 44700,
    },
  },
  {
    _id: "11",
    tripStatus: {
      odometer: 1498,
    },
  },
  {
    _id: "12",
    tripStatus: {
      odometer: 55000,
    },
  },
  {
    _id: "13",
    tripStatus: {
      odometer: 66010,
    },
  },
];

//dummy tiles
export const dummyTiles = [
  {
    id: 1,
    url: "/assets/table",
    type: tileTypes.totalAssets,
  },
  {
    id: 2,
    url: "/assets/table",
    type: tileTypes.activeAssets,
  },
];
