import { FC, MutableRefObject } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CargoSensorDark } from "../../../../../../assets/svgs/cargoSensorDark.svg";
import { ReactComponent as CargoSensorLight } from "../../../../../../assets/svgs/cargoSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import SensorHeader from "../../../../../../shared/components/SensorHeader/SensorHeader";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import { BAR_HEIGHT, CargoData, calculateCargoStatusLast30Days } from "./utils";

export interface CargoStatusInLocationsProps {
  data: Array<CargoData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const CargoStatus: FC<CargoStatusInLocationsProps> = ({ data, parentRef }) => {
  const { emptyCargoDays, loadedCargoDays } =
    calculateCargoStatusLast30Days(data);
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "5rem",
    height: "5rem",
    display: "block",
  };
  const { width } = useContainerDimensions(parentRef);

  // layout grid parent dimensions
  const gridItemPaddings = 32;
  const gridItemGapDistance = 16;

  // card layout dimensions
  const labelWidth = 55;
  const margins = 18;
  const paddings = 18 + gridItemPaddings + gridItemGapDistance;

  const loadedDays = loadedCargoDays;
  const emptyDays = emptyCargoDays;

  // calculate percentage based on 30 days
  const loadedDaysInPercentage = (loadedDays / 30) * 100;
  const emptyDaysInPercentage = (emptyDays / 30) * 100;

  // calculate percentage based on bars possible width
  const barMaxWidth = width - (labelWidth + paddings + margins);
  const loadedBarPercentage = (loadedDaysInPercentage / 100) * barMaxWidth;
  const unloadedBarPercentage = (emptyDaysInPercentage / 100) * barMaxWidth;

  return (
    <Box
      className="text-sm w-full inline-block p-4"
      data-testid="last-30-days-loaded-unloaded"
    >
      <SensorHeader title="Cargo Sensor">
        {isLightTheme ? (
          <CargoSensorDark style={svgIconSettings} />
        ) : (
          <CargoSensorLight style={svgIconSettings} />
        )}
      </SensorHeader>

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="battery-widget-label"
      >
        Cargo Status Last 30 Days
      </Typography>
      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Empty
        </Typography>

        <Box
          className="!mr-2 rounded inline-block align-middle text-center text-card-sensors-text"
          data-testid="empty-days"
          style={{
            color: `var(--${emptyDays ? "white" : "primary"})`,
            background: "var(--indigo)",
            width: `${unloadedBarPercentage}px`,
            height: `${BAR_HEIGHT}px`,
            maxWidth: barMaxWidth,
          }}
        >
          {emptyDays}
        </Box>
      </Box>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Loaded
        </Typography>

        <Box
          data-testid="loaded-days"
          className="!mr-2 rounded inline-block align-middle text-center"
          style={{
            color: `var(--${loadedDays ? "white" : "primary"})`,
            background: "var(--indigo)",
            width: `${loadedBarPercentage}px`,
            height: `${BAR_HEIGHT}px`,
          }}
        >
          {loadedDays}
        </Box>
      </Box>
    </Box>
  );
};

export default CargoStatus;
