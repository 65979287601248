import { memo, FC, ReactElement, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ArticleIcon from "@mui/icons-material/Article";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MapIcon from "@mui/icons-material/Map";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SchoolIcon from "@mui/icons-material/School";
import SensorsIcon from "@mui/icons-material/Sensors";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { orderBy } from "lodash";
import { EXTERNAL_HELP_URL } from "../../../../../constants";
import { useAuthContext } from "../../../../../context/AuthContext";
import { env } from "../../../../../env";
import { useFeatureFlag } from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import {
  adminPanelTabs,
  AdminPanelTabs,
} from "../../../../../views/AdminPanel/adminPanelUtils";
import NavListItem from "../NavListItem";

type ViewRights =
  | "dashboard"
  | "asset"
  | "user"
  | "org"
  | "configurations"
  | "device"
  | "reports"
  | "geofences"
  | "batches"
  | "alerts"
  | "brands"
  | "profiles"
  | "help"
  | "adminPanel";

export type NavItemChildrenType = {
  viewRights: ViewRights;
  name: AdminPanelTabs;
  id: string;
  route: NavigationRoutes;
};

export type NavItemsType = {
  name: string;
  icon: ReactElement;
  id: string;
  viewRights: ViewRights;
  route?: NavigationRoutes;
  url?: string;
  openInNewTab?: boolean;
  children?: NavItemChildrenType[];
};

export const NAV_ITEMS: NavItemsType[] = [
  {
    route: NavigationRoutes.Dashboard,
    name: "Dashboards",
    icon: <DashboardIcon />,
    id: "dashboard-cta",
    viewRights: "dashboard",
  },
  {
    route: NavigationRoutes.Assets,
    name: "Assets",
    icon: <LocalShippingIcon />,
    id: "assets-cta",
    viewRights: "asset",
  },
  {
    route: NavigationRoutes.Geofences,
    name: "Geofences",
    icon: <MapIcon />,
    id: "geofences-cta",
    viewRights: "geofences",
  },
  {
    route: NavigationRoutes.Devices,
    name: "Devices",
    icon: <SensorsIcon />,
    id: "devices-cta",
    viewRights: "device",
  },
  {
    route: NavigationRoutes.Batches,
    name: "Batches",
    icon: <DynamicFeedIcon />,
    id: "batches-cta",
    viewRights: "batches",
  },
  {
    route: NavigationRoutes.Reports,
    name: "Reports",
    icon: <ArticleIcon />,
    id: "reports-cta",
    viewRights: "reports",
  },
  {
    route: NavigationRoutes.Alerts,
    name: "Alerts",
    icon: <NotificationsNoneIcon />,
    id: "alerts-cta",
    viewRights: "alerts",
  },
  {
    name: "Help",
    icon: <SchoolIcon />,
    id: "help-cta",
    viewRights: "help",
    url: EXTERNAL_HELP_URL,
    openInNewTab: true,
  },
  {
    route: NavigationRoutes.AdminPanel,
    name: "Admin Panel",
    icon: <AdminPanelSettingsRoundedIcon />,
    id: "admin-panel-cta",
    viewRights: "adminPanel",
    children: [...adminPanelTabs] as NavItemChildrenType[],
  },
];

interface NavListProps {
  onItemClick?: () => void;
}

export const NavList: FC<NavListProps> = (props) => {
  const { onItemClick } = props;

  const location = useLocation();
  const { pathname } = location;

  const [selectedMenuItemState, setSelectedMenuItemState] = useState<
    number | undefined
  >();

  const { userRolePermissions } = useAuthContext();
  const geoFancingFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1RolloutGeofencing
  );
  const reportsFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1RolloutReports
  );
  const alertsFeatureFlag = useFeatureFlag(FeatureFlags.Connect1RolloutAlerts);
  const performanceFeatureFlag =
    env.REACT_APP_ROLLOUT_API_PERFORMANCE === "true";
  // useFeatureFlag("connect1RolloutApiPerformance") || true; // defaulting it to true for now
  if (!performanceFeatureFlag) {
    const assetMapIndex = NAV_ITEMS.findIndex(
      (item) => item.id === "v2-asset-map-cta"
    );
    if (assetMapIndex !== -1) {
      NAV_ITEMS.splice(assetMapIndex, 1);
    }
    const assetListIndex = NAV_ITEMS.findIndex(
      (item) => item.id === "v2-asset-list-cta"
    );
    if (assetListIndex !== -1) {
      NAV_ITEMS.splice(assetListIndex, 1);
    }
  }

  if (!geoFancingFeatureFlag) {
    const index = NAV_ITEMS.findIndex((item) => item.id === "geofences-cta");
    if (index !== -1) {
      NAV_ITEMS.splice(index, 1);
    }
  }

  if (!reportsFeatureFlag) {
    const index = NAV_ITEMS.findIndex((item) => item.id === "reports-cta");
    if (index !== -1) {
      NAV_ITEMS.splice(index, 1);
    }
  }

  if (!alertsFeatureFlag) {
    const index = NAV_ITEMS.findIndex((item) => item.id === "alerts-cta");
    if (index !== -1) {
      NAV_ITEMS.splice(index, 1);
    }
  }

  const navigate = useNavigate();

  return (
    <div data-testid="side-menu_nav-list" className="px-2">
      {NAV_ITEMS.filter(
        //@ts-ignore
        ({ viewRights }) => userRolePermissions?.[viewRights]?.view
      ).map((navItem, index) => {
        const { name, icon, route, id, children, url, openInNewTab } = navItem;

        if (children?.length) {
          return (
            <Accordion
              key={id}
              expanded={selectedMenuItemState === index}
              sx={{
                "&::before": {
                  display: "none",
                },
              }}
            >
              <NavListItem
                onClick={() =>
                  setSelectedMenuItemState((prevIndex) =>
                    prevIndex === index ? undefined : index
                  )
                }
                className="!pt-0 !pb-0"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ padding: 0, fontSize: "1rem", margin: 0 }}
                >
                  <div className="flex font-bold">
                    <span className="mr-2.5 h-6 w-6">{icon}</span>
                    <span>{name}</span>
                  </div>
                </AccordionSummary>
              </NavListItem>
              <AccordionDetails
                sx={{
                  padding: "0 !important",
                }}
              >
                <div className="mb-2">
                  {orderBy(children, ["name"], ["asc"])
                    .filter(
                      ({ viewRights }) =>
                        userRolePermissions?.[viewRights]?.view
                    )
                    .map((tab, childIndex) => (
                      <NavListItem
                        key={tab.id}
                        onClick={() => {
                          if (pathname !== tab.route) {
                            navigate(tab.route, {
                              state: { activeTabIndex: childIndex },
                            });
                          }

                          if (onItemClick) {
                            onItemClick();
                          }
                        }}
                        text={tab.name}
                        className="!font-normal ml-3"
                      />
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        }
        return (
          <NavListItem
            key={name}
            id={id}
            onClick={() => {
              if (url) {
                if (openInNewTab) {
                  window.open(url, "_blank");
                } else {
                  window.open(url);
                }
              }

              if (route && pathname !== route) {
                navigate(route);
              }

              if (onItemClick) {
                onItemClick();
              }
            }}
          >
            <div className="flex">
              <p className="mr-2.5 h-6 w-6">{icon}</p>
              <b>{name}</b>
            </div>
          </NavListItem>
        );
      })}
    </div>
  );
};

export default memo(NavList);
