import { FC, useCallback, useEffect } from "react";
import { FieldValues } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Drawer,
  Grid,
  Typography,
  Button as CancelButton,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Position } from "geojson";
import { pick, isEmpty, omitBy } from "lodash";
import {
  LOCATION_CHANGE,
  PAGE_SNACKBAR,
  SET_PARENT_GEOFENCE,
  USE_PARENT_GEOFENCE_ORGID,
} from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  CreateGeofenceInput,
  GeofenceData,
  GeofenceShape,
  useCreateGeofenceMutation,
  useFindSubGeofencesQuery,
  useUpdateGeofenceMutation,
} from "../../../../../graphql/operations";
import { Button } from "../../../../../shared/components/Button";
import { SearchAddressField } from "../../../../../shared/components/SearchAddressField/SearchAddressField";
import ToggleButtons, {
  ToggleButtonOption,
} from "../../../../../shared/components/ToggleButtons";
import { useSpinner } from "../../../../../shared/hooks/useSpinner";
import { FormFieldDropdownOption } from "../../../../../types";
import {
  getGeocodeFromCoordinates,
  mapGeocodeResultToGeofenceAddressInput,
  mapServerErrorCodeToHumanReadableMessage,
} from "../../../../../utils";
import { isGeofenceInsideParent } from "../../../helpers/helpers";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { SelectedValueType } from "../../../shared/AssetsFilterControls/Filters/AssetFilters/AssetFilters";
import { GeofenceForm } from "../GeofenceFormComponent/GeofenceForm";
import { useGeofenceForm } from "../GeofenceFormComponent/useGeofenceForm";
import GeofenceShapeControls from "../GeofenceShapeControls/GeofenceShapeControls";
import { redirectWithoutId } from "./helpers";

interface AddGeofenceDrawerProps {
  open: boolean;
  setOpen: (flag: boolean) => void;
  changeDrawMode: (geofenceType: GeofenceShape) => void;
  resetDrawManager: () => void;
  undoDrawManager: () => void;
  cleanUpMap: () => void;
  existingGeofence?: GeofenceData | null;
  isEditGeofence?: boolean;
}

const CreateGeofenceInputKeys: Array<keyof CreateGeofenceInput> = [
  "orgId",
  "geofence",
  "configuration",
  "metadata",
];

export const AddGeofenceDrawer: FC<AddGeofenceDrawerProps> = ({
  open,
  setOpen,
  changeDrawMode,
  resetDrawManager,
  undoDrawManager,
  existingGeofence,
  isEditGeofence,
  cleanUpMap,
}) => {
  const queryClient = useQueryClient();
  const { dispatch, state } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    drawnGeofence,
    setDrawnGeofence,
    isDrawingGeofence,
    setIsDrawingGeofence,
    setGeofenceForUpdate,
    setSelectedGeofence,
    geofences,
    setGeofences,
    isGeofenceFormDirty,
    setIsGeofenceFormDirty,
    drawnGeofenceArea,
    setDrawnGeofenceArea,
    drawnGeofenceAreaInSqKm,
    setDrawnGeofenceAreaInSqKm,
    drawnGeofenceType,
    setDrawnGeofenceType,
    setDrawerFocused,
    restoreGeofencePreviousFilters,
    resetGeofenceFiltersToDefault,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
    setAddGeofenceDrawerOpen,
  } = useAssetsDataContext();
  const { form } = useGeofenceForm(existingGeofence as Partial<GeofenceData>);

  useEffect(() => {
    form.reset();
  }, [open, form]);

  useEffect(() => {
    if (state.geofence?.useParentGeofenceOrgId) {
      form.setValue("orgId", state.geofence?.parentGeofence?.orgId);
      form.setValue(
        "configuration.parentId",
        state.geofence?.parentGeofence?._id
      );
    }
  }, [
    state.geofence?.parentGeofence,
    state.geofence?.useParentGeofenceOrgId,
    form,
  ]);

  const { mutateAsync: createAsync, isLoading: createIsLoading } =
    useCreateGeofenceMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["findGeofences"] });
        await queryClient.invalidateQueries({
          queryKey: ["findGeofencesByOrgIds"],
        });
        setOpen(false);
        setSelectedGeofence(null);
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Created Successfully!",
            text: "You can find the Geofence in the table",
            severity: "success",
          },
        });
        handleResetState();
        redirectWithoutId(navigate, location);
      },
    });

  const { mutateAsync: updateAsync, isLoading: updateIsLoading } =
    useUpdateGeofenceMutation({
      onSuccess: async (updated) => {
        if (
          updated?.updateGeofence?.geofenceData?._id &&
          Object.values(updated?.updateGeofence?.geofenceData).length
        ) {
          setOpen(false);
          setGeofenceForUpdate(null);
          const updatedGeofences = geofences.map((geofence) =>
            geofence._id === updated?.updateGeofence?.geofenceData?._id
              ? updated.updateGeofence.geofenceData
              : geofence
          );
          setGeofences(updatedGeofences as GeofenceData[]);
          setSelectedGeofence(updated.updateGeofence.geofenceData);
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Geofence Update",
              text: "Geofence Updated Successfully!",
              severity: "success",
            },
          });
          handleResetState();
          handleRedirect();
          await queryClient.invalidateQueries({ queryKey: ["findGeofences"] });
        }
      },
    });

  const { data: subGeofences } = useFindSubGeofencesQuery({
    input: {
      geofenceId: existingGeofence ? existingGeofence._id : "",
    },
  });

  const onCreate = useCallback(
    async (data: FieldValues) => {
      dispatch({
        type: SET_PARENT_GEOFENCE,
        payload: null,
      });
      dispatch({
        type: USE_PARENT_GEOFENCE_ORGID,
        payload: false,
      });
      const valid = await form.trigger();
      if (!drawnGeofence) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Creation Failed",
            text: "You need to draw the geofence first",
            severity: "error",
          },
        });
        return;
      }

      const geofence = pick(
        data,
        CreateGeofenceInputKeys
      ) as CreateGeofenceInput;
      const coordinates = {
        type: drawnGeofence.geometry.type,
        coordinates:
          drawnGeofence.geometry.type === "Polygon"
            ? [...drawnGeofence.geometry.coordinates]
            : [[[]]],
      };
      if (state.geofence?.parentGeofence) {
        const isInside = isGeofenceInsideParent(
          coordinates.coordinates,
          state.geofence?.parentGeofence.geofence.coordinates!
            .coordinates as Position[][]
        );
        if (!isInside) {
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Geofence Creation Failed",
              text: "Sub-geofence must completely be inside the Parent Geofence",
              severity: "error",
            },
          });
          return;
        }
      }
      const geocodeResults = await getGeocodeFromCoordinates(
        drawnGeofence.properties?.centroid.lat,
        drawnGeofence.properties?.centroid.lon
      );
      const geofenceAddressInput = mapGeocodeResultToGeofenceAddressInput(
        geocodeResults[0]
      );
      const geofenceInput = {
        ...geofence,
        orgId: geofence?.orgId as unknown as FormFieldDropdownOption,
        geofence: {
          ...omitBy(geofence.geofence, isEmpty),
          coordinates,
          centroid: drawnGeofence.properties?.centroid,
          gisConfig: {
            shape: drawnGeofenceType,
          },
          fullAddress: geofenceAddressInput,
          geofenceArea: drawnGeofenceAreaInSqKm,
        },
        configuration: {
          ...geofence.configuration,
          typeId: data.configuration?.typeId,
          subTypeId: data.configuration?.subTypeId,
        },
      };
      if (!valid) {
        return;
      }

      try {
        await createAsync({
          geofence: geofenceInput as unknown as CreateGeofenceInput,
        });
      } catch (error) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Creation Failed",
            text: mapServerErrorCodeToHumanReadableMessage(
              error instanceof Error ? error.message : "Something Went Wrong."
            ),
            severity: "error",
          },
        });
      }
    },
    [
      form,
      drawnGeofence,
      state.geofence?.parentGeofence,
      drawnGeofenceType,
      drawnGeofenceAreaInSqKm,
      dispatch,
      createAsync,
    ]
  );

  const handleRedirect = useCallback(() => {
    if (existingGeofence) {
      navigate(`/assets/geofences/${existingGeofence._id}`);
    } else {
      navigate(`/assets/geofences`);
    }
    setOpen(false);
  }, [navigate, existingGeofence, setOpen]);

  const handleResetState = useCallback(() => {
    if (isDrawingGeofence) {
      restoreGeofencePreviousFilters();
    } else {
      resetGeofenceFiltersToDefault();
    }
    dispatch({
      type: SET_PARENT_GEOFENCE,
      payload: null,
    });
    dispatch({
      type: USE_PARENT_GEOFENCE_ORGID,
      payload: false,
    });
    setIsDrawingGeofence(false);
    setDrawnGeofenceType(null);
    setDrawnGeofence(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);

    setAddGeofenceDrawerOpen(false);
    // Open the drawers back
    setIsAssetsDrawerOpen(true);
    setIsFiltersDrawerOpen(true);

    cleanUpMap();
  }, [
    isDrawingGeofence,
    setIsDrawingGeofence,
    setDrawnGeofenceType,
    setDrawnGeofence,
    setDrawnGeofenceArea,
    setDrawnGeofenceAreaInSqKm,
    cleanUpMap,
    dispatch,
    restoreGeofencePreviousFilters,
    resetGeofenceFiltersToDefault,
    setAddGeofenceDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
  ]);

  const handleCancel = useCallback(() => {
    handleRedirect();
    handleResetState();
  }, [handleRedirect, handleResetState]);

  const removeDuplicateCoordinates = (coordinates: number[][]): number[][] => {
    return coordinates.filter(
      (coord, index, self) =>
        index === 0 || JSON.stringify(coord) !== JSON.stringify(self[index - 1])
    );
  };

  const onUpdate = useCallback(
    async (data: FieldValues) => {
      dispatch({
        type: SET_PARENT_GEOFENCE,
        payload: null,
      });
      dispatch({
        type: USE_PARENT_GEOFENCE_ORGID,
        payload: false,
      });
      const valid = await form.trigger();
      if (!existingGeofence) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Update Failed",
            text: "You need to select the geofence for update first",
            severity: "error",
          },
        });
        return;
      }
      let coordinates: { type: string; coordinates: Position[][] } | undefined =
        undefined;
      if (drawnGeofence && drawnGeofence.geometry.type === "Polygon") {
        const uniqueCoordinates = removeDuplicateCoordinates(
          drawnGeofence.geometry.coordinates[0]
        );
        coordinates = {
          type: "Polygon",
          coordinates: [uniqueCoordinates],
        };
        if (data.configuration.parentId) {
          const parentGeofence = geofences.find(
            (geofence) => geofence._id === data.configuration.parentId
          );
          if (parentGeofence) {
            const isInside = isGeofenceInsideParent(
              coordinates.coordinates,
              parentGeofence.geofence.coordinates!.coordinates as Position[][]
            );
            if (!isInside) {
              dispatch({
                type: PAGE_SNACKBAR,
                payload: {
                  title: "Geofence Updating Failed",
                  text: "Sub-geofence must completely be inside the Parent Geofence",
                  severity: "error",
                },
              });
              return;
            }
          }
        }
        if (subGeofences?.findSubGeofences?.length) {
          const isSubGeofencesContainedInParent: boolean[] = [];

          subGeofences.findSubGeofences.forEach((subGeofence) => {
            isSubGeofencesContainedInParent.push(
              isGeofenceInsideParent(
                subGeofence?.geofence.coordinates?.coordinates as Position[][],
                coordinates?.coordinates as Position[][]
              )
            );
          });

          if (isSubGeofencesContainedInParent.includes(false)) {
            dispatch({
              type: PAGE_SNACKBAR,
              payload: {
                title: "Geofence Updating Failed",
                text: "Parent geofence must include all subgeofences within the borders",
                severity: "error",
              },
            });
            return;
          }
        }
      }
      let geofenceAddressInput;
      if (drawnGeofence) {
        const geocodeResults = await getGeocodeFromCoordinates(
          drawnGeofence.properties?.centroid.lat,
          drawnGeofence.properties?.centroid.lon
        );

        geofenceAddressInput = mapGeocodeResultToGeofenceAddressInput(
          geocodeResults[0]
        );
      }
      const geofenceInput = {
        _id: existingGeofence._id,
        orgId: data.orgId,
        configuration: {
          ...data.configuration,
          typeId: data.configuration?.typeId,
          subTypeId: data.configuration?.subTypeId,
        },
        geofence: {
          name: data.geofence.name,
          fullAddressFormatted: data.geofence?.fullAddressFormatted,
          description: data.geofence?.description,
          code: data.geofence?.code,
          tags: data.geofence?.tags,
          fullAddress: drawnGeofence
            ? geofenceAddressInput
            : existingGeofence.geofence.fullAddress,
          coordinates,
          geofenceArea: drawnGeofenceAreaInSqKm,
        },
        metadata: {
          owner: data.metadata?.owner,
        },
      };
      if (!valid) {
        return;
      }
      try {
        await updateAsync({
          geofence: geofenceInput,
        });
      } catch (error) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Update Failed",
            text: mapServerErrorCodeToHumanReadableMessage(
              error instanceof Error ? error.message : "Something Went Wrong."
            ),
            severity: "error",
          },
        });
      }
    },
    [
      form,
      updateAsync,
      dispatch,
      existingGeofence,
      drawnGeofence,
      geofences,
      subGeofences?.findSubGeofences,
      drawnGeofenceAreaInSqKm,
    ]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmitClick = () => {
    if (existingGeofence) {
      form.handleSubmit((e) => {
        onUpdate(e);
        restoreGeofencePreviousFilters();
      })();
    } else {
      form.handleSubmit((e) => {
        onCreate(e);
        restoreGeofencePreviousFilters();
      })();
    }
  };

  useEffect(() => {
    if (form && form.formState && form.formState.dirtyFields) {
      if (!isEmpty(form.formState.dirtyFields) || drawnGeofence) {
        setIsGeofenceFormDirty(true);
      } else {
        setIsGeofenceFormDirty(false);
      }
    }
  }, [form.formState, drawnGeofence, setIsGeofenceFormDirty, form]);

  useEffect(() => {
    if (existingGeofence?.geofence.gisConfig?.shape) {
      setDrawnGeofenceType(existingGeofence.geofence.gisConfig.shape);
    }
  }, [existingGeofence, setDrawnGeofenceType]);

  useSpinner(createIsLoading || updateIsLoading);

  const GEOFENCE_SHAPE_OPTIONS: ToggleButtonOption[] = [
    {
      value: GeofenceShape.Polygon,
      label: "Polygon",
    },
    {
      value: GeofenceShape.Circle,
      label: "Radius",
    },
  ];

  const onChangeShape = useCallback(
    (value: SelectedValueType) => {
      setDrawnGeofenceType(value as GeofenceShape);
      changeDrawMode(value as GeofenceShape);
      setDrawnGeofenceArea(null);
      setDrawnGeofenceAreaInSqKm(null);
    },
    [
      setDrawnGeofenceType,
      changeDrawMode,
      setDrawnGeofenceArea,
      setDrawnGeofenceAreaInSqKm,
    ]
  );

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      const drawer = document.getElementById("geofence-drawer");
      if (drawer) {
        if (drawer.contains(targetElement)) {
          setDrawerFocused(true);
        } else {
          setDrawerFocused(false);
        }
      }
    };

    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [setDrawerFocused]);

  useEffect(() => {
    if (open && !existingGeofence) {
      dispatch({
        type: SET_PARENT_GEOFENCE,
        payload: null,
      });
      dispatch({
        type: USE_PARENT_GEOFENCE_ORGID,
        payload: false,
      });
    }
  }, [open, existingGeofence, dispatch]);

  return (
    <Drawer
      data-testid="add-geofence-drawer"
      anchor={"right"}
      open={open}
      hideBackdrop={true}
      variant="persistent"
      id="geofence-drawer"
      className="!z-[1202]"
      classes={{
        paper: "w-full sm:w-[375px] !top-auto",
      }}
      PaperProps={{
        sx: {
          height: "calc(100% - 4.25rem - 68px)",
        },
      }}
      onClose={handleClose}
    >
      <div className="flex h-full flex-col">
        <div className="relative flex w-full flex-initial justify-between border-b border-concrete bg-background p-6 !text-primary">
          <div
            className="flex-auto text-3xl font-semibold"
            data-testid="add-geofence-drawer-header"
          >
            Geofence Details
          </div>
        </div>
        <div className="h-full flex-auto overflow-auto">
          <Grid container className="flex-initial bg-background p-6 gap-5">
            <Grid item xs={12}>
              <SearchAddressField
                placeholder="Type Address To Zoom"
                onChange={(_, option) => {
                  dispatch({
                    type: LOCATION_CHANGE,
                    payload: option?.formatted,
                  });
                }}
              />
            </Grid>
            {drawnGeofenceType && (
              <GeofenceShapeControls
                isEditGeofence={isEditGeofence}
                handleReset={resetDrawManager}
                handleUndo={undoDrawManager}
              />
            )}
          </Grid>
          {!existingGeofence && (
            <Grid container className="flex-initial">
              <Grid
                item
                className="bg-table_row_bg"
                sx={{ padding: 3, width: "100%" }}
              >
                <Typography
                  className="!mb-4 !font-bold"
                  sx={{ fontSize: "14px" }}
                >
                  Choose Geofence you want to create
                </Typography>
                <ToggleButtons
                  id="geofence-create__shapes"
                  options={GEOFENCE_SHAPE_OPTIONS}
                  value={drawnGeofenceType}
                  onChange={onChangeShape}
                />
              </Grid>
            </Grid>
          )}
          <div className="bg-background pb-6">
            <GeofenceForm
              form={form}
              existingGeofence={existingGeofence}
              onSubmit={existingGeofence ? onUpdate : onCreate}
              data-testid="add-geofence-form"
              compact
              cancelable={!createIsLoading && !updateIsLoading}
              onCancel={onCancel}
            />
            <div className="flex items-center justify-center mt-8">
              <CancelButton
                className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !text-sm"
                onClick={handleCancel}
                color="inherit"
                size="small"
                data-testid="cancel-geofence-button"
              >
                Cancel
              </CancelButton>
              <Button
                dataTestid="save-geofence-button"
                className="w-full"
                text={createIsLoading || updateIsLoading ? "Saving" : "Save"}
                size="medium"
                theme="blue"
                variant="default"
                type="button"
                disabled={!isGeofenceFormDirty}
                onClick={handleSubmitClick}
                iconPosition="right"
                icon={
                  (createIsLoading || updateIsLoading) && (
                    <CircularProgress size={15} style={{ color: "white" }} />
                  )
                }
                sx={{
                  "&.MuiButton-root": {
                    width: "120px", // width is out of design, as button includes loader
                    marginLeft: "16px",
                    marginTop: "0",

                    "@media (max-width: 767px)": {
                      marginTop: "34px",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
