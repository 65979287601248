import { memo } from "react";
import { motion } from "framer-motion";
import Page from "../../shared/components/Page";
import HomeComponent from "./HomeComponent";

const Home = () => {
  return (
    <motion.div
      initial={{ height: "100%" }}
      animate={{ height: "100%" }}
      exit={{ y: -window.innerHeight, transition: { duration: 0.2 } }}
    >
      <Page title="">
        <HomeComponent />
      </Page>
    </motion.div>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
Home.displayName = "Home";
export default memo(Home);
