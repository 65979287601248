import { FC, useRef, useState } from "react";
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { Marker } from "@react-google-maps/api";
import { SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID } from "../../../../../../constants";
import AssetShortTooltip from "../../AssetShortTooltip";
import { arrowSide } from "../../AssetShortTooltip/helpers";
import "../../AssetShortTooltip/style.scss";
import { useBreadcrumbsContext } from "../context/BreadCrumbsContext";
import otherIcon from "./markers/OtherEvent.svg";
import { BreadcrumbsFeature, getBreadcrumbIcon } from "./utils";

interface BreadcrumbsMarkersProps {
  mapEventHistoryFeatures: BreadcrumbsFeature[];
  googleMap: google.maps.Map | null;
}

export const BreadcrumbsMarkers: FC<BreadcrumbsMarkersProps> = ({
  mapEventHistoryFeatures,
}) => {
  const [focusedFeature, setFocusedFeature] =
    useState<BreadcrumbsFeature | null>(null);
  const { dispatch } = useBreadcrumbsContext();

  const arrowRef = useRef(null);
  const { refs, floatingStyles, middlewareData, placement } = useFloating({
    open: !!focusedFeature,
    whileElementsMounted: autoUpdate,
    middleware: [offset(20), flip(), shift(), arrow({ element: arrowRef })],
  });

  const onMarkerClick = (feature: BreadcrumbsFeature) => {
    dispatch({
      type: SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID,
      payload: feature.properties.imei ?? 0,
    });
    setFocusedFeature(null);
  };

  const currentSide = placement.split("-")[0];

  return (
    <>
      {mapEventHistoryFeatures.map((feature, index) => {
        const breadcrumbIcon = getBreadcrumbIcon(feature);
        const icon: google.maps.Icon = {
          url: breadcrumbIcon as string,
          scaledSize: new google.maps.Size(24, 24),
          anchor: new google.maps.Point(12, 12),
        };
        const defaultIcon = {
          url: otherIcon,
        };
        const isDefaultIcon = !breadcrumbIcon;
        return (
          <Marker
            onMouseOver={(e) => {
              if (!isDefaultIcon) {
                refs.setReference(e.domEvent.target as HTMLElement);
                setFocusedFeature(feature);
              }
            }}
            onMouseOut={() => {
              if (!isDefaultIcon) {
                refs.setReference(null);
                setFocusedFeature(null);
              }
            }}
            onClick={() => onMarkerClick(feature)}
            position={{
              lat: feature.geometry.coordinates[1],
              lng: feature.geometry.coordinates[0],
            }}
            key={`${feature.geometry.coordinates[0]}-${feature.geometry.coordinates[1]}-${index}`}
            icon={breadcrumbIcon ? icon : defaultIcon}
          ></Marker>
        );
      })}
      {focusedFeature && (
        <div ref={refs.setFloating} style={floatingStyles}>
          <div
            ref={arrowRef}
            style={{
              position: "absolute",
              width: "16px",
              height: "16px",
              backgroundColor: "var(--custom-tooltip-background)",
              ...arrowSide[currentSide],
              ...(middlewareData.arrow && {
                left: middlewareData.arrow.x ?? 0,
              }),
            }}
          />
          <AssetShortTooltip
            breadCrumbsTooltipText={focusedFeature.properties.eventName}
          />
        </div>
      )}
    </>
  );
};
