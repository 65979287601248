import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Chip,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import {
  SharedAssetsInvitationResult,
  useAcceptSharedAssetsInvitationMutation,
  useDeclineSharedAssetsInvitationMutation,
} from "../../../graphql/operations";
import { useFormTheme } from "../../../shared/hooks/theme/useFormTheme";
import useBreakpoint from "../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../shared/hooks/useCurrentOrg";
import { DATE_FORMAT, formatDate } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { parseHiddenProperties } from "./helpers";
import { shareAssetInvitationSchema } from "./schema";
import { ShareAssetInvitationForm } from "./types";

const sectionStyles = { marginBottom: { xs: "16px", md: "32px" } };
const sectionTitleStyles = {
  fontSize: {
    xs: "14px",
    md: "16px",
  },
  fontWeight: "400",
  lineHeight: {
    xs: "22px",
    md: "24px",
  },
  marginBottom: "8px",
};

export interface ShareAssetsInvitationProps {
  invitation: SharedAssetsInvitationResult;
}

export const ShareAssetsInvitation: FC<ShareAssetsInvitationProps> = ({
  invitation,
}) => {
  const { dispatch } = useAppContext();

  const formTheme = useFormTheme();

  const isMobile = useBreakpoint("down", "sm");

  const navigate = useNavigate();

  const org = useCurrentOrg();

  const { control, handleSubmit } = useForm<ShareAssetInvitationForm>({
    resolver: yupResolver(shareAssetInvitationSchema),
    defaultValues: {
      shareGroupName: "",
    },
  });

  const showSuccessMessageAndNavigateToAssetMap = (
    title: string,
    text: string
  ) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title,
        text,
        severity: "success",
      },
    });

    navigate(NavigationRoutes.AssetMap);
  };

  const {
    mutate: acceptInvitationMutation,
    isLoading: acceptInvitationProcessing,
  } = useAcceptSharedAssetsInvitationMutation({
    onSuccess: () => {
      showSuccessMessageAndNavigateToAssetMap(
        "Accepting Invitation",
        "Successfully Accepted Invitation."
      );
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Accepting Invitiation Failed",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    },
  });

  const {
    mutate: declineInvitationMutation,
    isLoading: declineInvitationProcessing,
  } = useDeclineSharedAssetsInvitationMutation({
    onSuccess: () => {
      showSuccessMessageAndNavigateToAssetMap(
        "Declining Invitation",
        "Successfully Declined Invitation."
      );
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Declining Invitiation Failed",
          text: "Something Went Wrong.",
          severity: "error",
        },
      });
    },
  });

  const onSubmit = ({ shareGroupName }: ShareAssetInvitationForm) => {
    if (invitation._id && org) {
      acceptInvitationMutation({
        input: {
          invitationId: invitation._id,
          organizationId: org._id,
          sharedGroupName: shareGroupName,
        },
      });
    }
  };

  const handleDecline = () => {
    if (invitation._id && org) {
      declineInvitationMutation({
        input: { invitationId: invitation._id, organizationId: org._id },
      });
    }
  };

  const hiddenProperties = useMemo(
    () => parseHiddenProperties(invitation?.hideProperties),
    [invitation?.hideProperties]
  );

  const isLoading = acceptInvitationProcessing || declineInvitationProcessing;

  return (
    <Box
      data-testid="shared-asset-invitation--container"
      className="text-typography-secondary"
    >
      <Box
        sx={{ margin: { xs: "0 0 32px 0", md: "32px 0" }, padding: "8px 0" }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "32px",
            textTransform: "capitalize",
          }}
          data-testid="shared-asset-invitation--title"
        >
          Shared Assets
        </Typography>
      </Box>
      <ThemeProvider theme={formTheme}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          data-testid="shared-asset-invitation--share-group-name-form"
        >
          <Box sx={{ padding: { xs: "0 16px", md: 0 } }}>
            <Box sx={sectionStyles}>
              <TextFieldElement
                data-testid="shared-asset-invitation--share-group-name-input"
                fullWidth
                control={control}
                name="shareGroupName"
                required
                label="Share Group Name"
                disabled={isLoading}
              />
            </Box>

            <Box sx={sectionStyles}>
              <Box
                sx={sectionTitleStyles}
                data-testid="shared-asset-invitation--sharing-org-title"
              >
                Organization {invitation?.organizationName} wants to share with
                you the following assets ({invitation?.assets?.length}):
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={isMobile ? "5px" : 2}
                data-testid="shared-asset-invitation--asset-ids-chips-grid"
              >
                {invitation?.assets?.map((asset) => (
                  <Grid item key={asset._id}>
                    <Chip
                      key={asset.asset_id}
                      label={asset.asset_id}
                      sx={{
                        padding: "4px 8px",
                        borderRadius: {
                          xs: "16px",
                          md: "25px",
                        },
                        fontSize: "12px",
                        fontWeight: {
                          xs: 500,
                          md: 700,
                        },
                        lineHeight: "16px",
                        "& .MuiChip-label": {
                          padding: 0,
                        },
                      }}
                      className="!bg-concrete !text-mid-charcoal"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box
              sx={sectionStyles}
              data-testid="shared-asset-invitation--share-time-frame-container"
            >
              <Box
                sx={sectionTitleStyles}
                data-testid="shared-asset-invitation--share-time-frame-title"
              >
                Share Time Frame:
              </Box>
              <Box
                sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}
                data-testid="shared-asset-invitation--share-time-frame-value"
              >
                from
                <span className="font-bold ml-2 mr-6">
                  {formatDate(invitation?.startDate, DATE_FORMAT)}
                </span>
                to
                <span className="font-bold ml-2 mr-6">
                  {formatDate(invitation?.endDate, DATE_FORMAT)}
                </span>
              </Box>
            </Box>
            {hiddenProperties && (
              <Box
                sx={{ marginBottom: "32px" }}
                data-testid="shared-asset-invitation--hidden-properties-container"
              >
                <Box
                  sx={sectionTitleStyles}
                  className="text-typography-secondary"
                  data-testid="shared-asset-invitation--hidden-properties-title"
                >
                  Excluded Information:
                </Box>

                <Box data-testid="shared-asset-invitation--hidden-properties-list">
                  {hiddenProperties}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                padding: "16px 0",
              }}
            >
              <Button
                onClick={handleDecline}
                disabled={isLoading}
                className="!bg-app_header__bg !text-typography-secondary"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: { xs: "48px", md: "100px" },
                  fontSize: { xs: "10px", md: "16px" },
                  fontWeight: { xs: 800, md: 700 },
                  padding: { xs: "8px 16px", md: "12px 24px" },
                  border: "2px solid var(--typography-secondary)",
                  marginRight: "16px",
                }}
                data-testid="shared-asset-invitation--decline-invitation-btn"
              >
                {declineInvitationProcessing ? "Declining..." : "Decline"}
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                className="!bg-info !text-feather-gray"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: { xs: "48px", md: "100px" },
                  fontSize: { xs: "10px", md: "16px" },
                  fontWeight: { xs: 800, md: 700 },
                  padding: { xs: "8px 16px", md: "12px 24px" },
                }}
                data-testid="shared-asset-invitation--accept-invitation-btn"
              >
                {acceptInvitationProcessing ? "Accepting..." : "Accept"}
              </Button>
            </Box>
          </Box>
        </form>
      </ThemeProvider>
    </Box>
  );
};
