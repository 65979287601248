import { FC } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { DefaultLegendContentProps } from "recharts";
import { formatNumber } from "../../../../../utils/formatters";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import { DEFAULT_LEGEND_TEXT_COLOR } from "../constants";

const PieLegend: FC<DefaultLegendContentProps> = ({ payload }) => {
  const isMobile = useBreakpoint("down", "sm");
  const isTablet = useMediaQuery("(min-width:640px) and (max-width:1366px)");
  const isVerticalLayout = isMobile || isTablet;

  if (!payload) {
    return null;
  }

  return (
    <div className={`${isVerticalLayout ? "mb-20 min-w-[200px]" : ""}`}>
      {payload?.map((entry: any, index) => {
        return (
          <Box key={`item-${index}`} className="mb-4">
            <Box className={`${isVerticalLayout ? "w-full" : "w-28"}`}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "26px",
                  textTransform: "capitalize",
                  overflowWrap: "break-word",
                }}
              >
                {entry?.value}
              </Typography>
            </Box>
            <Box
              className="mt-2 p-2 rounded text-center"
              sx={{
                backgroundColor: entry?.color,
                width: isVerticalLayout
                  ? `${entry.payload.percent * 100}%`
                  : "auto",
              }}
              data-testid={`item-legend-${index}`}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "26px",
                  textTransform: "capitalize",
                  color:
                    entry.payload?.legendTextColor ?? DEFAULT_LEGEND_TEXT_COLOR,
                }}
              >
                {formatNumber(entry?.payload?.value ?? 0)}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

export default PieLegend;
