import { useMemo } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { OrgData } from "../../../../../graphql/operations";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import MapWidget from "../../Assets/SummaryTabPanel/MapWidget/MapWidget";
import DetailListWidget, {
  detailItem,
} from "../../Shared/DashboardWidgets/DetailListWidget";
import GeofenceCapacityWidget from "./GeofenceCapacity/GeofenceCapacityWidget";
import { getGeofenceCapacityInfo } from "./GeofenceCapacity/helper";
import GeofenceHoursWidget from "./GeofenceHours/GeofenceHoursWidget";
import { getGeofenceHoursInfo } from "./GeofenceHours/helper";
import { getGeofenceInfo } from "./helper";
import { DetailItem } from "./types";

const SummaryTabPanel = () => {
  const {
    allGeofences,
    geofenceTypes,
    geofenceDetail,
    selectedGeofence,
    isGeofenceDetailLoading: isLoading,
    isGeofenceDetailFetching: isFetching,
    isGeofenceDetailFetchingSuccess: isSuccess,
  } = useAssetsDataContext();

  const org: OrgData[] = useAvailableOrgs();
  const geofenceParents = useMemo(() => allGeofences, [allGeofences]);
  const geofenceCapacityDetail: {
    GeofenceCapacityList: Array<DetailItem<string | number>>;
    title: string;
    subTitle: string;
  } = useMemo(
    () => ({
      GeofenceCapacityList: getGeofenceCapacityInfo(geofenceDetail),
      title: "Min/Max",
      subTitle: "Detailed Information About Your Geofence",
    }),
    [geofenceDetail]
  );

  const geofenceHoursDetail: {
    GeofenceHoursList: Array<DetailItem<string | number>>;
    title: string;
    subTitle: string;
  } = useMemo(
    () => ({
      GeofenceHoursList: getGeofenceHoursInfo(geofenceDetail),
      title: "Hours of Operation",
      subTitle: "Detailed Information About Your Geofence",
    }),
    [geofenceDetail]
  );

  const geofenceInfoDetail: {
    GeofenceInfoDetailList: Array<detailItem<string | number>>;
    title: string;
    subTitle: string;
  } = useMemo(
    () => ({
      GeofenceInfoDetailList: getGeofenceInfo(
        geofenceDetail,
        org,
        geofenceTypes,
        geofenceParents
      ),
      title: "Geofence Information",
      subTitle: "Detailed Information About Your Geofence",
    }),
    [geofenceDetail, org, geofenceTypes, geofenceParents]
  );

  return (
    <Box className="flex flex-col gap-4" data-testid="summary-tab-panel-loader">
      {isLoading && isFetching ? (
        <Box className="flex h-full w-full items-center justify-center">
          <CircularProgress data-testid="summary-tab-panel-progress" />
        </Box>
      ) : (
        <>
          {isSuccess && (
            <Box
              className="flex flex-col gap-4"
              data-testid="summary-tab-panel"
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} xs={12} className="mapWidgetContainer">
                  {geofenceDetail && (
                    <MapWidget selectedGeofence={selectedGeofence} />
                  )}
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <DetailListWidget
                    title={geofenceInfoDetail.title}
                    detailList={geofenceInfoDetail.GeofenceInfoDetailList}
                  />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <GeofenceCapacityWidget
                    title={geofenceCapacityDetail.title}
                    detailList={geofenceCapacityDetail.GeofenceCapacityList}
                  />
                </Grid>
                <Grid item xl={6} lg={6} xs={12}>
                  <GeofenceHoursWidget
                    title={geofenceHoursDetail.title}
                    detailList={geofenceHoursDetail.GeofenceHoursList}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default SummaryTabPanel;
