import { Asset } from "../../../../graphql/operations";

export function calculateTotalDistance(assets: Asset[]) {
  let distance = 0;

  assets.forEach((asset) => {
    const odometer = Number(asset.tripStatus?.odometer);
    distance += odometer;
  });
  const formattedTotal = new Intl.NumberFormat("en-EN").format(distance);
  return formattedTotal;
}
