export const columnVisibilityModel = {
  org_name: false,
  icon: true,
  "sensors.voltage.data.battery": true,
  asset_id: true,
  name: false,
  dwellingDays: true,
  imei: false,
  prd_cde: false,
  lst_evnt_t: false,
  device_associated: false,
  trip_st: false,
  city: false,
  state: false,
  postcode: false,
  vin: false,
  longitude: false,
  latitude: false,
  category: false,
  tags: false,
  wheel_config: false,
  num_of_axles: false,
  length: false,
  door_type: false,
  last_gps_t: false, // GPS Time
};
