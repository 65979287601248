import { useMemo, useRef } from "react";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import { sliderMarks } from "../../../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

const minAssetYear = 1900;
const maxAssetYear = new Date().getFullYear();

const AssetYearSlider: React.FC = () => {
  const {
    currentFilter: { assetYear },
    onChangeFilters,
  } = useAssetsDataContext();

  const defaultPowerValues = useRef<number[]>([
    assetYear?.startValue ?? minAssetYear,
    assetYear?.endValue ?? maxAssetYear,
  ]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      const [startValue, endValue] = values;
      onChangeFilters({ assetYear: { startValue, endValue } });
    }
  };

  const dataValues = useMemo(
    () => ({
      defaultValue: defaultPowerValues.current,
      mainBarColor: ["concrete"],
      valueBarColor: ["primary-blue"],
    }),
    [defaultPowerValues]
  );

  return (
    <RangeSlider
      min={minAssetYear}
      max={maxAssetYear}
      marks={sliderMarks(minAssetYear, maxAssetYear, "year")}
      defaultValues={dataValues.defaultValue}
      marksMargin={{ left: "1.5rem", right: "-1.5rem" }}
      onChange={onValueChanged}
    />
  );
};

export default AssetYearSlider;
