import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";
import { GridColType, GridLinkOperator } from "@mui/x-data-grid/models";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  SortOrder,
  TableColumn,
  TableColumnFormat,
  TableFilterInput,
  TableFilterLinkOperator,
  TableFilterOperator,
  TableFiltersInput,
  TableSortingInput,
  TableValueDataType,
} from "../../../graphql/operations";
import { formatDateToTimezoneAsUTCISOString } from "../../../utils";
import { TableGridColDef, TableGridData } from "./types";

export enum TableDataModes {
  Server = "server",
  FullServer = "full-server",
  Client = "client",
  // semi-server used for export to work with other server props
  SemiServer = "semi-server",
}

export const muiDataGridSortToTableSort = (
  sortModel: GridSortModel
): TableSortingInput[] =>
  sortModel.map(({ field, sort }) => ({ field, order: sort as SortOrder }));

export const tableSortToMuiDataGridSort = (
  sorting?: TableSortingInput[]
): GridSortModel =>
  sorting?.map(({ field, order }) => ({ field, sort: order })) ?? [];

export const MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP: Record<
  GridLinkOperator,
  TableFilterLinkOperator
> = {
  [GridLinkOperator.And]: TableFilterLinkOperator.And,
  [GridLinkOperator.Or]: TableFilterLinkOperator.Or,
};

export const MUI_GRID_COL_TYPE_TO_TABLE_VALUE_DATA_TYPE_MAP: Record<
  GridColType,
  TableValueDataType
> = {
  boolean: TableValueDataType.Boolean,
  date: TableValueDataType.Date,
  datetime: TableValueDataType.Date,
  number: TableValueDataType.Number,
  string: TableValueDataType.String,
  singleSelect: TableValueDataType.SingleSelect,
};

export const MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP: Record<
  TableValueDataType,
  Record<string, Partial<TableFilterOperator>>
> = {
  [TableValueDataType.Id]: {
    is: TableFilterOperator.Equals,
  },
  [TableValueDataType.Boolean]: {
    is: TableFilterOperator.Equals,
  },
  [TableValueDataType.Date]: {
    is: TableFilterOperator.Equals,
    not: TableFilterOperator.IsNotEqual,
    after: TableFilterOperator.Gt,
    onOrAfter: TableFilterOperator.Gte,
    before: TableFilterOperator.Lt,
    onOrBefore: TableFilterOperator.Lte,
    isEmpty: TableFilterOperator.IsEmpty,
    isNotEmpty: TableFilterOperator.IsNotEmpty,
  },
  [TableValueDataType.Number]: {
    "=": TableFilterOperator.Equals,
    "!=": TableFilterOperator.IsNotEqual,
    ">": TableFilterOperator.Gt,
    ">=": TableFilterOperator.Gte,
    "<": TableFilterOperator.Lt,
    "<=": TableFilterOperator.Lte,
    isEmpty: TableFilterOperator.IsEmpty,
    isNotEmpty: TableFilterOperator.IsNotEmpty,
    isAnyOf: TableFilterOperator.IsAnyOf,
  },
  [TableValueDataType.String]: {
    contains: TableFilterOperator.Contains,
    doesNotContain: TableFilterOperator.DoesNotContain,
    equals: TableFilterOperator.Equals,
    isNotEqual: TableFilterOperator.IsNotEqual,
    startsWith: TableFilterOperator.StartsWith,
    endsWith: TableFilterOperator.EndsWith,
    isEmpty: TableFilterOperator.IsEmpty,
    isNotEmpty: TableFilterOperator.IsNotEmpty,
    isAnyOf: TableFilterOperator.IsAnyOf,
  },
  [TableValueDataType.SingleSelect]: {
    is: TableFilterOperator.Is,
    isNot: TableFilterOperator.IsNot,
    isAnyOf: TableFilterOperator.IsAnyOf,
  },
};

export const isTableFilterValid = (
  filter: Partial<TableFilterInput>
): filter is TableFilterInput => {
  const { dataType, operator, field, value } = filter;

  if (!dataType || !operator || !field) {
    return false;
  }
  if (dataType === TableValueDataType.Boolean) {
    return value === "true" || value === "false";
  }
  if (
    operator === TableFilterOperator.IsEmpty ||
    operator === TableFilterOperator.IsNotEmpty
  ) {
    return !value;
  }
  if (operator === TableFilterOperator.IsAnyOf) {
    return Array.isArray(value);
  }

  return value !== "" && typeof value !== "undefined" && value !== null;
};

export const dataGridFiltersToTableFilters = <T extends TableGridData>(
  model: GridFilterModel,
  columns: TableGridColDef<T>[],
  timezone: string
): TableFiltersInput | undefined => {
  const filters: TableFiltersInput = {
    linkOperator: model.linkOperator
      ? MUI_GRID_LINK_OPERATOR_TO_TABLE_FILTER_LINK_OPERATOR_MAP[
          model.linkOperator
        ]
      : TableFilterLinkOperator.And,
    filters: [],
  };

  model.items.forEach((item) => {
    const { columnField, value, operatorValue } = item;
    const column = columns.find(({ field }) => field === columnField);
    const dataType = column?.type
      ? MUI_GRID_COL_TYPE_TO_TABLE_VALUE_DATA_TYPE_MAP[column.type]
      : undefined;
    const operator =
      dataType && operatorValue
        ? MUI_GRID_FILTER_OPERATOR_TO_TABLE_FILTER_OPERATOR_MAP[dataType][
            operatorValue
          ]
        : undefined;
    const filter = {
      dataType,
      operator,
      field: columnField,
      value,
    };
    const tableFilterValid = isTableFilterValid(filter);
    if (tableFilterValid) {
      // Convert date value to provided timezone
      if (dataType === TableValueDataType.Date) {
        filter.value = formatDateToTimezoneAsUTCISOString(
          filter.value,
          timezone
        );
      }

      filters.filters.push({
        ...filter,
        value: filter.value
          ? JSON.stringify({ value: filter.value })
          : undefined,
      });
    }
  });

  return filters.filters.length > 0 ? filters : undefined;
};

export type TableColumnProps<T extends TableGridData> = {
  field: string;
  headerName: string;
  type?: "string" | "number" | "date" | "dateTime" | "boolean" | "singleSelect";
  format?: TableColumnFormat;
  options?: Omit<GridColDef<T>, "field" | "type" | "headerName">;
};

export const getTableColumn = <T extends TableGridData>({
  field,
  headerName,
  type = "string",
  format = TableColumnFormat.String,
  options,
}: TableColumnProps<T>): TableGridColDef<T> => ({
  ...(options ?? {}),
  field,
  headerName,
  type,
  format,
  flex: options?.flex ?? 1,
  minWidth: options?.minWidth ?? 120,
});

export const getExportColumn = <T extends TableGridData>(
  column: TableGridColDef<T>
): TableColumn => ({
  disableExport: column.disableExport,
  field: column.field,
  format: column.format,
  label: column.headerName,
});
