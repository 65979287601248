import { FC, useState, MutableRefObject, ReactNode } from "react";
import { ClickAwayListener, Popper } from "@mui/base";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportProps,
  GridPreferencePanelsValue,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import classNames from "classnames";
import { ExportFileType } from "../../../graphql/operations";
import TableExportToolbar from "./TableExportToolbar";

type TableToolbarProps = {
  allowExport: boolean;
  exportProps?: GridToolbarExportProps;
  apiRef: MutableRefObject<GridApiPremium>;
  children?: ReactNode;
  checkboxSelection?: boolean;
  onExportClick?: (fileType: ExportFileType) => void;
  handleDataMode?: string;
  isMobileView?: boolean;
};

const TableToolbarItemsContainer: FC<TableToolbarProps> = ({
  allowExport,
  exportProps,
  apiRef,
  children,
  checkboxSelection,
  onExportClick,
  handleDataMode,
  isMobileView,
}) => {
  const toolbarItemClassName = "!text-brand !text-sm !font-bold !px-2 ";

  const handleShowPreferences = (
    selectedPreference: GridPreferencePanelsValue
  ) => {
    if (apiRef?.current) {
      apiRef.current.hidePreferences();
      setTimeout(() => {
        apiRef.current.showPreferences(selectedPreference);
      }, 100);
    }
  };

  return (
    <GridToolbarContainer className={classNames({ "flex-col": isMobileView })}>
      <GridToolbarColumnsButton
        data-testid="table-toolbar-columns-btn"
        className={toolbarItemClassName}
        onMouseDown={() => {
          if (isMobileView) {
            handleShowPreferences(GridPreferencePanelsValue.columns);
          }
        }}
      />
      <GridToolbarFilterButton
        data-testid="table-toolbar-filter-btn"
        className={toolbarItemClassName}
        onMouseDown={() => {
          if (isMobileView) {
            handleShowPreferences(GridPreferencePanelsValue.filters);
          }
        }}
      />
      <GridToolbarDensitySelector
        data-testid="table-toolbar-density-btn"
        className={toolbarItemClassName}
      />
      {allowExport && (
        <TableExportToolbar
          className={toolbarItemClassName}
          onClick={() => {
            if (!checkboxSelection) apiRef?.current?.setSelectionModel([]);
          }}
          excelOptions={exportProps?.excelOptions ?? {}}
          csvOptions={exportProps?.csvOptions ?? {}}
          printOptions={exportProps?.printOptions ?? {}}
          onExportClick={onExportClick}
          handleDataMode={handleDataMode}
          {...exportProps}
        />
      )}
      {children}
    </GridToolbarContainer>
  );
};

const TableToolbar: FC<TableToolbarProps> = (props) => {
  const { isMobileView = false } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "table-toolbar-popover" : undefined;

  return (
    <div className="border-b border-concrete !py-4">
      {isMobileView ? (
        <IconButton
          className="w-6 h-6 !ml-1"
          title="Saved Filters"
          data-testid="save-filters"
          onClick={handleClick}
        >
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <TableToolbarItemsContainer {...props} />
      )}

      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            id={id}
            open={open}
            placement="top-start"
            anchorEl={anchorEl}
            className="bg-background z-[1300]"
          >
            <TableToolbarItemsContainer {...props} />
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TableToolbar;
