import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement, CheckboxElement } from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import { useFindAssetsByIdsQuery } from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";
import { AssetsParameters } from "./AssetsParameters";

interface AlertCargoParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertCargoParameters: FC<AlertCargoParametersProps> = ({
  form,
}: AlertCargoParametersProps) => {
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);
  const [assetNameSearch, setAssetNameSearch] = useState(
    form.getValues().name ?? ""
  );

  const watchAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });
  let shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssets);

  const {
    data: foundAsset,
    isLoading: findAssetLoading,
    isSuccess: findAssetSuccess,
  } = useFindAssetsByIdsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );
  useEffect(() => {
    if (foundAsset && !findAssetLoading && findAssetSuccess) {
      onFindAssetsSettledCallback({ data: foundAsset, form, setSelectedAsset });
    }
  }, [foundAsset, findAssetLoading, findAssetSuccess, form, shouldBeEnabled]);

  useEffect(() => {
    form.setValue("parameters.assetIds", selectedAsset);
  }, [form, selectedAsset]);

  const { options, isFetching } = useFindAssetListOptions(
    assetNameSearch,
    state.appConfig.searchOptionsLimit
  );

  useEffect(() => {
    setAssetsOptionsList(options);
  }, [options]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssets?.length &&
    findAssetLoading &&
    !findAssetSuccess;

  return (
    <AlertParametersContainer>
      <AssetsParameters
        setAssetNameSearch={setAssetNameSearch}
        form={form}
        setSelectedAsset={setSelectedAsset}
        setAssetsOptionsList={setAssetsOptionsList}
        watchAssetTypeValue={watchAssetType}
        hideInputValue={hideInputValue}
        isFetching={isFetching}
        assetsOptionsList={assetsOptionsList}
        watchAssetsValue={watchAssets}
        assetTypeOptions={assetTypeOptions}
        assetsSelectTestId="cargo-assets-select"
        assetTypeSelectTestId="cargo-asset-type-select"
      />

      <Grid
        item
        xs={12}
        display={{ md: "flex" }}
        md={"auto"}
        lg={"auto"}
        xl={6}
      >
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-cargo-empty"
        >
          <CheckboxElement
            name="parameters.isEmpty"
            control={form.control}
            label="Asset is Empty"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-cargo-full"
        >
          <CheckboxElement
            name="parameters.isFull"
            control={form.control}
            label="Asset is Loaded"
          />
        </Grid>
      </Grid>
    </AlertParametersContainer>
  );
};
