import { FC, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  ConfigurationSet,
  ConfigurationSetType,
  useFindConfigurationSetByIdQuery,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import { useConfigurationSetsApi } from "../../../../hooks/useConfigurationSetsApi";
import { mapFaultCodesConfigurationSetsTableData } from "../../Tables/ABSFaultCodes/helpers";
import { DrawerType, FaultCodesDrawers } from "../../configurationsUtils";
import { ABSFaultCodesForm } from "./components/Form";
import {
  FaultCodeFormValues,
  useFaultCodeForm,
} from "./hooks/useFaultCodeForm";

// TODO: Review and maybe export common interface?
export interface ABSFaultCodesConfigurationSetDrawerProps {
  isOpen: boolean;
  type?: DrawerType;
  selectedOrgId?: string;
  selectedConfigId?: string;
  onClose: () => void;
  onRequestClose: () => void;
}

const ABSFaultCodesConfigurationSetDrawer: FC<
  ABSFaultCodesConfigurationSetDrawerProps
> = ({
  isOpen,
  type,
  selectedConfigId,
  selectedOrgId,
  onClose,
  onRequestClose,
}) => {
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();

  const [isRemoveDisabled, setIsRemoveDisabled] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const isCreateDrawer = type === FaultCodesDrawers.Create;
  const isEditDrawer = type === FaultCodesDrawers.Edit;

  const { form, getValues } = useFaultCodeForm();

  const findABSFaultCodeConfigurationSetOnSuccess = (
    configurationSet: ConfigurationSet
  ) => {
    const data = mapFaultCodesConfigurationSetsTableData(configurationSet);

    // Get only the values we need for the form
    const formValues: FaultCodeFormValues = {
      manufacturer: data?.manufacturer ?? "",
      faultCodeType: data?.faultCodeType ?? "",
      pgn: data?.pgn ?? "",
      dtc: data?.dtc ?? "",
      sid: data?.sid ?? "",
      fmi: data?.fmi ?? "",
      problemArea: data?.problemArea ?? "",
      cause: data?.cause ?? "",
      description: data?.description ?? "",
      pctExplains: data?.pctExplains ?? "",
    };

    form.reset(formValues);
  };

  const findABSFaultCodeConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "An unexpected error has occurred!",
        text: "An error occurred while getting information for the selected Fault Code Configuration.",
      },
    });

    onClose();
    form.reset();
  };

  const {
    data: dataConfigurationSet,
    isFetching: isFetchingFindConfiguration,
    refetch,
  } = useFindConfigurationSetByIdQuery(
    {
      input: {
        entityId: selectedConfigId!,
      },
    },
    {
      enabled: Boolean(selectedConfigId) && isEditDrawer,
      onSuccess: (response) => {
        if (!response?.findConfigurationSetById) {
          findABSFaultCodeConfigurationSetOnError();
          return;
        }

        findABSFaultCodeConfigurationSetOnSuccess(
          response.findConfigurationSetById
        );
      },
    }
  );

  useEffect(() => {
    if (isOpen && isEditDrawer) {
      refetch();
    }
  }, [refetch, isOpen, isEditDrawer]);

  const createABSFaultCodeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Fault Code Configuration created!",
        text: "Your Fault Code Configuration is created successfully.",
      },
    });

    onClose();
  };

  const createABSFaultCodeConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Fault Code Configuration create failed!",
        text: "An error occurred while creating your Fault Code Configuration.",
      },
    });
  };

  const updateABSFaultCodeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Fault Code Configuration updated!",
        text: "Your Fault Code Configuration is updated successfully.",
      },
    });

    onClose();
  };

  const updateABSFaultCodeConfigurationSetOnError = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Fault Code Configuration update failed!",
        text: "An error occurred while creating your Fault Code Configuration.",
      },
    });
  };

  const deleteABSFaultCodeConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries(["getConfigurationSets"]);

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Fault Code Configuration deleted!",
        text: "Your Fault Code Configuration is deleted successfully.",
        severity: "success",
      },
    });

    setIsDeleteDialogOpen(false);
    onClose();
  };

  const deleteABSFaultCodeConfigurationSetOnError = (error: unknown) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Fault Code Configuration delete failed!",
        text: mapServerErrorCodeToHumanReadableMessage(
          (error as Error).message
        ),
        severity: "error",
      },
    });

    setIsDeleteDialogOpen(false);
    onClose();
  };

  const {
    isLoadingCreateConfigurationSet,
    createConfigurationSet,

    isLoadingUpdateConfigurationSet,
    updateConfigurationSet,

    isLoadingDeleteConfigurationSet,
    deleteConfigurationSet,
  } = useConfigurationSetsApi({
    createConfigurationSetOnSuccess:
      createABSFaultCodeConfigurationSetOnSuccess,
    createConfigurationSetOnError: createABSFaultCodeConfigurationSetOnError,

    updateConfigurationSetOnSuccess:
      updateABSFaultCodeConfigurationSetOnSuccess,
    updateConfigurationSetOnError: updateABSFaultCodeConfigurationSetOnError,

    deleteConfigurationSetOnSuccess:
      deleteABSFaultCodeConfigurationSetOnSuccess,
    deleteConfigurationSetOnError: deleteABSFaultCodeConfigurationSetOnError,
  });

  const isLoading =
    isFetchingFindConfiguration ||
    isLoadingUpdateConfigurationSet ||
    isLoadingCreateConfigurationSet ||
    isLoadingDeleteConfigurationSet;
  const isSubmitDisabled = isLoading || isEmpty(form.formState.dirtyFields);

  useEffect(() => {
    setIsRemoveDisabled(
      isLoading ||
        Boolean(dataConfigurationSet?.findConfigurationSetById?.archived)
    );
  }, [isLoading, dataConfigurationSet]);

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const onHandleConfirmationResult = (confirmation: boolean) => {
    if (confirmation && dataConfigurationSet?.findConfigurationSetById) {
      deleteConfigurationSet({
        entityId: dataConfigurationSet?.findConfigurationSetById?._id,
      });
    } else {
      setIsDeleteDialogOpen(false);
    }
  };

  const onSubmit = (data: FieldValues) => {
    if (isCreateDrawer) {
      submitCreateConfigurationSet(data);
    } else if (isEditDrawer) {
      submitUpdateConfigurationSet(data);
    }
  };

  const submitCreateConfigurationSet = (formValues: FieldValues) => {
    const configInput = {
      type: ConfigurationSetType.FaultCode,
      orgId: selectedOrgId ?? "",
      name: `FAULT_CODE_CONFIGURATION_SET_${Date.now()}`,
      value: JSON.stringify(formValues),
    };

    createConfigurationSet(configInput);
  };

  const submitUpdateConfigurationSet = (formValues: FieldValues) => {
    if (dataConfigurationSet?.findConfigurationSetById) {
      const { _id } = dataConfigurationSet.findConfigurationSetById;

      updateConfigurationSet({
        _id,
        value: JSON.stringify(formValues),
      });
    }
  };

  const headerText = `${isCreateDrawer ? "Create" : "Edit"} Fault Code`;

  return (
    <Drawer
      testId="abs-fault-codes-drawer"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <DrawerHeader text={headerText} onClose={onClose} />

      <DrawerContent>
        <ABSFaultCodesForm form={form} />

        <DrawerActions
          deleteBtnTestId="abs-fault-code-configuration-set--action--delete"
          cancelBtnTestId="abs-fault-codes-drawer--cancel-btn"
          disabled={isRemoveDisabled || isLoading}
          showDeleteBtn={isEditDrawer}
          onCancel={onClose}
          onDelete={openDeleteDialog}
        />

        {isDeleteDialogOpen && (
          <ConfirmationDialog
            title="You are about to delete a Configuration"
            message="Are you sure?"
            isLoading={isLoadingDeleteConfigurationSet}
            open={isDeleteDialogOpen}
            confirmButtonText="Delete Configuration"
            cancelButtonText="Cancel"
            handleConfirmationResult={onHandleConfirmationResult}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={
          isLoadingUpdateConfigurationSet ||
          isLoadingCreateConfigurationSet ||
          isLoadingDeleteConfigurationSet
            ? "Saving..."
            : "Save"
        }
        disabled={isSubmitDisabled}
        testId="abs-fault-codes-drawer--save-btn"
        submit={form.handleSubmit(() => onSubmit(getValues()))}
      />
    </Drawer>
  );
};

export default ABSFaultCodesConfigurationSetDrawer;
