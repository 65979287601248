//@ts-nocheck
//TODO: FIX NO CHECK
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import {
  AppBar,
  Button,
  Grid,
  Skeleton,
  Toolbar,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import classNames from "classnames";
import { capitalize } from "lodash";
import {
  SET_APP_BRAND_COLOR,
  SET_SELECTED_ORGANIZATION,
  SELECTED_ORGANIZATION,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { useAuthContext } from "../../../context/AuthContext";
import { SelectedOrganization } from "../../../context/reducers/selectedOrganization";
import {
  MeUserData,
  useGetBrandFilesQuery,
  useGetUserDataQuery,
} from "../../../graphql/operations";
import { useAvailableOrgs } from "../../../shared/hooks/useAvailableOrgs";
import { useCurrentOrg } from "../../../shared/hooks/useCurrentOrg";
import { useImpersonate } from "../../../shared/hooks/useImpersonate";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import { flattenHierarchy } from "../../../utils";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../../../utils/routes/useCheckRouteSecurity";
import { getOrgsHierarchy } from "../../../views/ReportView/helpers/getOrgsHierarchy";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Hamburger } from "../Svg";
import { NotificationsComponent } from "./Notifications/NotificationsComponent";
import { PopoverMenu } from "./PopoverMenu";
import { SearchButton } from "./Search/SearchButton";
import { SideMenu } from "./SideMenu";

interface HeaderProps {
  title: string;
}

export const Header: FC<HeaderProps> = ({ title }) => {
  const { tokens, logout, decodedToken, userRolePermissions, isAuthorized } =
    useAuthContext();
  const {
    data: USER_DATA_RESPONSE,
    isLoading: USER_DATA_IS_LOADING,
    isSuccess: USER_DATA_IS_SUCCESS,
  } = useGetUserDataQuery(undefined, {
    enabled: isAuthorized,
  });
  const [_, setCookie, removeCookie] = useCookies([SELECTED_ORGANIZATION]);

  const { data: existingBrandFiles, isLoading: existingBrandLoading } =
    useGetBrandFilesQuery(
      {
        input: { id: USER_DATA_RESPONSE?.me.customerOrg.brandId ?? "" },
      },
      {
        enabled: Boolean(USER_DATA_RESPONSE?.me.customerOrg.brandId),
      }
    );
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  const navigate = useNavigate();

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [navigationMenu, setNavigationMenu] = useState(false);
  const [userData, setUserData] = useState<MeUserData | undefined>(undefined);
  const [userOrgData, setUserOrgData] = useState({} as any);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isImpersonationStopping, setIsImpersonationStopping] = useState(false);
  const dataCurrentOrg = useCurrentOrg();
  const [selectedOrgState, setSelectedOrgState] =
    useState<SelectedOrganization>(selectedOrganization);
  const { dispatch } = useAppContext();

  const isMobile = useBreakpoint("down", "sm");
  const availableOrgs = useAvailableOrgs();

  // ### START Logo redirection logic ###

  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();
  const [shouldRedirectToRoot, setShouldRedirectToRoot] = useState(false);

  useEffect(() => {
    if (shouldRedirectToRoot && !isLoadingDefaultPath) {
      setShouldRedirectToRoot(false);
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirectToRoot, navigate]);

  // ### END Logo redirection logic ###

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrgState?.value !== selectedOrganization?.value
    ) {
      setSelectedOrgState(selectedOrganization);
    } else if (
      !selectedOrganization &&
      (selectedOrgState?.label !== dataCurrentOrg?.name ||
        selectedOrgState?.value !== dataCurrentOrg?._id)
    ) {
      setSelectedOrgState({
        label: dataCurrentOrg?.name,
        value: dataCurrentOrg?._id,
      });
    }
  }, [selectedOrganization, dataCurrentOrg, selectedOrgState]);

  const availableOrgsHierarchy = useMemo(
    () => flattenHierarchy(getOrgsHierarchy(availableOrgs)),
    [availableOrgs]
  );

  const organizations =
    useMemo(() => {
      const determineTimeZone = (orgId) => {
        const matchedOrg = availableOrgs.find((org) => org._id === orgId);
        if (matchedOrg) {
          return matchedOrg.time_zones;
        }
        return selectedOrganization.timezone;
      };

      return availableOrgsHierarchy.map((org) => ({
        value: org.id as keyof OrgData,
        id: org.id,
        label: org.name,
        timezone: determineTimeZone(org.id),
        level: org.level,
      }));
    }, [
      availableOrgs,
      availableOrgsHierarchy,
      selectedOrganization?.timezone,
    ]) ?? [];

  const onToggleAccountMenu = useCallback(() => {
    setAccountMenuOpen((accountMenuOpen) => !accountMenuOpen);
  }, []);
  const onCloseAccMenu = useCallback(() => {
    setAccountMenuOpen(false);
  }, []);
  const onCloseNavMenu = useCallback(() => {
    setNavigationMenu(false);
  }, []);
  const onToggleNavMenu = useCallback(() => {
    setNavigationMenu((navigationMenu) => !navigationMenu);
  }, []);
  const onLogoClickHandler = () => {
    if (userRolePermissions.home.view) {
      setShouldRedirectToRoot(true);
    } else {
      navigate(NavigationRoutes.MyAccount);
    }
  };
  const onStopImpersonateClick = () => {
    setShowSpinner(true);
    removeCookie(SELECTED_ORGANIZATION, { path: "/" });
    if (decodedToken?.name) {
      handleImpersonation(decodedToken.name);
    }
  };
  const onChangeSelectOrganization = (event, value) => {
    if (!value) return;
    const org = value ?? selectedOrgState;

    dispatch({
      type: SET_SELECTED_ORGANIZATION,
      payload: org,
    });
    setCookie(SELECTED_ORGANIZATION, org, { path: "/" });
  };
  const isImpersonated = !!decodedToken?.impersonation;

  useEffect(() => {
    if (!USER_DATA_IS_LOADING && USER_DATA_IS_SUCCESS) {
      const me: MeUserData = USER_DATA_RESPONSE.me;
      setUserData(me);
      setUserOrgData(me.customerOrg);

      if (me.customerOrg.brand) {
        dispatch({
          type: SET_APP_BRAND_COLOR,
          payload: me.customerOrg.brand.color,
        });
      }
    }
  }, [
    USER_DATA_IS_LOADING,
    USER_DATA_IS_SUCCESS,
    USER_DATA_RESPONSE,
    decodedToken,
    dispatch,
  ]);

  const {
    mutate: IMPERSONATE_USER_MUTATION,
    isLoading: IMPERSONATION_USER_MUTATION_LOADING,
  } = useImpersonate("Impersonation ended successfully!");

  const handleImpersonation = (target_user: any) => {
    if (!IMPERSONATION_USER_MUTATION_LOADING && !isImpersonationStopping) {
      setIsImpersonationStopping(true);
      IMPERSONATE_USER_MUTATION({
        impersonationUserInput: {
          active: false,
          impersonate_by: decodedToken?.["cognito:username"],
          target_user,
        },
      });
    }
  };

  const getOrgHeaderName = (fullName: boolean = true) => {
    let nameSplitted = (userData?.customerOrg?.name as string)?.split(" ");
    let finalName: string = "";
    if (fullName) {
      nameSplitted?.forEach((word) => {
        finalName += " " + word;
      });
    } else {
      nameSplitted?.forEach((word) => {
        finalName += word[0];
      });
    }
    return finalName;
  };

  useSpinner(showSpinner);

  const companyLogo =
    userData?.customerOrg?.company_logos?.length > 0
      ? userData.customerOrg.company_logos[0].url
      : undefined;

  const getBrandLogo = () => {
    if (existingBrandLoading) {
      return <Skeleton variant="rounded" width={210} height={49} />;
    } else if (
      existingBrandFiles?.getBrandFiles.logo &&
      existingBrandFiles?.getBrandFiles.logo?.url
    ) {
      return (
        <div className="max-w-[150px]">
          <img
            className="w-full max-h-[64px]"
            src={existingBrandFiles?.getBrandFiles.logo?.url}
            alt={existingBrandFiles?.getBrandFiles.logo?.file_name}
          />
        </div>
      );
    } else {
      return (
        <h1 className="text-center text-2xl font-semibold text-header-text md:mb-1 truncate">
          {userOrgData?.name}
        </h1>
      );
    }
  };

  const filterOptionsOnSearch = useCallback((options, state) => {
    const inputValue = state.inputValue.toLowerCase();

    // collect all the matched options
    const matchedOptions = options
      .map((option, index) => ({ ...option, index }))
      .filter((option) => option.label.toLowerCase().includes(inputValue));

    // finds option's parents
    const findOptionParents = (option) => {
      const optionLevel = option.level;
      const parents = [];

      // an array of already collected parent levels
      // org can have only 1 parent at a level
      const uniqueParentLevels = [];
      // iterate through the options in reverse order
      for (let i = option.index; i >= 0; i--) {
        const otherOption = options[i];

        // if otherOption level is smaller
        // and has not been yet collected
        if (
          otherOption.level < optionLevel &&
          !uniqueParentLevels.includes(otherOption.level)
        ) {
          // collect the part
          parents.push({ ...otherOption, index: i });
          // mark level as already collected
          uniqueParentLevels.push(otherOption.level);

          // if level 0 is reached -> finish
          if (otherOption.level === 0) break;
        }
      }

      return parents.sort((a, b) => a.level - b.level);
    };

    // a set containing all the indexes to show
    const set = new Set();
    matchedOptions.forEach((option) => {
      // for each matched option find a parent
      const parents = findOptionParents(option);
      parents.forEach((parent) => {
        // for each parent store unique parent and child indexes
        set.add(parent.index);
        set.add(option.index);
      });
    });

    return Array.from(set)
      .sort((a, b) => a - b)
      .map((index) => options[index]);
  }, []);

  const orgHierarchyIndent = 20;

  return (
    <>
      <SideMenu
        open={navigationMenu}
        onClose={onCloseNavMenu}
        customerSupportData={{
          email: USER_DATA_RESPONSE?.me.customerOrg.brand?.supportEmail ?? "",
          phone:
            USER_DATA_RESPONSE?.me.customerOrg.brand?.supportPhoneNumber ?? "",
        }}
      />
      <AppBar
        className="!bg-none !z-[1201] !bg-background"
        elevation={0}
        data-testid="header"
        sx={{
          boxShadow: "0px 2px 4px 0px var(--box-shadow)",
          "@media (max-width:640px)": {
            boxShadow: "none",
          },
        }}
      >
        <Toolbar className="!pl-[19px] !pr-[18px] lg:!pl-[35px] lg:!pr-[32px] mb-3">
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            sx={{
              "@media (max-width:640px)": {
                maxWidth: "max-content",
              },
            }}
          >
            <IconButton
              id="app-menu-btn"
              onClick={onToggleNavMenu}
              className="!p-0 !text-primary"
              data-testid="header-sideMenu-btn"
            >
              <Hamburger />
            </IconButton>

            <Typography
              data-testid="header-title"
              className="!mx-1 md:!mx-2 !text-primary lg:block"
            >
              {title}
            </Typography>

            {!isMobile && (
              <Grid xs={7} item justifyContent="start" alignItems="center">
                {selectedOrganization?.value ? (
                  <Autocomplete
                    id="organizations-header-dropdown"
                    data-testid="organizations-header-dropdown"
                    options={organizations as Option[]}
                    sx={{ width: 300 }}
                    disableClearable
                    value={{
                      id: selectedOrganization.value,
                      value: selectedOrganization.value,
                      label: selectedOrganization.label,
                      timezone: selectedOrganization.timezone,
                      level: selectedOrganization.level,
                      index: selectedOrganization.index,
                    }}
                    onChange={onChangeSelectOrganization}
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option?.value}
                        style={{
                          marginLeft: `${option?.level * orgHierarchyIndent}px`,
                        }}
                      >
                        {option?.label ?? ""}
                      </li>
                    )}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    filterOptions={filterOptionsOnSearch}
                  />
                ) : (
                  <Skeleton
                    variant="rounded"
                    width={300}
                    height={49}
                    data-testid={"organizations-header-dropdown-loader"}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {userOrgData && (
            <Grid
              data-testid="header-logo"
              onClick={onLogoClickHandler}
              className="cursor-pointer flex text-primary justify-center truncate basis-[900px]"
            >
              {getBrandLogo()}
            </Grid>
          )}
          <Grid
            container
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item minWidth={"25px"} margin={"0 0.5rem"} padding={0}>
              {isImpersonated && (
                <Stack
                  spacing={1}
                  className="flex-no-wrap lg:w-56 lg:h-10 cursor-pointer !flex-row items-center rounded-full border border-error hover:bg-error hover:text-white "
                  data-testid="acctMenu-impersonated-container"
                  onClick={onStopImpersonateClick}
                  sx={{
                    color: "var(--stop-impersonate-btn)",
                    "&:hover .MuiIconButton-root": {
                      color: "var(--white)",
                    },
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="stop user impersonation"
                    className="!p-0 lg:!p-3"
                    data-testid="header-stop-impersonate-icon"
                    sx={{
                      color: "var(--error)",
                      "&:hover": {
                        color: "var(--white)",
                      },
                    }}
                  >
                    <StopCircleIcon />
                  </IconButton>
                  <Typography
                    className="hidden lg:block stop-impersonate-btn"
                    data-testid="header-stop-impersonate-label"
                  >
                    Stop impersonating
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  "@media (max-width:640px)": {
                    margin: 0,
                    padding: 0,
                  },
                }}
              >
                <SearchButton />
              </Grid>
              <Grid item>
                <NotificationsComponent />
              </Grid>
              <Grid>
                {!existingBrandLoading && companyLogo && (
                  <Button
                    onClick={onToggleAccountMenu}
                    className="max-w-[65px] min-w-[50px] m-0 p-0"
                  >
                    <img
                      data-testid="orgLogo"
                      src={companyLogo}
                      alt={companyLogo?.file_name ?? "company logo"}
                      className="w-full"
                      style={{ margin: 0, padding: 0 }}
                    />
                  </Button>
                )}
                {existingBrandLoading && (
                  <Skeleton variant="rounded" width={70} height={49} />
                )}
                {!existingBrandLoading && !companyLogo && (
                  <Typography
                    data-testid="orgName"
                    className={classNames(
                      "text-primary p-1 !font-bold",
                      isMobile
                        ? "xs:block sm:hidden"
                        : "hidden sm:block xs:hidden"
                    )}
                  >
                    {isMobile ? getOrgHeaderName(false) : getOrgHeaderName()}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {tokens && (
                  <Stack
                    spacing={1}
                    onClick={onToggleAccountMenu}
                    data-testid="acctMenu-container"
                    className="flex-no-wrap lg:h-10 cursor-pointer !flex-row items-center rounded-full"
                    sx={{
                      margin: 0,
                      padding: 0,
                      "@media (min-width:641px)": {
                        color: isImpersonated
                          ? "var(--white)"
                          : "var(--impersonate-container)",
                        backgroundColor: isImpersonated
                          ? "var(--indigo)"
                          : "transparent",
                        paddingRight: "1rem",
                      },
                    }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      sx={{
                        "@media (min-width:641px)": {
                          margin: isImpersonated ? "" : "0rem",
                          padding: isImpersonated ? "" : "0rem",
                          paddingRight: isImpersonated ? "" : "0.5rem",
                        },
                        "@media (max-width:640px)": {
                          maxWidth: "25px",
                        },
                      }}
                    >
                      {isImpersonated ? (
                        <SupervisorAccountOutlinedIcon
                          sx={{
                            backgroundColor: "var(--indigo)",
                            borderRadius: "50%",
                            "@media (max-width:640px)": {
                              padding: "1px",
                              fill: "var(--background)",
                            },
                          }}
                        />
                      ) : (
                        <AccountCircleOutlinedIcon
                          sx={{
                            fill: "var(--primary)",
                          }}
                        />
                      )}
                    </IconButton>
                    <Grid
                      data-testid="header-logo-right-corner"
                      className="cursor-pointer flex text-primary justify-center truncate basis-[900px]"
                    >
                      <Typography
                        data-testid="logged-in-username"
                        className={classNames(
                          "hidden sm:block self-center",
                          isImpersonated ? "text-white" : ""
                        )}
                      >
                        {capitalize(decodedToken?.name)}
                      </Typography>
                    </Grid>
                  </Stack>
                )}
              </Grid>
            </Grid>

            <Grid item>
              {!tokens && (
                <Link to="/login">
                  <Button variant="contained" color="secondary">
                    Login
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        <PopoverMenu
          open={accountMenuOpen}
          onClose={onCloseAccMenu}
          onLogout={logout}
          userData={userData}
        ></PopoverMenu>
      }
    </>
  );
};

export default memo(Header);
