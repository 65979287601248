import { FC, useState } from "react";
import { Box } from "@mui/material";
import { sortBy } from "lodash";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import {
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  formatDate,
  parseISODate,
} from "../../../../../utils/date";
import BatteryChartLegend from "../BatteryChart/BatteryChartLegend";
import { CustomBrakesChartTooltip } from "./CustomBrakesChartTooltip";

export interface IndividualAirTank {
  min?: number | null;
  max?: number | null;
  median?: number | null;
}
export interface AirTankData {
  date: string;
  airTank?: {
    // airTank1, airTank2 and so on (if needed)
    [key: string]: IndividualAirTank;
  } | null;
}

interface BrakesAirTankChartProps {
  data?: AirTankData[];
}

const containerHeight = 630;
const darkColors = ["#3C88CC", "#E78D3A"];
const lightColors = ["#3C88CC", "#E78D3A"];

export const BrakesAirTankChart: FC<BrakesAirTankChartProps> = ({ data }) => {
  const [hoveredLineIndex, setHoveredLineIndex] = useState<number>();
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);
  const muiTheme = useCurrentTheme();
  const isDarkMode = muiTheme.palette.mode === "dark";
  const chartColors = isDarkMode ? darkColors : lightColors;

  const formatXAxis = (tickItem: number) => {
    return formatDate(new Date(tickItem), DAY_MONTH_FORMAT);
  };

  const lineKeys = ["airTank1"];

  const visibleLines = lineKeys.filter((key) => !hiddenLines.includes(key));

  const formattedData = data?.map((d) => {
    const date = new Date(d.date);
    return {
      ...d,
      axisDate: new Date(date).getTime(),
      toolTipDate: formatDate(parseISODate(d.date), DATE_FORMAT),
    };
  });

  const sortedData = sortBy(formattedData, (d) => d?.date);
  const domain = [
    (sortedData[0]?.axisDate as number) ?? "auto",
    (sortedData[sortedData.length - 1]?.axisDate as number) ?? "auto",
  ];

  return (
    <>
      <Box
        className="relative !text-xs !font-medium"
        style={{ height: containerHeight }}
        data-testid="brakes-airtank-chart"
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={sortedData}
            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="axisDate"
              tickFormatter={formatXAxis}
              scale="time"
              type="number"
              domain={domain}
            />
            <YAxis
              label={{ value: "Pressure", angle: -90, position: "insideLeft" }}
              type={"number"}
              yAxisId={"pressure"}
            />
            <Tooltip
              content={
                <CustomBrakesChartTooltip activeIndex={hoveredLineIndex} />
              }
            />

            {lineKeys.map((key, index) =>
              hiddenLines.includes(key) ? null : (
                <Line
                  key={key}
                  strokeWidth={4}
                  type="linear"
                  yAxisId={"pressure"}
                  dataKey={`airTank.${key}.median`}
                  stroke={chartColors[index]}
                  connectNulls
                  dot={false}
                  activeDot={{
                    onMouseOver: () => {
                      setHoveredLineIndex(
                        visibleLines.findIndex((k) => k === key)
                      );
                    },
                    onMouseOut: () => setHoveredLineIndex(undefined),
                  }}
                />
              )
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Box className="mb-12 flex justify-center gap-8">
        {lineKeys.map((key, index) => (
          <BatteryChartLegend
            key={key}
            label={`Air Tank ${index + 1}`}
            checked={!hiddenLines.includes(key)}
            color={chartColors[index]}
            onClick={() =>
              setHiddenLines((prevState) =>
                prevState.includes(key)
                  ? prevState.filter((k) => k !== key)
                  : [...prevState, key]
              )
            }
          />
        ))}
      </Box>
    </>
  );
};
