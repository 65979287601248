import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useFindAssetByIdQuery } from "../../../../../../graphql/operations";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext/AssetsDataContext";
import AssetErrorMessage from "../../../Shared/AssetsErrorMessage";
import RegulatorAveragePressureCard from "./cards/regulator/RegulatorAveragePressureCard";
import RegulatorStatusCard from "./cards/regulator/RegulatorStatusCard";
import RegulatorChart from "./charts/sensors/RegulatorChart";

const RegulatorSensorSection = () => {
  const { selectedAssetId } = useAssetsDataContext();
  const isMobile = useBreakpoint("down", "sm");

  const {
    isLoading,
    isSuccess,
    isError,
    data: assetData,
  } = useFindAssetByIdQuery(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const selectedAsset = useMemo(
    () => assetData?.findAssetById ?? null,
    [assetData]
  );

  const supplyPressure =
    selectedAsset?.sensors?.psiAirSupply?.data?.supplyPressure;
  if (!isLoading && !isError && !supplyPressure) {
    return null;
  }

  return (
    <Box
      className="flex flex-col w-full mt-4"
      data-testid="asset-dashboard--tires-tab--regulator-sensor-section"
    >
      {!isLoading && (
        <>
          {isError && <AssetErrorMessage />}

          {isSuccess && supplyPressure && (
            <>
              <Box
                className={"flex gap-4 "}
                data-testid="regulatorWrapper"
                sx={{ height: "100%" }}
              >
                <Box
                  sx={{
                    gap: "16px",
                    justifyContent: "space-between",
                    flexGrow: 1,
                  }}
                  className={`regulatorMainContainer flex w-full ${
                    isMobile ? "flex-col" : "flex-row"
                  }`}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexFlow: "column nowrap",
                      flex: "0 0 25%",
                    }}
                    data-testid="regulatorLeftPanel"
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                      data-testid="regulatorStatusCard"
                      className={"mb-4 "}
                    >
                      <RegulatorStatusCard asset={selectedAsset} />
                    </Box>
                    <Box
                      data-testid="regulatorAveragePressureCard"
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        height: "100%",
                        flexFlow: "column nowrap",
                      }}
                    >
                      <RegulatorAveragePressureCard asset={selectedAsset} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: isMobile ? "600px" : "100%",
                      flexDirection: "column",
                      flex: 1,
                      flexGrow: 1,
                      width: isMobile ? "100%" : "50%",
                    }}
                    data-testid="regulatorChart"
                  >
                    {!isMobile && <RegulatorChart asset={selectedAsset} />}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default RegulatorSensorSection;
