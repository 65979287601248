export const columnVisibilityModel = {
  asset_id: true,
  timestamp: true,
  arrival_cargo_state: true,
  arrival_time: true,
  departure_cargo_state: true,
  departure_time: true,
  geofence_id: true,
  geofence_name: true,
  imei: true,
  totalDwelling: true,
};
