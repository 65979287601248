import { AlertTypeV2 } from "../../graphql/operations";

export const AlertTypeTitle: {
  [AlertTypeV2.Cargo]: string;
  [AlertTypeV2.Geofence]: string;
  [AlertTypeV2.Dwell]: string;
  [AlertTypeV2.Custom]: string;
  [AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]: string;
  [AlertTypeV2.Speeding]: string;
  [AlertTypeV2.WheelEnd]: string;
} = {
  [AlertTypeV2.Geofence]: "Geofence",
  [AlertTypeV2.Cargo]: "Cargo",
  [AlertTypeV2.Dwell]: "Dwell",
  [AlertTypeV2.Custom]: "Custom",
  [AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]:
    "Moving without Primary or Secondary Power",
  [AlertTypeV2.Speeding]: "Speeding",
  [AlertTypeV2.WheelEnd]: "Wheel End",
};
