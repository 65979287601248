import { memo, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useAuthContext } from "../../../context/AuthContext";
import { useUserData } from "../../../shared/hooks/useUserData";
import DashboardButtons from "../DashboardButtons";
import TilesContainer from "../TilesContainer";
import { filterMenuOptions } from "./constants";

interface HomeComponentProps {
  onClose?: () => void;
}

const HomeComponent: React.FC<HomeComponentProps> = ({ onClose }) => {
  const [storageKey, setStorageKey] = useState("tmp");
  const location = useLocation();
  const { pathname } = location;
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();
  const userData = useUserData();

  useEffect(() => {
    if (userInfo && storageKey === "tmp") {
      setStorageKey(
        `${filterMenuOptions.assets.toLowerCase()}-search-items-${
          userInfo.username
        }`
      );
    }
  }, [userInfo, storageKey]);

  const handleMoveTo = (url: string) => {
    if (onClose && pathname === url) {
      onClose();
      return;
    }
    navigate(url);
  };
  const welcomeMessage = userData?.firstName
    ? `Welcome back, ${userData.firstName}`
    : "Welcome back!";

  return (
    <Paper
      data-testid="dashboard-component"
      className="h-full w-full !bg-background"
      square
      elevation={0}
    >
      <Box>
        <div className="mx-8 mb-6 pt-18 text-center">
          <Typography
            title={welcomeMessage}
            variant="h3"
            className="text-ellipsis !text-4xl !leading-snug sm:!text-5xl"
            gutterBottom
          >
            {welcomeMessage}
          </Typography>
        </div>
        <Grid container>
          <Grid item container className="!mb-10 !pt-0">
            <DndProvider backend={HTML5Backend}>
              <TilesContainer />
            </DndProvider>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            className="!bg-background !pt-0"
          >
            <DashboardButtons moveTo={handleMoveTo} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default memo(HomeComponent);
