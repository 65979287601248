import { Box } from "@mui/material";
import classNames from "classnames";
import { STATE_TO_COLOR } from "../../../TiresTabPanel/components/charts/components/regulator/RegulatorChartBody";
import { isCurrentDualImbalanceSelected } from "../../../TiresTabPanel/helpers";
import {
  AxleSide,
  ImbalanceVariantsByAxle,
  TPMS_Axle,
  TPMS_Tire,
} from "../../../TiresTabPanel/interfaces";
import {
  TiresDualImbalanceChips,
  TiresSensorChips,
} from "../../hooks/useGetSensorStateWidget";
import { TireSensorChip } from "../TireSensorChip/TireSensorChip";

export interface TiresSensorStateWidgetProps {
  sensorLabel: TPMS_Axle;
  sensorsChips: TiresSensorChips;
  dualImbalanceChips: TiresDualImbalanceChips;
  size?: "small" | "big";
  selectedTire?: TPMS_Tire;
  selectedDualImbalanceRecord?: ImbalanceVariantsByAxle;
}

// Put into constants if needed somewhere else
export const dualImbalanceContainerStyling = {
  display: "flex",
  borderRadius: "20px",
  paddingTop: "7px",
  width: "173px", // width complying with design look
  flexDirection: "column",
  alignItems: "center",
};

export const TiresSensorStateWidget: React.FC<TiresSensorStateWidgetProps> = ({
  sensorLabel,
  sensorsChips,
  size = "small",
  selectedTire,
  dualImbalanceChips,
  selectedDualImbalanceRecord,
}) => {
  const { innerLeft, outerLeft, innerRight, outerRight } = sensorsChips;
  const { left, right } = dualImbalanceChips;

  return (
    <div className="front-tires flex justify-between">
      {innerLeft ? (
        <Box
          sx={{
            ...dualImbalanceContainerStyling,
            backgroundColor: isCurrentDualImbalanceSelected(
              sensorLabel,
              AxleSide.LEFT,
              selectedDualImbalanceRecord as ImbalanceVariantsByAxle
            )
              ? "var(--primary-blue-transparent)"
              : STATE_TO_COLOR[left.label as keyof typeof STATE_TO_COLOR], // background color related to status
          }}
          component="div"
        >
          {left.label !== "Unknown" && left.icon}
          <Box display="flex" component="div">
            <TireSensorChip
              dataTestid="front-left-outer"
              color={outerLeft.color}
              icon={outerLeft.icon}
              size={size}
              isSelected={selectedTire === TPMS_Tire.OuterLeft}
            />
            <TireSensorChip
              dataTestid="front-left-inner"
              color={innerLeft.color}
              icon={innerLeft.icon}
              size={size}
              isSelected={selectedTire === TPMS_Tire.InnerLeft}
            />
          </Box>
        </Box>
      ) : (
        <TireSensorChip
          dataTestid="front-left-outer"
          color={outerLeft.color}
          icon={outerLeft.icon}
          size={size}
          isSelected={selectedTire === TPMS_Tire.OuterLeft}
        />
      )}
      <div
        className={classNames(
          `flex items-center`,
          {
            "w-[76px]": size === "small",
          },
          {
            "flex-grow": size === "big",
          }
        )}
      >
        <div
          className={classNames(
            `w-full front-divider leading-none font-medium border-gray text-center text-light-charcoal`,
            {
              "text-[9px]": size === "small",
              "border-b": size === "small",
            },
            {
              "text-[24px] ": size === "big",
              "border-b-4": size === "big",
            }
          )}
        >
          {sensorLabel}
        </div>
      </div>
      {innerRight ? (
        <Box
          sx={{
            ...dualImbalanceContainerStyling,
            backgroundColor: isCurrentDualImbalanceSelected(
              sensorLabel,
              AxleSide.RIGHT,
              selectedDualImbalanceRecord as ImbalanceVariantsByAxle
            )
              ? "var(--primary-blue-transparent)"
              : STATE_TO_COLOR[right.label as keyof typeof STATE_TO_COLOR], // background color related to status
          }}
          component="div"
        >
          {right.label !== "Unknown" && right.icon}
          <Box display="flex" component="div">
            <TireSensorChip
              dataTestid="front-right-inner"
              color={innerRight.color}
              icon={innerRight.icon}
              size={size}
              isSelected={selectedTire === TPMS_Tire.InnerRight}
            />
            <TireSensorChip
              dataTestid="front-right-outer"
              color={outerRight.color}
              icon={outerRight.icon}
              size={size}
              isSelected={selectedTire === TPMS_Tire.OuterRight}
            />
          </Box>
        </Box>
      ) : (
        <TireSensorChip
          dataTestid="front-right-outer"
          color={outerRight.color}
          icon={outerRight.icon}
          size={size}
          isSelected={selectedTire === TPMS_Tire.OuterRight}
        />
      )}
    </div>
  );
};
