export const PAGE_SPINNER = "PAGE_SPINNER";
export const PAGE_SPINNER_MESSAGE = "PAGE_SPINNER_MESSAGE";
export const PAGE_SNACKBAR = "PAGE_SNACKBAR";
export const PAGE_DIALOG = "PAGE_DIALOG";
export const LOCATION_CHANGE = "LOCATION_CHANGE";
export const LOCATION_RESET = "LOCATION_RESET";
export const ASSET_SEARCH = "ASSET_SEARCH";
export const ASSET_SEARCH_RESET = "ASSET_SEARCH_RESET";
export const ASSET_TARGET = "ASSET_TARGET";
export const ASSET_TARGET_RESET = "ASSET_TARGET_RESET";
export const ASSET_TYPE = "ASSET_TYPE";
export const ASSET_TYPE_RESET = "ASSET_TYPE_RESET";
export const ASSET_SEARCH_HISTORY = "ASSET_SEARCH_HISTORY";
export const ASSET_PARTIAL_SEARCH = "ASSET_PARTIAL_SEARCH";
export const ASSET_PARTIAL_SEARCH_RESET = "ASSET_PARTIAL_SEARCH_RESET";
export const ASSETS_LIST_TABLE_SEARCH_SET_LOADING =
  "ASSETS_LIST_TABLE_SEARCH_SET_LOADING";
export const COLUMN_MENU_HIDE_LABEL = "Hide Columns";
export const ROOT_ORGANIZATION_NAME = "Phillips Connect";
export const ADD_TABLE_STATE = "ADD_TABLE_STATE";
export const TABLE_FILTERS_RESET = "TABLE_FILTERS_RESET";
export const TERRITORIES_IN_USE_USA = "TERRITORIES_IN_USE_USA";
export const TERRITORIES_IN_USE_CANADA = "TERRITORIES_IN_USE_CANADA";
export const TERRITORIES_IN_USE_MEXICO = "TERRITORIES_IN_USE_MEXICO";
export const SET_DISPLAY_SCHEDULE_MODAL = "SET_DISPLAY_SCHEDULE_MODAL";
export const SET_APP_THEME = "SET_APP_THEME";
export const SET_APP_BRAND_COLOR = "SET_APP_BRAND_COLOR";
export const DISABLE_ASSET_TABLE_ON_CLICK = "DISABLE_ASSET_TABLE_ON_CLICK";
export const DEVICES_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/createDevice.xlsx";
export const ASSETS_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/createAsset.xlsx";
export const USERS_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/createUser.xlsx";
export const ASSIGN_SENSOR_PROFILES_FILE_TEMPLATE_PATH =
  "/upload-templates/assignProfile.xlsx";
export const FAULT_CODE_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/createFaultCode.xlsx";
export const INSTALL_DEVICES_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/InstallDeviceAsset.xlsx";
export const TRANSFER_ASSETS_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/transferAsset.xlsx";
export const TRANSFER_DEVICES_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/transferDevice.xlsx";
export const CUSTOMER_ORGS_UPLOAD_FILE_TEMPLATE_PATH =
  "/upload-templates/UploadOrganization.xlsx";
export const DATE_TIME_FORMAT = "MM/dd/yy hh:mm aa";
export const SET_BREADCRUMBS_FILTERS = "SET_BREADCRUMBS_FILTERS";
export const SET_BREADCRUMBS_START_DATE = "SET_BREADCRUMBS_START_DATE";
export const SET_BREADCRUMBS_END_DATE = "SET_BREADCRUMBS_END_DATE";
export const SET_BREADCRUMBS_CURRENT_PAGE_RANGE =
  "SET_BREADCRUMBS_CURRENT_PAGE_RANGE";
export const SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID =
  "SET_BREADCRUMBS_MAP_SELECTED_MARKER_ID";
export const SET_APP_CONFIG = "SET_APP_CONFIG";

export const SET_PARENT_GEOFENCE = "SET_PARENT_GEOFENCE";
export const USE_PARENT_GEOFENCE_ORGID = "USE_PARENT_GEOFENCE_ORGID";

export const EXTERNAL_HELP_URL = "https://www.phillips-connect.com/";

export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";
export const SELECTED_ORGANIZATION = "SELECTED_ORGANIZATION";
