import { useMemo } from "react";
import { useAppContext } from "../../context/AppContext";
import {
  Asset,
  useGetAssetsForListQuery,
  TableFilterLinkOperator,
  AssetInput,
  useGetAssetsForListOsQuery,
  GetAssetsForListOsQuery,
  GetAssetsForListQuery,
  AssetOs,
  AssetInputOs,
  TableFilterOperator,
  TableValueDataType,
} from "../../graphql/operations";
import { useFeatureFlag } from "../../utils";
import { FeatureFlags } from "../../utils/featureFlagsConstants";
import { assetsListInputParams } from "../../views/AssetsView/helpers/helpers";

export const useFindAssetListOptions = (search: string, limit: number) => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const assetInput: AssetInput = {
    ...assetsListInputParams,
    filterList: [
      {
        filters: [
          {
            columnField: "asset_id",
            operatorValue: "startsWith",
            value: JSON.stringify(search),
          },
          {
            columnField: "customer_orgs_id",
            operatorValue: "equals",
            value: JSON.stringify({ value: selectedOrganization.value }),
          },
        ],
        linkOperator: TableFilterLinkOperator.And,
      },
    ],
    skip: 0,
    limit,
  };

  const assetInputOs: AssetInputOs = {
    ...assetsListInputParams,
    filterList: {
      filters: [
        {
          field: "customer_orgs_id",
          dataType: TableValueDataType.String,
          operator: TableFilterOperator.Equals,
          value: JSON.stringify({ value: selectedOrganization.value }),
        },
        {
          field: "asset_id",
          dataType: TableValueDataType.String,
          operator: TableFilterOperator.Equals,
          value: JSON.stringify({ value: search }),
        },
      ],
      linkOperator: TableFilterLinkOperator.And,
    },
    skip: 0,
    limit,
  };

  const fn = fetchAssetsFromOpenSearchFeatureFlag
    ? useGetAssetsForListOsQuery
    : useGetAssetsForListQuery;

  const input = (
    fetchAssetsFromOpenSearchFeatureFlag ? assetInputOs : assetInput
  ) as (AssetInputOs & AssetInput) | null | undefined;

  const props = fn({ input }, { enabled: search.length > 2 });

  const { data: assetDataList, isFetching } = props;

  const searchResult = useMemo<Array<{ id: string; label: string }>>(
    () =>
      fetchAssetsFromOpenSearchFeatureFlag
        ? (
            assetDataList as GetAssetsForListOsQuery | undefined
          )?.getAssetsOS?.assets
            ?.filter((item): item is AssetOs => !!item && !!item.asset_id)
            .map(({ _id, asset_id }) => ({ id: _id, label: asset_id! })) ?? []
        : (
            assetDataList as GetAssetsForListQuery | undefined
          )?.getAssets?.assets
            ?.filter((item): item is Asset => !!item && !!item.asset_id)
            .map(({ _id, asset_id }) => ({ id: _id, label: asset_id! })) ?? [],
    [assetDataList, fetchAssetsFromOpenSearchFeatureFlag]
  );

  return {
    isFetching,
    options: searchResult,
  };
};
