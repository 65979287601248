import type { FieldStruct, Fields } from "@react-awesome-query-builder/ui";
import {
  prepareConfigDoorStatus,
  prepareConfigOutsideOfGeofenceState,
  prepareConfigTripState,
} from "./utils";
import {
  prepareConfigTextField,
  prepareConfigBooleanField,
  prepareConfigNumberField,
  prepareConfigDateField,
} from "./index";

export const assetQueryBuilderFields: Fields = {
  asset: {
    label: "Asset",
    type: "!struct",
    subfields: {
      added_by: prepareConfigTextField("Added By"),
      archived: prepareConfigBooleanField("Archived"),
      asset_group: prepareConfigTextField("Asset Group"),
      asset_id: prepareConfigTextField("Asset ID"),
      "sensors.voltage.data.battery": prepareConfigNumberField(
        "Battery Voltage",
        {
          min: 0,
          step: 0.01,
        }
      ),
      "sensors.voltage.readingDate": prepareConfigDateField(
        "Battery Voltage Update"
      ),
      "sensors.chassis.data.cargoState": prepareConfigTextField("Cargo State"),
      cargo_state_update: prepareConfigDateField("Cargo State Update"),
      category: prepareConfigTextField("Category"),
      "fullAddress.city": prepareConfigTextField("City"),
      customer_orgs_id: prepareConfigTextField("Customer Orgs ID"),
      door_type: prepareConfigTextField("Door Type"),
      outsideOfGeofence: prepareConfigOutsideOfGeofenceState(
        "Outside Of Geofence"
      ),
      "sensors.bleDoorSensor.data.doorState":
        prepareConfigDoorStatus("Door Status"),
      "tripStatus.tripState": prepareConfigTripState("Trip Status"),
      "dwelling.dwellingDays": prepareConfigNumberField(
        "Dwelling Days Outside",
        {
          min: 0,
        }
      ),
      "dwelling.dwellingStatus": prepareConfigTextField(
        "Dwelling Status Outside"
      ),
      "geofence.dwelling.dwellingDays": prepareConfigNumberField(
        "Dwelling Days Inside",
        {
          min: 0,
        }
      ),
      "geofence.dwelling.dwellingStatus": prepareConfigTextField(
        "Dwelling Status Inside"
      ),
      "geofence.collisionType": prepareConfigTextField("Collision"),
      "geofence.name": prepareConfigTextField("Geofence Name"),
      "sensors.voltage.data.main": prepareConfigNumberField(
        "External Voltage",
        {
          min: 0,
          step: 0.01,
        }
      ),
      event_name: prepareConfigTextField("Event Name"),
      fullAddressFormatted: prepareConfigTextField("Full Address"),
      gps_lock: prepareConfigBooleanField("GPS Lock"),
      gps_lock_update: prepareConfigDateField("GPS Lock Update"),
      height: prepareConfigNumberField("Height", { min: 0 }),
      imei: prepareConfigTextField("IMEI (Device ID)"),
      last_gps_t: prepareConfigDateField("Last GPS Time"),
      length: prepareConfigNumberField("Length", { min: 0 }),
      license_plate: prepareConfigNumberField("License Plate", { min: 0 }),
      license_state: prepareConfigTextField("License State"),
      "location.type": prepareConfigTextField("Location Type"),
      "location.coordinates.1": prepareConfigNumberField("Location Latitude"),
      "location.coordinates.0": prepareConfigNumberField("Location Longitude"),
      lst_evnt_t: prepareConfigDateField("Last Event Time"),
      make: prepareConfigTextField("Make"),
      name: prepareConfigTextField("Name"),
      num_of_axles: prepareConfigNumberField("Number of Axles", { min: 0 }),
      "tripStatus.odometer": prepareConfigNumberField("Odometer", { min: 0 }),
      odo_update: prepareConfigDateField("Odometer Update"),
      "fullAddress.postcode": prepareConfigTextField("Postcode"),
      prd_cde: prepareConfigTextField("Product Code"),
      "tripStatus.signal": prepareConfigBooleanField("Signal"),
      "tripStatus.speed": prepareConfigNumberField("Speed", { min: 0 }),
      speed_update: prepareConfigDateField("Speed Update"),
      stale_time: prepareConfigDateField("Stale Time"),
      "fullAddress.state": prepareConfigTextField("State"),
      tags: prepareConfigTextField("Tags"),
      trip_st_update: prepareConfigDateField("Trip Status Update"),
      vin: prepareConfigTextField("VIN"),
      wheel_config: prepareConfigNumberField("Wheel Config"),
      updated_by: prepareConfigTextField("Updated By"),
      "timestamps.createdAt": prepareConfigDateField("Created At"),
      "timestamps.updatedAt": prepareConfigDateField("Updated At"),
    },
  },
};

export const incidentsQueryBuilderFields: Fields = {
  assetIdentifier: prepareConfigTextField("Asset Identifier"),
  assetName: prepareConfigTextField("Asset Name"),
  assetType: prepareConfigTextField("Asset Type"),
  batteryStatus: prepareConfigTextField("Battery Status"),
  cargoState: prepareConfigTextField("Cargo State"),
  batteryVoltage: prepareConfigNumberField("Battery Voltage", {
    min: 0,
    step: 0.01,
  }),
  deviceAssociated: prepareConfigTextField("Device Associated"),
  doorType: prepareConfigTextField("Door Type"),
  dwellingDays: prepareConfigNumberField("Dwelling Days", { min: 0 }),
  imei: prepareConfigTextField("IMEI"),
  length: prepareConfigNumberField("Length", { min: 0 }),
  manufacturerName: prepareConfigTextField("Manufacturer Name"),
  modelYear: prepareConfigTextField("Model Year"),
  model: prepareConfigTextField("Model"),
  numberOfAxles: prepareConfigNumberField("Number of Axles", { min: 0 }),
  primaryVoltage: prepareConfigNumberField("Primary Voltage", {
    min: 0,
    step: 0.01,
  }),
  prdName: prepareConfigTextField("Product Name"),
  "tripStatus.signal": prepareConfigBooleanField("Signal"),
  secondaryVoltage: prepareConfigNumberField("Secondary Voltage", {
    min: 0,
    step: 0.01,
  }),
  tags: prepareConfigTextField("Tags"),
  vin: prepareConfigTextField("VIN"),
  tripStatus: prepareConfigTextField("Trip Status"),
  wheelConfig: prepareConfigNumberField("Wheel Config"),
};
