import * as yup from "yup";
import { transformers } from "../../../../../../../utils/yupUtils";

export const faultCodesFormSchema = yup.object().shape({
  manufacturer: yup.string().optional().transform(transformers.objectToString),
  faultCodeType: yup.string().optional().transform(transformers.string),
  pgn: yup.number().optional().transform(transformers.number),
  dtc: yup.string().optional().transform(transformers.string),
  sid: yup.number().optional().transform(transformers.number),
  fmi: yup.number().optional().transform(transformers.number),
  problemArea: yup.string().optional(),
  cause: yup.string().optional(),
  description: yup.string().optional(),
  pctExplains: yup.string().optional(),
});
