import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactElement,
  MutableRefObject,
} from "react";
import { ThemeProvider, Box, SxProps } from "@mui/material";
import { Breakpoint, Theme } from "@mui/material/styles";
import {
  DataGridPremium,
  gridVisibleRowCountSelector,
  DataGridPremiumProps,
  GridCellParams,
  GridRowParams,
  GridSortModel,
  GridRowGroupingModel,
  GridColumnVisibilityModel,
  GridColumnOrderChangeParams,
  GridColDef,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { isEmpty, kebabCase } from "lodash";
import {
  ADD_TABLE_STATE,
  COLUMN_MENU_HIDE_LABEL,
  TABLE_FILTERS_RESET,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { useAuthContext } from "../../../context/AuthContext";
import {
  TableViewType,
  useGetUserDataQuery,
  TableViewPreferenceColumn,
} from "../../../graphql/operations";
import { TableViewMenuRef } from "../../../views/ReportView/AssetReport";
import useBreakpoint from "../../hooks/useBreakpoint";
import TableViewsMenu from "../TableViewsMenu/TableViewsMenu";
import { LoadingOverlay } from "./LoadingOverlay";
import "./Table.scss";
import TableClientSideSearch from "./TableClientSideSearch";
import ErrorOverlay from "./TableErrorOverlay";
import TableFooter from "./TableFooter";
import NoRowsOverlay from "./TableNoRowsOverlay";
import TablePopover from "./TablePopover";
import { TableServerSideSearch } from "./TableServerSideSearch";
import Toolbar from "./TableToolbar";
import { StringTableFilterValue } from "./types";
import { useDataTestId } from "./useDataTestId";
import { useExtendTableFilters } from "./useExtendTableFilters";
import { useTableTheme } from "./useTableTheme";
import { TableDataModes } from "./utils";

/**
 * A helper to add default column properties. Note that you can override any default property if needed.
 * @param ...props MUI DataGrid column properties
 */

const defaultSX: SxProps<Theme> = {
  "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "700" },
  "& .MuiDataGrid-row:hover": { backgroundColor: "rgba(80, 134, 199, 0.12)" },
};

const getDataGridDataMode = (
  dataMode: string | undefined
): TableDataModes.Client | TableDataModes.Server =>
  dataMode === TableDataModes.Client
    ? TableDataModes.Client
    : TableDataModes.Server;

export type Row = {
  _id: string;
  id: string;
  [key: string]: any;
};

export interface TableProps<T extends GridValidRowModel> {
  onExportClick?: (fileType: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  sx?: SxProps<Theme>;
  actions?: ReactElement | null;
  currentPage?: number;
  rowHeight?: number;
  rows: GridRowsProp<T>;
  columns: GridColDef[];
  pageSize?: number;
  pagination?: boolean;
  className?: string;
  showToolbar?: boolean;
  treeData?: boolean;
  getTreeDataPath?: (row: Row) => any;
  autoHeight?: boolean;
  groupingColDef?: DataGridPremiumProps["groupingColDef"];
  loading?: boolean;
  handleDataMode?: TableDataModes;
  onPageChange?: (page: number) => void;
  onSortModelChange?: (value: GridSortModel) => void;
  error?: boolean | null;
  getRowId?: (row: any) => string | number;
  onCellClick?: (cell: GridCellParams) => void;
  onRowDoubleClick?: (row: GridRowParams) => void;
  onRowClick?: (row: GridRowParams) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  sortModel?: GridSortModel;
  searchKeys?: string[];
  sortKeys?: string[];
  searchMinLength?: number;
  enableSearch?: boolean;
  searchThreshold?: number;
  searchExactMatch?: boolean;
  allowExport?: boolean;
  exportProps?: {
    csvOptions: { fileName: string };
    excelOptions: { fileName: string };
    printOptions: { fileName: string };
  };
  initialSearch?: string;
  isPopOver?: boolean;
  initialState?: any;
  tableName: string;
  tableType?: TableViewType;
  onRowGroupingModelChange?: (model: GridRowGroupingModel) => void;
  rowGroupingModel?: GridRowGroupingModel | undefined;
  changeColumnsVisibility?: (model: GridColumnVisibilityModel) => void;
  onColumnOrderChange?: (colData: GridColumnOrderChangeParams) => void;
  tableHeight?: number | string;
  tableMinHeight?: number | string;
  onSearch?: (searchQuery: string) => void;
  checkboxSelection?: boolean;
  disableSelectionOnClick?: boolean;
  updateSelectedRowsHandler?: (value: any) => void;
  onFilterModelChange?: (value?: any) => void;
  onPaginationModelChange?: (page: number) => void;
  rowCount?: number;
  offset?: number;
  disableRowGrouping?: boolean;
  isDataRefetching?: boolean;
  handleVisibleRowsCount?: (data: number) => void;
  mobileBreakpoint?: number | Breakpoint;
  disableColumnFilter?: boolean;
  useExtendedSearch?: boolean;
  tableViewsMenuRef?: MutableRefObject<TableViewMenuRef | null>;
  tableFiltersToSkip?: StringTableFilterValue[] | undefined;
}
const Table = <T extends GridValidRowModel>({
  apiRef,
  sx,
  actions,
  currentPage = 1,
  rowHeight = 45,
  rows,
  columns,
  pageSize = 25,
  pagination = true,
  className,
  showToolbar = true,
  treeData = false,
  getTreeDataPath,
  autoHeight,
  groupingColDef,
  loading,
  handleDataMode = TableDataModes.Client,
  onExportClick,
  onPageChange,
  onSortModelChange,
  error,
  getRowId,
  onCellClick,
  onRowDoubleClick,
  onRowClick,
  columnVisibilityModel,
  sortModel,
  searchKeys,
  sortKeys,
  searchMinLength = 3,
  enableSearch,
  searchThreshold,
  searchExactMatch,
  allowExport = true,
  exportProps,
  initialSearch,
  isPopOver,
  initialState,
  tableName,
  tableType,
  onRowGroupingModelChange,
  rowGroupingModel,
  changeColumnsVisibility,
  onColumnOrderChange,
  tableHeight = "100%",
  tableMinHeight = "400px",
  onSearch,
  checkboxSelection,
  disableSelectionOnClick,
  updateSelectedRowsHandler,
  onFilterModelChange,
  onPaginationModelChange,
  rowCount = 0,
  offset,
  disableRowGrouping,
  isDataRefetching,
  handleVisibleRowsCount,
  mobileBreakpoint = "md",
  disableColumnFilter,
  useExtendedSearch = true,
  tableViewsMenuRef,
  tableFiltersToSkip = [],
}: TableProps<T>) => {
  const [page, setPage] = useState(currentPage);
  const [visibleColumns, setVisibleColumns] = useState(columnVisibilityModel);
  const [sortingModel, setSortingModel] = useState(sortModel);
  const [pinnedColumns, setPinnedColumns] = useState({});
  const [needUpdateDataTestIds, setNeedUpdateDataTestIds] = useState(false);
  const [foundRows, setFoundRows] = useState<GridRowsProp<GridValidRowModel>>(
    []
  );
  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const [visibleRowsCount, setVisibleRowsCount] = useState(0);
  const updatedColumns = useExtendTableFilters(columns, tableFiltersToSkip);
  const [tableColumns, setTableColumns] =
    useState<GridColDef[]>(updatedColumns);
  const { isAuthorized } = useAuthContext();
  const tableColumnsRef = useRef(tableColumns);
  const { state, dispatch } = useAppContext();
  const boxRef = useRef();
  const tableTheme = useTableTheme();
  const [selectRows, setSelectRows] = useState<GridValidRowModel>([]);
  const [groupedBy, setGroupedBy] = useState([]);
  const [isUserLayoutUpdated, setIsUserLayoutUpdated] = useState(false);

  const isMobile = useBreakpoint("down", mobileBreakpoint);

  const { data: userData, isFetching: isFetchingUserData } =
    useGetUserDataQuery(undefined, {
      enabled: isAuthorized,
    });

  const handleSortModelChange = useCallback(
    (newModel: GridSortModel) => {
      setSortingModel(newModel);
      onSortModelChange && onSortModelChange(newModel);
    },
    [columns, loading, onSortModelChange] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      setVisibleColumns(newModel);
      changeColumnsVisibility && changeColumnsVisibility(newModel);
    },
    [columns, loading, changeColumnsVisibility] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPinnedColumnsChange = useCallback(
    (updatedPinnedColumns: any) => setPinnedColumns(updatedPinnedColumns),
    []
  );

  const handleTableLayoutChange = useCallback(
    (savedColumns: TableViewPreferenceColumn[]) => {
      const currentColumns = tableColumnsRef.current;

      const newColumns = savedColumns.map(
        (savedColumn: TableViewPreferenceColumn) => {
          const newColumn = currentColumns.find(
            (column) => column.headerName === savedColumn.name
          );
          if (newColumn) {
            return {
              ...newColumn,
              width: savedColumn.minWidth ?? 0,
              flex: 0,
            };
          }
          return {
            field: savedColumn.name,
            headerName: savedColumn.name,
            width: savedColumn.minWidth ?? 0,
            flex: 0,
          } as GridColDef;
        }
      );

      setTableColumns(newColumns);
      tableColumnsRef.current = newColumns;
    },
    []
  );

  const handleTableLayoutSave = () => {
    setIsUserLayoutUpdated(true);
  };

  useEffect(() => {
    if (
      !isUserLayoutUpdated &&
      !isFetchingUserData &&
      userData?.me?.user_preferences?.tableViews?.length
    ) {
      const tableViews = userData.me.user_preferences.tableViews;
      const match = tableViews.find((view) => view?.type === tableType);
      if (match?.value?.columns) {
        handleTableLayoutChange(match.value.columns);
      }
    }
  }, [
    userData,
    isFetchingUserData,
    tableType,
    isUserLayoutUpdated,
    handleTableLayoutChange,
  ]);

  useEffect(() => {
    setFoundRows(rows);
  }, [rows]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useDataTestId({
    rows,
    pinnedColumns,
    visibleColumns,
    needUpdateDataTestIds,
    setNeedUpdateDataTestIds,
  });

  // Build footer component
  const Footer = () => {
    const calculatePages = () => {
      let numberOfPages = Math.ceil(rowCount / pageSize);

      if (handleDataMode === TableDataModes.FullServer) {
        return Math.ceil(rowCount / pageSize);
      } else if (
        handleDataMode === TableDataModes.Server ||
        handleDataMode === TableDataModes.SemiServer
      ) {
        if (isSearchTriggered) {
          return 1;
        } else {
          return numberOfPages;
        }
      } else {
        return Math.ceil(visibleRowsCount / pageSize);
      }
    };

    const getTotalRows = () => {
      if (handleDataMode === TableDataModes.FullServer) {
        return rowCount;
      } else if (
        handleDataMode === TableDataModes.Server ||
        handleDataMode === TableDataModes.SemiServer
      ) {
        if (isSearchTriggered) {
          return visibleRowsCount;
        }
        return rowCount;
      } else {
        return visibleRowsCount;
      }
    };

    const getPage = () => {
      if (handleDataMode === TableDataModes.FullServer) {
        return (offset ?? 0) / pageSize + 1;
      } else if (
        isSearchTriggered &&
        (handleDataMode === TableDataModes.Server ||
          handleDataMode === TableDataModes.SemiServer)
      ) {
        return 1;
      } else {
        return page;
      }
    };

    return (
      <TableFooter
        page={getPage()}
        onPageChange={(page) => {
          setPage(page);
          if (onPageChange) {
            onPageChange(page);
          }
        }}
        pages={calculatePages()}
        rowsPerPage={pageSize}
        selectedCount={selectRows.length}
        totalRows={getTotalRows()}
        pagination={Boolean(pagination)}
      />
    );
  };

  const onGroupingColumnChange = (...props: any) => {
    const currentGroups = props[0];
    const removedGroups = groupedBy.filter(
      (group) => !currentGroups.includes(group)
    );
    const newVisibilityColumnModel = { ...visibleColumns };

    removedGroups.forEach((group) => {
      newVisibilityColumnModel[group] = true;
    });
    currentGroups.forEach((key: string) => {
      newVisibilityColumnModel[key] = false;
    });
    setVisibleColumns(newVisibilityColumnModel);
    setGroupedBy(currentGroups);

    if (onRowGroupingModelChange) {
      onRowGroupingModelChange([...props]);
    }
  };

  const dataGridProps = {
    apiRef,
    sx: { ...defaultSX, ...sx },
    className,
    rowHeight,
    rows: loading ? [] : foundRows,
    pageSize,
    pagination,
    treeData,
    getTreeDataPath,
    groupingColDef,
    loading,
    error,
    getRowId,
    onCellClick,
    onRowDoubleClick,
    onRowClick,
    rowsLoadingMode: getDataGridDataMode(handleDataMode),
    // disable serve side filtering & sorting for assetTable
    filterMode:
      className === "assetListTable"
        ? TableDataModes.Client
        : getDataGridDataMode(handleDataMode),
    sortingMode:
      className === "assetListTable"
        ? TableDataModes.Client
        : getDataGridDataMode(handleDataMode),
    paginationMode: getDataGridDataMode(handleDataMode),
    columnVisibilityModel: visibleColumns,
    onColumnVisibilityModelChange,
    sortModel: sortingModel,
    onSortModelChange: handleSortModelChange,
    pinnedColumns,
    onPinnedColumnsChange,
    searchExactMatch,
    searchThreshold,
    initialState,
    onRowGroupingModelChange: onGroupingColumnChange,
    rowGroupingModel,
    onColumnOrderChange,
    onSearch,
    checkboxSelection,
    disableSelectionOnClick,
    onFilterModelChange: onFilterModelChange,
    onPaginationModelChange: onPaginationModelChange,
    disableRowGrouping,
  };

  let tableSearchClassName = showToolbar
    ? "z-10 absolute right-2 -bottom-14 md:right-4 md:-bottom-[3.6rem] w-52 md:w-auto"
    : "";

  useEffect(() => {
    if (!isEmpty(apiRef)) {
      // calculates visible rows in the table
      const handleStateChange = () => {
        const count = gridVisibleRowCountSelector(
          (apiRef as any).current.state,
          (apiRef as any).current.instanceId
        );
        setVisibleRowsCount(count);
      };
      return (apiRef as any).current.subscribeEvent(
        "stateChange",
        handleStateChange
      );
    }
  }, [apiRef, foundRows]);

  const handleColumnSizeChange = useCallback(() => {
    dispatch({
      type: ADD_TABLE_STATE,
      payload: {
        key: tableName,
        data: (apiRef as any).current.exportState(),
      },
    });
  }, [apiRef, dispatch, tableName]);

  const tableInitialState = state.table?.tableData?.[tableName];

  useEffect(() => {
    if (
      !isEmpty(apiRef) &&
      !isEmpty(tableInitialState) &&
      tableInitialState?.tableName === tableName
    ) {
      (apiRef as any).current.restoreState(tableInitialState);
    }
  }, [apiRef, initialState, tableInitialState, tableName]);

  // on component unmount (reset table reducer filters)
  useEffect(() => {
    return () =>
      dispatch({ type: TABLE_FILTERS_RESET, payload: { key: tableName } });
  }, [dispatch, tableName]);

  /**
   *   `disableVirtualization` is used in unit test to be able to render the full table not just the first 3 columns.
   *   It should always be `false` in production.
   *   @see https://mui.com/x/react-data-grid/virtualization
   *   @see https://github.com/mui/mui-x/issues/1519
   */
  const disableVirtualization = process.env.NODE_ENV === "test";

  useEffect(() => {
    if (typeof handleVisibleRowsCount === "function") {
      handleVisibleRowsCount(visibleRowsCount);
    }
  }, [handleVisibleRowsCount, visibleRowsCount]);

  /*
    This is sort of a workaround for a problem causing column sorting after it's been resized.
    Mentioned here: https://github.com/mui/mui-x/pull/9117
  */
  useEffect(() => {
    apiRef?.current?.subscribeEvent("columnResizeStart", () => {
      apiRef.current.columnHeadersElementRef!.current!.style.pointerEvents =
        "none";
    });

    apiRef?.current?.subscribeEvent("columnResizeStop", () => {
      apiRef.current.columnHeadersElementRef!.current!.style.pointerEvents =
        "unset";
    });
  }, [apiRef]);

  return (
    <Box
      data-testid={`${kebabCase(tableName)}-table`}
      ref={boxRef}
      sx={{
        height: tableHeight,
        minHeight: tableMinHeight,
        width: "100%",
        "& .super-app-theme--custom": {
          "&:hover": {
            bgcolor: "var(--table_row_hover)",
          },
        },
        "& .MuiDataGrid-root": {
          "& .MuiDataGrid-row.Mui-selected": {
            bgcolor: "var(--table_row_bg)",
          },
        },
        ...sx,
      }}
    >
      {enableSearch && (
        <div className="relative">
          {handleDataMode === TableDataModes.FullServer ? (
            <TableServerSideSearch
              className={tableSearchClassName}
              onSearch={onSearch ? onSearch : () => undefined}
              initialSearch={initialSearch}
              debounceTime={searchThreshold}
            />
          ) : (
            <TableClientSideSearch
              className={tableSearchClassName}
              rows={rows}
              onSearch={onSearch}
              searchKeys={searchKeys ?? []}
              sortKeys={sortKeys}
              searchMinLength={searchMinLength}
              setFoundRows={setFoundRows}
              setIsSearchTriggered={setIsSearchTriggered}
              searchExactMatch={searchExactMatch}
              searchThreshold={searchThreshold}
              initialSearch={initialSearch}
              isDataRefetching={isDataRefetching}
              tableName={tableName}
              useExtendedSearch={useExtendedSearch}
            />
          )}
        </div>
      )}
      <ThemeProvider theme={tableTheme}>
        <DataGridPremium
          hideFooter={!pagination}
          disableVirtualization={disableVirtualization}
          rowCount={
            getDataGridDataMode(handleDataMode) === TableDataModes.Server
              ? rowCount
              : visibleRowsCount
          }
          componentsProps={{
            // GridPanel
            baseSelect: { native: false },
            panel: {
              sx: {
                "& .MuiButtonBase-root": {
                  color: "var(--primary)",
                },
                "&.MuiButtonBase-root": { color: "var(--primary)" },
              },
            },
          }}
          columns={tableColumns}
          page={page - 1} // DataGridPremium page props is the zero-based index of the current page.
          localeText={{
            columnMenuHideColumn: COLUMN_MENU_HIDE_LABEL,
          }}
          autoHeight={autoHeight}
          {...dataGridProps}
          components={{
            Toolbar: (props) =>
              showToolbar ? (
                <Toolbar
                  allowExport={allowExport}
                  exportProps={exportProps}
                  apiRef={apiRef}
                  checkboxSelection={checkboxSelection}
                  onExportClick={onExportClick}
                  handleDataMode={handleDataMode}
                  isMobileView={isMobile}
                >
                  {tableType && tableType !== "report" && (
                    <TableViewsMenu
                      ref={apiRef}
                      tableType={tableType}
                      onTableLayoutChange={handleTableLayoutChange}
                      tableViewsMenuRef={tableViewsMenuRef}
                      onTableLayoutSave={handleTableLayoutSave}
                    />
                  )}

                  {actions}
                </Toolbar>
              ) : (
                <TablePopover {...props} isPopOver={isPopOver} />
              ),
            Footer,
            ErrorOverlay,
            LoadingOverlay,
            NoResultsOverlay: NoRowsOverlay,
            NoRowsOverlay,
          }}
          initialState={{
            ...initialState,
            columns: { columnVisibilityModel: {} },
          }}
          onColumnWidthChange={handleColumnSizeChange}
          onSelectionModelChange={(rows) => {
            if (dataGridProps.checkboxSelection) {
              setSelectRows(rows);

              if (updateSelectedRowsHandler) {
                updateSelectedRowsHandler(rows);
              }
            }
          }}
          isGroupExpandedByDefault={() => {
            return false;
          }}
          disableColumnFilter={disableColumnFilter}
        />
      </ThemeProvider>
    </Box>
  );
};

export default memo(Table);
