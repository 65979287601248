import { memo, useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  TextField,
} from "@mui/material";
import {
  GeofenceShape,
  useGetGeofencesTagsQuery,
} from "../../../../../../graphql/operations";
import ToggleButtons, {
  ToggleButtonOption,
} from "../../../../../../shared/components/ToggleButtons";
import { useAssetTypes } from "../../../../../../shared/hooks/useAssetTypes";
import { useAvailableOrgs } from "../../../../../../shared/hooks/useAvailableOrgs";
import {
  useOrgsOptions,
  useGeofenceTypeOptions,
  useGeofenceTagOptions,
  useGeofenceNameOptions,
} from "../../../../TableView/hooks";
import {
  GeofenceAssetsFilter,
  GeofenceShapesFilter,
  useAssetsDataContext,
} from "../../../AssetsDataContext";

export type SelectedValueType =
  | ToggleButtonOption["value"]
  | Array<ToggleButtonOption["value"]>
  | undefined;

const GeofenceFiltersTab = memo(() => {
  const {
    currentGeofenceFilter: {
      names,
      orgIds,
      shapes,
      typeIds,
      tags,
      underMin,
      overMax,
      hasAssets,
    },
    onChangeGeofenceFilters,
    geofenceTypes,
    allGeofences,
  } = useAssetsDataContext();

  const GEOFENCE_SHAPE_OPTIONS: ToggleButtonOption[] = [
    {
      value: GeofenceShape.Polygon,
      label: "Polygon",
    },
    {
      value: GeofenceShape.Circle,
      label: "Radius",
    },
  ];

  const geofenceNameOpts = useGeofenceNameOptions(allGeofences);
  const availableOrgs = useAvailableOrgs();
  const orgOptions = useOrgsOptions(availableOrgs);

  const geofenceTypesOpts = useGeofenceTypeOptions(geofenceTypes);

  const { assetTypeOptionsState: assetTypeOpts } = useAssetTypes();

  const { data: geofenceTagsData } = useGetGeofencesTagsQuery({ input: {} });
  const geofenceTags = useMemo(
    () => geofenceTagsData?.getGeofencesTags ?? [],
    [geofenceTagsData]
  );

  const geofenceTagsOpts = useGeofenceTagOptions(geofenceTags);

  const HAS_ASSETS_OPTIONS: ToggleButtonOption[] = [
    {
      value: true,
      label: "Has assets present",
    },
    {
      value: false,
      label: "Has no assets present",
    },
  ];

  const onChangeShape = useCallback(
    (value: SelectedValueType) =>
      onChangeGeofenceFilters({
        shapes: (value ?? "all") as GeofenceShapesFilter,
      }),
    [onChangeGeofenceFilters]
  );

  const onChangeAssets = useCallback(
    (value: SelectedValueType) =>
      onChangeGeofenceFilters({
        hasAssets: (value ?? "all") as GeofenceAssetsFilter,
      }),
    [onChangeGeofenceFilters]
  );

  return (
    <Box sx={{ maxHeight: `70vh`, overflow: "auto" }}>
      <Accordion defaultExpanded>
        <AccordionSummary>Name</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__name"
            limitTags={3}
            multiple
            options={geofenceNameOpts}
            size="small"
            value={names}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => onChangeGeofenceFilters({ names: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Geofence Names"
                data-testid="geofence-names-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      {/* Was commented in scope of PRJIND-8424 */}
      {/* TODO: remove this code after complete implementation of the Global Filtering feature */}
      {/* <Accordion defaultExpanded>
        <AccordionSummary>Organization</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__org-ids"
            limitTags={3}
            multiple
            options={orgOptions}
            size="small"
            value={orgIds}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => onChangeGeofenceFilters({ orgIds: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Organizations"
                data-testid="geofence-orgs-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion> */}

      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Shape</AccordionSummary>
        <AccordionDetails>
          <ToggleButtons
            id="geofence-filter__shapes"
            options={GEOFENCE_SHAPE_OPTIONS}
            value={shapes}
            onChange={onChangeShape}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Type</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__type-ids"
            limitTags={3}
            multiple
            options={geofenceTypesOpts}
            size="small"
            value={typeIds}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => onChangeGeofenceFilters({ typeIds: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Geofence Type"
                data-testid="geofence-type-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>Under Min</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__under-min"
            limitTags={3}
            multiple
            options={assetTypeOpts}
            size="small"
            value={underMin}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) =>
              onChangeGeofenceFilters({ underMin: value })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Assets"
                data-testid="geofence-under-min-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>Over Max</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__over-max"
            limitTags={3}
            multiple
            options={assetTypeOpts}
            size="small"
            value={overMax}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => onChangeGeofenceFilters({ overMax: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Assets"
                data-testid="geofence-over-max-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Tags</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__geofence-tags"
            limitTags={3}
            multiple
            options={geofenceTagsOpts}
            size="small"
            value={tags}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => onChangeGeofenceFilters({ tags: value })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Tags"
                data-testid="geofence-tags-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Assets</AccordionSummary>
        <AccordionDetails>
          <ToggleButtons
            id="geofence-filter__has-assets"
            options={HAS_ASSETS_OPTIONS}
            value={hasAssets}
            onChange={onChangeAssets}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default memo(GeofenceFiltersTab);
