export const mapServerErrorCodeToHumanReadableMessage = (message: string) => {
  const ERROR_MESSAGES_MAP: { [key: string]: string } = {
    ORGANIZATIONS_MISMATCH:
      "All uploaded assets must be associated with the same organization",
    MISSING_CUSTOMER_ORGANIZATION: "Missing provided organization name",
    ERROR_CREATING_ASSET: "Error creating asset",
    ASSET_VALIDATION_ERROR: "Asset validation error",
    ERROR_FINDING_ASSETS: "Error finding assets",
    ERROR_UPDATING_ASSET: "Error updating asset",
    ASSET_WITH_GIVEN_ASSET_ID_ALREADY_EXISTS:
      "Asset with given asset ID already exists",
    ERROR_MISSING_ASSET_WITH_GIVEN_ID: "Missing asset with given ID",
    ERROR_DELETING_ASSET: "Error deleting asset",
    ERROR_FINDING_ASSET: "Error finding asset",
    ERROR_CREATE_OR_UPDATE_ASSET: "Error updating asset",
    ERROR_INCREMENT_ORG_ASSETS_COUNT:
      "Error incrementing organization assets count",
    ERROR_LINK_DEVICE_TO_ASSET: "Error linking device to asset",
    ERROR_UNLINK_DEVICE_FROM_ASSET: "Error unlinking device from asset",
    ERROR_UPDATE_ASSET_ORG: "Error updating asset organization",
    ERROR_GET_DEVICE_BY_IMEI: "Error searching device by IMEI",
    ERROR_LINK_ASSET_TO_DEVICE: "Error linking asset to device",
    ERROR_UNLINK_ASSET_FROM_DEVICE: "Error unlinking asset from device",
    ACCESS_NOT_GRANTED_TO_THE_PROVIDED_CUSTOMER_ORG: "Access not granted",
    ERROR_GETTING_IDENTITY: "Error getting identity",
    ERROR_INCREMENT_ORG_DEVICES_COUNT:
      "Error incrementing organization devices count",
    ERROR_UPDATE_DEVICE_ORG: "Error updating device organization",
    ERROR_PUSH_DEVICE_EVENT: "Error pushing device event",
    ERROR_GET_ORG_BY_ID: "Error getting organization by ID",
    ERROR_CREATING_GEOFENCE_TESSELLATE:
      "Unable to Tessellate shape. Possible malformed shape detected.",
    ERROR_ARCHIVING_CONFIGURATION_SET: "Something went wrong.",
    ERROR_ARCHIVING_CONFIGURATION_SET_IS_ACTIVE:
      "Configuration is used in geofence.",
    ERROR_GEOFENCE_CONFIGURATION_SET_DUPLICATE:
      "This geofence type name is already used, please use another one.",
    ERROR_ARCHIVING_CONFIGURATION_SET_IS_PARENT:
      "Configuration is a parent of another geofence type",
    ERROR_FINDING_ASSET_BY_IMEI:
      "The device with the provided IMEI is not associated with an asset",
    ERROR_FINDING_CONFIGURATION_SET: "The configuration set does not exist",
    ERROR_UPDATING_CONFIGURATION_SET:
      "An error occurred while trying to updating the configuration set",
    ERROR_TABLE_VIEW_IS_USED_BY_OTHER_USERS:
      "The table view is used by other users.",
    ERROR_TABLE_VIEW_ALREADY_EXISTS: "Table view already exists",
    ERROR_DELETING_DEFAULT_DASHBOARD:
      "Dashboard marked as default for organization cannot be deleted.",
    ERROR_DELETING_FAVORITE_DASHBOARD:
      "This dashboard is in use and cannot be deleted.",
  };

  return ERROR_MESSAGES_MAP[message] ?? message;
};
