import { useMemo } from "react";
import {
  GetTpmsSensorHistoricalDataQuery,
  PreparedStatisticsForSensor,
} from "../../../../../../../../../graphql/operations";
import { formatDate } from "../../../../../../../../../utils";
import { AXLE_TO_KEY, Axle } from "../../components/tpms/TiresChartBody";
import { TiresChartType } from "../../components/tpms/TiresChartTooltip";

export function useTrendData(
  data: GetTpmsSensorHistoricalDataQuery | undefined,
  axle: Axle,
  type: TiresChartType
) {
  return useMemo(() => {
    const eventHistoryData = data?.searchHistoricalEventHistory?.data;
    if (!eventHistoryData) return [];
    // loop over event history data
    return eventHistoryData.map((history) => {
      const tpms = history?.tpms;

      const chartTypeKey = type.toLowerCase() as keyof typeof tpms;

      // get pressure values
      const sensorData = tpms && tpms[chartTypeKey];

      // construct initial trend object to return in case values are not present
      const trend: {
        date?: string;
        tooltipDate?: string;
      } = {};

      if (!sensorData || !history) return trend;

      // transform axle to key, e.g. Axle.ONE to key=1, Axle.TWO to key=2
      const axleKey = AXLE_TO_KEY[axle];

      // loop over keys in pressure object
      // get all tires which are present on axle based on key
      const tires = Object.keys(sensorData).filter((tire) =>
        // if tire ends with axle key number then return it
        tire.endsWith(axleKey.toString())
      );

      // compute dates based on timezone
      trend.date = formatDate(history?.date, "MM/dd");
      trend.tooltipDate = formatDate(history?.date, "dd/MM/yyyy");

      // loop over each tire present in axle
      tires.forEach((tire) => {
        // get tire key, e.g. primaryCurbside, innerCurbside, etc.
        const tireKey = tire.slice(0, -1);

        // get statistics for tire
        const tireStatistics = sensorData[
          tire as keyof typeof sensorData
        ] as PreparedStatisticsForSensor;

        if (tireStatistics) {
          // assign statistics to the trend object specifying tire key without an axle
          Object.assign(trend, {
            [tireKey]: tireStatistics.median,
            [`${tireKey}-min`]: tireStatistics.min,
            [`${tireKey}-max`]: tireStatistics.max,
          });
        }
      });

      return trend;
    });
  }, [axle, data?.searchHistoricalEventHistory?.data, type]);
}
