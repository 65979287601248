import { memo, useCallback, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AuthorizedComponent } from "../../../shared/components/AuthorizedComponent/AuthorizedComponent";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";
import { useAssetsDataContext } from "../shared/AssetsDataContext";
import { AddAssetDrawer } from "./components";
import { AssetDashboard } from "./components/AssetDashboard";
import { AssetsTable } from "./components/AssetsTable";
import { useAssetManagementTheme } from "./hooks/useAssetManagementTheme";

const AssetsTableView = memo(() => {
  const assetManagementTheme = useAssetManagementTheme();
  const { gridApiRef, filters } = useAssetsDataContext();
  const assetId = Object.values(useParams())[0];
  const [shouldShowTable, setShouldShowTable] = useState(!assetId);

  //<editor-fold desc="Queries">
  const { setSelectedAssetId } = useAssetsDataContext();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateBack = useCallback(() => {
    setSelectedAssetId(null);
    setShouldShowTable(true);
    const queryParams = new URLSearchParams(location.search);
    navigate(`${NavigationRoutes.AssetTable}?${queryParams}`);
  }, [location.search, navigate, setSelectedAssetId]);
  //</editor-fold>

  useEffect(() => {
    // Check if the current path is assets/table/:id and set the selected asset id
    if (location.pathname.includes("/assets/table/")) {
      const assetId = location.pathname.split("/").at(-1);
      if (assetId) {
        setSelectedAssetId(assetId);
      }
    }
    setShouldShowTable(location.pathname === NavigationRoutes.AssetTable);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return gridApiRef ? (
    <ThemeProvider theme={assetManagementTheme}>
      {shouldShowTable ? (
        <AssetsTable />
      ) : (
        <Routes>
          <Route
            path="create"
            element={
              <AuthorizedComponent actionPath="assetManagement.addAsset">
                <AddAssetDrawer open={true} setOpen={handleNavigateBack} />
              </AuthorizedComponent>
            }
          />
          <Route
            path=":id"
            element={
              <AssetDashboard
                onClose={handleNavigateBack}
                isAssetRefetching={false}
                backButtonText="Back"
              />
            }
          />
        </Routes>
      )}
    </ThemeProvider>
  ) : null;
});

export default AssetsTableView;
