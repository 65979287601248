import { useMemo } from "react";
import { QueryObserverResult } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import {
  AssetInstallEventDataResponse,
  AssetTransferEventDataResponse,
  ReportType,
  useFindAssetInstallHistoryQuery,
  useFindAssetTransferHistoryQuery,
  useFindAssetInventoryQuery,
  AssetReportRowDataResponse,
  AccountsReportDataResponse,
  useFindAssetYardQuery,
  useFindOutsideGeofenceQuery,
  useGetAccountsReportDataQuery,
  useFindMediaActivitiesQuery,
  Pagination,
  AssetReportDataRow,
  useFindMileageQuery,
  useFindSensorsQuery,
  MileageReportDataResponse,
  MediaActivitiesReportDataResponse,
  useGetDwellHierarchyTableDataQuery,
  GetDwellHierarchyTableDataResponse,
  GetTableDataInput,
  TableFilterLinkOperator,
  useGetReportAlertHistoryTableDataQuery,
  ReportAlertHistoryTableDataResponse,
  SensorsReportDataResponse,
} from "../../../graphql/operations";

interface IUseReportHistoryApi {
  orgId?: string;
  reportType: string;
  reportQueryParameters: Record<string, any>;
  trigger?: string;
  queryInput: GetTableDataInput;
}

interface IUseReportHistoryApiResult {
  data:
    | AssetTransferEventDataResponse
    | AssetInstallEventDataResponse
    | AssetReportRowDataResponse
    | AccountsReportDataResponse
    | ReportAlertHistoryTableDataResponse
    | GetDwellHierarchyTableDataResponse
    | MediaActivitiesReportDataResponse
    | MileageReportDataResponse
    | SensorsReportDataResponse;
  isLoading: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  refetch: () => Promise<QueryObserverResult>;
}

export const useReportHistoryApi = (
  {
    orgId,
    reportType,
    reportQueryParameters,
    trigger = "",
    queryInput,
  }: IUseReportHistoryApi,
  shouldFetch = true
): IUseReportHistoryApiResult => {
  const defaultReportQueryParameters = {
    orgIds: reportQueryParameters.orgIds ?? null,
    searchText: reportQueryParameters.searchText,
    filterList: reportQueryParameters.filterList,
    sorting: reportQueryParameters.sorting,
    pagination: reportQueryParameters.pagination,
  };

  const paramTableFilters = queryInput.tableFilters;

  const defaultReportTableDataInput = {
    searchText: queryInput.searchText,
    tableFilters: {
      linkOperator:
        paramTableFilters?.linkOperator ?? TableFilterLinkOperator.And,
      filters: paramTableFilters?.filters ?? [],
    },
    sorting: queryInput.sorting,
    pagination: queryInput.pagination,
  };

  const defaultDateRangeQueryParameters = {
    startDate: reportQueryParameters.startDate ?? null,
    endDate: reportQueryParameters.endDate ?? null,
  };
  const defaultPaginationResponse: Pagination = useMemo(
    () => ({ total: 0, limit: 0, skip: 0 }),
    []
  );

  // should be enabled only for asset transfer report
  const {
    data: transferReportData,
    isLoading: isTransferReportLoading,
    isRefetching: isTransferReportRefetching,
    isSuccess: isTransferReportSuccess,
    refetch: refetchTransferReport,
  } = useFindAssetTransferHistoryQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetTransfer && !!orgId && shouldFetch,
    }
  );

  const {
    data: accountsReportData,
    isLoading: isAccountsReportLoading,
    isRefetching: isAccountsReportRefetching,
    isSuccess: isAccountsReportSuccess,
    refetch: refetchAccountsReport,
  } = useGetAccountsReportDataQuery(
    { input: defaultReportQueryParameters },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Accounts && !!orgId && shouldFetch,
    }
  );
  // should be enabled only for asset install report
  const {
    data: installReportData,
    isLoading: isInstallReportLoading,
    isRefetching: isInstallReportRefetching,
    isSuccess: isInstallReportSuccess,
    refetch: refetchInstallReport,
  } = useFindAssetInstallHistoryQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AssetInstall && !!orgId && shouldFetch,
    }
  );
  // should be enabled only for alert history report
  const {
    data: alertHistoryReportData,
    isLoading: isAlertHistoryReportLoading,
    isRefetching: isAlertHistoryReportRefetching,
    isSuccess: isAlertHistoryReportSuccess,
    refetch: refetchAlertHistoryReport,
  } = useGetReportAlertHistoryTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        ...defaultDateRangeQueryParameters,
        orgIds: reportQueryParameters.orgIds,
        jsonLogic: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AlertHistory && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getReportAlertHistoryTableData }) =>
        getReportAlertHistoryTableData,
    }
  );
  const {
    data: inventoryReportData,
    isLoading: isInventoryReportLoading,
    isRefetching: isInventoryReportRefetching,
    isSuccess: isInventoryReportSuccess,
    refetch: refetchInventoryReport,
  } = useFindAssetInventoryQuery(
    {
      input: { ...defaultReportQueryParameters, query: trigger },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.AssetInventory && !!orgId && shouldFetch,
    }
  );
  const {
    data: yardReportData,
    isLoading: yardReportLoading,
    isRefetching: yardReportRefetching,
    isSuccess: yardReportSuccess,
    refetch: refetchYardReport,
  } = useFindAssetYardQuery(
    {
      input: { ...defaultReportQueryParameters, query: trigger },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.AssetYard && !!orgId && shouldFetch,
    }
  );

  const {
    data: outsideGeofenceData,
    isLoading: outsideGeofenceReportLoading,
    isRefetching: outsideGeofenceReportRefetching,
    isSuccess: outsideGeofenceReportSuccess,
    refetch: outsideGeofenceYardReport,
  } = useFindOutsideGeofenceQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.OutsideGeofence && !!orgId && shouldFetch,
    }
  );

  const {
    data: mileageReportData,
    isLoading: isMileageReportLoading,
    isRefetching: isMileageReportRefetching,
    isSuccess: isMileageReportSuccess,
    refetch: refetchMileageReport,
  } = useFindMileageQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Mileage && !!orgId && shouldFetch,
    }
  );

  const {
    data: sensorsReportData,
    isLoading: isSensorsReportLoading,
    isRefetching: isSensorsReportRefetching,
    isSuccess: isSensorsReportSuccess,
    refetch: refetchSensorsReport,
  } = useFindSensorsQuery(
    { input: { ...defaultReportQueryParameters, query: trigger } },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: reportType === ReportType.Sensors && !!orgId && shouldFetch,
    }
  );

  const {
    data: mediaActivitiesReportData,
    isLoading: isMediaActivitiesReportLoading,
    isRefetching: isMediaActivitiesReportRefetching,
    isSuccess: isMediaActivitiesReportSuccess,
    refetch: refetchMediaActivitiesReport,
  } = useFindMediaActivitiesQuery(
    {
      input: {
        ...defaultReportQueryParameters,
        ...defaultDateRangeQueryParameters,
        query: trigger,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.MediaActivities && !!orgId && shouldFetch,
    }
  );

  const {
    data: dwellHierarchyReportData,
    isLoading: isDwellHierarchyReportLoading,
    isRefetching: isDwellHierarchyReportRefetching,
    isSuccess: isDwellHierarchyReportSuccess,
    refetch: refetchDwellHierarchyReport,
  } = useGetDwellHierarchyTableDataQuery(
    {
      input: {
        ...defaultReportTableDataInput,
        jsonLogic: trigger,
        orgIds: reportQueryParameters.orgIds,
      },
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled:
        reportType === ReportType.DwellHierarchy && !!orgId && shouldFetch,
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getDwellHierarchyTableData }) => getDwellHierarchyTableData,
    }
  );

  const transferHistory = useMemo(
    () => ({
      data:
        transferReportData?.findAssetTransferHistory?.data?.map((event) => ({
          ...event,
          _id: `${event?.assetName}-${event?.date}`,
        })) ?? [],
      pagination:
        transferReportData?.findAssetTransferHistory?.pagination ??
        defaultPaginationResponse,
    }),
    [transferReportData?.findAssetTransferHistory, defaultPaginationResponse]
  );
  const installHistory = useMemo(
    () => ({
      data: installReportData?.findAssetInstallHistory?.data ?? [],
      pagination:
        installReportData?.findAssetInstallHistory?.pagination ??
        defaultPaginationResponse,
    }),
    [installReportData?.findAssetInstallHistory, defaultPaginationResponse]
  );
  const alertHistoryReportHistory = useMemo(
    () => ({
      data: alertHistoryReportData?.data ?? [],
      pagination:
        alertHistoryReportData?.pagination ?? defaultPaginationResponse,
    }),
    [alertHistoryReportData, defaultPaginationResponse]
  );
  const assetInventory = useMemo(() => {
    const data: AssetReportDataRow[] = [];
    inventoryReportData?.findAssetInventory.data.forEach((assetInventory) => {
      data.push({
        ...assetInventory,
        device_associated: isEmpty(assetInventory?.device.imei) ? "No" : "Yes",
      } as AssetReportDataRow);
    });

    return {
      data,
      pagination:
        inventoryReportData?.findAssetInventory?.pagination ??
        defaultPaginationResponse,
    };
  }, [inventoryReportData?.findAssetInventory, defaultPaginationResponse]);
  const assetYard = useMemo(
    () => ({
      data:
        yardReportData?.findAssetYard?.data?.map((assetYard) => {
          return {
            ...assetYard,
            device_associated: isEmpty(assetYard?.device.imei) ? "No" : "Yes",
          };
        }) ?? [],
      pagination:
        yardReportData?.findAssetYard?.pagination ?? defaultPaginationResponse,
    }),
    [yardReportData?.findAssetYard, defaultPaginationResponse]
  );

  const outsideGeofenceAssets = useMemo(
    () => ({
      data:
        outsideGeofenceData?.findOutsideGeofence?.data?.map(
          (outsideGeofenceAssets) => {
            return {
              ...outsideGeofenceAssets,
              device_associated: isEmpty(outsideGeofenceAssets?.device.imei)
                ? "No"
                : "Yes",
            };
          }
        ) ?? [],
      pagination:
        outsideGeofenceData?.findOutsideGeofence?.pagination ??
        defaultPaginationResponse,
    }),
    [outsideGeofenceData?.findOutsideGeofence, defaultPaginationResponse]
  );

  const mileageReportHistory = useMemo(
    () => ({
      data: mileageReportData?.findMileage?.data ?? [],
      pagination:
        mileageReportData?.findMileage?.pagination ?? defaultPaginationResponse,
    }),
    [mileageReportData?.findMileage, defaultPaginationResponse]
  );

  const sensorsReportHistory = useMemo(
    () => ({
      data: sensorsReportData?.findSensors?.data ?? [],
      pagination:
        sensorsReportData?.findSensors?.pagination ?? defaultPaginationResponse,
    }),
    [sensorsReportData?.findSensors, defaultPaginationResponse]
  );

  const mediaActivitiesReportHistory = useMemo(
    () => ({
      data: mediaActivitiesReportData?.findMediaActivities?.data ?? [],
      pagination:
        mediaActivitiesReportData?.findMediaActivities?.pagination ??
        defaultPaginationResponse,
    }),
    [mediaActivitiesReportData?.findMediaActivities, defaultPaginationResponse]
  );

  const accountsReport = useMemo(
    () => ({
      data: accountsReportData?.getAccountsReportData?.data ?? [],
      pagination:
        accountsReportData?.getAccountsReportData?.pagination ??
        defaultPaginationResponse,
    }),
    [accountsReportData?.getAccountsReportData, defaultPaginationResponse]
  );

  const dwellHierarchyReportHistory = useMemo(
    () => ({
      data: dwellHierarchyReportData?.data ?? [],
      pagination:
        dwellHierarchyReportData?.pagination ?? defaultPaginationResponse,
    }),
    [dwellHierarchyReportData, defaultPaginationResponse]
  );

  switch (reportType) {
    case ReportType.AssetTransfer: {
      return {
        data: transferHistory,
        isLoading: isTransferReportLoading,
        isRefetching: isTransferReportRefetching,
        isSuccess: isTransferReportSuccess,
        refetch: refetchTransferReport,
      };
    }
    case ReportType.AssetInstall: {
      return {
        data: installHistory,
        isLoading: isInstallReportLoading,
        isRefetching: isInstallReportRefetching,
        isSuccess: isInstallReportSuccess,
        refetch: refetchInstallReport,
      };
    }
    case ReportType.AssetInventory: {
      return {
        data: assetInventory,
        isLoading: isInventoryReportLoading,
        isRefetching: isInventoryReportRefetching,
        isSuccess: isInventoryReportSuccess,
        refetch: refetchInventoryReport,
      };
    }
    case ReportType.AlertHistory: {
      return {
        data: alertHistoryReportHistory,
        isLoading: isAlertHistoryReportLoading,
        isRefetching: isAlertHistoryReportRefetching,
        isSuccess: isAlertHistoryReportSuccess,
        refetch: refetchAlertHistoryReport,
      };
    }
    case ReportType.Mileage: {
      return {
        data: mileageReportHistory,
        isLoading: isMileageReportLoading,
        isRefetching: isMileageReportRefetching,
        isSuccess: isMileageReportSuccess,
        refetch: refetchMileageReport,
      };
    }
    case ReportType.Sensors: {
      return {
        data: sensorsReportHistory,
        isLoading: isSensorsReportLoading,
        isRefetching: isSensorsReportRefetching,
        isSuccess: isSensorsReportSuccess,
        refetch: refetchSensorsReport,
      };
    }
    case ReportType.AssetYard: {
      return {
        data: assetYard,
        isLoading: yardReportLoading,
        isRefetching: yardReportRefetching,
        isSuccess: yardReportSuccess,
        refetch: refetchYardReport,
      };
    }
    case ReportType.OutsideGeofence: {
      return {
        data: outsideGeofenceAssets,
        isLoading: outsideGeofenceReportLoading,
        isRefetching: outsideGeofenceReportRefetching,
        isSuccess: outsideGeofenceReportSuccess,
        refetch: outsideGeofenceYardReport,
      };
    }
    case ReportType.Accounts: {
      return {
        data: accountsReport,
        isLoading: isAccountsReportLoading,
        isRefetching: isAccountsReportRefetching,
        isSuccess: isAccountsReportSuccess,
        refetch: refetchAccountsReport,
      };
    }
    case ReportType.DwellHierarchy: {
      return {
        data: dwellHierarchyReportHistory,
        isLoading: isDwellHierarchyReportLoading,
        isRefetching: isDwellHierarchyReportRefetching,
        isSuccess: isDwellHierarchyReportSuccess,
        refetch: refetchDwellHierarchyReport,
      };
    }
    case ReportType.MediaActivities: {
      return {
        data: mediaActivitiesReportHistory,
        isLoading: isMediaActivitiesReportLoading,
        isRefetching: isMediaActivitiesReportRefetching,
        isSuccess: isMediaActivitiesReportSuccess,
        refetch: refetchMediaActivitiesReport,
      };
    }

    default: {
      return {
        data: { data: [], pagination: defaultPaginationResponse },
        isLoading: false,
        isRefetching: false,
        isSuccess: false,
        refetch: () => Promise.resolve({} as QueryObserverResult),
      };
    }
  }
};
