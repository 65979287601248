import { PropsWithChildren } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { ColorsPalette } from "../../../design-system/colors-palette";

export interface BaseBarChartProps<T> extends PropsWithChildren {
  data: T[];
  xAsisKey: string;
  xAxisTickFormatter?: (tickItem: any) => string;
  yAxisLabel?: string;
  width?: string | number;
  height?: string | number;
  chartStyle?: { [key: string]: string | number };
  maxBarSize?: number;
  yAxisLabelColor?: string;
  yAxisLabelPosition?: string;
  yAxisDomain?: AxisDomain;
}

export const chartTickStyle = {
  fontSize: 12,
  fill: "var(--chart-tick-style)",
  fontWeight: 500,
};

const BaseBarChart = <T,>(props: BaseBarChartProps<T>) => {
  const {
    data,
    xAsisKey,
    xAxisTickFormatter,
    yAxisLabel,
    chartStyle,
    width = "100%",
    height = 500,
    maxBarSize,
    children,
    yAxisLabelColor,
    yAxisLabelPosition,
    yAxisDomain,
  } = props;

  const tickStyles = chartStyle ? chartStyle : chartTickStyle;

  return (
    <>
      {Boolean(data?.length) && (
        <ResponsiveContainer
          width={width}
          height={height}
          className="responsiveContainer"
        >
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            maxBarSize={maxBarSize}
          >
            <CartesianGrid stroke={ColorsPalette.Concrete} />

            <XAxis
              dataKey={xAsisKey}
              tick={tickStyles}
              tickLine={false}
              tickFormatter={xAxisTickFormatter}
              interval={"preserveStart"}
            />

            <YAxis
              label={{
                value: yAxisLabel,
                angle: -90,
                dx: -10,
                ...(yAxisLabelPosition && { position: yAxisLabelPosition }),
                style: {
                  textAnchor: "middle",
                  fill: yAxisLabelColor,
                  fontSize: "0.625rem",
                },
              }}
              domain={yAxisDomain}
              tick={tickStyles}
            />

            {children}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default BaseBarChart;
