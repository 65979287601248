import { FieldValues, UseFormReturn } from "react-hook-form";
import { AlertType, AlertTypeV2 } from "../../../graphql/operations";
import { useAlertCargoForm } from "./useAlertCargoForm";
import { useAlertCustomForm } from "./useAlertCustomForm";
import { useAlertDwellForm } from "./useAlertDwellForm";
import { useAlertGeofenceForm } from "./useAlertGeofenceForm";
import { useAlertMovingWithoutPrimOrSecPowerForm } from "./useAlertMovingWithoutPrimOrSecPowerForm";
import { useAlertSpeedForm } from "./useAlertSpeedForm";
import { useAlertWheelEndForm } from "./useAlertWheelEndForm";

type FormReturnType = {
  schema: any;
  form: UseFormReturn<FieldValues, any, undefined>;
  getValues: () => any;
};

export const useCurrentForm = (
  type: AlertTypeV2,
  initialValues: any
): FormReturnType => {
  const {
    form: alertGeofenceForm,
    schema: alertGeofenceSchema,
    getValues: alertGeofenceGetValues,
  } = useAlertGeofenceForm(initialValues);
  const {
    form: alertCargoForm,
    schema: alertCargoSchema,
    getValues: alertCargoGetValues,
  } = useAlertCargoForm(initialValues);
  const {
    form: alertDwellForm,
    schema: alertDwellSchema,
    getValues: alertDwellGetValues,
  } = useAlertDwellForm(initialValues);
  const {
    form: alertCustomForm,
    schema: alertCustomSchema,
    getValues: alertCustomGetValues,
  } = useAlertCustomForm(initialValues);
  const {
    form: alertMovingWithoutPrimOrSecPowerForm,
    schema: alertMovingWithoutPrimOrSecPowerSchema,
    getValues: alertMovingWithoutPrimOrSecPowerGetValues,
  } = useAlertMovingWithoutPrimOrSecPowerForm(initialValues);
  const {
    form: alertSpeedForm,
    schema: alertSpeedSchema,
    getValues: alertSpeedGetValues,
  } = useAlertSpeedForm(initialValues);
  const {
    form: alertWheelEndForm,
    schema: alertWheelEndSchema,
    getValues: alertWheelEndGetValues,
  } = useAlertWheelEndForm(initialValues);
  switch (type) {
    case AlertTypeV2.Geofence:
      return {
        schema: alertGeofenceSchema,
        form: alertGeofenceForm,
        getValues: alertGeofenceGetValues,
      };
    case AlertTypeV2.Cargo:
      return {
        schema: alertCargoSchema,
        form: alertCargoForm,
        getValues: alertCargoGetValues,
      };
    case AlertTypeV2.Dwell:
      return {
        schema: alertDwellSchema,
        form: alertDwellForm,
        getValues: alertDwellGetValues,
      };
    case AlertTypeV2.Custom:
      return {
        schema: alertCustomSchema,
        form: alertCustomForm,
        getValues: alertCustomGetValues,
      };
    case AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower:
      return {
        schema: alertMovingWithoutPrimOrSecPowerSchema,
        form: alertMovingWithoutPrimOrSecPowerForm,
        getValues: alertMovingWithoutPrimOrSecPowerGetValues,
      };
    case AlertTypeV2.Speeding:
      return {
        schema: alertSpeedSchema,
        form: alertSpeedForm,
        getValues: alertSpeedGetValues,
      };
    case AlertTypeV2.WheelEnd:
      return {
        schema: alertWheelEndSchema,
        form: alertWheelEndForm,
        getValues: alertWheelEndGetValues,
      };

    default:
      return {} as FormReturnType;
  }
};
