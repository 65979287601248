import { FC, useEffect, useState, useMemo, MutableRefObject } from "react";
import { Box } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  useGetConfigurationSetsQuery,
  ConfigurationSetType,
  TableViewType,
} from "../../../../../../graphql/operations";
import { getRows, Table } from "../../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import {
  productTableColumns,
  productTableKeysList,
} from "../../configurationsUtils";

export interface ProductsTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  orgId: string | undefined;
  onRowClick: (data: any) => void;
}

const ProductsTable: FC<ProductsTableProps> = ({
  apiRef,
  orgId,
  onRowClick,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const [rowsState, setRowsState] = useState<any[]>([]);

  const fileName: string = useExportedFileName("Products");

  const {
    data: dataConfigurations,
    isError: isErrorConfiguration,
    // using isFetching because for some reason isLoading stucks on 'true'
    isFetching: isFetchingConfiguration,
  } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? "",
        type: ConfigurationSetType.ProductCode,
      },
    },
    {
      enabled: Boolean(orgId),
    }
  );

  const dataParsed = useMemo(
    () =>
      dataConfigurations?.getConfigurationSets?.map((singleSet) => {
        const singleSetCopy = singleSet as any;
        const valuePropertyParsed =
          singleSet?.value && JSON.parse(singleSet?.value);
        return {
          _id: singleSet._id,
          productName: singleSetCopy.name,
          productCode: valuePropertyParsed.product_code,
          assetName: valuePropertyParsed.name,
          colorReflectsHealth: valuePropertyParsed.colorReflectsAsset,
          outerRingReflectsDwell: valuePropertyParsed.outerRingReflectsDwell,
          lostCommunicationTime: valuePropertyParsed.lostCommunicationTime,
          type: valuePropertyParsed.centerSensor
            ? valuePropertyParsed.centerSensor.type
            : "",
        };
      }),
    [dataConfigurations?.getConfigurationSets]
  );

  useEffect(() => {
    if (dataParsed) {
      setRowsState(
        getRows<typeof dataParsed>(
          dataParsed,
          productTableColumns,
          "",
          "",
          "_id"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParsed]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={TableViewType.ProductsConfigurations}
        showToolbar
        rows={rowsState}
        apiRef={apiRef}
        columns={productTableColumns}
        onRowClick={onRowClick}
        loading={isFetchingConfiguration}
        error={isErrorConfiguration}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={productTableKeysList}
        sortKeys={productTableKeysList}
        searchMinLength={appConfig.searchMinLength}
        tableName="products-config"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default ProductsTable;
