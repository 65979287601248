import { Dispatch } from "react";
import { QueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { Action } from "../../../../../context/commonTypes";

export const onSuccessDeletingGeofence = async (
  queryClient: QueryClient,
  dispatch: Dispatch<Action>,
  setOnSuccess: (flag: boolean) => void
) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: `Geofence successfully deleted`,
      text: "Success!",
      severity: "success",
    },
  });
  await queryClient.invalidateQueries(["findGeofences"], {
    refetchType: "all",
  });
  setOnSuccess(true);
};
