import { FC } from "react";
import CargoStateIcon from "./CargoStateIcon";
import DwellIcon from "./DwellIcon";
import { getIconType, IconType } from "./utils";

interface AssetIconProps {
  dwellingDays?: number;
  signal?: boolean;
  cargo_state?: string;
  svgProps?: Record<string, unknown>;
  trip_st?: string;
  customColor?: string;
}

const AssetIcon: FC<AssetIconProps> = ({
  dwellingDays = 0,
  signal = false,
  cargo_state,
  svgProps = {},
  trip_st,
  customColor,
}) => {
  const iconType = getIconType(trip_st, dwellingDays);
  const fill = customColor
    ? customColor
    : signal
    ? "var(--asset-status-icon-gps)"
    : "var(--asset-status-icon-no-gps)";

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="12" cy="12" r="6" fill={fill} />
      <CargoStateIcon cargoState={cargo_state} signal={signal} />
      <DwellIcon iconType={iconType} customColor={customColor} />
      {iconType.startsWith(IconType.MOVING) && (
        <circle
          cx="12"
          cy="12"
          fill="none"
          r="4"
          stroke="var(--asset-status-icon-moving)"
          strokeWidth="8"
        >
          <animate
            attributeName="r"
            from="5"
            to="10"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      )}
    </svg>
  );
};

export default AssetIcon;
