import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  AssetWithSensors,
  AssignOrgProfileToAssetsInput,
  LiteSentryGammaProfileSensorInput,
  LiteSentryGammaSensorConfigInput,
  SensorProfileType,
  SensorProfileConfigType,
  SetAssetSensorProfilesInput,
  useGetSensorProfileNamesWithConfigurationQuery,
  LiteSentryGammaThresholds,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import { liteSentryGammaDefaultValues } from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import LiteSentryGammaAccordion from "../../../Profiles/Drawers/LiteSentryGammaDrawer/components/LiteSentryGammaAccordion";
import LiteSentryGammaSliderContainer from "../../../Profiles/Drawers/LiteSentryGammaDrawer/components/LiteSentryGammaSliderContainer";
import { useLiteSentryGammaForm } from "../../../Profiles/Drawers/LiteSentryGammaDrawer/hooks/useLiteSentryGammaForm";
import { LiteSentryGammaFormValues } from "../../../Profiles/Drawers/LiteSentryGammaDrawer/types";
import {
  getBrakeCircuitConfigInput,
  parseLiteSentryGammaProfiles,
  getLeftTurnSignalCircuitConfigInput,
  getRightTurnSignalCircuitConfigInput,
  getMarkerCircuitConfigInput,
  prepareLiteSentryGammaProfileConfigInput,
  getLicenseCircuitConfigInput,
} from "../../../Profiles/Drawers/LiteSentryGammaDrawer/utils/utils";
import {
  LITE_SENTRY_GAMMA_FORM_FIELDS,
  SensorProfileDropdownItem,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import SensorProfileAssignmentForm from "../Components/SensorProfileAssignmentForm";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import { LiteSentryGammaDrawerSchema } from "./LiteSentryGammaDrawerSchema";
import { convertLiteSentryGammaConfigToSensorInput } from "./utils";

interface LiteSentryGammaSensorDrawerProps {
  currentOrgId?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
}

const LiteSentryGammaSensorDrawer: FC<LiteSentryGammaSensorDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const formTheme = useFormTheme();
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();
  let defaultLiteSentryGammaSensorProfile = undefined;
  let defaultTypeOfAssignment = "custom";
  const liteSentryGamma =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.liteSentryGamma;
  const liteSentryGammaThresholdsRules =
    selectedRecordsData?.[0]?.liteSentryGamma;
  const isPresetProfile = liteSentryGamma?.type !== SensorProfileType.Asset;

  if (isPresetProfile) {
    defaultTypeOfAssignment = "profile";
    defaultLiteSentryGammaSensorProfile = liteSentryGamma?.profileId;
  }

  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          mergeProfilesWithDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.LiteSentryGamma,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  const { liteSentryGammaThresholds, liteSentryGammaSetPoints } =
    parseLiteSentryGammaProfiles(
      liteSentryGammaThresholdsRules as LiteSentryGammaThresholds
    );
  const { form: liteSentryGammaForm } = useLiteSentryGammaForm(
    liteSentryGammaSetPoints
  );

  const {
    isValid: isLiteSentryGammaFormValid,
    isDirty: isLiteSentryGammaFormDirty,
  } = liteSentryGammaForm.formState;

  const {
    brakeCircuitThresholds,
    leftTurnSignalThresholds,
    rightTurnSignalThresholds,
    markerCircuitThresholds,
    licenseCircuitThresholds,
  } = liteSentryGammaThresholds;

  // brake circuit states
  const initialBluePowerPresentValues =
    brakeCircuitThresholds.bluePowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialBluePowerNotPresentValues =
    brakeCircuitThresholds.bluePowerNotPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialBrakeCircuitPrecheckValues =
    brakeCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [bluePowerPresentValues, setBluePowerPresentValues] = useState<
    number[]
  >(initialBluePowerPresentValues);

  const [bluePowerNotPresentValues, setBluePowerNotPresentValues] = useState<
    number[]
  >(initialBluePowerNotPresentValues);

  const [brakeCircuitPrecheckValues, setBrakeCircuitPrecheckValues] = useState<
    number[]
  >(initialBrakeCircuitPrecheckValues);

  // left turn signal states
  const initialLeftTurnSignalCircuitTractorPowerPresentValues =
    leftTurnSignalThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialLeftTurnSignalCircuitPrecheckValues =
    leftTurnSignalThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    leftTurnSignalCircuitTractorPowerPresentValues,
    setLeftTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialLeftTurnSignalCircuitTractorPowerPresentValues);

  const [
    leftTurnSignalCircuitPrecheckValues,
    setLeftTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(initialLeftTurnSignalCircuitPrecheckValues);

  // right turn signal states
  const initialRightTurnSignalCircuitTractorPowerPresentValues =
    rightTurnSignalThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialRightTurnSignalCircuitPrecheckValues =
    rightTurnSignalThresholds.precheckThresholds ??
    liteSentryGammaDefaultValues;

  const [
    rightTurnSignalCircuitTractorPowerPresentValues,
    setRightTurnSignalCircuitTractorPowerPresentValues,
  ] = useState<number[]>(
    initialRightTurnSignalCircuitTractorPowerPresentValues
  );

  const [
    rightTurnSignalCircuitPrecheckValues,
    setRightTurnSignalCircuitPrecheckValues,
  ] = useState<number[]>(initialRightTurnSignalCircuitPrecheckValues);

  // marker states
  const initialMarkerCircuitTractorPowerPresentValues =
    markerCircuitThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialMarkerCircuitPrecheckValues =
    markerCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    markerCircuitTractorPowerPresentValues,
    setMarkerCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialMarkerCircuitTractorPowerPresentValues);

  const [markerCircuitPrecheckValues, setMarkerCircuitPrecheckValues] =
    useState<number[]>(initialMarkerCircuitPrecheckValues);

  // license states
  const initialLicenseCircuitTractorPowerPresentValues =
    licenseCircuitThresholds.tractorPowerPresentThresholds ??
    liteSentryGammaDefaultValues;

  const initialLicenseCircuitPrecheckValues =
    licenseCircuitThresholds.precheckThresholds ?? liteSentryGammaDefaultValues;

  const [
    licenseCircuitTractorPowerPresentValues,
    setLicenseCircuitTractorPowerPresentValues,
  ] = useState<number[]>(initialLicenseCircuitTractorPowerPresentValues);

  const [licenseCircuitPrecheckValues, setLicenseCircuitPrecheckValues] =
    useState<number[]>(initialLicenseCircuitPrecheckValues);

  //set default values and resolver to the main form
  const mainForm = useForm({
    resolver: yupResolver(LiteSentryGammaDrawerSchema),
    defaultValues: {
      typeOfAssignment: defaultTypeOfAssignment,
      liteSentryGammaSensorProfile: defaultLiteSentryGammaSensorProfile,
    },
  });

  const { isValid: isMainFormValid, isDirty: isMainFormDirty } =
    mainForm.formState;

  //states
  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");

  //effects
  useEffect(() => {
    if (isPresetProfile) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [isPresetProfile]);

  //handlers
  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const selectedAssetsImeis: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.imei)
    .filter((imei): imei is string => !!imei);

  const selectedRowsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?._id)
    .filter((id): id is string => !!id);

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );

  const liteSentryGammaSensorProfiles: SensorProfileDropdownItem[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      value: item._id,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  LITE_SENTRY_GAMMA_FORM_FIELDS[1].options = sortSensorProfileNameDropdownItems(
    liteSentryGammaSensorProfiles
  );

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onSubmit = async () => {
    const isMainFormValid = await mainForm.trigger();
    if (!isMainFormValid) return;

    if (!isTypeOfAssignmentProfile) {
      const liteSentryGammaFormData: LiteSentryGammaFormValues =
        liteSentryGammaForm.getValues();

      const {
        brakeCircuitSetPoints,
        leftTurnSignalCircuitSetPoints,
        rightTurnSignalCircuitSetPoints,
        markerCircuitSetPoints,
        licenseCircuitSetPoints,
      } = liteSentryGammaFormData;

      const brakeCircuitConfigInput = getBrakeCircuitConfigInput(
        brakeCircuitSetPoints,
        {
          bluePowerPresentThresholds: bluePowerPresentValues,
          bluePowerNotPresentThresholds: bluePowerNotPresentValues,
          precheckThresholds: brakeCircuitPrecheckValues,
        }
      );

      const leftTurnSignalCircuitConfigInput =
        getLeftTurnSignalCircuitConfigInput(leftTurnSignalCircuitSetPoints, {
          tractorPowerPresentThresholds:
            leftTurnSignalCircuitTractorPowerPresentValues,
          precheckThresholds: leftTurnSignalCircuitPrecheckValues,
        });

      const rightTurnSignalCircuitConfigInput =
        getRightTurnSignalCircuitConfigInput(rightTurnSignalCircuitSetPoints, {
          tractorPowerPresentThresholds:
            rightTurnSignalCircuitTractorPowerPresentValues,
          precheckThresholds: rightTurnSignalCircuitPrecheckValues,
        });

      const markerCircuitConfigInput = getMarkerCircuitConfigInput(
        markerCircuitSetPoints,
        {
          tractorPowerPresentThresholds: markerCircuitTractorPowerPresentValues,
          precheckThresholds: markerCircuitPrecheckValues,
        }
      );

      const licenseCircuitConfigInput = getLicenseCircuitConfigInput(
        licenseCircuitSetPoints,
        {
          tractorPowerPresentThresholds:
            licenseCircuitTractorPowerPresentValues,
          precheckThresholds: licenseCircuitPrecheckValues,
        }
      );

      const liteSentryGammaConfig: LiteSentryGammaSensorConfigInput =
        prepareLiteSentryGammaProfileConfigInput(
          brakeCircuitConfigInput,
          leftTurnSignalCircuitConfigInput,
          rightTurnSignalCircuitConfigInput,
          markerCircuitConfigInput,
          licenseCircuitConfigInput
        );

      const liteSentryGammaConfigInput: LiteSentryGammaProfileSensorInput =
        convertLiteSentryGammaConfigToSensorInput(liteSentryGammaConfig);

      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          liteSentryGamma: liteSentryGammaConfigInput,
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfile = mainForm.getValues().liteSentryGammaSensorProfile;
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfile ?? "",
      };

      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  const handleAssignmentTypeInputChange = (e: {
    name: string;
    value: string;
    id: string;
  }) => {
    const id = e?.id ?? undefined;
    setIsTypeOfAssignmentProfile(id === "profile");
  };

  const handleSensorProfileInputChange = (
    selectedSensorProfile: SensorProfileDropdownItem
  ) => {
    if (!selectedSensorProfile) return;
    const config = selectedSensorProfile.configuration?.liteSentryGamma;
    const values = parseLiteSentryGammaProfiles(
      config as LiteSentryGammaThresholds
    );

    const thresholds = values.liteSentryGammaThresholds;

    setBluePowerPresentValues(
      thresholds.brakeCircuitThresholds.bluePowerPresentThresholds ??
        initialBluePowerPresentValues
    );
    setBluePowerNotPresentValues(
      thresholds.brakeCircuitThresholds.bluePowerNotPresentThresholds ??
        initialBluePowerNotPresentValues
    );
    setBrakeCircuitPrecheckValues(
      thresholds.brakeCircuitThresholds.precheckThresholds ??
        initialBrakeCircuitPrecheckValues
    );
    setLeftTurnSignalCircuitTractorPowerPresentValues(
      thresholds.leftTurnSignalThresholds.tractorPowerPresentThresholds ??
        initialLeftTurnSignalCircuitTractorPowerPresentValues
    );
    setLeftTurnSignalCircuitPrecheckValues(
      thresholds.leftTurnSignalThresholds.precheckThresholds ??
        initialLeftTurnSignalCircuitPrecheckValues
    );
    setRightTurnSignalCircuitTractorPowerPresentValues(
      thresholds.rightTurnSignalThresholds.tractorPowerPresentThresholds ??
        initialRightTurnSignalCircuitTractorPowerPresentValues
    );
    setRightTurnSignalCircuitPrecheckValues(
      thresholds.rightTurnSignalThresholds.precheckThresholds ??
        initialRightTurnSignalCircuitPrecheckValues
    );
    setMarkerCircuitTractorPowerPresentValues(
      thresholds.markerCircuitThresholds.tractorPowerPresentThresholds ??
        initialMarkerCircuitTractorPowerPresentValues
    );
    setMarkerCircuitPrecheckValues(
      thresholds.markerCircuitThresholds.precheckThresholds ??
        initialMarkerCircuitTractorPowerPresentValues
    );
    setLicenseCircuitTractorPowerPresentValues(
      thresholds.licenseCircuitThresholds.tractorPowerPresentThresholds ??
        initialLicenseCircuitTractorPowerPresentValues
    );
    setLicenseCircuitPrecheckValues(
      thresholds.licenseCircuitThresholds.precheckThresholds ??
        initialLicenseCircuitPrecheckValues
    );

    liteSentryGammaForm.reset(values.liteSentryGammaSetPoints);

    setSelectedProfileLabel(selectedSensorProfile?.label ?? "");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useMutateCustomProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useMutateProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  const areThresholdsEqual = isEqual(liteSentryGammaThresholds, {
    brakeCircuitThresholds: {
      bluePowerPresentThresholds: bluePowerPresentValues,
      bluePowerNotPresentThresholds: bluePowerNotPresentValues,
      precheckThresholds: brakeCircuitPrecheckValues,
    },
    leftTurnSignalThresholds: {
      tractorPowerPresentThresholds:
        leftTurnSignalCircuitTractorPowerPresentValues,
      precheckThresholds: leftTurnSignalCircuitPrecheckValues,
    },
    rightTurnSignalThresholds: {
      tractorPowerPresentThresholds:
        rightTurnSignalCircuitTractorPowerPresentValues,
      precheckThresholds: rightTurnSignalCircuitPrecheckValues,
    },
    markerCircuitThresholds: {
      tractorPowerPresentThresholds: markerCircuitTractorPowerPresentValues,
      precheckThresholds: markerCircuitPrecheckValues,
    },
    licenseCircuitThresholds: {
      tractorPowerPresentThresholds: licenseCircuitTractorPowerPresentValues,
      precheckThresholds: licenseCircuitPrecheckValues,
    },
  });

  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const isLiteSentryGammmaFormInvalid =
    !isTypeOfAssignmentProfile && !isLiteSentryGammaFormValid;
  const isInvalid =
    isLoading || !isMainFormValid || isLiteSentryGammmaFormInvalid;
  const isCustomPristine =
    !isPresetProfile &&
    !isTypeOfAssignmentProfile &&
    !isLiteSentryGammaFormDirty &&
    areThresholdsEqual;
  const isProfilePristine = isPresetProfile && !isMainFormDirty;
  const isDisabled = isInvalid || isCustomPristine || isProfilePristine;

  const additionalCheckboxComponent = (
    <ConfigurationDialogAdditionalElement
      checked={dontShowConfirmationDialog}
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Drawer
      testId="lights-sensor-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />
      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <Box className="h-full flex flex-col justify-between">
            <Box>
              {selectedRecordsData && (
                <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
              )}
              <Box className="pt-6 flex-auto">
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  className="justify-left px-6"
                ></Grid>
                <SensorProfileAssignmentForm
                  isTypeOfAssignmentProfile={isTypeOfAssignmentProfile}
                  form={mainForm}
                  SENSOR_FORM_FIELDS={LITE_SENTRY_GAMMA_FORM_FIELDS}
                  handleAssignmentTypeInputChange={
                    handleAssignmentTypeInputChange
                  }
                  handleSensorProfileInputChange={
                    handleSensorProfileInputChange
                  }
                  isProfileDataLoading={isSensorProfileNamesLoading}
                />
              </Box>
              <Grid
                container
                className="drawerSection"
                spacing={6}
                direction="column"
              >
                <LiteSentryGammaAccordion
                  accordionTitle="Brake Circuit Settings"
                  dataTestId="brake-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Blue Power Present"
                      control={liteSentryGammaForm.control}
                      values={bluePowerPresentValues}
                      name="brakeCircuitSetPoints.bluePowerPresentSetPoint"
                      isLoading={isLoading}
                      onChange={setBluePowerPresentValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Blue Power Not Present"
                      control={liteSentryGammaForm.control}
                      values={bluePowerNotPresentValues}
                      name="brakeCircuitSetPoints.bluePowerNotPresentSetPoint"
                      isLoading={isLoading}
                      onChange={setBluePowerNotPresentValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={brakeCircuitPrecheckValues}
                      name="brakeCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoading}
                      onChange={setBrakeCircuitPrecheckValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Left Turn Signal Circuit Settings"
                  dataTestId="left-turn-signal-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={leftTurnSignalCircuitTractorPowerPresentValues}
                      name="leftTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoading}
                      onChange={
                        setLeftTurnSignalCircuitTractorPowerPresentValues
                      }
                      disabled={isTypeOfAssignmentProfile}
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={leftTurnSignalCircuitPrecheckValues}
                      name="leftTurnSignalCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoading}
                      onChange={setLeftTurnSignalCircuitPrecheckValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Right Turn Signal Circuit Settings"
                  dataTestId="right-turn-signal-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={rightTurnSignalCircuitTractorPowerPresentValues}
                      name="rightTurnSignalCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoading}
                      onChange={
                        setRightTurnSignalCircuitTractorPowerPresentValues
                      }
                      disabled={isTypeOfAssignmentProfile}
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={rightTurnSignalCircuitPrecheckValues}
                      name="rightTurnSignalCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoading}
                      onChange={setRightTurnSignalCircuitPrecheckValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="Marker Circuit Settings"
                  dataTestId="marker-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={markerCircuitTractorPowerPresentValues}
                      name="markerCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoading}
                      onChange={setMarkerCircuitTractorPowerPresentValues}
                      disabled={isTypeOfAssignmentProfile}
                    />

                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={markerCircuitPrecheckValues}
                      name="markerCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoading}
                      onChange={setMarkerCircuitPrecheckValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                  </>
                </LiteSentryGammaAccordion>

                <LiteSentryGammaAccordion
                  accordionTitle="License Circuit Settings"
                  dataTestId="license-circuit-settings"
                >
                  <>
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Tractor Power Present"
                      control={liteSentryGammaForm.control}
                      values={licenseCircuitTractorPowerPresentValues}
                      name="licenseCircuitSetPoints.tractorPowerPresentSetPoint"
                      isLoading={isLoading}
                      onChange={setLicenseCircuitTractorPowerPresentValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                    <LiteSentryGammaSliderContainer
                      sectionTitle="Precheck"
                      control={liteSentryGammaForm.control}
                      values={licenseCircuitPrecheckValues}
                      name="licenseCircuitSetPoints.precheckSetPoint"
                      isLoading={isLoading}
                      onChange={setLicenseCircuitPrecheckValues}
                      disabled={isTypeOfAssignmentProfile}
                    />
                  </>
                </LiteSentryGammaAccordion>
              </Grid>
            </Box>

            <DrawerActions disabled={isLoading} onCancel={handleClose} />
          </Box>
        </ThemeProvider>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Apply to Selected"}
        disabled={isDisabled}
        testId="lights-sensors-drawer-submit-btn"
        submit={() => onSubmit()}
      />

      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={additionalCheckboxComponent}
        />
      )}
    </Drawer>
  );
};

export default LiteSentryGammaSensorDrawer;
