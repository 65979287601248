import { FC, HTMLAttributes } from "react";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { useSettingsTheme } from "../../../../../../AssetsView/MapView/hooks/useSettingsTheme";
import { AbsFaultCodeFieldName } from "../../../Tables/ABSFaultCodes/constants";
import { useFaultCodeTypeOptions } from "../hooks/useFaultCodeTypeOptions";
import { useManufacturerOptions } from "../hooks/useManufacturerOptions";
import { sortManufacturersOptions } from "../utils/utils";

interface ABSFaultCodesFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  form: any; // TODO: Fix type
}

export const ABSFaultCodesForm: FC<ABSFaultCodesFormProps> = ({ form }) => {
  const theme = useSettingsTheme();
  const { manufacturers } = useManufacturerOptions();
  const { faultCodeOptions } = useFaultCodeTypeOptions();
  manufacturers.sort(sortManufacturersOptions);
  return (
    <ThemeProvider theme={theme}>
      <div
        className="justify-center bg-background p-6"
        data-testid="abs-fault-codes-drawer-form"
      >
        <Box>
          <Grid container className="w-full justify-center bg-background">
            <Grid container className="w-full justify-center bg-background">
              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--manufacturer--container"
              >
                <AutocompleteElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.Manufacturer}
                  options={manufacturers}
                  label={"Manufacturer"}
                />
              </Grid>

              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--fault-code-type--container"
              >
                <AutocompleteElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.FaultCodeType}
                  options={faultCodeOptions}
                  label={"Fault Code Type"}
                  matchId
                />
              </Grid>

              <Grid container className="w-full justify-between" spacing={2}>
                <Grid
                  item
                  className="w-1/2 !mb-[48px]"
                  data-testid="abs-fault-codes-form--pgn--container"
                >
                  <TextFieldElement
                    type="number"
                    control={form.control}
                    name={AbsFaultCodeFieldName.Pgn}
                    label="PGN"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  className="w-1/2 !mb-[48px]"
                  data-testid="abs-fault-codes-form--dtc--container"
                >
                  <TextFieldElement
                    type="text"
                    control={form.control}
                    name={AbsFaultCodeFieldName.Dtc}
                    label="DTC"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container className="w-full justify-between" spacing={2}>
                <Grid
                  item
                  className="w-1/2 !mb-[48px]"
                  data-testid="abs-fault-codes-form--sid--container"
                >
                  <TextFieldElement
                    type="number"
                    control={form.control}
                    name={AbsFaultCodeFieldName.Sid}
                    label="SID"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  className="w-1/2 !mb-[48px]"
                  data-testid="abs-fault-codes-form--fmi--container"
                >
                  <TextFieldElement
                    type="number"
                    control={form.control}
                    name={AbsFaultCodeFieldName.Fmi}
                    label="FMI"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--problem-area--container"
              >
                <TextFieldElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.ProblemArea}
                  label="Problem Area"
                  fullWidth
                />
              </Grid>

              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--cause--container"
              >
                <TextFieldElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.Cause}
                  label="Cause"
                  fullWidth
                />
              </Grid>

              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--description--container"
              >
                <TextFieldElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.Description}
                  label="Description"
                  fullWidth
                />
              </Grid>

              <Grid
                item
                className="d-block w-full !mb-[48px]"
                data-testid="abs-fault-codes-form--pct-explains--container"
              >
                <TextFieldElement
                  control={form.control}
                  name={AbsFaultCodeFieldName.PctExplains}
                  label="PCT Explains"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};
