import { useCallback, useEffect, useMemo, useState } from "react";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useAppContext } from "../../../../context/AppContext";
import {
  AssetInput,
  AssetInputOs,
  GetAssetsClustersCoordinatesOsQuery,
  GetAssetsClustersCoordinatesQuery,
  useGetAssetsClustersCoordinatesOsQuery,
  useGetAssetsClustersCoordinatesQuery,
} from "../../../../graphql/operations";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import {
  AssetOrClusterListItem,
  extractAssetOrClusterCoordinatesData,
} from "../../../../utils/maps/core";
import { useFeatureFlag } from "../../../../utils/useFeatureFlag";
import { useAssetsDataContext } from "../../shared/AssetsDataContext";

export interface AssetsClustersCoordinatesDataHook {
  data: number[][];
  isFetching: boolean;
  refetch: () => void;
}

export type GetAssetsClustersCoordinatesQueryOptions = UseQueryOptions<
  GetAssetsClustersCoordinatesQuery,
  unknown,
  GetAssetsClustersCoordinatesQuery,
  QueryKey
>;

export type GetAssetsClustersCoordinatesOSQueryOptions = UseQueryOptions<
  GetAssetsClustersCoordinatesOsQuery,
  unknown,
  GetAssetsClustersCoordinatesOsQuery,
  QueryKey
>;

export const useAssetsClustersCoordinatesData = (
  queryInput: AssetInput | AssetInputOs,
  queryOptions:
    | GetAssetsClustersCoordinatesQueryOptions
    | GetAssetsClustersCoordinatesOSQueryOptions
): AssetsClustersCoordinatesDataHook => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const {
    setLastFetchedAllCoordinatesForOrg,
    setIsLoadingAllAssetsCoordinates,
    setAllAssetsCoordinates,
  } = useAssetsDataContext();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<number[][]>([]);

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const queryIsEnabled = useMemo(
    () => queryOptions?.enabled ?? true,
    [queryOptions]
  );

  const {
    data: dataDocumentDB,
    isFetching: isFetchingDocumentDB,
    refetch: refetchDocumentDB,
  } = useGetAssetsClustersCoordinatesQuery(
    {
      input: queryInput as AssetInput,
    },
    {
      ...queryOptions,
      enabled: queryIsEnabled && !fetchAssetsFromOpenSearchFeatureFlag,
    } as GetAssetsClustersCoordinatesQueryOptions
  );

  const {
    data: dataOpenSearch,
    isFetching: isFetchingOpenSearch,
    refetch: refetchOpenSearch,
  } = useGetAssetsClustersCoordinatesOsQuery(
    {
      input: queryInput as AssetInputOs,
    },
    {
      ...queryOptions,
      enabled: queryIsEnabled && fetchAssetsFromOpenSearchFeatureFlag,
    } as GetAssetsClustersCoordinatesOSQueryOptions
  );

  useEffect(() => {
    let isFetching = false;

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      isFetching = isFetchingOpenSearch;
    } else {
      isFetching = isFetchingDocumentDB;
    }

    // Ensure context state is up-to-date
    setIsLoadingAllAssetsCoordinates(isFetching);

    setIsFetching(isFetching);
  }, [
    isFetchingDocumentDB,
    isFetchingOpenSearch,
    fetchAssetsFromOpenSearchFeatureFlag,
    setIsLoadingAllAssetsCoordinates,
  ]);

  useEffect(() => {
    let data: number[][] = [];

    if (dataDocumentDB) {
      data = extractAssetOrClusterCoordinatesData(
        (dataDocumentDB?.getAssets?.clusters ?? []) as AssetOrClusterListItem[]
      );
    } else if (dataOpenSearch) {
      data = extractAssetOrClusterCoordinatesData(
        (dataOpenSearch?.getAssetsOS?.clusters ??
          []) as AssetOrClusterListItem[]
      );
    }

    // Ensure context state is up-to-date
    setAllAssetsCoordinates(data);
    setLastFetchedAllCoordinatesForOrg(selectedOrganization?.value);

    setData(data);
  }, [
    dataDocumentDB,
    dataOpenSearch,
    selectedOrganization?.value,
    setAllAssetsCoordinates,
    setLastFetchedAllCoordinatesForOrg,
  ]);

  const refetch = useCallback(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) refetchOpenSearch();
    else refetchDocumentDB();
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    refetchDocumentDB,
    refetchOpenSearch,
  ]);

  return {
    data,
    isFetching,
    refetch,
  };
};
