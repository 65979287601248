import {
  OrderDirection,
  Report,
  ReportStatus,
  ReportType,
} from "../../../../graphql/operations";
import { getColumns as getAccountsTableColumns } from "../../AssetReport/components/AccountsTable/columns";
import { getColumns as getAlertHistoryTableColumns } from "../../AssetReport/components/AlertHistoryTable/columns";
import { getColumns as getAssetInstallTableColumns } from "../../AssetReport/components/AssetInstallTable/columns";
import { getColumns as getAssetInventoryTableColumns } from "../../AssetReport/components/AssetInventoryTable/columns";
import { getColumns as getAssetTransferTableColumns } from "../../AssetReport/components/AssetTransferTable/columns";
import { getColumns as getDwellHierarchyTableColumns } from "../../AssetReport/components/DwellHierarchyTable/columns";
import { getColumns as getMediaActivitiesTableColumns } from "../../AssetReport/components/MediaActivitiesTable/columns";
import { getColumns as getMileageTableColumns } from "../../AssetReport/components/MileageTable/columns";
import { getColumns as getSensorsTableColumns } from "../../AssetReport/components/SensorsTable/columns";
import { ReportTypeTitle } from "../../constants";
import { ReportParameters, ReportWithParameters } from "../../interfaces";

const getCommonReportFields = (
  orgId: string,
  orgName: string,
  userId: string
): Pick<
  Report,
  | "_id"
  | "orgId"
  | "orgName"
  | "schedule"
  | "addedBy"
  | "updatedBy"
  | "status"
  | "addedAt"
  | "updatedAt"
> => {
  const now = new Date();
  return {
    _id: "template",
    orgId,
    orgName,
    schedule: [],
    addedBy: userId,
    updatedBy: userId,
    status: ReportStatus.Created,
    addedAt: now,
    updatedAt: now,
  };
};

export const buildAssetTransferReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.AssetTransfer]} Report`,
  type: ReportType.AssetTransfer,
  order: [{ field: "date", direction: OrderDirection.Desc }],
  parameters,
  columns: getAssetTransferTableColumns("").map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});
export const buildAssetInstallReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.AssetInstall]} Report`,
  type: ReportType.AssetInstall,
  order: [{ field: "installDate", direction: OrderDirection.Desc }],
  parameters,
  columns: getAssetInstallTableColumns("").map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});
export const buildAssetInventoryReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.AssetInventory]} Report`,
  type: ReportType.AssetInventory,
  order: [{ field: "device.lst_evnt_t", direction: OrderDirection.Desc }],
  parameters,
  columns: getAssetInventoryTableColumns("").map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});

export const buildYardReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => {
  const report = buildAssetInventoryReportTemplate(
    orgId,
    orgName,
    userId,
    parameters
  );
  report.name = `${ReportTypeTitle[ReportType.AssetYard]} Report`;
  report.type = ReportType.AssetYard;
  return report;
};

export const buildAlertHistoryReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.AlertHistory]} Report`,
  type: ReportType.AlertHistory,
  order: [{ field: "startedAt", direction: OrderDirection.Desc }], // may be changed
  parameters,
  columns: getAlertHistoryTableColumns(undefined).map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});

export const buildMileageReportTemplate = (
  timezone: string,
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.Mileage]} Report`,
  type: ReportType.Mileage,
  order: [{ field: "dateAndTime", direction: OrderDirection.Desc }], // may be changed
  parameters,
  columns: getMileageTableColumns(timezone).map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
  })),
});

export const buildOutsideGeofenceReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => {
  const report = buildAssetInventoryReportTemplate(
    orgId,
    orgName,
    userId,
    parameters
  );
  report.name = `${ReportTypeTitle[ReportType.OutsideGeofence]} Report`;
  report.type = ReportType.OutsideGeofence;
  return report;
};
export const buildAccountsReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): ReportWithParameters => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.Accounts]} Report`,
  type: ReportType.Accounts,
  order: [{ field: "updatedAt", direction: OrderDirection.Desc }],
  parameters,
  columns: getAccountsTableColumns(undefined).map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});

export const buildDwellHierarchyReportTemplate = (
  orgId: string,
  orgName: string,
  userId: string,
  parameters: Record<string, any>
): Report => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.DwellHierarchy]} Report`,
  type: ReportType.DwellHierarchy,
  order: [{ field: "geofenceName", direction: OrderDirection.Asc }],
  parameters,
  columns: getDwellHierarchyTableColumns().map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
  })),
});

export const buildMediaActivitiesReportTemplate = (
  timezone: string,
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): Report => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.MediaActivities]} Report`,
  type: ReportType.MediaActivities,
  order: [{ field: "dateAndTime", direction: OrderDirection.Desc }],
  parameters,
  columns: getMediaActivitiesTableColumns(timezone).map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});

export const buildSensorsReportTemplate = (
  timezone: string,
  orgId: string,
  orgName: string,
  userId: string,
  parameters: ReportParameters
): Report => ({
  ...getCommonReportFields(orgId, orgName, userId),
  name: `${ReportTypeTitle[ReportType.Sensors]} Report`,
  type: ReportType.Sensors,
  order: [{ field: "assetId", direction: OrderDirection.Asc }],
  parameters,
  columns: getSensorsTableColumns(timezone).map((col) => ({
    field: col.field,
    label: col.headerName as string,
    format: col.format,
    disableExport: col.disableExport,
  })),
});

export const getIsDateDisabled = (reportType: ReportType) => {
  const liveReports = [
    ReportType.AssetInventory,
    ReportType.AssetYard,
    ReportType.Mileage,
    ReportType.OutsideGeofence,
    ReportType.Accounts,
    ReportType.DwellHierarchy,
    ReportType.Sensors,
  ];
  return liveReports.includes(reportType);
};
