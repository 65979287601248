import { isNil } from "lodash";
import { ColorsPalette } from "../../../../../../../design-system/colors-palette";
import {
  HistoricalEventHistory,
  HistoricalEventHistoryLightsScenarioData,
} from "../../../../../../../graphql/operations";
import { LightsChartObservationType } from "./LightsChart";

export type LightsMappedValues = {
  date: number;
  [key: number]: {
    date: number;
    value: number | undefined;
  };
};

export enum BRAKE_LIGHT_SCENARIO_TITLES {
  BluePresent = "Blue Power Present",
  BlueNotPresent = "Blue Power Not Present",
  Precheck = "Precheck",
}

export enum OTHER_LIGHT_SCENARIO_TITLES {
  TractorPresent = "Tractor Power Present",
  Precheck = "Precheck",
}

export enum LITE_SENTRY_LIGHT_TYPES {
  BrakeLight = "brakeLight",
  LeftTurnSignal = "leftTurnSignal",
  RightTurnSignal = "rightTurnSignal",
  License = "license",
  Marker = "marker",
}

// Scenario numbers are defined by data & business logic
export const BrakeLightScenarioTitlesMap = new Map<number, string>([
  [0, BRAKE_LIGHT_SCENARIO_TITLES.BluePresent],
  [5, BRAKE_LIGHT_SCENARIO_TITLES.BlueNotPresent],
  [6, BRAKE_LIGHT_SCENARIO_TITLES.Precheck],
]);

export const BRAKE_LIGHT_SCENARIO_COLORS = new Map<number, string>([
  [0, ColorsPalette.PrimaryBlue],
  [5, ColorsPalette.LightCharcoal],
  [6, ColorsPalette.Alert],
]);

export const OtherLightScenarioColors = new Map<number, string>([
  [1, ColorsPalette.PrimaryBlue],
  [2, ColorsPalette.PrimaryBlue],
  [3, ColorsPalette.PrimaryBlue],
  [4, ColorsPalette.PrimaryBlue],
  [7, ColorsPalette.Alert],
  [8, ColorsPalette.Alert],
  [9, ColorsPalette.Alert],
  [10, ColorsPalette.Alert],
]);

export const LeftTurnLightScenarioTitlesMap = new Map<number, string>([
  [3, OTHER_LIGHT_SCENARIO_TITLES.TractorPresent],
  [9, OTHER_LIGHT_SCENARIO_TITLES.Precheck],
]);

export const RightTurnLightScenarioTitlesMap = new Map<number, string>([
  [1, OTHER_LIGHT_SCENARIO_TITLES.TractorPresent],
  [7, OTHER_LIGHT_SCENARIO_TITLES.Precheck],
]);

export const MarkerLightScenarioTitlesMap = new Map<number, string>([
  [4, OTHER_LIGHT_SCENARIO_TITLES.TractorPresent],
  [10, OTHER_LIGHT_SCENARIO_TITLES.Precheck],
]);

export const LicenseLightScenarioTitlesMap = new Map<number, string>([
  [2, OTHER_LIGHT_SCENARIO_TITLES.TractorPresent],
  [8, OTHER_LIGHT_SCENARIO_TITLES.Precheck],
]);

export const mapAggregatedLightsObservationData = (
  lightSensorType: LITE_SENTRY_LIGHT_TYPES,
  historicalEventHistoryData: HistoricalEventHistory[],
  observationType: LightsChartObservationType
): LightsMappedValues[] => {
  const mappedLightsData: LightsMappedValues[] = [];

  if (!historicalEventHistoryData?.length) return mappedLightsData;

  /**Map historical data for every lights type  */
  historicalEventHistoryData.forEach((data: HistoricalEventHistory) => {
    const dailyElements = data.lightsOut?.[observationType]?.[
      lightSensorType
    ] as HistoricalEventHistoryLightsScenarioData[];

    const initialObject = {
      date: new Date(data.date).getTime(),
      scenario_id: -1,
    };

    // If there's no data for the day we create initial object so that the chart still renders by date
    if (!dailyElements?.length) return mappedLightsData.push(initialObject);

    dailyElements?.forEach((element) => {
      const scenario_id = element?.scenario_id ?? -1;
      const preparedObject = {
        scenario_id,
        date: new Date(data.date).getTime(),
        value: !isNil(element?.value) ? element?.value : undefined,
      };

      // Object mapped with Scenario property is used for the yAxis to render the correct dot
      // The spread is done so the date is always available for the xAxis
      const mappedObject = {
        ...preparedObject,
        [scenario_id]: preparedObject,
      };

      mappedLightsData.push(mappedObject);
    });
  });

  return mappedLightsData;
};

export const renderBrakeLightCurrentScatterElements = () => {};
