import { memo } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import RequireAuth from "../../permissions/RequireAuth";
import Page from "../../shared/components/Page";
import { useFeatureFlag } from "../../utils";
import { FeatureFlags } from "../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { withDisplayName } from "../../utils/withDisplayName";
import AssetsViewContainer from "./AssetsViewContainer";
import { GalleryView } from "./GalleryView/GalleryView";
import MapView from "./MapView";
import { ShareAssetsView } from "./ShareAssetsView/ShareAssetsView";
import TableView from "./TableView";
import { AssetsDataContextProvider } from "./shared/AssetsDataContext";
import AssetsFilterControls from "./shared/AssetsFilterControls";

const Geofences = withDisplayName(Outlet, "Geofences");

const getViewFromUrl = (pathname: string) => {
  if (pathname.includes("table")) return "table";
  if (pathname.includes("gallery")) return "gallery";
  return "map";
};

const AssetView = memo(() => {
  const { userRolePermissions } = useAuthContext();

  const {
    state: { appConfig },
  } = useAppContext();
  const { selectedAssetsView } = appConfig;
  const urlView = getViewFromUrl(window.location.pathname);

  const geoFencingFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1RolloutGeofencing
  );

  return (
    <Page title="" className="bg-background">
      <AssetsDataContextProvider>
        <AssetsFilterControls />

        <AssetsViewContainer view={selectedAssetsView ?? urlView}>
          <Routes>
            <Route path={NavigationRoutes.Map} element={<MapView />} />
            <Route path={NavigationRoutes.Table} element={<TableView />} />
            <Route path={NavigationRoutes.Gallery} element={<GalleryView />} />
            <Route
              path={NavigationRoutes.SharedAssetInvitation}
              element={<ShareAssetsView />}
            />

            {geoFencingFeatureFlag && userRolePermissions.geofences.view && (
              <Route
                path={NavigationRoutes.Geo}
                element={
                  <RequireAuth>
                    <Geofences />
                  </RequireAuth>
                }
              >
                <Route path="" element={<MapView />} />
                <Route path=":id" element={<MapView />} />
              </Route>
            )}

            <Route
              path="*"
              element={
                <Navigate to={selectedAssetsView ?? urlView} relative="route" />
              }
            />
          </Routes>
        </AssetsViewContainer>
      </AssetsDataContextProvider>
    </Page>
  );
});

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
AssetView.displayName = "AssetsView";
export default AssetView;
