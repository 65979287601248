import { memo } from "react";
import { Box } from "@mui/material";
import { RegionsSection } from "./components/RegionsSection";
import { RegionsContextProvider } from "./context";
import { RegionCountry } from "./interfaces";

const Regions: React.FC = () => {
  return (
    <RegionsContextProvider>
      <Box
        data-testid="regions"
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "4rem",
          gap: "2rem",
        }}
      >
        <RegionsSection country={RegionCountry.UnitedStates} />
        <RegionsSection country={RegionCountry.Canada} />
        <RegionsSection country={RegionCountry.Mexico} />
      </Box>
    </RegionsContextProvider>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
Regions.displayName = "Regions";
export default memo(Regions);
