import { useState } from "react";

interface PopoverHook {
  popoverId: string | null;
  popoverAnchorElement: HTMLButtonElement | null;
  isPopoverOpen: boolean;
  openPopover: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closePopover: () => void;
}

const usePopover = (): PopoverHook => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null
  );

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorElement(null);
  };

  const isPopoverOpen = Boolean(anchorElement);
  const popoverId = isPopoverOpen ? "date-range-popover" : null;

  return {
    popoverId,
    isPopoverOpen,
    popoverAnchorElement: anchorElement,
    openPopover,
    closePopover,
  };
};

export default usePopover;
