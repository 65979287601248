import { ColorsPalette } from "../../../../../../design-system/colors-palette";
import { AggregatedEventHistory } from "../../../../../../graphql/operations";

export const BAR_HEIGHT = 24;

export type CargoData = {
  eventHistoryData?: {
    cargo: Partial<AggregatedEventHistory["cargo"] | null>;
    date?: string | null;
  };
} | null;

export function indexOfMax(arr: number[]) {
  if (arr.length === 0) {
    return -1;
  }
  if (arr.length > 0) {
    let max = arr[0];
    let maxIndex = 0;

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return maxIndex;
  }
}

export function calculateCargoStatusLast30Days(data: Array<CargoData>) {
  let emptyCargoDays = 0;
  let loadedCargoDays = 0;

  data.forEach((aggregatedData: any) => {
    let cargoLoadedEvents =
      aggregatedData.eventHistoryData.cargo?.cargoSummary.loaded;
    if (cargoLoadedEvents > 0) {
      loadedCargoDays += 1;
    } else {
      emptyCargoDays += 1;
    }
  });

  return {
    emptyCargoDays,
    loadedCargoDays,
  };
}

export function calculateCargoStatusInLocationsLast30Days(
  data: Array<CargoData>
) {
  let emptyCargoDaysInsideGeofence = 0;
  let emptyCargoDaysOutsideGeofence = 0;
  let loadedCargoDaysInsideGeofence = 0;
  let loadedCargoDaysOutsideGeofence = 0;

  data.forEach((aggregatedData: any) => {
    const loadedCountGeofence =
      aggregatedData.eventHistoryData.cargo?.cargoSummary.loadedCountGeofence;
    const loadedCountOutGeofence =
      aggregatedData.eventHistoryData.cargo?.cargoSummary
        .loadedCountOutGeofence;

    const unloadedCountGeofence =
      aggregatedData.eventHistoryData.cargo?.cargoSummary.unloadedCountGeofence;
    const unloadedCountOutGeofence =
      aggregatedData.eventHistoryData.cargo?.cargoSummary
        .unloadedCountOutGeofence;

    let maxValueIndex = indexOfMax([
      loadedCountGeofence,
      loadedCountOutGeofence,
      unloadedCountGeofence,
      unloadedCountOutGeofence,
    ]);

    switch (maxValueIndex) {
      case 0:
        loadedCargoDaysInsideGeofence += 1;
        break;
      case 1:
        loadedCargoDaysOutsideGeofence += 1;
        break;
      case 2:
        emptyCargoDaysInsideGeofence += 1;
        break;
      case 3:
        emptyCargoDaysOutsideGeofence += 1;
        break;
      default:
        console.log("Error calculating cargo state for current day!");
        break;
    }
  });

  return {
    emptyCargoDaysInsideGeofence,
    emptyCargoDaysOutsideGeofence,
    loadedCargoDaysInsideGeofence,
    loadedCargoDaysOutsideGeofence,
  };
}

export const getBoxColor = (eventsCount: number) => {
  if (eventsCount === 0) return "border";
  if (eventsCount > 0) return "bg-brand-light-blue";
  return "bg-box-shadow";
};

export const getBoxBorderColor = (eventsCount: number) => {
  if (eventsCount === 0) return ColorsPalette.RoyalBlue;
  if (eventsCount > 0) return ColorsPalette.Concrete;
  return "bg-grayscale-feather-gray";
};
