import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import debounce from "lodash/debounce";
import { TIME_ZONE_OPTIONS } from "../../../../../../constants/account";
import { DateTextField } from "../../../../../../shared/components/DateTextField";
import {
  AM_PM_OPTIONS,
  HOUR_OPTIONS,
} from "../../../../../../shared/components/FrequencySelect/constants";
import { addDaysToDate, checkIsValidDate } from "../../../../../../utils/date";
import { useAssetsDataContext } from "../../../AssetsDataContext";
import { InstalledDateFilterOptions } from "../MoreFilters";
import {
  convertTo12HoursFormat,
  convertTo24HoursFormat,
  optionise,
} from "./helpers";

export const GeofenceMoreFiltersTab = () => {
  const {
    currentGeofenceFilter: {
      timezones,
      createdRange,
      updatedRange,
      operatingHoursRange,
    },
    onChangeGeofenceFilters,
  } = useAssetsDataContext();

  const { time: startTime, format: startTimeFormat } = convertTo12HoursFormat(
    operatingHoursRange?.timeFrom
  );
  const { time: endTime, format: endTimeFormat } = convertTo12HoursFormat(
    operatingHoursRange?.timeTo
  );

  const changeDateHandler = (
    selectedDate: Date | null,
    dateField: string,
    name: string
  ) => {
    if (!selectedDate || selectedDate.toString() === "Invalid Date") {
      onChangeGeofenceFilters({
        [name]: null,
      });
      return;
    }

    let startDate =
      name === "createdRange"
        ? createdRange?.startDate
        : updatedRange?.startDate;
    let endDate =
      name === "createdRange" ? createdRange?.endDate : updatedRange?.endDate;

    if (dateField === InstalledDateFilterOptions.StartDate) {
      const newStartDate = checkIsValidDate(selectedDate)
        ? selectedDate.toISOString()
        : null;

      const endDateAsDate = endDate ? new Date(endDate) : null;

      if (
        !endDateAsDate ||
        (newStartDate && new Date(newStartDate) >= endDateAsDate)
      ) {
        const nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        endDate = nextDay.toISOString();
      }
      startDate = newStartDate;
    } else {
      endDate = checkIsValidDate(selectedDate)
        ? selectedDate.toISOString()
        : null;
    }

    if (startDate && endDate) {
      onChangeGeofenceFilters({
        [name]: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  };

  const handleTimeChange = (value: string | undefined, name: string) => {
    if (!value) {
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom:
            name === "timeFrom" || name === "timeFromFormat"
              ? ""
              : operatingHoursRange?.timeFrom,
          timeTo:
            name === "timeTo" || name === "timeToFormat"
              ? ""
              : operatingHoursRange?.timeTo,
        },
      });
    }
    if (name === "timeFrom") {
      const time = convertTo24HoursFormat(value, startTimeFormat ?? "AM");
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: time as string,
          timeTo: operatingHoursRange?.timeTo,
        },
      });
    } else if (name === "timeFromFormat") {
      const timeValue = startTime ? startTime : "10:00";
      const time = convertTo24HoursFormat(timeValue, value as string);
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: time as string,
          timeTo: operatingHoursRange?.timeTo,
        },
      });
    } else if (name === "timeTo") {
      const time = convertTo24HoursFormat(value, endTimeFormat ?? "AM");
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: operatingHoursRange?.timeFrom,
          timeTo: time as string,
        },
      });
    } else if (name === "timeToFormat") {
      const timeValue = endTime ? endTime : "10:00";
      const time = convertTo24HoursFormat(timeValue, value as string);
      onChangeGeofenceFilters({
        operatingHoursRange: {
          timeFrom: operatingHoursRange?.timeFrom,
          timeTo: time as string,
        },
      });
    }
  };

  const timezonesOptions = TIME_ZONE_OPTIONS.map((timezone) => ({
    id: timezone.value,
    label: timezone.label,
  }));

  const debouncedSetLocationCode = debounce((value: string) => {
    onChangeGeofenceFilters({ locationCodes: [value] });
  }, 500);

  return (
    <Box sx={{ maxHeight: `70vh`, overflow: "auto" }}>
      <Accordion defaultExpanded>
        <AccordionSummary>Time Zone</AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            data-testid="geofence-filter__timezones"
            limitTags={3}
            options={timezonesOptions}
            size="small"
            value={timezones[0] ?? null}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(_, value) => {
              onChangeGeofenceFilters({
                timezones: value
                  ? [value as { id: string; label: string }]
                  : [],
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Time Zone"
                data-testid="geofence-timezones-input"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary>Location Code</AccordionSummary>
        <AccordionDetails>
          <div data-testid="geofence-filter__location-code">
            <TextField
              label="Location Code"
              variant="standard"
              fullWidth
              onChange={(e) => debouncedSetLocationCode(e.target.value)}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Created</AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <div data-testid="geofence-filter__created-start-date">
                <DateTextField
                  label="Start Date"
                  value={createdRange?.startDate ?? null}
                  data-title="Created Start Date"
                  fullWidth
                  onChange={(value) => {
                    changeDateHandler(
                      value,
                      InstalledDateFilterOptions.StartDate,
                      "createdRange"
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div data-testid="geofence-filter__created-end-date">
                <DateTextField
                  label="End Date"
                  value={createdRange?.endDate ?? null}
                  data-title="Created End Date"
                  fullWidth
                  onChange={(value) => {
                    changeDateHandler(
                      value,
                      InstalledDateFilterOptions.EndDate,
                      "createdRange"
                    );
                  }}
                  minDate={addDaysToDate(
                    new Date(createdRange?.startDate ?? null),
                    1
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary>Geofence Updated</AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <div data-testid="geofence-filter__updated-start-date">
                <DateTextField
                  label="Start Date"
                  value={updatedRange?.startDate ?? null}
                  data-title="Updated Start Date"
                  fullWidth
                  onChange={(value) => {
                    changeDateHandler(
                      value,
                      InstalledDateFilterOptions.StartDate,
                      "updatedRange"
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div data-testid="geofence-filter__updated-end-date">
                <DateTextField
                  label="End Date"
                  value={updatedRange?.endDate ?? null}
                  data-title="Updated End Date"
                  fullWidth
                  onChange={(value) => {
                    changeDateHandler(
                      value,
                      InstalledDateFilterOptions.EndDate,
                      "updatedRange"
                    );
                  }}
                  minDate={addDaysToDate(
                    new Date(updatedRange?.startDate ?? null),
                    1
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary>Currently Open</AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} className="!mb-4">
            <Grid item xs={6}>
              <Autocomplete
                data-testid="geofence-filter__open-time-from"
                limitTags={3}
                options={HOUR_OPTIONS}
                size="small"
                value={startTime ? optionise(startTime) : null}
                fullWidth
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) =>
                  handleTimeChange(value?.value, "timeFrom")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time From"
                    data-testid="geofence-open-time-from-input"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                data-testid="geofence-filter__time-format-from"
                limitTags={3}
                options={AM_PM_OPTIONS}
                size="small"
                fullWidth
                value={startTimeFormat ? optionise(startTimeFormat) : null}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) =>
                  handleTimeChange(value?.value, "timeFromFormat")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="AM/PM"
                    data-testid="geofence-open-time-format-from-input"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                data-testid="geofence-filter__open-time-to"
                limitTags={3}
                options={HOUR_OPTIONS}
                size="small"
                value={endTime ? optionise(endTime) : null}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) =>
                  handleTimeChange(value?.value, "timeTo")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time To"
                    data-testid="geofence-open-time-to-input"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                data-testid="geofence-filter__time-format-to"
                limitTags={3}
                options={AM_PM_OPTIONS}
                size="small"
                value={endTimeFormat ? optionise(endTimeFormat) : null}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                onChange={(_, value) =>
                  handleTimeChange(value?.value, "timeToFormat")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="AM/PM"
                    data-testid="geofence-open-time-format-to-input"
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
