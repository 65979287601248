import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  SensorStatusUppercase,
  TemperatureSensorDetails,
  useFindAssetByIdQuery,
} from "../../../../../graphql/operations";
import { TemperatureInfoComponent } from "./TemperatureInfoComponent";
import { TemperatureStatusCard } from "./TemperatureStatusCard";
import { TemperatureTabChartSection } from "./chart/TemperatureTabChartSection";
import { TemperatureSummarydata, mapTemperatureSummary } from "./helpers";

interface TemperaturesTabProps {
  selectedAssetId: string;
  internalId: string;
  assetImei?: string;
}

export const TemperaturesTab: React.FC<TemperaturesTabProps> = ({
  selectedAssetId,
  internalId,
  assetImei,
}) => {
  const [sensor, setSensor] = useState<TemperatureSummarydata | undefined>(
    undefined
  );

  const { data: assetData } = useFindAssetByIdQuery({
    assetId: internalId ?? "",
  });

  const selectedAsset = useMemo(
    () => assetData?.findAssetById ?? null,
    [assetData]
  );

  useEffect(() => {
    setSensor(
      mapTemperatureSummary(
        selectedAsset?.sensors?.temperature as TemperatureSensorDetails,
        selectedAsset?.sensors?.temperature?.data?.statusInternal ??
          SensorStatusUppercase.Unknown
      )
    );
  }, [selectedAsset]);

  return (
    <>
      {sensor?.status && sensor?.status !== SensorStatusUppercase.Unknown ? (
        <Box
          className="flex h-full flex-col gap-4 px-4 py-4"
          data-testid="temperature-tab-panel"
        >
          <>
            <Grid item container columnSpacing={2} xs={12}>
              {sensor && (
                <Grid item xl={4} lg={4} xs={12}>
                  <Grid item xl={12} lg={12} xs={12} paddingBottom={2}>
                    <TemperatureStatusCard tempSummary={sensor} />
                  </Grid>
                  <Grid item xl={12} lg={12} xs={12} paddingBottom={2}>
                    <TemperatureInfoComponent sensor={sensor} />
                  </Grid>
                </Grid>
              )}
              {!sensor && (
                <Grid item xl={3} lg={3} xs={12}>
                  <Box className="flex justify-center items-center w-full">
                    <Box
                      className="py-4 px-8 md:p-8 bg-background"
                      data-testid="assets-error-msg"
                    >
                      <Typography className="mb-6 text-lg font-semibold text-typography">
                        There is no such data recorded for this asset.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xl={8} lg={8} xs={12}>
                <TemperatureTabChartSection
                  customerOrgId={selectedAsset?.customer_orgs_id ?? ""}
                  assetImei={assetImei}
                  selectedAssetId={selectedAssetId}
                  internalId={internalId}
                />
              </Grid>
            </Grid>
          </>
        </Box>
      ) : (
        <Box
          className="text-sm bg-dashboard_subheader__bg p-6 m-2 text-asset-info-subheader"
          data-testid="other-sensors-tab-panel-no-data"
        >
          No Sensors Available.
        </Box>
      )}
    </>
  );
};
