import { FC, useCallback, useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import ActionButton from "../../../../../../shared/components/Button/ActionButton";
import { QueryBuilderElement } from "../../../../../../shared/components/react-hook-form-mui/QueryBuilderElement";
import { useAssetsDataContext } from "../../../AssetsDataContext";
import { geofenceQueryBuilderFields } from "./config";

interface QueryBuilderTabProps {
  form: any;
  onClose: () => void;
}

export const QueryBuilderTab: FC<QueryBuilderTabProps> = ({
  form,
  onClose,
}: QueryBuilderTabProps) => {
  const [shouldReset, setShouldReset] = useState(false);
  const { onChangeGeofenceFilters } = useAssetsDataContext();

  const filterValues = form.watch();

  const resetQueryBuilderHandler = useCallback(() => {
    setShouldReset(true);
    onChangeGeofenceFilters({ filters: null });
  }, [onChangeGeofenceFilters]);

  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  const onApply = () => {
    onChangeGeofenceFilters({ filters: filterValues.filters || null });
    onClose();
  };

  return (
    <Paper
      elevation={0}
      className="w-[900px]"
      data-testid="alert-query-builder"
    >
      <Box className="p-6">
        {/*query builder has padding right, remove pr-2 if style change*/}
        <Box className="flex justify-between pr-2">
          <Typography className="!pb-8 !text-lg" variant="h6" component="div">
            Custom Query
          </Typography>
          <Button
            data-testid="reset-query-view-btn"
            className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
            onClick={resetQueryBuilderHandler}
          >
            Reset query view
          </Button>
        </Box>
        <div className="mb-4">
          <QueryBuilderElement
            label=""
            control={form.control}
            name="filters"
            configFields={geofenceQueryBuilderFields}
            shouldReset={shouldReset}
            onReset={onReset}
          />
        </div>
        <div className="flex justify-end pr-2">
          <ActionButton
            testId="apply-query-view-btn"
            className="block text-primary border-primary text-typography"
            onClick={onApply}
          >
            Save
          </ActionButton>
        </div>
      </Box>
    </Paper>
  );
};
