import { memo, SyntheticEvent, useMemo } from "react";
import { Box, Tab, Collapse, Tabs } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { deepmerge } from "@mui/utils";
import classNames from "classnames";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useAssetsDataContext } from "../../AssetsDataContext";
import { SelectedFilterTab } from "./Filters";
import GeofenceFiltersTab from "./GeofenceFiltersTab/GeofenceFiltersTab";
import { GeofenceMoreFiltersTab } from "./GeofenceMoreFiltersTab/GeofenceMoreFiltersTab";
import { QueryBuilderTab } from "./QueryBuilderTab/QueryBuilderTab";
import { useGeofenceQueryBuilderForm } from "./QueryBuilderTab/useGeofenceQueryBuilderForm";

//----------------------------
// Types
//----------------------------

interface FilterTab {
  label: string;
  value: string;
}

export interface FilterProps {
  selectedFilterTab: SelectedFilterTab;
  setSelectedFilterTab: (x: SelectedFilterTab) => void;
}

const useAssetFiltersTheme = () => {
  const muiTheme = useCurrentTheme();

  return createTheme(
    deepmerge(muiTheme, {
      components: {
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: "16px 32px",
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              background: "var(--bg)",
              color: "var(--text)",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "140%",
              padding: "0 28px",
            },
          },
        },
      },
    })
  );
};

export const GeofenceFilters = memo(
  ({ selectedFilterTab, setSelectedFilterTab }: FilterProps) => {
    const muiTheme = useAssetFiltersTheme();
    const { currentGeofenceFilter } = useAssetsDataContext();
    const { form } = useGeofenceQueryBuilderForm(currentGeofenceFilter);
    const isMobile: boolean = useBreakpoint("down", "md");

    const filterTabs: FilterTab[] = useMemo(() => {
      let geofenceFiltersCount = 0;
      geofenceFiltersCount += currentGeofenceFilter.names?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.orgIds?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.shapes !== "all" ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.typeIds?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.tags?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.underMin?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.overMax?.length ? 1 : 0;
      geofenceFiltersCount += currentGeofenceFilter.hasAssets !== "all" ? 1 : 0;
      let qbFiltersCount = 0;
      qbFiltersCount += currentGeofenceFilter.filters?.length ? 1 : 0;
      let moreFiltersCount = 0;

      return [
        {
          label: `Geofence${
            geofenceFiltersCount ? ` • ${geofenceFiltersCount}` : ""
          }`,
          value: "geofence",
        },
        {
          label: `Query Builder${qbFiltersCount ? ` • ${qbFiltersCount}` : ""}`,
          value: "queryBuilder",
        },
        {
          label: `More${moreFiltersCount ? ` • ${moreFiltersCount}` : ""}`,
          value: "more",
        },
      ];
    }, [currentGeofenceFilter]);
    let actualFilterTabs = filterTabs;
    if (isMobile) {
      actualFilterTabs = filterTabs.filter(
        (tab) => tab.value !== "queryBuilder"
      );
    }
    const onChange = (e: SyntheticEvent, value: SelectedFilterTab) => {
      setSelectedFilterTab(value);
    };

    const isQueryBuilderTabSelected = selectedFilterTab === "queryBuilder";
    const containerClasses = isQueryBuilderTabSelected
      ? "left-[-600px]"
      : "w-[340px] max-w-[340px]";
    return (
      <ThemeProvider theme={muiTheme}>
        <div data-testid="map-filters" className="relative">
          <Tabs
            className="max-w-[340px] bg-secondary"
            value={selectedFilterTab || false}
            onChange={onChange}
            TabIndicatorProps={{
              className: "!bg-secondary",
            }}
            sx={{
              minHeight: "auto",
              maxHeight: "32px",
            }}
          >
            {actualFilterTabs.map((props, index) => (
              <Tab
                sx={{
                  minHeight: "initial",
                  "&.MuiButtonBase-root": {
                    flexGrow: 1,
                    position: "relative",
                    minWidth: "100px",
                  },
                  "&.MuiButtonBase-root:not(:first-of-type)::after": {
                    position: "absolute",
                    left: 0,
                    top: 7,
                    width: "2px",
                    height: "16px",
                    backgroundColor: "#E3E3E3",
                    content: '""',
                  },
                }}
                className={classNames(
                  "max-h-[30px]  border-r-typography !text-primary",
                  {
                    "!font-bold !text-primary":
                      selectedFilterTab === props.value,
                    "!border-none": index === filterTabs.length - 1,
                  }
                )}
                data-testid={`filter-item-${props.label}`}
                key={`filter-item-${index}`}
                {...props}
              />
            ))}
          </Tabs>
          <Collapse in={Boolean(selectedFilterTab)}>
            <Box
              className={classNames(
                "top-full bg-secondary md:absolute",
                containerClasses
              )}
            >
              {selectedFilterTab === "geofence" && <GeofenceFiltersTab />}
              {selectedFilterTab === "queryBuilder" && !isMobile && (
                <QueryBuilderTab
                  form={form}
                  onClose={() => setSelectedFilterTab(null)}
                />
              )}
              {selectedFilterTab === "more" && <GeofenceMoreFiltersTab />}
            </Box>
          </Collapse>
        </div>
      </ThemeProvider>
    );
  }
);
