import { GridColDef } from "@mui/x-data-grid/models";
import { AssetAlert } from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { AlertNotificationModal } from "../../../TableView/components";

export const defaultColumnVisibilityModel = {
  trigger: true,
  alert_notification: true,
  assetOrgName: true,
  type: true,
  remindAt: true,
  imei: true,
}; //NOSONAR

export const getColumns = (timezone: string): GridColDef<AssetAlert>[] => [
  { field: "trigger", headerName: "Trigger", flex: 1, minWidth: 120 },
  {
    field: "alert_notification",
    headerName: "Alert Notification",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => {
      return (
        <AlertNotificationModal alertNotificationId={String(params.row._id)} />
      );
    },
  },
  {
    field: "assetOrgName",
    headerName: "Organization Name",
    flex: 1,
    minWidth: 120,
  },
  { field: "type", headerName: "Alert Type", flex: 1, minWidth: 120 },
  {
    field: "remindAt",
    headerName: "Date / Time Alert Sent",
    flex: 1,
    minWidth: 200,
    ...columnTimezoneDateTimeFormatParams(timezone),
  },
  { field: "imei", headerName: "Device ID", flex: 1, minWidth: 120 },
]; //NOSONAR

export const SEARCH_KEYS: Array<string> = [
  "trigger",
  "alert_notification",
  "assetOrgName",
  "type",
  "remindAt",
  "imei",
]; //NOSONAR
