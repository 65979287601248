import { FC, useEffect, useState } from "react";
import { default as MuiCarousel } from "react-material-ui-carousel";
import ArrowPrevIcon from "@mui/icons-material/ArrowBack";
import ArrowNextIcon from "@mui/icons-material/ArrowForward";
import { Button, Box } from "@mui/material";
import classNames from "classnames";
import { useInternalCameraSensorContext } from "../context";
import { paginateMediaActivityItems } from "../helpers";
import { MediaActivityItem } from "../interface";
import CarouselItem from "./CarouselItem";

export interface InternalCameraStatusCarouselProps {
  items: MediaActivityItem[];
}

const Carousel: FC<InternalCameraStatusCarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // We need to know the active view in order to paginate the items (one image, four images, etc.)
  const { activeView, activeMediaActivityItems, setActiveMediaActivityItems } =
    useInternalCameraSensorContext();
  const paginatedItems = paginateMediaActivityItems(items, activeView);

  // We need to keep track of the current index in order to disable the prev/next buttons
  const handleCarouselChange = (index: number | undefined) => {
    if (index === undefined) {
      return;
    }

    setCurrentIndex(index);
  };

  // Trigger redrawing of the carousel when the active view changes, so it will recalculate the height
  useEffect(() => {
    // If the current index is greater than the number of items, set it to the last item
    // Note: This may happen when the user changes the active view to a larger number of items
    let nextPage = currentIndex;
    if (currentIndex >= paginatedItems.length) {
      nextPage = paginatedItems.length - 1;
    }

    // Simulate changing the index to 0, so the carousel will redraw
    setCurrentIndex(1);

    // And on the next tick, set the index back to the original value
    setTimeout(() => {
      setCurrentIndex(nextPage);
    });
  }, [activeView, setCurrentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the active selection in the context when the current index changes
  useEffect(() => {
    const activeItems = paginatedItems[currentIndex] ?? [];

    // Compare the active items to the current active items, and only update the context if they are different
    if (activeItems.length === activeMediaActivityItems.length) {
      const activeItemsAreEqual = activeItems.every((item, i) => {
        const activeItem = activeMediaActivityItems[i];

        return item.imageUrl === activeItem.imageUrl;
      });

      // If the active items are the same, don't update the context
      if (activeItemsAreEqual) {
        return;
      }
    }

    // Well, the active items are different, so update the context
    setActiveMediaActivityItems(activeItems);
  }, [
    activeView,
    currentIndex,
    paginatedItems,
    activeMediaActivityItems,
    setActiveMediaActivityItems,
  ]);

  const isPrevButtonDisabled = currentIndex === 0;
  const isNextButtonDisabled = currentIndex === paginatedItems.length - 1;

  return (
    <Box className="w-full" data-testid="internal-camera-status--carousel">
      {paginatedItems.length === 0 && (
        <Box className="flex justify-center items-center h-32 text-card__typography text-center">
          There is no such data recorded for this asset.
        </Box>
      )}

      <MuiCarousel
        index={currentIndex}
        onChange={handleCarouselChange}
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        sx={{
          "& div": {
            "&:hover": {
              "& button": {
                opacity: "1 !important",
                "&.Mui-disabled": {
                  color: "var(--light-charcoal) !important",
                },
              },
            },
          },
        }}
        navButtonsWrapperProps={{
          style: {
            display: "flex",
            alignItems: "end",
          },
        }}
        navButtonsProps={{
          style: {
            backgroundColor: "transparent",
            marginBottom: "18px",
            color: "var(--primary-blue)",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "700",
            textTransform: "none",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "26px 90px",
            width: "calc(100% - 180px)",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "16px",
            height: "16px",
            padding: "8px",
            color: "var(--concrete)",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "var(--primary-blue)",
          },
        }}
        NavButton={({ onClick, className, style, next, prev }) => {
          const isButtonDisabled =
            (prev && isPrevButtonDisabled) || (next && isNextButtonDisabled);

          const handleClick = (
            event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
          ) => {
            onClick(event);
          };

          return (
            <Button
              onClick={handleClick}
              style={style}
              className={classNames(className, {
                "!text-light-charcoal": isButtonDisabled,
              })}
              disabled={isButtonDisabled}
            >
              {next && (
                <>
                  Next <ArrowNextIcon className="ml-2" />
                </>
              )}
              {prev && (
                <>
                  <ArrowPrevIcon className="mr-2" /> Prev
                </>
              )}
            </Button>
          );
        }}
      >
        {paginatedItems.map((items, i) => (
          <CarouselItem key={i} items={items} />
        ))}
      </MuiCarousel>
    </Box>
  );
};

export default Carousel;
