import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { GridColDef } from "@mui/x-data-grid-premium";
import {
  AssetWithSensors,
  SensorProfileResult,
  SensorProfileConfigType,
  ProfileConfigProperty,
  SensorThresholdShort,
  LiteSentryGammaThresholds,
  PressureUnit,
} from "../../../../graphql/operations";
import TableHeader from "../../../../shared/components/TableHeader/TableHeader";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
  SensorRangesSettingsValues,
} from "../../../../shared/helpers/battery";
import { LiteSentryGammaScenario } from "../../../../shared/helpers/liteSentryGamma";
import {
  SensorData,
  ThresholdsRange,
  SensorThresholds,
  InternalTemperatureRange,
  PressureSensorThresholds,
  InternalTemperatureSensorThresholds,
} from "../../../../shared/helpers/sensors.utils";
import { formatDate } from "../../../../utils";
import { convertPressureThresholdsRange } from "../../../../utils/convertPressure";

export type ConfigurationTypes = "voltage" | "TPMS";

export enum ConfigurationOptions {
  Voltage = "voltage",
  TPMS = "TPMS",
}

export enum BatteryDrawers {
  Edit = "editBatteryProfile",
  Create = "createBatteryProfile",
}

export enum TpmsDrawers {
  Edit = "editTPMSProfile",
  Create = "createTPMSProfile",
}

export enum AirbagDrawers {
  Edit = "editAirbagProfile",
  Create = "createAirbagProfile",
}

export enum RegulatorDrawers {
  Edit = "editRegulatorProfile",
  Create = "createRegulatorProfile",
}

export enum CargoCameraDrawers {
  Edit = "editCargoCameraProfile",
  Create = "createCargoCameraProfile",
}
export enum AirSupplyDrawers {
  Edit = "editAirTankProfile",
  Create = "createAirTankProfile",
}

export enum PsiWheelEndDrawers {
  Edit = "editPsiWheelEndProfile",
  Create = "createPsiWheelEndProfile",
}

export enum TemperatureDrawers {
  Edit = "editTemperatureProfile",
  Create = "createTemperatureProfile",
}

export enum AtisDrawers {
  Edit = "editAtisProfile",
  Create = "createAtisProfile",
}

export enum LiteSentryGammaDrawers {
  Edit = "editLiteSentryGammaProfile",
  Create = "createLiteSentryGammaProfile",
}

export enum LiftgateDrawers {
  Edit = "editLiftgateProfile",
  Create = "createLiftgateProfile",
}

export type DrawersTypeMapping = {
  voltage: {
    create: BatteryDrawers.Create;
    edit: BatteryDrawers.Edit;
  };
  tpmsBeta: {
    create: TpmsDrawers.Create;
    edit: TpmsDrawers.Edit;
  };
  airbag: {
    create: AirbagDrawers.Create;
    edit: AirbagDrawers.Edit;
  };
  airSupply: {
    create: AirSupplyDrawers.Create;
    edit: AirSupplyDrawers.Edit;
  };
  cargoCamera: {
    create: CargoCameraDrawers.Create;
    edit: CargoCameraDrawers.Edit;
  };
  psiWheelEnd: {
    create: PsiWheelEndDrawers.Create;
    edit: PsiWheelEndDrawers.Edit;
  };
  temperature: {
    create: TemperatureDrawers.Create;
    edit: TemperatureDrawers.Edit;
  };
  atisAlpha: {
    create: AtisDrawers.Create;
    edit: AtisDrawers.Edit;
  };
  liteSentryGamma: {
    create: LiteSentryGammaDrawers.Create;
    edit: LiteSentryGammaDrawers.Edit;
  };
  liftgate: {
    create: LiftgateDrawers.Create;
    edit: LiftgateDrawers.Edit;
  };
};

export const drawersTypeMapping: DrawersTypeMapping = {
  voltage: {
    create: BatteryDrawers.Create,
    edit: BatteryDrawers.Edit,
  },
  tpmsBeta: {
    create: TpmsDrawers.Create,
    edit: TpmsDrawers.Edit,
  },
  airbag: {
    create: AirbagDrawers.Create,
    edit: AirbagDrawers.Edit,
  },
  airSupply: {
    create: AirSupplyDrawers.Create,
    edit: AirSupplyDrawers.Edit,
  },
  cargoCamera: {
    create: CargoCameraDrawers.Create,
    edit: CargoCameraDrawers.Edit,
  },
  psiWheelEnd: {
    create: PsiWheelEndDrawers.Create,
    edit: PsiWheelEndDrawers.Edit,
  },
  temperature: {
    create: TemperatureDrawers.Create,
    edit: TemperatureDrawers.Edit,
  },
  atisAlpha: {
    create: AtisDrawers.Create,
    edit: AtisDrawers.Edit,
  },
  liteSentryGamma: {
    create: LiteSentryGammaDrawers.Create,
    edit: LiteSentryGammaDrawers.Edit,
  },
  liftgate: {
    create: LiftgateDrawers.Create,
    edit: LiftgateDrawers.Edit,
  },
};

export type DrawerType =
  | AirSupplyDrawers
  | AirbagDrawers
  | TpmsDrawers
  | BatteryDrawers
  | CargoCameraDrawers
  | PsiWheelEndDrawers
  | TemperatureDrawers
  | AtisDrawers
  | LiteSentryGammaDrawers
  | LiftgateDrawers;

export type ProfilesData = {
  data: SensorProfileResult[];
  isErrorProfile: boolean;
  isFetchingProfile: boolean;
};

export enum SensorsProperties {
  Battery = "batterySensors",
  Airbag = "airbag",
  SupplyPressure = "supplyPressure",
  Pressure = "pressure",
  Temperature = "temperature",
  temperatureSensor = "temperatureSensor",
  TankPressure = "tankPressure",
  CargoCamera = "cargoCamera",
  PsiWheelEnd = "psiWheelEnd",
  Imbalance = "imbalance",
  Atis = "atisAlpha",
  LiteSentryGamma = "liteSentryGamma",
  Liftgate = "liftgate",
}

export enum BatteryState {
  Healthy = "Healthy",
  Warning = "Warning",
  Alert = "Alert",
  Critical = "Critical",
}

export enum PressureState {
  Healthy = "Healthy Pressure Level",
  Warning = "Warning Pressure Level",
  Alert = "Alert Pressure Level",
  Critical = "Critical Pressure Level",
}

export enum SupplyPressureState {
  Healthy = "Healthy",
  Warning = "Warning",
  Alert = "Alert",
  Critical = "Critical",
}

export type MappedSensorProfile = SensorProfileResult & SensorData;

export const tableStyleMap = {
  healthy: "bg-[var(--success-transparent)]",
  warning: "bg-[var(--caution-transparent)]",
  alert: "bg-[var(--warning-transparent)]",
  critical: "bg-[var(--error-transparent)]",
};

export const sensorTypeToSensorNameMap = {
  [SensorProfileConfigType.Airbag]: "Air Bag Pressure",
  [ProfileConfigProperty.Battery]: "Battery",
  [SensorProfileConfigType.CargoCamera]: "Cargo Camera",
  [ProfileConfigProperty.Pressure]: "Pressure",
  [ProfileConfigProperty.TankPressure]: "Tank Pressure",
  [SensorProfileConfigType.Temperature]: "Temperature",
  [ProfileConfigProperty.SupplyPressure]: "Regulator",
  [ProfileConfigProperty.Imbalance]: "Imbalance",
};

const createSensorColumn = (
  fieldPrefix: string,
  state: string,
  type: string
): GridColDef<SensorProfileResult> => ({
  field: `${fieldPrefix}.${state}`,
  headerName: `${state.charAt(0).toUpperCase() + state.slice(1)} ${type} Level`,
  type: "string",
  renderCell: renderValueRanges,
  flex: 1,
  minWidth: 140,
  headerClassName:
    tableStyleMap[state.toLowerCase() as keyof typeof tableStyleMap],
  cellClassName:
    tableStyleMap[state.toLowerCase() as keyof typeof tableStyleMap],
  headerAlign: "left",
  align: "left",
});

const generateSensorColumns = (
  fieldPrefix: string,
  states: string[],
  type: string
): GridColDef<SensorProfileResult>[] => {
  return states.map((state) => createSensorColumn(fieldPrefix, state, type));
};

const sensorStates = Object.values(BatteryState).map(
  (value) => value.charAt(0).toLowerCase() + value.slice(1)
);

const getBatterySensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix + "."}healthy`,
      headerName: "Healthy",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={BatteryState.Healthy} />,
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}warning`,
      headerName: "Warning",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={BatteryState.Warning} />,
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}alert`,
      headerName: "Alert",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={BatteryState.Alert} />,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}critical`,
      headerName: "Critical",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={BatteryState.Critical} />,
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const getAirBagSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix}.healthy`,
      headerName: "Healthy Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={PressureState.Healthy} />,
      valueGetter: (params) =>
        `${params.value.healthyFrom} - ${params.value.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.warning`,
      headerName: "Warning Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={PressureState.Warning} />,
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.alert`,
      headerName: "Alert Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={PressureState.Alert} />,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.critical`,
      headerName: "Critical Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => <TableHeader text={PressureState.Critical} />,
      valueGetter: (params) =>
        `${params.value.criticalFrom} - ${params.value.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

const getRegulatorSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix}.moving.healthy`,
      headerName: "Healthy Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Healthy + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.moving.warning`,
      headerName: "Warning Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Warning + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.moving.alert`,
      headerName: "Alert Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Alert + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.moving.critical`,
      headerName: "Critical Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Critical + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.healthy`,
      headerName: "Healthy Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Healthy + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.warning`,
      headerName: "Warning Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Warning + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.alert`,
      headerName: "Alert Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Alert + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.critical`,
      headerName: "Critical Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Critical + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const getTemperatureSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix}.internal.healthy`,
      headerName: "Healthy Temperature Level",
      type: "number",
      flex: 1,
      minWidth: 120,
      renderCell: renderValueRanges,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.internal.warning`,
      headerName: "Warning Temperature Level",
      flex: 1,
      minWidth: 120,
      renderCell: renderValueRanges,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.internal.alert`,
      headerName: "Alert Temperature Level",
      flex: 1,
      minWidth: 120,
      renderCell: renderValueRanges,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.internal.critical`,
      headerName: "Critical Temperature Level",
      type: "number",
      flex: 1,
      minWidth: 120,
      renderCell: renderValueRanges,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const getAirTankSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: "configuration.psiAirSupply.tankPressure.minSpeed",
      headerName: "Min Speed",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => params.value,
    },
    {
      field: `${fieldPrefix + "."}healthy`,
      headerName: "Healthy Pressure Level",
      type: "number",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={BatteryState.Healthy + " Air Tank Pressure"} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}warning`,
      headerName: "Warning Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={BatteryState.Warning + " Air Tank Pressure"} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}alert`,
      headerName: "Alert Pressure Level",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={BatteryState.Alert + " Air Tank Pressure"} />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}critical`,
      headerName: "Critical Pressure Level",
      type: "number",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={BatteryState.Critical + " Air Tank Pressure"} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
    //
    {
      field: `${SensorsProperties.SupplyPressure}.moving.healthy`,
      headerName: "Regulator Healthy Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Healthy + " Regulator Pressure Moving"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.moving.warning`,
      headerName: "Regulator Warning Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Warning + " Regulator Pressure Moving"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.moving.alert`,
      headerName: "Regulator Alert Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Alert + " Regulator Pressure Moving"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.moving.critical`,
      headerName: "Regulator Critical Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Critical + " Regulator Pressure Moving"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.parked.healthy`,
      headerName: "Regulator Healthy Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Healthy + " Regulator Pressure Parked"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.parked.warning`,
      headerName: "Regulator Warning Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Warning + " Regulator Pressure Parked"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.parked.alert`,
      headerName: "Regulator Alert Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Alert + " Regulator Pressure Parked"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${SensorsProperties.SupplyPressure}.parked.critical`,
      headerName: "Regulator Critical Level Parked",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader
          text={SupplyPressureState.Critical + " Regulator Pressure Parked"}
        />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
    //
  ] as GridColDef<SensorProfileResult>[];
};

export const getPsiWheelEndSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix + "."}healthy`,
      headerName: "Healthy Level",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.healthyFrom} - ${params.value.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}warning`,
      headerName: "Warning Level",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}alert`,
      headerName: "Alert Level",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix + "."}critical`,
      headerName: "Critical Level",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.criticalFrom} - ${params.value.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const getAtisSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => renderElement(params),
    },
    ...defaultCells,
    ...usedByCountCells,
    {
      field: `${fieldPrefix}.moving.healthy`,
      headerName: "Healthy Level Moving",
      type: "number",
      flex: 1,
      headerClassName: tableStyleMap.healthy,
      minWidth: 120,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Healthy + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
    },
    {
      field: `${fieldPrefix}.moving.warning`,
      headerName: "Warning Level Moving",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Warning + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.moving.alert`,
      headerName: "Alert Level Moving",
      flex: 1,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Alert + " Moving"} />
      ),
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.moving.critical`,
      headerName: "Critical Level Moving",
      type: "number",
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Critical + " Moving"} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
    },
    {
      field: `${fieldPrefix}.parked.healthy`,
      headerName: "Healthy Level Parked",
      type: "number",
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Healthy + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.healthyFrom} - ${params.value?.healthyTo}`,
    },
    {
      field: `${fieldPrefix}.parked.warning`,
      headerName: "Warning Level Parked",
      flex: 1,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Warning + " Parked"} />
      ),
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.alert`,
      headerName: "Alert Level Parked",
      flex: 1,
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Alert + " Parked"} />
      ),
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.parked.critical`,
      headerName: "Critical Level Parked",
      type: "number",
      flex: 1,
      minWidth: 120,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <TableHeader text={SupplyPressureState.Critical + " Parked"} />
      ),
      valueGetter: (params) =>
        `${params.value?.criticalFrom} - ${params.value?.criticalTo}`,
    },
  ] as GridColDef<SensorProfileResult>[];
};

export const getLiteSentryGammaSensorTableColumns = (
  fieldPrefix: string = ""
): GridColDef<SensorProfileResult | AssetWithSensors>[] => {
  return [
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.BluePowerPresent}`,
      "Brake Deviation (Blue Power Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.BluePowerNotPresent}`,
      "Brake Deviation (Blue Power Not Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.BrakeCircuitPrecheck}`,
      "Brake Deviation (Precheck)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.LeftTurnSignalTractorPower}`,
      "Left Turn Signal Deviation (Tractor Power Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.LeftTurnSignalPrecheck}`,
      "Left Turn Signal Deviation (Precheck)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.RightTurnSignalTractorPower}`,
      "Right Turn Signal Deviation (Tractor Power Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.RightTurnSignalPrecheck}`,
      "Right Turn Signal Deviation (Precheck)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.MarkerTractorPower}`,
      "Marker Deviation (Tractor Power Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.MarkerPrecheck}`,
      "Marker Deviation (Precheck)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.LicenseTractorPower}`,
      "License Deviation (Tractor Power Present)"
    ),
    ...getLiteSentryGammaSensorScenarioTableColumns(
      `${fieldPrefix}.${LiteSentryGammaScenario.LicensePrecheck}`,
      "License Deviation (Precheck)"
    ),
  ] as GridColDef<SensorProfileResult | AssetWithSensors>[];
};

export const getLiftgateSensorTableColumns = (
  fieldPrefix: string
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: `${fieldPrefix}.healthy`,
      headerName: "Healthy Battery Voltage",
      type: "number",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.healthyFrom} - ${params.value.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.warning`,
      headerName: "Warning Battery Voltage",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.warningFrom} - ${params.value?.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.alert`,
      headerName: "Alert Battery Voltage",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value?.alertFrom} - ${params.value?.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.critical`,
      headerName: "Critical Battery Voltage",
      type: "number",
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.criticalFrom} - ${params.value.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ];
};

export const getLiteSentryGammaSensorScenarioTableColumns = (
  fieldPrefix: string,
  headerNameSuffix: string
) => {
  return [
    {
      field: `${fieldPrefix}.healthy`,
      headerName: `Healthy - ${headerNameSuffix}`,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.healthyFrom} - ${params.value.healthyTo}`,
      headerClassName: tableStyleMap.healthy,
      cellClassName: tableStyleMap.healthy,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.warning`,
      headerName: `Warning - ${headerNameSuffix}`,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.warningFrom} - ${params.value.warningTo}`,
      headerClassName: tableStyleMap.warning,
      cellClassName: tableStyleMap.warning,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.alert`,
      headerName: `Alert - ${headerNameSuffix}`,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.alertFrom} - ${params.value.alertTo}`,
      headerClassName: tableStyleMap.alert,
      cellClassName: tableStyleMap.alert,
      headerAlign: "left",
      align: "left",
    },
    {
      field: `${fieldPrefix}.critical`,
      headerName: `Critical - ${headerNameSuffix}`,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        `${params.value.criticalFrom} - ${params.value.criticalTo}`,
      headerClassName: tableStyleMap.critical,
      cellClassName: tableStyleMap.critical,
      headerAlign: "left",
      align: "left",
    },
  ] as GridColDef<SensorProfileResult>[];
};

const renderElement = (params: any) => (
  <div>
    {params.value}
    {params.row.default && (
      <CheckBoxOutlinedIcon className="!text-brand ml-2" />
    )}
  </div>
);
const defaultCells = [
  {
    field: "default",
    headerName: "Default",
    flex: 1,
    minWidth: 120,
  },
];
const usedByCountCells = [
  {
    field: "usedByCount",
    headerName: "Count",
    type: "number",
    flex: 1,
    minWidth: 120,
    headerAlign: "left",
    align: "left",
  },
];

export const getProfilesTableColumns = (
  customColumns: GridColDef<SensorProfileResult>[]
): GridColDef<SensorProfileResult>[] => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => renderElement(params),
    },
    ...defaultCells,
    ...usedByCountCells,
    ...customColumns,
    {
      field: "createdAt",
      headerName: "Created Date",
      type: "date",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      type: "date",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
  ];
};

export const renderValueRanges = (params: any): JSX.Element => {
  const field: string = params?.field;
  const valueRanges = params.value as ThresholdsRange;

  if (!valueRanges) return <span>0 - 0</span>;

  if (field === "temperature.healthy" || field === "imbalance.healthy") {
    return (
      <span>
        {valueRanges.healthyFrom} - {valueRanges.healthyTo}
      </span>
    );
  }

  if (field === "temperature.warning" || field === "imbalance.warning") {
    return (
      <span>
        {valueRanges.warningFrom} - {valueRanges.warningTo}
      </span>
    );
  }

  if (field === "temperature.alert" || field === "imbalance.alert") {
    return (
      <span>
        {valueRanges.alertFrom} - {valueRanges.alertTo}
      </span>
    );
  }

  if (field === "temperature.critical" || field === "imbalance.critical") {
    return (
      <span>
        {valueRanges.criticalFrom} - {valueRanges.criticalTo}
      </span>
    );
  }

  if (field === "pressure.healthy") {
    return (
      <span>
        {valueRanges.underFrom} - {valueRanges.overTo}
      </span>
    );
  }

  if (field.startsWith("temperatureSensor.internal")) {
    const { coldSideFrom, coldSideTo, hotSideFrom, hotSideTo } =
      valueRanges as unknown as InternalTemperatureRange;
    return renderDoubleValueRanges(
      coldSideFrom,
      coldSideTo,
      hotSideFrom,
      hotSideTo
    );
  }

  return renderDoubleValueRanges(
    valueRanges.overFrom,
    valueRanges.overTo,
    valueRanges.underFrom,
    valueRanges.underTo
  );
};

export const renderDoubleValueRanges = (
  firstFrom: number,
  firstTo: number,
  secondFrom: number,
  secondTo: number
): JSX.Element => {
  return (
    <div className="flex justify-evenly gap-4 items-center">
      <div className="flex items-center gap-4">
        {firstFrom} - {firstTo}
        <ArrowUpwardIcon fontSize="small" className="text-light-charcoal" />
      </div>
      <div className="flex items-center gap-4">
        {secondFrom} - {secondTo}
        <ArrowDownwardIcon fontSize="small" className="text-light-charcoal" />
      </div>
    </div>
  );
};

export const pressureTableColumns: GridColDef<SensorProfileResult>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => renderElement(params),
  },
  ...defaultCells,
  ...usedByCountCells,
  ...generateSensorColumns(
    SensorsProperties.Temperature,
    sensorStates,
    "Temperature"
  ),
  ...generateSensorColumns(
    SensorsProperties.Pressure,
    sensorStates,
    "Pressure"
  ),
  ...generateSensorColumns(
    SensorsProperties.Imbalance,
    sensorStates,
    "Dual Imbalance"
  ),
];

export const batteryTableColumns = getProfilesTableColumns(
  getBatterySensorTableColumns(SensorsProperties.Battery)
);

export const temperatureTableColumns = getProfilesTableColumns(
  getTemperatureSensorTableColumns(SensorsProperties.temperatureSensor)
);

export const batteryTableKeyList: string[] = batteryTableColumns.map(
  (column) => column.field
);

export const pressureTableKeyList: string[] = pressureTableColumns.map(
  (column) => column.field
);

export const airBagTableColumns = getProfilesTableColumns(
  getAirBagSensorTableColumns(SensorsProperties.Airbag)
);

export const airBagTableKeyList: string[] = airBagTableColumns.map(
  (column) => column.field
);

export const regulatorTableColumns = getProfilesTableColumns(
  getRegulatorSensorTableColumns(SensorsProperties.SupplyPressure)
);

export const regulatorTableKeyList: string[] = regulatorTableColumns.map(
  (column) => column.field
);
export const temperatureTableKeyList: string[] = temperatureTableColumns.map(
  (column) => column.field
);

export const airTankTableKeyList: string[] = getProfilesTableColumns(
  getAirTankSensorTableColumns(SensorsProperties.TankPressure)
).map((column) => column.field);

export const psiWheelEndTableColumns = getProfilesTableColumns(
  getPsiWheelEndSensorTableColumns(SensorsProperties.PsiWheelEnd)
);

export const liteSentryGammaTableColumns = getProfilesTableColumns(
  getLiteSentryGammaSensorTableColumns(
    SensorsProperties.LiteSentryGamma
  ) as GridColDef<SensorProfileResult>[]
);

export const liftgateTableColumns = getProfilesTableColumns(
  getLiftgateSensorTableColumns(
    SensorsProperties.Liftgate
  ) as GridColDef<SensorProfileResult>[]
);

export const liftgateTableKeyList: string[] = liftgateTableColumns.map(
  (column) => column.field
);

export const atisTableColumns = getAtisSensorTableColumns(
  SensorsProperties.Atis
);

export const psiWheelEndTableKeyList: string[] = psiWheelEndTableColumns.map(
  (column) => column.field
);

export const liteSentryGammaTableKeyList: string[] =
  liteSentryGammaTableColumns.map((column) => column.field);

export const atisTableKeyList = atisTableColumns.map((column) => column.field);

export const columnVisibilityModel = {
  default: false,
};

export const mapSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.voltage?.battery)
    .map((profile) => {
      const sensorData = {} as SensorData;

      sensorData.batterySensors = profile?.configuration?.voltage
        ?.battery as SensorThresholds;

      return {
        ...profile,
        ...sensorData,
      };
    });
  return filteredAndMappedProfiles;
};

export const mapTireSensorProfileData = (
  profiles: SensorProfileResult[],
  pressureUnit: PressureUnit
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter(
      (profile) =>
        profile?.configuration?.tpmsBeta?.temperature &&
        profile?.configuration?.tpmsBeta?.pressure &&
        profile?.configuration?.tpmsBeta?.imbalance
    )
    .map((profile) => {
      const sensorData = {} as SensorData;

      if (profile?.configuration?.tpmsBeta?.temperature) {
        sensorData.temperature = profile.configuration.tpmsBeta
          .temperature as SensorThresholds;
      }
      if (profile?.configuration?.tpmsBeta?.pressure) {
        const { healthy, warning, alert, critical } = profile.configuration
          .tpmsBeta.pressure as PressureSensorThresholds;

        sensorData.pressure = {
          healthy: convertPressureThresholdsRange<ThresholdsRange>(
            healthy,
            pressureUnit
          ),
          warning: convertPressureThresholdsRange<ThresholdsRange>(
            warning,
            pressureUnit
          ),
          alert: convertPressureThresholdsRange<ThresholdsRange>(
            alert,
            pressureUnit
          ),
          critical: convertPressureThresholdsRange<ThresholdsRange>(
            critical,
            pressureUnit
          ),
        };
      }
      if (profile?.configuration?.tpmsBeta?.imbalance) {
        const { healthy, warning, alert, critical } = profile.configuration
          .tpmsBeta.imbalance as SensorThresholds;

        sensorData.imbalance = {
          healthy: convertPressureThresholdsRange<{
            healthyFrom: number;
            healthyTo: number;
          }>(healthy, pressureUnit),
          warning: convertPressureThresholdsRange<{
            warningFrom: number;
            warningTo: number;
          }>(warning, pressureUnit),
          alert: convertPressureThresholdsRange<{
            alertFrom: number;
            alertTo: number;
          }>(alert, pressureUnit),
          critical: convertPressureThresholdsRange<{
            criticalFrom: number;
            criticalTo: number;
          }>(critical, pressureUnit),
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapTemperatureSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.temperature?.internal)
    .map((profile) => {
      const sensorData = {} as SensorData;

      if (profile?.configuration?.temperature?.internal !== undefined) {
        sensorData.temperatureSensor = {
          internal: profile.configuration.temperature
            .internal as InternalTemperatureSensorThresholds,
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });
  return filteredAndMappedProfiles;
};

export const mapAirBagSensorProfileData = (
  profiles: SensorProfileResult[],
  pressureUnit: PressureUnit
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.airbag?.measures)
    .map((profile) => {
      const sensorData = {} as SensorData;
      if (profile?.configuration?.airbag?.measures) {
        const { healthy, warning, alert, critical } = profile.configuration
          .airbag.measures as SensorThresholds;

        sensorData.airbag = {
          healthy: convertPressureThresholdsRange<{
            healthyFrom: number;
            healthyTo: number;
          }>(healthy, pressureUnit),
          warning: convertPressureThresholdsRange<{
            warningFrom: number;
            warningTo: number;
          }>(warning, pressureUnit),
          alert: convertPressureThresholdsRange<{
            alertFrom: number;
            alertTo: number;
          }>(alert, pressureUnit),
          critical: convertPressureThresholdsRange<{
            criticalFrom: number;
            criticalTo: number;
          }>(critical, pressureUnit),
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapSupplyPressureSensorProfileData = (
  profiles: SensorProfileResult[],
  pressureUnit: PressureUnit
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter(
      (profile) =>
        profile?.configuration?.psiAirSupply?.supplyPressure?.parked ||
        profile?.configuration?.psiAirSupply?.supplyPressure?.moving ||
        profile?.configuration?.psiAirSupply?.tankPressure
    )
    .map((profile) => {
      const sensorData = {} as SensorData;
      const supplyPressure: {
        parked?: SensorThresholds;
        moving?: SensorThresholds;
      } = {};
      if (profile?.configuration?.psiAirSupply?.supplyPressure?.parked) {
        const { healthy, warning, alert, critical } = profile.configuration
          .psiAirSupply.supplyPressure.parked as SensorThresholds;

        supplyPressure.parked = {
          healthy: convertPressureThresholdsRange<{
            healthyFrom: number;
            healthyTo: number;
          }>(healthy, pressureUnit),
          warning: convertPressureThresholdsRange<{
            warningFrom: number;
            warningTo: number;
          }>(warning, pressureUnit),
          alert: convertPressureThresholdsRange<{
            alertFrom: number;
            alertTo: number;
          }>(alert, pressureUnit),
          critical: convertPressureThresholdsRange<{
            criticalFrom: number;
            criticalTo: number;
          }>(critical, pressureUnit),
        };
      }

      if (
        profile?.configuration?.psiAirSupply?.supplyPressure?.moving !==
        undefined
      ) {
        const { healthy, warning, alert, critical } = profile.configuration
          .psiAirSupply.supplyPressure.moving as SensorThresholds;

        supplyPressure.moving = {
          healthy: convertPressureThresholdsRange<{
            healthyFrom: number;
            healthyTo: number;
          }>(healthy, pressureUnit),
          warning: convertPressureThresholdsRange<{
            warningFrom: number;
            warningTo: number;
          }>(warning, pressureUnit),
          alert: convertPressureThresholdsRange<{
            alertFrom: number;
            alertTo: number;
          }>(alert, pressureUnit),
          critical: convertPressureThresholdsRange<{
            criticalFrom: number;
            criticalTo: number;
          }>(critical, pressureUnit),
        };
      }
      const tankPressure = profile?.configuration?.psiAirSupply?.tankPressure;
      if (tankPressure !== undefined && tankPressure !== null) {
        sensorData.tankPressure = tankPressure as SensorThresholdShort;
      }
      sensorData.supplyPressure = supplyPressure;

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapAirTankSensorProfileData = (
  profiles: SensorProfileResult[],
  pressureUnit: PressureUnit
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.psiAirSupply?.tankPressure)
    .map((profile) => {
      const sensorData = {} as SensorData;
      if (profile?.configuration?.psiAirSupply?.tankPressure) {
        const { healthy, warning, alert, critical } = profile.configuration
          .psiAirSupply.tankPressure as SensorThresholds;

        sensorData.tankPressure = {
          healthy: convertPressureThresholdsRange<{
            healthyFrom: number;
            healthyTo: number;
          }>(healthy, pressureUnit),
          warning: convertPressureThresholdsRange<{
            warningFrom: number;
            warningTo: number;
          }>(warning, pressureUnit),
          alert: convertPressureThresholdsRange<{
            alertFrom: number;
            alertTo: number;
          }>(alert, pressureUnit),
          critical: convertPressureThresholdsRange<{
            criticalFrom: number;
            criticalTo: number;
          }>(critical, pressureUnit),
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapPsiWheelEndSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter(
      (profile) => profile?.configuration?.psiWheelEnd?.temperature?.healthy
    )
    .map((profile) => {
      const sensorData = {} as SensorData;
      if (profile?.configuration?.psiWheelEnd?.temperature !== undefined) {
        sensorData.psiWheelEnd = profile?.configuration?.psiWheelEnd
          ?.temperature as SensorThresholds;
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapLiteSentryGammaSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.liteSentryGamma)
    .map((profile) => {
      const sensorData = {} as SensorData;
      const { liteSentryGamma } = profile.configuration;

      sensorData.liteSentryGamma = {
        scenario_0: liteSentryGamma?.scenario_0,
        scenario_1: liteSentryGamma?.scenario_1,
        scenario_2: liteSentryGamma?.scenario_2,
        scenario_3: liteSentryGamma?.scenario_3,
        scenario_4: liteSentryGamma?.scenario_4,
        scenario_5: liteSentryGamma?.scenario_5,
        scenario_6: liteSentryGamma?.scenario_6,
        scenario_7: liteSentryGamma?.scenario_7,
        scenario_8: liteSentryGamma?.scenario_8,
        scenario_9: liteSentryGamma?.scenario_9,
        scenario_10: liteSentryGamma?.scenario_10,
      } as LiteSentryGammaThresholds;

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const mapLiftgateSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter((profile) => profile?.configuration?.liftgate)
    .map((profile) => {
      const sensorData = {} as SensorData;
      if (profile?.configuration?.liftgate) {
        const { healthy, warning, alert, critical } = profile.configuration
          .liftgate as SensorThresholds;

        sensorData.liftgate = {
          healthy: {
            healthyFrom: healthy.healthyFrom,
            healthyTo: healthy.healthyTo,
          },
          warning: {
            warningFrom: warning.warningFrom,
            warningTo: warning.warningTo,
          },
          alert: {
            alertFrom: alert.alertFrom,
            alertTo: alert.alertTo,
          },
          critical: {
            criticalFrom: critical.criticalFrom,
            criticalTo: critical.criticalTo,
          },
        };
      }

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const buildSensorDataForLiteSentryGammaScenario = (
  parsedScenarioData: SensorRangesSettingsValues
): SensorThresholds => {
  return {
    critical: {
      criticalFrom:
        parsedScenarioData.criticalFrom ??
        MinValuesBySensorType.liteSentryGamma,
      criticalTo: parsedScenarioData.criticalTo,
    },
    alert: {
      alertFrom: parsedScenarioData.alertFrom,
      alertTo: parsedScenarioData.alertTo,
    },
    warning: {
      warningFrom: parsedScenarioData.warningFrom,
      warningTo: parsedScenarioData.warningTo,
    },
    healthy: {
      healthyFrom: parsedScenarioData.healthyFrom,
      healthyTo:
        parsedScenarioData.healthyTo ?? MaxValuesBySensorType.liteSentryGamma,
    },
  };
};

export const mapAtisSensorProfileData = (
  profiles: SensorProfileResult[]
): MappedSensorProfile[] => {
  const filteredAndMappedProfiles = profiles
    .filter(
      (profile) =>
        profile?.configuration?.atisAlpha?.lightActivatedSeconds?.parked ||
        profile?.configuration?.atisAlpha?.lightActivatedSeconds?.moving
    )
    .map((profile) => {
      const sensorData = {} as SensorData;
      const parked =
        profile?.configuration?.atisAlpha?.lightActivatedSeconds?.parked;
      const moving =
        profile?.configuration?.atisAlpha?.lightActivatedSeconds?.moving;
      const atisAlpha = {
        parked,
        moving,
      };

      sensorData.atisAlpha = atisAlpha;

      return {
        ...profile,
        ...sensorData,
      };
    });

  return filteredAndMappedProfiles;
};

export const sensorsCounter = (
  profilesData: SensorProfileResult[],
  sensorType: SensorProfileConfigType | ProfileConfigProperty
): number => {
  switch (sensorType) {
    case ProfileConfigProperty.Battery:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.voltage?.battery)
      ).length;
    case SensorProfileConfigType.TpmsBeta:
      return profilesData.filter((profile) =>
        Boolean(
          profile?.configuration?.tpmsBeta?.temperature &&
            profile?.configuration?.tpmsBeta?.pressure &&
            profile?.configuration?.tpmsBeta?.imbalance
        )
      ).length;
    case SensorProfileConfigType.Airbag:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.airbag?.measures)
      ).length;
    case ProfileConfigProperty.AirSupply:
      return profilesData.filter((profile) =>
        Boolean(
          profile?.configuration?.psiAirSupply?.supplyPressure &&
            profile?.configuration?.psiAirSupply?.tankPressure
        )
      ).length;
    case SensorProfileConfigType.CargoCamera:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.cargoCamera)
      ).length;
    case SensorProfileConfigType.Temperature:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.temperature)
      ).length;
    case SensorProfileConfigType.PsiWheelEnd:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.psiWheelEnd?.temperature)
      ).length;
    case SensorProfileConfigType.AtisAlpha:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.atisAlpha?.lightActivatedSeconds)
      ).length;
    case SensorProfileConfigType.LiteSentryGamma:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.liteSentryGamma)
      ).length;
    case SensorProfileConfigType.Liftgate:
      return profilesData.filter((profile) =>
        Boolean(profile?.configuration?.liftgate)
      ).length;
    default:
      return 0;
  }
};
