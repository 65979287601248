import React from "react";
import { AlertReminderV2, Maybe } from "../../../graphql/operations";
import { Paused, Running } from "../Svg";

export type SpinnerProps = {
  text: string;
  bgColor: string;
  maxWidth: number;
  icon: React.ReactNode;
};

export const getStatus = (reminder?: Maybe<AlertReminderV2>) => {
  let active;
  if (reminder) {
    active = reminder?.schedule?.suspended ? false : true;
  }
  const bgColor = active ? "bg-green" : "bg-dark-grey";
  const text = active ? "Running" : "Paused";
  const icon = active ? <Running /> : <Paused />;
  const maxWidth = active ? 103 : 95;
  return (
    <Status bgColor={bgColor} icon={icon} text={text} maxWidth={maxWidth} />
  );
};

const Status = ({ text, bgColor, icon, maxWidth }: SpinnerProps) => (
  <div
    data-testid="status"
    className={`${bgColor} text-white font-bold py-1 px-4 rounded text-xs flex items-center justify-center w-full max-w-[${maxWidth}px]`}
  >
    <span className="mr-1" data-testid="status-icon">
      {icon}
    </span>
    <span>{text}</span>
  </div>
);

export default Status;
