import { useCallback, useEffect, useState } from "react";
import { Card, CircularProgress, Typography } from "@mui/material";
import { Bar, Tooltip } from "recharts";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import VerticalBarChartWidget from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget";
import { BarLabel } from "../../../../../shared/components/DashboardWidgets/VerticalBarChartWidget/components/BarLabel";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import {
  dateToExactDateTimeStringNoTz,
  getEndOfDay,
  getStartOfDay,
} from "../../../../../utils";
import { formatNumber } from "../../../../../utils/formatters";
import {
  DEFAULT_DASHBOARD_END_DATE,
  DEFAULT_DASHBOARD_START_DATE,
} from "../../shared";
import {
  formatDateIntoLabel,
  formatDateIntoLabelForTooltip,
  hasNoPermission,
} from "../../shared/utils";

export interface NewUsersPerPeriodCount {
  date: string;
  value: number;
}
export type DashboardWidgetNewUsersPerPeriodData = {
  newUsersPerDay: NewUsersPerPeriodCount[];
};

export interface DashboardWidgetNewUsersPerPeriod
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetNewUsersPerPeriodData;
}

export interface NewUsersPerPeriodWidgetProps {
  widget: DashboardWidgetNewUsersPerPeriod;
}

export const NewUsersPerPeriodWidget: React.FC<
  NewUsersPerPeriodWidgetProps
> = ({ widget }) => {
  const isDarkMode = useCurrentTheme().palette.mode === "dark";

  const [startDate, setStartDate] = useState<Date>(
    DEFAULT_DASHBOARD_START_DATE
  );
  const [endDate, setEndDate] = useState<Date>(DEFAULT_DASHBOARD_END_DATE);
  const [data, setData] = useState<
    NewUsersPerPeriodWidgetProps["widget"]["data"]["newUsersPerDay"]
  >(widget?.data?.newUsersPerDay || []);
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetAlertsPerDayQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        orgId: currentOrg?._id ?? "",
        widgetId: widget?.id,
        // Note: prevents the AWSDateTime input type resolver from casting to Date again and altering the desired date
        fromDate: dateToExactDateTimeStringNoTz(startDate),
        toDate: dateToExactDateTimeStringNoTz(endDate),
      },
    },
    {
      enabled: Boolean(widget?.id) && Boolean(currentOrg),
    }
  );

  useEffect(() => {
    if (getDashboardWidgetData?.getDashboardWidget?.data) {
      const parsedData = JSON.parse(
        getDashboardWidgetData.getDashboardWidget.data
      ) as DashboardWidgetNewUsersPerPeriodData;

      setData(parsedData.newUsersPerDay);
    }
  }, [getDashboardWidgetData]);

  const handleDateChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      if (startDate && endDate) {
        // They come in as dates and we need exact datetime strings
        setStartDate(getStartOfDay(startDate));
        setEndDate(getEndOfDay(endDate));
      }
    },
    [setStartDate, setEndDate]
  );

  return (
    <Card
      data-testid="dashboard-widget--new-users-per-period"
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetAlertsPerDayQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
    >
      {data && !dashboardWidgetAlertsPerDayQueryError && (
        <VerticalBarChartWidget
          /*
              startDate and endDate need to be converted to dates again
              to match the requirements of the DateRangePicker
            */
          startDate={startDate}
          isLoading={isLoading}
          endDate={endDate}
          xAxisTickFormatter={formatDateIntoLabel}
          onDateFilterChange={handleDateChange}
          title={widget.name ?? ""}
          yAxisLabel="Number Of Users"
          xAsisKey="date"
          barKey="value"
          data={data}
          isDateFilterEnabled
          yAxisLabelColor={isDarkMode ? ColorsPalette.BrightWhite : undefined}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        >
          <Bar
            fill="var(--rechartsOrange)"
            dataKey="value"
            maxBarSize={70}
            label={<BarLabel />}
          ></Bar>
          <Tooltip
            formatter={(value) => [formatNumber(value as number), "Users"]}
            labelFormatter={formatDateIntoLabelForTooltip}
            labelStyle={{
              color: "black",
            }}
            cursor={false}
          />
        </VerticalBarChartWidget>
      )}
      {Boolean(dashboardWidgetAlertsPerDayQueryError) && (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the New Users Per Period
          chart! Please try again later.
        </Typography>
      )}
    </Card>
  );
};
