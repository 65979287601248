import { FC, useMemo, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  CircularProgress,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { last } from "lodash";
import { VOLTAGE_CHART_DATE_RANGE_OPTIONS } from "../../../../../constants/map";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import {
  AssetUiConfig,
  useFindAssetByIdQuery,
} from "../../../../../graphql/operations";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import {
  getEndOfToday,
  getStartOfDay,
  getSubDays,
  getSubMonths,
} from "../../../../../utils/date";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import AssetErrorMessage from "../../Shared/AssetsErrorMessage";
import BatteryChart from "../../Shared/BatteryChart";
import BatteryWidget from "../../Shared/BatteryWidget";
import { useBatteryChartsBatteryOnlyData } from "./hooks/useBatteryChartsBatteryOnlyData";
import { useBatteryChartsData } from "./hooks/useBatteryChartsData";
import { BATTERY_CHART_NAME } from "./types";

export const BatteryTabPanel: FC = () => {
  const isMobile = useBreakpoint("down", "sm");
  const muiTheme = useCurrentTheme();
  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(muiTheme, {
          palette: {
            primary: {
              main: ColorsPalette.PrimaryBlue,
            },
          },
          components: {
            MuiTextField: {
              defaultProps: {
                variant: "outlined",
              },
            },
            MuiFormHelperText: {
              defaultProps: {
                variant: "outlined",
              },
            },
            MuiInputLabel: {
              defaultProps: {
                variant: "outlined",
              },
            },
          },
        })
      ),
    [muiTheme]
  );

  const { selectedAssetId } = useAssetsDataContext();

  const [startDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState<Date | null>(getEndOfToday());

  const [startDateOnlyBattery, setStartDateOnlyBattery] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDateOnlyBattery, setEndDateOnlyBattery] = useState<Date | null>(
    getEndOfToday()
  );

  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(
    VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value
  );
  const [
    selectedDateRangeOptionOnlyBattery,
    setSelectedDateRangeOptionOnlyBattery,
  ] = useState(VOLTAGE_CHART_DATE_RANGE_OPTIONS[0].value);

  const { data: assetData } = useFindAssetByIdQuery({
    assetId: selectedAssetId ?? "",
  });

  const selectedAsset = useMemo(
    () => assetData?.findAssetById ?? null,
    [assetData]
  );

  const voltageData = selectedAsset?.sensors?.voltage;
  const lastReportedDate = voltageData?.readingDate
    ? new Date(voltageData?.readingDate)
    : null;

  const setLastDateRangeOption = () => {
    setSelectedDateRangeOption(last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!);
  };

  const {
    chartData: batteryChartData,
    isLoadingVoltageOsData,
    isFetchingVoltageOsData,
    isSuccessVoltageOsData,
  } = useBatteryChartsData({
    imei: selectedAsset?.imei ?? "",
    startDate,
    endDate,
    uiConfig: selectedAsset?.uiConfig as AssetUiConfig,
  });

  const {
    chartBatteryOnlyData,
    isLoadingVoltageBatteryOnlyOsData,
    isFetchingVoltageBatteryOnlyOsData,
    isSuccessVoltageBatteryOnlyOsData,
  } = useBatteryChartsBatteryOnlyData({
    imei: selectedAsset?.imei ?? "",
    startDate: startDateOnlyBattery,
    endDate: endDateOnlyBattery,
    uiConfig: selectedAsset?.uiConfig as AssetUiConfig,
  });

  const handleDateRangeChange = (
    value: number,
    chartName: BATTERY_CHART_NAME
  ) => {
    const option = VOLTAGE_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    if (option && chartName !== BATTERY_CHART_NAME.Battery) {
      setSelectedDateRangeOption(value);
      if (option.getRange) {
        const range = option.getRange();
        setStartDate(range[0]);
        setEndDate(range[1]);
      }
    }

    if (option && chartName === BATTERY_CHART_NAME.Battery) {
      setSelectedDateRangeOptionOnlyBattery(value);
      if (option.getRange) {
        const range = option.getRange();
        setStartDateOnlyBattery(range[0]);
        setEndDateOnlyBattery(range[1]);
      }
    }
  };

  const isLoading = isLoadingVoltageOsData || isLoadingVoltageBatteryOnlyOsData;
  const isSuccess = isSuccessVoltageOsData || isSuccessVoltageBatteryOnlyOsData;
  const isFetching =
    isFetchingVoltageOsData || isFetchingVoltageBatteryOnlyOsData;

  return (
    <Box className="flex h-full flex-col gap-4" data-testid="battery-tab-panel">
      {isLoading && isFetching ? (
        <Box className="flex h-full w-full items-center justify-center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isSuccess ? (
            <AssetErrorMessage />
          ) : (
            <>
              <Grid container spacing={2}>
                {/* true & null || undefined counts as visible */}
                {selectedAsset?.uiConfig?.power?.battery !== false && (
                  <Grid item xl={3} lg={6} xs={12}>
                    <BatteryWidget
                      label="Battery"
                      voltage={voltageData?.data?.battery}
                      unit="V"
                      isStatusVisible
                      date={lastReportedDate}
                      batteryStatusString={
                        selectedAsset?.sensors?.voltage?.data?.statusBattery ??
                        ""
                      }
                    />
                  </Grid>
                )}
                {/* true & null || undefined counts as visible */}
                {selectedAsset?.uiConfig?.power?.main !== false && (
                  <Grid item xl={3} lg={6} xs={12}>
                    <BatteryWidget
                      label="Primary Voltage"
                      voltage={voltageData?.data?.main}
                      unit="V"
                      date={lastReportedDate}
                    />
                  </Grid>
                )}
                {/* true & null || undefined counts as visible */}
                {selectedAsset?.uiConfig?.power?.auxiliary !== false && (
                  <Grid item xl={3} lg={6} xs={12}>
                    <BatteryWidget
                      label="Secondary Voltage"
                      voltage={voltageData?.data?.auxiliary}
                      unit="V"
                      date={lastReportedDate}
                    />
                  </Grid>
                )}
                {/* true & null || undefined counts as visible */}
                {selectedAsset?.uiConfig?.power?.solarAmperage !== false && (
                  <Grid item xl={3} lg={6} xs={12}>
                    <BatteryWidget
                      label="Solar"
                      voltage={voltageData?.data?.solarAmperage}
                      unit="A"
                      date={lastReportedDate}
                    />
                  </Grid>
                )}
              </Grid>

              {!isMobile && (
                <ThemeProvider theme={theme}>
                  <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
                    <Typography className="!text-lg !font-semibold text-brand">
                      Trending Voltage
                    </Typography>
                    <Typography
                      className="!text-xs !font-medium text-asset-info-subheader"
                      data-testid="trending-voltage"
                    >
                      Trending battery data over time
                    </Typography>
                    <Box className="flex items-center justify-end gap-4 py-4">
                      <MobileDatePicker
                        label="Start date"
                        inputFormat="MM/dd/yyyy"
                        closeOnSelect
                        value={startDate}
                        shouldDisableDate={(day) =>
                          day < getStartOfDay(getSubMonths(new Date(), 6))
                        }
                        disableFuture
                        onChange={(date) => {
                          setStartDate(date);
                          setLastDateRangeOption();
                        }}
                        DialogProps={{
                          className: "trending-voltage-date-picker",
                        }}
                        renderInput={(params) => (
                          <TextField
                            data-testid="voltage-chart-start-date"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                      <span className="text-base font-normal text-primary">
                        to
                      </span>
                      <MobileDatePicker
                        DialogProps={{
                          className: "trending-voltage-date-picker",
                        }}
                        label="End date"
                        closeOnSelect
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        shouldDisableDate={(day) =>
                          !!startDate && day < startDate
                        }
                        disableFuture
                        onChange={(date) => {
                          setEndDate(date);
                          setLastDateRangeOption();
                        }}
                        renderInput={(params) => (
                          <TextField
                            data-testid="voltage-chart-end-date"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />

                      <TextField
                        data-testid="voltage-chart-date-range-select"
                        select
                        value={selectedDateRangeOption}
                        variant="outlined"
                        className="w-40"
                        onChange={(e) =>
                          handleDateRangeChange(
                            +e.target.value,
                            BATTERY_CHART_NAME.AllChart
                          )
                        }
                      >
                        {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            data-testid={`voltage-chart-date-range-select-option-${option.value}`}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <BatteryChart
                      data={batteryChartData}
                      xKey="date"
                      lineKeys={[
                        selectedAsset?.uiConfig?.power?.main !== false
                          ? "Primary Voltage"
                          : "",
                        selectedAsset?.uiConfig?.power?.auxiliary !== false
                          ? "Secondary Voltage"
                          : "",
                        selectedAsset?.uiConfig?.power?.solarAmperage !== false
                          ? "Solar"
                          : "",
                      ]}
                    />
                  </Box>

                  {/* only battery chart */}
                  {/* true & null || undefined counts as visible in the uiConfig */}
                  {chartBatteryOnlyData.length > 0 &&
                    selectedAsset?.uiConfig?.power?.battery !== false && (
                      <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
                        <Typography className="!text-lg !font-semibold text-brand">
                          Trending Battery Voltage
                        </Typography>
                        <Typography className="!text-xs !font-medium text-asset-info-subheader">
                          Trending battery data over time
                        </Typography>
                        <Box className="flex items-center justify-end gap-4 py-4">
                          <MobileDatePicker
                            label="Start date"
                            inputFormat="MM/dd/yyyy"
                            closeOnSelect
                            value={startDateOnlyBattery}
                            shouldDisableDate={(day) =>
                              day < getStartOfDay(getSubMonths(new Date(), 6))
                            }
                            disableFuture
                            onChange={(date) => {
                              setStartDateOnlyBattery(date);
                              setSelectedDateRangeOptionOnlyBattery(
                                last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!
                              );
                            }}
                            DialogProps={{
                              className: "trending-voltage-date-picker",
                            }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="battery-chart-start-date"
                                variant="outlined"
                                {...params}
                              />
                            )}
                          />
                          <span className="text-base font-normal text-primary">
                            to
                          </span>
                          <MobileDatePicker
                            DialogProps={{
                              className: "trending-voltage-date-picker",
                            }}
                            label="End date"
                            closeOnSelect
                            inputFormat="MM/dd/yyyy"
                            value={endDateOnlyBattery}
                            shouldDisableDate={(day) =>
                              !!startDateOnlyBattery &&
                              day < startDateOnlyBattery
                            }
                            disableFuture
                            onChange={(date) => {
                              setEndDateOnlyBattery(date);
                              setSelectedDateRangeOptionOnlyBattery(
                                last(VOLTAGE_CHART_DATE_RANGE_OPTIONS)?.value!
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="battery-chart-end-date"
                                variant="outlined"
                                {...params}
                              />
                            )}
                          />

                          <TextField
                            data-testid="battery-chart-date-range-select"
                            select
                            value={selectedDateRangeOptionOnlyBattery}
                            variant="outlined"
                            className="w-40"
                            onChange={(e) =>
                              handleDateRangeChange(
                                +e.target.value,
                                BATTERY_CHART_NAME.Battery
                              )
                            }
                          >
                            {VOLTAGE_CHART_DATE_RANGE_OPTIONS.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                data-testid={`battery-chart-date-range-select-option-${option.value}`}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        <BatteryChart
                          data={chartBatteryOnlyData}
                          xKey="date"
                          lineKeys={["Battery"]}
                          typeChart={BATTERY_CHART_NAME.Battery}
                        />
                      </Box>
                    )}
                </ThemeProvider>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
