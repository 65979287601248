import { useCookies } from "react-cookie";
import { SELECTED_ORGANIZATION } from "../../constants";
import { DEFAULT_TIMEZONE } from "../../utils";
import { useUserData } from "./useUserData";

export const usePreferredTimezone = (useUserOrgTimezone = false) => {
  const userData = useUserData();
  const [orgCookie] = useCookies([SELECTED_ORGANIZATION]);

  if (
    orgCookie?.SELECTED_ORGANIZATION &&
    orgCookie?.SELECTED_ORGANIZATION?.timezone &&
    !useUserOrgTimezone
  ) {
    return orgCookie.SELECTED_ORGANIZATION.timezone;
  }

  if (useUserOrgTimezone) {
    return userData?.customerOrg?.time_zones ?? DEFAULT_TIMEZONE;
  }

  return (
    userData?.user_preferences?.time_zones ??
    userData?.customerOrg?.time_zones ??
    DEFAULT_TIMEZONE
  );
};
