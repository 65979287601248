export default {
  active: {
    count: 0,
    title: "Active",
    description: "Active assets",
  },
  parked: {
    count: 0,
    title: "Parked",
    description: "Less 24 Hrs",
  },
  lowDwell: {
    count: 0,
    title: "Low Dwell",
    description: "0-3 Days",
  },
  mediumDwell: {
    count: 0,
    title: "Medium Dwell",
    description: "4-7 Days",
  },
  highDwell: {
    count: 0,
    title: "High Dwell",
    description: "8-29 Days",
  },
  megaDwell: {
    count: 0,
    title: "Mega Dwell",
    description: "30+ Days",
  },
};
