import { FC, memo, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { PAGE_SNACKBAR } from "../../../constants";
import { DEVICE_EVENT_LOAD_FAILED_PAYLOAD } from "../../../constants/device";
import { useAppContext } from "../../../context/AppContext";
import {
  DeviceEvents,
  useFindDeviceEventsQuery,
} from "../../../graphql/operations";
import { Table, getRows } from "../../../shared/components/Table";
import { useExportedFileName } from "../../../shared/hooks/useExportedFileName";
import {
  SEARCH_KEYS,
  columnVisibilityModel,
  DEVICE_HISTORY_COLUMNS,
} from "./constants";
import { getSortedDeviceEventsForTable } from "./utils";

export interface DeviceHistoryTableProps {
  device_id: string | undefined;
  useExtendedSearch: boolean;
  searchThreshold: number;
}

const DeviceHistoryTable: FC<DeviceHistoryTableProps> = ({
  device_id,
  useExtendedSearch,
  searchThreshold,
}) => {
  const {
    state: { appConfig },
    dispatch,
  } = useAppContext();

  const fileName = useExportedFileName("Device History");

  // search start
  const [rows, setRows] = useState<DeviceEvents[]>();
  const devicesGridApiRef = useGridApiRef();

  const {
    data: findDeviceEvents,
    isSuccess,
    isLoading,
  } = useFindDeviceEventsQuery(
    { input: { device_id: device_id ?? "" } },
    {
      enabled: Boolean(device_id),
      cacheTime: 0,
      onError: (error: Error) => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: DEVICE_EVENT_LOAD_FAILED_PAYLOAD,
        });
      },
    }
  );

  const deviceEvents = useMemo(
    () => findDeviceEvents?.findDeviceEvents?.events ?? [],
    [findDeviceEvents]
  );

  useEffect(() => {
    const sortedEventsForTable = getSortedDeviceEventsForTable(deviceEvents);
    setRows(
      getRows<typeof sortedEventsForTable>(
        sortedEventsForTable,
        DEVICE_HISTORY_COLUMNS,
        "",
        "",
        "_id"
      )
    );
  }, [deviceEvents]);

  return (
    <Box className="pb-3 h-[28rem] w-full">
      <Table
        tableName="device-history"
        columns={DEVICE_HISTORY_COLUMNS}
        rows={rows || []}
        apiRef={devicesGridApiRef}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        //showToolbar
        columnVisibilityModel={columnVisibilityModel}
        className="w-full border-solid"
        enableSearch={false}
        searchKeys={SEARCH_KEYS}
        searchMinLength={appConfig.searchMinLength}
        isPopOver
        useExtendedSearch={useExtendedSearch}
        searchThreshold={searchThreshold}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
      />
    </Box>
  );
};

export default memo(DeviceHistoryTable);
