import cloneDeep from "lodash/cloneDeep";
import { AssetsSummary } from "../../../../graphql/operations";
import legendObject from "./legend";

export function calculateAssetsActivity(assetsSummary: AssetsSummary) {
  const currentMetrics = cloneDeep(legendObject);
  if (assetsSummary.moving) currentMetrics.active.count = assetsSummary.moving;
  if (assetsSummary.parked) currentMetrics.parked.count = assetsSummary.parked;
  if (assetsSummary.dwell?.low)
    currentMetrics.lowDwell.count = assetsSummary.dwell.low;
  if (assetsSummary.dwell?.medium)
    currentMetrics.mediumDwell.count = assetsSummary.dwell.medium;
  if (assetsSummary.dwell?.high)
    currentMetrics.highDwell.count = assetsSummary.dwell.high;
  if (assetsSummary.dwell?.mega)
    currentMetrics.megaDwell.count = assetsSummary.dwell.mega;

  return currentMetrics;
}
