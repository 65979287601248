import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as CargoVisionSensorDark } from "../../../../../../../assets/svgs/cargoVisionSensorDark.svg";
import { ReactComponent as CargoVisionSensorLight } from "../../../../../../../assets/svgs/cargoVisionSensorLight.svg";
import { useAppContext } from "../../../../../../../context/AppContext";

export interface InternalCameraStatusHeaderProps {}

const Header: FC<InternalCameraStatusHeaderProps> = () => {
  const {
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";

  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretched"
      className="pb-4"
      data-testid="internal-camera-status-header"
    >
      <Box className="!mb-1 justify w-full">
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Box data-testid="internal-camera-icon">
            {isLightTheme ? (
              <CargoVisionSensorDark style={svgIconSettings} />
            ) : (
              <CargoVisionSensorLight style={svgIconSettings} />
            )}
          </Box>
          <Box>
            <Typography
              variant="caption"
              className="!pl-2 !text-3xl inline-block !font-semibold"
            >
              Camera Sensor
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Header;
