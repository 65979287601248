import { FC, useEffect, useState, useMemo, MutableRefObject } from "react";
import { Box } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import uniq from "lodash/uniq";
// TODO: [PRJIND-9046] Uncomment these once MUI gets upgraded to v7
// import { GridRowModel, GridRowOrderChangeParams } from "@mui/x-data-grid-premium";
import {
  useGetConfigurationSetsQuery,
  ConfigurationSetType,
  TableViewType,
} from "../../../../../../graphql/operations";
import { getRows, Table } from "../../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { detentionRulesColumns } from "../../configurationsUtils";
import CompanySelect from "./CompanySelect";

export interface DetentionRulesTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  orgId: string | undefined;
  onRowClick: (data: any) => void;
}

const DetentionRulesTable: FC<DetentionRulesTableProps> = ({
  apiRef,
  orgId,
  onRowClick,
}) => {
  const [rowsState, setRowsState] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [companyOptions, setCompanyOptions] = useState<string[]>([]);

  const fileName: string = useExportedFileName("Geofence Detention Rules");

  const {
    data: dataConfigurations,
    isError: isErrorConfiguration,
    // using isFetching because for some reason isLoading stucks on 'true'
    isFetching: isFetchingConfiguration,
  } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? "",
        type: ConfigurationSetType.DetentionRule,
        excludeDataFromParents: true,
      },
    },
    {
      enabled: Boolean(orgId),
    }
  );

  const parsedRules = useMemo(() => {
    const parsed = dataConfigurations?.getConfigurationSets?.map(
      (singleSet) => {
        const { _id, name } = singleSet;
        const parsedValue = singleSet?.value
          ? JSON.parse(singleSet?.value)
          : {};

        return {
          _id,
          name,
          priority: parsedValue.priority,
          company: parsedValue?.company,
          geofenceType: parsedValue?.geofenceType,
          geofenceSubType: parsedValue?.geofenceSubType,
          geofenceName: parsedValue?.geofenceName,
          clockStarts: parsedValue.clockStarts,
          dayOfDrop: parsedValue?.dayOfDrop,
          endOnEmpty: parsedValue?.endOnEmpty,
          endOnLoaded: parsedValue?.endOnLoaded,
          detentionCharges: parsedValue.detentionCharges,
        };
      }
    );

    setCompanyOptions(
      uniq(
        (parsed ?? [])
          .map((rule) => rule?.company)
          .filter((company) => Boolean(company))
      )
    );

    return parsed?.filter((set) =>
      selectedCompany ? set?.company?.includes(selectedCompany) : true
    );
  }, [dataConfigurations?.getConfigurationSets, selectedCompany]);

  useEffect(() => {
    if (parsedRules) {
      setRowsState(
        getRows<typeof parsedRules>(
          parsedRules,
          detentionRulesColumns,
          "",
          "",
          "_id"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedRules]);

  // TODO: [PRJIND-9046] Implement once MUI gets upgraded to v7
  // const handleRowOrderChange = useCallback(
  //   async (params: GridRowOrderChangeParams) => {
  //     updateConfigurationSet({
  //       _id,
  //       orgId,
  //       value: {
  //         priority: params.targetIndex + 1,
  //       },
  //     });
  //   },
  //   []
  // );

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        showToolbar
        disableColumnFilter
        disableRowGrouping
        // TODO: [PRJIND-9046] Uncomment these once MUI gets upgraded to v7
        // rowReordering
        // onRowOrderChange={handleRowOrderChange}
        pagination={false}
        isDataRefetching={false}
        tableName="detention-rules-config"
        initialState={{
          sorting: {
            sortModel: [{ field: "priority", sort: "asc" }],
          },
        }}
        tableType={TableViewType.DetentionRulesConfigurations}
        rows={rowsState}
        apiRef={apiRef}
        columns={detentionRulesColumns}
        loading={isFetchingConfiguration}
        error={isErrorConfiguration}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
        onRowClick={onRowClick}
        actions={
          <CompanySelect
            selectedCompany={selectedCompany}
            companyOptions={companyOptions}
            setSelectedCompany={setSelectedCompany}
          />
        }
      />
    </Box>
  );
};

export default DetentionRulesTable;
