import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  Asset,
  useFindAssetByIdQuery,
} from "../../../../../../graphql/operations";
import { useApplicationTheme } from "../../../../../../shared/hooks/theme/useApplicationTheme";
import useBreakpoint from "../../../../../../shared/hooks/useBreakpoint";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import AssetErrorMessage from "../../../Shared/AssetsErrorMessage";
import ToggleViewButton, {
  TAB_VIEW_LABEL,
  TiresTabView,
} from "../../../Shared/ToggleViewButton/ToggleViewButton";
import { defaultBorderStyling } from "../constants";
import { useTiresTabLayout } from "../hooks/useTiresTabLayout";
import AxlesSummaryCards from "./cards/tpms/AxlesSummaryCards";
import TiresSummaryCards from "./cards/tpms/TiresSummaryCards";
import TiresSummaryTable from "./cards/tpms/TiresSummaryTable";

const TPMSCardsSection = () => {
  const { selectedAssetId } = useAssetsDataContext();
  const { applicationTheme } = useApplicationTheme();
  const [activeView, setActiveView] = useState<TiresTabView>(TiresTabView.Card);
  const isTablet = useBreakpoint("down", "md");
  const [isDarkTheme, setIsDarkTheme] = useState(applicationTheme === "dark");

  useEffect(() => {
    setIsDarkTheme(applicationTheme === "dark");
  }, [applicationTheme]);

  const {
    isLoading,
    isSuccess,
    isError,
    data: assetData,
  } = useFindAssetByIdQuery(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const selectedAsset = useMemo(
    () => assetData?.findAssetById ?? null,
    [assetData]
  );

  // Let's configure the layout of the cards based on the screen size and the number of axles/tires
  const { isDesktop } = useTiresTabLayout(selectedAsset);

  return (
    <Box
      className="flex flex-col w-full"
      data-testid="asset-dashboard--tires-tab--tpms-cards-section"
    >
      {!isLoading && (
        <>
          {isError && <AssetErrorMessage />}

          {isSuccess && selectedAsset?.sensors?.tpmsBeta !== null && (
            <>
              {Boolean(
                selectedAsset?.sensors?.tpmsBeta?.data?.sensors?.length
              ) && (
                <Box display="flex" className="w-full">
                  {isDesktop && (
                    <Box
                      sx={{
                        backgroundColor: "var(--dashboard_subheader__bg)",
                        width: "50%",
                        borderRadius: "8px",
                        border: isDarkTheme ? defaultBorderStyling : "none",
                        padding: "16px",
                        paddingBottom: "26px",
                        marginRight: "24px",
                      }}
                    >
                      <Typography
                        data-testid="atis-alpha-table-label"
                        sx={{
                          fontSize: "18px",
                          paddingBottom: "16px",
                          color: "var(--brand)",
                          fontWeight: 600,
                        }}
                      >
                        Axle Sensors
                      </Typography>
                      <AxlesSummaryCards asset={selectedAsset as Asset} />
                    </Box>
                  )}

                  <Box
                    sx={{
                      backgroundColor: isTablet
                        ? ""
                        : "var(--dashboard_subheader__bg)",
                      width: "100%",
                      border: isDarkTheme ? defaultBorderStyling : "none",
                      borderRadius: "8px",
                      padding: "16px",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{
                        paddingBottom: "16px",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Typography
                        data-testid="atis-alpha-table-label"
                        sx={{
                          fontSize: "18px",
                          color: "var(--brand)",
                          fontWeight: 600,
                        }}
                      >
                        Tire Sensors Details
                      </Typography>
                      <ToggleViewButton
                        activeView={activeView}
                        onClick={setActiveView}
                      />
                    </Box>

                    {activeView === TiresTabView.Card && (
                      <TiresSummaryCards asset={selectedAsset as Asset} />
                    )}

                    {activeView === TiresTabView.Table && (
                      <TiresSummaryTable asset={selectedAsset as Asset} />
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TPMSCardsSection;
