import { useCookies } from "react-cookie";
import { SELECTED_ORGANIZATION } from "../../constants";
import { OrgData } from "../../graphql/operations";
import { useCurrentOrg } from "./useCurrentOrg";

/**
 * Returns the current selected organization
 */
export const useSelectedOrg = (): OrgData | undefined => {
  const [cookies] = useCookies([SELECTED_ORGANIZATION]);
  const userOrg = useCurrentOrg();
  const orgFromCookie = cookies[SELECTED_ORGANIZATION];
  const selectedOrg = {
    _id: orgFromCookie.value,
    time_zones: orgFromCookie.time_zones,
    name: orgFromCookie.label,
  };
  return cookies[SELECTED_ORGANIZATION] ? selectedOrg : userOrg;
};
