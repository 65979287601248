import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  AuthenticationResultType,
  ChallengeNameType,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { env } from "../../env";
import { MfaOption } from "../../graphql/operations";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: env.REACT_APP_REGION,
});

export const signIn = async (
  username: string,
  password: string
): Promise<
  | { type: "AuthenticationResult"; result: AuthenticationResultType }
  | { type: "Challenge"; challengeName: ChallengeNameType }
  | void
> => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult, ChallengeName, Session } =
      await cognitoClient.send(command);

    if (!ChallengeName) {
      if (AuthenticationResult) {
        sessionStorage.setItem("idToken", AuthenticationResult.IdToken ?? "");
        sessionStorage.setItem(
          "accessToken",
          AuthenticationResult.AccessToken ?? ""
        );
        sessionStorage.setItem(
          "refreshToken",
          AuthenticationResult.RefreshToken ?? ""
        );

        return { type: "AuthenticationResult", result: AuthenticationResult };
      }
    } else {
      sessionStorage.setItem("session", Session ?? "");
      sessionStorage.setItem("username", username ?? "");

      return { type: "Challenge", challengeName: ChallengeName };
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const mfaSignIn = async (
  Session: any,
  username: string,
  code: string
): Promise<AuthenticationResultType | void> => {
  const challengeResponse = {
    ClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    ChallengeName: MfaOption.SoftwareTokenMfa,
    Session: Session,
    ChallengeResponses: {
      USERNAME: username,
      SOFTWARE_TOKEN_MFA_CODE: code,
    },
  } as RespondToAuthChallengeCommandInput;

  try {
    const mfaCommand = new RespondToAuthChallengeCommand(challengeResponse);
    const mfaResponse = await cognitoClient.send(mfaCommand);

    if (mfaResponse.AuthenticationResult) {
      sessionStorage.setItem(
        "idToken",
        mfaResponse.AuthenticationResult.IdToken ?? ""
      );
      sessionStorage.setItem(
        "accessToken",
        mfaResponse.AuthenticationResult.AccessToken ?? ""
      );
      sessionStorage.setItem(
        "refreshToken",
        mfaResponse.AuthenticationResult.RefreshToken ?? ""
      );
      return mfaResponse.AuthenticationResult;
    } else {
      throw new Error("MFA failed");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

// Sign-up and password reset functions will be added here
