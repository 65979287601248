import { useGetAssetsManufacturersQuery } from "../../../../../graphql/operations";

export const useGetAssetsManufacturers = (): {
  assetManufacturers: string[];
  isLoading: boolean;
} => {
  const { isLoading, data } = useGetAssetsManufacturersQuery({
    input: { limit: 100, skip: 0 },
  });
  const assetManufacturers = data?.getAssetsManufacturers ?? [];

  return {
    assetManufacturers,
    isLoading,
  };
};
