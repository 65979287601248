import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";

interface SecondaryInfoProps {
  value: string;
  title: string;
  subTitle: string;
}

const SecondaryInfo: FC<SecondaryInfoProps> = ({ value, title, subTitle }) => {
  return (
    <Grid item>
      <Grid item>
        <Typography
          data-testid="total-assets-secondary-value"
          className="!text-2xl !font-normal !text-brand"
        >
          {value}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className="!text-[11px] !font-bold">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className="!text-[10px]">{subTitle}</Typography>
      </Grid>
    </Grid>
  );
};

export default SecondaryInfo;
