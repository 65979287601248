import { FC, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useGetAssetsQuery,
  useRemoveAssetMutation,
} from "../../../../../graphql/operations";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";

interface DeleteConfirmationDialogProps {
  open: boolean;
  handleDialogClose: () => void;
  onRemove?: () => void;
  assetId: string;
}
export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  handleDialogClose,
  assetId,
  onRemove = () => {},
}) => {
  const { dispatch } = useAppContext();
  const { assetsInput } = useAssetsDataContext();

  const findAssetsQuery = useGetAssetsQuery(
    { input: assetsInput },
    { enabled: false }
  );
  const queryClient = useQueryClient();

  const { mutateAsync: removeAsset, isLoading } = useRemoveAssetMutation({
    onSuccess: async (data) => {
      const initial = findAssetsQuery?.data?.getAssets?.assets?.find(
        (a) => a?._id === data.deleteAsset._id
      );
      findAssetsQuery.refetch({ cancelRefetch: true });

      if (initial?.imei) {
        queryClient.invalidateQueries(["findDevices"], {
          refetchType: "all",
        });
      }

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset was removed Successfully!",
          text: "",
          severity: "success",
          onClose: () => {},
        },
      });
      onRemove();
    },
    onError: (_error) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset Removing Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
  });

  const handleRemoveCLick = useCallback(async () => {
    await removeAsset({
      id: assetId as string,
    });
    handleDialogClose();
  }, [assetId, handleDialogClose, removeAsset]);
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Asset</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this asset?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="delete-confirmation-dialog-cancel-btn"
          onClick={handleDialogClose}
          autoFocus
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          data-testid="delete-confirmation-dialog-remove-btn"
          className="!text-error"
          variant="text"
          onClick={handleRemoveCLick}
          disabled={isLoading}
        >
          {isLoading ? "Removing..." : "Remove"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
