import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import { AutocompleteOption } from "../../../../../../../types/form";
import { faultCodesFormSchema } from "../utils/validationSchema";

export interface FaultCodeFormValues {
  manufacturer?: string | AutocompleteOption;
  faultCodeType?: string | AutocompleteOption;
  pgn?: number;
  dtc?: string;
  sid?: number;
  fmi?: number;
  problemArea?: string;
  cause?: string;
  description?: string;
  pctExplains?: string;
}

export const faultCodeFormInitialValues: FaultCodeFormValues = {
  manufacturer: "",
  faultCodeType: "",
  pgn: undefined,
  dtc: undefined,
  sid: undefined,
  fmi: undefined,
  problemArea: "",
  cause: "",
  description: "",
  pctExplains: "",
};

export const useFaultCodeForm = () => {
  const form = useForm({
    resolver: yupResolver(faultCodesFormSchema),
    values: omitBy(faultCodeFormInitialValues, isNil),
  });

  const getValues = useCallback(
    () => faultCodesFormSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues };
};
