export enum NavigationRoutes {
  Root = "/",
  Login = "/login",
  Dashboard = "/dashboards",
  Reports = "/reports",
  Alerts = "/alerts",
  Assets = "/assets",
  MyAccount = "/my-account",
  Batches = "/batches",
  Devices = "/devices",
  AssetMap = "/assets/map",
  AssetTable = "/assets/table",
  AssetGallery = "/assets/gallery",
  Geofences = "/assets/geofences",
  AdminPanel = "/admin-panel",
  TermsAndConditions = "/terms-and-conditions",
  Help = "/help",
  CustomLogin = "/custom-login", // temporal; to be removed when Login is replaced
  Mfa = "/mfa",
  // Admin Panel sub routes
  AdminPanelBrands = "/admin-panel/brands",
  AdminPanelConfigurations = "/admin-panel/configurations",
  AdminPanelSensors = "/admin-panel/sensors",
  AdminPanelOrganizations = "/admin-panel/organizations",
  AdminPanelProfiles = "/admin-panel/profiles",
  AdminPanelUsers = "/admin-panel/users",
  AdminPanelRegions = "/admin-panel/regions",
  AdminPanelZones = "/admin-panel/zones",

  // Assets sub routes
  Map = "map",
  Table = "table/*",
  Gallery = "gallery/*",
  Geo = "geofences/*",
  SharedAssetInvitation = "invitations/:id",
}
