import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BatteryIcon } from "../../../../../shared/components/BatteryIcon";
import { AssetIcon } from "../../../TableView/components";

interface GeofenceHistoryTileProps {
  type: string;
  valueOne: string;
  valueTwo: string;
}

export const GeofenceHistoryTile: FC<GeofenceHistoryTileProps> = ({
  type,
  valueOne,
  valueTwo,
}) => {
  const [titleState, setTitleState] = useState("");
  const [dayTimeState, setDayTimeState] = useState("assets");
  const [isLoaded, setIsLoaded] = useState("noLoaded");
  const [isParked, setIsParked] = useState("pass");
  const [isPercentage, setIsPercentage] = useState(false);
  const [isBattery, setIsBattery] = useState(false);

  useEffect(() => {
    switch (type) {
      case "AverageAssetsPresent":
        setTitleState("Average Assets Present");
        setDayTimeState("assets");
        setIsLoaded("loaded");
        break;
      case "AssetsWereHealthy":
        setTitleState("Assets Were Healthy");
        setDayTimeState("of the time");
        setIsPercentage(true);
        setIsBattery(true);
        break;
      case "AssetsAreLoaded":
        setTitleState("Assets Are Loaded");
        setDayTimeState("of the time");
        setIsLoaded("loaded");
        setIsParked("Moving");
        setIsPercentage(true);
        break;
      default:
        console.log("Haven't data to render Tile");
    }
  }, [type]);

  return (
    <Box
      className="grid text-start gap-2 text-typography-tooltip p-4 сursor-pointer grow"
      style={{
        backgroundColor: "var(--gallery-cards-background)",
        borderRadius: "8px",
      }}
    >
      <Box className="flex flex-start ">
        <div className="flex items-end mr-2" data-testid="asset-icon">
          {isBattery ? (
            <BatteryIcon
              chargeLevel={12}
              svgProps={{ width: "24px", height: "24px" }}
              status={"healthy"}
            />
          ) : (
            <AssetIcon
              dwellingDays={12}
              signal={true}
              cargo_state={isLoaded}
              trip_st={isParked}
              svgProps={{ height: 24, width: 24 }}
            />
          )}
        </div>
        <Box
          mt={2}
          sx={{
            color: "var(--Brand-Colors-Primary-Blue, #3C88CC)",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "26px",
            letterSpacing: "-0.18px",
            textTransform: "capitalize",
          }}
        >
          {titleState}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        mb={2}
        sx={{
          "@media (min-width: 1105px)": {
            minHeight: "86px",
          },
          "@media (min-width: 1280px)": {
            minHeight: "86px",
          },
          "@media (min-width: 1640px)": {
            minHeight: "56px",
          },
          "@media (min-width: 1920px)": {
            minHeight: "56px",
          },
          "@media (min-width: 2560px)": {
            minHeight: "56px",
          },
        }}
      >
        <Box textAlign="left" flex={1}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 400, color: "var(--impersonate-container)" }}
          >
            30 D
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 600,
              display: "inline",
              color: "var(--impersonate-container)",
            }}
          >
            {valueOne}
            {isPercentage && "%"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              display: "inline",
              marginLeft: "4px",
              fontWeight: 400,
              color: "var(--impersonate-container)",
            }}
          >
            {dayTimeState}
          </Typography>
        </Box>
        <Box textAlign="left" flex={1}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 400, color: "var(--impersonate-container)" }}
          >
            365 D
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: 600,
              display: "inline",
              color: "var(--impersonate-container)",
            }}
          >
            {valueTwo}
            {isPercentage && "%"}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              display: "inline",
              marginLeft: "4px",
              fontWeight: 400,
              color: "var(--impersonate-container)",
            }}
          >
            {dayTimeState}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
