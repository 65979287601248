import { Dispatch, SetStateAction } from "react";
import {
  EventHistoryName,
  EventNameStatus,
  eventHistoryNameMap,
} from "../../../../../constants/map";
import { HistoricalEventHistory } from "../../../../../graphql/operations";

export interface HistoryEventsCount {
  healthEvent: number;
  locationUpdate: number;
  cargoUpdate: number;
  doorEvent: number;
  locationEntry: number;
}

export interface HistoryEventsFiltersData {
  circleBackgroundColor: string;
  iconColor: string;
  filterName: string;
  filterValue: number;
  filterType: string;
  onClick?: () => void;
  isSelected: boolean;
  setSelectedFilter: Dispatch<SetStateAction<String>>;
}

export const getHistoryEventsCount = (
  eventHistory: HistoricalEventHistory[]
): HistoryEventsCount => {
  let historyEventsCount: HistoryEventsCount = {
    healthEvent: 0,
    locationUpdate: 0,
    cargoUpdate: 0,
    doorEvent: 0,
    locationEntry: 0,
  };
  eventHistory.forEach((historyEvent) => {
    let eventName = eventHistoryNameMap.get(
      historyEvent.breadcrumbs?.eventName as EventHistoryName
    );
    if (eventName === EventNameStatus.LocationUpdate)
      historyEventsCount.locationUpdate++;
    if (eventName === EventNameStatus.Heartbeat)
      historyEventsCount.healthEvent++;
    if (eventName === EventNameStatus.Door) historyEventsCount.doorEvent++;
    if (eventName === EventNameStatus.Cargo) historyEventsCount.cargoUpdate++;
    if (eventName === EventNameStatus.LocationEntry)
      historyEventsCount.locationEntry++;
  });

  return historyEventsCount;
};

export const getTravelledDistance = (
  eventHistory: HistoricalEventHistory[]
): number => {
  if (eventHistory.length > 1) {
    const firstEventHistory = eventHistory[0];
    const lastEventHistory = eventHistory[eventHistory.length - 1];

    if (
      firstEventHistory?.breadcrumbs?.odometer &&
      lastEventHistory?.breadcrumbs?.odometer
    ) {
      const travelledDistance =
        firstEventHistory?.breadcrumbs?.odometer -
        lastEventHistory?.breadcrumbs?.odometer;
      if (travelledDistance >= 0) {
        return Math.round(travelledDistance);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  return 0;
};
