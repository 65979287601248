import { FC, memo } from "react";
import { Grid } from "@mui/material";
import { AuthorizedComponent } from "../../../shared/components/AuthorizedComponent/AuthorizedComponent";
import { Button } from "../../../shared/components/Button";
import { NavigationRoutes } from "../../../utils/routes/routesUtils";

interface DashboardButtonsProps {
  moveTo: (url: string) => void;
}
const DashboardButtons: FC<DashboardButtonsProps> = ({ moveTo }) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";

  const moveToDevices = () => {
    moveTo(NavigationRoutes.Devices);
  };

  const moveToMap = () => {
    moveTo(NavigationRoutes.AssetMap);
  };

  const sx = {
    width: "194px",
    height: "64px !important",
    padding: "16px 32px !important",
    gap: "8px",
    fontWeight: "700 !important",
    fontSize: "18px !important",
    "&:hover": {
      backgroundColor: "var(--typography) !important",
      color: "var(--secondary) !important",
    },
  };

  const buttonTheme = isDarkTheme ? "white" : "black";

  return (
    <Grid
      container
      spacing={4}
      className="!mb-16 !flex justify-center text-center"
    >
      <AuthorizedComponent actionPath="dashboard.deviceButton">
        <Grid
          item
          data-testid="dashboard-component-buttons-device-management"
          className="mr-4"
        >
          <Button
            text="My Devices"
            onClick={moveToDevices}
            theme={buttonTheme}
            sx={sx}
          />
        </Grid>
      </AuthorizedComponent>
      <Grid item data-testid="dashboard-component-buttons-asset-map">
        <Button
          text={"Asset Map"}
          onClick={moveToMap}
          theme={buttonTheme}
          sx={sx}
        />
      </Grid>
    </Grid>
  );
};

export default memo(DashboardButtons);
