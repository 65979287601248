import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR, ROOT_ORGANIZATION_NAME } from "../../../../constants";
import { USER_UPDATE_FAILED_PAYLOAD } from "../../../../constants/users";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  Dashboard,
  DashboardType,
  MeUserData,
  useUpdateMyAccountMutation,
} from "../../../../graphql/operations";
import { SubHeader } from "../../../../shared/components/SubHeader";
import {
  isAdmin,
  isSuperAdmin,
} from "../../../../shared/components/WithPermissions";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { toggleFavoriteDashboard } from "../../DashboardsTable/utils";
import { FavoriteDashboardsDropdown } from "../../FavoriteDashboardsDropdown/FavoriteDashboardsDropdown";
import FavoriteButton from "./buttons/FavoriteButton";

export interface HeaderProps {
  dashboard: Dashboard | undefined;
  setEditDashboardPopupOpened: React.Dispatch<React.SetStateAction<boolean>>;
  showWidgetsDrawer: () => void;
  isLoading: boolean;
}

const getUserFavoriteDashboards = (userData?: MeUserData) =>
  (userData?.user_preferences?.favorite_dashboards ?? []).filter(
    (id): id is number => id !== null && id !== undefined
  );

const Header: React.FC<HeaderProps> = ({
  dashboard,
  setEditDashboardPopupOpened,
  showWidgetsDrawer,
  isLoading,
}) => {
  const userData = useUserData();
  const { userInfo } = useAuthContext();
  const currentUserOrganization = useCurrentOrg();
  const isAnyAdmin = useMemo(
    () =>
      isAdmin(userInfo?.groups ?? []) || isSuperAdmin(userInfo?.groups ?? []),
    [userInfo?.groups]
  );

  const isUserAllowedToModify = useMemo(() => {
    const isCustomDashboard = dashboard?.type === DashboardType.Custom;
    const isPhillipsConnectMember =
      currentUserOrganization?.name === ROOT_ORGANIZATION_NAME ||
      currentUserOrganization?.org_key === ROOT_ORGANIZATION_NAME;
    const isUserPhillipsConnectAdmin = isPhillipsConnectMember && isAnyAdmin;
    const isAdminAllowedModifying = isCustomDashboard && isAnyAdmin;
    const hasUserCreatedDashboard = dashboard?.userId === userData?._id;

    return (
      isUserPhillipsConnectAdmin ||
      isAdminAllowedModifying ||
      hasUserCreatedDashboard
    );
  }, [currentUserOrganization, dashboard, isAnyAdmin, userData]);

  const isDashboardFavoriteForTheUserOrganization = useMemo(
    () =>
      (currentUserOrganization?.favorite_dashboards ?? []).includes(
        dashboard?.id ?? null
      ),
    [currentUserOrganization?.favorite_dashboards, dashboard?.id]
  );
  const queryClient = useQueryClient();
  const [favoriteDashboards, setFavoriteDashboards] = useState<number[]>(
    getUserFavoriteDashboards(userData)
  );
  // Sync favorite dashboards from user preferences and local state
  useEffect(() => {
    setFavoriteDashboards((current: number[]): number[] => {
      const newFavoriteDashboards = new Set([
        ...current,
        ...getUserFavoriteDashboards(userData),
      ]);
      return [...newFavoriteDashboards];
    });
  }, [userData, setFavoriteDashboards]);

  const { dispatch } = useAppContext();
  const { mutate } = useUpdateMyAccountMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["getUserData"]);
    },
    onError: (error: Error | null) => {
      const errorMessage = error ? error.message : "Something Went Wrong.";
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...USER_UPDATE_FAILED_PAYLOAD,
          text: errorMessage,
        },
      });
    },
  });

  return (
    <Box
      className=""
      data-testid="page-dashboard-header"
      style={{
        display: "block",
        backgroundColor: "var(--dashboard-view-header-background)",
      }}
    >
      <Box style={{ display: "inline-block", verticalAlign: "middle" }}>
        {!isLoading ? (
          <SubHeader
            title={dashboard?.name ?? ""}
            actions={[]}
            hideHeaderBorder={true}
            hideOrgName
            removeHorizontalPadding={true}
          />
        ) : (
          <Box
            className="py-5 mx-3"
            style={{
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            <Skeleton variant="rounded" width={250} height={32} />
          </Box>
        )}
      </Box>
      {!isLoading && isUserAllowedToModify && (
        <Box style={{ display: "inline-block", verticalAlign: "middle" }}>
          <IconButton
            size="large"
            onClick={() => {
              setEditDashboardPopupOpened(true);
            }}
            className="!p-1 !mr-1"
            data-testid="edit-dashboard-button"
          >
            <EditOutlinedIcon className="text-primary" />
          </IconButton>
        </Box>
      )}

      <Box style={{ display: "inline-block", verticalAlign: "middle" }}>
        <FavoriteButton
          dashboard={dashboard}
          isDashboardFavoriteForTheUserOrganization={
            isDashboardFavoriteForTheUserOrganization
          }
          favoriteDashboards={favoriteDashboards}
          toggleFavoriteDashboard={() => {
            toggleFavoriteDashboard(
              favoriteDashboards,
              dashboard?.id as number,
              setFavoriteDashboards,
              mutate,
              userData
            );
          }}
        />

        <IconButton
          size="large"
          onClick={() => {}}
          className="!p-1 !mr-1"
          data-testid="default-dashboard-button"
        >
          <CheckBoxRoundedIcon className="text-primary" />
        </IconButton>
      </Box>

      <Box
        style={{
          marginRight: "1rem",
          display: "inline-block",
          verticalAlign: "middle",
          float: "right",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        {!isLoading && isUserAllowedToModify && (
          <Box
            style={{
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            <Button
              className="!font-bold !text-base"
              sx={{
                color: "var(--brand)",
                textTransform: "none",
              }}
              data-testid="add-widget-button"
              disabled={false}
              variant="text"
              type="button"
              onClick={showWidgetsDrawer}
            >
              <AddIcon />
              <Typography className="!font-bold	!text-base">
                Add New Widget
              </Typography>
            </Button>
          </Box>
        )}
        <Box
          style={{
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <FavoriteDashboardsDropdown />
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
