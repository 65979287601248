import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useGridApiRef, GridRowsProp } from "@mui/x-data-grid-premium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useFindAssetByIdQuery,
  TableViewType,
  useFindAssetsAlertsV2Query,
  AssetAlertV2,
} from "../../../../../graphql/operations";
import { Table, TableDataModes } from "../../../../../shared/components/Table";
import useExportedFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { resolvePromiseSafely } from "../../../../../utils";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../AdminPanel/tabs/constants";
import {
  SEARCH_KEYS,
  defaultColumnVisibilityModel,
  getColumns,
} from "./columns";
import { ASSET_ALERT_PAGE_SIZE } from "./utils";

export type AlertsListProps = {
  selectedAssetId: string;
};

export const AssetAlertsList: FC<AlertsListProps> = memo(
  ({ selectedAssetId }) => {
    const {
      state: { appConfig },
    } = useAppContext();
    const gridApiRef = useGridApiRef();

    const [tableRows, setTableRows] = useState<(AssetAlertV2 | null)[]>([]);
    const [totalTableRowsCount, setTotalTableRowsCount] = useState<number>(0);
    const [currentPageNo, setCurrentPageNo] = useState<number>(1);
    const [pagination, setPagination] = useState<number>(0);

    const { data: assetData } = useFindAssetByIdQuery({
      assetId: selectedAssetId,
    });
    const { orgName } = useMemo(
      () => ({
        orgName: assetData?.findAssetById?.org_name ?? "",
      }),
      [assetData]
    );

    const fileName = useExportedFileNameForOrg(orgName, "Asset Alerts");
    const userTimezone = usePreferredTimezone();
    const columns = getColumns(userTimezone);
    const assetsAlertsInput = {
      input: {
        _id: selectedAssetId,
        skip: pagination,
        limit: ASSET_ALERT_PAGE_SIZE,
      },
    };
    const {
      data: assetsAlertsQueryData,
      isSuccess: assetsAlertsDataSuccess,
      isLoading: assetsAlertsDataLoading,
    } = useFindAssetsAlertsV2Query(assetsAlertsInput);

    useEffect(() => {
      const assetAlerts =
        assetsAlertsQueryData?.findAssetsAlertsV2?.alerts ?? [];
      setTableRows(assetAlerts);
      setTotalTableRowsCount(
        assetsAlertsQueryData?.findAssetsAlertsV2?.total ?? 0
      );
    }, [assetsAlertsDataSuccess, assetsAlertsQueryData]);

    const onPageChange = useCallback((page: number) => {
      setCurrentPageNo(page);
      setPagination(ASSET_ALERT_PAGE_SIZE * (page - 1));
    }, []);

    const onDownloadHandler = () => {
      gridApiRef.current.setSelectionModel([]);
      resolvePromiseSafely(gridApiRef.current.exportDataAsExcel({ fileName }));
    };

    const [searchParams] = useSearchParams();
    const search = searchParams.get("search");
    const [initialSearch, setInitialSearch] = useState("");

    useEffect(() => {
      if (search && !assetsAlertsDataLoading) {
        setInitialSearch(search);
      }
    }, [search, assetsAlertsDataLoading]);

    return (
      <Box className="h-full w-full bg-background">
        <Box className="h-full w-full pt-2 px-4 pb-4 md:px-6 md:pb-10 lg:px-16 lg:pb-20">
          <Table
            useExtendedSearch={true}
            tableType={TableViewType.AssetsAlerts}
            handleDataMode={TableDataModes.Server}
            columns={columns}
            rows={tableRows as GridRowsProp}
            rowCount={totalTableRowsCount}
            initialSearch={initialSearch}
            error={
              !assetsAlertsDataLoading && !assetsAlertsDataSuccess ? true : null
            }
            showToolbar
            apiRef={gridApiRef}
            columnVisibilityModel={defaultColumnVisibilityModel}
            enableSearch={true}
            searchExactMatch
            searchKeys={SEARCH_KEYS}
            searchMinLength={appConfig.searchMinLength}
            getRowId={(row) => row._id}
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            exportProps={{
              csvOptions: { fileName },
              excelOptions: { fileName },
              printOptions: { fileName },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "lastTriggeredAt", sort: "desc" }],
              },
            }}
            tableName="asset_alerts_table"
            pageSize={ASSET_ALERT_PAGE_SIZE}
            loading={assetsAlertsDataLoading}
            currentPage={currentPageNo}
            onPageChange={onPageChange}
            onPaginationModelChange={onPageChange}
            allowExport
            disableRowGrouping
            mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
          />
        </Box>
      </Box>
    );
  }
);
