import React, { FC, useEffect } from "react";
import { Box, Popover } from "@mui/material";
import Calendar from "./components/Calendar";
import DatePickerTrigger from "./components/DatePickerTrigger";
import useDatePicker from "./hooks/useDatePicker";
import usePopover from "./hooks/usePopover";

export interface DateRangePickerProps {
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  defaultStartDate?: Date | null;
  defaultEndDate?: Date | null;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  onChange,
  defaultStartDate,
  defaultEndDate,
}) => {
  const {
    startDate,
    endDate,
    setDateRange,
    selectedDateRangeOption,
    setSelectedDateRangeOption,
  } = useDatePicker({ defaultStartDate, defaultEndDate });

  const {
    isPopoverOpen,
    popoverId,
    popoverAnchorElement,
    openPopover,
    closePopover,
  } = usePopover();

  useEffect(() => {
    if (startDate && endDate) {
      onChange(startDate, endDate);
    }
  }, [startDate, endDate, onChange]);

  return (
    <Box data-testid="date-range-picker">
      <DatePickerTrigger
        startDate={startDate}
        endDate={endDate}
        isDatePickerOpen={isPopoverOpen}
        openDatePicker={openPopover}
      />

      <Popover
        id={popoverId ?? undefined}
        open={isPopoverOpen}
        anchorEl={popoverAnchorElement}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "var(--date-range-picker--popover-background)",
            padding: "16px",
          },
        }}
      >
        <Calendar
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          selectedDateRangeOption={selectedDateRangeOption}
          setSelectedDateRangeOption={setSelectedDateRangeOption}
        />
      </Popover>
    </Box>
  );
};

export default DateRangePicker;
