import { ColorsPalette, DefaultBrandColorPalette } from "./colors-palette";
import { parseHexToHslBrandColors } from "./helpers/parseHexToHslBrandColors";

// Accent color may vary depending on color scheme, e.g. light or dark mode.

export interface AccentColor {
  light: ColorsPalette | string;
  dark: ColorsPalette | string;
}
export type Color = AccentColor | ColorsPalette | string;

// Color object may contain both types of colors.
export interface Colors {
  [cssClass: string]: Color;
}

/**
 * Get list of the accent colors and values (check Figma)
 * Other design system tokens (fonts, etc)
 */
export const getColors = (brandColor: string): Colors => {
  // If 'brandColor' is not provided (for the cases, where we don't have access to AppContext), will try to get if from localStorage or use default one.
  let brandHexColor = brandColor;
  if (!brandColor && typeof localStorage !== "undefined") {
    const brandColorFromLocalStorage = localStorage.getItem("brandColor");
    brandHexColor =
      brandColorFromLocalStorage ?? DefaultBrandColorPalette.Primary;
  }

  const brandColors = parseHexToHslBrandColors(brandHexColor);

  const brandPrimary: Color = brandColors
    ? brandColors.primary
    : DefaultBrandColorPalette.Primary;
  // Update the affected color properties with primary and secondary colors.
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const brandSecondary: Color = brandColors
    ? brandColors.secondary
    : DefaultBrandColorPalette.Secondary;
  const brandLighter: Color = brandColors
    ? brandColors.lighter
    : ColorsPalette.PrimaryBlueOpacity50;

  const brandTransparent = brandColors
    ? brandColors.transparent
    : ColorsPalette.PrimaryBlueOpacity20;

  const colors: Colors = {
    primary: {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.BrightWhite,
    },
    secondary: {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.FlatBlack,
    },
    typography: {
      light: ColorsPalette.FlatBlack,
      dark: ColorsPalette.OffWhite,
    },
    checked: {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.OffWhite,
    },
    "header-text": {
      light: ColorsPalette.FlatBlack,
      dark: ColorsPalette.OffWhite,
    },
    "header-text-secondary": {
      light: ColorsPalette.FlatBlack,
      dark: ColorsPalette.BrightWhite,
    },
    "sub-header-text": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    background: {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.RichBlack,
    },
    "background-secondary": {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.FlatBlack,
    },
    info: ColorsPalette.PrimaryBlue,
    brand: brandPrimary,
    "brand-lighter": brandLighter,
    "brand-transparent": brandTransparent,
    caution: ColorsPalette.Caution,
    "caution-transparent": ColorsPalette.CautionOpacity20,
    error: ColorsPalette.Error,
    "error-transparent": ColorsPalette.ErrorOpacity20,
    warning: ColorsPalette.Alert,
    "warning-transparent": ColorsPalette.AlertOpacity20,
    success: ColorsPalette.Success,
    "success-transparent": ColorsPalette.SuccessOpacity20,
    yellow: ColorsPalette.Alert,
    green: ColorsPalette.Success,
    black: ColorsPalette.RichBlack,
    "flat-black": ColorsPalette.FlatBlack,
    "dark-charcoal": ColorsPalette.DarkCharcoal,
    "mid-charcoal": ColorsPalette.MidCharcoal,
    "light-charcoal": ColorsPalette.LightCharcoal,
    concrete: ColorsPalette.Concrete,
    "feather-gray": ColorsPalette.OffWhite,
    "off-white": ColorsPalette.OffWhite,
    blue: brandPrimary,
    "primary-blue": ColorsPalette.PrimaryBlue,
    "primary-blue-transparent": {
      light: ColorsPalette.PrimaryBlueOpacity20,
      dark: ColorsPalette.PrimaryBlueOpacity40,
    },
    "primary-blue-transparent-light": ColorsPalette.PrimaryBlueOpacity20,
    "primary-blue-transparent-10": ColorsPalette.PrimaryBlueOpacity10,
    "primary-blue-transparent-20": ColorsPalette.PrimaryBlueOpacity20,
    "primary-blue-transparent-30": ColorsPalette.PrimaryBlueOpacity30,
    "primary-blue-transparent-40": ColorsPalette.PrimaryBlueOpacity40,
    "primary-blue-transparent-50": ColorsPalette.PrimaryBlueOpacity50,
    "primary-blue-transparent-60": ColorsPalette.PrimaryBlueOpacity60,
    "primary-blue-transparent-70": ColorsPalette.PrimaryBlueOpacity70,
    "primary-blue-transparent-80": ColorsPalette.PrimaryBlueOpacity80,
    "primary-blue-transparent-90": ColorsPalette.PrimaryBlueOpacity90,
    indigo: ColorsPalette.RoyalBlue,
    white: ColorsPalette.BrightWhite,
    gray: ColorsPalette.Concrete,
    "dark-grey": ColorsPalette.LightCharcoal,
    "dark-blue": ColorsPalette.DarkBlue,
    "border-color": ColorsPalette.Concrete,
    "brand-light-blue": ColorsPalette.LightBlue,
    "grayscale-feather-gray": ColorsPalette.FeatherGray,
    "solid-gray-border-color": ColorsPalette.SolidGray,
    rechartsGreen: ColorsPalette.ReChartsGreen,
    rechartsPurple: ColorsPalette.ReChartsPurple,
    rechartsCoral: ColorsPalette.ReChartsCoral,
    rechartsBlue: ColorsPalette.PrimaryBlue,
    rechartsMarineBlue: ColorsPalette.ReChartsMarineBlue,
    rechartsTeal: ColorsPalette.ReChartsTeal,
    rechartsViolet: ColorsPalette.ReChartsViolet,
    rechartsGold: ColorsPalette.ReChartsGold,
    rechartsRed: ColorsPalette.ReChartsRed,
    rechartsOrange: ColorsPalette.ReChartsOrange,
    "brand-blue": {
      light: ColorsPalette.LightBlue,
      dark: ColorsPalette.PrimaryBlue,
    },
    app_header__bg: {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.RichBlack,
    },
    app_header_rev__bg: {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.BrightWhite,
    },
    dashboard_subheader__bg: {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    panel_content__bg: {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.FlatBlack,
    },
    card__bg: {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    card__typography: {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    card__placeholder: {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.MidCharcoal,
    },
    email__chip: {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.MidCharcoal,
    },
    card__placeholder_img: {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.LightCharcoal,
    },
    "warning-box": {
      light: ColorsPalette.ErrorOpacity20,
      dark: ColorsPalette.Error,
    },
    "typography-tooltip": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "success-box": {
      light: ColorsPalette.SuccessOpacity20,
      dark: ColorsPalette.Success,
    },
    "warning-box-text": {
      light: ColorsPalette.Error,
      dark: ColorsPalette.OffWhite,
    },
    "success-box-text": {
      light: ColorsPalette.Success,
      dark: ColorsPalette.OffWhite,
    },
    "toggle-button": {
      light: ColorsPalette.FlatBlack,
      dark: ColorsPalette.DarkCharcoal,
    },
    "date-range-interval-highlight": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.MidCharcoal,
    },
    "date-range-inside-interval": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.FlatBlack,
    },
    upload_file__form_bg: {
      light: ColorsPalette.PrimaryBlueOpacity20,
      dark: ColorsPalette.PrimaryBlueOpacity20,
    },
    upload_file__form_border: {
      light: ColorsPalette.PrimaryBlue,
      dark: ColorsPalette.Concrete,
    },
    upload_file__border: {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.LightCharcoal,
    },
    upload_file__icon: {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "autocomplete-highlight": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.MidCharcoal,
    },
    table_row_bg: {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.DarkCharcoal,
    },
    table_row_hover: ColorsPalette.FeatherGray,
    "menu-group": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.LightCharcoal,
    },
    backdrop: {
      light: ColorsPalette.FlatBlackOpacity60,
      dark: ColorsPalette.FlatBlackOpacity80,
    },
    "typography-secondary": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "checkbox-unchecked": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "checkbox-checked": ColorsPalette.PrimaryBlue,
    "datepicker-hover-bg": {
      light: ColorsPalette.RichBlackOpacity10,
      dark: ColorsPalette.BrightWhiteOpacity10,
    },
    "datepicker-hover-border": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.LightCharcoal,
    },
    "datepicker-selected": brandPrimary,
    "datepicker-selected-range-highlight-background": brandTransparent,
    "datepicker-selected-range-highlight-text": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "accordion-bg": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.DarkCharcoal,
    },
    "sub-header-border": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.MidCharcoal,
    },
    "asset-info-subheader": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "battery-label": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "option-hover": {
      light: ColorsPalette.PrimaryBlueOpacity20,
      dark: ColorsPalette.PrimaryBlueOpacity40,
    },
    "asset-status-icon-moving": ColorsPalette.PrimaryBlueOpacity40,
    "asset-status-icon-gps": ColorsPalette.PrimaryBlue,
    "asset-status-icon-no-gps": {
      light: ColorsPalette.RichBlackOpacity30,
      dark: ColorsPalette.BrightWhiteOpacity30,
    },
    "asset-status-icon-dwell-active": ColorsPalette.PrimaryBlue,
    "asset-status-icon-dwell-grayed": {
      light: ColorsPalette.RichBlackOpacity30,
      dark: ColorsPalette.BrightWhiteOpacity30,
    },
    "asset-status-icon-empty": ColorsPalette.BrightWhite,
    "asset-status-icon-loaded": ColorsPalette.PrimaryBlue,
    "asset-status-icon-loaded-no-gps": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.DarkCharcoal,
    },
    "asset-green": ColorsPalette.AccentAqua,
    "read-only-menu-background": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.LightCharcoal,
    },
    "read-only-menu-typography": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "chip-bg": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.BrightWhiteOpacity10,
    },
    "primary-chip-bg": {
      light: ColorsPalette.PrimaryBlue,
      dark: ColorsPalette.PrimaryBlueOpacity40,
    },
    "primary-chip-text": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.BrightWhite,
    },
    "query-builder-bg": {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "query-builder-text-color": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "table-data-panel": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.MidCharcoal,
    },
    switch: {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.OffWhite,
    },
    "switch-track": {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.RichBlack,
    },
    "stop-impersonate-btn": {
      light: ColorsPalette.Error,
      dark: ColorsPalette.BrightWhite,
    },
    "impersonate-container": {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.BrightWhite,
    },
    "assets-list-header": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "assets-list-header-sort-menu": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.Concrete,
    },
    "yellow-custom-slider": ColorsPalette.Caution,
    "battery-settings": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "tpms-settings": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "regulator-settings": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "sensor-unknown": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "tire-sensor-chip-healthy": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "tire-sensor-chip-warning": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "tire-sensor-chip-alert": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "tire-sensor-chip-critical": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "tire-sensor-chip-healthy-icon": {
      light: ColorsPalette.Success,
      dark: ColorsPalette.Success,
    },
    "tire-sensor-chip-warning-icon": {
      light: ColorsPalette.Caution,
      dark: ColorsPalette.Caution,
    },
    "tire-sensor-chip-alert-icon": {
      light: ColorsPalette.Alert,
      dark: ColorsPalette.Alert,
    },
    "tire-sensor-chip-critical-icon": {
      light: ColorsPalette.Error,
      dark: ColorsPalette.Error,
    },
    "dwell-sensor-icon": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "divider-color": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.LightCharcoal,
    },
    "settings-card-bg": {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "dwell-bg": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "dwell-bg-cards-frame-bg": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.FlatBlack,
    },
    "dwell-bg-cards-bg": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "transparent-gray": {
      light: ColorsPalette.RichBlackOpacity60,
      dark: ColorsPalette.BrightWhiteOpacity60,
    },
    "map-details-item": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.MidCharcoal,
    },
    "cargo-trend-expand-icon": {
      light: ColorsPalette.RichBlack,
      dark: ColorsPalette.FeatherGray,
    },
    "other-sensors-cards-header": ColorsPalette.PrimaryBlue,
    "input-border": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "input-border-table-grid": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.BrightWhite,
    },
    "input-border-hover": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "chart-tick-style": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "chart-secondary-bar-style": {
      light: ColorsPalette.RoyalBlue,
      dark: ColorsPalette.RoyalBlue,
    },
    no_img_bg: ColorsPalette.NoImageBG,
    "card-sensors-text": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "form-label-default": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.Concrete,
    },
    "form-label-focused": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "form-label-hover": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.Concrete,
    },
    "mui-border-bottom-color-default": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.Concrete,
    },
    "mui-border-bottom-color-hover": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.BrightWhite,
    },
    "asset-sensor-filters-slider-labels": {
      light: ColorsPalette.LightCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "asset-card-border": {
      light: "transparent",
      dark: ColorsPalette.Concrete,
    },
    "asset-inner-card--border": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.LightCharcoal,
    },
    "asset-table-border": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.LightCharcoal,
    },
    "asset-toggle-view-button-background": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "asset-toggle-view-button-background-light": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.OffWhite,
    },
    "asset-toggle-view-button-text": {
      light: ColorsPalette.OffWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "breadcrumbs-filters-border": {
      light: ColorsPalette.SilverGray,
      dark: ColorsPalette.LightGray,
    },
    "breadcrumbs-path-line": ColorsPalette.DarkBlue,
    "breadcrumbs-filters-icon-color": ColorsPalette.BrightWhite,
    "breadcrumbs-filter-location-update": ColorsPalette.DodgerBlue,
    "breadcrumbs-filter-health": ColorsPalette.BabyBlueEyes,
    "breadcrumbs-filter-cargo": ColorsPalette.PowderBlue,
    "breadcrumbs-filter-door": ColorsPalette.PaleGreen,
    "breadcrumbs-filter-location-entry": ColorsPalette.AmberOrange,
    "breadcrumbs-filter-selected": ColorsPalette.PrimaryBlue,
    "breadcrumbs-table-moving-with-primary-voltage":
      ColorsPalette.ExtraLightBlue,
    "breadcrumbs-table-moving-without-primary-voltage":
      ColorsPalette.AccentGreen,
    "asset-widget-action-button-border": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "asset-widget-action-button-color": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.OffWhite,
    },
    "asset-image-fallback-background": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.DarkCharcoal,
    },
    "sensor-filter-selected": ColorsPalette.PrimaryBlue,
    "box-shadow": {
      light: ColorsPalette.RichBlackOpacity16,
      dark: ColorsPalette.ConcreteOpacity40,
    },
    "date-range-picker--popover-background": {
      light: ColorsPalette.FeatherGray,
      dark: ColorsPalette.DarkCharcoal,
    },
    "custom-tooltip-background": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.RichBlack,
    },
    "gallery-cards-background": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.MidCharcoal,
    },
    "dashboard-view-header-background": {
      light: ColorsPalette.BrightWhite,
      dark: ColorsPalette.DarkCharcoal,
    },
    "dashboard-view-background": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.RichBlack,
    },
    "triple-grid-widget-label": {
      light: ColorsPalette.PrimaryBlueOpacity20,
      dark: ColorsPalette.ExtraLightBlue,
    },
    "dashboard-add-widget-card-description": {
      light: ColorsPalette.DarkCharcoal,
      dark: ColorsPalette.FeatherGray,
    },
    "dashboard-add-widget-card-tags-text": {
      light: ColorsPalette.MidCharcoal,
      dark: ColorsPalette.DarkCharcoal,
    },
    "dashboard-view-apply-changes-popup-border": {
      light: ColorsPalette.Concrete,
      dark: ColorsPalette.MidCharcoal,
    },
    "dashboard-no-widgets-message-color": {
      light: ColorsPalette.PrimaryBlue,
      dark: ColorsPalette.MidCharcoal,
    },
    "dashboard-favorite-organization-star-icon": {
      light: ColorsPalette.ExtraLightBlue,
      dark: ColorsPalette.ExtraLightBlue,
    },
  };

  return colors;
};
