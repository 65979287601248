import {
  AssetInput,
  AssetInputOs,
  useGetAssetsForListOsQuery,
  useGetAssetsForListQuery,
} from "../../../../../graphql/operations";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../../../utils/useFeatureFlag";

interface AssetListPrefetcherProps {
  cachedQueryInput: AssetInput | AssetInputOs;
  queryOptions: {
    keepPreviousData: boolean;
    enabled: boolean;
  };
}

/**
 * Triggers a prefetch for the getAssetsForList
 * If a result is accessed somewhere with the same input,
 * it will return the result from the prefetch done here
 */
const AssetListPrefetcher: React.FC<AssetListPrefetcherProps> = ({
  cachedQueryInput,
  queryOptions,
}) => {
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  useGetAssetsForListQuery(
    {
      input: cachedQueryInput as AssetInput,
    },
    { ...queryOptions, enabled: !fetchAssetsFromOpenSearchFeatureFlag }
  );

  useGetAssetsForListOsQuery(
    {
      input: cachedQueryInput as AssetInputOs,
    },
    { ...queryOptions, enabled: fetchAssetsFromOpenSearchFeatureFlag }
  );

  return <></>;
};

export default AssetListPrefetcher;
