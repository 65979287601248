import { FC, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/formatters";
import { TileComponentProps } from "../../interfaces";
import SecondaryInfo from "../SecondaryInfo";
import DashboardTile from "../index";
import { calculateBucketPercentage } from "./helpers";

type BucketLegend = {
  value: string;
  title: string;
  subTitle: string;
};

const TotalAssets: FC<TileComponentProps> = ({
  tile,
  moveTile,
  findTile,
  clickHandler,
  data,
}) => {
  const { assetsSummary } = data;

  const formattedTotal = assetsSummary.total
    ? formatNumber(assetsSummary.total)
    : "0";
  const metrics: Record<string, BucketLegend> = useMemo(
    () => calculateBucketPercentage(assetsSummary),
    [assetsSummary]
  );

  return (
    <DashboardTile
      clickHandler={clickHandler}
      url={tile.url}
      moveTile={moveTile}
      findTile={findTile}
    >
      <Grid
        container
        data-testid="dashboard-tile-total-assets"
        className="!pt-3 "
      >
        <Grid container item className="!mb-4 !flex-col">
          <Grid item className="!mb-3">
            <Typography
              className="!text-5xl !font-normal !text-blue"
              data-testid="dashboard-tile-total-assets-value"
            >
              {formattedTotal}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="!font-bold">Total Assets</Typography>
          </Grid>
        </Grid>
        <Grid container item className="!mb-4 justify-around">
          {Object.keys(metrics).map((key) => (
            <SecondaryInfo
              key={key}
              value={metrics[key].value}
              title={metrics[key].title}
              subTitle={metrics[key].subTitle}
            />
          ))}
        </Grid>
      </Grid>
    </DashboardTile>
  );
};

export default TotalAssets;
