import { NavigationRoutes } from "../../utils/routes/routesUtils";

export enum AdminPanelTabs {
  users = "Users",
  organizations = "Organizations",
  brands = "Brands",
  configurations = "Configurations",
  profiles = "Profiles",
  sensors = "Sensors",
  regions = "Regions",
  zones = "Zones",
}

export const adminPanelTabs = [
  {
    id: "user",
    name: AdminPanelTabs.users,
    viewRights: "user",
    route: NavigationRoutes.AdminPanelUsers,
  },
  {
    id: "organizations",
    name: AdminPanelTabs.organizations,
    viewRights: "org",
    route: NavigationRoutes.AdminPanelOrganizations,
  },
  {
    id: "sensors",
    name: AdminPanelTabs.sensors,
    viewRights: "sensors",
    route: NavigationRoutes.AdminPanelSensors,
  },
  {
    id: "profiles",
    name: AdminPanelTabs.profiles,
    viewRights: "profiles",
    route: NavigationRoutes.AdminPanelProfiles,
  },
  {
    id: "configurations",
    name: AdminPanelTabs.configurations,
    viewRights: "configurations",
    route: NavigationRoutes.AdminPanelConfigurations,
  },
  {
    id: "brands",
    name: AdminPanelTabs.brands,
    viewRights: "brands",
    route: NavigationRoutes.AdminPanelBrands,
  },
  {
    id: "regions",
    name: AdminPanelTabs.regions,
    viewRights: "regions",
    route: NavigationRoutes.AdminPanelRegions,
  },
  {
    id: "zones",
    name: AdminPanelTabs.zones,
    viewRights: "zones",
    route: NavigationRoutes.AdminPanelZones,
  },
];
