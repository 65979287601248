import { useMemo, useRef } from "react";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";
import { formatNumber } from "../../../../../../../../utils/formatters";
import { useAssetsDataContext } from "../../../../../AssetsDataContext";

const minWeightStatusValues = 0;
const maxWeightStatusValues = 50_000;

const WeightStatusSlider: React.FC = () => {
  const {
    currentFilter: { weightStatus },
    onChangeFilters,
  } = useAssetsDataContext();

  const defaultPowerValues = useRef<number[]>([
    weightStatus?.startValue ?? minWeightStatusValues,
    weightStatus?.endValue ?? maxWeightStatusValues,
  ]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      const [startValue, endValue] = values;
      onChangeFilters({ weightStatus: { startValue, endValue } });
    }
  };

  const dataValues = useMemo(
    () => ({
      defaultValue: defaultPowerValues.current,
      mainBarColor: ["concrete"],
      valueBarColor: ["primary-blue"],
    }),
    [defaultPowerValues]
  );

  const marks = [
    {
      value: minWeightStatusValues,
      label: `${formatNumber(minWeightStatusValues)} kg`,
    },
    {
      value: maxWeightStatusValues,
      label: `${formatNumber(maxWeightStatusValues)} kg`,
    },
  ];

  return (
    <RangeSlider
      min={minWeightStatusValues}
      max={maxWeightStatusValues}
      defaultValues={dataValues.defaultValue}
      marks={marks}
      marksMargin={{ left: "0.5rem", right: "-1.5rem" }}
      onChange={onValueChanged}
    />
  );
};

export default WeightStatusSlider;
