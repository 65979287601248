import { FC, MutableRefObject, useMemo } from "react";
import { Box } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  ConfigurationSetType,
  useGetConfigurationSetsQuery,
  TableViewType,
} from "../../../../../../graphql/operations";
import { Table } from "../../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../../shared/hooks/useExportedFileName";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../constants";
import { useFaultCodeTypeOptions } from "../../Drawers/ABSFaultCode/hooks/useFaultCodeTypeOptions";
import {
  ABS_FAULT_CODES_EXPORT_FILENAME,
  ABS_FAULT_CODES_TABLE_COLUMNS,
  AbsFaultCodeFieldName,
} from "./constants";
import { parseGetFaultCodesConfigurationSetsQueryData } from "./helpers";

export interface ABSFaultCodesTableProps {
  apiRef: MutableRefObject<GridApiPremium>;
  orgId: string | undefined;
  onRowClick: (data: any) => void; // TODO: Fix any when the drawer is implemented
}

const ABSFaultCodesTable: FC<ABSFaultCodesTableProps> = ({
  apiRef,
  orgId,
  onRowClick,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  // Setup base configuration for the table
  const fileName: string = useExportedFileName(ABS_FAULT_CODES_EXPORT_FILENAME);
  const keysList = useMemo(() => Object.values(AbsFaultCodeFieldName), []);

  // Get the data for the table from the server
  const { isLoading, isError, data } = useGetConfigurationSetsQuery(
    {
      input: {
        orgId: orgId ?? "",
        type: ConfigurationSetType.FaultCode,
      },
    },
    {
      enabled: Boolean(orgId),
    }
  );

  const { mapFaultCodeOptionValueToLabel } = useFaultCodeTypeOptions();

  const absFaultCodeConfigurationSetsData = useMemo(() => {
    return parseGetFaultCodesConfigurationSetsQueryData(data).map((data) => ({
      ...data,
      faultCodeType: mapFaultCodeOptionValueToLabel(data?.faultCodeType),
    }));
  }, [data, mapFaultCodeOptionValueToLabel]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={TableViewType.FaultCodesConfigurations}
        showToolbar
        rows={absFaultCodeConfigurationSetsData ?? []}
        apiRef={apiRef}
        onRowClick={onRowClick}
        columns={ABS_FAULT_CODES_TABLE_COLUMNS}
        loading={isLoading}
        error={isError}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={keysList}
        sortKeys={keysList}
        searchMinLength={appConfig.searchMinLength}
        tableName="abs-fault-codes-config"
        isDataRefetching={false}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default ABSFaultCodesTable;
