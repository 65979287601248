import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { omitBy, isNil } from "lodash";
import { UserRole } from "../../../../../graphql/operations";
import { FormFieldDropdownOption } from "../../../../../types";
import { UserRoleDropdownItem } from "../helpers";
import { userSchema } from "../validationSchema";

export interface UserFormValues {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phoneNumber?: string | null;
  groups: UserRole | null;
  customer_orgs_id: string | null;
  [x: string]:
    | string
    | UserRoleDropdownItem
    | Partial<FormFieldDropdownOption>
    | null
    | undefined;
}

export const userFormInitialValues: UserFormValues = {
  firstName: "",
  lastName: "",
  name: "",
  email: "",
  phoneNumber: "",
  groups: null,
  customer_orgs_id: null,
};

export const useUserForm = (
  incomingInitialValues: Partial<UserFormValues> = {}
) => {
  const form = useForm({
    resolver: yupResolver(userSchema),
    values: omitBy(
      { ...userFormInitialValues, ...incomingInitialValues },
      isNil
    ),
  });

  const getValues = useCallback(
    () => userSchema.cast(form.getValues(), { assert: false }),
    [form]
  );

  return { form, getValues };
};
