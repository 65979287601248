import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import config from "../../../config";
import { useSessionTimeout } from "./useSessionTimeout";

export const SessionTimeoutModal = () => {
  const { handleCloseModal, handleCancelClick, isOpen } = useSessionTimeout({
    idleTimeout: config.idleTimeout ? +config.idleTimeout : undefined,
    totalIdleThreshold: config.totalIdleThreshold
      ? +config.totalIdleThreshold
      : undefined,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      maxWidth="xs"
      aria-labelledby="timeout-dialog-title"
      aria-describedby="timeout-dialog-description"
    >
      <DialogTitle
        data-testid="timeout-dialog-title"
        id="timeout-dialog-title"
        className="flex items-center justify-between"
      >
        <div>Are you still there?</div>
        <IconButton aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          data-testid="timeout-dialog-description"
          id="timeout-dialog-description"
        >
          It looks like you've been inactive for 15 minutes. Would you like to
          stay logged in?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="timeout-dialog-cancel-btn"
          onClick={handleCancelClick}
          variant="text"
          className="!normal-case !text-brand"
        >
          No, log me out
        </Button>
        <Button
          data-testid="timeout-dialog-agree-btn"
          className="!rounded-[100px] !bg-brand !normal-case"
          autoFocus
          variant="contained"
          onClick={handleCloseModal}
        >
          Yes, I'm still here
        </Button>
      </DialogActions>
    </Dialog>
  );
};
