import { FC, useEffect, useMemo, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { DateRangeKey } from "../../../../../constants/map";
import {
  useFindAssetByIdQuery,
  useGetDwellHistoricalDataQuery,
} from "../../../../../graphql/operations";
import { Table, getRows } from "../../../../../shared/components/Table";
import useAssetExportFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import {
  DATE_TIME_FORMAT,
  DEFAULT_TIMEZONE,
  formatDate,
  formatDateInTimezone,
  getSubMonths,
  mapTimezoneToIANAValue,
} from "../../../../../utils/date";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../../../AdminPanel/tabs/constants";
import { getColumns, TableRowEventData } from "./columns";

enum DwellColorSchema {
  low = "success",
  medium = "yellow-custom-slider",
  high = "warning",
  mega = "error",
}

type DwellStatus = "low" | "medium" | "high" | "mega";

const dwellLabelStyles = {
  display: "inline-block",
  margin: "7px 0 5px",
  padding: "3px",
  color: "#fff",
  fontSize: "12px",
  textTransform: "capitalize",
  borderRadius: "3px",
};

type CardsData = {
  averageDwell?: number | null;
  averageDwellStatus?: string | null;
  maxDwell?: number | null;
  maxDwellStatus?: string | null;
  geofencesNumber?: number | null;
  mostDwellingGeofenceName?: string | null;
  dateTimeOfMaxDwellData?: Date | null;
  dateTime?: Date | null;
};

export interface DwellTabPanelProps {
  selectedAssetId: string;
}

const DwellTab: FC<DwellTabPanelProps> = ({ selectedAssetId }) => {
  const [rows, setRows] = useState<TableRowEventData[]>([]);
  const [cardsData, setCardsData] = useState<CardsData | undefined | null>(
    null
  );
  const gridApiRef = useGridApiRef();
  const rowsPerPage = 100;
  const userData = useUserData();

  const columns = getColumns(usePreferredTimezone(), "asset");
  const SEARCH_KEYS = useMemo(() => columns.map((column) => column.field), []); // eslint-disable-line react-hooks/exhaustive-deps
  const { data: assetData } = useFindAssetByIdQuery({
    assetId: selectedAssetId,
  });

  const { orgName, customerOrgId, imei } = useMemo(
    () => ({
      assetId: assetData?.findAssetById?.asset_id ?? "",
      imei: assetData?.findAssetById?.imei ?? "",
      customerOrgId: assetData?.findAssetById?.customer_orgs_id ?? "",
      orgName: assetData?.findAssetById?.org_name ?? "",
    }),
    [assetData]
  );

  const fileName = useAssetExportFileNameForOrg(orgName, "Geofence Dwell");

  const {
    data: dwellHistoricalData,
    isLoading,
    isError,
  } = useGetDwellHistoricalDataQuery(
    {
      input: {
        startDate:
          formatDate(getSubMonths(new Date(), 6), "yyyy-MM-dd") +
          "T00:00:00.000Z",
        endDate: formatDate(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z",
        customerOrgId,
        imei,
        period: DateRangeKey.Custom,
      },
    },
    {
      enabled: Boolean(imei) && Boolean(customerOrgId),
    }
  );

  const getTotalDwell = (totalDwell?: number | null) => {
    const dwellDays = Number(totalDwell?.toFixed(0));
    return dwellDays + (dwellDays && dwellDays === 1 ? " day" : " days");
  };

  const renderIcon = (dwellType: DwellStatus) => {
    switch (dwellType) {
      case "low":
        return (
          <CheckCircleOutlineIcon
            className="!h-[12px] !w-[12px] text-xs mb-px"
            data-testid="dwell-icon-low"
          />
        );
      case "medium":
        return (
          <NotificationImportantOutlinedIcon
            className="!h-[12px] !w-[12px] text-xs mb-px"
            data-testid="dwell-icon-medium"
          />
        );
      case "high":
        return (
          <WarningAmberIcon
            className="!h-[12px] !w-[12px] text-xs mb-px"
            data-testid="dwell-icon-high"
          />
        );
      case "mega":
        return (
          <ErrorOutlineIcon
            className="!h-[12px] !w-[12px] text-xs mb-px"
            data-testid="dwell-icon-mega"
          />
        );
    }
  };

  useEffect(() => {
    // sort data by date property
    const sortedData = dwellHistoricalData?.searchHistoricalEventHistory?.data
      ?.map((item) => {
        const eventDate = item?.dwell?.dateTime;
        const validEventDate = eventDate ? new Date(eventDate) : new Date("");
        return {
          ...item?.dwell,
          dateTime: validEventDate,
        };
      })
      .sort(
        (a, b) => (a?.dateTime?.getTime() || 0) - (b?.dateTime?.getTime() || 0)
      )
      .reverse();

    // prepare data for rows
    const rowArray: TableRowEventData[] = [];
    sortedData?.forEach((event) => {
      const rowData = {
        ...event,
      } as TableRowEventData;
      rowData.totalDwell = getTotalDwell(event.totalDwell);
      rowData.dateTime = event?.dateTime;

      rowArray.push(rowData);
    });
    setRows(getRows(rowArray, columns, "", "", "id"));

    // prepare data for 2 cards
    setCardsData(dwellHistoricalData?.searchDwellAverageMaxData);
    /* eslint-disable */
  }, [dwellHistoricalData]);

  return (
    <Box
      className="h-full overflow-auto"
      sx={{ background: "var(--dwell-bg)" }}
      data-testid="dwell-tab-panel"
    >
      {isLoading ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="dwell-loading-screen"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className="p-[16px]">
            <Box
              className="flex flex-col sm:flex-row justify-between p-[16px] rounded-md"
              sx={{ background: "var(--dwell-bg-cards-frame-bg)" }}
            >
              <Box
                className="w-[100%] sm:w-[49%] p-[16px] rounded-md mb-[16px] sm:mb-0"
                sx={{ background: "var(--dwell-bg-cards-bg)" }}
                data-testid="dwell-card-average"
              >
                <Typography
                  sx={{
                    color: "var(--brand)",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "26px",
                  }}
                >
                  Average Dwell
                </Typography>
                {cardsData ? (
                  <>
                    {cardsData.averageDwellStatus && (
                      <Typography
                        sx={{
                          ...dwellLabelStyles,
                          background: `var(--${
                            DwellColorSchema[
                              cardsData?.averageDwellStatus?.toLowerCase() as keyof typeof DwellColorSchema
                            ]
                          })`,
                        }}
                      >
                        {renderIcon(
                          cardsData.averageDwellStatus?.toLowerCase() as DwellStatus
                        )}
                        &nbsp;
                        {cardsData.averageDwellStatus}&nbsp;
                      </Typography>
                    )}
                    <Typography sx={{ color: "var(--primary)" }}>
                      Duration:&nbsp;
                      {getTotalDwell(cardsData?.averageDwell)}
                    </Typography>
                    <Typography sx={{ color: "var(--primary)" }}>
                      Number of Geofences:&nbsp;
                      {cardsData.geofencesNumber ?? ""}
                    </Typography>
                    <Typography sx={{ color: "var(--primary)" }}>
                      Last update:&nbsp;
                      {cardsData.dateTime
                        ? formatDate(
                            formatDateInTimezone(
                              cardsData.dateTime,
                              DATE_TIME_FORMAT,
                              mapTimezoneToIANAValue(
                                userData?.user_preferences?.time_zones ??
                                  userData?.customerOrg?.time_zones ??
                                  DEFAULT_TIMEZONE
                              )
                            )
                          )
                        : ""}
                    </Typography>
                  </>
                ) : (
                  <Typography sx={{ color: "var(--primary)" }}>
                    No data found.
                  </Typography>
                )}
              </Box>

              <Box
                className="w-[100%] sm:w-[49%] p-[16px] rounded-md"
                sx={{ background: "var(--dwell-bg-cards-bg)" }}
                data-testid="dwell-card-maximum-geofence"
              >
                <Typography
                  sx={{
                    color: "var(--brand)",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "26px",
                  }}
                >
                  Maximum Geofence Dwell
                </Typography>
                {cardsData ? (
                  <>
                    {cardsData.maxDwellStatus && (
                      <Typography
                        sx={{
                          ...dwellLabelStyles,
                          background: `var(--${
                            DwellColorSchema[
                              cardsData.maxDwellStatus?.toLowerCase() as keyof typeof DwellColorSchema
                            ]
                          })`,
                        }}
                      >
                        {renderIcon(
                          cardsData.maxDwellStatus?.toLowerCase() as DwellStatus
                        )}
                        &nbsp;
                        {cardsData.maxDwellStatus}
                        &nbsp;
                      </Typography>
                    )}
                    <Typography sx={{ color: "var(--primary)" }}>
                      Duration:&nbsp;
                      {getTotalDwell(cardsData?.maxDwell)}
                    </Typography>
                    <Typography sx={{ color: "var(--primary)" }}>
                      Name of Geofence:&nbsp;
                      {cardsData.mostDwellingGeofenceName ?? ""}
                    </Typography>
                    <Typography sx={{ color: "var(--primary)" }}>
                      Last update:&nbsp;
                      {cardsData.dateTimeOfMaxDwellData
                        ? formatDate(
                            formatDateInTimezone(
                              cardsData.dateTimeOfMaxDwellData,
                              DATE_TIME_FORMAT,
                              mapTimezoneToIANAValue(
                                userData?.user_preferences?.time_zones ??
                                  userData?.customerOrg?.time_zones ??
                                  DEFAULT_TIMEZONE
                              )
                            )
                          )
                        : ""}
                    </Typography>
                  </>
                ) : (
                  <Typography sx={{ color: "var(--primary)" }}>
                    No data found.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="py-4 px-8 md:p-8 h-full w-full"
            data-testid="dwell-table"
          >
            {/* table */}
            <Table
              tableName="dwell-tab"
              columns={columns}
              rows={rows}
              apiRef={gridApiRef}
              pageSize={rowsPerPage}
              loading={isLoading}
              error={isError}
              showToolbar={true}
              allowExport
              enableSearch={true}
              searchExactMatch
              searchKeys={SEARCH_KEYS}
              exportProps={{
                csvOptions: {
                  fileName,
                },
                excelOptions: {
                  fileName,
                },
                printOptions: {
                  fileName,
                },
              }}
              mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default DwellTab;
