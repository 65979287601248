import { GridColDef } from "@mui/x-data-grid-premium";
import { Dwell } from "../../../../../graphql/operations";
import {
  columnTimezoneDateTimeFormatParams,
  columnTimezoneTimeFormatParams,
} from "../../../../../utils";
import { DwellGridColDef } from "../../Geofences/GeofenceDwellHistory/helpers";

export interface TableRowEventData extends Dwell {
  totalDwell?: any;
  dateTime?: any;
}

const getBaseColumn = (
  field: string,
  headerName: string,
  minWidth: number = 120,
  options?: Partial<GridColDef>
) => ({
  field,
  headerName,
  flex: 1,
  minWidth,
  cellClassName: "capitalize",
  ...options,
});

const getTimezoneColumn = (
  field: string,
  headerName: string,
  timezone: string,
  minWidth = 120,
  isDateTimeNeeded: boolean = true
) => {
  const dateField = isDateTimeNeeded
    ? columnTimezoneDateTimeFormatParams(timezone)
    : columnTimezoneTimeFormatParams(timezone);

  return {
    ...getBaseColumn(field, headerName, minWidth),
    ...dateField,
  };
};

export const getColumns = (
  timezone: string,
  typeOfTable: string
): GridColDef<TableRowEventData>[] | DwellGridColDef[] => {
  const assetDwellColumns = [
    getBaseColumn("geofenceName", "Name", 215),
    getBaseColumn("category", "Category", 150),
    getTimezoneColumn("arrivalTime", "Arrival Time", timezone, 150, false),
    getBaseColumn("currentCargoState", "Current Cargo State", 150),
    getTimezoneColumn("dateTime", "Date and Time", timezone, 180),
    getBaseColumn("unloadDuration", "Unload Duration", 150, {
      renderCell: (option) => Math.round(option.value / 60) + "h",
    }),
    getTimezoneColumn("departureTime", "Departure Time", timezone, 150, false),
    getBaseColumn("arrivalCargoState", "Cargo Status Upon Arrival", 215),
    getBaseColumn("departureCargoState", "Cargo Status Upon Exit", 215),
    getBaseColumn("totalDwell", "Total Dwell", 150),
  ];

  const geofenceDwellColumns = [
    getBaseColumn("asset_id", "Asset ID", 150),
    getBaseColumn("imei", "Imei", 150),
    getBaseColumn("arrival_cargo_state", "Cargo Status", 150),
    getTimezoneColumn("timestamp", "Date And Time", timezone, 180),
    getBaseColumn("unload_duration", "Unload Duration", 120),
    getTimezoneColumn("departure_time", "Departure Time", timezone, 150, false),
    getBaseColumn("geofence_id", "Geofence Id", 215),
    getBaseColumn("geofence_name", "Geofence Name", 215),
    getBaseColumn("total_dwell", "Total Dwell", 150),
    getTimezoneColumn("arrival_time", "Arrival Time", timezone, 150, false),
  ];

  return typeOfTable === "asset" ? assetDwellColumns : geofenceDwellColumns;
};
