import { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useFindAssetByIdQuery } from "../../../../../graphql/operations";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import LiftgateBatteryStatus from "./LiftgateBatteryStatus/LiftgateBatteryStatus";

export const LiftgateTabPanel: React.FC = () => {
  // Hooks
  const { selectedAssetId } = useAssetsDataContext();

  // Queries
  const { data: assetData } = useFindAssetByIdQuery(
    {
      assetId: selectedAssetId as string,
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  // Variables
  const liftgateSensorReadings = useMemo(
    () => assetData?.findAssetById?.sensors?.liftgateAdvertisement,
    [assetData]
  );

  return (
    <Box sx={{ padding: "1rem" }} data-testid="liftgate-tab-panel">
      {liftgateSensorReadings?.data?.statusBattery && (
        <Grid container direction="row" spacing={2}>
          <Grid item lg={3}>
            <LiftgateBatteryStatus
              status={liftgateSensorReadings.data.statusBattery}
              date={liftgateSensorReadings.readingDate}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
