import { useMemo } from "react";
import { Box } from "@mui/system";
import { useGridApiRef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { orderBy, startCase, toLower } from "lodash";
import { TIME_ZONE_OPTIONS } from "../../../../../constants/account";
import {
  roleToHumanReadableMapper,
  TERMS_ACCEPTED,
  USER_EVENTS_MAPPER,
  USER_GROUPS_UPDATED,
  USER_IMPERSONATION_END,
  USER_IMPERSONATION_START,
  USER_TIME_ZONE_PREFERENCE_UPDATED,
  USER_PHONE_REMOVED,
  USER_STATUS_UPDATED,
  UserEventsMapper,
} from "../../../../../constants/users";
import { useAppContext } from "../../../../../context/AppContext";
import {
  useFindUserEventsQuery,
  useGetUserDataQuery,
  TableViewType,
} from "../../../../../graphql/operations";
import { Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { NO_SIDE_PANEL_TABLE } from "../../constants";

const SEARCH_KEYS = ["event", "user", "event_date"];

interface UserEvent {
  event: {
    current_value?: string | null;
    previous_value?: string | null;
    triggered_by: string;
    type: string;
  };
}

const generateEventMessage = (
  userEvent: string,
  current_value: string,
  previous_value: string,
  isCapitalize = false
) => {
  const currentValue = isCapitalize
    ? startCase(toLower(current_value))
    : current_value;
  const previousValue = previous_value
    ? isCapitalize
      ? startCase(toLower(previous_value))
      : previous_value
    : "";
  return `${
    USER_EVENTS_MAPPER[userEvent as keyof UserEventsMapper]
  } was updated to ${currentValue} ${
    previousValue ? `from ${previousValue}` : ""
  }`;
};

const eventMapper = (row: UserEvent) => {
  const userEvent = row?.event?.type;
  let output = "";
  switch (userEvent) {
    case USER_TIME_ZONE_PREFERENCE_UPDATED:
      output = generateEventMessage(
        userEvent,
        //@ts-ignore
        TIME_ZONE_OPTIONS.find((x) => x.value === row?.event?.current_value)
          .label,
        (row?.event?.previous_value
          ? TIME_ZONE_OPTIONS.find(
              (x) => x.value === row?.event?.previous_value
            )?.label
          : row?.event?.previous_value) as string
      );
      break;
    case USER_GROUPS_UPDATED:
      output = generateEventMessage(
        userEvent,
        roleToHumanReadableMapper[row?.event?.current_value || ""],
        (row?.event?.previous_value
          ? roleToHumanReadableMapper[row?.event?.previous_value]
          : row?.event?.previous_value) as string,
        true
      );
      break;
    case USER_STATUS_UPDATED:
      output = generateEventMessage(
        userEvent,
        row?.event?.current_value as string,
        row?.event?.previous_value as string,
        true
      );
      break;
    case TERMS_ACCEPTED:
    case USER_IMPERSONATION_START:
    case USER_IMPERSONATION_END:
    case USER_PHONE_REMOVED:
      output = USER_EVENTS_MAPPER[userEvent];
      break;
    default:
      output = generateEventMessage(
        userEvent,
        row?.event?.current_value as string,
        row?.event?.previous_value as string
      );
  }

  return output;
};

export interface UserHistoryTableProps {
  email: string;
}

const UserHistoryTable: React.FC<UserHistoryTableProps> = ({ email }) => {
  const {
    state: { appConfig },
  } = useAppContext();

  const {
    data: eventsData,
    isLoading,
    isSuccess,
  } = useFindUserEventsQuery(
    { input: { email: email } },
    {
      onSettled: (_, _error) => {
        if (_error) {
          return null;
        }
      },
    }
  );

  const historyGridApiRef = useGridApiRef();
  const { data: accountData } = useGetUserDataQuery();
  const timeZone = accountData?.me?.user_preferences?.time_zones;

  const fileName = useExportedFileName("Activity Log");

  const rows = useMemo(
    () =>
      eventsData?.findUserEvents
        ? orderBy(
            eventsData?.findUserEvents.map((row: any) => ({
              event: eventMapper(row),
              event_date: row?.event_date,
              user: row?.event?.triggered_by,
              id: row?._id,
            })),
            "event_date",
            "desc"
          )
        : [],
    [eventsData?.findUserEvents]
  );

  const columns = useMemo(
    () => [
      {
        field: "event",
        headerName: "Event",
        flex: 1,
        minWidth: 120,
      },
      { field: "user", headerName: "User", flex: 1, minWidth: 120 },
      {
        field: "event_date",
        headerName: "Event Date",
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value
            ? new Date(params.value).toLocaleString("en-US", {
                ...(timeZone && { timeZone: timeZone }),
              })
            : "",
      },
    ],
    [timeZone]
  );

  return (
    <Box className="h-full w-full">
      <Table
        tableType={TableViewType.UserActivityLog}
        showToolbar
        tableName="user-history"
        columns={columns}
        rows={rows}
        apiRef={historyGridApiRef}
        loading={isLoading}
        error={!isLoading && !isSuccess ? true : null}
        className="w-full border-solid"
        enableSearch={true}
        searchKeys={SEARCH_KEYS}
        searchMinLength={appConfig.searchMinLength}
        isPopOver
        initialState={{
          sorting: {
            sortModel: [{ field: "event_date", sort: "desc" }],
          },
        }}
        autoHeight={true}
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        mobileBreakpoint={NO_SIDE_PANEL_TABLE}
      />
    </Box>
  );
};

export default UserHistoryTable;
