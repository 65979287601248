import React, { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../../../graphql/operations";
import PieChartWidget, {
  DataItem,
} from "../../../../../../../shared/components/DashboardWidgets/PieChartWidget";
import {
  mapBinaryChartColors,
  mapLegendTextColor,
} from "../../../../../../../shared/components/DashboardWidgets/PieChartWidget/utils";
import { useSelectedOrg } from "../../../../../../../shared/hooks/useSelectedOrg";
import { hasNoPermission } from "../../../../shared/utils";

export type DashboardWidgetCargoUltrasonicSensorStatusData = {
  statuses: CargoUltrasonicSensorStatusData[];
};

export type CargoUltrasonicSensorStatusData = {
  name: string;
  count: number;
};

export interface DashboardWidgetCargoUltrasonicSensorStatus
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetCargoUltrasonicSensorStatusData;
}

export interface CargoUltrasonicSensorStatusWidgetProps {
  widget: DashboardWidgetCargoUltrasonicSensorStatus;
}

export const mapWidgetData = (
  data: DashboardWidgetCargoUltrasonicSensorStatusData
): DataItem[] => {
  if (!data || !data.statuses) {
    return [];
  }

  return data.statuses.map((status, index) => ({
    name: status.name,
    value: status.count,
    color: mapBinaryChartColors(index),
    legendTextColor: mapLegendTextColor(status.name),
  }));
};

export const CargoUltrasonicSensorStatusWidget: React.FC<
  CargoUltrasonicSensorStatusWidgetProps
> = ({ widget }) => {
  const title = widget.name ?? "";
  const currentOrg = useSelectedOrg();

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData: DashboardWidgetCargoUltrasonicSensorStatusData =
      JSON.parse(getDashboardWidgetData?.getDashboardWidget?.data ?? null);
    return mapWidgetData(parsedData);
  }, [getDashboardWidgetData?.getDashboardWidget?.data]);

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--cargo-ultrasonic-sensor-status"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the cargo ultrasonic
          sensor status widget! Please try again later.
        </Typography>
      ) : (
        <PieChartWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
