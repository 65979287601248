import { FC, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  ASSIGN_SENSOR_PROFILES_FILE_TEMPLATE_PATH,
  PAGE_SNACKBAR,
} from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import { BatchTemplate } from "../../../graphql/operations";
import { ParseFileContentError } from "../../../utils";
import {
  BatchUploadDialogProps,
  useBatchUploadForm,
} from "../../../views/BatchesView/BatchManagementUtils";
import { useUploadJsonBatchFileToS3 } from "../../../views/BatchesView/hooks/useUploadJsonBatchFileToS3";
import { parseContentAsJsonBlobFile } from "../TableActionsMenu/helpers";
import UploadFileDialog from "../UploadFileDialog/UploadFileDialog";
import UploadResult from "../UploadFileDialog/UploadResult";
import {
  mapFileDataToCreateAssetInput,
  parseSensorProfilesFile,
} from "./helpers";

export const UploadSensorProfilesDialog: FC<BatchUploadDialogProps> = ({
  title,
  customerOrg,
  dialogFields,
  isOpen,
  onClose,
  onUpload,
}: BatchUploadDialogProps) => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const { uploadBatchFileToS3 } = useUploadJsonBatchFileToS3();
  const { form, getValues } = useBatchUploadForm(title, customerOrg);

  const [uploadedMessage, setUploadedMessage] = useState<string | undefined>();
  const [isDataUploading, setIsDataUploading] = useState(false);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [parseFileResult, setParseFileResult] = useState<{
    validatedSensorProfiles: any[];
    validationErrors: ParseFileContentError[];
  }>({ validatedSensorProfiles: [], validationErrors: [] });
  const resetState = () => {
    setIsDataUploading(false);
    setIsDataUploaded(false);
    setParseFileResult({ validatedSensorProfiles: [], validationErrors: [] });
  };

  const handleSubmit = async (file: File) => {
    setIsDataUploading(true);

    const {
      org_select: { id: orgId },
      batch_name,
    } = getValues();

    try {
      const { fileData, errors: parseErrors } = await parseSensorProfilesFile(
        file
      );
      const { validatedSensorProfiles, validationErrors } =
        mapFileDataToCreateAssetInput(fileData, orgId);
      setParseFileResult({
        validatedSensorProfiles,
        validationErrors: [...validationErrors, ...parseErrors],
      });

      const fileName = file.name.replace(/\.(csv|xls|xlsx)$/i, ".json");
      setUploadedMessage(`${fileName} ${batch_name} Uploaded`);

      const sensorProfiles = validatedSensorProfiles.map(
        ({ sensor }: any) => sensor
      );
      const jsonFile = parseContentAsJsonBlobFile(sensorProfiles, fileName);

      await uploadBatchFileToS3(
        jsonFile,
        BatchTemplate.AssignSensorProfiles,
        batch_name,
        orgId
      );
    } catch (error) {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Devices Uploading Failed",
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    }

    setIsDataUploading(false);
    setIsDataUploaded(true);

    if (onUpload) {
      onUpload();
    }

    queryClient.invalidateQueries(["getAssetsWithSensorProfiles"]);
    queryClient.invalidateQueries(["getSensorProfilesForOrganization"]);
  };

  const onFilesUploadDialogClose = () => {
    onClose();
    resetState();
  };

  return (
    <UploadFileDialog
      title={title}
      form={form}
      allowedFormats={["csv", "xls", "xlsx"]}
      dialogFields={dialogFields}
      isOpen={isOpen}
      onClose={onFilesUploadDialogClose}
      handleSubmit={handleSubmit}
      isDataUploading={isDataUploading}
      isDataUploaded={isDataUploaded}
      downloadTemplateFilePath={ASSIGN_SENSOR_PROFILES_FILE_TEMPLATE_PATH}
      uploadResultComponent={
        <UploadResult
          uploadedMessage={uploadedMessage}
          errors={parseFileResult.validationErrors}
          itemName={"sensor"}
        />
      }
    />
  );
};
