import { GridColumnOrderChangeParams } from "@mui/x-data-grid-premium";
import {
  ReportAlertHistoryTableData,
  DwellHierarchyTableData,
} from "../../../graphql/operations";
import { TableGridColDef } from "../../../shared/components/Table";
import { ReportGridColDef, ReportGridColTableData } from "../helpers/helpers";
import { ReportWithParameters } from "../interfaces";

export const changeColumnsOrder = (
  colData: GridColumnOrderChangeParams,
  columns: (
    | ReportGridColDef
    | TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>
  )[],
  report: ReportWithParameters
) => {
  const { targetIndex, field } = colData;
  const oldIndex = columns.findIndex((col) => col.field === field);
  const newColumns = [...columns];
  const newReportColumns = [...report.columns];
  const oldReportColumnIndex = newReportColumns.findIndex(
    (col) => col.field === field
  );
  newColumns.splice(targetIndex, 0, newColumns.splice(oldIndex, 1)[0]);
  newReportColumns.splice(
    targetIndex,
    0,
    newReportColumns.splice(oldReportColumnIndex, 1)[0]
  );
  return { newColumns, newReportColumns };
};
