import { FC, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { ImageList, ImageListItem, Box } from "@mui/material";
import { Asset } from "../../../../graphql/operations";
import TableFooter from "../../../../shared/components/Table/TableFooter";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { assetsListInputParams } from "../../helpers/helpers";
import { useAssetsDataContext } from "../../shared/AssetsDataContext";
import { AssetGalleryCard } from "./AssetGalleryCard";

export interface AssetsGalleryProps {
  assetsList: Asset[];
  isAssetDataTableRefetching: boolean;
  isAssetDataTableLoading: boolean;
  handleAssetClick: (asset: Asset) => void;
  totalAssetsLength: number;
}

const GRID_GAP = 16;
/*
  This width is the max width each card will take
  given our current configuration (the card content, etc.).
  It's more so used for rough calculations rather than being exact.
*/
const ITEM_WIDTH = 271.35;
const LIST_ITEM_FULL_WIDTH = ITEM_WIDTH + GRID_GAP;
const MAX_COLUMNS_PER_ROW = 6;

export const AssetsGallery: FC<AssetsGalleryProps> = ({
  assetsList,
  isAssetDataTableRefetching,
  isAssetDataTableLoading,
  handleAssetClick,
  totalAssetsLength,
}) => {
  const { onPageChange, currentPageNo } = useAssetsDataContext();

  const wrapperRef = useRef(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const currentRef = wrapperRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  const columnNumber = useMemo(() => {
    if (dimensions.width) {
      const numberOfColumns = Math.min(
        Math.trunc(dimensions.width / LIST_ITEM_FULL_WIDTH),
        MAX_COLUMNS_PER_ROW
      );
      return numberOfColumns;
    }

    return 0;
  }, [dimensions.width]);

  const handleNext = useCallback(
    (page: number) => {
      onPageChange(page);
    },
    [onPageChange]
  );

  useSpinner(isAssetDataTableLoading || isAssetDataTableRefetching);

  return (
    <Box
      ref={wrapperRef}
      className="h-[99%] w-full flex flex-1 justify-center mt-2 ml-2 pl-1 pt-2 !bg-secondary"
      data-testid="assets-gallery"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: dimensions.height,
          width: "100%",
        }}
      >
        <ImageList
          variant="masonry"
          cols={columnNumber}
          gap={GRID_GAP}
          sx={{ alignSelf: "center", width: "100%", paddingRight: "1rem" }}
        >
          {assetsList.map((asset) => (
            <ImageListItem
              key={asset._id}
              sx={{
                boxShadow: "0 2px 4px 0px var(--box-shadow)",
                borderRadius: "0.5rem",
              }}
            >
              <AssetGalleryCard
                asset={asset}
                isFetchingAssetById={isAssetDataTableLoading}
                handleAssetClick={handleAssetClick}
              />
            </ImageListItem>
          ))}
          {!isAssetDataTableLoading && (
            <ImageListItem cols={columnNumber}></ImageListItem>
          )}
        </ImageList>

        {!isAssetDataTableLoading && (
          <Box className="text-sm text-primary font-light mr-[-1rem]">
            <TableFooter
              page={currentPageNo}
              onPageChange={(page) => handleNext(page)}
              pages={
                totalAssetsLength
                  ? Math.ceil(totalAssetsLength / assetsListInputParams.limit)
                  : 0
              }
              rowsPerPage={assetsListInputParams.limit}
              selectedCount={0}
              totalRows={totalAssetsLength}
              pagination={true}
              noBorder={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
