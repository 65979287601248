import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Divider, Skeleton } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { IsEqualCustomizer, isEmpty, isEqualWith, merge } from "lodash";
import { ReactComponent as EllipseIcon } from "../../../../../assets/svgs/ellipse.svg";
import { ReactComponent as RectangleIcon } from "../../../../../assets/svgs/rectangle.svg";
import {
  ASSETS_LIST_ITEM_HEIGHT,
  GEOFENCE_LIST_ITEM_HEIGHT,
  ASSETS_LIST_PAGINATION_HEIGHT,
  ASSETS_LIST_WIDTH,
  CHECK_ICON_STYLE,
  LOCATION_ICON_STYLE,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import {
  GeofenceData,
  Asset,
  useFindAssetByIdQuery,
  AssetSort,
  AssetInput,
  TableFiltersInput,
  AssetInputOs,
} from "../../../../../graphql/operations";
import ActionButton from "../../../../../shared/components/Button/ActionButton";
import { WithPermissions } from "../../../../../shared/components/WithPermissions";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import { getItemsPerPage, useFeatureFlag } from "../../../../../utils";
import { getStaleTime } from "../../../../../utils/date";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { mapAssetFilters } from "../../../TableView/components/AssetsTable/utils";
import {
  assetsListInputParams,
  defaultAssetsFilter,
  splitViewportAtMeridian,
} from "../../../helpers/helpers";
import {
  PageTypes,
  useAssetsDataContext,
} from "../../../shared/AssetsDataContext";
import AssetListPrefetcher from "./AssetListPrefetcher";
import AssetsListFooter from "./AssetsHeaderFooter/AssetsListFooter";
import AssetsListHeader from "./AssetsListHeader";
import { useAssetsListData } from "./hooks/useAssetsListData";

export interface AssetsListProps {
  onFeatureClick: (asset: Asset) => void;
  onGeofenceClick: (geofence: GeofenceData) => void;
}

const AssetsList: React.FC<AssetsListProps> = ({
  onFeatureClick,
  onGeofenceClick,
}) => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const location = useLocation();
  const pageType = useMemo<PageTypes>(
    () =>
      location.pathname.includes("map")
        ? PageTypes.AssetMap
        : PageTypes.Geofences,
    [location]
  );
  const listRef = useRef<HTMLDivElement | null>(null);

  const [assetsInput, setAssetsInput] = useState(assetsListInputParams);

  const {
    currentFilter: { complexFiltersMongodb },
    selectedAssetId,
    sortedGeofences,
    selectedGeofence,
    assetsPagination,
    onPageChange,
    currentSort,
    setCurrentSort,
    currentPageNo,
    filters,
    searchParams,
    geofenceTypes,
    assetsSearchInput,
    setShowAddAsset,
    setSelectedGeofence,
    setGeofenceForUpdate,
    setIsDrawingGeofence,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
    setAddGeofenceDrawerOpen,
    addGeofenceDrawerOpen,
    isDrawingGeofence,
    isGeofencesLoading,
    setAssetsImeisHandler,
    setIsGeofenceDashboardVisible,
    restoreGeofencePreviousFilters,
    resetGeofenceFiltersToDefault,
    currentFilter,
  } = useAssetsDataContext();
  const userData = useUserData();

  const [listFilters, setListFilters] = useState(defaultAssetsFilter);
  const [tableFilters, setTableFilters] = useState<{
    filterList: TableFiltersInput | undefined;
  }>();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  useEffect(() => {
    const filters = mapAssetFilters(
      currentFilter,
      userData,
      selectedOrganization?.value,
      fetchAssetsFromOpenSearchFeatureFlag
    );
    if (JSON.stringify(filters) !== JSON.stringify(tableFilters?.filterList)) {
      setTableFilters({ filterList: filters });
    }
  }, [
    selectedOrganization,
    currentFilter,
    assetsSearchInput,
    userData,
    fetchAssetsFromOpenSearchFeatureFlag,
    tableFilters?.filterList,
  ]);

  useEffect(() => {
    const newListFilters = merge({}, defaultAssetsFilter, filters);

    setListFilters(newListFilters);
  }, [filters]);
  const [firstRenderState, setFirstRenderState] = useState<boolean>(false);

  useEffect(() => {
    setFirstRenderState(true);
  }, []);

  const assetsPerPage = appConfig.table.defaultRowsPerPage;
  const zoomLevelUrlParam = searchParams.get("zoomLevel");
  const zoom = useMemo(() => {
    return zoomLevelUrlParam
      ? { zoom: parseFloat(zoomLevelUrlParam) }
      : { zoom: 3 };
  }, [zoomLevelUrlParam]);

  const viewportFromUrl = JSON.parse(searchParams.get("viewport") ?? "[]");
  const viewport = viewportFromUrl?.length ? { viewport: viewportFromUrl } : {};
  const orgStaticFilter = useMemo(() => {
    return { org_name: selectedOrganization?.label };
  }, [selectedOrganization?.label]);

  const processedViewport = useMemo(() => {
    if (viewport.viewport) {
      return splitViewportAtMeridian(viewport.viewport);
    }
    return null;
  }, [viewport.viewport]);

  const filtersInput = fetchAssetsFromOpenSearchFeatureFlag
    ? tableFilters
    : listFilters;

  const getAssetsForListInput: AssetInput | AssetInputOs = useMemo(() => {
    return {
      ...assetsInput,
      ...filtersInput,
      viewport: processedViewport,
      ...zoom,
      complexFilters: !isEmpty(complexFiltersMongodb)
        ? JSON.stringify([{ ...complexFiltersMongodb }, orgStaticFilter])
        : JSON.stringify(orgStaticFilter),
      search: assetsSearchInput,
      skip: assetsPagination,
    };
  }, [
    assetsInput,
    filtersInput,
    processedViewport,
    zoom,
    complexFiltersMongodb,
    orgStaticFilter,
    assetsSearchInput,
    assetsPagination,
  ]);

  const getAssetsForListOptions = {
    keepPreviousData: true,
    enabled:
      firstRenderState &&
      processedViewport !== null &&
      pageType === PageTypes.AssetMap,
  };

  const {
    isFetching: isGetAssetsForListFetching,
    assets: assetsList,
    imeis,
    total: totalAssets,
  } = useAssetsListData(getAssetsForListInput, getAssetsForListOptions);

  // customize isEqualWith to skip the skip property
  const comparatorCustomizer: IsEqualCustomizer = (
    objValue,
    otherValue,
    key
  ) => {
    if (key === "skip") return true;
  };
  const previousSkipBaseRef = useRef<number | null>(null);
  const assetsForListInputRef = useRef<AssetInput | AssetInputOs | null>(null);
  /**
   * Used for prefetching & caching previous five and next 5 paginated getAssetsForList requests
   * Returns query inputs which are copies of getAssetsForListInput, the only difference is the skip parameter
   * it is calculated by taking the current skip parameter and multiplying it by the number of assetsPerPage (100 by default)
   * and by the current index
   * We go from -5 to 5 so get the input for the previous 5 and the next 5 pages
   * Example: We're on page 5, the skip parameter is 500, for the iteration where index is 1,
   * 1. we calculate assetsPerPage * index which is 100 * 1 = 100
   * 2. we then sum 500 + 100 = 600 so for the request with index 1 we will skip 600 assets
   */
  const cachedAssetsQueriesInputs = useMemo<
    AssetInput[] | AssetInputOs[]
  >(() => {
    const skipBase = getAssetsForListInput?.skip ?? 0;

    // check if the skipBase and previousSkipBaseRef are different because they are equal in the beginning,
    // check if the some filter has changed,
    // then check if the difference between them is greater than 3 pages and if it is get the next pages

    if (
      previousSkipBaseRef.current !== skipBase &&
      isEqualWith(
        assetsForListInputRef.current,
        getAssetsForListInput,
        comparatorCustomizer
      ) &&
      previousSkipBaseRef.current !== null &&
      Math.abs(skipBase - previousSkipBaseRef.current) < assetsPerPage * 3
    ) {
      return [];
    }

    previousSkipBaseRef.current = skipBase;
    assetsForListInputRef.current = getAssetsForListInput;
    const assetsQueryInputs: AssetInput[] = Array.from(
      { length: 11 },
      (_, i) => {
        const index = i - 5;
        const assetsToSkip = skipBase + assetsPerPage * index;

        // skip requests where skip parameter is negative or is greater than total number of assets
        if (assetsToSkip < 0 || assetsToSkip > totalAssets) {
          return null;
        }

        return { ...getAssetsForListInput, skip: assetsToSkip } as AssetInput;
      }
    ).filter((queryInput): queryInput is AssetInput => queryInput !== null);

    return assetsQueryInputs;
  }, [assetsPerPage, totalAssets, getAssetsForListInput]);

  useEffect(() => {
    setAssetsImeisHandler(imeis ?? []);
  }, [imeis, setAssetsImeisHandler]);

  const handlerSort = (sort: AssetSort) => {
    setCurrentSort(sort);
    setAssetsInput((prev) => ({
      ...prev,
      sort,
    }));
  };

  const isMobile = useBreakpoint("down", "sm");

  const { data: assetData } = useFindAssetByIdQuery(
    {
      assetId: selectedAssetId ?? "",
    },
    {
      enabled: Boolean(selectedAssetId),
    }
  );

  const selectedAsset = useMemo(
    () => assetData?.findAssetById ?? null,
    [assetData]
  );

  const getCorrectPageItems = useMemo(() => {
    let itemsToRender =
      pageType === PageTypes.AssetMap
        ? assetsList
        : getItemsPerPage(sortedGeofences, currentPageNo, assetsPerPage);

    [selectedGeofence, selectedAsset].forEach((selected) => {
      if (selected) {
        itemsToRender = itemsToRender.filter(
          (item) => item._id !== selected._id
        );
      }
    });

    return itemsToRender;
  }, [
    assetsPerPage,
    currentPageNo,
    assetsList,
    sortedGeofences,
    pageType,
    selectedGeofence,
    selectedAsset,
  ]);

  const rowVirtualizer = useVirtualizer({
    count: (
      pageType === PageTypes.AssetMap
        ? assetsList.length
        : sortedGeofences.length
    )
      ? getCorrectPageItems.length
      : 0, // first page will be always with position 0
    getScrollElement: () => listRef.current,
    estimateSize: () =>
      pageType === PageTypes.Geofences
        ? GEOFENCE_LIST_ITEM_HEIGHT
        : ASSETS_LIST_ITEM_HEIGHT,
    overscan: 20,
  });

  const getSkeletonForAssetListTable = (count = 10) => {
    return (
      <>
        {new Array(count).fill(null).map((none, i) => (
          <Box
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            key={"skeleton-asset" + i}
          >
            <Skeleton sx={{ height: "24px" }} variant="rectangular" />
            <Skeleton
              sx={{ height: "12px", marginTop: "1px" }}
              variant="rectangular"
            />
          </Box>
        ))}
      </>
    );
  };

  const getAssetRow = (asset: Asset, virtualRow?: any) => {
    return (
      <div
        key={asset._id}
        data-testid={`assetRow-${asset._id}`}
        className="flex items-center"
        style={{
          position: virtualRow ? "absolute" : undefined,
          top: 0,
          left: 0,
          width: "100%",
          height: virtualRow?.size ? `${virtualRow.size}px` : undefined,
          transform: virtualRow
            ? `translateY(${virtualRow.start}px)`
            : undefined,
        }}
      >
        <Button
          variant="text"
          data-testid={`asset-${asset._id}`}
          className={classNames(
            {
              "!rounded-lg !bg-brand-transparent !px-3":
                asset._id === selectedAssetId,
            },
            "flex !h-12 w-full flex-col !px-0 !py-2 text-left"
          )}
          onClick={() => onFeatureClick(asset)}
        >
          <Box className="flex w-full justify-between gap-2">
            <p
              className="grow truncate text-base font-bold text-primary"
              data-testid={`asset-list-item-${asset?.asset_id}`}
            >
              {asset?.asset_id}
            </p>
            <div className="border-1 border-white-500/50 flex items-center justify-end gap-0.5 whitespace-nowrap rounded-sm text-xs capitalize text-primary sm:justify-start">
              <CheckIcon sx={CHECK_ICON_STYLE} />
              <span data-timestamp={asset?.lst_evnt_t}>
                {getStaleTime(asset?.lst_evnt_t)}
              </span>
            </div>
          </Box>
          <div className="flex w-full items-center text-sm font-normal text-light-charcoal">
            <LocationOnIcon sx={LOCATION_ICON_STYLE} />{" "}
            <span className="truncate">
              {asset.fullAddress?.city}, {asset.fullAddress?.state}
            </span>
          </div>
        </Button>
      </div>
    );
  };

  const getGeoFenceRow = (geoFence: GeofenceData, virtualRow?: any) => {
    return (
      <Button
        key={`${virtualRow?.index}-${geoFence._id}`}
        variant="text"
        data-testid={`geoFence-${geoFence._id}`}
        className={classNames(
          {
            "!rounded-lg !bg-brand-transparent !px-3":
              geoFence._id === selectedGeofence?._id,
          },
          "flex w-full flex-col !px-3 !py-2 text-left"
        )}
        onClick={() => onGeofenceClick(geoFence)}
        style={{
          position: virtualRow ? "absolute" : undefined,
          top: 0,
          left: 0,
          width: "100%",
          height: virtualRow?.size ? `${virtualRow.size}px` : undefined,
          transform: virtualRow
            ? `translateY(${virtualRow.start}px)`
            : undefined,
        }}
      >
        <div className="flex w-full justify-between">
          <div className="w-44">
            <p
              className="w-10/12 truncate text-sm font-bold normal-case text-primary sm:w-11/12"
              data-testid={geoFence.geofence?.name}
            >
              {geoFence.geofence?.name}
            </p>

            <div className="flex w-full items-center text-2xs font-medium normal-case text-light-charcoal">
              <span className="truncate" data-testid="geofence-type-field">
                Type:{" "}
                {geofenceTypes.find(
                  (type) => type._id === geoFence.configuration?.typeId
                )?.name ?? geoFence.configuration?.typeId}
              </span>
            </div>
            <div className="flex w-full items-center text-2xs font-medium normal-case  text-light-charcoal">
              <LocationOnIcon sx={LOCATION_ICON_STYLE} />{" "}
              <span className="truncate">
                {geoFence.geofence?.fullAddress?.city},{" "}
                {geoFence.geofence?.fullAddress?.state}
              </span>
            </div>
          </div>
          <div className="self-center">
            {geoFence.geofence?.gisConfig?.shape === "Polygon" && (
              <RectangleIcon />
            )}
            {geoFence.geofence?.gisConfig?.shape === "Circle" && (
              <EllipseIcon />
            )}
          </div>
        </div>
      </Button>
    );
  };
  const pages = Math.ceil(
    (pageType === PageTypes.AssetMap ? totalAssets : sortedGeofences.length) /
      assetsPerPage
  );

  const listStyle =
    pageType === PageTypes.AssetMap
      ? "overflow-auto px-6.5"
      : "overflow-y-auto overflow-x-hidden px-5.5";

  const isListLoading =
    pageType === PageTypes.AssetMap
      ? isGetAssetsForListFetching
      : isGeofencesLoading;

  return (
    <Box
      className="flex h-full flex-col"
      width={isMobile ? "100%" : ASSETS_LIST_WIDTH}
    >
      {pageType === PageTypes.AssetMap ? (
        <WithPermissions accessScope={"asset.create"}>
          <ActionButton
            data-testid="new-asset-button"
            className="w-[190px] !mt-[20px] !mb-[20px]"
            sx={{ margin: "0 auto" }}
            onClick={() => setShowAddAsset(true)}
          >
            New Asset
          </ActionButton>
          <Divider />
        </WithPermissions>
      ) : (
        ""
      )}

      <AssetsListHeader
        currentSort={currentSort}
        sortChange={handlerSort}
        totalAssets={totalAssets}
      />
      {selectedAsset && (
        <Box className="px-6.5 mt-2">{getAssetRow(selectedAsset)}</Box>
      )}
      {selectedGeofence && (
        <Box className="mt-2">{getGeoFenceRow(selectedGeofence)}</Box>
      )}

      <Box
        data-testid="asset-list-container-parent"
        className={`flex-1 ${listStyle}`}
        ref={listRef}
        sx={{
          overflow: isListLoading ? "hidden" : "auto",
          marginBottom: "5.5rem",
          marginTop: ".5rem",
        }}
      >
        <Box
          data-testid="asset-list-container"
          className="flex flex-col"
          position="relative"
        >
          {isListLoading
            ? getSkeletonForAssetListTable()
            : rowVirtualizer.getVirtualItems().map((virtualRow: any) => {
                const item = getCorrectPageItems[virtualRow.index];
                return pageType === PageTypes.AssetMap
                  ? getAssetRow(item, virtualRow)
                  : getGeoFenceRow(item, virtualRow);
              })}
        </Box>
        <Box
          data-testid="box"
          className="flex flex-col bg-background pl-2 shadow-[5px_5px_5px_5px_rgba(0,0,0,0.3)]"
          width={ASSETS_LIST_WIDTH}
          height={ASSETS_LIST_PAGINATION_HEIGHT}
          position="absolute"
          bottom={0}
          left={0}
        >
          {/* Used to trigger prefetch, doesn't render anything */}
          {cachedAssetsQueriesInputs.map((cachedQueryInput) => {
            return (
              <AssetListPrefetcher
                cachedQueryInput={cachedQueryInput}
                queryOptions={getAssetsForListOptions}
                key={cachedQueryInput.skip}
              />
            );
          })}
          <AssetsListFooter
            disabled={isListLoading}
            page={currentPageNo}
            onPageChange={onPageChange}
            pages={pages}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AssetsList);
