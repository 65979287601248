import { Box } from "@mui/material";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
} from "recharts";
import { ColorsPalette } from "../../../../../../../../../design-system/colors-palette";
import { chartTickStyle } from "../../../../../SummaryTabPanel/Charts/chartUtils";
import { RegulatorReferenceAreaValueType } from "../../sensors/RegulatorChart";
import { RegulatorChartTooltip } from "./RegulatorChartTooltip";

export type RegulatorChartData = {
  date: string | null;
  median: number | null;

  tooltipDate: string | null;
  min: number | null;
  max: number | null;
};

type RegulatorChartBodyProps = {
  data: RegulatorChartData[];
  referenceAreaValues: Array<any>; // TODO: Review
};

export enum RegulatorState {
  Critical = "Critical",
  Warning = "Warning",
  Alert = "Alert",
  Healthy = "Healthy",
}

export const STATE_TO_COLOR = {
  [RegulatorState.Critical]: ColorsPalette.ErrorOpacity20,
  [RegulatorState.Warning]: ColorsPalette.CautionOpacity10,
  [RegulatorState.Alert]: ColorsPalette.AlertOpacity20,
  [RegulatorState.Healthy]: ColorsPalette.SuccessOpacity20,
};

const RegulatorChartBody: React.FC<RegulatorChartBodyProps> = ({
  data,
  referenceAreaValues,
}) => {
  // Compute reference area elements. For temperature we do not have repeated areas.
  const getReferenceAreasForRegulator = () => {
    return referenceAreaValues?.map(
      (value: RegulatorReferenceAreaValueType) => {
        return (
          <ReferenceArea
            yAxisId={"median"}
            y1={value.min}
            y2={value.max}
            fill={STATE_TO_COLOR[value.state as RegulatorState]}
            fillOpacity={0.8}
            ifOverflow="extendDomain"
            key={`reference-${value.state.toLowerCase()}`}
            data-testid="regulator-chart-body-reference-area"
          />
        );
      }
    );
  };

  return (
    <Box
      className="w-full"
      style={{ height: 330 }}
      data-testid="regulator-chart-body-container"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid
            stroke="#DFDFDF"
            data-testid="regulator-chart-body-chart-grid"
          />
          <XAxis
            dataKey="date"
            tick={chartTickStyle}
            tickLine={false}
            interval={"preserveStart"}
          />
          <YAxis
            yAxisId="median"
            label={{
              value: "Pressure",
              angle: -90,
              dx: -10,
              style: {
                textAnchor: "middle",
                ...chartTickStyle,
              },
            }}
            tick={chartTickStyle}
            interval={0}
            tickSize={0}
          />

          {/* Show the thresholds legend behind the lines */}
          {getReferenceAreasForRegulator()}

          {/* Show information for the currently hovered tire */}
          <Tooltip offset={-5} content={<RegulatorChartTooltip />} />

          <Line
            yAxisId="median"
            type="linear"
            dataKey="median"
            stroke={ColorsPalette.PrimaryBlue}
            strokeWidth={3}
            connectNulls
            data-testid="regulator-chart-body-chart-line"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default RegulatorChartBody;
