import { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
  Button as CancelButton,
  CircularProgress,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  MergedProfileForAsset,
  Asset,
  useSetAssetSensorProfilesMutation,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesMutation,
  SetAssetSensorProfilesResponse,
  SetAssetSensorProfilesInput,
  SensorProfileConfigType,
} from "../../../../../graphql/operations";
import { Button } from "../../../../../shared/components/Button";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  internalTemperatureDefaultValues,
  extractDoubleRules,
  prepareDoubleRulesPayload,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  sliderMarks,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../shared/components/Text";
import {
  MaxValuesBySensorType,
  MinValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import {
  HEALTHY_POINT_MAX,
  HEALTHY_POINT_MIN,
} from "../../../../../shared/helpers/temperature";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";

export interface TemperatureSettingsProps {
  asset: Asset;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const TemperatureSettings: React.FC<TemperatureSettingsProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}) => {
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    marginBottom: "0.75rem",
  };
  const queryClient = useQueryClient();
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  const min = MinValuesBySensorType.internal;
  const max = MaxValuesBySensorType.internal;

  const temperatureData =
    extractDoubleRules(
      assetMergedSensorProfile?.configuration?.temperature?.internal?.match
        ?.thresholds,
      SensorProfileConfigType.Internal
    ) ?? internalTemperatureDefaultValues;
  const coldTemperatureData = temperatureData?.slice(0, 3) || [];
  const hotTemperaturedata = temperatureData?.slice(4, 7) || [];
  const healthyTempValue = temperatureData?.slice(3, 4)[0];

  const [coldTemperature, setColdTemperature] = useState(coldTemperatureData);
  const [hotTemperature, setHotTemperature] = useState(hotTemperaturedata);
  const [healthyTemperature, setHealthyTemperature] =
    useState(healthyTempValue);
  const [healthyTemperatureError, setHealthyTemperatureError] =
    useState<string>("");

  const dynamicMinOrMax = returnDynamicLimit(
    healthyTemperature,
    HEALTHY_POINT_MIN,
    HEALTHY_POINT_MAX
  );
  const hotTemperatureMarks = sliderMarks(dynamicMinOrMax, max, "°F");
  const coldTemperatureMarks = sliderMarks(min, dynamicMinOrMax, "°F");

  const areSlidersDirty = Boolean(
    coldTemperature.toString() !== coldTemperatureData.toString() ||
      hotTemperature.toString() !== hotTemperaturedata.toString() ||
      healthyTemperature !== healthyTempValue
  );

  const showErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Temperature Settings Update Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const showSuccessMessage = () => {
    queryClient.invalidateQueries({ queryKey: ["getMergedProfileForAsset"] });
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Temperature Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  // GraphQL hooks
  const { mutate: mutateCustomProfile, isLoading } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          showErrorMessage,
          showSuccessMessage,
          queryClient
        );
      },
    });

  const validateHealthyValue = (e: { target: { value: string } }) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return;

    const defaultErrorMessage = `Number must be between ${HEALTHY_POINT_MIN} and ${HEALTHY_POINT_MAX}.`;
    let evenColdTemperature = returnEvenValuesFromRange(min, value);
    let evenHotTemperature = returnEvenValuesFromRange(value, max);
    let errorMessage = "";

    if (value < HEALTHY_POINT_MIN) {
      evenColdTemperature = returnEvenValuesFromRange(min, HEALTHY_POINT_MIN);
      evenHotTemperature = returnEvenValuesFromRange(HEALTHY_POINT_MIN, max);
      errorMessage = defaultErrorMessage;
    } else if (value > HEALTHY_POINT_MAX) {
      evenColdTemperature = returnEvenValuesFromRange(min, HEALTHY_POINT_MAX);
      evenHotTemperature = returnEvenValuesFromRange(HEALTHY_POINT_MAX, max);
      errorMessage = defaultErrorMessage;
    }

    setColdTemperature(evenColdTemperature);
    setHotTemperature(evenHotTemperature);
    setHealthyTemperature(value);
    setHealthyTemperatureError(errorMessage);
  };

  const handleResetClick = () => {
    setColdTemperature(coldTemperatureData);
    setHotTemperature(hotTemperaturedata);
    setHealthyTemperature(healthyTempValue);
    setHealthyTemperatureError("");
  };

  const handleFormSubmit = () => {
    if (!asset.imei || healthyTemperatureError) return;

    const input: SetAssetSensorProfilesInput = {
      selectedImeis: [asset.imei ?? ""],
      orgId: asset.customer_orgs_id ?? "",
      sensors: {
        temperature: {
          internal: prepareDoubleRulesPayload(
            SensorProfileConfigType.Temperature,
            coldTemperature,
            hotTemperature,
            healthyTemperature,
            min,
            max
          ),
        },
      },
    };

    mutateCustomProfile({ input });
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box data-testid="temperature-settings-input-label">
        <Typography className="assetSettingsSectionTitle">
          Temperature Settings
        </Typography>
        {isLightTheme ? (
          <TemperatureSensorDark
            style={svgIconSettings}
            data-testid="temperature-settings-section-thermostat-icon"
          />
        ) : (
          <TemperatureSensorLight
            style={svgIconSettings}
            data-testid="temperature-settings-section-thermostat-icon"
          />
        )}

        <FormControl sx={{ width: "100%" }}>
          <Input
            value={healthyTemperature}
            onChange={validateHealthyValue}
            data-testid="temperature-settings-healthy-value-input"
            id="healthyTemperature"
            disabled={isLoading || isAssetRefetching}
            sx={{ width: "100%" }}
          />
          {healthyTemperatureError && (
            <FormHelperText
              data-testid="temperature-settings-input-error-text"
              error={true}
            >
              {healthyTemperatureError}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box className="py-8">
        <Typography
          className="assetSettingsSectionTitle"
          sx={{
            marginBottom: "4rem",
          }}
        >
          Hot Side Settings
        </Typography>
        <SensorSlider
          values={hotTemperature}
          min={dynamicMinOrMax}
          max={max}
          marks={hotTemperatureMarks}
          disabled={isLoading || isAssetRefetching}
          onChange={setHotTemperature}
        />
      </Box>

      <Box className="py-8">
        <Typography
          className="assetSettingsSectionTitle"
          sx={{
            marginBottom: "4rem",
          }}
        >
          Cold Side Settings
        </Typography>
        <SensorSlider
          values={coldTemperature}
          min={min}
          max={dynamicMinOrMax}
          marks={coldTemperatureMarks}
          disabled={isLoading || isAssetRefetching}
          onChange={setColdTemperature}
          reversed
        />
      </Box>

      {/* TODO: Replace with reusable component after it's fixed to accept 2 disabled states */}
      {isUserAllowed && (
        <Grid
          container
          spacing={8}
          className="items-baseline justify-center md:justify-end"
        >
          <Grid item>
            <CancelButton
              data-testid="temp-settings-cancel"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={handleResetClick}
              disabled={isLoading || !areSlidersDirty}
            >
              Reset
            </CancelButton>
          </Grid>
          <Grid item>
            <Button
              dataTestid="temp-settings-submit"
              className="w-full"
              text={isLoading ? "Saving" : "Save"}
              size="medium"
              theme="blue"
              variant="default"
              type="button"
              onClick={handleFormSubmit}
              iconPosition="right"
              icon={
                isLoading && (
                  <CircularProgress size={15} style={{ color: "white" }} />
                )
              }
              disabled={Boolean(
                isLoading || !areSlidersDirty || healthyTemperatureError
              )}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
