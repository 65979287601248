import { FC, useMemo, useState, useEffect, useRef } from "react";
import RangeSlider from "../../../../../../../../shared/components/RangeSlider/RangeSlider";

export interface BatteryVoltageSliderProps {
  onChange: (newValues: number[]) => void;
  currentSensor: { minValue: number; maxValue: number };
  maxValue: number;
  marks: { value: number; label: string }[];
  valueBarColor: string;
  mainBarColor?: string;
}

const BatteryVoltageSlider: FC<BatteryVoltageSliderProps> = ({
  onChange,
  currentSensor,
  maxValue,
  marks,
  valueBarColor,
  mainBarColor,
}) => {
  const defaultPowerValues = useRef<number[]>([
    currentSensor.minValue,
    currentSensor.maxValue,
  ]);

  const onValueChanged = (values: number | number[]) => {
    if (Array.isArray(values)) {
      // call onChange event to update filter values in asset context
      onChange(values);
    }
  };

  const dataValues = useMemo(
    () => ({
      defaultValue: defaultPowerValues.current,
      mainBarColor: [mainBarColor ?? "concrete"], // default is concrete for all
      valueBarColor: [valueBarColor],
    }),
    [defaultPowerValues, mainBarColor, valueBarColor]
  );

  return (
    <RangeSlider
      max={maxValue}
      marks={marks}
      step={0.01}
      defaultValues={dataValues.defaultValue}
      color={dataValues.valueBarColor[0]}
      onChange={onValueChanged}
    />
  );
};

export default BatteryVoltageSlider;
