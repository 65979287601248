import { FC, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import {
  Box,
  Button as CancelButton,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isNil, omitBy } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../constants";
import {
  DAYS_OF_WEEK_ARRAY,
  DayOfWeek,
  GeofenceSettingsTitle,
  GeofenceSettingsType,
  defaultTimeProps,
} from "../../../../../constants/geofences";
import { useAppContext } from "../../../../../context/AppContext";
import {
  GeofenceData,
  SetGeofenceRestrictionsInput,
  useGetGeofenceRestrictionsQuery,
  useSetGeofenceRestrictionsMutation,
  useUpdateGeofenceMutation,
} from "../../../../../graphql/operations";
import { Button } from "../../../../../shared/components/Button";
import Spinner from "../../../../../shared/components/Spinner";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { GeofenceCapacityForm } from "../GeofenceForm/components/GeofenceCapacityForm";
import { GeofenceOperationsForm } from "../GeofenceForm/components/GeofenceOperationsForm";
import { GeofenceRestrictionsForm } from "../GeofenceForm/components/GeofenceRestrictionsForm";
import { useCurrentForm } from "../GeofenceForm/useCurrentForm";
import {
  appendInitialOpperations,
  getRestrictionsInitialValues,
} from "../GeofenceForm/utils";
import { GeofenceForm } from "../GeofenceFormComponent/GeofenceForm";
import { ManageGeofence } from "../ManageGeofence";
import {
  onGeofenceUpdateSuccess,
  daysWithOperatingHours,
  prepareUpdateData,
  daysWithLunchBreaks,
  prepareUpdateRestriction,
  ERROR_UPDATING_GEOFENCE,
} from "./helpers";

interface GeofenceSettingsTabProps {
  tab: GeofenceSettingsType;
  geofence: GeofenceData;
  onBack: () => void;
}
export const GeofenceSettingsTab: FC<GeofenceSettingsTabProps> = ({
  geofence,
  tab,
  onBack,
}: GeofenceSettingsTabProps) => {
  const {
    isGeofencesFetching,
    setSelectedGeofence,
    setGeofences,
    geofences,
    setShouldUpdateQueryBuilder,
  } = useAssetsDataContext();

  const queryClient = useQueryClient();

  const {
    data: geofenceRestrictionsData,
    refetch: refetchGeofenceRestrictions,
    isLoading: isGeofenceRestrictionsLoading,
  } = useGetGeofenceRestrictionsQuery(
    { input: { geofenceId: geofence._id } },
    { onSuccess: () => setShouldUpdateQueryBuilder(true) }
  );

  const [restriction, setRestriction] =
    useState<SetGeofenceRestrictionsInput>();

  useEffect(() => {
    const geofenceRestrictions = () => {
      if (geofenceRestrictionsData?.getGeofenceRestrictions === null) {
        return {
          geofenceId: geofence._id,
        };
      }
      return geofenceRestrictionsData?.getGeofenceRestrictions;
    };
    setRestriction(geofenceRestrictions);
    setShouldUpdateQueryBuilder(true);
  }, [
    geofenceRestrictionsData?.getGeofenceRestrictions,
    geofence,
    setShouldUpdateQueryBuilder,
  ]);
  const { form, schema } = useCurrentForm(tab, geofence, restriction);
  const { dispatch } = useAppContext();
  const isUserAllowed = useUserPermission("assetManagement.editAsset");

  const [selectAll, setSelectAll] = useState<boolean>(
    daysWithOperatingHours(geofence).length === DAYS_OF_WEEK_ARRAY.length
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(
    daysWithOperatingHours(geofence)
  );
  const [daysWithLunch, setDaysWithLunch] = useState<string[]>(
    daysWithLunchBreaks(geofence)
  );
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    setSelectedDays(daysWithOperatingHours(geofence));
    setSelectAll(daysWithOperatingHours(geofence).length === 7);
    setDaysWithLunch(daysWithLunchBreaks(geofence));
  }, [geofence]);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedDays(isChecked ? DAYS_OF_WEEK_ARRAY : []);
    setButtonsDisabled(false);
  };

  const handleDayChange = (day: DayOfWeek) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
      setDaysWithLunch(
        daysWithLunch.filter((selectedDay) => selectedDay !== day)
      );
      form.setValue(`configuration.operations.${day}.lunch`, {
        ...defaultTimeProps,
      });
      form.setValue(`configuration.operations.${day}.operatingHours`, {
        ...defaultTimeProps,
      });
      setSelectAll(false);
    } else {
      const newSelectedDays = [...selectedDays, day];
      if (newSelectedDays.length === 7) {
        setSelectAll(true);
      }
      setSelectedDays(newSelectedDays);
    }
    setButtonsDisabled(false);
  };
  const handleLunchToggle = (day: DayOfWeek) => {
    if (daysWithLunch.includes(day)) {
      setDaysWithLunch(
        daysWithLunch.filter((selectedDay) => selectedDay !== day)
      );
      form.setValue(
        `configuration.operations.${day}.lunch`,
        {
          ...defaultTimeProps,
        },
        { shouldDirty: true }
      );
    } else {
      setDaysWithLunch([...daysWithLunch, day]);
    }
  };

  useEffect(() => {
    if (form.formState.isDirty) {
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(true);
    }
  }, [form.formState.isDirty]);

  const { mutateAsync, isLoading } = useUpdateGeofenceMutation({
    onSuccess: async (updated) => {
      if (
        !updated?.updateGeofence?.geofenceData &&
        updated?.updateGeofence.status === ERROR_UPDATING_GEOFENCE
      ) {
        return dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Geofence Update Failed",
            text: updated?.updateGeofence.message,
            severity: "error",
          },
        });
      }

      await onGeofenceUpdateSuccess({
        updatedGeofence: updated.updateGeofence.geofenceData as GeofenceData,
        geofences,
        setSelectedGeofence,
        setGeofences,
      });
      queryClient.invalidateQueries(["findGeofenceById"]);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofence Update",
          text: "Geofence Updated Successfully!",
          severity: "success",
        },
      });
    },
    onError: (error: unknown) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Geofence Update Failed",
          text: mapServerErrorCodeToHumanReadableMessage(
            error instanceof Error ? error.message : "Something Went Wrong."
          ),
          severity: "error",
        },
      });
    },
  });
  const { mutate: restrictionMutation, isLoading: isLoadingRestriction } =
    useSetGeofenceRestrictionsMutation({
      onSuccess: async () => {
        refetchGeofenceRestrictions();
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Set Restriction",
            text: "Set Restriction Successfully!",
            severity: "success",
          },
        });
      },
      onError: (error: unknown) => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Set Restriction Failed",
            text: mapServerErrorCodeToHumanReadableMessage(
              error instanceof Error ? error.message : "Something Went Wrong."
            ),
            severity: "error",
          },
        });
      },
    });

  const getGeofencePropCopyCasted = () => {
    // we need this to reset the form with new geofence data if user selects another geofence
    const geofencePropCopy = { ...geofence };
    // cast form to apply transformers
    return schema.cast(omitBy(geofencePropCopy, isNil), { assert: false });
  };

  const handleFormSubmit = async (formValues: FieldValues) => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }
    const preparedData = prepareUpdateData(formValues, tab, selectedDays);

    const prepareRestrictionData = prepareUpdateRestriction(formValues);
    if (tab !== GeofenceSettingsType.Restrictions) {
      await mutateAsync({
        geofence: preparedData,
      });
    } else {
      restrictionMutation({
        input: prepareRestrictionData,
      });
    }
  };

  const handleSubmitClick = () => {
    form.handleSubmit((d) => handleFormSubmit(d))();
  };

  const handleCancelClick = () => {
    setSelectAll(daysWithOperatingHours(geofence).length === 7);
    setSelectedDays(daysWithOperatingHours(geofence));
    setDaysWithLunch(daysWithLunchBreaks(geofence));
    let preparedData = getGeofencePropCopyCasted();

    if (tab === GeofenceSettingsType.Operations) {
      // for operations we need to append initial operations as simple schema cast does not help to reset the form to initial values
      preparedData = appendInitialOpperations(geofence);
    }
    if (tab === GeofenceSettingsType.Restrictions) {
      preparedData = getRestrictionsInitialValues(restriction);
      setShouldUpdateQueryBuilder(true);
    }

    form.reset(preparedData, {
      keepDirty: false,
      keepValues: false,
      keepDirtyValues: false,
    });
  };

  const renderTitle = () => {
    switch (tab) {
      case GeofenceSettingsType.Details:
        return GeofenceSettingsTitle.Details;
      case GeofenceSettingsType.Operations:
        return GeofenceSettingsTitle.Operations;
      case GeofenceSettingsType.Capacity:
        return GeofenceSettingsTitle.Capacity;
      case GeofenceSettingsType.Restrictions:
        return GeofenceSettingsTitle.Restrictions;
      default:
        return "";
    }
  };

  const renderForm = () => {
    switch (tab) {
      case GeofenceSettingsType.Details:
        return (
          <GeofenceForm
            form={form}
            existingGeofence={geofence}
            onChange={() => {}}
            showDetailsHeader
            disableAllFields={!isUserAllowed}
          />
        );
      case GeofenceSettingsType.Operations:
        return (
          <GeofenceOperationsForm
            form={form}
            geofence={geofence}
            selectAll={selectAll}
            selectedDays={selectedDays}
            daysWithLunch={daysWithLunch}
            handleLunchToggle={handleLunchToggle}
            onChange={() => {}}
            onDayChange={handleDayChange}
            onSelectAllChange={handleSelectAllChange}
            showDetailsHeader
            disableAllFields={!isUserAllowed}
          />
        );
      case GeofenceSettingsType.Capacity:
        return (
          <GeofenceCapacityForm
            form={form}
            geofence={geofence}
            onChange={() => {}}
            showDetailsHeader
            disableAllFields={!isUserAllowed}
          />
        );
      case GeofenceSettingsType.Restrictions:
        return (
          <GeofenceRestrictionsForm
            form={form}
            onChange={() => {}}
            showDetailsHeader
            disableAllFields={!isUserAllowed}
          />
        );
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container className="mb-12">
        <Grid item xs={12}>
          <Typography className="!my-6 !text-lg !font-semibold !text-brand">
            {renderTitle()}
          </Typography>
        </Grid>
        {renderForm()}
      </Grid>
      {isUserAllowed ? (
        <Box className="mx-auto md:w-full md:flex items-baseline md:justify-end">
          {tab === GeofenceSettingsType.Details ? (
            <ManageGeofence id={geofence._id} onBack={onBack} />
          ) : (
            ""
          )}
          <Box className="flex items-baseline">
            <CancelButton
              data-testid="settings-cancel"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={handleCancelClick}
              disabled={buttonsDisabled}
              sx={{
                "&.MuiButton-root": {
                  padding: "18px 11px !important",
                },
              }}
            >
              Reset
            </CancelButton>
            <Button
              dataTestid="settings-submit"
              className="w-full"
              text={isLoading || isLoadingRestriction ? "Saving" : "Save"}
              size="medium"
              theme="blue"
              variant="default"
              type="button"
              onClick={handleSubmitClick}
              iconPosition="right"
              icon={
                (isLoading || isLoadingRestriction) && (
                  <CircularProgress size={15} style={{ color: "white" }} />
                )
              }
              disabled={buttonsDisabled}
              sx={{
                "&.MuiButton-root": {
                  width: "120px", // width is out of design, as button includes loader
                  marginLeft: "16px",
                  marginTop: "0",

                  "@media (max-width: 767px)": {
                    marginTop: "34px",
                  },
                },
              }}
            />
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Spinner
        counter={
          Number(isGeofencesFetching) || Number(isGeofenceRestrictionsLoading)
        }
      />
    </>
  );
};
