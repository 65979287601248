import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import ActionButton from "../../../../../../shared/components/Button/ActionButton";
import { useAssetsDataContext } from "../../../../shared/AssetsDataContext";
import { useGeofenceQueryBuilderForm } from "../../../../shared/AssetsFilterControls/Filters/QueryBuilderTab/useGeofenceQueryBuilderForm";
import { GeoFenceQBModal } from "./GeofenceQBModal";

export const GeofenceQueryBuilder = () => {
  const [isQueryBuilderOpen, setIsQueryBuilderOpen] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const { onChangeGeofenceFilters, currentGeofenceFilter } =
    useAssetsDataContext();

  let qbFiltersCount = 0;
  qbFiltersCount += currentGeofenceFilter?.filters?.length ? 1 : 0;

  const { form } = useGeofenceQueryBuilderForm(currentGeofenceFilter);
  const filterValues = form.watch();
  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  const onApply = () => {
    onChangeGeofenceFilters({ filters: filterValues.filters || null });
    setIsQueryBuilderOpen(false);
  };

  const resetQueryBuilderHandler = () => {
    setShouldReset(true);
  };

  const handleQueryBuilderOpen = () => {
    setIsQueryBuilderOpen(true);
  };
  const handleQueryBuilderClose = () => {
    form.reset(currentGeofenceFilter);
    setIsQueryBuilderOpen(false);
  };
  return (
    <>
      <div className="flex justify-between">
        <Typography className="!text-lg !font-semibold py-6 px-0">
          Query Builder
        </Typography>
        <Typography className="!text-lg !font-semibold px-0">
          {qbFiltersCount ? qbFiltersCount : ""}
        </Typography>
      </div>
      <Box className=" flex flex-col justify-evenly">
        <ActionButton
          testId="open-query-view-btn"
          className="block text-primary border-primary text-typography"
          onClick={handleQueryBuilderOpen}
        >
          Open Query Builder
        </ActionButton>

        <GeoFenceQBModal
          form={form}
          isOpen={isQueryBuilderOpen}
          onClose={handleQueryBuilderClose}
          onApply={onApply}
          resetQueryBuilderHandler={resetQueryBuilderHandler}
          shouldReset={shouldReset}
          onReset={onReset}
        />
      </Box>
    </>
  );
};
