import { LabelProps } from "recharts";
import { formatNumber } from "../../../../../utils/formatters";

export const BarLabel: React.FC<LabelProps> = ({ value, x, y, width }) => {
  if (
    value === 0 ||
    x === undefined ||
    y === undefined ||
    width === undefined
  ) {
    return null;
  }

  const adjustedX = Number(x) + Number(width) / 2; // adjust the label to the center of the bar
  const adjustedY = Number(y) - 6; // Move the label up a bit

  return (
    <text
      data-testid="bar-label"
      x={adjustedX}
      y={adjustedY}
      fill="var(--typography-secondary)"
      textAnchor="middle"
    >
      {formatNumber(Number(value))}
    </text>
  );
};
