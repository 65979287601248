import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import slug from "slug";
import {
  AlertTypeV2,
  useFindAlertTemplateByIdQuery,
} from "../../../../graphql/operations";
import { useSpinner } from "../../../../shared/hooks/useSpinner";
import { AlertTypeTitle } from "../../../AlertsView/constants";
import {
  buildCargoAlertTemplate,
  buildCustomAlertTemplate,
  buildDwellAlertTemplate,
  buildGeofenceAlertTemplate,
  buildMovingWithoutPrimOrSecPowerAlertTemplate,
  buildSpeedAlertTemplate,
  buildWheelEndAlertTemplate,
} from "../../utils";
import { AlertComponent } from "../Alert/AlertComponent";

interface AlertTemplateProps {
  templateName: string;
}

export type AlertTemplateData = {
  name?: string;
  message?: string;
  trigger?: string;
};

const geofenceAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Geofence]);
const cargoAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Cargo]);
const dwellAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Dwell]);
const customAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Custom]);
const movingWithoutPrimOrSecPowerAlertSlug = slug(
  AlertTypeTitle[AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower]
);
const speedAlertSlug = slug(AlertTypeTitle[AlertTypeV2.Speeding]);
const wheelEndAlertSlug = slug(AlertTypeTitle[AlertTypeV2.WheelEnd]);

export const AlertTemplate: FC<AlertTemplateProps> = ({
  templateName,
}: AlertTemplateProps) => {
  const [templateData, setTemplateData] = useState<AlertTemplateData>({});
  const { state: locationState } = useLocation();
  const { templateId, isCustomTemplate } = locationState;
  const { data, refetch, isLoading } = useFindAlertTemplateByIdQuery(
    { alertTemplateId: templateId },
    { enabled: isCustomTemplate }
  );

  useEffect(() => {
    const template = data?.findAlertTemplateById;
    if (template) {
      const { name, message, query } = template;
      setTemplateData({ name, message: message ?? "", trigger: query ?? "" });
    }
  }, [data]);

  useEffect(() => {
    if (isCustomTemplate) {
      refetch();
    }
  }, [templateId, isCustomTemplate, refetch]);
  useSpinner(isLoading && isCustomTemplate);

  return (
    <>
      {((isCustomTemplate &&
        !isEmpty(templateData) &&
        templateId &&
        !isLoading) ||
        !isCustomTemplate) && (
        <>
          {templateName === geofenceAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildGeofenceAlertTemplate(templateData)}
              alertType={AlertTypeV2.Geofence}
            />
          )}
          {templateName === cargoAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildCargoAlertTemplate(templateData)}
              alertType={AlertTypeV2.Cargo}
            />
          )}
          {templateName === dwellAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildDwellAlertTemplate(templateData)}
              alertType={AlertTypeV2.Dwell}
            />
          )}
          {templateName === customAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildCustomAlertTemplate(templateData)}
              alertType={AlertTypeV2.Custom}
            />
          )}
          {templateName === movingWithoutPrimOrSecPowerAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildMovingWithoutPrimOrSecPowerAlertTemplate(
                templateData
              )}
              alertType={AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower}
            />
          )}
          {templateName === speedAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildSpeedAlertTemplate(templateData)}
              alertType={AlertTypeV2.Speeding}
            />
          )}
          {templateName === wheelEndAlertSlug && (
            <AlertComponent
              isTemplate
              alert={buildWheelEndAlertTemplate(templateData)}
              alertType={AlertTypeV2.WheelEnd}
            />
          )}
        </>
      )}
    </>
  );
};
