import { memo, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import AddCircle from "@mui/icons-material/AddCircle";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUpload from "@mui/icons-material/FileUpload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  popoverClasses,
  ThemeProvider,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { BatchTitles } from "../../../../../constants/batches";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetFilter,
  useFindOrgsQuery,
  CreateAssetFilterMutation,
  DeleteAssetFilterMutation,
  UpdateAssetFilterMutation,
  useCreateAssetFilterMutation,
  useDeleteAssetFilterMutation,
  useUpdateAssetFilterMutation,
} from "../../../../../graphql/operations";
import { ThreeDotsMenu } from "../../../../../shared/components/ThreeDotsMenu/ThreeDotsMenu";
import { UserAccessScope } from "../../../../../shared/components/WithPermissions";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../../../views/BatchesView/BatchManagementUtils";
import { Modes } from "../../../MapView/Shared/FiltersDrawer/FiltersDrawer";
import { AddAssetDrawer } from "../../../TableView/components/AddAssetDrawer";
import { useAssetManagementTheme } from "../../../TableView/hooks/useAssetManagementTheme";
import {
  AssetFilters,
  initialFilterState,
  initialGeofenceFilterState,
  PageTypes,
  useAssetsDataContext,
} from "../../AssetsDataContext";
import { UploadAssetsDialog } from "../../UploadAssetsDialog/UploadAssetsDialog";
import AssetFilterMenuButton from "../AssetFilterMenuButton";
import FilterItem from "./FilterItem";
import FilterModal, { ModalModeMap } from "./FilterModal";

//---------------------------
// Main Component
//---------------------------

export interface MoreActionsProps {
  pageType: PageTypes;
  onRefetch: () => void;
  onDeleteClick: () => void;
}

const menuActionsOptions = [
  {
    title: "New Asset",
    action: "create",
    icon: <AddCircle fontSize="inherit" />,
  },
  {
    title: "Upload Asset",
    action: "upload",
    icon: <FileUpload fontSize="inherit" />,
  },
];

const MoreActions: React.FC<MoreActionsProps> = ({
  pageType,
  onRefetch,
  onDeleteClick,
}) => {
  const { dispatch } = useAppContext();
  const {
    reset,
    formState: { errors },
  } = useFormContext();
  const queryClient = useQueryClient();
  const assetManagementTheme = useAssetManagementTheme(); // in context of Map View we will not have same theme as we have in AssetManagement View, so we need to set it explicitly
  const {
    savedFilters,
    currentFilter: criteria,
    removeAssetFilterCache,
    refetchAssetFilters,
    onChangeFilters,
    isFetchingUserFilters,
    showAddAsset,
    setShowAddAsset,
    isFileUploadDialogOpen,
    setIsFileUploadDialogOpen,
    isAssetsVisibleOnGeoFence,
    setIsAssetsVisibleOnGeoFence,
    onChangeGeofenceFilters,
  } = useAssetsDataContext();
  const [selectedFilter, setSelectedFilter] = useState<AssetFilter | null>(
    null
  );
  const [name, setName] = useState<string>(selectedFilter?.name ?? "");
  const [isPublic, setPublic] = useState<boolean>(
    selectedFilter?.is_public || false
  );
  const [mode, setMode] = useState<string>("");
  const userMadeChange = !isEqual(criteria, initialFilterState);
  const disabled = !userMadeChange || !isEmpty(errors);
  const { publicFilters, userFilters } = useMemo(() => {
    const filtersSorted = savedFilters
      ?.filter((filter) => filter.is_public)
      .sort((a, b) => a.name.localeCompare(b.name));
    const publicFilters = filtersSorted;
    const userFilters = filtersSorted;

    return { publicFilters, userFilters };
  }, [savedFilters]);

  const { data: orgData } = useFindOrgsQuery();
  const userData = useUserData();
  const orgs = mapOrgs(orgData?.findOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  const filterAlreadyExists = useCallback(() => {
    if (!name || !savedFilters?.length) {
      return false;
    }
    return savedFilters.some((filter) => filter.name === name);
  }, [name, savedFilters]);

  const isUpdatingName = useMemo(
    () => mode === Modes.Update && name !== selectedFilter?.name,
    [selectedFilter, mode, name]
  );

  const handleHelperText = useCallback((): string => {
    if ((mode === Modes.Save || isUpdatingName) && filterAlreadyExists()) {
      return "This filter name already exists.";
    }
    return "";
  }, [mode, isUpdatingName, filterAlreadyExists]);

  const isSaveFilterDisabled = filterAlreadyExists();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-actions-popup",
  });

  const onClickHandler = (clickMode: string, filter?: AssetFilter) => {
    popupState.close();
    // If user made any change and clicked edit then use that change for Edit Else
    const filterToApply: AssetFilter =
      userMadeChange && clickMode
        ? ({ ...filter, criteria: JSON.stringify(criteria) } as AssetFilter)
        : (filter as AssetFilter);
    // either Save, Update or Delete
    if (clickMode) {
      setMode(clickMode);
    }
    // filter == true means Update or Delete mode, filter === false means Save mode
    if (filter) {
      setSelectedFilter(filterToApply);
      setName(filterToApply?.name);
      setPublic(filterToApply?.is_public || false);
      onChangeFilters(JSON.parse(filterToApply?.criteria));
    } else {
      setName("");
      setPublic(false);
      setSelectedFilter(null);
    }
  };
  const onCloseModal = useCallback(() => {
    setMode("");
  }, []);
  const onReset = useCallback(() => {
    popupState.close();
    reset();
    if (
      pageType === PageTypes.AssetMap ||
      pageType === PageTypes.AssetTable ||
      pageType === PageTypes.AssetGallery
    ) {
      onChangeFilters(initialFilterState);
    } else if (pageType === PageTypes.Geofences) {
      onChangeGeofenceFilters(initialGeofenceFilterState);
    }
    setSelectedFilter(null);
    onDeleteClick();
  }, [
    onChangeFilters,
    popupState,
    reset,
    onDeleteClick,
    onChangeGeofenceFilters,
    pageType,
  ]);

  const onSuccess = useCallback(
    async (
      data:
        | CreateAssetFilterMutation
        | UpdateAssetFilterMutation
        | DeleteAssetFilterMutation
        | AssetFilter
    ) => {
      if (mode === Modes.Save && "createAssetFilter" in data) {
        data.createAssetFilter?.criteria &&
          onChangeFilters(
            JSON.parse(
              data.createAssetFilter?.criteria
            ) as Partial<AssetFilters>
          );
        setSelectedFilter(data.createAssetFilter);
      }
      if (mode === Modes.Update && "updateAssetFilter" in data) {
        onChangeFilters(
          JSON.parse(data.updateAssetFilter?.criteria) as Partial<AssetFilters>
        );
      }
      if (mode === Modes.Delete && "deleteAssetFilter" in data) {
        onReset(); // reset assets back to original state when selected filter is deleted
      }
      onCloseModal();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode}d `,
          text: "Success!",
          severity: "success",
          onClose: () => {},
        },
      });
      if (removeAssetFilterCache && refetchAssetFilters) {
        removeAssetFilterCache();
        refetchAssetFilters();
      }
    },
    [
      removeAssetFilterCache,
      refetchAssetFilters,
      mode,
      dispatch,
      onChangeFilters,
      onCloseModal,
      name,
      onReset,
    ]
  );

  const onError = useCallback(
    (error: unknown) => {
      onCloseModal();
      console.error(error);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: `Asset Filter "${name}" ${mode} Failed`,
          text: "Something Went Wrong.",
          severity: "error",
          onClose: () => {},
        },
      });
    },
    [mode, dispatch, onCloseModal, name]
  );

  const { mutate: createAssetFilter, isLoading: isCreatingAssetFilter } =
    useCreateAssetFilterMutation({ onSuccess, onError });

  const { mutate: updateAssetFilter, isLoading: isUpdatingAssetFilter } =
    useUpdateAssetFilterMutation({
      onSuccess,
      onError,
    });
  const { mutate: deleteAssetFilter, isLoading: isDeletingAssetFilter } =
    useDeleteAssetFilterMutation({
      onSuccess,
      onError,
    });

  const handleToggleAssetsVisibility = () => {
    setIsAssetsVisibleOnGeoFence(!isAssetsVisibleOnGeoFence);
  };

  const handleActionMenuClick = (action: string) => {
    if (!action) return;
    if (action === "create") setShowAddAsset(true);
    if (action === "upload") setIsFileUploadDialogOpen(true);
  };

  const modalModeMapping: ModalModeMap = useMemo(
    () => ({
      Save: {
        title: "Save New Filter",
        action: () => {
          createAssetFilter({
            input: {
              name,
              is_public: isPublic,
              criteria: JSON.stringify(criteria),
            },
          });
        },
        disabled: isSaveFilterDisabled,
      },
      Update: {
        title: "Update Filter",
        action: () => {
          selectedFilter &&
            updateAssetFilter({
              input: {
                ...(({ email, ...rest }) => rest)(selectedFilter),
                name,
                is_public: isPublic,
              },
            });
        },
      },
      Delete: {
        title: "Delete Filter",
        action: () => {
          selectedFilter && deleteAssetFilter({ id: selectedFilter._id });
        },
      },
    }),
    [
      selectedFilter,
      createAssetFilter,
      updateAssetFilter,
      deleteAssetFilter,
      name,
      isPublic,
      criteria,
      isSaveFilterDisabled,
    ]
  );

  const geofenceMenuOptions = [
    {
      title: isAssetsVisibleOnGeoFence ? "Hide assets" : "Show assets",
      onClick: handleToggleAssetsVisibility,
      permission: "geofences.view" as UserAccessScope,
    },
  ];

  return (
    <section
      className={`filters-operations-section flex gap-4 md:gap-0 items-center ${
        pageType !== PageTypes.Geofences ? "ml-1" : ""
      }`}
    >
      {pageType !== PageTypes.Geofences && (
        <AssetFilterMenuButton
          disabled={disabled}
          onClick={() => onClickHandler("Save")}
          title="Save"
          endIcon={
            <SaveOutlinedIcon fontSize="inherit" data-testid="save-filter" />
          }
        />
      )}
      <IconButton
        color="inherit"
        className={classNames("md:!hidden", {
          "opacity-30": disabled,
        })}
        disabled={disabled}
        onClick={() => onClickHandler("Save")}
        data-testid="save-icon-button"
      >
        <SaveIcon />
      </IconButton>
      <AssetFilterMenuButton
        onClick={onRefetch}
        endIcon={
          <AutorenewOutlinedIcon
            fontSize="inherit"
            data-testid="refetch-icon-filters"
          />
        }
      />
      <AssetFilterMenuButton
        onClick={onReset}
        endIcon={
          <DeleteOutlinedIcon
            fontSize="inherit"
            data-testid="delete-icon-filters"
          />
        }
      />
      <ThemeProvider theme={assetManagementTheme}>
        <AddAssetDrawer open={showAddAsset} setOpen={setShowAddAsset} />
      </ThemeProvider>

      {pageType !== PageTypes.Geofences && (
        <>
          <IconButton
            size="small"
            color="inherit"
            title="Saved Filters"
            data-testid="save-filters"
            {...bindTrigger(popupState)}
          >
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            id="filters-operations-menu"
            data-testid="filters-operations-menu"
            className="!text-xs"
            sx={{
              [`& .${popoverClasses.paper}`]: {
                backgroundColor: "var(--background)",
                minWidth: "240px",
                maxWidth: "280px",
              },
              "& .MuiList-root": {
                display: "flex",
                flexDirection: "column",
                width: "100%",
                fontSize: ".75rem",
                "*": {
                  fontWeight: "500 !important",
                },
              },
            }}
          >
            {/* this will not shown any more. Keep it for while because of export methods. They will be not remove for now and not to lose the way of using it */}
            {/* <Typography className="px-4 !text-inherit text-menu-group">
          Actions
        </Typography>

        <MenuItem
          className="flex !justify-between !text-inherit"
          onClick={exportToExcel}
        >
          <p>Download Excel</p>
          <FileDownloadIcon fontSize="inherit" />
        </MenuItem>

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={csvFilename}
          onClick={exportToCsv}
          enclosingCharacter=""
        >
          <MenuItem className="flex !justify-between !text-inherit">
            <p>Download CSV</p>
            <FileDownloadIcon fontSize="inherit" />
          </MenuItem>
        </CSVLink>

        <Divider /> */}

            {isFetchingUserFilters ? (
              <span color="var(--secondary)" className="px-4 py-2 text-inherit">
                <CircularProgress size={25} color="inherit" />
              </span>
            ) : (
              <div>
                <>
                  <Typography
                    key="actions-title"
                    className="px-4 !text-inherit text-menu-group"
                    variant="subtitle2"
                  >
                    Actions
                  </Typography>
                  {menuActionsOptions.map((option) => (
                    <MenuItem
                      key={option.title}
                      className="flex !justify-between !text-inherit"
                      onClick={() => handleActionMenuClick(option.action)}
                    >
                      <p>{option.title}</p>
                      {option.icon}
                    </MenuItem>
                  ))}
                </>
                <Divider />

                {!!userFilters?.length && (
                  <>
                    <Typography
                      key="my-filters-title"
                      className="px-4 !text-inherit text-menu-group"
                      variant="subtitle2"
                    >
                      My Filters
                    </Typography>
                    {userFilters?.map((filter) => (
                      <FilterItem
                        key={`id-${filter?._id ?? ""}-${filter.name}`}
                        filter={filter}
                        onClick={onClickHandler}
                        selectedFilter={selectedFilter}
                      />
                    ))}
                  </>
                )}
                {userFilters?.length > 0 && publicFilters?.length > 0 && (
                  <Divider />
                )}
                {!!publicFilters?.length && (
                  <>
                    <Typography
                      key="public-filters-title"
                      className="px-4 !text-inherit text-menu-group"
                      variant="subtitle2"
                    >
                      Shared Filters
                    </Typography>
                    {publicFilters?.map((filter, index) => (
                      <FilterItem
                        key={`id-${filter?._id ?? ""}-${filter.name}`}
                        filter={filter}
                        onClick={onClickHandler}
                        selectedFilter={selectedFilter}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </Menu>

          {mode && (
            <FilterModal
              open={Boolean(mode)}
              mode={(mode || "") as string}
              onClose={onCloseModal}
              modalModeMapping={modalModeMapping}
              name={name}
              isPublic={isPublic}
              originalIsPublic={selectedFilter?.is_public ?? isPublic}
              setPublic={setPublic}
              setName={setName}
              isCreatingAssetFilter={isCreatingAssetFilter}
              isUpdatingAssetFilter={isUpdatingAssetFilter}
              isDeletingAssetFilter={isDeletingAssetFilter}
              helperText={handleHelperText()}
            />
          )}
          <UploadAssetsDialog
            title={BatchTitles.CreateUpdateAssets}
            customerOrg={userData?.customerOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isFileUploadDialogOpen}
            onClose={() => setIsFileUploadDialogOpen(false)}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        </>
      )}

      {pageType === PageTypes.Geofences && (
        <ThreeDotsMenu menuOptions={geofenceMenuOptions} />
      )}
    </section>
  );
};

export default memo(MoreActions);
