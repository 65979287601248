import {
  TableColumnFormat,
  UsersTableData,
} from "../../../../../graphql/operations";
import {
  TableGridColDef,
  getTableColumn,
  TableColumnProps,
} from "../../../../../shared/components/Table";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";

export const columnVisibilityModel = {
  organizationName: true,
  lastName: true,
  firstName: true,
  username: true,
  email: true,
  phoneNumber: true,
  role: true,
  lastLoginDate: true,
  status: true,
  createdDate: true,
  updatedDate: true,
};

export const getColumns = (
  timezone: string
): TableGridColDef<UsersTableData>[] => {
  const columns: TableColumnProps<UsersTableData>[] = [
    {
      field: "organizationName",
      headerName: "Organization Name",
      type: "string",
      options: {
        filterable: false,
      },
    },
    { field: "lastName", headerName: "Last Name", type: "string" },
    { field: "firstName", headerName: "First Name", type: "string" },
    { field: "username", headerName: "Username", type: "string" },
    {
      field: "email",
      headerName: "Email",
      type: "string",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      type: "string",
    },
    {
      field: "role",
      headerName: "Role",
      type: "string",
    },
    {
      field: "lastLoginDate",
      headerName: "Last Login Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      type: "date",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
  ];

  return columns.map(getTableColumn);
};
