import { FC, memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { cloneDeep, isEmpty } from "lodash";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  Brand,
  FileCategory,
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useGetBrandFileUploadUrlQuery,
  useGetBrandFilesQuery,
  useUpdateBrandMutation,
} from "../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../shared/components/ConfirmationDialog/ConfirmationDialog";
import Drawer from "../../../../shared/components/Drawer";
import DrawerActions from "../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../shared/components/Drawer/DrawerHeader";
import Text from "../../../../shared/components/Text";
import { ColorInputElement } from "../../../../shared/components/react-hook-form-mui/ColorInputElement/ColorInputElement";
import { useFormTheme } from "../../../../shared/hooks/theme/useFormTheme";
import { validatePdfFile } from "../../../../utils/pdfUtils";
import BrandFileUpload from "./BrandFileUpload/BrandFileUpload";
import {
  BRAND_CREATE_FAILED_PAYLOAD,
  BRAND_CREATE_SUCCESS_PAYLOAD,
  BRAND_DELETE_FAILED_PAYLOAD,
  BRAND_DELETE_SUCCESS_PAYLOAD,
  BRAND_FILES_UPLOAD_FAILURE,
  BRAND_FORM_FIELDS,
  BRAND_UPDATE_FAILED_PAYLOAD,
  BRAND_UPDATE_SUCCESS_PAYLOAD,
  BrandsDrawerState,
  brandSchema,
  handleMutationData,
  onSubmit,
  s3FilesUploadType,
} from "./BrandUtils";

export type drawerArgs = {
  brandData: (Partial<Brand> & { id: string }) | undefined;
  drawerState: BrandsDrawerState;
  open: boolean;
  onBrandMutation: () => void;
  setOpen: (value: boolean) => void;
};

const BrandsDrawer: FC<drawerArgs> = ({
  drawerState = BrandsDrawerState.create,
  onBrandMutation,
  brandData,
  open,
  setOpen,
}: drawerArgs) => {
  const { dispatch } = useAppContext();
  const [hasDirtyFileInputs, setHasDirtyFileInputs] = useState(false);
  const defaultValues = cloneDeep(brandData) ?? {};

  const createBrandSchema = yup.object().shape(brandSchema);
  const updateBrandSchema = yup.object().shape(brandSchema);
  const [deleteBrandPopup, setDeleteBrandPopup] = useState(false);
  const formFields = BRAND_FORM_FIELDS;
  const breakpoints = { xs: 12 };
  const formTheme = useFormTheme();
  // States for newly uploaded files
  const [logo, setLogo] = useState<any>(); // Can be file or S3 object depending if there's existing file
  const [eulaDocument, setEulaDocument] = useState<any>(); // Can be file or S3 object depending if there's existing file
  const [backgroundImage, setBackgroundImage] = useState<any>(); // Can be file or S3 object depending if there's existing file
  // Urls for the files to be transferred to S3
  const [s3UploadUrls, setS3UploadUrls] = useState<
    s3FilesUploadType | undefined
  >();
  const [missingFilesErrors, setMissingFileErrors] = useState({
    logo: false,
    pdf: false,
    image: false,
  });
  const [isPdfValid, setIsPdfValid] = useState<boolean>(true);

  const isEdit = drawerState === BrandsDrawerState.edit;

  const onDeleteLogo = async () => {
    setMissingFileErrors((prevState) => {
      return { ...prevState, logo: true };
    });
    setLogo(() => undefined);
    if (hasDirtyFileInputs) setHasDirtyFileInputs(false);
  };
  const onDeleteEula = async () => {
    setMissingFileErrors((prevState) => {
      return { ...prevState, pdf: true };
    });
    setEulaDocument(() => undefined);

    if (hasDirtyFileInputs) setHasDirtyFileInputs(false);
    setIsPdfValid(true);
  };
  const onDeleteBackImage = async () => {
    setMissingFileErrors((prevState) => {
      return { ...prevState, image: true };
    });
    setBackgroundImage(() => undefined);
    if (hasDirtyFileInputs) setHasDirtyFileInputs(false);
  };
  const { data: brandExistingFiles } = useGetBrandFilesQuery(
    {
      input: { id: brandData?.id ?? "" },
    },
    {
      enabled: Boolean(brandData?.id),
    }
  );

  useEffect(() => {
    if (
      brandData?.id &&
      drawerState === BrandsDrawerState.edit &&
      brandExistingFiles?.getBrandFiles
    ) {
      setLogo(brandExistingFiles?.getBrandFiles.logo);
      setEulaDocument(brandExistingFiles?.getBrandFiles.eulaDocument);
      setBackgroundImage(brandExistingFiles?.getBrandFiles.backgroundImage);
    }
  }, [brandData, drawerState, brandExistingFiles]);

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(isEdit ? updateBrandSchema : createBrandSchema),
    defaultValues: defaultValues,
  });

  const { data: s3UploadUrlData } = useGetBrandFileUploadUrlQuery(
    {
      input: {
        file_categories: [
          FileCategory.Logo,
          FileCategory.Image,
          FileCategory.Pdf,
        ],
      },
    },
    {
      onSettled: (_: any, error: any) => {
        if (error) {
          const displayError = error
            ? (error as { message: string }).message
            : "Something Went Wrong.";
          dispatch({
            type: PAGE_SNACKBAR,
            payload: {
              title: "Cannot fetch S3 upload url!",
              text: displayError,
              severity: "error",
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    setS3UploadUrls(s3UploadUrlData?.getBrandFileUploadUrl);
  }, [s3UploadUrlData]);

  const toggleDeleteBrandPopup = () => {
    setDeleteBrandPopup(!deleteBrandPopup);
  };

  const handleDeleteBrand = (confirmed: boolean) => {
    if (!confirmed || deleteBrandIsLoading || deleteBrandIsSuccess) {
      toggleDeleteBrandPopup();
      return;
    }

    deleteBrandMutation({
      input: { _id: brandData?.id as string },
    });
  };

  const {
    mutate: deleteBrandMutation,
    isLoading: deleteBrandIsLoading,
    isSuccess: deleteBrandIsSuccess,
  } = useDeleteBrandMutation({
    onSuccess: () => {
      onBrandMutation();
      setOpen(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: BRAND_DELETE_SUCCESS_PAYLOAD,
      });
    },
    onError: (error: Error) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...BRAND_DELETE_FAILED_PAYLOAD,
          text: error?.message ?? BRAND_DELETE_FAILED_PAYLOAD.text,
        },
      });
      setOpen(false);
    },
  });

  const { mutate: createBrandMutation, isLoading: isCreateBrandLoading } =
    useCreateBrandMutation({
      onSuccess: () => {
        onBrandMutation();
        setOpen(false);
        dispatch({
          type: PAGE_SNACKBAR,
          payload: BRAND_CREATE_SUCCESS_PAYLOAD,
        });
      },
      onError: (error: Error) => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            ...BRAND_CREATE_FAILED_PAYLOAD,
            text: error?.message,
          },
        });
      },
    });

  const { mutate: updateBrandMutation, isLoading: isUpdateBrandLoading } =
    useUpdateBrandMutation({
      onSuccess: () => {
        onBrandMutation();
        setOpen(false);
        dispatch({
          type: PAGE_SNACKBAR,
          payload: BRAND_UPDATE_SUCCESS_PAYLOAD,
        });
      },
      onError: (error: Error) => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            ...BRAND_UPDATE_FAILED_PAYLOAD,
            text: error?.message,
          },
        });
      },
    });

  const handleFileUpload = async (fileData: File, type: string) => {
    if (type === "logo") {
      setMissingFileErrors((prevState) => ({ ...prevState, logo: false }));
      setLogo(fileData);
    } else if (type === "eulaDocument") {
      const isPdfValid = await validatePdfFile(fileData);
      setIsPdfValid(isPdfValid);

      if (!isPdfValid) return;

      setMissingFileErrors((prevState) => ({ ...prevState, pdf: false }));
      setEulaDocument(fileData);
    } else if (type === "backgroundImage") {
      setMissingFileErrors((prevState) => ({ ...prevState, image: false }));
      setBackgroundImage(fileData);
    }
    setHasDirtyFileInputs(true);
  };

  const handleOnSubmit = async (data: any) => {
    const brandFormData = await onSubmit({
      data,
      trigger,
      s3UploadUrls,
      dispatchUploadFailure,
      logo,
      eulaDocument,
      backgroundImage,
      setMissingFileErrors,
      missingFilesErrors,
    });

    if (brandFormData)
      handleMutationData(
        brandFormData,
        drawerState,
        brandData?.id,
        createBrandMutation,
        dirtyFields,
        updateBrandMutation
      );
  };

  const dispatchUploadFailure = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: BRAND_FILES_UPLOAD_FAILURE,
    });
  };

  const handleClose = () => setOpen(false);
  const headerText = `${isEdit ? "Edit" : "Create"} Brand`;
  const isLoading =
    isCreateBrandLoading || isUpdateBrandLoading || deleteBrandIsLoading;

  const isSubmitDisabled =
    (isLoading || isEmpty(dirtyFields) || !isPdfValid) && !hasDirtyFileInputs;

  return (
    <Drawer testId="brand-drawer" isOpen={open} onRequestClose={handleClose}>
      <DrawerHeader text={headerText} onClose={handleClose} />

      <DrawerContent>
        <ThemeProvider theme={formTheme}>
          <form data-testid="brand-drawer-form">
            <Grid container className="bg-background thirdDrawerSection">
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[0].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[0].name as never}
                  required
                  label={formFields[0].label}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[1].dataTestid}
              >
                <ColorInputElement
                  control={control}
                  name={formFields[1].name}
                  required
                  label={formFields[1].label}
                  errors={errors}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[2].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[2].name as never}
                  required
                  label={formFields[2].label}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[3].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[3].name as never}
                  label={formFields[3].label}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[4].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[4].name as never}
                  label={formFields[4].label}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[5].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[5].name as never}
                  label={formFields[5].label}
                />
              </Grid>
              <Grid
                item
                {...breakpoints}
                data-testid={formFields[6].dataTestid}
              >
                <TextFieldElement
                  fullWidth
                  control={control}
                  name={formFields[6].name as never}
                  label={formFields[6].label}
                />
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>

        <Box
          className="justify-left px-6 !mb-6"
          data-testid="brand-logo-upload"
        >
          <Text fontSize={16} color="var(--transparent-gray)">
            Upload Logo <span className="text-error !mb-3.5 ml-[2px]">*</span>
          </Text>
          <Text
            fontSize={16}
            fontWeight="regular"
            classes="!mb-3.5"
            color="var(--transparent-gray)"
          >
            Image must be in either jpg, jpeg or png format.
          </Text>
          <BrandFileUpload
            onFileUpload={(file) => handleFileUpload(file, "logo")}
            allowedFormats={["jpeg", "jpg", "png"]}
            fileData={logo}
            onDeleteFile={onDeleteLogo}
          />
          {missingFilesErrors.logo && (
            <Text
              fontSize={12}
              classes="!mb-3.5"
              color="var(--error)"
              fontWeight="regular"
            >
              Logo is required!
            </Text>
          )}
        </Box>
        <Box
          className="justify-left px-6 !mb-6"
          data-testid="brand-bImage-upload"
        >
          <Text fontSize={16} classes="!mb-3.5" color="var(--transparent-gray)">
            Upload Background Image{" "}
            <span className="text-error ml-[2px]">*</span>
          </Text>
          <Text
            fontSize={16}
            fontWeight="regular"
            classes="!mb-3.5"
            color="var(--transparent-gray)"
          >
            Image must be in either jpg, jpeg or png format.
          </Text>
          <BrandFileUpload
            onFileUpload={(file) => handleFileUpload(file, "backgroundImage")}
            fileData={backgroundImage}
            onDeleteFile={onDeleteBackImage}
            allowedFormats={["jpeg", "jpg", "png"]}
          />
          {missingFilesErrors.image && (
            <Text
              fontSize={12}
              classes="text-error !mb-3.5"
              color="var(--error)"
              fontWeight="regular"
            >
              Background Image is required!
            </Text>
          )}
        </Box>

        <Box className="justify-left px-6 pb-9" data-testid="brand-pdf-upload">
          <Text fontSize={16} classes="!mb-3.5" color="var(--transparent-gray)">
            Upload Eula Document <span className="text-error ml-[2px]">*</span>
          </Text>
          <Text
            fontSize={16}
            fontWeight="regular"
            classes="!mb-3.5"
            color="var(--transparent-gray)"
          >
            File must be in pdf format.
          </Text>
          <BrandFileUpload
            onFileUpload={(file) => handleFileUpload(file, "eulaDocument")}
            onDeleteFile={onDeleteEula}
            allowedFormats={["pdf"]}
            fileData={eulaDocument}
          />
          {missingFilesErrors.pdf && (
            <Text
              fontSize={12}
              classes="!mb-3.5"
              color="var(--error)"
              fontWeight="regular"
            >
              Eula Document is required!
            </Text>
          )}
          {!isPdfValid && (
            <Text
              fontSize={12}
              color="var(--error)"
              classes="!mt-2.5"
              fontWeight="regular"
            >
              This document is not valid. Please provide another one.
            </Text>
          )}
        </Box>

        <DrawerActions
          deleteBtnTestId="btn-delete-Brand"
          cancelBtnTestId="btn-Brand-form-cancel"
          disabled={isLoading}
          showDeleteBtn={isEdit}
          onCancel={handleClose}
          onDelete={toggleDeleteBrandPopup}
        />

        {isEdit && deleteBrandPopup && (
          <ConfirmationDialog
            title="You are about to delete a Brand"
            message="Are you sure?"
            open={deleteBrandPopup}
            confirmButtonText={
              deleteBrandIsLoading ? "Deleting..." : "Delete Brand"
            }
            cancelButtonText="Cancel"
            handleConfirmationResult={handleDeleteBrand}
          />
        )}
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving..." : "Save"}
        disabled={isSubmitDisabled}
        testId="btn-brand-form-submit"
        submit={() => handleOnSubmit(getValues())}
      />
    </Drawer>
  );
};

export default memo(BrandsDrawer);
