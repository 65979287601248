import { FC, memo, MutableRefObject, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  TableViewType,
} from "../../../../../graphql/operations";
import { getRows, Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../constants";
import {
  getProfilesTableColumns,
  columnVisibilityModel,
  ProfilesData, // MappedSensorProfile,
  SensorsProperties,
  getAirTankSensorTableColumns,
  airTankTableKeyList,
  MappedSensorProfile,
  mapSupplyPressureSensorProfileData, // mapAirTankSensorProfileData,
} from "../profileUtils";

export interface AirSupplyProfileTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  profilesData: ProfilesData;
}

const AirSupplyProfileTable: FC<AirSupplyProfileTableProps> = ({
  tableType,
  onRowClick,
  apiRef,
  profilesData,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const pressureUnit = useGetPressureUnitPreference();

  const [tableRows, setTableRows] = useState<SensorProfileResult[]>([]);

  const profilesTableColumns = getProfilesTableColumns(
    getAirTankSensorTableColumns(SensorsProperties.TankPressure)
  );
  const fileName = useExportedFileName("Profiles");

  useEffect(() => {
    const mappedSPProfiles = mapSupplyPressureSensorProfileData(
      profilesData.data ?? [],
      pressureUnit
    );

    setTableRows(
      getRows<MappedSensorProfile[]>(
        mappedSPProfiles,
        profilesTableColumns,
        "",
        "",
        "_id"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesData]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={profilesTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        loading={profilesData.isFetchingProfile}
        error={profilesData.isErrorProfile}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={airTankTableKeyList}
        sortKeys={airTankTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="profiles"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(AirSupplyProfileTable);
