import { useGetGeofenceNamesQuery } from "../../graphql/operations";

interface UseGetGeofenceNamesResponse {
  isFetching: boolean;
  geofenceNames: string[];
}

export const useGetGeofenceNames = (
  orgIds: string | string[]
): UseGetGeofenceNamesResponse => {
  const { data, isFetching } = useGetGeofenceNamesQuery({ orgIds });

  const geofenceNames = (data?.findGeofencesByOrgIds ?? [])
    .map(({ geofence }) => geofence?.name ?? "")
    .filter(Boolean);

  return { isFetching, geofenceNames };
};
