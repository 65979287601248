import { NOMENCLATURE_NAMES } from "../../../AssetsView/TableView/hooks";

export interface ParsedRegionsData {
  unitedStates: RegionGroupData;
  canada: RegionGroupData;
  mexico: RegionGroupData;
}

export interface RegionGroupData {
  isDefault: boolean;
  data: RegionData[];
}

export interface RegionData {
  id: string;
  name: string;
  country: RegionCountry;
  territories: string[];
}

export interface RegionOptionType {
  id: string;
  label: string;
}

export interface TerritoryInUse {
  regionName: string;
  territoryName: string;
}

export enum RegionCountry {
  UnitedStates = "unitedStates",
  Canada = "canada",
  Mexico = "mexico",
}

export const countryToTerritoriesNomenclatures: Record<
  RegionCountry,
  NOMENCLATURE_NAMES
> = {
  [RegionCountry.UnitedStates]: NOMENCLATURE_NAMES.territoriesUSA,
  [RegionCountry.Canada]: NOMENCLATURE_NAMES.territoriesCanada,
  [RegionCountry.Mexico]: NOMENCLATURE_NAMES.territoriesMexico,
};

export const countryToDisplayName: Record<RegionCountry, string> = {
  [RegionCountry.UnitedStates]: "United States",
  [RegionCountry.Canada]: "Canada",
  [RegionCountry.Mexico]: "Mexico",
};
