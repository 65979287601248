import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Button, TextButton } from "../../../../shared/components/Button";

interface EditAlertNameDialogProps {
  alertName: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

export const ChangeAlertNameDialog: FC<EditAlertNameDialogProps> = ({
  open,
  onClose,
  onSubmit,
  alertName,
}) => {
  const [newAlertName, setNewAlertName] = useState(alertName);

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewAlertName(event.target.value);
    },
    [setNewAlertName]
  );

  useEffect(() => {
    if (alertName !== newAlertName) {
      setNewAlertName(alertName);
    }
  }, [alertName, setNewAlertName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(() => {
    setNewAlertName(alertName);
    onClose();
  }, [onClose, alertName]);

  const handleDone = useCallback(() => {
    if (!newAlertName || newAlertName.length > 255) return;
    onSubmit(newAlertName);
    handleClose();
  }, [onSubmit, handleClose, newAlertName]);

  let helperText = "";
  if (!newAlertName) {
    helperText = "Please enter an Alert Name";
  } else if (newAlertName.length > 255) {
    helperText = "Max 255 characters";
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="edit-alert-name-dialog"
      aria-labelledby="edit-alert-name-dialog-title"
      aria-describedby="edit-alert-name-dialog-description"
      sx={{ backgroundColor: "var(--backdrop)" }}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        sx: {
          backgroundColor: "var(--background) !important",
          backgroundImage: "none",
          width: "100%",
          maxWidth: "720px !important",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        className="flex justify-between"
        id="edit-alert-name-dialog-title"
      >
        <span className="text-2xl font-semibold !text-typography-secondary">
          Alert Name
        </span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          data-testid="edit-alert-name-dialog-close-btn"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="!pb-0">
        <DialogContentText
          className="!mb-7 text-base !text-typography-secondary"
          id="edit-alert-name-dialog-description"
        >
          Please choose a name for your alert
        </DialogContentText>
        <Typography
          data-testid="alert-name-input-label"
          className="!mb-1 !text-sm !font-bold"
        >
          Alert Name
        </Typography>
        <TextField
          error={!!helperText}
          data-testid="alertNameInput"
          variant="standard"
          fullWidth
          value={newAlertName}
          onChange={handleNameChange}
          helperText={helperText}
        />
      </DialogContent>
      <DialogActions className="!p-6">
        <TextButton
          text="Cancel"
          size="medium"
          onClick={handleClose}
          theme="blue"
          data-testid="btn-alert-name-cancel"
          iconPosition="none"
          icon={undefined}
          className={undefined}
        />
        <Button
          text="Done"
          size="medium"
          theme="blue"
          variant="default"
          onClick={handleDone}
          dataTestid="btn-update-name-alert"
        />
      </DialogActions>
    </Dialog>
  );
};
