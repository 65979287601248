import { FC, MutableRefObject } from "react";
import { Circle } from "@mui/icons-material";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import DoorHeader from "./DoorHeader";
import {
  BAR_HEIGHT,
  DoorData,
  calculateDoorStatusInLocationsLast30Days,
} from "./utils";

export interface DoorStatusInLocationProps {
  data: Array<DoorData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const DoorStatusInLocation: FC<DoorStatusInLocationProps> = ({
  data,
  parentRef,
}) => {
  const {
    closedDoorDaysInsideGeofence,
    closedDoorDaysOutsideGeofence,
    openDoorDaysInsideGeofence,
    openDoorDaysOutsideGeofence,
  } = calculateDoorStatusInLocationsLast30Days(data);

  const legendDoorKeys = ["Inside Geofence", "Outside Geofence"];
  const legendDoorLabels = ["Inside Geofence", "Outside Geofence"];
  const legendDoorColors = ["var(--primary-blue)", "var(--indigo)"];

  const { width } = useContainerDimensions(parentRef);

  // layout grid parent dimensions
  const gridItemPaddings = 32;
  const gridItemGapDistance = 16;

  // card layout dimensions
  const labelWidth = 55;
  const margins = 16;
  const paddings = 16 + gridItemPaddings + gridItemGapDistance;
  const barsGap = 8;

  let closedBarMaxWidth = width - (labelWidth + margins + paddings);
  let openBarMaxWidth = width - (labelWidth + margins + paddings);

  // sum of closed and open days
  const openGeofenceDaysSum =
    openDoorDaysInsideGeofence + openDoorDaysOutsideGeofence;
  const closedGeofenceDaysSum =
    closedDoorDaysInsideGeofence + closedDoorDaysOutsideGeofence;

  // calculate percentage from possible days for the bars
  const closedDaysInsideGeofenceInPercentage =
    (closedDoorDaysInsideGeofence / closedGeofenceDaysSum) * 100;
  const closedDaysOutsideGeofenceInPercentage =
    (closedDoorDaysOutsideGeofence / closedGeofenceDaysSum) * 100;

  const openDaysInsideGeofenceInPercentage =
    (openDoorDaysInsideGeofence / openGeofenceDaysSum) * 100;

  const openDaysOutsideGeofenceInPercentage =
    (openDoorDaysOutsideGeofence / openGeofenceDaysSum) * 100;

  // align bar width when one of the bars is not visible
  if (openDoorDaysInsideGeofence === 0 || openDoorDaysOutsideGeofence === 0) {
    openBarMaxWidth = openBarMaxWidth + barsGap;
  }
  if (
    closedDoorDaysInsideGeofence === 0 ||
    closedDoorDaysOutsideGeofence === 0
  ) {
    closedBarMaxWidth = closedBarMaxWidth + barsGap;
  }

  // calculate percentage based on the place that we have for the bars in the container
  const closedDaysInsideGeofenceBarPercentage =
    (closedDaysInsideGeofenceInPercentage / 100) * closedBarMaxWidth;
  const closedDaysOutsideGeofenceBarPercentage =
    (closedDaysOutsideGeofenceInPercentage / 100) * closedBarMaxWidth;

  const openDaysInsideGeofenceBarPercentage =
    (openDaysInsideGeofenceInPercentage / 100) * openBarMaxWidth;
  const openDaysOutsideGeofenceBarPercentage =
    (openDaysOutsideGeofenceInPercentage / 100) * openBarMaxWidth;

  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="last-30-days-door-status-in-locations"
    >
      {/* TODO: currently showing only one card. Update the layout when we need ot show 2 cards */}
      <DoorHeader title={"Door Sensor"} />

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="door-status-in-locations-card-title"
      >
        Door Status in Locations Last 30 Days
      </Typography>

      <Box className="!mb-3 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Open
        </Typography>
        {openDoorDaysOutsideGeofence > 0 && (
          <Box
            data-testid="open-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${openDaysOutsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {openDoorDaysOutsideGeofence}
          </Box>
        )}
        {openDoorDaysInsideGeofence > 0 && (
          <Box
            data-testid="open-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${openDaysInsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {openDoorDaysInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Closed
        </Typography>
        {closedDoorDaysOutsideGeofence > 0 && (
          <Box
            data-testid="closed-days-outside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--indigo)",
              color: "var(--white)",
              width: `${closedDaysOutsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {closedDoorDaysOutsideGeofence}
          </Box>
        )}
        {closedDoorDaysInsideGeofence > 0 && (
          <Box
            data-testid="closed-days-inside-geofence"
            className="!mr-2 rounded inline-block align-middle text-center"
            style={{
              background: "var(--primary-blue)",
              color: "var(--white)",
              width: `${closedDaysInsideGeofenceBarPercentage}px`,
              lineHeight: `${BAR_HEIGHT}px`,
              height: `${BAR_HEIGHT}px`,
            }}
          >
            {closedDoorDaysInsideGeofence}
          </Box>
        )}
      </Box>

      <Box className="justify gap-2">
        {legendDoorKeys.map((key, index) => (
          <Chip
            key={key}
            label={legendDoorLabels[index]}
            icon={
              <Circle
                sx={{
                  width: "16px",
                  fill: legendDoorColors[index],
                }}
              />
            }
            className="!h-6 !mb-2 !mr-2 !rounded-full !text-white"
            sx={{
              color: "var(--mid-charcoal)",
              backgroundColor: "var(--grayscale-feather-gray)",
              "& .MuiChip-label": {
                color: "var(--mid-charcoal)",
              },
            }}
          />
        ))}
      </Box>
    </Grid>
  );
};
export default DoorStatusInLocation;
