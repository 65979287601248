import { useState, FC, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { GridSortModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { isUndefined } from "lodash";
import { useAppContext } from "../../../../../context/AppContext";
import {
  AssetActivityEvent,
  FilterListInput,
  FiltersInput,
  SortOrder,
  useFindAssetActivityHistoryQuery,
  TableViewType,
} from "../../../../../graphql/operations";
import { Table, TableDataModes } from "../../../../../shared/components/Table";
import useExportedFileNameForOrg from "../../../../../shared/hooks/useExportedFileNameForOrg";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { validateTableFilters } from "../../../../ReportView/helpers/helpers";
import { SEARCH_KEYS, getColumns, columnVisibilityModel } from "./columns";

export interface AssetActivityLogProps {
  selectedAssetId: string;
  selectedAssetOrgName: string;
}

export const AssetActivityLog: FC<AssetActivityLogProps> = ({
  selectedAssetId,
  selectedAssetOrgName,
}) => {
  const [tableRows, setTableRows] = useState<AssetActivityEvent[]>([]);
  const [totalTableRowsCount, setTotalTableRowsCount] = useState<number>(0);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [paginationSkip, setPaginationSkip] = useState<number>(0);
  const [filters, setFilters] = useState<FilterListInput | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const initialSortModel: GridSortModel = [{ field: "postedAt", sort: "desc" }];
  const [sortModel, setSortModel] = useState<GridSortModel>(initialSortModel);

  const fileName = useExportedFileNameForOrg(
    selectedAssetOrgName,
    "Asset Activity"
  );
  const gridApiRef = useGridApiRef();
  const userTimezone = usePreferredTimezone();
  const columns = getColumns(userTimezone);
  const {
    state: { appConfig },
  } = useAppContext();
  const activityLogsPerPage = appConfig.table.defaultRowsPerPage;

  const { data, isLoading, isSuccess, refetch } =
    useFindAssetActivityHistoryQuery(
      {
        input: {
          assetId: selectedAssetId,
          filterList: filters?.filters ? [filters] : null,
          pagination: { skip: paginationSkip, limit: activityLogsPerPage },
          sorting: [
            {
              field: sortModel[0]?.field ?? initialSortModel[0].field,
              order: (sortModel[0]?.sort ??
                initialSortModel[0].sort) as SortOrder,
            },
          ],
          searchText: searchValue,
        },
      },
      { enabled: !!selectedAssetOrgName && !!selectedAssetId }
    );

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPageNo(page);
      setPaginationSkip(activityLogsPerPage * (page - 1));
    },
    [activityLogsPerPage]
  );

  const onFilterChange = (value: {
    items: FiltersInput[];
    linkOperator: string;
  }) => {
    setCurrentPageNo(1);
    setPaginationSkip(0);
    const newFilters = validateTableFilters(value);
    if (!isUndefined(newFilters)) {
      setFilters(newFilters);
    }
  };

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  useEffect(() => {
    refetch();
  }, [searchValue, sortModel, filters, refetch]);

  useEffect(() => {
    const events = data?.findAssetActivityHistory?.data ?? [];
    setTableRows(events);
    setTotalTableRowsCount(
      data?.findAssetActivityHistory?.pagination?.total ?? 0
    );
  }, [isSuccess, data]);

  return (
    <Box className="h-full w-full bg-background">
      <Box className="h-full w-full pt-2 px-4 pb-4 md:px-6 md:pb-10 lg:px-16 lg:pb-20">
        <Table
          tableType={TableViewType.AssetsActivityLog}
          handleDataMode={TableDataModes.Server}
          columns={columns}
          rows={tableRows}
          rowCount={totalTableRowsCount}
          initialSearch={""}
          error={!isLoading && !isSuccess ? true : null}
          showToolbar
          apiRef={gridApiRef}
          columnVisibilityModel={columnVisibilityModel}
          enableSearch
          searchExactMatch
          searchKeys={SEARCH_KEYS}
          searchMinLength={appConfig.searchMinLength}
          getRowId={(row) => row._id}
          sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
          exportProps={{
            csvOptions: { fileName },
            excelOptions: { fileName },
            printOptions: { fileName },
          }}
          initialState={{
            sorting: { sortModel: [{ field: "postedAt", sort: "desc" }] },
          }}
          tableName="asset_activity_log"
          pageSize={activityLogsPerPage}
          loading={isLoading}
          currentPage={currentPageNo}
          onPageChange={onPageChange}
          onPaginationModelChange={onPageChange}
          allowExport
          disableRowGrouping
          onSearch={onSearch}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortModelChange}
        />
      </Box>
    </Box>
  );
};
