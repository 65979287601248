import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { Box, Grid, CircularProgress, ThemeProvider } from "@mui/material";
import {
  DateRangeKey,
  DEFAULT_DATE_RANGE_OPTIONS,
} from "../../../../../constants/map";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  useGetCargoSensorDataFromEventHistoryQuery,
  useSearchAggregatedEventDoorQuery,
  useGetMediaHistoricalDataQuery,
  MediaActivity,
} from "../../../../../graphql/operations";
import { Sections } from "../../../../../shared/components/DashboardFrame/components/DashboardSectionsMenu/DashboardSectionsMenu";
import CargoTrendingGraph from "../../Shared/CargoTrendingGraph";
import DoorSensorGraph from "../../Shared/DoorSensorGraph";
import CargoState from "../CargoTabPanel/CargoStatus/CargoState";
import CargoStatus from "../CargoTabPanel/CargoStatus/CargoStatus";
import CargoStatusInLocations from "../CargoTabPanel/CargoStatus/CargoStatusInLocations";
import { CargoData } from "../CargoTabPanel/CargoStatus/utils";
import DoorState from "../CargoTabPanel/DoorStatus/DoorState";
import DoorStatus from "../CargoTabPanel/DoorStatus/DoorStatus";
import DoorStatusInLocation from "../CargoTabPanel/DoorStatus/DoorStatusInLocation";
import { DoorData } from "../CargoTabPanel/DoorStatus/utils";
import InternalCameraFloorSpaceGraph from "../CargoTabPanel/InternalCameraFloorSpaceGraph";
import { InternalCameraFloorSpaceGraphProvider } from "../CargoTabPanel/InternalCameraFloorSpaceGraph/context";
import InternalCameraStatus from "../CargoTabPanel/InternalCameraStatus/InternalCameraStatus";
import { AggregatedEventHistoryReportTypes } from "../SummaryTabPanel/Charts/chartUtils";
import { useOtherSensorsTabPanelTheme } from "../shared";

export interface CargoTabPanelProps {
  selectedAsset: Asset | null;
}

const BREAKPOINTS = {
  xs: 12,
  md: 6,
  lg: 3,
  xl: 3,
};

const DEFAULT_DATE_RANGE_OPTION = DEFAULT_DATE_RANGE_OPTIONS.find(
  (option) => option.key === DateRangeKey.Last30Days
);

const CargoTabPanel: FC<CargoTabPanelProps> = ({ selectedAsset }) => {
  // Shared state
  const theme = useOtherSensorsTabPanelTheme();
  const {
    state: { appConfig },
  } = useAppContext();

  const [defaultStartDate, defaultEndDate] = DEFAULT_DATE_RANGE_OPTION?.getRange
    ? DEFAULT_DATE_RANGE_OPTION.getRange()
    : [null, null];

  const [noSensorData, setNoSensorData] = useState<boolean>(true);

  // TODO: Export states for individual sensors to separate custom hooks
  // ########## START CARGO STATE ##########
  const [aggregatedCargoData, setAggregatedCargoData] = useState<CargoData[]>(
    []
  );
  const gridContainerRefCargo: MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);
  const [startDateCargo, setStartDateCargo] = useState<Date | null>(
    defaultStartDate
  );
  const [endDateCargo, setEndDateCargo] = useState<Date | null>(defaultEndDate);
  // Assertion is fine because we'll always have DEFAULT_DATE_RANGE_OPTION
  const [selectedDateRangeOptionCargo, setSelectedDateRangeOptionCargo] =
    useState(DEFAULT_DATE_RANGE_OPTION?.value!);
  // ########## END CARGO STATE ##########

  // ########## START DOOR GRAPH ##########
  const [aggregatedDoorData, setAggregatedDoorData] = useState<DoorData[]>([]);
  const gridContainerRefDoor: MutableRefObject<HTMLDivElement | null> =
    React.useRef(null);
  const [startDateDoor, setStartDateDoor] = useState<Date | null>(
    defaultStartDate
  );
  const [endDateDoor, setEndDateDoor] = useState<Date | null>(defaultEndDate);
  // Assertion is fine because we'll always have DEFAULT_DATE_RANGE_OPTION
  const [selectedDateRangeOptionDoor, setSelectedDateRangeOptionDoor] =
    useState(DEFAULT_DATE_RANGE_OPTION?.value!);
  // ########## END DOOR GRAPH ##########

  // ########## MEDIA STATE ##########
  const [aggregatedMediaData, setAggregatedMediaData] = useState<
    MediaActivity[]
  >([]);

  const { data: dataDoor } = useSearchAggregatedEventDoorQuery(
    {
      input: {
        startDate: startDateDoor,
        endDate: endDateDoor,
        assetId: selectedAsset?.asset_id ?? "", // mock data only for '379787'
        reportType: AggregatedEventHistoryReportTypes.DailyReportDoor,
        includeEmptyDates: true,
      },
    },
    {
      enabled: Boolean(selectedAsset?.asset_id),
    }
  );

  const { data: dataMedia, refetch: refetchMedia } =
    useGetMediaHistoricalDataQuery(
      {
        input: {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          imei: selectedAsset?.imei ?? "",
          customerOrgId: selectedAsset?.customer_orgs_id ?? "",
          period: DateRangeKey.Last30Days,
          limit: appConfig.assets.otherSensors.internalCameraSensor.maxImages,
        },
      },
      {
        enabled:
          Boolean(selectedAsset?.imei) &&
          Boolean(selectedAsset?.customer_orgs_id),
      }
    );

  const { isInitialLoading, data: cargoData } =
    useGetCargoSensorDataFromEventHistoryQuery(
      {
        input: {
          startDate: startDateCargo,
          endDate: endDateCargo,
          assetId: selectedAsset?.asset_id ?? "",
          reportType: AggregatedEventHistoryReportTypes.DailyReportCargo,
          includeEmptyDates: true,
        },
      },
      {
        enabled: Boolean(selectedAsset?.asset_id),
      }
    );

  useEffect(() => {
    if (cargoData?.searchAggregatedEventHistory?.data?.length) {
      setAggregatedCargoData(
        cargoData?.searchAggregatedEventHistory?.data as CargoData[]
      );
      if (noSensorData) setNoSensorData(false);
    }
    if (dataDoor?.searchAggregatedEventHistory?.data?.length) {
      setAggregatedDoorData(dataDoor?.searchAggregatedEventHistory?.data);
      if (noSensorData) setNoSensorData(false);
    }
    if (dataMedia?.searchHistoricalEventHistory?.data?.length) {
      const filteredMediaData = dataMedia?.searchHistoricalEventHistory?.data
        ?.map((d) => d?.mediaActivity)
        ?.filter((activity): activity is MediaActivity => Boolean(activity));

      setAggregatedMediaData(filteredMediaData ?? []);
      if (noSensorData) setNoSensorData(false);
    }
  }, [
    noSensorData,
    cargoData?.searchAggregatedEventHistory?.data,
    dataDoor?.searchAggregatedEventHistory?.data,
    dataMedia?.searchHistoricalEventHistory?.data,
  ]);

  if (isInitialLoading) {
    return (
      <Box
        className="flex h-full w-full items-center justify-center"
        data-testid="cargo-tab-panel-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (noSensorData) {
    return (
      <Box
        className="text-sm bg-dashboard_subheader__bg p-6 m-2 text-asset-info-subheader"
        data-testid="cargo-tab-panel-no-data"
      >
        No Sensors Available.
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="w-full p-4" data-testid="cargo-tab-panel">
        {/****** Cargo sensor ******/}
        {selectedAsset?.sensors?.chassis?.data?.cargoState &&
          selectedAsset.sensors.chassis.data.cargoState !== "unknown" && (
            <Grid
              container
              spacing={2}
              className="h-full w-full "
              id={Sections.Cargo}
            >
              <Grid item {...BREAKPOINTS} className="grid">
                <Box
                  sx={{ minWidth: 0 }}
                  className="flex justify-center bg-dashboard_subheader__bg "
                >
                  {selectedAsset && (
                    <CargoState selectedAsset={selectedAsset} />
                  )}
                </Box>
              </Grid>

              <Grid
                ref={gridContainerRefCargo}
                item
                {...BREAKPOINTS}
                className="grid"
              >
                <Box className="flex justify-center bg-dashboard_subheader__bg ">
                  <CargoStatus
                    data={aggregatedCargoData}
                    parentRef={gridContainerRefCargo}
                  />
                </Box>
              </Grid>

              <Grid item {...BREAKPOINTS} className="grid">
                <Box className="flex justify-center bg-dashboard_subheader__bg ">
                  <CargoStatusInLocations
                    data={aggregatedCargoData}
                    parentRef={gridContainerRefCargo}
                  />
                </Box>
              </Grid>

              <CargoTrendingGraph
                data={aggregatedCargoData}
                startDateCargo={startDateCargo}
                endDateCargo={endDateCargo}
                setStartDateCargo={setStartDateCargo}
                setEndDateCargo={setEndDateCargo}
                selectedDateRangeOptionCargo={selectedDateRangeOptionCargo}
                setSelectedDateRangeOptionCargo={
                  setSelectedDateRangeOptionCargo
                }
              />
            </Grid>
          )}

        {/****** Door sensor ******/}
        {selectedAsset?.sensors?.bleDoorSensor && (
          <Grid container direction="row" className="!mt-4" id={Sections.Door}>
            {/* Door card row */}
            {/* TODO: update lg make column bigger if there are 2 doors */}
            <Grid
              container
              direction="column"
              lg={3}
              ref={gridContainerRefDoor}
            >
              <Grid item className="grid !mb-4">
                <Box className="flex justify-center bg-dashboard_subheader__bg ">
                  <DoorState selectedAsset={selectedAsset} />
                </Box>
              </Grid>

              <Grid item className="grid !mb-4">
                <Box className="flex justify-center bg-dashboard_subheader__bg ">
                  <DoorStatus
                    data={aggregatedDoorData}
                    parentRef={gridContainerRefDoor}
                  />
                </Box>
              </Grid>

              <Grid item className="grid !mb-4">
                <Box className="flex justify-center bg-dashboard_subheader__bg ">
                  <DoorStatusInLocation
                    data={aggregatedDoorData}
                    parentRef={gridContainerRefDoor}
                  />
                </Box>
              </Grid>
            </Grid>

            {/****** Door card chart ******/}
            <Grid container direction="column" lg={9} className="pl-4">
              <Box>
                <DoorSensorGraph
                  data={aggregatedDoorData}
                  startDateDoor={startDateDoor}
                  endDateDoor={endDateDoor}
                  setStartDateDoor={setStartDateDoor}
                  setEndDateDoor={setEndDateDoor}
                  selectedDateRangeOptionDoor={selectedDateRangeOptionDoor}
                  setSelectedDateRangeOptionDoor={
                    setSelectedDateRangeOptionDoor
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {/* Internal Camera sensor */}
        {selectedAsset?.sensors?.cargoCamera && (
          <Grid
            container
            direction="row"
            spacing={2}
            className="!mt-2"
            id={Sections.Camera}
          >
            <Grid item lg={6} className="w-full">
              <Box className="flex justify-center bg-dashboard_subheader__bg">
                <InternalCameraStatus
                  data={aggregatedMediaData}
                  refreshMedia={refetchMedia}
                  deviceId={selectedAsset?.imei ?? ""}
                />
              </Box>
            </Grid>

            <InternalCameraFloorSpaceGraphProvider>
              <InternalCameraFloorSpaceGraph selectedAsset={selectedAsset} />
            </InternalCameraFloorSpaceGraphProvider>
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default CargoTabPanel;
