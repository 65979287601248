import { memo, useMemo, FC } from "react";
import { Grid, Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/formatters";
import { TileComponentProps } from "../../interfaces";
import SecondaryInfo from "../SecondaryInfo";
import DashboardTile from "../index";
import { calculateAssetsActivity } from "./helpers";

type DwellLegend = {
  count: number;
  title: string;
  description: string;
};

const ActiveAssets: FC<TileComponentProps> = (props) => {
  const { data, tile, ...otherProps } = props;

  const { assetsSummary } = data;
  const metrics: Record<string, DwellLegend> = useMemo(
    () => calculateAssetsActivity(assetsSummary),
    [assetsSummary]
  );

  const { active, parked, ...secondaryData } = metrics;

  return (
    <DashboardTile {...otherProps} url={tile.url}>
      <Grid container data-testid="dashboard-tile-active-assets">
        <Grid container item className="!mb-8 !flex-col">
          <Grid item>
            <Typography
              data-testid="active-assets-value"
              className="!text-5xl !font-normal !text-blue"
            >
              {formatNumber(active.count)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="!text-lg">Assets Moving</Typography>
          </Grid>
          <Grid item>
            <Typography
              data-testid="inactive-assets-value"
              className="!text-sm !font-bold"
            >
              {`${formatNumber(parked.count)} Parked Assets`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item className="!-mt-5  justify-around pl-10 pr-10">
          {Object.keys(secondaryData).map((key) => (
            <SecondaryInfo
              key={key}
              value={formatNumber(secondaryData[key].count)}
              title={metrics[key].title}
              subTitle={metrics[key].description}
            />
          ))}
        </Grid>
      </Grid>
    </DashboardTile>
  );
};

export default memo(ActiveAssets);
