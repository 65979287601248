import {
  ChangeEvent,
  memo,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Box,
  Button,
  ClickAwayListener,
  Popover,
  IconButton,
  Divider,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useQueryClient } from "@tanstack/react-query";
import { debounce, difference, intersection, isEmpty } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import {
  SET_PARENT_GEOFENCE,
  USE_PARENT_GEOFENCE_ORGID,
  SET_APP_CONFIG,
} from "../../../../constants";
import { ASSETS_LIST_WIDTH } from "../../../../constants/map";
import { useAppContext } from "../../../../context/AppContext";
import { LeaveModal } from "../../../../shared/components/LeaveModal";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { SwitchButtons } from "../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../shared/components/ToggleButtons";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { usePrompt } from "../../../../shared/hooks/usePrompt";
import { getStatusCounts, useFeatureFlag } from "../../../../utils";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import { NavigationRoutes } from "../../../../utils/routes/routesUtils";
import { getViewFromUrl } from "../../AssetsViewContainer";
import {
  AssetFilters,
  PageTypes,
  useAssetsDataContext,
} from "../AssetsDataContext";
import { AssetsStatusCount, SelectedFilterTab } from "./Filters";
import { GeofenceFilters } from "./Filters/GeofenceFilters";
import MoreActions from "./MoreActions";

export type StatusFilter = Pick<AssetFilters, "status">;
export type TFieldValues = {
  minDays: number;
  maxDays: number;
};
export type AssetsRouteKeys = "map" | "table" | "gallery";
export type GeofenceRouteKeys = "show-assets" | "hide-assets";

//-----------------------------
// constants
///-----------------------------
export const initialDayRange = {
  minDays: 0,
  maxDays: 0,
};

export const schema = yup.object({
  maxDays: yup.number().min(0),
  minDays: yup
    .number()
    .min(0)
    .test({
      name: "min-max-validation",
      message: "Min Value can not be greater than Max",
      test: (minDays, { parent }) => {
        const { maxDays } = parent;
        if (minDays === undefined) minDays = 0;
        if (minDays > maxDays) return false;
        return true;
      },
    }),
});

//-----------------------------
// Main Component
///-----------------------------
const AssetsFilterControls = () => {
  const location = useLocation();
  const urlPath = getViewFromUrl(location.pathname);

  const {
    dispatch,
    state: {
      appConfig,
      theme: { theme },
    },
  } = useAppContext();

  const isDarkMode = theme === "dark";

  const { selectedAssetsView } = appConfig;

  const initialAssetsView = selectedAssetsView ?? urlPath;
  const [assetsView, setAssetsView] = useState(initialAssetsView);
  const [geofenceView, setGeofenceView] =
    useState<GeofenceRouteKeys>("hide-assets");
  const [showMobileFilter, setShowMobileFilter] =
    useState<HTMLDivElement | null>(null);
  const [selectedFilterTab, setSelectedFilterTab] =
    useState<SelectedFilterTab>(null);
  const [isInitLoadComponent, setIsInitLoadComponent] = useState(false);
  const [isInitLoadWithStatus, setIsInitLoadWithStatus] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const [statusCount, setStatusCount] = useState<AssetsStatusCount>(
    {} as AssetsStatusCount
  );
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {
    pageType,
    onChangeFilters,
    currentFilter,
    isDrawingGeofence,
    setIsDrawingGeofence,
    setAssetsSearchInput,
    onPageChange,
    setShowAddAsset,
    setIsFileUploadDialogOpen,
    isGeofenceFormDirty,
    setIsGeofencesListVisible,
    isGeofencesListVisible,
    setDrawnGeofence,
    setDrawnGeofenceType,
    setDrawnGeofenceArea,
    setDrawnGeofenceAreaInSqKm,
    searchParams,
    setGeofenceNameInput,
    geofenceNameInput,
    geofenceForUpdate,
    restoreGeofencePreviousFilters,
    setAddGeofenceDrawerOpen,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
  } = useAssetsDataContext();
  const { setIsAssetsVisibleOnGeoFence } = useAssetsDataContext();
  const [searchValue, setSearchValue] = useState(geofenceNameInput);
  const methods = useForm<TFieldValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: initialDayRange,
  });
  const { reset } = methods;

  // set the reducer selectedAssetsView state from URL on initial load
  useEffect(() => {
    if (selectedAssetsView === "" && assetsView !== initialAssetsView) {
      dispatch({
        type: SET_APP_CONFIG,
        payload: { selectedAssetsView: assetsView },
      });
    }
  }, [assetsView, initialAssetsView, selectedAssetsView, dispatch]);

  // update the assets toggle button on URL change
  useEffect(() => {
    const view = getViewFromUrl(location.pathname);
    if (view === "map" || view === "table" || view === "gallery") {
      setAssetsView(view);
    }
  }, [location.pathname]);

  // set value for cards in filter status only on initial load. No refresh numbers on cards on click on it
  useEffect(() => {
    // check if it is initial load with URL contains status
    if (!isInitLoadWithStatus && !isInitLoadComponent) {
      setIsInitLoadWithStatus(
        searchParams.get("filters")?.includes("status") ?? false
      );
    }

    if (!isInitLoadComponent) {
      setIsInitLoadComponent(true);
    }
  }, [isInitLoadComponent, searchParams, isInitLoadWithStatus]);

  // if it is initial load with URL contains status on click it should reset status card value with all statuses
  // once show cards with all status values not update them any more just like initial load without status as filter in URL
  useEffect(() => {
    if (hasClick) {
      setStatusCount(getStatusCounts());
      setHasClick(false);
    }
  }, [hasClick, searchParams]);

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const onStatusFilterClick = useCallback(
    (selectedStatus: StatusFilter): MouseEventHandler<HTMLDivElement> =>
      () => {
        if (fetchAssetsFromOpenSearchFeatureFlag) {
          queryClient.invalidateQueries(["getAssetsForClustersOS"]);
          queryClient.invalidateQueries(["getAssetsForTableOS"]);
          queryClient.invalidateQueries(["getAssetsForListOS"]);
        } else {
          queryClient.invalidateQueries(["getAssetsClusters"]);
          queryClient.invalidateQueries(["getAssetsTableData"]);
          queryClient.invalidateQueries(["getAssetsForList"]);
        }

        if (Array.isArray(selectedStatus.status)) reset(initialDayRange); // reset days range on status button click
        if (
          Array.isArray(currentFilter.status) &&
          Array.isArray(selectedStatus.status)
        ) {
          // Status button is selected
          if (
            !isEmpty(
              intersection(
                currentFilter["status"] as string[],
                selectedStatus["status"] as string[]
              )
            )
          ) {
            // toggle filter selection on second click unselect filter
            onChangeFilters({
              status: difference(
                currentFilter["status"],
                selectedStatus["status"]
              ),
            });
          } else {
            // apply filters
            onChangeFilters({
              status: [...currentFilter.status, ...selectedStatus.status],
            });
          }
        } else if (
          !Array.isArray(currentFilter.status) &&
          typeof currentFilter.status === "object" &&
          Array.isArray(selectedStatus.status)
        ) {
          // Status range is selected
          onChangeFilters({ status: [...selectedStatus.status] });
        }
      },

    [
      currentFilter,
      onChangeFilters,
      reset,
      queryClient,
      fetchAssetsFromOpenSearchFeatureFlag,
    ]
  );
  const isMobile: boolean = useBreakpoint("down", "md");
  const onClickAway = (event: any) => {
    /*
      The following check is needed because of the QueryBuilder specificaly
      and how it renders part of it's control elements (Select's popup menu for example) directly into the "body" tag,
      which places them outside of the ClickAwayListener end therefore
      triggeres the onClickAway callback, when it's not needed.

      Note: As described in the docs: https://github.com/ukrbublik/react-awesome-query-builder#query-,
      "disableEnforceFocus" prop should do the trick but it doesn't
    */

    if (event?.target.localName === "body") return;

    setSelectedFilterTab(null);
    setShowMobileFilter(null);
  };

  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };

  const handleLeave = () => {
    setIsDrawingGeofence(!isDrawingGeofence);
    restoreGeofencePreviousFilters();
    setDrawnGeofence(null);
    setDrawnGeofenceType(null);
    setDrawnGeofenceArea(null);
    setDrawnGeofenceAreaInSqKm(null);
    setAddGeofenceDrawerOpen(false);

    // Open the drawers back
    setIsAssetsDrawerOpen(true);
    setIsFiltersDrawerOpen(true);

    handleLeaveModalClose();
    dispatch({
      type: SET_PARENT_GEOFENCE,
      payload: null,
    });
    dispatch({
      type: USE_PARENT_GEOFENCE_ORGID,
      payload: false,
    });
    if (geofenceForUpdate) {
      navigate(`/assets/geofences/${geofenceForUpdate._id}`);
    } else {
      navigate(`/assets/geofences`);
    }
  };

  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } = usePrompt(
    isGeofenceFormDirty,
    handleLeave
  );

  const handleGeofenceSearch = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setGeofenceNameInput(e.target.value);
    },
    appConfig.debounceTimeShort
  );

  const onGeofenceSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      handleGeofenceSearch(e);
    },
    [handleGeofenceSearch]
  );

  const onRefetchHandler = useCallback(() => {
    switch (pageType) {
      case PageTypes.AssetMap:
        // TODO: Refactor with PRJIND-9218
        if (fetchAssetsFromOpenSearchFeatureFlag) {
          queryClient.refetchQueries({ queryKey: ["getAssetsForListOS"] });
          queryClient.refetchQueries({ queryKey: ["getAssetsForClustersOS"] });
        } else {
          queryClient.refetchQueries({ queryKey: ["getAssetsForList"] });
          queryClient.refetchQueries({ queryKey: ["getAssetsClusters"] });
        }
        break;

      case PageTypes.AssetTable:
        // TODO: Refactor with PRJIND-9218
        if (fetchAssetsFromOpenSearchFeatureFlag) {
          queryClient.refetchQueries({ queryKey: ["getAssetsForTableOS"] });
        } else {
          queryClient.refetchQueries({ queryKey: ["getAssetsTableData"] });
        }
        break;

      case PageTypes.Geofences:
        queryClient.refetchQueries({ queryKey: ["findGeofences"] });
        queryClient.refetchQueries({ queryKey: ["getGeofenceType"] });

        // TODO: Refactor with PRJIND-9218
        if (fetchAssetsFromOpenSearchFeatureFlag) {
          queryClient.refetchQueries({ queryKey: ["getAssetsForListOS"] });
          queryClient.refetchQueries({ queryKey: ["getAssetsForClustersOS"] });
        } else {
          queryClient.refetchQueries({ queryKey: ["getAssetsClusters"] });
          queryClient.refetchQueries({ queryKey: ["getAssetsForList"] });
        }
        break;

      case PageTypes.AssetGallery:
        queryClient.refetchQueries({ queryKey: ["getAssetsForGallery"] });
        break;
    }
  }, [pageType, fetchAssetsFromOpenSearchFeatureFlag, queryClient]);

  const uploadHandler = () => {
    setIsFileUploadDialogOpen(true);
  };
  const newAssetsHandler = () => {
    setShowAddAsset(true);
  };

  const toggleAssetsListHandler = () => {
    setIsGeofencesListVisible(!isGeofencesListVisible);
  };

  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      title: "Upload",
      handler: uploadHandler,
      accessScope: "asset.upload",
    },
    {
      type: SubHeaderActionType.Button,
      primary: true,
      title: "New Asset",
      handler: newAssetsHandler,
      accessScope: "asset.create",
    },
  ];

  // if it is initial load with URL contains status, on click reset filter the values in card should be to full value
  const onDeleteHandler = () => {
    if (isInitLoadWithStatus && isInitLoadComponent) {
      setHasClick(true);
      setIsInitLoadWithStatus(false);
    }
  };

  const handleAssetsViewChange = (value: AssetsRouteKeys) => {
    if (!value) return;

    const routes: { [key in AssetsRouteKeys]: NavigationRoutes } = {
      map: NavigationRoutes.AssetMap,
      table: NavigationRoutes.AssetTable,
      gallery: NavigationRoutes.AssetGallery,
    };
    const selectedRoute = routes[value] ?? "map";
    const fullRoute = location.search
      ? `${selectedRoute}${location.search}`
      : selectedRoute;

    setAssetsView(value);

    dispatch({
      type: SET_APP_CONFIG,
      payload: { selectedAssetsView: value },
    });

    navigate(fullRoute);
  };

  const geofenceHandleViewChange = (value: GeofenceRouteKeys) => {
    if (!value) return;
    setIsAssetsVisibleOnGeoFence(value === "show-assets");
    setGeofenceView(value);
  };

  const ASSETS_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "map",
      label: "Map",
    },
    {
      value: "table",
      label: "List",
    },
    {
      value: "gallery",
      label: "Gallery",
    },
  ];
  const GEOFENCES_SWITCH_OPTIONS: ToggleButtonOption[] = [
    {
      value: "hide-assets",
      label: "Hide Assets",
    },
    {
      value: "show-assets",
      label: "Show Assets",
    },
  ];

  const switchOptionsMap: { [key: number]: ToggleButtonOption[] } = {
    [PageTypes.Geofences]: GEOFENCES_SWITCH_OPTIONS,
    [PageTypes.AssetMap]: ASSETS_SWITCH_OPTIONS,
  };

  const switchOptions = switchOptionsMap[pageType] ?? ASSETS_SWITCH_OPTIONS;

  const color = isDarkMode ? "white" : "black";
  const borderColor = isDarkMode ? "gray" : "var(--border-color)";

  if (pageType === PageTypes.ShareAssetsInvitation) {
    return null;
  }

  return (
    <>
      {pageType !== PageTypes.Geofences && isMobile && (
        <Box className="flex justify-center align-center bg-secondary p-2">
          <SwitchButtons
            id="assets-navigation-switch"
            data-testid="assets-navigation-switch-mobile"
            value={assetsView}
            onChange={(_, value) => handleAssetsViewChange(value)}
            options={ASSETS_SWITCH_OPTIONS}
            size="small"
            groupclass={`!w-[300px] min-w-80 flex justify-between h-8 overflow-hidden !rounded-3xl p-0.5 !border ${
              isDarkMode ? "!border-white" : "!border-black"
            }`}
            className={`!text-${color} !font-medium`}
            fullWidth
            exclusive
          />
        </Box>
      )}
      <Box
        data-testid="filter-bar-sub-header"
        className="relative z-50 flex h-[68px] items-center min-h-[68px] md:justify-between justify-center gap-4 md:gap-0 overflow-visible bg-secondary pl-4 text-base font-normal leading-none text-typography"
        sx={{
          flexDirection: pageType !== PageTypes.Geofences ? "column" : "row",
        }}
      >
        <LeaveModal
          isOpen={isLeaveModalOpen}
          confirmNavigation={() => setIsConfirmed(true)}
          onClose={handleLeaveModalClose}
        />
        {pageType === PageTypes.Geofences && isDrawingGeofence && (
          <Button
            className="block border-primary text-brand global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize"
            onClick={(e: MouseEvent) => {
              if (isGeofenceFormDirty) {
                setIsLeaveModalOpen(true);
              } else {
                handleLeave();
              }
            }}
            color="inherit"
            size="small"
            startIcon={<ArrowBackIcon />}
            data-testid="back-geofence-draw-button"
          >
            Back
          </Button>
        )}
        {pageType === PageTypes.Geofences && isMobile ? (
          <>
            <IconButton
              data-testid="subheader-toggle-assets-list"
              onClick={toggleAssetsListHandler}
            >
              {isGeofencesListVisible && (
                <MapIcon data-testid="subheader-hide-assets-list" />
              )}
              {!isGeofencesListVisible && (
                <TableViewIcon data-testid="subheader-show-assets-list" />
              )}
            </IconButton>
            <IconButton
              data-testid="geo_mobile-filter-icon-button"
              className="block text-primary md:!hidden"
              onClick={(e: MouseEvent) => {
                setSelectedFilterTab("geofence");
                setShowMobileFilter(e?.currentTarget as HTMLDivElement);
              }}
              color="inherit"
            >
              <FilterAltIcon />
            </IconButton>
            <FormProvider {...methods}>
              <MoreActions
                pageType={pageType}
                onRefetch={onRefetchHandler}
                onDeleteClick={onDeleteHandler}
              />
            </FormProvider>
          </>
        ) : (
          ""
        )}

        {pageType === PageTypes.Geofences && !isDrawingGeofence && (
          <FormProvider {...methods}>
            <section className="items-center hidden md:flex">
              <TextField
                id="geofences-search-field"
                label=""
                placeholder="Search geofence"
                variant="standard"
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                onChange={onGeofenceSearch}
                value={searchValue}
                sx={{
                  ".MuiInput-root.MuiInput-underline.Mui-focused:after": {
                    borderBottom: "2px solid rgba(0, 0, 0, 0.87) !important",
                  },
                }}
                data-testid="geofences-search-field"
              />
              {isMobile ? (
                <Popover
                  open={Boolean(showMobileFilter)}
                  anchorEl={showMobileFilter}
                  onClose={onClickAway}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <GeofenceFilters
                    setSelectedFilterTab={setSelectedFilterTab}
                    selectedFilterTab={selectedFilterTab}
                  />
                </Popover>
              ) : (
                <ClickAwayListener onClickAway={onClickAway}>
                  <div className="flex items-center geofence-controls">
                    <GeofenceFilters
                      setSelectedFilterTab={setSelectedFilterTab}
                      selectedFilterTab={selectedFilterTab}
                    />
                  </div>
                </ClickAwayListener>
              )}
              <MoreActions
                pageType={pageType}
                onRefetch={onRefetchHandler}
                onDeleteClick={onDeleteHandler}
              />
            </section>
          </FormProvider>
        )}
        {!isMobile && (
          <Box
            className="h-full px-4 flex justify-center items-center self-end"
            sx={{
              borderLeft: `1px solid ${borderColor}`,
              width: ASSETS_LIST_WIDTH,
            }}
          >
            <SwitchButtons
              id="assets-navigation-switch"
              data-testid="assets-navigation-switch-desktop"
              value={
                pageType !== PageTypes.Geofences ? assetsView : geofenceView
              }
              onChange={(_, value) =>
                pageType !== PageTypes.Geofences
                  ? handleAssetsViewChange(value)
                  : geofenceHandleViewChange(value)
              }
              options={switchOptions}
              size="small"
              groupclass={`h-8 overflow-hidden !rounded-3xl p-0.5 border border-${color}`}
              className={`!text-${color} !font-medium`}
              fullWidth
              exclusive
            />
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default memo(AssetsFilterControls);
