import { FC, memo, useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { SwitchButtons } from "../../../../../shared/components/SwitchButtons";
import { ToggleButtonOption } from "../../../../../shared/components/ToggleButtons";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import { useFeatureFlag } from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { useAssetsDataContext } from "../../AssetsDataContext/AssetsDataContext";
import { StatusesCheckboxFilterGrid } from "./SensorsFilter/components/StatusesCheckbox/StatusesCheckbox";
import InternalCameraPercentageFilter from "./SensorsFilter/components/singleSelectionFilters/InternalCameraPercentage";
import BackupBatteryVoltageSlider from "./SensorsFilter/components/sliders/BackupBatteryVoltageSlider";
import BatteryPrimaryVoltageSlider from "./SensorsFilter/components/sliders/BatteryPrimaryVoltageSlider";
import BatterySecondaryVoltageSlider from "./SensorsFilter/components/sliders/BatterySecondaryVoltageSlider";
import SolarAmperageSlider from "./SensorsFilter/components/sliders/SolarAmperageSlider";

export const CARGO_OPTIONS: ToggleButtonOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "unloaded",
    label: "Empty",
  },
  {
    value: "loaded",
    label: "Loaded",
  },
];

export const DOOR_OPTIONS: ToggleButtonOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

export const CAMERA_OPTIONS: ToggleButtonOption[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "empty",
    label: "Empty",
  },
  {
    value: "loaded",
    label: "Loaded",
  },
];

const StatusFilters: FC = () => {
  // Context / Hooks
  const {
    onChangeFilters,
    currentFilter: { doorState, cargoUltrasonicState, internalCameraStatus },
  } = useAssetsDataContext();

  const muiTheme = useCurrentTheme();

  const isDarkMode = muiTheme.palette.mode === "dark";
  const color = isDarkMode ? "white" : "black";

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  // State
  const [cargo, setCargo] = useState<string>("all");
  const [door, setDoor] = useState<string>("all");
  const [camera, setCamera] = useState<string>("all");

  // UseEffect
  useEffect(() => {
    if (doorState) {
      setDoor(doorState);
    }

    if (internalCameraStatus) {
      setCamera(internalCameraStatus);
    }

    if (cargoUltrasonicState) {
      setCargo(cargoUltrasonicState);
    }
  }, [
    doorState,
    internalCameraStatus,
    cargoUltrasonicState,
    setDoor,
    setCamera,
    setCargo,
  ]);

  const getFilterValue = (value: string) =>
    fetchAssetsFromOpenSearchFeatureFlag && value === "all" ? undefined : value;

  // Handlers
  const handleDoorChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value) {
      setDoor(value);
      const filterValue = {
        doorState: getFilterValue(value),
      };
      onChangeFilters(filterValue);
    }
  };

  const handleCargoChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value) {
      setCargo(value);
      const filterValue = {
        cargoUltrasonicState: getFilterValue(value),
      };
      onChangeFilters(filterValue);
    }
  };

  const handleCameraChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value) {
      setCamera(value);
      const filterValue = {
        internalCameraStatus: getFilterValue(value),
      };
      onChangeFilters(filterValue);
    }
  };

  return (
    <Box className="w-full">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Sensors</Box>
      </Box>

      <Box className="mb-6">
        <StatusesCheckboxFilterGrid />
      </Box>

      <Box className="h-20 mt-2 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Cargo</Box>

        <SwitchButtons
          id="filters-sensors-cargo"
          value={cargo}
          onChange={handleCargoChange}
          options={CARGO_OPTIONS}
          size="small"
          groupclass={`h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 ${
            isDarkMode ? "!border-white" : "!border-black"
          }`}
          className={`!text-${color}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="h-20 mt-2 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">Door</Box>

        <SwitchButtons
          id="filters-sensors-door"
          value={door}
          onChange={handleDoorChange}
          options={DOOR_OPTIONS}
          size="small"
          groupclass={`h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 ${
            isDarkMode ? "!border-white" : "!border-black"
          }`}
          className={`!text-${color}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="h-20 mt-2 flex flex-col justify-evenly">
        <Box className="font-bold text-[10px] leading-4">
          Internal Camera Status
        </Box>

        <SwitchButtons
          id="filters-sensors-internal-camera-status"
          value={camera}
          onChange={handleCameraChange}
          options={CAMERA_OPTIONS}
          size="small"
          groupclass={`h-[40px] overflow-hidden !rounded-[30px] p-0.5 !border-2 ${
            isDarkMode ? "!border-white" : "!border-black"
          }`}
          className={`!text-${color}`}
          fullWidth
          exclusive
        />
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Battery
        </Box>
        <Box className="w-[90%]">
          <BackupBatteryVoltageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Primary Voltage
        </Box>
        <Box className="w-[90%]">
          <BatteryPrimaryVoltageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">
          Secondary Voltage
        </Box>
        <Box className="w-[90%]">
          <BatterySecondaryVoltageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="h-[100px] my-4 flex flex-col justify-between items-center">
        <Box className="self-start font-bold text-[10px] leading-4">Solar</Box>
        <Box className="w-[90%]">
          <SolarAmperageSlider />
        </Box>
      </Box>

      <Divider />

      <Box className="my-4">
        <Box className="pb-2 font-bold text-[10px] leading-4">
          Internal Camera Floor Space
        </Box>
        <InternalCameraPercentageFilter />
      </Box>
    </Box>
  );
};

export default memo(StatusFilters);
