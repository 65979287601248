import { get, uniq } from "lodash";
import * as yup from "yup";
import config from "../config";
import { useAuthContext } from "../context/AuthContext";
import { env } from "../env";

const request = async <TData, TVariables>(
  query: string,
  skip: number,
  limit: number,
  tokens: any,
  accessToken: string,
  variables: TVariables,
  controller: AbortController
): Promise<TData> => {
  const res = await fetch(config.appsync.url, {
    signal: controller.signal,
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${tokens?.idToken}`,
      "Content-Type": "application/json",
      "X-Access-Token": `${accessToken}`,
    }),
    body: JSON.stringify({
      query,
      variables: {
        ...variables,
        input: {
          ...(variables as any)?.input,
          skip,
          limit,
        },
      },
    }),
  });

  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

interface Request<TData> {
  id: number;
  promise: Promise<TData | null | undefined>;
  controller: AbortController;
  params: unknown;
  completed: boolean;
  data?: TData | null | undefined;
  empty?: boolean;
  size: number | null;
}

const fetchers = {
  normal:
    <TData, TVariables>(
      query: string,
      tokens: any,
      accessToken: string,
      params: any
    ) =>
    async (variables?: TVariables): Promise<TData> => {
      const queryKey: keyof TData = get(params, "queryKey[0]");

      const now = Date.now();
      console.time(`[${now}] Query with name ${String(queryKey)} took`);
      const res = await fetch(config.appsync.url, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${tokens?.idToken}`,
          "Content-Type": "application/json",
          "X-Access-Token": `${accessToken}`,
        }),
        body: JSON.stringify({ query, variables }),
      });

      const json = await res.json();
      console.timeEnd(`[${now}] Query with name ${String(queryKey)} took`);
      if (json.errors) {
        const { message } = json.errors[0];

        throw new Error(message);
      }

      return json.data;
    },
  eager:
    <TData, TVariables>(
      query: string,
      tokens: any,
      accessToken: string,
      params?: any,
      options?: { limit?: number | string; concurrency?: number | string }
    ) =>
    async (variables?: TVariables): Promise<TData> => {
      let limit = yup
        .number()
        .default(5000)
        .min(10)
        .max(10000)
        .validateSync(options?.limit);
      let concurrency = yup
        .number()
        .default(3)
        .min(1)
        .max(6)
        .validateSync(options?.concurrency);
      let complete = false;
      let empty = false;
      let queryKey: keyof TData = get(params, "queryKey[0]");
      let iterations = -1;
      console.log(
        `[${String(
          queryKey
        )}]: Fetching using ${limit} limit in ${concurrency} threads.`
      );

      let result: TData | undefined = undefined;
      const requests: Array<Request<TData>> = [];
      let onDone: Function;
      const done = new Promise((resolve) => (onDone = resolve));
      const tick = () => {
        if (complete) {
          onDone();
          const lastChunk = requests.findIndex(
            (r) => typeof r.size === "number" && r.size < limit
          );
          requests.forEach((r, i) => {
            if (lastChunk > -1 && i > lastChunk) {
              r.empty = true;
              // Temporary solution which fixes PRJIND-2167.
              // Backend pagination will change the fetching strategy.
              // commented by Billy on 13.04.23:

              // r.controller.abort();
            }
          });
        } else {
          const completed = requests.reduce(
            (res, req) => (req.completed ? res + 1 : res),
            0
          );
          const available = concurrency - (requests.length - completed);
          for (let i = 0; i < available; i++) {
            const currentIteration = iterations + 1;
            iterations++;
            const controller = new AbortController();
            // eslint-disable-next-line no-loop-func
            const promise = new Promise<TData | null | undefined>((resolve) =>
              request<TData, TVariables>(
                query,
                iterations * limit,
                limit,
                tokens,
                accessToken,
                variables!,
                controller
              ).then((chunk) => {
                const size = chunk
                  ? (chunk[queryKey] as Array<unknown>)?.length
                  : 0;
                complete = size < limit;
                resolve(chunk);
                if (size === 0 && currentIteration === 0) {
                  // if first request resolved with empty data => set 'empty' flag
                  empty = true;
                } else {
                  tick();
                }
              })
            );
            promise.then((chunk) => {
              const req = requests.find((r) => r.promise === promise);
              const size = chunk
                ? (chunk[queryKey] as Array<unknown>)?.length
                : 0;

              if (req) {
                req.completed = true;
                req.data = chunk;
                req.empty = size === 0;
                req.size = size;
              } else {
                console.error(
                  `[${String(
                    queryKey
                  )}]: Unknown error. Couldn't find request source.`
                );
              }
            });
            requests.push({
              id: iterations,
              promise,
              controller,
              completed: false,
              params: {},
              size: null,
            });
          }
        }
      };

      console.time(`Query with name ${String(queryKey)} took`);
      tick();
      await done;
      console.timeEnd(`Query with name ${String(queryKey)} took`);
      const promises = requests
        .filter((r) => !r.controller.signal.aborted)
        .map((r) => r.promise);
      if (empty) {
        // if first request resolved with empty data => return empty data
        return { [queryKey]: [] } as TData;
      }
      await Promise.all(promises);

      if (env.REACT_APP_FETCHER_EAGER_DEBUG === "true") {
        console.log("Final requests state is:", requests);
        const records = result
          ? (result[queryKey] as Array<{ _id: string }>)
          : [];
        const ids: Array<string> = records.map((a) => a._id);
        const unique = uniq(ids);
        if (unique.length !== records.length) {
          console.error(
            `[${String(
              queryKey
            )}]: Records may contain duplicates. Total number of loaded records is ${
              records.length
            } but unique are ${unique.length}.`
          );
        } else {
          console.log(
            `[${String(queryKey)}]: All loaded records seems to be unique.`
          );
        }
      }

      const chunks = requests
        .filter((r) => r.completed && r.data)
        .map((r) => r.data);
      for (const chunk of chunks) {
        result =
          typeof result === "object" && result
            ? {
                ...(result as TData),
                [queryKey]: (result![queryKey] as Array<TData>).concat(
                  chunk![queryKey] as Array<TData>
                ),
              }
            : (chunk as TData);
      }
      return result as TData;
    },
  eagerFindGeofences:
    <TData, TVariables>(
      query: string,
      tokens: any,
      accessToken: string,
      params: any
    ) =>
    async (variables?: TVariables | any): Promise<TData> => {
      const queryKey: keyof TData = get(params, "queryKey[0]");
      const limitPerRequest = Number(
        process.env.REACT_APP_FETCHER_EAGER_GEOFENCES_LIMIT ?? 5000
      );
      const apiRequest = async (payload: any) => {
        const res = await fetch(config.appsync.url, {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${tokens?.idToken}`,
            "Content-Type": "application/json",
            "X-Access-Token": `${accessToken}`,
          }),
          body: JSON.stringify({ query, variables: payload }),
        });

        return await res.json();
      };

      const now = Date.now();
      console.time(`[${now}] Queries with name ${String(queryKey)} took`);
      let initialVariables = {
        input: {
          ...(variables?.input || {}),
          limit: limitPerRequest,
          skip: 0,
        },
      };
      const json = await apiRequest(initialVariables);
      if (json.errors) {
        const { message } = json.errors[0];

        throw new Error(message);
      }

      const firstSet = json.data.findGeofences;
      const pendingItemsToFetch = firstSet.total - firstSet.data.length;
      let data = firstSet.data;
      if (pendingItemsToFetch > 0) {
        const numberOFRequests = Math.ceil(
          pendingItemsToFetch / limitPerRequest
        );
        const responses = await Promise.all(
          Array(numberOFRequests)
            .fill(null)
            .map((a, index) => {
              initialVariables = {
                input: {
                  ...(variables?.input || {}),
                  limit: limitPerRequest,
                  skip: (index + 1) * limitPerRequest,
                },
              };
              return apiRequest(initialVariables);
            })
        );
        responses.forEach((res) => {
          if (res.errors) {
            const { message } = json.errors[0];

            throw new Error(message);
          }
          data = [...data, ...res.data.findGeofences.data];
        });
      }
      console.timeEnd(`[${now}] Queries with name ${String(queryKey)} took`);
      return {
        findGeofences: { data, skip: 0, total: firstSet.total },
      } as TData;
    },
};

export function useFetcher<TData, TVariables>(query: string) {
  let { tokens, accessToken } = useAuthContext();
  return (variables?: TVariables, params?: any) => {
    if (!accessToken || accessToken === "undefined") {
      accessToken = localStorage.getItem("accessToken");
    }
    if (get(params, "queryKey[0]") === "findAssets") {
      return fetchers.eager<TData, TVariables>(query, tokens, params, {
        limit: env.REACT_APP_FETCHER_EAGER_DEFAULT_LIMIT,
        concurrency: env.REACT_APP_FETCHER_EAGER_DEFAULT_CONCURRENCY,
      })(variables);
    }
    if (get(params, "queryKey[0]") === "findGeofences") {
      return fetchers.eagerFindGeofences<TData, TVariables>(
        query,
        tokens,
        accessToken,
        params
      )(variables);
    }
    return fetchers.normal<TData, TVariables>(
      query,
      tokens,
      accessToken,
      params
    )(variables);
  };
}
