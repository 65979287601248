import { Box, Typography, Button } from "@mui/material";
import { DashboardLibraryWidget } from "../../../../graphql/operations";
import { Button as SubmitButton } from "../../../../shared/components/Button";

export interface SaveDiscardChangesPopupProps {
  newlyAddedWidgets: Partial<DashboardLibraryWidget>[];
  onSave: () => void;
  onDiscard: () => void;
  processing: boolean;
}

const SaveDiscardChangesPopup = ({
  newlyAddedWidgets,
  onSave,
  onDiscard,
  processing,
}: SaveDiscardChangesPopupProps) => {
  return (
    <>
      {newlyAddedWidgets.length > 0 && (
        <Box
          className="mt-1"
          data-testid="page-dashboard-apply-changes-popup"
          style={{
            display: "block",
            borderTop:
              "1px solid var(--dashboard-view-apply-changes-popup-border)",
            borderBottom:
              "1px solid var(--dashboard-view-apply-changes-popup-border)",
            backgroundColor: "var(--dashboard-view-header-background)",
          }}
        >
          <Box className="inline-block p-4">
            <Typography sx={{ color: "var(--primary)" }}>
              You have made changes to the dashboard. Would you like to save
              them?
            </Typography>
          </Box>
          <Box
            className="inline-block  float-right"
            style={{
              marginRight: "1rem",
              verticalAlign: "middle",
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Box className="inline-block">
              <Button
                className="!mr-2 !text-base !font-bold !text-brand global-btn !rounded-[100px] !border-2 !border-solid !py-1 !text-sm !font-bold !text-primary"
                variant={"text"}
                type="button"
                onClick={onDiscard}
              >
                Discard
              </Button>
            </Box>
            <Box className="inline-block">
              <SubmitButton
                sx={{ margin: 0, fontSize: "14px !important" }}
                text={processing ? "Saving..." : "Save"}
                disabled={processing}
                theme="blue"
                variant="default"
                type="submit"
                onClick={onSave}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SaveDiscardChangesPopup;
