import { GridColDef } from "@mui/x-data-grid-premium";
import { ABSFaultCodeConfigurationSetTableData } from "./interfaces";

export enum AbsFaultCodeFieldName {
  Manufacturer = "manufacturer",
  FaultCodeType = "faultCodeType",
  Pgn = "pgn",
  Dtc = "dtc",
  Sid = "sid",
  Fmi = "fmi",
  ProblemArea = "problemArea",
  Cause = "cause",
  Description = "description",
  PctExplains = "pctExplains",
}

export const ABS_FAULT_CODES_TABLE_COLUMNS: GridColDef<ABSFaultCodeConfigurationSetTableData>[] =
  [
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Manufacturer,
      headerName: "Manufacturer",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.FaultCodeType,
      headerName: "Fault Code Type",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Pgn,
      headerName: "PGN",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Dtc,
      headerName: "DTC",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Sid,
      headerName: "SID",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Fmi,
      headerName: "FMI",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.ProblemArea,
      headerName: "Problem Area",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Cause,
      headerName: "Cause",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.Description,
      headerName: "Description",
    },
    {
      flex: 1,
      field: AbsFaultCodeFieldName.PctExplains,
      headerName: "PCT Explains",
    },
  ];

export const ABS_FAULT_CODES_EXPORT_FILENAME = "ABS Fault Codes";
