import { FC, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { ReactComponent as ImpersonateSvg } from "../../../../../assets/svgs/impersonate.svg";
import {
  PAGE_SNACKBAR,
  PAGE_SPINNER,
  ROOT_ORGANIZATION_NAME,
  SELECTED_ORGANIZATION,
} from "../../../../../constants";
import {
  USER_DELETION_FAILED_PAYLOAD,
  USER_DELETION_SUCCESS_PAYLOAD,
  USER_ACTIVATION_FAILED_PAYLOAD,
  USER_ACTIVATION_SUCCESS_PAYLOAD,
  USER_FORM_FIELDS,
  USER_RESEND_CONFIRMATION_FAILED_PAYLOAD,
  USER_ROLES,
  USER_UPDATE_SUCCESS_PAYLOAD,
  USER_DEACTIVATION_SUCCESS_PAYLOAD,
  USER_DEACTIVATION_FAILED_PAYLOAD,
  USER_UPDATE_FAILED_PAYLOAD,
  USER_IMPERSONATION_SUCCESS_PAYLOAD,
  USER_IMPERSONATION_FAILED_PAYLOAD,
} from "../../../../../constants/users";
import { useAppContext } from "../../../../../context/AppContext";
import { useAuthContext } from "../../../../../context/AuthContext";
import {
  useUpdateUserMutation,
  useResendConfirmationMutation,
  useDeleteUserMutation,
  useActivateUserMutation,
  useImpersonateUserMutation,
  useGetUserDataQuery,
  UserStatus,
  UpdateUserInput,
  UserRole,
  UsersTableData,
} from "../../../../../graphql/operations";
import { Button, TextButton } from "../../../../../shared/components/Button";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import Text from "../../../../../shared/components/Text";
import { useApplicationTheme } from "../../../../../shared/hooks/theme/useApplicationTheme";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { useAvailableOrgs } from "../../../../../shared/hooks/useAvailableOrgs";
import { useSpinner } from "../../../../../shared/hooks/useSpinner";
import { parsePhoneNumber } from "../../../../../utils";
import { NavigationRoutes } from "../../../../../utils/routes/routesUtils";
import { useOrgsOptions } from "../../../../AssetsView/TableView/hooks";
import { UserFormContent } from "../UserFormContent";
import { UserRoleDropdownItem } from "../helpers";
import { UserFormValues, useUserForm } from "../hooks/useUserForm";
import UserActivityLogDialog from "./UserActivityLogDialog";

export interface EditUserFormProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentOrgName: string | undefined;
  isSuperAdmin: boolean;
  user: UsersTableData | undefined;
  onUserUpdate: () => void;
  isClientAdmin: boolean;
  isSupervisor: boolean;
  isMaintenanceManager: boolean;
  setOpenResetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  rolesForSuperAdminOptions: UserRoleDropdownItem[];
  rolesForClientAdminOptions: UserRoleDropdownItem[];
}

const EditUserForm: FC<EditUserFormProps> = ({
  open,
  setOpen,
  parentOrgName,
  isSuperAdmin,
  user,
  onUserUpdate,
  isClientAdmin,
  isSupervisor,
  isMaintenanceManager,
  setOpenResetDialog,
  rolesForSuperAdminOptions,
  rolesForClientAdminOptions,
}) => {
  const { userRolePermissions, decodedToken } = useAuthContext();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showUserActivityLogDialog, setShowUserActivityLogDialog] =
    useState<boolean>(false);
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { refetch } = useGetUserDataQuery();
  const { refreshAuthTokens$ } = useAuthContext();
  const navigate = useNavigate();
  const formTheme = useFormTheme();
  const breakpoints = { xs: 12 };

  const [_, setCookie, removeCookie] = useCookies([SELECTED_ORGANIZATION]);
  const availableOrgs = useAvailableOrgs();
  const orgOptionsSorted = useOrgsOptions(availableOrgs);
  const orgOptions = useMemo(() => {
    return orgOptionsSorted.map((org) => ({
      id: org.id ?? "",
      label: org.label ?? "",
    }));
  }, [orgOptionsSorted]);

  const getGroups = (user: UsersTableData | undefined): UserRole | null => {
    if (!user) {
      return null;
    }

    const normalizedValue = user.role.replace(/\s+/g, "").toLowerCase();

    const matchedRole = rolesForSuperAdminOptions.find(
      (x) => x.value === normalizedValue
    );

    if (matchedRole) {
      return matchedRole.value as unknown as UserRole;
    } else {
      return null;
    }
  };

  if (user && user.phoneNumber) {
    user = { ...user, phoneNumber: user.phoneNumber.replace("+1", "") };
  }
  const defaultValues: UserFormValues = {
    name: user?.username || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    customer_orgs_id: user?.orgId ?? null,
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    groups: getGroups(user),
  };
  const { form, getValues } = useUserForm(defaultValues);
  const {
    control,
    formState: { errors },
    trigger,
  } = form;
  const { dispatch } = useAppContext();
  const { applicationTheme } = useApplicationTheme();
  const isDarkTheme = applicationTheme === "dark";
  const buttonTheme = isDarkTheme ? "white" : "black";

  /**
   * NOTE: we get user data from users_table_data where the Status is capitalized
   * so we need to convert the status to lowercase to correctly compare it against UserStatus enum
   */
  const isUserDeactivated =
    user?.status?.toLowerCase() === UserStatus.Deactivated;
  const isUserActive = user?.status?.toLowerCase() === UserStatus.Active;
  const isUserPending = user?.status?.toLowerCase() === UserStatus.Pending;

  const handleClose = () => {
    setOpen(false);
  };

  const {
    mutate: deleteUserMutation,
    isLoading: deleteUserIsLoading,
    isSuccess: deleteUserIsSuccess,
    isError: deleteUserIsFailed,
  } = useDeleteUserMutation({
    onSuccess: (_, { user }) => {
      // there are two cases:
      // 1 - the user is deactivated deletePermanently - false
      // 2 - the user is removed   deletePermanently - true
      // showing different message depending on the case
      const deletePermanently = user?.deletePermanently;

      dispatch({
        type: PAGE_SNACKBAR,
        payload: deletePermanently
          ? USER_DELETION_SUCCESS_PAYLOAD
          : USER_DEACTIVATION_SUCCESS_PAYLOAD,
      });
      onUserUpdate();
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: (_, { user }) => {
      const deletePermanently = user?.deletePermanently;

      dispatch({
        type: PAGE_SNACKBAR,
        payload: deletePermanently
          ? USER_DELETION_FAILED_PAYLOAD
          : USER_DEACTIVATION_FAILED_PAYLOAD,
      });
    },
  });

  const {
    mutate: activateUserMutation,
    isLoading: activateUserIsLoading,
    isSuccess: activateUserIsSuccess,
  } = useActivateUserMutation({
    onSuccess: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: USER_ACTIVATION_SUCCESS_PAYLOAD,
      });
      onUserUpdate();
      setTimeout(() => {
        handleClose();
      }, 3000);
    },
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: USER_ACTIVATION_FAILED_PAYLOAD,
      });
    },
  });

  const {
    mutate: resendConfirmationMutation,
    isLoading: resendConfirmationIsLoading,
    isSuccess: resendConfirmationSuccess,
  } = useResendConfirmationMutation({
    onError: () => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: USER_RESEND_CONFIRMATION_FAILED_PAYLOAD,
      });
    },
  });

  const resendConfirmation = () => {
    if (user) {
      resendConfirmationMutation({ user: { name: user.username } });
    }
  };

  const { mutate, isLoading } = useUpdateUserMutation({
    onSuccess: () => {
      onUserUpdate();
      handleClose();
      dispatch({
        type: PAGE_SNACKBAR,
        payload: USER_UPDATE_SUCCESS_PAYLOAD,
      });
    },
    onError: (error: Error | null) => {
      const errorMessage = error ? error.message : "Something Went Wrong.";
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...USER_UPDATE_FAILED_PAYLOAD,
          text: errorMessage,
        },
      });
    },
  });

  const handleRemoveUser = () => {
    const ableToDeleteUser = !deleteUserIsLoading || !deleteUserIsSuccess;
    if (ableToDeleteUser && user) {
      if (isUserPending) {
        deleteUserMutation({
          user: { name: user.username, deletePermanently: true },
        });
      } else {
        deleteUserMutation({
          user: { name: user.username, deletePermanently: false },
        });
      }
    }
  };

  const handleActivateUser = () => {
    const ableToActivateUser = !activateUserIsLoading || !activateUserIsSuccess;

    if (ableToActivateUser && user) {
      activateUserMutation({
        user: { name: user.username },
      });
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const valid = await trigger();

    if (!valid) {
      return;
    }

    const userData = data as UserFormValues;
    const selectedOrgId = userData.customer_orgs_id ?? "";
    const customer_orgs_id = selectedOrgId ? [selectedOrgId] : [];
    const userPayload: UpdateUserInput = {
      name: userData.name,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      customer_orgs_id: customer_orgs_id,
      groups: userData.groups !== null ? [userData.groups] : [],
    };

    if (userData?.phoneNumber) {
      userPayload.phoneNumber = parsePhoneNumber(userData.phoneNumber, true);
    }

    mutate({
      user: userPayload,
    });
  };

  const updateUserRoleDropdownValues = () => {
    const fields: any[] = JSON.parse(JSON.stringify(USER_FORM_FIELDS));
    const groupsIndex = fields?.findIndex((obj) => obj.name === "groups");
    if (groupsIndex > 0) {
      const isUserSuperAdmin = user?.role === USER_ROLES.SUPER_ADMIN;
      const isUserMemberOfPhillipsConnect =
        user?.organizationName === ROOT_ORGANIZATION_NAME;

      const groups = {
        ...fields[groupsIndex],
        options:
          !!isSuperAdmin &&
          (!!isUserSuperAdmin || isUserMemberOfPhillipsConnect)
            ? rolesForSuperAdminOptions
            : rolesForClientAdminOptions,
      };

      return groups;
    }
  };
  const roleSelect = updateUserRoleDropdownValues();
  const { mutate: IMPERSONATE_USER, isLoading: IMPERSONATION_USER_LOADING } =
    useImpersonateUserMutation({
      onSuccess: () => {
        setOpen(false);
        setIsImpersonating(true);

        refreshAuthTokens$((error: Error | null) => {
          if (error) {
            const displayError = error
              ? error?.message
              : "Something went wrong";
            dispatch({
              type: PAGE_SNACKBAR,
              payload: {
                ...USER_IMPERSONATION_FAILED_PAYLOAD,
                text: displayError,
              },
            });
          } else {
            queryClient.invalidateQueries();
            refetch();
            dispatch({
              type: PAGE_SNACKBAR,
              payload: USER_IMPERSONATION_SUCCESS_PAYLOAD,
            });

            setTimeout(() => {
              navigate(NavigationRoutes.AssetMap);
              // this will refresh the page after navigate to desirable location to apply all related to user & organization data, brands and etc
              navigate(0);
              dispatch({ type: PAGE_SNACKBAR, payload: null });
            }, 3000);
          }
        });
      },
      onError: (error: Error | null) => {
        const displayError = error ? error.message : "Something went wrong";
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            ...USER_IMPERSONATION_FAILED_PAYLOAD,
            text: displayError,
          },
        });
        setIsImpersonating(false);

        dispatch({
          type: PAGE_SPINNER,
          payload: { increment: 0 },
        });
      },
    });

  const handleImpersonation = () => {
    if (!isImpersonating) {
      setIsImpersonating(true);
      setShowSpinner(true);
      removeCookie(SELECTED_ORGANIZATION, { path: "/" });
      if (!IMPERSONATION_USER_LOADING && user) {
        IMPERSONATE_USER({
          impersonationUserInput: {
            active: true,
            impersonate_by: decodedToken?.["cognito:username"],
            target_user: user.username,
          },
        });
      }
    }
  };

  const isImpersonationDisabled =
    !isSuperAdmin ||
    decodedToken?.impersonation ||
    (user &&
      (decodedToken?.name === user.username ||
        /**
         * NOTE: we get user data from users_table_data where the Status is capitalized
         * so we need to convert the status to lowercase to correctly compare it against UserStatus enum
         */
        user.status?.toLowerCase() !== UserStatus.Active));

  const isResetOptionVisible =
    (isSuperAdmin || isClientAdmin || isSupervisor || isMaintenanceManager) &&
    isUserActive;
  useSpinner(showSpinner);

  const isUpdatingUser =
    isLoading || activateUserIsLoading || deleteUserIsLoading;
  const isSubmitDisabled =
    isUpdatingUser || isUserDeactivated || isEmpty(form.formState.dirtyFields);
  const headerText = `${user?.firstName} ${user?.lastName}`;
  return (
    <Drawer
      testId="edit-user-drawer"
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DrawerHeader
        text={headerText}
        sub={user?.organizationName}
        onClose={handleClose}
      />

      <DrawerContent>
        <UserFormContent
          form={form}
          availableOrgs={availableOrgs}
          roleSelect={roleSelect}
          breakpoints={breakpoints}
          formTheme={formTheme}
          orgOptions={orgOptions}
          isEditMode={true}
        />
        {userRolePermissions.user?.edit && isUserPending && (
          <>
            {!resendConfirmationSuccess && (
              <Grid
                container
                className="space-between align-center justify-space-between bg-background px-6 py-5"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Text
                    fontSize={14}
                    fontWeight="regular"
                    dataTestId="text-resend-verification-email"
                    classes="!text-primary"
                  >
                    Resend verification email?
                  </Text>
                </Grid>
                <Grid item>
                  <Button
                    onClick={resendConfirmation}
                    size="medium"
                    text={resendConfirmationIsLoading ? "Sending..." : "Resend"}
                    disabled={resendConfirmationIsLoading}
                    data-testid="btn-user-resend-email"
                    theme={buttonTheme}
                    sx={{ border: "2px solid!important" }}
                  />
                </Grid>
              </Grid>
            )}
            {resendConfirmationSuccess && (
              <Grid
                container
                className="space-between align-center justify-space-between bg-background px-6 py-5"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    fontSize={"small"}
                    data-testid="text-user-resend-email-success"
                  >
                    <DoneIcon fontSize="small" color="success" /> Verification
                    email has been sent
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid
          container
          className="space-between align-center justify-space-between bg-background px-6 pt-10"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Text
              fontSize={14}
              fontWeight="regular"
              dataTestId="text-activity-log-title"
              classes="!text-primary"
            >
              Activity Log
            </Text>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setShowUserActivityLogDialog(true)}
              size="medium"
              text="Open"
              dataTestid="btn-user-open-activity-log"
              theme={buttonTheme}
              sx={{ border: "2px solid!important" }}
            />
          </Grid>
        </Grid>
        <UserActivityLogDialog
          userEmail={user?.email ?? ""}
          showUserActivityLogDialog={showUserActivityLogDialog}
          setShowUserActivityLogDialog={setShowUserActivityLogDialog}
        />

        {isResetOptionVisible && (
          <Grid
            container
            className="space-between align-center justify-space-between bg-background px-6 pt-5"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Text fontSize={14} fontWeight="regular" classes="!text-primary">
                Reset user password
              </Text>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setOpenResetDialog(true)}
                size="medium"
                text={"Reset"}
                data-testid="btn-user-reset-email"
                theme={buttonTheme}
                sx={{ border: "2px solid!important" }}
              />
            </Grid>
          </Grid>
        )}

        <DrawerActions
          deleteBtnTestId="btn-user-remove"
          deleteBtnText={isUserPending ? "Delete" : "Deactivate"}
          disabled={isUpdatingUser}
          showDeleteBtn={!isUserDeactivated}
          onDelete={handleRemoveUser}
          onCancel={handleClose}
        >
          <>
            {!isImpersonationDisabled && (
              <Grid container className="justify-center bg-background py-3">
                <Grid item>
                  <TextButton
                    text="Impersonate"
                    size="medium"
                    className="!text-brand"
                    icon={<ImpersonateSvg />}
                    iconPosition={"left"}
                    disabled={isUpdatingUser}
                    onClick={handleImpersonation}
                    data-testid="btn-user-impersonate"
                  />
                </Grid>
              </Grid>
            )}
            {isUserDeactivated && (
              <Grid container className="justify-center bg-background py-3">
                <Grid item>
                  <TextButton
                    text="Activate"
                    size="medium"
                    className="!text-success"
                    icon={<VerifiedUserIcon />}
                    iconPosition={"left"}
                    disabled={isUpdatingUser}
                    onClick={handleActivateUser}
                    data-testid="btn-user-activate"
                  />
                </Grid>
              </Grid>
            )}
          </>
        </DrawerActions>
      </DrawerContent>

      <DrawerFooter
        text={isUpdatingUser ? "Saving..." : "Save"}
        disabled={isSubmitDisabled}
        testId="edit-user-form-submit-btn"
        submit={() => onSubmit(getValues())}
      />
    </Drawer>
  );
};

export default EditUserForm;
