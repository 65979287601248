import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useGetUserDataQuery } from "../graphql/operations";
import Spinner from "../shared/components/Spinner";
import { NavigationRoutes } from "../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../utils/routes/useCheckRouteSecurity";
import { useFeatureFlagProtectedRoutes } from "../utils/routes/useFeatureFlagProtectedRoutes";

const RequireAuth = ({ children }: { children: ReactElement }) => {
  let { isAuthorized, tokens, userInfo } = useAuthContext();
  const { isFeatureEnabled } = useFeatureFlagProtectedRoutes();
  const { hasRoleAccess, defaultPath } = useCheckRouteSecurity();
  const { data: accountData, isFetching } = useGetUserDataQuery(undefined, {
    enabled: isAuthorized,
  });

  const hasAcceptedTerms =
    accountData?.me.onboarding_details?.terms_conditions_accepted;

  const isImpersonated =
    userInfo?.username && accountData
      ? userInfo?.username !== accountData.me.name
      : true;

  if (!tokens) {
    return <Navigate to={NavigationRoutes.Login} />;
  }

  if (isAuthorized) {
    if (!hasRoleAccess(children) || !isFeatureEnabled(children)) {
      return <Navigate to={defaultPath} />;
    }
    if (!isFetching && !hasAcceptedTerms && !isImpersonated) {
      return <Navigate to={NavigationRoutes.TermsAndConditions} />;
    }
    return children;
  }
  return <Spinner counter={Number(!isAuthorized)} />;
};

export default RequireAuth;
