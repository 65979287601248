// global search options
export const filterMenuOptions = {
  assets: "Assets",
  locations: "Locations",
  devices: "Devices",
  users: "Users",
  organizations: "Organizations",
};

export enum GlobalSearchMenuOption {
  "Assets",
  "Locations",
  "Devices",
  "Users",
  "Organizations",
}
