import { TIME_ZONE_OPTIONS } from "./account";

export const ORGANIZATION_LOAD_FAILED_PAYLOAD = {
  title: "Organization load failed!",
  text: "Something Went Wrong.",
  severity: "error",
};

export const ORGANIZATIONS_LOAD_FAILED_PAYLOAD = {
  title: "Organizations load failed!",
  text: "Something Went Wrong. Unable to load Organizations list.",
  severity: "error",
};

export const ORGANIZATION_CREATE_FAILED_PAYLOAD = {
  title: "Organization Creation Failed!",
  text: "Organization name is already exist or something Went Wrong.",
  severity: "error",
};

export const ORGANIZATION_UPDATE_FAILED_PAYLOAD = {
  title: "Organization Update Failed!",
  text: "Something Went Wrong. Organization update failed.",
  severity: "error",
};

export const ORGANIZATION_CREATE_SUCCESS_PAYLOAD = {
  title: "Organization Created Successfully!",
  text: "You can find the Organization in the table.",
  severity: "success",
};

export const ORGANIZATION_UPDATE_SUCCESS_PAYLOAD = {
  title: "Organization Updated Successfully!",
  text: "You can find the Updated Organization in the table.",
  severity: "success",
};

export const ORGANIZATION_LOGO_UPLOAD_URL_QUERY_FAILED_PAYLOAD = {
  title: "Unable to upload thelogo",
  text: "Something Went Wrong.",
  severity: "error",
};

export const ORGANIZATION_DELETE_SUCCESS_PAYLOAD = {
  title: "Organization Deleted Successfully!",
  text: "The Organization has been deleted.",
  severity: "success",
};

export const ORGANIZATION_DELETE_FAILED_PAYLOAD = {
  title: "Organization Deletion Failed!",
  text: "The Organization is not eligible for deletion. Contact your Administrator.",
  severity: "error",
};

export const INPUT_FIELD_NAME = {
  name: "name",
  type: "text",
  label: "Organization Name",
  dataTestid: "input-org-name",
  required: true,
};

export const INPUT_FIELD_PARENT_COMPANY = {
  name: "parent_company",
  type: "autocomplete",
  label: "Parent Organization Name",
  dataTestid: "input-org-parent-org",
  options: [],
  required: true,
};

export const INPUT_FIELD_BRAND = {
  name: "brand",
  type: "autocomplete",
  label: "Brand",
  dataTestid: "input-org-brand",
  required: true,
  options: [],
};

export const INPUT_FIELD_TYPE = {
  name: "type",
  type: "autocomplete",
  label: "Type",
  dataTestid: "input-org-type",
  required: true,
  options: [
    {
      value: "Fleet",
      label: "Fleet",
    },
    {
      value: "Retail",
      label: "Retail",
    },
    {
      value: "Factory",
      label: "Factory",
    },
  ],
};

export const INPUT_FIELD_TIME_ZONE = {
  name: "time_zones",
  type: "autocomplete",
  label: "Time Zone",
  dataTestid: "input-org-time-zone",
  required: true,
  options: TIME_ZONE_OPTIONS,
};

export const INPUT_FIELD_DISTANCE_UNIT_PREFERENCE = {
  name: "distance_unit_preference",
  type: "autocomplete",
  label: "Distance",
  dataTestid: "input-org-distance-units",
  required: true,
  options: [],
};

export const INPUT_FIELD_PRESSURE_UNIT_PREFERENCE = {
  name: "pressure_unit_preference",
  type: "autocomplete",
  label: "Pressure",
  dataTestid: "input-org-pressure-units",
  required: true,
  options: [],
};

export const INPUT_FIELD_TEMPERATURE_UNIT_PREFERENCE = {
  name: "temperature_unit_preference",
  type: "autocomplete",
  label: "Temperature",
  dataTestid: "input-org-temperature-units",
  required: true,
  options: [],
};

export const INPUT_FIELD_DEFAULT_DASHBOARD = {
  name: "default_dashboard",
  type: "autocomplete",
  label: "Default Dashboard",
  dataTestid: "input-org-default-dashboard",
  options: [],
};

export const INPUT_FIELD_FAVORITE_DASHBOARDS = {
  name: "favorite_dashboards",
  type: "autocomplete",
  label: "Favorite Dashboards",
  dataTestid: "input-org-favorite-dashboards",
  options: [],
};

export const ORG_FORM_FIELDS = {
  orgNameInput: INPUT_FIELD_NAME,
  parentOrgInput: INPUT_FIELD_PARENT_COMPANY,
  brandInput: INPUT_FIELD_BRAND,
  orgTypeInput: INPUT_FIELD_TYPE,
  timezoneInput: INPUT_FIELD_TIME_ZONE,
  distanceUnitInput: INPUT_FIELD_DISTANCE_UNIT_PREFERENCE,
  pressureUnitInput: INPUT_FIELD_PRESSURE_UNIT_PREFERENCE,
  temperatureUnitInput: INPUT_FIELD_TEMPERATURE_UNIT_PREFERENCE,
  defaultDashboardInput: INPUT_FIELD_DEFAULT_DASHBOARD,
  favoriteDashboardsInput: INPUT_FIELD_FAVORITE_DASHBOARDS,
};
