import { FC, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Asset } from "../../../../../../graphql/operations";
import ToggleViewButton from "../../../Shared/ToggleViewButton";
import { TiresTabView } from "../../../Shared/ToggleViewButton/ToggleViewButton";
import AirBagCard from "./AirBagCard";
import AirBagTable from "./AirBagTable";

interface AirBagStatusCardsProps {
  selectedAsset: Asset;
}

export const axleLabels = new Map([
  [0, "Axle One"],
  [1, "Axle One"],
  [2, "Axle Two"],
  [3, "Axle Two"],
  [4, "Axle Three"],
  [5, "Axle Three"],
]);

const AirBagStatusCards: FC<AirBagStatusCardsProps> = ({ selectedAsset }) => {
  const { sensors } = selectedAsset;
  const sensorData = sensors?.airbag?.data?.measures ?? [];

  const [activeView, setActiveView] = useState<TiresTabView>(TiresTabView.Card);

  return (
    <>
      {sensorData.length > 0 && (
        <Grid item lg={6} className="w-full flex">
          <Box
            className="mb-4 w-full flex flex-col bg-dashboard_subheader__bg rounded-lg pr-4 pt-4 pl-4 pb-20"
            data-testid="airbag-status-cards-box"
          >
            <Box className="pb-4">
              <Grid item xs={12} className="flex justify-end relative">
                <Grid
                  item
                  xs={activeView === TiresTabView.Table}
                  style={{
                    position:
                      activeView === TiresTabView.Table
                        ? "absolute"
                        : "relative",
                  }}
                  className="flex"
                >
                  <ToggleViewButton
                    activeView={activeView}
                    onClick={setActiveView}
                    data-testid="toggle-view-button"
                    whiteTheme={true}
                  />
                </Grid>
              </Grid>
            </Box>
            {activeView === "card-view" && (
              <AirBagCard airbag={sensors?.airbag} data-testid="airbag-card" />
            )}
            {activeView === "table-view" && (
              <AirBagTable
                airbag={sensors?.airbag}
                data-testid="airbag-table"
              />
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default AirBagStatusCards;
