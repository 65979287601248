import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import { useFindAssetsByIdsQuery } from "../../../../graphql/operations";
import { useFindAssetListOptions } from "../../../../shared/hooks/useFindAssetListOptions";
import {
  NOMENCLATURE_NAMES,
  useNomenclatures,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  isFindAssetByIdQueryEnabled,
  onFindAssetsSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertMovingWithoutPrimOrSecPowerParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}

export const AlertMovingWithoutPrimOrSecPowerParameters: FC<
  AlertMovingWithoutPrimOrSecPowerParametersProps
> = ({ form }: AlertMovingWithoutPrimOrSecPowerParametersProps) => {
  const [search, setSearch] = useState(form.getValues().name ?? "");
  const { state } = useAppContext();
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const [assetsOptionsList, setAssetsOptionsList] = useState<
    { id: string; label: string }[]
  >([]);
  const assetTypeOptions = useNomenclatures(NOMENCLATURE_NAMES.assetType);

  const watchAssets = useWatch({
    name: "parameters.assetIds",
    control: form.control,
  });
  const watchAssetType = useWatch({
    name: "parameters.assetType",
    control: form.control,
  });

  let shouldBeEnabled = isFindAssetByIdQueryEnabled(watchAssets);

  const {
    data: foundAssets,
    isLoading: findAssetsLoading,
    isSuccess: findAssetsSuccess,
  } = useFindAssetsByIdsQuery(
    { assetIds: getAlertMultiselectIds(watchAssets) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );

  useEffect(() => {
    if (foundAssets && !findAssetsLoading && findAssetsSuccess) {
      onFindAssetsSettledCallback({
        data: foundAssets,
        form,
        setSelectedAsset,
      });
    }
  }, [
    foundAssets,
    findAssetsLoading,
    findAssetsSuccess,
    form,
    shouldBeEnabled,
  ]);

  useEffect(() => {
    form.setValue("parameters.assetIds", selectedAsset);
  }, [form, selectedAsset]);

  const { options: searchResult, isFetching: isGetAssetsForListFetching } =
    useFindAssetListOptions(search, state.appConfig.searchOptionsLimit);

  useEffect(() => {
    setAssetsOptionsList(searchResult);
  }, [searchResult]);

  const hideInputValue =
    !selectedAsset &&
    !!watchAssets?.length &&
    findAssetsLoading &&
    !findAssetsSuccess;

  return (
    <AlertParametersContainer>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="moving-without-prim-or-sec-power-assets-select"
        sx={{ marginTop: "-0.75rem" }}
      >
        <AutocompleteElement
          autocompleteProps={{
            noOptionsText: "No options",
            onInputChange: (event, newInputValue) => {
              if (newInputValue) {
                setSearch(newInputValue);
              }
            },
            onChange: (event, newInputValue) => {
              if (newInputValue) {
                form.setValue("parameters.assetIds", newInputValue);
                setSelectedAsset(newInputValue);
                setAssetsOptionsList([]);
                form.trigger();
              }
            },
            autoComplete: true,
            blurOnSelect: true,
            disabled: !!watchAssetType,
            readOnly: !!watchAssetType,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideInputValue || !option.label ? "loading" : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
            sx: {
              marginTop: "0.75rem",
              "& .MuiInputLabel-root": { marginTop: "-0.2rem" },
            },
          }}
          multiple
          loading={isGetAssetsForListFetching || hideInputValue}
          label="Asset(s)"
          control={form.control}
          name="parameters.assetIds"
          options={assetsOptionsList}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="moving-without-prim-or-sec-power-asset-type-select"
      >
        <AutocompleteElement
          autocompleteProps={{
            disabled: !!watchAssets?.length,
            readOnly: !!watchAssets?.length,
          }}
          matchId={true}
          label="Asset Type"
          control={form.control}
          name="parameters.assetType"
          options={assetTypeOptions}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
