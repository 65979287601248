import { useGetAssetsTagsQuery } from "../../../../../graphql/operations";

export const useGetAssetsTags = (): {
  tags: string[];
  isLoading: boolean;
} => {
  const { isLoading, data } = useGetAssetsTagsQuery({
    input: { limit: 100, skip: 0 },
  });
  const tags = data?.getAssetsTags ?? [];

  return {
    tags,
    isLoading,
  };
};
