import { Feature as GeoJsonFeature, Point } from "geojson";
import {
  EventHistoryName,
  EventNameStatus,
  eventHistoryNameMap,
  breadcrumbIconsMapping,
  BreadcrumbIconsMapping,
} from "../../../../../../constants/map";
import { HistoricalEventHistory } from "../../../../../../graphql/operations";

export interface FeatureProperties {
  eventName: string;
  location_update_event: boolean;
  health_event: boolean;
  cargo_event: boolean;
  door_event: boolean;
  location_entry_event: boolean;
  imei: string;
}

// Marker icons conditions
const LOCATION_UPDATE_EVENT_FILTER = [
  "==",
  ["get", "location_update_event"],
  true,
];
const HEALTH_EVENT_FILTER = ["==", ["get", "health_event"], true];
const DOOR_EVENT_FILTER = ["==", ["get", "door_event"], true];
const CARGO_EVENT_FILTER = ["==", ["get", "cargo_event"], true];
const LOCATION_ENTRY_EVENT_FILTER = [
  "==",
  ["get", "location_entry_event"],
  true,
];

export const LOCATION_UPDATE_HISTORY_EVENTS = [
  "all",
  LOCATION_UPDATE_EVENT_FILTER,
];
export const HEALTH_HISTORY_EVENTS = ["all", HEALTH_EVENT_FILTER];
export const DOOR_HISTORY_EVENTS = ["all", DOOR_EVENT_FILTER];
export const CARGO_HISTORY_EVENTS = ["all", CARGO_EVENT_FILTER];
export const LOCATION_ENTRY_HISTORY_EVENTS = [
  "all",
  LOCATION_ENTRY_EVENT_FILTER,
];

export interface BreadcrumbsFeature extends GeoJsonFeature {
  type: "Feature";
  geometry: Point;
  properties: FeatureProperties;
}

export const mapEventHistoryDataToFeature = (
  eventHistory: HistoricalEventHistory
): BreadcrumbsFeature => {
  let properties = {
    eventName: "",
    health_event: false,
    location_update_event: false,
    door_event: false,
    cargo_event: false,
    location_entry_event: false,
    imei: "",
  };
  let coordinates = [0, 0];
  let lat = eventHistory.breadcrumbs?.lat ?? 0;
  let long = eventHistory.breadcrumbs?.lon ?? 0;
  coordinates = [long, lat];

  let eventName =
    eventHistoryNameMap.get(
      eventHistory.breadcrumbs?.eventName as EventHistoryName
    ) ?? "";

  properties = {
    eventName: eventName,
    health_event: Boolean(eventName === EventNameStatus.Heartbeat),
    location_update_event: Boolean(
      eventName === EventNameStatus.LocationUpdate
    ),
    door_event: Boolean(eventName === EventNameStatus.Door),
    cargo_event: Boolean(eventName === EventNameStatus.Cargo),
    location_entry_event: Boolean(eventName === EventNameStatus.LocationEntry),
    imei: eventHistory?.breadcrumbs?.imei ?? "",
  };

  return {
    type: "Feature",
    properties,
    geometry: {
      type: "Point",
      coordinates,
    },
  };
};

export const getEventHistoryFeatures = (
  eventHistoryData: HistoricalEventHistory[]
) => {
  const features = eventHistoryData.map(mapEventHistoryDataToFeature);

  return features;
};

export const getBreadcrumbIcon = (feature: BreadcrumbsFeature) => {
  let type: keyof BreadcrumbIconsMapping = "";
  if (feature.properties?.cargo_event) {
    type = "cargo_event";
  } else if (feature.properties?.door_event) {
    type = "door_event";
  } else if (feature.properties?.health_event) {
    type = "health_event";
  } else if (feature.properties?.location_entry_event) {
    type = "location_entry_event";
  } else if (feature.properties?.location_update_event) {
    type = "location_update_event";
  }
  return breadcrumbIconsMapping[type];
};
