import { memo, useCallback, useState, ReactElement } from "react";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { dummyTiles, tileTypes } from "../../../constants/dashboardViewMocks";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import ActiveAssets from "../DashboardTile/ActiveAssets";
import TotalAssets from "../DashboardTile/TotalAssets";
import TotalDistance from "../DashboardTile/TotalDistance";
import { ItemTypes } from "../constants";
import { Tile } from "../interfaces";

const TilesContainer = () => {
  const [tiles, setTiles] = useState<Tile[]>(dummyTiles);
  const [, drop] = useDrop({ accept: ItemTypes.TILE });
  const navigate = useNavigate();

  const assetsSummary = {} as any;

  const handleClick = (url: string) => {
    navigate(url);
  };

  // a little function to help us with reordering the result
  const reorder = (list: Tile[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const findTile = useCallback(
    (id: string): { tile: Tile; index: number } => {
      const [tile] = tiles.filter((c) => c.url === id);
      return {
        tile,
        index: tiles.indexOf(tile),
      };
    },
    [tiles]
  );
  const moveTile = useCallback(
    (id: string, atIndex: number) => {
      const { index } = findTile(id);
      setTiles(reorder(tiles, index, atIndex));
    },
    [findTile, tiles, setTiles]
  );

  return (
    <Grid
      ref={drop}
      container
      spacing={4}
      justifyContent="center"
      data-testid="dashboard-dnd-container"
    >
      {tiles.map((tile) => {
        const tileProps = {
          key: tile.id,
          tile,
          moveTile,
          findTile,
          clickHandler: handleClick,
          data: { assetsSummary }, // data object can be extended in further API integrations to store more processable data
        };

        let content: ReactElement = <></>;

        switch (tile.type) {
          case tileTypes.totalAssets:
            content = <TotalAssets {...tileProps} />;
            break;
          case tileTypes.activeAssets:
            content = <ActiveAssets {...tileProps} />;
            break;
          case tileTypes.totalDistance:
            content = <TotalDistance {...tileProps} />;
            break;
          default:
        }

        return content;
      })}
    </Grid>
  );
};

export default memo(TilesContainer);
