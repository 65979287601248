import { FC } from "react";
import {
  Typography,
  MenuItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  GridToolbarExportContainer,
  GridExcelExportMenuItem,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportProps,
} from "@mui/x-data-grid-premium";
import { FileFormat } from "../../../graphql/operations";
import { ServerSideExportFormat } from "./types";

type GridExportMenuItemProps = {
  format: ServerSideExportFormat;
  title: string;
  onCLick: (format: ServerSideExportFormat) => void;
};

const GridExportMenuItem = ({
  format,
  title,
  onCLick,
}: GridExportMenuItemProps) => (
  <MenuItem
    data-testid={`table-toolbar-export-option-${format}`}
    onClick={() => onCLick(format)}
  >
    {title}
  </MenuItem>
);

const getServerSideExportOptions = (
  onCLick: (format: ServerSideExportFormat) => void
): GridExportMenuItemProps[] => [
  {
    format: FileFormat.Excel as ServerSideExportFormat,
    title: "Download as Excel",
    onCLick,
  },
  {
    format: FileFormat.Csv as ServerSideExportFormat,
    title: "Download as CSV",
    onCLick,
  },
  { format: "EMAIL", title: "Send by email", onCLick },
];

const TableExportToolbar: FC<GridToolbarExportProps> = ({
  excelOptions,
  csvOptions,
  printOptions,
  onExportClick,
  handleDataMode,
  ...props
}: GridToolbarExportProps) => {
  return (
    <GridToolbarExportContainer
      {...props}
      data-testid="table-toolbar-export-btn"
    >
      {(handleDataMode === "client" || handleDataMode === "semi-server") && [
        <GridExcelExportMenuItem options={excelOptions} key={"excel"} />,
        <GridCsvExportMenuItem options={csvOptions} key={"csv"} />,
        <GridPrintExportMenuItem options={printOptions} key={"print"} />,
      ]}
      {handleDataMode === "server" && [
        <ListItemButton
          onClick={() => {
            onExportClick("csv");
          }}
          key={"serverCSV"}
        >
          <ListItemText
            sx={{
              ":hover": {
                backgroundColor: "#3c88cc66",
              },
            }}
            primary={
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                  ":hover": {
                    backgroundColor: "#3c88cc66",
                  },
                }}
              >
                Download as CSV
              </Typography>
            }
            disableTypography
          />
        </ListItemButton>,
      ]}
      {handleDataMode === "full-server" &&
        getServerSideExportOptions(onExportClick).map((props) => (
          <GridExportMenuItem {...props} key={props.format} />
        ))}
    </GridToolbarExportContainer>
  );
};

export default TableExportToolbar;
