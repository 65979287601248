import { Box, Typography, Grid } from "@mui/material";
import { TooltipProps } from "recharts";
import { TPMS_Tire_Type } from "../../../../interfaces";

export enum TiresChartType {
  Temperature = "Temperature",
  Pressure = "Pressure",
}

export const CHART_TYPE_TO_UNIT = {
  [TiresChartType.Temperature]: "F",
  [TiresChartType.Pressure]: "PSI",
};

export interface ChartTooltipProps {
  type: TiresChartType;
  selectedTire: TPMS_Tire_Type | undefined;
  payload?: TooltipProps<string, string>["payload"];
}

export const TIRE_KEY_TO_LABEL = {
  innerCurbside: "Inner Right",
  primaryCurbside: "Primary Right",
  innerRoadside: "Inner Left",
  primaryRoadside: "Primary Left",
};

export const TiresChartTooltip: React.FC<ChartTooltipProps> = ({
  type,
  selectedTire,
  payload,
}) => {
  if (!payload?.length || !selectedTire) {
    return null;
  }

  const tooltipTitle = payload[0].payload?.tooltipDate;

  const unit = CHART_TYPE_TO_UNIT[type];

  const selectedTireData = payload.find(
    (item) => item.dataKey === selectedTire
  );

  // We want to display data only for the hovered tile
  const visibleTiresData = payload
    .filter((item) => {
      if (!item || !item.dataKey || !selectedTire) {
        return false;
      }

      // Show the selected tire for sure
      if (item.dataKey === selectedTire) {
        return true;
      }

      // But also, show the other tires if they have the same value as the selected tire
      if (
        selectedTireData?.payload?.[selectedTire] ===
        item.payload?.[item.dataKey]
      ) {
        return true;
      }

      return false;
    })
    .map((item) => item.dataKey);

  return (
    <Box
      className="rounded-lg bg-custom-tooltip-background border border-sub-header-border p-3 w-3/5"
      sx={{ minWidth: visibleTiresData.length === 1 ? "200px" : "400px" }}
    >
      <Typography
        className="!text-xs !text-sub-header-text !font-medium !leading-3 p-1"
        data-testid={`tires-chart-tooltip-label-${type}`}
      >
        {tooltipTitle}
      </Typography>
      <Grid
        container
        spacing={1}
        alignItems="center"
        data-testid="tires-chart-tooltip"
      >
        {payload.map((item, index) => {
          if (!item || !item.dataKey) return null;
          const key = item.dataKey as keyof typeof TIRE_KEY_TO_LABEL;

          const label = `${TIRE_KEY_TO_LABEL[key]} Tire`;

          const min = item.payload[`${key}-min`];
          const max = item.payload[`${key}-max`];

          const value = `${type}: ${min}${unit}-${max}${unit}`;

          return (
            <Grid
              item
              lg={visibleTiresData.length === 1 ? 12 : 6}
              sx={{
                display: visibleTiresData.includes(item.dataKey)
                  ? "block"
                  : "none",
              }}
              key={item.dataKey}
              data-testid={`tires-chart-tooltip-value-${type}-${index}`}
            >
              <Typography className="!text-xs !text-brand !font-bold !leading-3 p-1">
                {label}
              </Typography>
              <Typography className="!text-xs !text-sub-header-text !font-medium !leading-3 p-1">
                {value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
