import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import throttle from "lodash/throttle";
import { useAuthContext } from "../../../context/AuthContext";
import { useImpersonateUserMutation } from "../../../graphql/operations";

interface IUseSessionTimeoutProps {
  idleTimeout?: number;
  totalIdleThreshold?: number;
}
export const useSessionTimeout = ({
  idleTimeout = 300000,
  totalIdleThreshold = 300000, // default threshold
}: IUseSessionTimeoutProps) => {
  const isOpenedInitially =
    localStorage.getItem("timeoutModalIsOpen") === "true";
  const [isOpen, setIsOpen] = useState<boolean>(isOpenedInitially);
  const [totalIdle, setTotalIdle] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const bc = useMemo(() => new BroadcastChannel("timeout_channel"), []);

  const timerRef = useRef<{
    id: number;
    start: number;
  } | null>(null);

  // random identifier for page component to reduce extra timer updates
  const tabId = Math.random().toString(36).slice(2);

  const { logout, decodedToken } = useAuthContext();

  const { mutate: IMPERSONATE_USER_MUTATION } = useImpersonateUserMutation();

  const handleLogout = () => {
    if (decodedToken?.impersonation) {
      const target_user = decodedToken.name;
      IMPERSONATE_USER_MUTATION({
        impersonationUserInput: {
          active: false,
          impersonate_by: decodedToken?.["cognito:username"],
          target_user,
        },
      });
    }
    logout();
  };

  const onIdle = () => {
    localStorage.setItem("timeoutModalIsOpen", "true");
    setIsOpen(true);
  };

  const resetTimer = useCallback(() => {
    if (timerRef?.current) {
      clearTimeout(timerRef.current.id);
    }

    timerRef.current = {
      id: setTimeout(onIdle, idleTimeout) as unknown as number,
      start: Date.now(),
    };
  }, [idleTimeout]);

  // throttled handler to update a timer, when user is active
  const handleUserActivity = useMemo(
    () =>
      throttle(() => {
        if (localStorage.getItem("timeoutModalIsOpen") !== "true") {
          resetTimer();
          bc.postMessage(`update-${tabId}`);
        }
      }, 3000),
    [bc, resetTimer, tabId]
  );

  const storageCallBack = (event: StorageEvent) => {
    if (event.key === "timeoutModalIsOpen" && event.newValue === "true") {
      // open modal if it was open on another tab
      setIsOpen(true);
    } else if (
      event.key === "timeoutModalIsOpen" &&
      event.newValue === "false"
    ) {
      // close modal if it was closed on another tab
      handleCloseModal();
    }
    if (event.key === "shouldLogout" && event.newValue === "true") {
      // if user was logged out by modal on another tab we need to log out on all tabs
      handleLogout();
    }
  };

  useEffect(() => {
    bc.onmessage = (event) => {
      const [eventType, id] = event.data.split("-") || [];
      if (eventType === "update" && id !== tabId) {
        resetTimer();
      }
    };

    // if modal is not opened initially we have to start timer
    if (!isOpen) {
      resetTimer();
    }

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    window.addEventListener("storage", storageCallBack);

    // clear the event listeners when the component unmounts
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      window.removeEventListener("storage", storageCallBack);

      if (timerRef?.current) {
        clearTimeout(timerRef.current.id);
      }

      if (bc) {
        bc.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem("timeoutModalIsOpen", "false");
    setIsOpen(false);
    setTotalIdle(0);
    resetTimer();
  };

  const handleCancelClick = () => {
    localStorage.setItem("timeoutModalIsOpen", "false");
    localStorage.setItem("shouldLogout", "true");
    handleLogout();
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    let heartBeat: NodeJS.Timeout | null = null;

    heartBeat = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    // only when modal is opened we have to start logout timer
    if (isOpen) {
      interval = setInterval(() => {
        setTotalIdle((prevTotalIdle) => prevTotalIdle + 10000); // increasing idle on an interval step value
      }, 10000);
    } else if (interval) {
      clearInterval(interval);
      setTotalIdle(0);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (heartBeat) {
        clearInterval(heartBeat);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const timerStart = timerRef?.current?.start || 0;
    const timePassed = currentTime - timerStart;
    const totalLogoutTreshold = totalIdleThreshold + idleTimeout;
    // we need second condition to handle logout when user's machine was in the sleep mode
    if (totalIdle >= totalIdleThreshold || timePassed >= totalLogoutTreshold) {
      localStorage.setItem("timeoutModalIsOpen", "false");
      localStorage.setItem("shouldLogout", "true");
      setIsOpen(false);
      setTotalIdle(0);
      handleLogout();
      localStorage.setItem("shouldLogout", "false");
    }
  }, [totalIdle, timerRef, currentTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleCloseModal,
    handleCancelClick,
    isOpen,
  };
};
