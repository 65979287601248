import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import isEmpty from "lodash/isEmpty";
import { useAppContext } from "../../../../../context/AppContext";
import { useAuthContext } from "../../../../../context/AuthContext";
import {
  FileFormat,
  GetTableDataInput,
  AssetTableData,
  TableDomain,
  TableViewType,
  TableFilterLinkOperator,
  TableValueDataType,
  TableFilterOperator,
} from "../../../../../graphql/operations";
import Spinner from "../../../../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../../../shared/components/Table/BackEndProcessingTable";
import TableActionsMenu from "../../../../../shared/components/TableActionsMenu/TableActionsMenu";
import { TypeTransfer } from "../../../../../shared/components/TableActionsMenu/helpers";
import { usePreferredTimezone } from "../../../../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { useUserData } from "../../../../../shared/hooks/useUserData";
import { useFeatureFlag } from "../../../../../utils";
import { FeatureFlags } from "../../../../../utils/featureFlagsConstants";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { columnVisibilityModel, getColumns } from "./columns";
import { useAssetsTableData } from "./useAssetsTableData";
import { mapAssetFilters, mergeFilters } from "./utils";

export const AssetsTable: FC = memo(() => {
  const {
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const userData = useUserData();
  const {
    setAssetsImeisHandler,
    currentFilter,
    assetsSearchInput,
    assetsPagination,
    onPageChange,
    filters,
  } = useAssetsDataContext();
  const { userRolePermissions } = useAuthContext();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const timezone = usePreferredTimezone();
  const columns = useMemo(() => getColumns(timezone), [timezone]);

  const [isExporting, setIsExporting] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);

  const [selectedRow, setSelectedRows] = useState<any[]>([]);

  const defaultField = fetchAssetsFromOpenSearchFeatureFlag
    ? "customer_orgs_id"
    : "orgName";
  const defaultOperator = fetchAssetsFromOpenSearchFeatureFlag
    ? TableFilterOperator.Equals
    : TableFilterOperator.Contains;

  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    pagination: {
      skip: assetsPagination ?? 0,
      limit: appConfig.table.defaultRowsPerPage,
    },
    tableFilters: {
      filters: [
        {
          dataType: TableValueDataType.String,
          field: defaultField,
          operator: defaultOperator,
          value: `{"value":"${selectedOrganization?.label}"}`,
        },
      ],
      linkOperator: TableFilterLinkOperator.And,
    },
  });

  const {
    isLoading,
    isSuccess,
    data: tableData,
    imeis: imeisData,
    pagination,
  } = useAssetsTableData(queryInput);

  useEffect(() => {
    setAssetsImeisHandler(imeisData ?? []);
  }, [imeisData, setAssetsImeisHandler]);

  // Keep in sync with pagination on the Asset Map and Asset Gallery
  useEffect(() => {
    if (queryInput.pagination?.skip) {
      const newPage =
        queryInput.pagination?.skip / appConfig.table.defaultRowsPerPage + 1;

      onPageChange(newPage);
    }
  }, [
    queryInput.pagination?.skip,
    assetsPagination,
    onPageChange,
    appConfig.table.defaultRowsPerPage,
  ]);

  useEffect(() => {
    setQueryInput((prev) => {
      const filters = mapAssetFilters(
        currentFilter,
        userData,
        fetchAssetsFromOpenSearchFeatureFlag
          ? selectedOrganization?.value
          : selectedOrganization?.label,
        fetchAssetsFromOpenSearchFeatureFlag
      );
      const tableFilters = mergeFilters(
        prev.tableFilters,
        filters,
        undefined,
        fetchAssetsFromOpenSearchFeatureFlag
      );

      return {
        ...prev,
        subStringSearchText: assetsSearchInput,
        tableFilters,
        jsonLogic: !isEmpty(currentFilter.complexFiltersMongodb)
          ? JSON.stringify(currentFilter.complexFiltersMongodb)
          : undefined,
        pagination: {
          skip: assetsPagination ?? 0,
          limit: appConfig.table.defaultRowsPerPage,
        },
      };
    });
  }, [
    selectedOrganization,
    currentFilter,
    userData,
    assetsSearchInput,
    appConfig.table.defaultRowsPerPage,
    assetsPagination,
    fetchAssetsFromOpenSearchFeatureFlag,
  ]);

  /**
   * Force page 1 on filter change due to the usage of query.skip
   * for pagination passed to the table
   */
  useEffect(() => {
    // Triggers on filters changed & forces page 1 if starting assets is already 0
    if (!assetsPagination) onPageChange(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({
        ...prev,
        ...data,
        subStringSearchText: assetsSearchInput,
        tableFilters: mergeFilters(
          prev.tableFilters,
          data.tableFilters,
          selectedOrganization?.label,
          fetchAssetsFromOpenSearchFeatureFlag
        ),
      }));
    },
    [
      assetsSearchInput,
      selectedOrganization?.label,
      fetchAssetsFromOpenSearchFeatureFlag,
    ]
  );

  const navigateToAssetPage = useCallback(
    (data: AssetTableData) => {
      navigate(`${data.id}`);
    },
    [navigate]
  );

  useTableDataExporter<AssetTableData>({
    queryInput,
    apiRef,
    columns,
    domain: TableDomain.Assets,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: pagination.total,
  });

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  const canCheckTheRows = userRolePermissions?.asset?.check || false;

  return (
    <Box className="h-full flex flex-1 p-5 md:px-10">
      <BackEndProcessingTable
        apiRef={apiRef}
        queryInput={queryInput}
        domain={TableDomain.Assets}
        isSendingEmail={isSendingEmail}
        setSendingEmail={setIsSendingEmail}
        tableType={TableViewType.Assets}
        tableName={"assets-list"}
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        data={{
          rows: tableData ?? [],
          pagination: pagination,
        }}
        updateQueryInput={updateQueryInput}
        sorting={queryInput.sorting ?? undefined}
        onExport={handleExport}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onRowClick={navigateToAssetPage}
        totalCount={pagination.total}
        updateSelectedRowsHandler={setSelectedRows}
        checkboxSelection={canCheckTheRows}
        actions={
          canCheckTheRows ? (
            <TableActionsMenu
              selectedRow={selectedRow}
              typeTransfer={TypeTransfer.Assets}
              apiRef={apiRef}
              assets={tableData?.map((asset) => ({
                _id: asset.id,
                asset_id: asset.assetIdentifier,
                imei: asset.imei,
                vin: asset.imei,
              }))}
            />
          ) : null
        }
        enableSearch={false}
      />
      <Spinner counter={Number(isExporting) || Number(isSendingEmail)} />
    </Box>
  );
});
