import { FC, useState, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";
import { TextButton } from "../../../../../shared/components/Button";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useGetZonesData } from "../hooks/useGetZonesData";
import { countryToDisplayName, ZoneCountry } from "../interfaces";
import CreateEditZoneDialog from "./CreateEditZoneDialog";
import Zone from "./Zone";

export interface ZonesSectionProps {
  country: ZoneCountry;
}

export const ZonesSection: FC<ZonesSectionProps> = ({ country }) => {
  // Hooks
  const isMobile = useBreakpoint("down", "sm");

  // States
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  // Variables
  const iconStyles = { width: "24px", height: "24px", color: "var(--brand)" };

  const { zones, isLoading } = useGetZonesData();
  const zoneGroupData = useMemo(() => zones?.[country], [zones, country]);

  return (
    <Box
      data-testid="zones-section"
      sx={{ padding: isMobile ? "0" : "1.5rem" }}
    >
      <Divider />
      <Accordion>
        <AccordionSummary
          sx={{
            backgroundColor: "var(--card__bg)",
            padding: isMobile ? "0 1.5rem" : "1rem 1.5rem",
          }}
        >
          {countryToDisplayName[country]}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            paddingX: "0",
          }}
        >
          <Box
            data-testid="zones-section--buttons"
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: "2rem",
            }}
          >
            <TextButton
              text="Add New Zone"
              icon={<AddIcon sx={iconStyles} />}
              iconPosition="left"
              onClick={() => setIsCreateDialogOpen(true)}
              className="!text-brand !text-base !font-bold"
              data-testid="add-new-zone-button"
            />
          </Box>

          <Box
            sx={{
              backgroundColor: "var(--accordion-bg)",
              padding: isMobile ? "24px 16px" : "40px",
              borderRadius: "8px",
            }}
          >
            {isLoading ? (
              <Box
                className="flex h-full w-full items-center justify-center"
                data-testid={`zone-section-loader-${country}`}
              >
                <CircularProgress />
              </Box>
            ) : (
              zoneGroupData.data.map((zone, index) => (
                <Zone
                  key={zone.id}
                  zoneData={zone}
                  showDivider={index !== zoneGroupData.data.length - 1}
                />
              ))
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Create Zone Dialog */}
      <CreateEditZoneDialog
        open={isCreateDialogOpen}
        country={country}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </Box>
  );
};
