import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as LiftgateSensorDark } from "../../../../../../assets/svgs/liftGateSensorDark.svg";
import { ReactComponent as LiftgateSensorLight } from "../../../../../../assets/svgs/liftgateSensorLight.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import { SensorStatusUppercase } from "../../../../../../graphql/operations";
import { themes } from "../../../../../../shared/hooks/theme/utils";
import { useGetSensorStateWidget } from "../../AssetShortTooltip/hooks/useGetSensorStateWidget";
import { getElapsedTimeSinceLabel } from "../../TiresTabPanel/helpers";
import LiftgateBatteryIcon from "./LiftgateBatteryIcon";

type LiftgateBatteryProps = {
  status: string;
  date: string | undefined;
};

const LiftgateBatteryStatus = ({ status, date }: LiftgateBatteryProps) => {
  const {
    state: { theme },
  } = useAppContext();
  const { getColoredStatusIcon } = useGetSensorStateWidget();
  const isLightTheme = theme.theme === themes.light;
  const svgIconSettings = {
    width: "80px",
    height: "80px",
    display: "block",
  };

  return (
    <Box
      sx={{
        width: "full",
        backgroundColor: "var(--card__bg)",
        borderRadius: "0.5rem",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="streched"
        className="p-4 rounded-lg"
        data-testId="liftgate-battery-status-wrapper"
      >
        <Box className="!mb-1 justify w-full ">
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <Box data-testid="cargo-icon">
              {isLightTheme ? (
                <LiftgateSensorDark style={svgIconSettings} />
              ) : (
                <LiftgateSensorLight style={svgIconSettings} />
              )}
            </Box>

            <Box>
              <Typography
                variant="caption"
                className="!pl-2 !text-base inline-block !font-semibold !text-primary-blue"
              >
                Liftgate Battery Status
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box
          className="flex justify-center w-full my-8"
          data-testId="liftgate-battery-status-icon"
        >
          <Grid container xs={10} md={8}>
            <LiftgateBatteryIcon
              width={250}
              height={255}
              statusIcon={getColoredStatusIcon(status as SensorStatusUppercase)}
            />
          </Grid>
        </Box>
        {date && (
          <Box className="flex justify-end w-full">
            <Chip
              component="span"
              label={getElapsedTimeSinceLabel(new Date(date))}
              data-testId="liftgate-battery-status-date-chip"
              sx={{
                backgroundColor: "var(--primary-chip-bg)",
                color: "var(--primary-chip-text)",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "12px",
                borderRadius: "4px",
                height: "18px",
                "& .MuiChip-label ": {
                  padding: "0px 8px",
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default LiftgateBatteryStatus;
