import { getGridSingleSelectOperators } from "@mui/x-data-grid-premium";
import {
  HistoryEventsIDToName,
  LocationUpdateEventIdsToName,
} from "../../../../../constants/map";
import { SingleSelectTableFilterValue } from "../../../../../shared/components/Table";

export const getIsSingleSelectFilterOperator = () => {
  return getGridSingleSelectOperators().filter(
    (f) => f.value === SingleSelectTableFilterValue.Is
  );
};

export const getGpsLockFilterOperatorOptions = () => {
  return [
    {
      label: "Locked",
      value: "true",
    },
    {
      label: "Unlocked",
      value: "false",
    },
  ];
};

export const getEventNameFilterOperatorOptions = () => {
  return [...HistoryEventsIDToName.entries()].map(([eventId, eventLabel]) => {
    return {
      label: LocationUpdateEventIdsToName.get(eventId) ?? eventLabel,
      value: eventId,
    };
  });
};
