import { Maybe } from "yup/lib/types";
import { TableColumnFormat as ReportColumnFormat } from "../../../../../graphql/operations";
import { columnTimezoneDateTimeFormatParams } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  dateAndTime: false,
  totalMileage: false,
  speedMph: false,
  speedKmph: false,
  tripState: false,
  assetType: false,
  assetId: false,
  imei: false,
  orgName: false,
  lastUpdated: false,
  healthStatus: false,
  engineHours: false,
};

export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  const columns = [
    createColumn(
      "dateAndTime",
      "Date and time",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
      }
    ),
    createColumn("totalMileage", "Total Mileage", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("engineHours", "Engine Hours", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("speedMph", "Speed (mph)", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("speedKmph", "Speed (kmph)", ReportColumnFormat.String, {
      type: "number",
    }),
    createColumn("tripState", "Trip State", ReportColumnFormat.String),
    createColumn("assetType", "Asset Type", ReportColumnFormat.String),
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("imei", "Device ID", ReportColumnFormat.String),
    createColumn("orgName", "Organization", ReportColumnFormat.String, {
      filterable: false,
    }),
    createColumn(
      "lastUpdated",
      "Last Updated",
      ReportColumnFormat.IsoDateTime,
      {
        ...columnTimezoneDateTimeFormatParams(timezone as string),
      }
    ),
    createColumn("healthStatus", "Health Status", ReportColumnFormat.String),
  ];
  return columns;
};

export const SEARCH_KEYS = [
  "dateAndTime",
  "totalMileage",
  "speedMph",
  "speedKmph",
  "tripState",
  "assetType",
  "assetId",
  "imei",
  "orgName",
  "lastUpdated",
  "healthStatus",
];
