import {
  GeofenceData,
  GeofenceTypeData,
  OrgData,
  useFindGeofencesByOrgIdsQuery,
} from "../../../../../graphql/operations";
import { detailItem } from "../../Shared/DashboardWidgets/DetailListWidget";

export function getGeofenceInfo(
  geofence: GeofenceData | undefined | null,
  org: OrgData[],
  geofenceTypes?: GeofenceTypeData[],
  parentIds?: GeofenceData[]
) {
  const company = org.find((org) => org._id === geofence?.orgId!);
  const parents = parentIds?.find(
    (parent) => parent._id === geofence?.configuration?.parentId!
  );

  const tags = geofence?.geofence?.tags?.join(",");
  const type = geofenceTypes?.find(
    (type) => type._id === geofence?.configuration?.typeId
  );
  const subType = geofenceTypes?.find(
    (type) => type._id === geofence?.configuration?.subTypeId
  );
  const detailList: Array<detailItem<string | number>> = [
    {
      label: "Name",
      detailItemvalue: geofence?.geofence.name ?? "",
      truncateText: true,
    },
    {
      label: "Organization",
      detailItemvalue: company?.name ?? "",
      truncateText: true,
    },
    {
      label: "Type",
      detailItemvalue: type?.name ?? "",
      truncateText: true,
    },
    {
      label: "Subtype",
      detailItemvalue: subType?.name ?? "",
      truncateText: true,
    },
    {
      label: "Parent",
      detailItemvalue: parents?.geofence.name ?? "",
      truncateText: true,
    },
    {
      label: "Address",
      detailItemvalue: geofence?.geofence.fullAddressFormatted ?? "",
      truncateText: true,
    },
    {
      label: "Location Code",
      detailItemvalue: geofence?.geofence?.code ?? "",
      truncateText: true,
    },
    {
      label: "Location Description",
      detailItemvalue: geofence?.geofence.description ?? "",
      truncateText: true,
    },
    { label: "Tags", detailItemvalue: tags ?? "", truncateText: true },
    {
      label: "Owner",
      detailItemvalue: geofence?.metadata?.owner ?? "",
      truncateText: true,
    },
  ];

  return detailList;
}
