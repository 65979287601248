import React from "react";
import {
  DashboardWidgetLibraryKey,
  GetDashboardWidgetResponse,
} from "../../../../graphql/operations";
import {
  AlertTypesWidget,
  DashboardWidgetAlertTypes,
} from "./widgets/AlertTypesWidget";
import {
  AlertsPerDayWidget,
  DashboardWidgetAlertsPerDay,
} from "./widgets/AlertsPerDay";
import {
  AssetInstallationsPerDayWidget,
  DashboardWidgetAssetInstallationsPerDay,
} from "./widgets/AssetInstallationsPerDay";
import {
  AssetsInDwellWidget,
  DashboardWidgetAssetsInDwell,
} from "./widgets/AssetsInDwellWidget";
import { AssetsInGeofenceWidget } from "./widgets/AssetsInGeofence/AssetsInGeofenceWidget";
import {
  AssetsLastReportedWidget,
  DashboardWidgetLastReported,
} from "./widgets/AssetsLastReportedWidget";
import {
  DashboardWidgetAssetsMileage,
  AssetsMileageWidget,
} from "./widgets/AssetsMileageWidget";
import {
  AssetsPerHealthStatusWidget,
  DashboardWidgetAssetsPerHealthType,
} from "./widgets/AssetsPerHealthStatusWidget";
import {
  AssetsPerProductWidget,
  DashboardWidgetAssetsPerProduct,
} from "./widgets/AssetsPerProductWidget";
import {
  AssetsPerStateWidget,
  DashboardWidgetAssetsPerState,
} from "./widgets/AssetsPerStateWidget";
import {
  AssetsPerSuborgWidget,
  DashboardWidgetAssetsPerSuborg,
} from "./widgets/AssetsPerSuborgWidget";
import {
  AssetsPerTripStatusWidget,
  DashboardWidgetAssetsPerTripStatusType,
} from "./widgets/AssetsPerTripStatusWidget";
import {
  AssetsSensorsWidget,
  DashboardWidgetSensorsHealthType,
} from "./widgets/AssetsSensorsWidget";
import {
  AssetsTypeWidget,
  DashboardWidgetAssetsType,
} from "./widgets/AssetsTypeWidget";
import {
  AssetsPerBatteryStatus,
  DevicesByBatteryLevel,
} from "./widgets/DevicesByBatteryLevel";
import {
  DevicesPerOrganisationsWidget,
  DashboardWidgetDevicesPerOrgType,
} from "./widgets/DevicesPerOrganisationsWidget";
import {
  DashboardWidgetDevicesPerSubOrgType,
  DevicesPerSubOrganisationsWidget,
} from "./widgets/DevicesPerSubOrganisationsWidget";
import {
  DashboardWidgetDevicesPerTagWidget,
  DevicesPerTagWidget,
} from "./widgets/DevicesPerTagWidget";
import {
  DashboardWidgetGeofenceTypes,
  GeofenceTypesWidget,
} from "./widgets/GeofenceTypesWidget";
import {
  DashboardWidgetGeofences,
  GeofencesWidget,
} from "./widgets/GeofencesWidget";
import {
  DashboardWidgetLoadedAssetsWidget,
  LoadedAssetsWidget,
} from "./widgets/LoadedAssetsWidget";
import {
  DashboardWidgetMediaPerPeriod,
  MediaPerPeriodWidget,
} from "./widgets/MediaPerPeriodWidget";
import {
  DashboardWidgetMediaPerSuborg,
  MediaPerSuborgWidget,
} from "./widgets/MediaPerSuborgWidget";
import {
  DashboardWidgetNewUsersPerPeriod,
  NewUsersPerPeriodWidget,
} from "./widgets/NewUsersPerPeriodWidget";
import {
  DashboardWidgetNotReportingDevicesTypes,
  NotReportingDevicesWidget,
} from "./widgets/NotReportingDevicesWidget";
import {
  DashboardWidgetOrganizationsPerType,
  OrganizationsPerTypeWidget,
} from "./widgets/OrganizationsPerTypeWidget";
import {
  ReportTypesWidget,
  DashboardWidgetReportTypes,
} from "./widgets/ReportTypesWidget";
import {
  DashboardWidgetReportingDevicesPerPeriodTypes,
  ReportingDevicesPerPeriodWidget,
} from "./widgets/ReportingDevicesPerPeriodWidget";
import {
  DashboardWidgetReportingDevicesTypes,
  ReportingDevicesWidget,
} from "./widgets/ReportingDevicesWidget";
import {
  DashboardWidgetReportsPerSubOrg,
  ReportsPerSuborgWidget,
} from "./widgets/ReportsPerSubOrg";
import {
  DashboardWidgetUsersPerRole,
  UsersPerRoleWidget,
} from "./widgets/UsersPerRoleWidget";
import { UsersPerSubOrganizationWidget } from "./widgets/UsersPerSubOrganizationWidget/UsersPerSubOrganizationWidget";
import {
  DashboardWidgetDevicesPerGroup,
  DevicesPerGroupWidget,
} from "./widgets/devices/DevicesPerGroupWidget/DevicesPerGroupWidget";
import {
  AlertsPerSuborgWidget,
  DashboardWidgetAlertsPerSuborg,
} from "./widgets/organizations/AlertsPerSuborg/AlertsPerSuborg";
import {
  CargoUltrasonicSensorStatusWidget,
  DashboardWidgetCargoUltrasonicSensorStatus,
} from "./widgets/sensors/CargoUltrasonicSensorStatus/CargoUltrasonicSensorStatus";
import {
  CargoVisionSensorStatusWidget,
  DashboardWidgetCargoVisionSensorStatus,
} from "./widgets/sensors/CargoVisionSensorStatus/CargoVisionSensorStatus";
import {
  DoorSensorStatusWidget,
  DashboardWidgetDoorSensorStatus,
} from "./widgets/sensors/DoorSensorStatus/DoorSensorStatus";

export interface DashboardWidgetMapperProps {
  widget: GetDashboardWidgetResponse;
}

export const DashboardWidgetMapper: React.FC<DashboardWidgetMapperProps> = ({
  widget,
}) => {
  switch (widget.key) {
    case DashboardWidgetLibraryKey.RetortTypes:
      return (
        <ReportTypesWidget
          widget={widget as unknown as DashboardWidgetReportTypes}
        />
      );
    case DashboardWidgetLibraryKey.AlertTypes:
      return (
        <AlertTypesWidget
          widget={widget as unknown as DashboardWidgetAlertTypes}
        />
      );
    case DashboardWidgetLibraryKey.AssetsType:
    case DashboardWidgetLibraryKey.AssetsTypeInGeofence:
      return <AssetsTypeWidget widget={widget as DashboardWidgetAssetsType} />;
    case DashboardWidgetLibraryKey.AssetsPerProduct:
      return (
        <AssetsPerProductWidget
          widget={widget as DashboardWidgetAssetsPerProduct}
        />
      );
    case DashboardWidgetLibraryKey.DevicesPerOrg:
      return (
        <DevicesPerOrganisationsWidget
          widget={widget as unknown as DashboardWidgetDevicesPerOrgType}
        />
      );
    case DashboardWidgetLibraryKey.ReportingDevices:
      return (
        <ReportingDevicesWidget
          widget={widget as unknown as DashboardWidgetReportingDevicesTypes}
        />
      );
    case DashboardWidgetLibraryKey.DevicesPerSuborg:
      return (
        <DevicesPerSubOrganisationsWidget
          widget={widget as unknown as DashboardWidgetDevicesPerSubOrgType}
        />
      );
    case DashboardWidgetLibraryKey.NotReportingDevices:
      return (
        <NotReportingDevicesWidget
          widget={widget as DashboardWidgetNotReportingDevicesTypes}
        />
      );
    case DashboardWidgetLibraryKey.ReportingDevicesPerPeriod:
      return (
        <ReportingDevicesPerPeriodWidget
          widget={widget as DashboardWidgetReportingDevicesPerPeriodTypes}
        />
      );
    case DashboardWidgetLibraryKey.LoadedAssets:
      return (
        <LoadedAssetsWidget
          widget={widget as unknown as DashboardWidgetLoadedAssetsWidget}
        />
      );
    case DashboardWidgetLibraryKey.DevicesPerTag:
      return (
        <DevicesPerTagWidget
          widget={widget as unknown as DashboardWidgetDevicesPerTagWidget}
        />
      );
    case DashboardWidgetLibraryKey.UsersPerSuborg:
      return (
        <UsersPerSubOrganizationWidget
          widget={widget as DashboardWidgetAssetsPerProduct}
        />
      );
    case DashboardWidgetLibraryKey.GeofenceTypes:
      return (
        <GeofenceTypesWidget
          widget={widget as unknown as DashboardWidgetGeofenceTypes}
        />
      );
    case DashboardWidgetLibraryKey.DevicesPerGroup:
      return (
        <DevicesPerGroupWidget
          widget={widget as DashboardWidgetDevicesPerGroup}
        />
      );

    case DashboardWidgetLibraryKey.AssetsInDwell:
      return (
        <AssetsInDwellWidget widget={widget as DashboardWidgetAssetsInDwell} />
      );
    case DashboardWidgetLibraryKey.AssetsHealth:
      return (
        <AssetsPerHealthStatusWidget
          widget={widget as DashboardWidgetAssetsPerHealthType}
        />
      );
    case DashboardWidgetLibraryKey.AssetsMileage:
      return (
        <AssetsMileageWidget widget={widget as DashboardWidgetAssetsMileage} />
      );
    case DashboardWidgetLibraryKey.NumberOfInstallations:
      return (
        <AssetInstallationsPerDayWidget
          widget={widget as unknown as DashboardWidgetAssetInstallationsPerDay}
        />
      );
    case DashboardWidgetLibraryKey.AssetsTrip:
      return (
        <AssetsPerTripStatusWidget
          widget={widget as unknown as DashboardWidgetAssetsPerTripStatusType}
        />
      );
    case DashboardWidgetLibraryKey.AssetsPerState:
      return (
        <AssetsPerStateWidget
          widget={widget as DashboardWidgetAssetsPerState}
        />
      );
    case DashboardWidgetLibraryKey.AssetsInGeofence:
      return <AssetsInGeofenceWidget widget={widget} />;
    case DashboardWidgetLibraryKey.CargoUltrasonicSensorStatus:
      return (
        <CargoUltrasonicSensorStatusWidget
          widget={
            widget as unknown as DashboardWidgetCargoUltrasonicSensorStatus
          }
        />
      );
    case DashboardWidgetLibraryKey.CargoVisionSensorStatus:
      return (
        <CargoVisionSensorStatusWidget
          widget={widget as unknown as DashboardWidgetCargoVisionSensorStatus}
        />
      );
    case DashboardWidgetLibraryKey.DoorSensorStatus:
      return (
        <DoorSensorStatusWidget
          widget={widget as unknown as DashboardWidgetDoorSensorStatus}
        />
      );
    case DashboardWidgetLibraryKey.UsersPerRole:
      return (
        <UsersPerRoleWidget widget={widget as DashboardWidgetUsersPerRole} />
      );
    case DashboardWidgetLibraryKey.AlertsPerSuborg:
      return (
        <AlertsPerSuborgWidget
          widget={widget as DashboardWidgetAlertsPerSuborg}
        />
      );
    case DashboardWidgetLibraryKey.AlertsPerPeriod:
      return (
        <AlertsPerDayWidget
          widget={widget as unknown as DashboardWidgetAlertsPerDay}
        />
      );
    case DashboardWidgetLibraryKey.LastReported:
      return (
        <AssetsLastReportedWidget
          widget={widget as unknown as DashboardWidgetLastReported}
        />
      );
    case DashboardWidgetLibraryKey.BatteryLevel:
      return (
        <DevicesByBatteryLevel widget={widget as AssetsPerBatteryStatus} />
      );
    case DashboardWidgetLibraryKey.SensorTemperature:
    case DashboardWidgetLibraryKey.SensorVoltage:
    case DashboardWidgetLibraryKey.SensorTpmsBeta:
    case DashboardWidgetLibraryKey.SensorTpmsImbalance:
    case DashboardWidgetLibraryKey.SensorPsiWheelEnd:
    case DashboardWidgetLibraryKey.SensorAirSupplyPressure:
    case DashboardWidgetLibraryKey.SensorAirTankPressure:
    case DashboardWidgetLibraryKey.SensorAtis:
    case DashboardWidgetLibraryKey.SensorGammaLiteSentry:
    case DashboardWidgetLibraryKey.SensorAirBag:
      return (
        <AssetsSensorsWidget
          widget={widget as unknown as DashboardWidgetSensorsHealthType}
        />
      );
    case DashboardWidgetLibraryKey.Geofences:
      return (
        <GeofencesWidget
          widget={widget as unknown as DashboardWidgetGeofences}
        />
      );
    case DashboardWidgetLibraryKey.AssetsPerSuborg:
      return (
        <AssetsPerSuborgWidget
          widget={widget as unknown as DashboardWidgetAssetsPerSuborg}
        />
      );
    case DashboardWidgetLibraryKey.ReportsPerSuborg:
      return (
        <ReportsPerSuborgWidget
          widget={widget as unknown as DashboardWidgetReportsPerSubOrg}
        />
      );
    case DashboardWidgetLibraryKey.NewUsersPerPeriod:
      return (
        <NewUsersPerPeriodWidget
          widget={widget as unknown as DashboardWidgetNewUsersPerPeriod}
        />
      );
    case DashboardWidgetLibraryKey.MediaPerPeriod:
      return (
        <MediaPerPeriodWidget
          widget={widget as unknown as DashboardWidgetMediaPerPeriod}
        />
      );
    case DashboardWidgetLibraryKey.OrganizationsPerType:
      return (
        <OrganizationsPerTypeWidget
          widget={widget as DashboardWidgetOrganizationsPerType}
        />
      );
    case DashboardWidgetLibraryKey.MediaPerSuborg:
      return (
        <MediaPerSuborgWidget
          widget={widget as DashboardWidgetMediaPerSuborg}
        />
      );
    default:
      return null;
  }
};
