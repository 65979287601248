import { FC, memo } from "react";
import { useCurrentOrg } from "../../hooks/useCurrentOrg";
import {
  SubHeaderAction,
  SubHeaderActionProps,
} from "./components/SubHeaderAction/SubHeaderAction";

export interface SubHeaderProps {
  title: string;
  actions: SubHeaderActionProps[];
  showDropdownIcon?: boolean;
  hideOrgName?: boolean;
  hideHeaderBorder?: boolean;
  removeHorizontalPadding?: boolean;
  hideTitle?: boolean;
}

const SubHeader: FC<SubHeaderProps> = ({
  title,
  actions,
  hideOrgName,
  showDropdownIcon,
  hideHeaderBorder,
  removeHorizontalPadding,
  hideTitle,
}) => {
  let classList =
    " border-solid border-typography p-3 pt-6 md:flex md:justify-between  md:border-solid md:border-typography md:py-3 ";
  const org = useCurrentOrg();
  const orgName = org?.name ?? "";
  if (!hideHeaderBorder) classList += " border-b md:border-b-[0.5px]";
  if (!removeHorizontalPadding) classList += " md:px-8 lg:px-16";
  return (
    <div className={classList} data-testid="sub-header-wrapper">
      <div className="mb-3 md:mb-0 md:pt-2 text-center md:text-left">
        {!hideTitle && (
          <h1
            className="text-2xl font-semibold text-header-text md:mb-1 lg:ml-2"
            data-testid={`management-${title
              .toLowerCase()
              .replaceAll(" ", "-")}`}
          >
            {title}
          </h1>
        )}
        {!hideOrgName && orgName && (
          <h3
            className="text-md font-medium text-sub-header-text lg:ml-2"
            data-testid={`company-${orgName
              .toLowerCase()
              .replaceAll(" ", "-")}`}
          >
            {orgName}
          </h3>
        )}
      </div>
      <div className="flex items-center justify-center gap-x-4">
        {actions.map(
          ({
            title,
            handler,
            icon,
            primary,
            accessScope,
            disabled,
            type,
            dropdownActions,
          }) => (
            <SubHeaderAction
              key={`sub-header-action-${title}`}
              type={type}
              title={title}
              handler={handler}
              icon={icon}
              showDropdownIcon={showDropdownIcon}
              primary={primary}
              disabled={disabled}
              accessScope={accessScope}
              dropdownActions={dropdownActions}
            />
          )
        )}
      </div>
    </div>
  );
};

export default memo(SubHeader);
