import { TableColumnFormat as ReportColumnFormat } from "../../../../../graphql/operations";
import {
  defaultColumnVisibilityModel as defaultAssetColumnVisibilityModel,
  getColumns as getCommonAssetColumns,
  SEARCH_KEYS as COMMON_ASSET_SEARCH_KEYS,
} from "../../../AssetReport/components/AssetInventoryTable/columns";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  "geofence.name": false,
  ...defaultAssetColumnVisibilityModel,
};

export const getColumns = (timezone: string): ReportGridColDef[] => [
  {
    field: "geofence.name",
    headerName: "Geofence Name",
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    valueGetter: (params) => {
      if ("geofence" in params.row && params.row.geofence) {
        return params.row.geofence.map((item: any) => item.name).join(", ");
      }
    },
  },
  ...getCommonAssetColumns(timezone),
];

export const SEARCH_KEYS = ["geofence.name", ...COMMON_ASSET_SEARCH_KEYS];
