import { Position } from "geojson";
import { SORT_OPTIONS } from "../../../constants/map";
import {
  AssetSort,
  AssetSortOrder,
  DistanceUnit,
  Maybe,
  TableFilterLinkOperator,
  TableFilterOperator,
  TableFiltersInput,
  TableValueDataType,
} from "../../../graphql/operations";
import { convertKmToMiles } from "../../../utils/convertUnits";

export const getFormattedOdometerValue = (
  value: number | null | undefined,
  unit: Maybe<DistanceUnit> | undefined
) => {
  if (!value) return "";
  let result = value; // default to km
  const formattedUnit = unit ? ` ${unit}` : "";
  if (unit === "Miles") {
    result = convertKmToMiles(value);
  }
  return `${result.toLocaleString("en-US")}${formattedUnit}`;
};

export const defaultSort: AssetSort = {
  field: SORT_OPTIONS[0].key,
  direction: SORT_OPTIONS[0].order as AssetSortOrder,
};

// for tables and assets list on left side on the map
export const assetsListInputParams = {
  limit: 100,
  skip: 0,
  gridCells: 10000,
  cluster: false,
  sort: defaultSort as AssetSort | null,
};

export const checkIsFilteredByInstalled = (data: any) => {
  const hasImeiNotEmptyFilter = data?.filterList?.some(
    (filterGroup: {
      filters: { columnField: string; operatorValue: string }[];
    }) =>
      filterGroup?.filters.some(
        (filter: { columnField: string; operatorValue: string }) =>
          filter.columnField === "imei" && filter.operatorValue === "isNotEmpty"
      )
  );
  return hasImeiNotEmptyFilter ?? false;
};

export const defaultAssetsFilter = {
  filterList: [
    {
      filters: [
        {
          columnField: "imei",
          operatorValue: "isNotEmpty",
          value: "[]",
        },
      ],
      linkOperator: "and",
    },
  ],
};

export const defaultTableAssetsFilter: { filterList: TableFiltersInput } = {
  filterList: {
    filters: [
      {
        dataType: TableValueDataType.String,
        field: "imei",
        operator: TableFilterOperator.IsNotEmpty,
      },
    ],
    linkOperator: TableFilterLinkOperator.And,
  },
};

export const assetsTabsList = [
  { value: 0, label: "Summary" },
  { value: 1, label: "Battery" },
  { value: 2, label: "Cargo", showSectionsMenu: true },
  { value: 3, label: "Tires" },
  { value: 4, label: "Media" },
  { value: 5, label: "Brakes" },
  { value: 6, label: "Temperature" },
  { value: 7, label: "Lights" },
  { value: 8, label: "Liftgate" },
  { value: 9, label: "Other Sensors", showSectionsMenu: true },
  { value: 10, label: "Event History" },
  { value: 11, label: "Dwell" },
  { value: 12, label: "Breadcrumbs" },
  { value: 13, label: "Alerts" },
  { value: 14, label: "Activity Log" },
  { value: 15, label: "Settings" },
];

// Try to fit as much of the world as possible within one polygon
export const DEFAULT_VIEWPORT_BBOX_COORDINATES = [
  [-180, 90], // Top-left corner (northwest)
  [180, 90], // Top-right corner (northeast)
  [180, -90], // Bottom-right corner (southeast)
  [-180, -90], // Bottom-left corner (southwest)
  [-180, 90], // Closing the loop back to the top-left corner
];

export const PREDEFINED_MAP_VIEWPORT = [
  [-133.08899687500002, -23.32209149317482],
  [71.34459687499998, -23.32209149317482],
  [71.34459687499998, 72.73799930805474],
  [-133.08899687500002, 72.73799930805474],
  [-133.08899687500002, -23.32209149317482],
];

/*
  Adjusts a viewport that crosses the 180° meridian (antimeridian) to ensure correct API queries.
  When a map's viewport is centered around the antimeridian, the calculated longitude values
  can be incorrect due to the wraparound effect. This function modifies the viewport to represent
  it as a single rectangle with correct longitude values, preventing erroneous queries to APIs
  that expect a single continuous viewport.
*/
export const splitViewportAtMeridian = (viewport: number[][]): number[][] => {
  // Destructure the viewport array to extract the coordinates of the four corners
  // and ignore the fifth element (if present) using an underscore.
  const [
    [topLeftX, topLeftY],
    [topRightX, topRightY],
    [bottomRightX, bottomRightY],
    [bottomLeftX, bottomLeftY],
    _,
  ] = viewport;
  // Check if the viewport crosses the 180° meridian (antimeridian).
  // This occurs when the top-right longitude is greater than the top-left longitude.
  if (topRightX > topLeftX) {
    const westernRectangle = [
      [topRightX, topRightY],
      [bottomRightX, bottomRightY],
      [180, bottomRightY],
      [180, topRightY],
      [topRightX, topRightY],
    ];
    const easternRectangle = [
      [topLeftX, topLeftY],
      [-180, topLeftY],
      [-180, bottomLeftY],
      [bottomLeftX, bottomLeftY],
      [topLeftX, topLeftY],
    ];
    return [...westernRectangle, ...easternRectangle];
  } else {
    return [...viewport];
  }
};

// Function to check if a point is inside a polygon using the ray casting algorithm
const isPointInsidePolygon = (
  point: Position,
  polygon: Position[]
): boolean => {
  const x = point[0];
  const y = point[1];
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];
    // Check if the point is intersecting with the polygon edge
    // prettier-ignore
    const intersect = ((yi > y) !== (yj > y)) &&
      (x < ((xj - xi) * (y - yi)) / (yj - yi) + xi);
    // Toggle inside if there's an intersection
    if (intersect) inside = !inside;
  }
  // Return true if the number of intersections is odd (point is inside), false otherwise
  return inside;
};

// Function to check if a child geofence is completely inside a parent geofence
export const isGeofenceInsideParent = (
  childGeofence: Position[][],
  parentGeofence: Position[][]
): boolean => {
  // Iterate through each point in the child geofence
  for (const point of childGeofence[0]) {
    // Check if the point is inside the parent geofence
    if (!isPointInsidePolygon(point, parentGeofence[0])) {
      // If any point is outside, return false
      return false;
    }
  }
  // If all points are inside, return true
  return true;
};
