import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import {
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridSortDirection } from "@mui/x-data-grid/models/gridSortModel";
import { useQueryClient } from "@tanstack/react-query";
import { cloneDeep, isEqual, set, isUndefined } from "lodash";
import { PAGE_SNACKBAR } from "../../../constants";
import { useAppContext } from "../../../context/AppContext";
import {
  DwellHierarchyTableData,
  FileFormat,
  FilterListInput,
  FiltersInput,
  GetTableDataInput,
  OrderDirection,
  Report,
  ReportAlertHistoryTableData,
  ReportOrder,
  ReportSchedule,
  ReportScheduleFrequency,
  ReportType,
  SortOrder,
  TableDomain,
  TableViewType,
  UpdateReportMutation,
  useCalculateNextRunDateQuery,
} from "../../../graphql/operations";
import { EditReportNameDialog } from "../../../shared/components/EditReportNameDialog";
import { LeaveModal } from "../../../shared/components/LeaveModal";
import { ReportHeader } from "../../../shared/components/ReportHeader";
import { ReportTable } from "../../../shared/components/ReportTable";
import Spinner from "../../../shared/components/Spinner";
import { SubHeader } from "../../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import {
  ServerSideExport,
  ServerSideExportFormat,
  TableGridColDef,
  TableGridData,
} from "../../../shared/components/Table";
import { BackEndProcessingTable } from "../../../shared/components/Table/BackEndProcessingTable";
import { UserAccessScope } from "../../../shared/components/WithPermissions";
import { useAvailableOrgs } from "../../../shared/hooks/useAvailableOrgs";
import { useCurrentOrg } from "../../../shared/hooks/useCurrentOrg";
import { usePreferredTimezone } from "../../../shared/hooks/usePreferredTimezone";
import { usePrompt } from "../../../shared/hooks/usePrompt";
import { useSpinner } from "../../../shared/hooks/useSpinner";
import { useTableDataExporter } from "../../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { useUserData } from "../../../shared/hooks/useUserData";
import {
  buildWildcardStructure,
  formatDateToTimezoneAsUTCISOString,
  getBeginningOfDayISOString,
  getEndingOfDayISOString,
  mapServerErrorCodeToHumanReadableMessage,
} from "../../../utils";
import {
  SchedulingConverter,
  SchedulingRepeat,
} from "../../../utils/scheduling";
import { ScheduleModal } from "../components/ScheduleModal";
import { getOrgsHierarchy } from "../helpers/getOrgsHierarchy";
import {
  convertTimeTo24Hour,
  createReportOnErrorHandler,
  getColumnsModel,
  onSuccessCreateCallbackHandler,
  onSuccessUpdateCallbackHandler,
  prepareReportParamsForSaving,
  ReportGridColDef,
  ReportGridColTableData,
  validateTableFilters,
} from "../helpers/helpers";
import { useReportApi } from "../hooks/useReportApi";
import { useReportHistoryApi } from "../hooks/useReportHistoryApi";
import { ReportWithParameters } from "../interfaces";
import { MaximumExceededModal } from "./components/MaximumExceededModal";
import { ReportSettings } from "./components/ReportSettings";
import { useGetReportParametersForm } from "./hooks/useGetReportParametersForm";
import { useGetReportScheduleForm } from "./hooks/useGetReportScheduleForm";
import { useGetReportQueryForm } from "./hooks/useReportQueryForm";
import { changeColumnsOrder } from "./utils";

export type AssetReportProps = {
  report: ReportWithParameters;
  reportType: string;
  getColumns: (
    timezone: string
  ) =>
    | ReportGridColDef[]
    | TableGridColDef<ReportGridColTableData | ReportAlertHistoryTableData>[];
  defaultColumnVisibilityModel: GridColumnVisibilityModel;
  searchKeys: string[];
  initialSortModel: GridSortModel;
};

export type ReportSettingsFormValues = {
  parameters: Record<string, any>;
  schedule: ReportSchedule;
};

export interface TableViewMenuRef {
  onSubmit: (formData?: FieldValues) => void;
}

export const AssetReport: FC<AssetReportProps> = ({
  report: reportProp,
  reportType,
  getColumns,
  defaultColumnVisibilityModel,
  searchKeys,
  initialSortModel,
}: AssetReportProps) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    state: { appConfig },
    dispatch,
  } = useAppContext();
  const { maxExceededOrgsCount } = appConfig.reports;
  const userInfo = useUserData();
  const currentOrg = useCurrentOrg();
  const availableOrgs = useAvailableOrgs();
  const availableOrgsHierarchy = useMemo(
    () => getOrgsHierarchy(availableOrgs),
    [availableOrgs]
  );
  const [tableDomain, setTableDomain] = useState<TableDomain>(
    TableDomain.Reports
  );
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
  const [isExporting, setIsExporting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] =
    useState<boolean>(false);
  const [report, setReport] = useState(reportProp);
  const [shouldCalculateNextRunDate, setShouldCalculateNextRunDate] =
    useState(false);
  const [shouldCreateReport, setShouldCreateReport] = useState(false);
  const userTimeZone = usePreferredTimezone(true);

  const defaultColumns = getColumns(userTimeZone) as (
    | ReportGridColDef
    | TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>
  )[];

  const reportColumns = report.columns
    .map(({ field }) => defaultColumns.find((col) => col.field === field))
    .filter(
      (
        column
      ): column is
        | ReportGridColDef
        | TableGridColDef<
            DwellHierarchyTableData | ReportAlertHistoryTableData
          > => !!column
    );
  const [columns, setColumns] = useState<
    (
      | ReportGridColDef
      | TableGridColDef<DwellHierarchyTableData | ReportAlertHistoryTableData>
    )[]
  >([
    ...reportColumns,
    // Add rest columns that are not visible.
    ...defaultColumns.filter(
      (col) => !reportColumns.find(({ field }) => field === col.field)
    ),
  ]);

  const [maxExceededModalOpened, setMaxExceededModalOpened] = useState(false);
  const [shouldPrompt, setShouldPrompt] = useState(false);
  const [lastUpdatedField, setLastUpdatedField] = useState("");

  const { isLeaveModalOpen, setIsLeaveModalOpen, setIsConfirmed } =
    usePrompt(shouldPrompt);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const defaultSortingField = useMemo(() => {
    return reportType === ReportType.AlertHistory
      ? { field: "startedAt", order: SortOrder.Desc }
      : { field: "geofenceName", order: SortOrder.Asc };
  }, [reportType]);
  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    sorting: [
      {
        field: reportProp.order[0]?.field ?? defaultSortingField.field,
        order:
          (reportProp.order[0]?.direction.toLowerCase() as SortOrder) ??
          defaultSortingField.order,
      },
    ],
    pagination: {
      skip: 0,
      limit: appConfig.table.defaultRowsPerPage,
    },
  });

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
      const sorting = {
        field: data.sorting?.[0]?.field ?? defaultSortingField.field,
        direction:
          data.sorting?.[0]?.order.toUpperCase() ??
          defaultSortingField.order.toUpperCase(),
      } as unknown as ReportOrder;
      setReport({ ...report, order: [sorting] });
    },
    [setQueryInput, defaultSortingField, report]
  );
  const { form: scheduleForm } = useGetReportScheduleForm({
    report,
    timezone: userTimeZone,
  });
  const { form: parametersForm } = useGetReportParametersForm({
    report,
    timezone: userTimeZone,
    availableOrgsHierarchy,
  });

  const { form: queryForm } = useGetReportQueryForm({
    parameters: {
      query: {
        logic: report.trigger,
      },
    },
  });

  // Watch schedule fields to check if we need to apply validation
  const watchFrequency = useWatch({
    name: "frequency",
    control: scheduleForm.control,
  });
  const watchFormat = useWatch({
    name: "format",
    control: scheduleForm.control,
  });

  useEffect(() => {
    // Update columns when timezone is received.
    setColumns(getColumns(userTimeZone));
  }, [getColumns, userTimeZone]);

  const handleMaxExceededModalAccept = useCallback(() => {
    setMaxExceededModalOpened(false);
    setIsSelectOpen(true);
  }, []);

  const handleMaxExceededModalDecline = useCallback(() => {
    setMaxExceededModalOpened(false);
    setIsSelectOpen(false);
  }, []);

  parametersForm.watch();

  const scheduleDirtyFields = Object.keys(scheduleForm.formState.dirtyFields);
  const parametersDirtyFields = Object.keys(
    parametersForm.formState.dirtyFields
  );

  useEffect(() => {
    // we don't need to compare a whole report object, just columns, order and parameters
    // for schedule we should not rely on nextRunAt field as we are not sending it to backend
    const currentReportParamsOrgIds = reportProp.parameters?.orgIds?.sort();
    const updatedReportParamsOrgIds = report.parameters?.orgIds?.sort();
    const currentReportParams = {
      ...reportProp.parameters,
      orgIds: currentReportParamsOrgIds,
    };
    const updatedReportParams = {
      ...report.parameters,
      orgIds: updatedReportParamsOrgIds,
    };
    const currentReport = {
      name: reportProp.name,
      columns: reportProp.columns,
      order: reportProp.order,
      parameters: currentReportParams,
      schedule: reportProp.schedule,
      trigger: reportProp.trigger || null,
    };
    const updatedReport = {
      name: report.name,
      columns: report.columns,
      order: report.order,
      parameters: updatedReportParams,
      schedule: report.schedule,
      trigger: report.trigger || null,
    };

    const recipientEmails = updatedReport.schedule[0]?.subscribers?.emails;

    // to prevent opening, "leave modal" checking if only user email is in subscribers
    if (
      recipientEmails?.length === 1 &&
      recipientEmails[0] === userInfo?.email
    ) {
      // update a current report to fill it with required by type fields
      // add user email to it to make updatedReport with no changes made by user equal to currentReport
      // because of automatically added user email to subscribers, object updatedReport with no changes is different to currentReport when creating new report
      // which forces to open "leave modal"
      currentReport.schedule = [
        {
          ...currentReport.schedule[0],
          subscribers: {
            roles: currentReport.schedule[0]?.subscribers?.roles ?? [],
            users: currentReport.schedule[0]?.subscribers?.users ?? [],
            emails: updatedReport.schedule[0].subscribers?.emails ?? [],
          },
        },
      ];

      // update updatedReport to fill it with necessary by type fields
      updatedReport.schedule = updatedReport.schedule.map((schedule) => {
        return {
          ...schedule,
          subscribers: {
            roles: schedule.subscribers?.roles ?? [],
            emails: schedule.subscribers?.emails ?? [],
            users: schedule.subscribers?.users ?? [],
          },
        };
      });
    }

    if (!isEqual(currentReport, updatedReport) && !shouldCreateReport) {
      // let's leave this log till reports are in development to easily define issues
      console.debug("Report has changes, which are not saved.", {
        currentReport,
        updatedReport,
      });
      setShouldPrompt(true);
    } else {
      setShouldPrompt(false);
    }
  }, [
    report,
    reportProp,
    shouldCreateReport,
    userTimeZone,
    scheduleDirtyFields,
    parametersDirtyFields,
    userInfo,
  ]);

  const {
    data: nextRunDateData,
    refetch: calculateNextRunDate,
    isSuccess: isNextRunDateCalculated,
    isFetching: isNextRunDateCalculating,
  } = useCalculateNextRunDateQuery(
    { input: { schedule: report.schedule[0], timezone: userTimeZone } },
    { enabled: false }
  );

  useEffect(() => {
    if (isNextRunDateCalculated) {
      setReport((prev) => ({
        ...prev,
        schedule: [
          {
            ...prev.schedule[0],
            nextRunAt: nextRunDateData?.calculateNextRunDate?.nextRunDate,
          },
        ],
      }));
    }
  }, [
    nextRunDateData?.calculateNextRunDate?.nextRunDate,
    isNextRunDateCalculated,
  ]);

  useEffect(() => {
    if (shouldCalculateNextRunDate) {
      calculateNextRunDate();
      setShouldCalculateNextRunDate(false);
    }
  }, [shouldCalculateNextRunDate, calculateNextRunDate]);

  const columnsVisibilityModel = useMemo(() => {
    return report.columns.reduce(
      (acc: GridColumnVisibilityModel, column) => ({
        ...acc,
        [column.field]: true,
      }),
      { ...defaultColumnVisibilityModel }
    );
  }, [report.columns, defaultColumnVisibilityModel]);

  const sortModelState = reportProp.order.length
    ? reportProp.order.map((order) => ({
        field: order.field,
        sort: order.direction?.toLowerCase() as GridSortDirection,
      }))
    : initialSortModel;

  const [sortModel, setSortModel] = useState<GridSortModel>(sortModelState);
  const [filters, setFilters] = useState<FilterListInput | null>(null);
  const [paginationSkip, setPaginationSkip] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const pageSize = 100;

  const {
    data: reportData,
    refetch: refetchReportData,
    isRefetching: isReportDataRefetching,
    isSuccess: isReportDataSuccess,
    isLoading: isReportDataLoading,
    ...rest
  } = useReportHistoryApi(
    {
      orgId: currentOrg?._id,
      reportType,
      reportQueryParameters: {
        ...report.parameters,
        orgIds: report.parameters?.orgIds
          ? buildWildcardStructure(
              report.parameters.orgIds,
              availableOrgsHierarchy
            )
          : undefined,
        sorting: sortModel.map((sortItem) => ({
          field: sortItem.field,
          order: sortItem.sort,
        })),
        pagination: { skip: paginationSkip, limit: pageSize },
        filterList: filters ? [filters] : null,
      },
      trigger: report.trigger || null,
      queryInput,
    },
    lastUpdatedField !== "trigger"
  );
  const [rowCount, setRowCount] = useState<number>(
    reportData.pagination?.total ?? 0
  );

  const updateReportOnSuccess = (data: UpdateReportMutation) => {
    onSuccessUpdateCallback(data.updateReport);
  };

  const onSuccessUpdateCallback = (report: Report) => {
    onSuccessUpdateCallbackHandler({
      report,
      queryClient,
      dispatch,
      setReport,
      availableOrgsHierarchy,
      setLoading,
      refetchReportData,
    });
  };

  const updateReportOnError = (error: unknown) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Error updating report",
        text: mapServerErrorCodeToHumanReadableMessage(
          error instanceof Error ? error.message : "Something Went Wrong."
        ),
        severity: "error",
      },
    });
  };

  const createReportOnSuccess = async ({
    createReport: created,
  }: {
    createReport: Report;
  }) => {
    await onSuccessCreateCallback(created);
  };

  const onSuccessCreateCallback = async (created: Report) => {
    await onSuccessCreateCallbackHandler({
      dispatch,
      created,
      queryClient,
      shouldCreateReport,
      navigate,
      setLoading,
      setShouldCreateReport,
    });
  };

  const createReportOnError = (error: unknown) => {
    createReportOnErrorHandler({ error, setLoading, dispatch });
  };

  const { createReport, updateReport } = useReportApi({
    reportType,
    createReportOnSuccess,
    createReportOnError,
    updateReportOnError,
    updateReportOnSuccess,
  });

  const getFormattedScheduleValues = useCallback(() => {
    const scheduleValues = scheduleForm.getValues();

    const start = scheduleValues.startDate
      ? formatDateToTimezoneAsUTCISOString(
          getBeginningOfDayISOString(scheduleValues.startDate),
          userTimeZone
        )
      : null;
    const end = scheduleValues.endDate
      ? formatDateToTimezoneAsUTCISOString(
          getEndingOfDayISOString(scheduleValues.endDate),
          userTimeZone
        )
      : null;

    const expression =
      scheduleValues.frequency === ReportScheduleFrequency.Custom
        ? SchedulingConverter.fromConverted({
            repeat: scheduleValues.repeat,
            on: scheduleValues.on,
            every: scheduleValues.every,
            dayOfMonth: new Date(scheduleValues.startDate).getDate(),
            month: new Date(scheduleValues.startDate).getMonth(),
            time: `${scheduleValues.time} ${scheduleValues.timeFormat}`,
          }).stringify()
        : null;
    const frequency =
      scheduleValues.repeat === SchedulingRepeat.Never &&
      scheduleValues.frequency === ReportScheduleFrequency.Custom
        ? ReportScheduleFrequency.DoNotRepeat
        : scheduleValues.frequency;
    return {
      start,
      end,
      expression,
      frequency,
    };
  }, [scheduleForm, userTimeZone]);

  const getScheduleIfHasData = useCallback(() => {
    const scheduleValues = scheduleForm.getValues();
    const { expression, frequency } = getFormattedScheduleValues();

    return !!watchFrequency && !!watchFormat
      ? [
          {
            _id: report.schedule[0]?._id,
            time: convertTimeTo24Hour(
              `${scheduleValues.time} ${scheduleValues.timeFormat}`
            ),
            endDate: report.schedule[0]?.endDate,
            startDate: report.schedule[0]?.startDate,
            nextRunAt: report.schedule[0]?.nextRunAt,
            frequency: frequency,
            format: report.schedule[0]?.format,
            expression: expression,
            subscribers: scheduleValues.subscribers,
            force: true,
            suspended: false, // will be changed after adding support for suspending reports
          },
        ]
      : [];
  }, [
    getFormattedScheduleValues,
    report.schedule,
    scheduleForm,
    watchFormat,
    watchFrequency,
  ]);
  const handleScheduleReportSubmit = useCallback(async () => {
    const scheduleValues = scheduleForm.getValues();

    const isScheduleValid = await scheduleForm.trigger();
    if (!isScheduleValid) {
      return;
    }

    const { start, end, expression, frequency } = getFormattedScheduleValues();

    setReport({
      ...report,
      schedule: [
        {
          _id: report.schedule[0]?._id,
          time: convertTimeTo24Hour(
            `${scheduleValues.time} ${scheduleValues.timeFormat}`
          ),
          endDate: end,
          startDate: start,
          nextRunAt: scheduleValues.nextRunAt,
          frequency: frequency,
          format: scheduleValues.format,
          expression: expression,
          subscribers: scheduleValues.subscribers,
          force: true,
          suspended: false, // will be changed after adding support for suspending reports
        },
      ],
    });
    setShouldCalculateNextRunDate(true);
  }, [report, scheduleForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildOrgIdsWildcardStructure = useCallback(
    (orgIds?: string[]) => {
      return orgIds
        ? buildWildcardStructure(orgIds, availableOrgsHierarchy)
        : undefined;
    },
    [availableOrgsHierarchy]
  );
  const validateOrgIdsWildcardStructureLength = useCallback(
    (wildcardStructure?: string[]) => {
      // Check if generated organizations wildcard structure is greater than maxExceededOrgsCount
      if (
        wildcardStructure &&
        wildcardStructure.length > maxExceededOrgsCount
      ) {
        setMaxExceededModalOpened(true);
      }
    },
    [setMaxExceededModalOpened, maxExceededOrgsCount]
  );

  const updateReportName = (name: string) => {
    if (shouldCreateReport) {
      const orgIdsWildcardStructure = buildOrgIdsWildcardStructure(
        report.parameters?.orgIds
      );
      validateOrgIdsWildcardStructureLength(orgIdsWildcardStructure);

      setLoading(true);
      createReport({
        input: {
          name,
          description: report.description,
          orgId: report.orgId,
          type: report.type,
          columns: report.columns,
          order: report.order,
          trigger: report.trigger || null,
          parameters: prepareReportParamsForSaving({
            ...report.parameters,
            orgIds: orgIdsWildcardStructure,
          }),
          schedule: getScheduleIfHasData(),
        },
      });
    } else {
      setReport({ ...report, name });
    }
  };

  const handleChangeColumnsVisibility = useCallback(
    (model: GridColumnVisibilityModel) => {
      const columnsModel = getColumnsModel(getColumns);
      const allColumns = [
        ...report.columns
          .map(({ field }) => columns.find((col) => col.field === field))
          .filter((column): column is ReportGridColDef => !!column),
        ...columns.filter(
          (col) => !report.columns.find(({ field }) => field === col.field)
        ),
      ];

      const { hiddenColumns, visibleColumns } = allColumns.reduce(
        (acc, column) => {
          if (!Object.hasOwn(model, column.field)) {
            acc.visibleColumns.push(column);
          } else if (model[column.field]) {
            acc.visibleColumns.push(column);
          } else {
            acc.hiddenColumns.push(column);
          }
          return acc;
        },
        {
          hiddenColumns: [] as (
            | ReportGridColDef
            | TableGridColDef<
                DwellHierarchyTableData | ReportAlertHistoryTableData
              >
          )[],
          visibleColumns: [] as (
            | ReportGridColDef
            | TableGridColDef<
                DwellHierarchyTableData | ReportAlertHistoryTableData
              >
          )[],
        }
      );

      setReport({
        ...report,
        columns: visibleColumns.map(({ field }) => columnsModel[field]),
      });
      setColumns([...visibleColumns, ...hiddenColumns]);
    },
    [setReport, report, columns, getColumns, setColumns]
  );

  const handleColumnsOrderChange = useCallback(
    (colData: GridColumnOrderChangeParams) => {
      const { newColumns, newReportColumns } = changeColumnsOrder(
        colData,
        columns,
        report
      );
      setColumns(newColumns);
      setReport({ ...report, columns: newReportColumns });
    },
    [setReport, setColumns, report, columns]
  );

  // Show spinner while report is loading
  useSpinner(loading);

  const onFilterChange = (value: {
    items: FiltersInput[];
    linkOperator: string;
  }) => {
    setCurrentPageNo(1);
    setPaginationSkip(0);
    const newFilters = validateTableFilters(value);
    if (!isUndefined(newFilters)) {
      setFilters(newFilters);
    }
  };

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      const reportOrder = sortModel.map((sortItem) => ({
        field: sortItem.field,
        direction: sortItem.sort?.toUpperCase() as OrderDirection,
      }));
      setReport({ ...report, order: reportOrder });

      setSortModel(sortModel);
    },
    [report]
  );

  const isTableDataLoading = isReportDataLoading || isReportDataRefetching;

  const handleCloseScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const handleCloseEditNameDialog = () => {
    setIsDialogOpen(false);
  };

  const tableViewsMenuRef = useRef<TableViewMenuRef>(null);

  const handleSaveReport = useCallback(async () => {
    const orgIdsWildcardStructure = buildOrgIdsWildcardStructure(
      report.parameters?.orgIds
    );
    validateOrgIdsWildcardStructureLength(orgIdsWildcardStructure);

    // check if schedule was partially filled => trigger validation
    const isScheduleValid =
      !!watchFrequency && !!watchFormat ? await scheduleForm.trigger() : true;
    const isParamsValid = await parametersForm.trigger();

    if (!isScheduleValid || !isParamsValid) {
      return;
    }

    if (report._id === "template") {
      setShouldCreateReport(true);
      setIsDialogOpen(true);
    } else {
      setLoading(true);
      updateReport({
        input: {
          _id: report._id,
          name: report.name,
          columns: report.columns,
          order: report.order,
          trigger: report.trigger || null,
          schedule: getScheduleIfHasData(),
          parameters: prepareReportParamsForSaving({
            ...report.parameters,
            orgIds: orgIdsWildcardStructure,
          }),
        },
      });
    }
    setShouldPrompt(false);
  }, [
    report,
    watchFrequency,
    watchFormat,
    scheduleForm,
    parametersForm,
    buildOrgIdsWildcardStructure,
    validateOrgIdsWildcardStructureLength,
    updateReport,
    getScheduleIfHasData,
  ]);

  const handleLeaveModalClose = () => {
    setIsLeaveModalOpen(false);
    setIsConfirmed(false);
  };

  const handleUpdateField = (value: any, field?: string) => {
    if (!field) return;
    //check if the given date is valid - if not don't proceed
    const dateFields = ["parameters.startDate", "parameters.endDate"];
    if (dateFields.includes(field) && isNaN(new Date(value).getTime())) {
      return;
    }
    let fieldValue = cloneDeep(value);

    if (field === "parameters.endDate") {
      fieldValue = value
        ? formatDateToTimezoneAsUTCISOString(
            getEndingOfDayISOString(value),
            userTimeZone
          )
        : null;
    }
    if (field === "parameters.startDate") {
      fieldValue = value
        ? formatDateToTimezoneAsUTCISOString(
            getBeginningOfDayISOString(value),
            userTimeZone
          )
        : null;
    }
    const reportCopy = cloneDeep(report);
    set(reportCopy, field, fieldValue);
    setLastUpdatedField(field);
    setReport(reportCopy);
  };

  const runReportHandler = useCallback(
    () => refetchReportData(),
    [refetchReportData]
  );

  useSpinner(loading);

  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      icon: <SaveIcon />,
      title: "Save",
      handler: handleSaveReport,
      accessScope: "reports.create" as UserAccessScope,
    },

    {
      type: SubHeaderActionType.Button,
      primary: true,
      title: "Run Report",
      handler: runReportHandler,
      accessScope: "reports.run" as UserAccessScope,
    },
  ];

  const wasSaved = report._id !== "template";

  const onPageChange = useCallback((page: number) => {
    setCurrentPageNo(page);
    setPaginationSkip(pageSize * (page - 1));
  }, []);

  useEffect(() => {
    refetchReportData();
  }, [sortModel, filters, refetchReportData]);

  useEffect(() => {
    setRowCount((prev: number) =>
      !isReportDataLoading && Number.isInteger(reportData?.pagination.total)
        ? reportData?.pagination.total
        : prev
    );
  }, [
    rowCount,
    isReportDataLoading,
    setRowCount,
    reportData?.pagination.total,
  ]);

  const baseFilters = useMemo(
    () => ({
      startDate: report.parameters?.startDate,
      endDate: report.parameters?.endDate,
    }),
    [report.parameters]
  );

  useTableDataExporter<TableGridData>({
    apiRef,
    queryInput,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    domain: tableDomain,
    columns: columns as TableGridColDef<TableGridData>[],
    totalCount: reportData?.pagination.total,
    baseFilters,
    trigger: report.trigger,
  });

  const handleExport = useCallback(
    (format: ServerSideExportFormat, tableDomain: TableDomain) => {
      if (format === ServerSideExport.EMAIL) {
        setIsSendingEmail(true);
      } else {
        setTableDomain(tableDomain);
        setFileFormat(format);
        setIsExporting(true);
      }
    },
    []
  );

  return (
    <>
      <SubHeader title="Reports" actions={subHeaderActions} hideOrgName />
      <ReportHeader
        setIsDialogOpen={setIsDialogOpen}
        name={report.name}
        id={report._id}
      />
      <ReportSettings
        scheduleForm={scheduleForm}
        parametersForm={parametersForm}
        queryForm={queryForm}
        report={report}
        timezone={userTimeZone}
        handleScheduleReportSubmit={handleScheduleReportSubmit}
        wasSaved={wasSaved}
        handleUpdateField={handleUpdateField}
        isNextRunDateCalculating={isNextRunDateCalculating}
        setIsSelectOpen={setIsSelectOpen}
        isSelectOpen={isSelectOpen}
      />
      {(() => {
        switch (reportType) {
          case ReportType.DwellHierarchy:
            return (
              <Box
                className="h-full w-full p-4 pt-2 md:px-6 md:pb-10 lg:px-16 lg:pb-20"
                data-testid="asset-report-table-container"
              >
                <BackEndProcessingTable
                  tableType={TableViewType.Report}
                  apiRef={apiRef}
                  queryInput={queryInput}
                  domain={TableDomain.DwellHierarchy}
                  isSendingEmail={isSendingEmail}
                  setSendingEmail={setIsSendingEmail}
                  tableName={"asset-report"}
                  columnVisibilityModel={columnsVisibilityModel}
                  changeColumnsVisibility={handleChangeColumnsVisibility}
                  onColumnOrderChange={handleColumnsOrderChange}
                  columns={columns as TableGridColDef<ReportGridColTableData>[]}
                  data={{
                    rows: (reportData?.data ?? []) as any,
                    pagination: reportData?.pagination,
                  }}
                  onExport={handleExport}
                  updateQueryInput={updateQueryInput}
                  sorting={queryInput.sorting ?? undefined}
                  isLoading={isTableDataLoading}
                  isSuccess={isReportDataSuccess}
                  searchMinLength={appConfig.searchMinLength}
                  totalCount={reportData?.pagination.total}
                  tableViewsMenuRef={tableViewsMenuRef}
                />
                <Spinner
                  counter={Number(isExporting) || Number(isSendingEmail)}
                />
              </Box>
            );

          case ReportType.AlertHistory:
            return (
              <Box
                className="h-full w-full p-4 pt-2 md:px-6 md:pb-10 lg:px-16 lg:pb-20"
                data-testid="alert-history-report-table"
              >
                <BackEndProcessingTable
                  tableType={TableViewType.Report}
                  apiRef={apiRef}
                  queryInput={queryInput}
                  baseFilters={baseFilters}
                  domain={TableDomain.ReportAlertHistory}
                  isSendingEmail={isSendingEmail}
                  setSendingEmail={setIsSendingEmail}
                  tableName={"asset-report"}
                  columnVisibilityModel={columnsVisibilityModel}
                  onColumnOrderChange={handleColumnsOrderChange}
                  changeColumnsVisibility={handleChangeColumnsVisibility}
                  columns={
                    columns as TableGridColDef<ReportAlertHistoryTableData>[]
                  }
                  data={{
                    rows: (reportData?.data ?? []) as any,
                    pagination: reportData?.pagination,
                  }}
                  onExport={handleExport}
                  updateQueryInput={updateQueryInput}
                  sorting={queryInput.sorting ?? undefined}
                  isLoading={isTableDataLoading}
                  isSuccess={isReportDataSuccess}
                  searchMinLength={appConfig.searchMinLength}
                  totalCount={reportData?.pagination.total}
                  tableViewsMenuRef={tableViewsMenuRef}
                />
                <Spinner
                  counter={Number(isExporting) || Number(isSendingEmail)}
                />
              </Box>
            );

          default:
            return (
              <ReportTable
                columns={columns as ReportGridColDef[]}
                data={reportData.data as any}
                isSuccess={isReportDataSuccess}
                isLoading={isTableDataLoading}
                apiRef={apiRef}
                columnsVisibilityModel={columnsVisibilityModel}
                changeColumnsVisibility={handleChangeColumnsVisibility}
                onColumnOrderChange={handleColumnsOrderChange}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                searchKeys={searchKeys}
                reportName={report.name}
                onPageChange={onPageChange}
                currentPage={currentPageNo}
                pageSize={pageSize}
                rowCount={rowCount}
                onFilterChange={onFilterChange}
                tableViewsMenuRef={tableViewsMenuRef}
              />
            );
        }
      })()}
      <EditReportNameDialog
        open={isDialogOpen}
        onClose={handleCloseEditNameDialog}
        onSubmit={updateReportName}
        reportName={report.name}
      />
      <ScheduleModal
        shouldPrompt={shouldPrompt}
        wasSaved={wasSaved}
        open={isScheduleModalOpen}
        handleClose={handleCloseScheduleModal}
      />
      <MaximumExceededModal
        isOpened={maxExceededModalOpened}
        onClose={handleMaxExceededModalAccept}
        onReturn={handleMaxExceededModalDecline}
        maxCount={maxExceededOrgsCount}
      />
      <LeaveModal
        isOpen={isLeaveModalOpen}
        confirmNavigation={() => setIsConfirmed(true)}
        onClose={handleLeaveModalClose}
      />
    </>
  );
};
