import { FC, useCallback, useEffect } from "react";
import { FieldValues } from "react-hook-form";
import { Box, ThemeProvider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isEmpty, pick } from "lodash";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  CreateAssetInput,
  useCreateAssetMutation,
  useGetAssetsQuery,
} from "../../../../../graphql/operations";
import Drawer from "../../../../../shared/components/Drawer";
import DrawerContent from "../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../shared/components/Drawer/DrawerHeader";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import { useSettingsTheme } from "../../../MapView/hooks/useSettingsTheme";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { AssetForm, useAssetForm } from "../AssetForm";

export interface AddAssetDrawerProps {
  open: boolean;
  setOpen: (flag: boolean) => void;
}

const CreateAssetInputKeys: Array<string> = [
  "asset_group",
  "asset_id",
  "category",
  "city",
  "customer_orgs_id",
  "door_type",
  "height",
  "imei",
  "length",
  "location",
  "name",
  "num_of_axles",
  "prd_cde",
  "state",
  "tags",
  "trip_st",
  "vin",
  "wheel_config",

  "externalHeight",
  "internalHeight",
  "internalLength",
  "internalWidth",
  "subAssetType",
  "externalLength",
  "manufacturer",
  "assetModel",
  "emptyWeight",
  "odometer",
  "engineHours",

  "metadata.year",
  "metadata.assetInserviceDate",
  "metadata.cubeCapacity",
  "metadata.railCompliant",
  "metadata.airRide",

  "metadata.liftgate",
  "metadata.liftgateMACAddress",
  "metadata.liftgateManufacturer",
  "metadata.liftgateModel",
  "metadata.liftgateSerial",
  "metadata.liftgateYear",
  "metadata.liftgateFWVersion",

  "metadata.CARBNumber",
  "metadata.reeferModel",
  "metadata.reeferSerialNumber",
  "metadata.reeferManufacturer",
  "metadata.reeferCARBCompliant",
  "metadata.reeferYear",

  "metadata.flushMountedNose",
  "metadata.gridExtensionPlate",
  "metadata.liftPads",

  "metadata.absCanbus",
  "metadata.absConfiguration",
  "metadata.absManufacturer",
  "metadata.absModel",
  "metadata.absYear",

  "metadata.atis",
  "metadata.atisManufacturer",
  "metadata.atisModel",

  "metadata.lightWeight",
  "metadata.ttPairCapable",
  "metadata.aeroKitType",
  "metadata.batteryCharger",
  "metadata.doorLatchType",
  "metadata.doorLatchManufacturer",
  "metadata.electricalHarnessManufacturer",
  "metadata.electronicLandingGear",
  "metadata.etrack",
  "metadata.gladhandManufacturer",
  "metadata.gladhandType",
  "metadata.howIsNoseboxMounted",
  "metadata.innerLinerAvailable",
  "metadata.nosePostDepth",
  "metadata.interiorDropDownCargoSystem",
  "metadata.kingpinOffset",
  "metadata.landingGearManufacturer",
  "metadata.landingGearModel",
  "metadata.landingGearModelRating",
  "metadata.lightingManufacture",
  "metadata.preferredMidturnLightPartNumber",
  "metadata.preferredTailLightPartNumber",
  "metadata.logisticsPosts",
  "metadata.noseTypeBody",
  "metadata.noseboxManufacturer",
  "metadata.noseboxModel",
  "metadata.permalogic",
  "metadata.rearAero",
  "metadata.rearAeroManufacturer",
  "metadata.roadtrainAllowed",
  "metadata.skirt",
  "metadata.skirtManufacturer",
  "metadata.suspensionManufacturer",
  "metadata.suspensionModel",
  "metadata.suspensionType",
  "metadata.suspensionYear",
  "metadata.tireSize",
  "metadata.tireRimType",
];

export const AddAssetDrawer: FC<AddAssetDrawerProps> = ({ open, setOpen }) => {
  const { dispatch } = useAppContext();
  const { assetsInput } = useAssetsDataContext();
  const getAssetsQuery = useGetAssetsQuery(
    { input: assetsInput },
    { enabled: false }
  );
  const { form, getValues } = useAssetForm();
  const queryClient = useQueryClient();
  const theme = useSettingsTheme();

  useEffect(() => {
    form.reset();
  }, [open, form]);

  const { mutateAsync, isLoading } = useCreateAssetMutation({
    onSuccess: (created) => {
      getAssetsQuery.refetch({ cancelRefetch: true });

      if (created.createAsset.name) {
        queryClient.invalidateQueries(["findDevices"], {
          refetchType: "all",
        });
        queryClient.invalidateQueries([
          "getAssetsTableData",
          {
            input: {
              pagination: { limit: 100, skip: 0 },
              subStringSearchText: "",
              tableFilters: {
                filters: [],
                linkOperator: "and",
              },
            },
          },
        ]);
      }

      setOpen(false);

      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset created successfully and will be visible soon!",
          text: "You can find the Asset in the table",
          severity: "success",
        },
      });
    },
    onError: (error) => {
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          title: "Asset Creation Failed",
          text: mapServerErrorCodeToHumanReadableMessage(
            error instanceof Error ? error.message : "Something Went Wrong."
          ),
          severity: "error",
        },
      });
    },
  });

  const isSubmitDisabled = isLoading || isEmpty(form.formState.dirtyFields);

  const onSubmit = async () => {
    const valid = await form.trigger();
    if (!valid) {
      return;
    }

    const asset = pick(
      getValues(),
      CreateAssetInputKeys
    ) as unknown as CreateAssetInput;

    await mutateAsync({
      asset,
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmitClick = async () => {
    await onSubmit();
  };

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Drawer testId="asset-drawer" isOpen={open} onRequestClose={handleClose}>
      <DrawerHeader text="Create Asset" onClose={handleClose} />

      <DrawerContent>
        <Box className="bg-background pb-0">
          <ThemeProvider theme={theme}>
            <AssetForm
              form={form}
              onSubmit={onSubmit}
              data-testid="add-asset-form"
              compact
              cancelable={!isLoading}
              onCancel={onCancel}
            />
          </ThemeProvider>
        </Box>
      </DrawerContent>

      <DrawerFooter
        text={isLoading ? "Saving" : "Save"}
        disabled={isSubmitDisabled}
        testId="add-asset-submit"
        submit={handleSubmitClick}
      />
    </Drawer>
  );
};
