import { FC, useEffect, useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { CheckboxElement, AutocompleteElement } from "react-hook-form-mui";
import { Chip, Grid } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import {
  FindMatchesType,
  GeofenceData,
  useFindGeofencesByIdsQuery,
  useFindGeofencesQuery,
  useGetGeofenceTypeQuery,
} from "../../../../graphql/operations";
import { useGlobalSearchQuery } from "../../../../shared/hooks/useGlobalSearchQuery";
import {
  useGeofenceNameOptions,
  useGeofenceTypeOptions,
} from "../../../AssetsView/TableView/hooks";
import { AlertFormValues } from "../../interfaces";
import {
  getAlertMultiselectIds,
  getGeofencesOptionsList,
  isFindGeofenceByIdQueryEnabled,
  onFindGeofencesSettledCallback,
} from "../../utils";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertGeofenceParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertGeofenceParameters: FC<AlertGeofenceParametersProps> = ({
  form,
}: AlertGeofenceParametersProps) => {
  const { state } = useAppContext();
  const [selectedGeofence, setSelectedGeofence] = useState<any>(null);
  const [geofencesOptionsList, setGeofencesOptionsList] = useState<
    { id: string; label: string }[]
  >([{ id: "", label: "" }]);
  const queryType = FindMatchesType.Geofence;
  const historyItems: string[] = [];
  const optionsLimit = state.appConfig.searchOptionsLimit;

  const watchGeofences = useWatch({
    name: "parameters.geofenceIds",
    control: form.control,
  });
  const watchGeofenceType = useWatch({
    name: "parameters.geofenceType",
    control: form.control,
  });

  const { data: query } = useGetGeofenceTypeQuery(
    {},
    {
      onError: (error) => {
        console.error(`Handled error in GetGeofenceTypeQuery, ${error}`);
      },
    }
  );
  const geofenceTypes = useMemo(() => query?.getGeofenceType ?? [], [query]);
  const geofenceTypesList = useGeofenceTypeOptions(geofenceTypes);
  let shouldBeEnabled = isFindGeofenceByIdQueryEnabled(watchGeofences);

  const { data, isSuccess } = useFindGeofencesQuery();
  const geofenceData = data?.findGeofences?.data ?? [];
  const geofenceNameOpts = useGeofenceNameOptions(
    geofenceData as GeofenceData[]
  );

  const {
    data: foundGeofence,
    isLoading: findGeofenceLoading,
    isSuccess: findGeofenceSuccess,
  } = useFindGeofencesByIdsQuery(
    { ids: getAlertMultiselectIds(watchGeofences) ?? [] },
    {
      enabled: shouldBeEnabled,
    }
  );
  useEffect(() => {
    if (foundGeofence && !findGeofenceLoading && findGeofenceSuccess) {
      onFindGeofencesSettledCallback({
        data: foundGeofence,
        setSelectedGeofence,
      });
    }
  }, [
    foundGeofence,
    findGeofenceLoading,
    findGeofenceSuccess,
    form,
    shouldBeEnabled,
  ]);

  useEffect(() => {
    form.setValue("parameters.geofenceIds", selectedGeofence);
  }, [form, selectedGeofence]);

  const { searchResult, isLoading, setSearch } = useGlobalSearchQuery(
    queryType,
    historyItems,
    optionsLimit
  );

  useEffect(() => {
    setGeofencesOptionsList(getGeofencesOptionsList(searchResult));
  }, [searchResult]);

  const hideInputValue =
    !selectedGeofence &&
    !!watchGeofences?.length &&
    findGeofenceLoading &&
    !findGeofenceSuccess;

  return (
    <AlertParametersContainer>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="alert-parameters-geofence-type"
      >
        <AutocompleteElement
          autocompleteProps={{
            disabled: !!watchGeofences?.length,
            readOnly: !!watchGeofences?.length,
          }}
          matchId={true}
          name="parameters.geofenceType"
          data-testid="alert-parameters-geofence-type"
          label="Geofence Type"
          control={form.control}
          options={geofenceTypesList}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={4}>
        <AutocompleteElement
          autocompleteProps={{
            noOptionsText: "No options",
            onInputChange: (event, newInputValue) => {
              if (newInputValue) {
                setSearch(newInputValue);
              }
            },
            onChange: (event, newInputValue) => {
              if (newInputValue) {
                form.setValue("parameters.geofenceIds", newInputValue);
                setSelectedGeofence(newInputValue);
                setGeofencesOptionsList([]);
              }
            },
            autoComplete: true,
            blurOnSelect: true,
            disabled: !!watchGeofenceType,
            readOnly: !!watchGeofenceType,
            renderTags: (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={
                    hideInputValue || !option.label ? "loading" : option.label
                  }
                  {...getTagProps({ index })}
                />
              )),
          }}
          multiple
          loading={isLoading || hideInputValue}
          label="Geofences"
          control={form.control}
          name="parameters.geofenceIds"
          options={geofenceNameOpts}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={{ md: "flex" }}
        md={"auto"}
        lg={"auto"}
        xl={6}
      >
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-geofence-enter"
        >
          <CheckboxElement
            name="parameters.alertOnEnter"
            control={form.control}
            label="Geofence Enter"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={"auto"}
          lg={"auto"}
          xl={"auto"}
          data-testid="alert-parameters-geofence-exit"
        >
          <CheckboxElement
            name="parameters.alertOnExit"
            control={form.control}
            label="Geofence Exit"
          />
        </Grid>
      </Grid>
    </AlertParametersContainer>
  );
};
