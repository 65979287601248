import React, {
  memo,
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useSearchParams } from "react-router-dom";
import FileUpload from "@mui/icons-material/FileUpload";
import { ThemeProvider } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR, PAGE_SPINNER } from "../../constants";
import { BatchTitles } from "../../constants/batches";
import {
  DEVICE_CREATE_FAILED_PAYLOAD,
  DEVICE_CREATE_SUCCESS_PAYLOAD,
  DEVICE_UPDATE_FAILED_PAYLOAD,
  DEVICE_UPDATE_SUCCESS_PAYLOAD,
} from "../../constants/device";
import { useAppContext } from "../../context/AppContext";
import { FormModes } from "../../enums/formModes";
import {
  DeviceDataInput,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useFindOrgsQuery,
  DevicesTableDataResponse,
  SortOrder,
  GetTableDataInput,
  useGetDevicesTableDataQuery,
  DeviceTableData,
} from "../../graphql/operations";
import Page from "../../shared/components/Page";
import { SubHeader } from "../../shared/components/SubHeader";
import {
  SubHeaderActionProps,
  SubHeaderActionType,
} from "../../shared/components/SubHeader/components/SubHeaderAction/SubHeaderAction";
import { useExportedFileName } from "../../shared/hooks/useExportedFileName";
import { useUserData } from "../../shared/hooks/useUserData";
import { FeatureFlags } from "../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../utils/useFeatureFlag";
import { useAssetManagementTheme } from "../AssetsView/TableView/hooks/useAssetManagementTheme";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../BatchesView/BatchManagementUtils";
import DeviceManagementTable from "./DeviceManagementTable";
import InstallDeviceForm from "./InstallDeviceForm/InstallDeviceForm";
import { UploadDevicesDialog } from "./UploadDevicesDialog/UploadDevicesDialog";
import {
  handleDataInvalidation,
  handleDeviceExport,
  useDeleteDeviceHook,
  validateAndSubmitFormAction,
} from "./deviceUtils";

const DeviceManagement: React.FC = () => {
  const assetManagementTheme = useAssetManagementTheme();
  const {
    dispatch,
    state: {
      appConfig,
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const queryClient = useQueryClient();
  const page = useRef();
  const [drawerState, setDrawerState] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const gridApiRef = useGridApiRef();
  const fileName = useExportedFileName("Devices");

  const exportHandler = useCallback(() => {
    handleDeviceExport(gridApiRef, fileName);
  }, [gridApiRef, fileName]);

  const installDeviceHandler = () => {
    setDeviceToEdit({});
    setDrawerState(!drawerState);
    setFormMode(FormModes.create);
  };
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [formMode, setFormMode] = useState(FormModes.view);
  const [searchParams] = useSearchParams();
  const initialSearch = searchParams.get("search");

  // Devices Upload
  const [isUploadDevicesDialogOpen, setIsUploadDevicesDialogOpen] =
    useState(false);
  const uploadHandler = () => setIsUploadDevicesDialogOpen(true);

  const onUploadDevicesDialogClose = () => {
    setIsUploadDevicesDialogOpen(false);
  };

  const [queryInput, setQueryInput] = useState<GetTableDataInput>({
    sorting: [{ field: "updatedDate", order: SortOrder.Desc }],
    orgId: selectedOrganization.value,
    pagination: {
      skip: 0,
      limit: appConfig.table.defaultRowsPerPage,
    },
  });

  useEffect(() => {
    setQueryInput((prev) => ({
      ...prev,
      orgId: selectedOrganization.value,
    }));
  }, [selectedOrganization]);

  const updateQueryInput = useCallback(
    (data: Partial<GetTableDataInput>) => {
      setQueryInput((prev) => ({ ...prev, ...data }));
    },
    [setQueryInput]
  );

  const {
    data: deviceGQLData,
    isSuccess: isDeviceDataSuccess,
    isLoading: isDeviceDataLoading,
    refetch: refetchDeviceData,
    isRefetching: IsDeviceDataRefetching,
  } = useGetDevicesTableDataQuery<DevicesTableDataResponse>(
    { input: queryInput },
    {
      refetchOnMount: true,
      staleTime: 5000,
      select: ({ getDevicesTableData }) => getDevicesTableData,
    }
  );

  const devices = useMemo<Array<DeviceTableData>>(
    () => deviceGQLData?.data || [],
    [deviceGQLData?.data]
  );

  const onTableCellClick = (e: any) => {
    const _id = e?.id;
    const device: DeviceTableData | undefined = (devices || []).find(
      (d) => d.id === _id
    );
    const deviceInput: DeviceDataInput = {
      _id: _id,
      customer_orgs_id: device?.customer_orgs_id ?? undefined,
      imei: device?.imei,
      assets_id: device?.assets_id ?? undefined,
      box_id: device?.box_id ?? undefined,
      order_num: device?.order_num ?? undefined,
      packing_list: device?.packing_list ?? undefined,
      sim_num: device?.sim_num ?? undefined,
      tags: device?.tags ?? undefined,
      org_name: device?.org_name ?? "",
      asset_name: device?.asset_name ?? "",
    };

    setDrawerState(true);
    setDeviceToEdit(deviceInput);
    setFormMode(FormModes.edit);
  };

  const { data: orgData, refetch: refetchOrgData } = useFindOrgsQuery(
    {},
    {
      staleTime: 900000, // 15 minutes
    }
  );
  const orgs = mapOrgs(orgData?.findOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  const userData = useUserData();

  const { mutate: createDeviceMutate } = useCreateDeviceMutation({
    onSuccess: async () => {
      await handleDataInvalidation(
        queryClient,
        refetchDeviceData,
        refetchOrgData
      );
      setIsDataLoading(false);
      setDeviceToEdit({});
      dispatch({
        type: PAGE_SNACKBAR,
        payload: DEVICE_CREATE_SUCCESS_PAYLOAD,
      });
    },
    onError: (error: any, reqData: any) => {
      setDrawerState(false);
      setIsDataLoading(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...DEVICE_CREATE_FAILED_PAYLOAD,
          text:
            error?.message === "ERROR_DEVICE_ALREADY_EXISTS"
              ? `Device with Device ID ${reqData?.input?.imei} already exists`
              : DEVICE_CREATE_FAILED_PAYLOAD.text,
        },
      });
    },
  });

  const { mutate: updateDeviceMutate } = useUpdateDeviceMutation({
    onSuccess: async () => {
      setTimeout(async () => {
        await handleDataInvalidation(
          queryClient,
          refetchDeviceData,
          refetchOrgData
        );
        setIsDataLoading(false);
        setDeviceToEdit({});
        dispatch({
          type: PAGE_SNACKBAR,
          payload: DEVICE_UPDATE_SUCCESS_PAYLOAD,
        });
      }, 0);
    },
    onError: (error: any, reqData: any) => {
      setDrawerState(false);
      setIsDataLoading(false);
      dispatch({
        type: PAGE_SNACKBAR,
        payload: {
          ...DEVICE_UPDATE_FAILED_PAYLOAD,
          text:
            error?.message === "ERROR_DEVICE_ALREADY_EXISTS"
              ? `Device with Device ID ${reqData?.input?.imei} already exists`
              : DEVICE_UPDATE_FAILED_PAYLOAD.text,
        },
      });
    },
  });

  const { mutate: useDeleteDeviceMutation } = useDeleteDeviceHook(
    queryClient,
    setIsDataLoading,
    setDeviceToEdit,
    refetchDeviceData,
    refetchOrgData,
    dispatch,
    setDrawerState
  );

  const handleFormSubmit = (data: any, dirtyFields: any) =>
    validateAndSubmitFormAction(
      data,
      dirtyFields,
      {
        setIsDataLoading,
        setDrawerState,
        createDeviceMutate,
        updateDeviceMutate,
        dispatch,
      },
      formMode
    );

  useEffect(() => {
    if (isDataLoading) {
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 1 },
      });
    }

    return () =>
      dispatch({
        type: PAGE_SPINNER,
        payload: { increment: 0 },
      });
  }, [dispatch, isDataLoading]);

  const subHeaderActions: SubHeaderActionProps[] = [
    {
      type: SubHeaderActionType.Button,
      icon: <FileUpload />,
      title: "Upload",
      handler: uploadHandler,
      accessScope: "device.upload",
    },
    {
      type: SubHeaderActionType.Button,
      primary: true,
      title: "New Device",
      handler: installDeviceHandler,
      accessScope: "device.create",
    },
  ];

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  const invalidateQueries = () => {
    const queries = ["findDevices", "findOrgs", "getBatchHistory"];

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      queries.push("getAssetsForClustersOS");
      queries.push("getAssetsForTableOS");
      queries.push("getAssetsForListOS");
    } else {
      queries.push("getAssetsClusters");
      queries.push("getAssetsForTable");
      queries.push("getAssetsForList");
    }

    queries.forEach((query) => {
      queryClient.invalidateQueries([query], {
        refetchType: "all",
      });
    });
  };
  return (
    <ThemeProvider theme={assetManagementTheme}>
      <Page ref={page} title="" className={"bg-background"}>
        <SubHeader
          title="Devices"
          actions={subHeaderActions}
          hideHeaderBorder={true}
          hideOrgName
        />
        <UploadDevicesDialog
          title={BatchTitles.CreateUpdateDevices}
          customerOrg={userData?.customerOrg?.name}
          dialogFields={BATCH_FORM_FIELDS}
          isOpen={isUploadDevicesDialogOpen}
          onClose={onUploadDevicesDialogClose}
          onUpload={() => invalidateQueries()}
        />
        {drawerState && (
          <InstallDeviceForm
            open={drawerState}
            setOpen={setDrawerState}
            deviceToEdit={deviceToEdit as DeviceDataInput}
            formMode={formMode}
            handleFormSubmit={handleFormSubmit}
            handleDeleteDevice={useDeleteDeviceMutation}
            orgData={orgData}
            setIsDataLoading={setIsDataLoading}
          />
        )}
        <DeviceManagementTable
          initialSearch={initialSearch}
          apiRef={gridApiRef}
          devices={devices}
          refetchDeviceData={refetchDeviceData}
          isSuccess={isDeviceDataSuccess}
          isLoading={isDeviceDataLoading || IsDeviceDataRefetching}
          onRowClick={onTableCellClick}
          isDataRefetching={IsDeviceDataRefetching}
          updateQueryInput={updateQueryInput}
          queryData={deviceGQLData}
          queryInput={queryInput}
        />
      </Page>
    </ThemeProvider>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
DeviceManagement.displayName = "DeviceManagement";
export default memo(DeviceManagement);
