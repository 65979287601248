import { FC, useMemo, useState } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import { ODOMETER_CHART_DATE_RANGE_OPTIONS } from "../../../../../../constants/map";
import {
  Asset,
  useSearchAggregatedEventHistoryQuery,
} from "../../../../../../graphql/operations";
import TripDetailsChart from "../../../Shared/TripDetailsChart/TripDetailsChart";
import {
  AggregatedEventHistoryReportTypes,
  DEFAULT_TRIP_DETAILS_OPTION,
  FIVE_MINUTES_REFETCH_INTERVAL,
  chartColors,
  chartTickStyle,
  getTripDetailsChartData,
} from "./chartUtils";

export interface TripDetailsProps {
  selectedAsset?: Asset;
}

const TripDetails: FC<TripDetailsProps> = ({ selectedAsset }) => {
  const [selectedTripDateRangeOption, setSelectedTripDateRangeOption] =
    useState(DEFAULT_TRIP_DETAILS_OPTION);

  const defaultDateRangeFilter = ODOMETER_CHART_DATE_RANGE_OPTIONS.find(
    (option) => option.value === DEFAULT_TRIP_DETAILS_OPTION
  );
  const [defaultStartDateFilter, defaultEndDateFilter] =
    defaultDateRangeFilter?.getRange() ?? [];

  const [tripChartStartDate, setTripChartStartDate] = useState<
    Date | string | null
  >(defaultStartDateFilter);

  const [tripChartEndDate, setTripChartEndDate] = useState<
    Date | string | null
  >(defaultEndDateFilter);

  const {
    data: tripDetailsHistoryData,
    isLoading: isLoadingTripDetailsHistoryData,
  } = useSearchAggregatedEventHistoryQuery(
    {
      input: {
        startDate: tripChartStartDate,
        endDate: tripChartEndDate,
        assetId: selectedAsset?.asset_id!,
        reportType: AggregatedEventHistoryReportTypes.AssetTripReport,
        includeEmptyDates: true,
      },
    },
    {
      enabled: !!selectedAsset?.imei,
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL, // refetch every 5 minutes
    }
  );

  const tripDetailsChartData = useMemo(() => {
    const result = getTripDetailsChartData(tripDetailsHistoryData);
    return result;
  }, [tripDetailsHistoryData]);

  const handleTripDateRangeChange = (value: number) => {
    const option = ODOMETER_CHART_DATE_RANGE_OPTIONS.find(
      (option) => option.value === value
    );
    if (option) {
      setSelectedTripDateRangeOption(value);
      if (option.getRange) {
        const [startDate, endDate] = option.getRange();
        setTripChartStartDate(startDate);
        setTripChartEndDate(endDate);
      }
    }
  };

  return (
    <Grid
      item
      xl={6}
      lg={12}
      xs={12}
      data-testid="trip-details-chart-container"
    >
      {isLoadingTripDetailsHistoryData ? (
        <Box
          className="flex h-full w-full items-center justify-center"
          data-testid="trip-details-loader"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className="flex-1 rounded-lg bg-dashboard_subheader__bg p-4">
          <Typography
            className="!text-lg !font-semibold text-brand"
            data-testid="chart-title"
          >
            Trip Details
          </Typography>
          <Typography
            className="!text-xs !font-medium text-asset-info-subheader"
            data-testid="chart-subtitle"
          >
            View daily asset trip information
          </Typography>
          <>
            <Stack direction="row" justifyContent="end">
              <TextField
                data-testid="trip-details-chart-date-range-select"
                select
                value={selectedTripDateRangeOption}
                variant="outlined"
                className="w-40"
                onChange={(e) => handleTripDateRangeChange(+e.target.value)}
                inputProps={{
                  "data-testid": "trip-details-chart-date-range-input",
                }}
              >
                {ODOMETER_CHART_DATE_RANGE_OPTIONS.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-testid={`trip-details-chart-date-range-select-option-${option.value}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <TripDetailsChart
              data={tripDetailsChartData}
              lineKeys={["Moving", "Parked"]}
              chartColors={chartColors}
              tickStyle={chartTickStyle}
            />
          </>
        </Box>
      )}
    </Grid>
  );
};

export default TripDetails;
