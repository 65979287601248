import { LAST_REPORTED_DATE_OPTIONS_MAP } from "../../../../../constants/map";
import {
  MeUserData,
  SensorStatus,
  TableFilterInput,
  TableFilterLinkOperator,
  TableFilterOperator,
  TableFiltersInput,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { AssetFilters } from "../../../shared/AssetsDataContext";

const getFilterEntry = (
  field: string,
  value: unknown,
  operator: TableFilterOperator,
  dataType: TableValueDataType = TableValueDataType.String
): TableFilterInput => ({
  dataType,
  field,
  operator,
  value:
    value || dataType === TableValueDataType.Boolean
      ? JSON.stringify({ value })
      : undefined,
});

export const mapAssetFilters = (
  rawFilters: Partial<AssetFilters>,
  userData: MeUserData | undefined,
  selectedOrganization: string,
  osFeatureFlag = false
): TableFiltersInput | undefined => {
  const filters: TableFilterInput[] = [];

  if (!rawFilters) {
    return undefined;
  }

  const capitalizeSensorValue = (values: SensorStatus[]) => {
    return values.map((value) =>
      osFeatureFlag ? value.charAt(0).toUpperCase() + value.slice(1) : value
    );
  };

  const {
    assetTypes,
    assetTags,
    productNames,
    subOrganizations,
    assetHealth,
    numberOfAxles,
    numberOfTires,
    weightStatus,
    totalMileage,
    assetYear,
    manufacturers,
    lastReportedDateRange,
    status,
    cargoUltrasonicState,
    doorState,
    internalCameraStatus,
    internalCameraFloorUsagePercentage,
    batteryPrimaryVoltage,
    batterySecondaryVoltage,
    backupBatteryVoltage,
    solarAmperage,
    atisAlpha,
    liteSentryGamma,
    temperatureInternal,
    wheelEndTemperature,
    airbag,
    tpmsBeta,
    supplyPressure,
    tankPressure,
    installStatus,
    installer,
    installedDate,
    signals,
    geofenceNames,
    geofenceCategories,
  } = rawFilters;

  if (assetTypes?.length) {
    filters.push(
      getFilterEntry("category", assetTypes, TableFilterOperator.IsAnyOf)
    );
  }

  if (assetTags?.length) {
    filters.push(
      getFilterEntry("tags", assetTags, TableFilterOperator.IsAnyOf)
    );
  }

  if (productNames?.length) {
    filters.push(
      getFilterEntry(
        "productName",
        productNames.map((productName) => productName.label),
        TableFilterOperator.IsAnyOf
      )
    );
  }
  // temp. commented code because of conflict with task 8319
  // if (!subOrganizations) {
  //   filters.push(
  //     getFilterEntry(
  //       "orgId",
  //       userData?.customerOrg._id,
  //       TableFilterOperator.Equals
  //     )
  //   );
  // }
  if (selectedOrganization) {
    const orgName = osFeatureFlag ? "customer_orgs_id" : "orgName";
    filters.push(
      getFilterEntry(orgName, selectedOrganization, TableFilterOperator.Equals)
    );
  }

  if (assetHealth?.length) {
    filters.push(
      getFilterEntry(
        "healthCondition",
        assetHealth,
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (numberOfAxles?.length) {
    filters.push(
      getFilterEntry(
        "numOfAxles",
        numberOfAxles.map((item) => item.value),
        TableFilterOperator.IsAnyOf,
        TableValueDataType.Number
      )
    );
  }

  if (numberOfTires?.length) {
    filters.push(
      getFilterEntry(
        "numOfTires",
        numberOfTires.map((item) => item.value),
        TableFilterOperator.IsAnyOf,
        TableValueDataType.Number
      )
    );
  }

  if (weightStatus?.startValue !== null && weightStatus?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "emptyWeight",
        [weightStatus?.startValue, weightStatus?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (totalMileage?.startValue !== null && totalMileage?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "odo",
        [totalMileage?.startValue, totalMileage?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (assetYear?.startValue !== null && assetYear?.endValue !== null) {
    filters.push(
      getFilterEntry(
        "year",
        [assetYear?.startValue, assetYear?.endValue],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (manufacturers?.length) {
    filters.push(
      getFilterEntry("manufacturer", manufacturers, TableFilterOperator.IsAnyOf)
    );
  }

  if (lastReportedDateRange) {
    const dateRange = LAST_REPORTED_DATE_OPTIONS_MAP.get(lastReportedDateRange);

    filters.push(
      getFilterEntry(
        "lastEventTime",
        [dateRange?.startDate, dateRange?.endDate],
        TableFilterOperator.InValueRange,
        TableValueDataType.Date
      )
    );
  }

  if (Array.isArray(status) && status.length > 0) {
    filters.push(
      getFilterEntry("tripStatus", status, TableFilterOperator.IsAnyOf)
    );
  }

  if (!Array.isArray(status)) {
    filters.push(
      getFilterEntry(
        "dwellingDays",
        [status?.minDays, status?.maxDays],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (cargoUltrasonicState) {
    filters.push(
      getFilterEntry(
        "cargoUltrasonicState",
        cargoUltrasonicState,
        TableFilterOperator.Equals
      )
    );
  }

  if (doorState) {
    filters.push(
      getFilterEntry("doorState", doorState, TableFilterOperator.Equals)
    );
  }

  if (internalCameraStatus) {
    filters.push(
      getFilterEntry(
        "internalCameraStatus",
        internalCameraStatus,
        TableFilterOperator.Equals
      )
    );
  }

  if (internalCameraFloorUsagePercentage) {
    filters.push(
      getFilterEntry(
        "internalCameraFloorUsagePercentage",
        internalCameraFloorUsagePercentage,
        TableFilterOperator.Gte,
        TableValueDataType.Number
      )
    );
  }

  if (batteryPrimaryVoltage) {
    filters.push(
      getFilterEntry(
        "batteryPrimaryVoltage",
        [batteryPrimaryVoltage?.minVoltage, batteryPrimaryVoltage?.maxVoltage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (batterySecondaryVoltage) {
    filters.push(
      getFilterEntry(
        "batterySecondaryVoltage",
        [
          batterySecondaryVoltage?.minVoltage,
          batterySecondaryVoltage?.maxVoltage,
        ],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (backupBatteryVoltage) {
    filters.push(
      getFilterEntry(
        "backupBatteryVoltage",
        [backupBatteryVoltage?.minVoltage, backupBatteryVoltage?.maxVoltage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (solarAmperage) {
    filters.push(
      getFilterEntry(
        "solarAmperage",
        [solarAmperage?.minAmperage, solarAmperage?.maxAmperage],
        TableFilterOperator.InValueRange,
        TableValueDataType.Number
      )
    );
  }

  if (atisAlpha) {
    filters.push(
      getFilterEntry(
        "atisAlphaHealthCondition",
        capitalizeSensorValue(atisAlpha),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (liteSentryGamma) {
    filters.push(
      getFilterEntry(
        "liteSentryGammaHealthCondition",
        capitalizeSensorValue(liteSentryGamma),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (temperatureInternal) {
    filters.push(
      getFilterEntry(
        "temperatureHealthCondition",
        capitalizeSensorValue(temperatureInternal),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (wheelEndTemperature) {
    filters.push(
      getFilterEntry(
        "psiWheelEndHealthCondition",
        capitalizeSensorValue(wheelEndTemperature),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (airbag) {
    filters.push(
      getFilterEntry(
        "airbagHealthCondition",
        capitalizeSensorValue(airbag),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (tpmsBeta) {
    filters.push(
      getFilterEntry(
        "tpmsBetaHealthCondition",
        capitalizeSensorValue(tpmsBeta),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (supplyPressure) {
    filters.push(
      getFilterEntry(
        "psiAirSupplyHealthCondition",
        capitalizeSensorValue(supplyPressure),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (tankPressure) {
    filters.push(
      getFilterEntry(
        "psiAirSupplyHealthCondition",
        capitalizeSensorValue(tankPressure),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (!installStatus) {
    filters.push(
      getFilterEntry("imei", undefined, TableFilterOperator.IsEmpty)
    );
  }

  if (installer) {
    filters.push(
      getFilterEntry("installer", installer, TableFilterOperator.Contains)
    );
  }

  if (installedDate?.startDate && installedDate?.endDate) {
    filters.push(
      getFilterEntry(
        "installedDate",
        [installedDate.startDate, installedDate.endDate],
        TableFilterOperator.InValueRange,
        TableValueDataType.Date
      )
    );
  }

  if (signals !== "all") {
    filters.push(
      getFilterEntry(
        "signal",
        signals,
        TableFilterOperator.Equals,
        TableValueDataType.Boolean
      )
    );
  }

  if (geofenceNames?.length) {
    filters.push(
      getFilterEntry(
        "geofenceNames",
        geofenceNames,
        TableFilterOperator.IsAnyOf
      )
    );
  }

  if (geofenceCategories?.length) {
    filters.push(
      getFilterEntry(
        "geofenceCategories",
        geofenceCategories.map((category) => category.label),
        TableFilterOperator.IsAnyOf
      )
    );
  }

  return {
    filters,
    linkOperator: TableFilterLinkOperator.And,
  };
};

export const mergeFilters = (
  currentFilters?: TableFiltersInput | null,
  newFilters?: TableFiltersInput | null,
  selectedOrganization?: string | null,
  osFeatureFlag = false
) => {
  if (!currentFilters && !newFilters) {
    return undefined;
  }

  const filtersMap = new Map<string, TableFilterInput>();

  if (newFilters?.filters !== undefined && newFilters?.filters?.length > 0) {
    currentFilters?.filters.forEach((filter) => {
      filtersMap.set(filter.field, filter);
    });

    newFilters?.filters.forEach((filter) => {
      filtersMap.set(filter.field, filter);
    });
  }

  if (selectedOrganization && !osFeatureFlag)
    filtersMap.set("org_name", {
      dataType: TableValueDataType.String,
      field: "orgName",
      operator: TableFilterOperator.Contains,
      value: `{"value":"${selectedOrganization}"}`,
    });

  const mergedFilters = Array.from(filtersMap.values());

  return {
    filters: mergedFilters,
    // Apply currentFilters linkOperator or default to And if not present, because we are only using And for newFilters
    linkOperator: currentFilters?.linkOperator ?? TableFilterLinkOperator.And,
  };
};
