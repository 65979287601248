import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  useGetAlertNotificationQuery,
  Scalars,
  useGetAlertNotificationByIdQuery,
} from "../../../../../../graphql/operations";
import { Button } from "../../../../../../shared/components/Button";
import { useDateInPreferredTimezone } from "../../../../../../shared/hooks/useDateInPreferredTimezone";

// save for future test
//import { alertNotificationMock } from "./alertNotificationMock";

interface ModalTemplatesProps {
  alertNotificationId: Scalars["ID"];
  isOpen: boolean;
  onClose: () => void;
  useDefaultQuery?: boolean;
}

type IssueAlert = {
  alertType: string;
  assetId: string;
  geofenceType: string;
  geofenceName: string;
  dwellTime: string;
  geofenceStatus: string;
  cargoStatus: string;
  alertSentDate: string;
};

export const ModalTemplates: React.FC<ModalTemplatesProps> = ({
  isOpen,
  onClose,
  alertNotificationId,
  useDefaultQuery = true,
}) => {
  const cellStyle = {
    borderBottom: "none",
  };

  const { data: alertNotification, isLoading: isAlertLoading } =
    useGetAlertNotificationQuery(
      {
        // for test "65a12713dfe969f188584114"
        id: alertNotificationId,
      },
      {
        enabled: isOpen && useDefaultQuery,
      }
    );

  const { data: alertByIdNotification, isLoading: isAlertByIdLoading } =
    useGetAlertNotificationByIdQuery(
      {
        id: alertNotificationId,
      },
      {
        enabled: isOpen && !useDefaultQuery,
      }
    );

  let alertNotificationData;
  const isLoading = useDefaultQuery ? isAlertLoading : isAlertByIdLoading;

  if (useDefaultQuery) {
    alertNotificationData = alertNotification?.getAlertNotificationV2;
  } else {
    alertNotificationData = alertByIdNotification?.getAlertNotificationByIdV2;
  }

  let issueData: IssueAlert | undefined;

  if (
    alertNotificationData &&
    alertNotificationData.issues &&
    alertNotificationData.issues.length > 0
  ) {
    const issueString = alertNotificationData.issues[0];
    try {
      issueData = JSON.parse(issueString);
    } catch (error) {
      console.error("JSON Error:", error);
    }
  }

  const timezoneDate = useDateInPreferredTimezone(
    alertNotificationData?.assetInfo?.lastReportedDate
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <Box
        sx={{
          backgroundColor: "var(--grayscale-feather-gray)",
          position: "relative",
          padding: "96px 96px 62px 96px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="h-10 w-10"
          data-testid="leave-alert-modal-close-icon-btn"
          sx={{
            color: "var(--black)",
            position: "absolute",
            right: 30,
            top: 30,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Box
            className="px-4 pt-4 md:px-8 md:pt-4 lg:px-16 lg:pt-4"
            sx={{
              backgroundColor: "var(--white)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "81px 0 94px",
              }}
            >
              <img
                alt="Connect1 Logo"
                src="https://connect1-email-assets-bucket.s3.us-west-2.amazonaws.com/assets/connect1-logo_288x59.png"
                style={{
                  border: 0,
                  display: "block",
                  outline: "none",
                  textDecoration: "none",
                  height: "auto",
                  width: "288px",
                  fontSize: "13px",
                }}
              />
            </Box>
            {isLoading || alertNotificationData === null ? (
              <div
                data-testid="circular-progress"
                className="w-full h-16 my-5 justify-center flex"
              >
                {alertNotificationData === null ? (
                  <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                    There is no sent notification
                  </p>
                ) : (
                  <CircularProgress size={50} />
                )}
              </div>
            ) : (
              <>
                <Box>
                  <p className="text-black">Hi,</p>
                  <p className="text-black" style={{ margin: "24px 0" }}>
                    {alertNotificationData?.alertMessage ?? ""}
                  </p>
                  <p className="text-black" style={{ margin: "24px 0" }}>
                    {alertNotificationData?.message ?? ""}
                  </p>
                </Box>
                <Box>
                  <TableContainer>
                    <Table className="overflow-x-auto">
                      <TableBody>
                        <TableRow>
                          {issueData?.alertType && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Alert Type
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.alertType}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.assetId && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Asset Id
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.assetId}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.geofenceType && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Geofence Type
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.geofenceType}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.geofenceName && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Geofence Name
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.geofenceName}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.cargoStatus && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Cargo Status
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.cargoStatus}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.dwellTime && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Total Dwell
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.dwellTime}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.geofenceStatus && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Status
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.geofenceStatus}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                          {issueData?.alertSentDate && (
                            <TableCell
                              style={cellStyle}
                              className="flex items-center"
                            >
                              <p className="font-bold px-2 py-2 pl-4 text-black whitespace-nowrap">
                                Alert Sent
                              </p>
                              <Box
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <p className="whitespace-no-wrap overflow-hidden truncate mb-6 py-3 px-4 text-black">
                                  {issueData?.alertSentDate}
                                </p>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Button
                  className="w-full"
                  text="View Alert"
                  size="medium"
                  variant="default"
                  disabled={false}
                  theme="blue"
                  type="button"
                  onClick={onClose}
                  sx={{
                    "&.MuiButton-root": {
                      width: "136px",
                      marginTop: "0",

                      "@media (max-width: 767px)": {
                        marginTop: "34px",
                      },
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    paddingBottom: "98px",
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: "51px",
                      flexBasis: "calc(50% - 100px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <>
                      <div data-testid="geofence-name" className="text-black">
                        <strong>Geofence Name: </strong>
                        <span>
                          {alertNotificationData?.assetInfo?.geofenceName ??
                            "-"}
                        </span>
                      </div>
                      <div data-testid="category" className="text-black">
                        <strong>Geofence Type: </strong>
                        {alertNotificationData?.assetInfo?.geofenceCategory ??
                          "-"}
                      </div>
                      <div data-testid="entered" className="text-black">
                        <strong>Entered: </strong>
                        {alertNotificationData?.assetInfo?.enteredDate ?? "-"}
                      </div>
                      <div data-testid="left" className="text-black">
                        <strong>Left: </strong>
                        {alertNotificationData?.assetInfo?.exitedDate ?? "-"}
                      </div>
                    </>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: "51px",
                      flexBasis: "calc(50% - 100px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <>
                      <div data-testid="primary-voltage" className="text-black">
                        <strong>Primary Voltage: </strong>
                        {alertNotificationData?.assetInfo?.voltageInfo
                          ?.primaryVoltage ?? "-"}
                      </div>
                      <div
                        data-testid="secondary-voltage"
                        className="text-black"
                      >
                        <strong>Secondary Voltage: </strong>
                        {alertNotificationData?.assetInfo?.voltageInfo
                          ?.secondaryVoltage ?? "-"}
                      </div>
                      <div data-testid="battery-voltage" className="text-black">
                        <strong>Battery Voltage: </strong>
                        {alertNotificationData?.assetInfo?.voltageInfo
                          ?.batteryVoltage ?? "-"}
                      </div>
                    </>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: "51px",
                      flexBasis: "calc(50% - 100px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <>
                      <div data-testid="address" className="text-black">
                        <strong>Address: </strong>
                        {alertNotificationData?.assetInfo?.address ?? "-"}
                      </div>
                      <div data-testid="last-reported" className="text-black">
                        <strong>Last Reported: </strong>
                        {timezoneDate ?? "-"}
                      </div>
                    </>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: "51px",
                      flexBasis: "calc(50% - 100px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <>
                      <div data-testid="region" className="text-black">
                        <strong>Region: </strong>
                        {alertNotificationData?.assetInfo?.region ?? "-"}
                      </div>
                      <div data-testid="zone" className="text-black">
                        <strong>Zone: </strong>
                        {alertNotificationData?.assetInfo?.zone ?? "-"}
                      </div>
                    </>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: "51px",
                      flexBasis: "calc(50% - 100px)",
                      boxSizing: "border-box",
                    }}
                  >
                    <>
                      <div data-testid="speed" className="text-black">
                        <strong>Speed: </strong>
                        {alertNotificationData?.assetInfo?.speed ?? "-"}
                      </div>
                      <div data-testid="total-dwell" className="text-black">
                        <strong>Total Dwell: </strong>
                        {alertNotificationData?.assetInfo?.totalDwell ?? "-"}
                      </div>
                    </>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "65px 0 60px",
            }}
          >
            <img
              alt="PCT Logo"
              src="https://connect1-email-assets-bucket.s3.us-west-2.amazonaws.com/assets/pct-logo_241x151.png"
              style={{
                border: 0,
                display: "block",
                outline: "none",
                textDecoration: "none",
                height: "auto",
                width: "120px",
                fontSize: "13px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <Box className="text-light-charcoal text-lg leading-6 pb-8">
              Phillips Connect, 5231 California Ave Suite 110,
              <br />
              Irvine, CA 92617, United States
            </Box>
            <Box className="text-light-charcoal text-lg leading-6 pb-8">
              Connect with us at
              <br />
              Customer Support 1-833-213-5839
              <br />
              phillips-connect.com
            </Box>
            <Box className="text-light-charcoal text-light-charcoal text-lg leading-6 pb-8">
              You can Unsubscribe from the system list or if you
              <br />
              don&#x27;t have access you can ask your admin.
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
