import { memo, useMemo, useState, FC, useCallback } from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import {
  UserRole,
  TableViewType,
  TableDomain,
  FileFormat,
  DevicesTableData,
} from "../../graphql/operations";
import Spinner from "../../shared/components/Spinner";
import {
  ServerSideExport,
  ServerSideExportFormat,
} from "../../shared/components/Table";
import { BackEndProcessingTable } from "../../shared/components/Table/BackEndProcessingTable";
import TransferDialog from "../../shared/components/TableActionsMenu/TableActionsMenu";
import { TypeTransfer } from "../../shared/components/TableActionsMenu/helpers";
import { usePreferredTimezone } from "../../shared/hooks/usePreferredTimezone";
import { useTableDataExporter } from "../../shared/hooks/useTableDataExporter/useTableDataExporter";
import { NO_SIDE_PANEL_TABLE } from "../AdminPanel/tabs/constants";
import { getDeviceColumns, columnVisibilityModel } from "./deviceUtils";

const DeviceManagementTable: FC<any> = (props) => {
  const {
    devices,
    isSuccess,
    isLoading,
    apiRef,
    isDataRefetching,
    updateQueryInput,
    queryData,
    queryInput,
    refetchDeviceData,
    initialSearch,
  } = props;

  const {
    state: { appConfig },
  } = useAppContext();
  const { userInfo, userRolePermissions } = useAuthContext();

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [selectedRow, setSelectedRows] = useState<any[]>([]);
  const timezone = usePreferredTimezone();
  const isAdmin = useMemo(
    () =>
      userInfo?.groups.some(
        (group: string) => group.toLowerCase() === UserRole.Superadmin
      ),
    [userInfo]
  );
  const columns = useMemo(() => getDeviceColumns(timezone), [timezone]);
  const [fileFormat, setFileFormat] = useState<FileFormat>(FileFormat.Excel);
  const [isExporting, setIsExporting] = useState(false);

  if (!isAdmin) {
    const firmwareVersionIndex = columns.findIndex(
      (a) => a.field === "firmwareVersion"
    );
    const configIndex = columns.findIndex((a) => a.field === "config");
    if (firmwareVersionIndex !== -1) columns.splice(firmwareVersionIndex, 1);
    if (configIndex !== -1) columns.splice(configIndex, 1);
  }

  const canCheckTheRows = userRolePermissions?.device?.check || false;

  useTableDataExporter<DevicesTableData>({
    queryInput,
    apiRef,
    columns,
    domain: TableDomain.Devices,
    fileFormat,
    isExporting,
    setExporting: setIsExporting,
    totalCount: queryData?.pagination?.total,
  });

  const handleExport = useCallback((format: ServerSideExportFormat) => {
    if (format === ServerSideExport.EMAIL) {
      setIsSendingEmail(true);
    } else {
      setFileFormat(format);
      setIsExporting(true);
    }
  }, []);

  return (
    <Box className="h-full w-full px-4 pb-4 pt-8 md:px-6 md:pt-0 md:pb-3 lg:px-16">
      <BackEndProcessingTable
        {...props}
        isSendingEmail={isSendingEmail}
        setSendingEmail={setIsSendingEmail}
        domain={TableDomain.Devices}
        tableName={"devices-list"}
        tableType={TableViewType.Devices}
        mobileBreakpoint={NO_SIDE_PANEL_TABLE}
        columns={columns}
        data={{
          rows: queryData?.data ?? [],
          pagination: queryData?.pagination,
        }}
        updateQueryInput={updateQueryInput}
        sorting={queryInput.sorting ?? undefined}
        isLoading={isLoading}
        isSuccess={isSuccess}
        apiRef={apiRef}
        columnVisibilityModel={columnVisibilityModel}
        searchMinLength={appConfig.searchMinLength}
        checkboxSelection={canCheckTheRows}
        onExport={handleExport}
        actions={
          canCheckTheRows ? (
            <TransferDialog
              refetchDeviceData={refetchDeviceData}
              selectedRow={selectedRow}
              typeTransfer={TypeTransfer.Devices}
              apiRef={apiRef}
              devices={devices}
            />
          ) : null
        }
        updateSelectedRowsHandler={setSelectedRows}
        disableSelectionOnClick={true}
        isDataRefetching={isDataRefetching}
        initialSearch={initialSearch}
        totalCount={queryData?.pagination?.total}
      />
      <Spinner counter={Number(isSendingEmail)} />
    </Box>
  );
};

DeviceManagementTable.propTypes = {
  onRowClick: PropTypes.func,
  devices: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  initialSearch: PropTypes.string,
};

DeviceManagementTable.defaultProps = {
  onRowClick: () => {},
  devices: null,
  isLoading: false,
  isSuccess: false,
  initialSearch: "",
};

export default memo(DeviceManagementTable);
