import {
  AggregatedEventHistory,
  HistoricalEventHistory,
} from "../../../../../../graphql/operations";
import { indexOfMax } from "../CargoStatus/utils";

export const BAR_HEIGHT = 24;

export type DoorData = {
  eventHistoryData?: Partial<Pick<
    AggregatedEventHistory,
    "door" | "date"
  > | null>;
} | null;
export type AirBagData = {
  eventHistoryData?: Pick<AggregatedEventHistory, "airbag" | "date">;
};
export type MediaData = {
  eventHistoryData?: Pick<HistoricalEventHistory, "mediaActivity">;
};

export function calculateDataStatusLast30Days(data: Array<DoorData>) {
  let closedDoorsDays = 0;
  let openDoorsDays = 0;

  data.forEach((aggreagatedData: any) => {
    let doorClosedEvents =
      aggreagatedData.eventHistoryData.door?.doorSummary.closed;
    if (doorClosedEvents > 0) {
      openDoorsDays += 1;
    } else {
      closedDoorsDays += 1;
    }
  });

  return {
    closedDoorsDays,
    openDoorsDays,
  };
}

export function calculateDoorStatusInLocationsLast30Days(
  data: Array<DoorData>
) {
  let closedDoorDaysInsideGeofence = 0;
  let closedDoorDaysOutsideGeofence = 0;
  let openDoorDaysInsideGeofence = 0;
  let openDoorDaysOutsideGeofence = 0;

  data.forEach((aggreagatedData: any) => {
    const open =
      aggreagatedData.eventHistoryData.door?.doorSummary.openCountGeofence;
    const openCountOutGeofence =
      aggreagatedData.eventHistoryData.door?.doorSummary.openCountOutGeofence;

    const closedCountGeofence =
      aggreagatedData.eventHistoryData.door?.doorSummary.closedCountGeofence;
    const closedCountOutGeofence =
      aggreagatedData.eventHistoryData.door?.doorSummary.closedCountOutGeofence;

    let maxValueIndex = indexOfMax([
      open,
      openCountOutGeofence,
      closedCountGeofence,
      closedCountOutGeofence,
    ]);

    switch (maxValueIndex) {
      case 0:
        openDoorDaysInsideGeofence += 1;
        break;
      case 1:
        openDoorDaysOutsideGeofence += 1;
        break;
      case 2:
        closedDoorDaysInsideGeofence += 1;
        break;
      case 3:
        closedDoorDaysOutsideGeofence += 1;
        break;
      default:
        console.log("Error calculating door state for current day!");
        break;
    }
  });

  return {
    closedDoorDaysInsideGeofence,
    closedDoorDaysOutsideGeofence,
    openDoorDaysInsideGeofence,
    openDoorDaysOutsideGeofence,
  };
}
