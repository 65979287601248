import { FC, MutableRefObject } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useContainerDimensions } from "../../../../../../shared/hooks/useContainerDimensions";
import DoorHeader from "./DoorHeader";
import { BAR_HEIGHT, DoorData, calculateDataStatusLast30Days } from "./utils";

export interface DoorStatusProps {
  data: Array<DoorData>;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
}

const DoorStatus: FC<DoorStatusProps> = ({ data, parentRef }) => {
  const { openDoorsDays, closedDoorsDays } =
    calculateDataStatusLast30Days(data);

  const { width } = useContainerDimensions(parentRef);

  // layout grid parent dimensions
  const gridItemPaddings = 32;
  const gridItemGapDistance = 16;

  // card layout dimensions
  const doorLabelWidth = 55;
  const doorBasedMargins = 18;
  const doorBasedPaddings = 18 + gridItemPaddings + gridItemGapDistance;

  // calculate percentage based on 30 days
  const closedDaysInPercentage = (closedDoorsDays / 30) * 100;
  const openDaysInPercentage = (openDoorsDays / 30) * 100;

  // calculate percentage based on bars possible width
  const doorBarMaxWidth =
    width - (doorLabelWidth + doorBasedPaddings + doorBasedMargins);
  const closedBarPercentage = (closedDaysInPercentage / 100) * doorBarMaxWidth;
  const openBarPercentage = (openDaysInPercentage / 100) * doorBarMaxWidth;

  return (
    <Grid
      spacing={0}
      justify-content="flex-end"
      container
      className="text-sm w-full p-4"
      data-testid="door-first-card"
    >
      {/* TODO: currently showing only one card. Update the layout when we need ot show 2 cards */}
      <DoorHeader title={"Door Sensor"} />

      <Typography
        variant="caption"
        className="!text-lg !font-semibold inline-block pb-4"
        data-testid="door-first-card-title"
      >
        Door Status Last 30 Days
      </Typography>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Open
        </Typography>

        <Box
          className="!mr-2 rounded inline-block align-middle text-center"
          data-testid="open-door-days"
          style={{
            color: `var(--${openDoorsDays ? "white" : "primary"})`,
            background: "var(--indigo)",
            width: `${openBarPercentage}px`,
            height: `${BAR_HEIGHT}px`,
          }}
        >
          {openDoorsDays}
        </Box>
      </Box>

      <Box className="!mb-2 justify w-full" justifyContent="flex-end">
        <Typography
          width="55px"
          className="!mr-2 !text-sm inline-block align-middle text-card-sensors-text"
        >
          Closed
        </Typography>

        <Box
          className="!mr-2 rounded inline-block align-middle text-center"
          data-testid="closed-door-days"
          style={{
            color: `var(--${closedDoorsDays ? "white" : "primary"})`,
            background: "var(--indigo)",
            width: `${closedBarPercentage}px`,
            height: `${BAR_HEIGHT}px`,
          }}
        >
          {closedDoorsDays}
        </Box>
      </Box>
    </Grid>
  );
};

export default DoorStatus;
