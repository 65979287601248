import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { capitalize } from "lodash";
import { AssetActivityEvent } from "../../../../../graphql/operations";
import { createField } from "./../../../../../shared/helpers/utils";
import { columnTimezoneDateTimeFormatParams } from "./../../../../../utils/date";

export const columnVisibilityModel = {
  postedAt: true,
  eventType: true,
  description: true,
  userFullName: true,
};

export const getColumns = (
  timezone: string
): GridColDef<AssetActivityEvent>[] =>
  [
    {
      field: "postedAt",
      headerName: "Event Date/Time",
      flex: 1,
      minWidth: 200,
      ...columnTimezoneDateTimeFormatParams(timezone),
    },
    {
      field: "eventType",
      headerName: "Event Name",
      flex: 1,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => capitalize(params.value),
    },
    {
      ...createField("description", "Event Description", {
        flex: 1,
        minWidth: 200,
        filterable: false,
      }),
    },
    {
      field: "userFullName",
      headerName: "Performed by",
      flex: 1,
      minWidth: 300,
    },
  ].map((column) => ({
    ...column,
    sortable: column.field === "description" ? false : true,
  }));

export const SEARCH_KEYS = ["postedAt", "eventType", "userFullName"];
export const ACTIVITY_LOG_DEFAULT_SORT_COLUMN = "postedAt";
