import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Box, ThemeProvider } from "@mui/material";
import { omitBy } from "lodash";
import { useAuthContext } from "../../../../../context/AuthContext";
import {
  Asset,
  DeviceData,
  VinCheckData,
  useFindDevicesQuery,
} from "../../../../../graphql/operations";
import DrawerActions from "../../../../../shared/components/Drawer/DrawerActions";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { AutocompleteOption } from "../../../../../types";
import { FindDeviceModal } from "./FindDeviceModal";
import { numberInputChangeHandler } from "./assetFormUtils";
import { ABSDetails } from "./sections/ABSDetails";
import { ATISDetails } from "./sections/ATISDetails";
import { AssetDetails } from "./sections/AssetDetails";
import { AssetInformation } from "./sections/AssetInformation";
import { Liftgate } from "./sections/Liftgate";
import { OtherDetails } from "./sections/OtherDetails";
import { RailCompliance } from "./sections/RailCompliance";
import { SectionHeader } from "./sections/SectionHeader";
import { Summary } from "./sections/Summary";
import { TemperatureControlledUnit } from "./sections/TemperatureControlledUnit";

const sectionContainerStyles = { marginBottom: { md: "24px" } };
const sectionHeaderContainerStyles = { paddingY: "16px" };
interface AssetFormProps
  extends Omit<HTMLAttributes<HTMLFormElement>, "onChange" | "onSubmit"> {
  onSubmit?: (data: FieldValues) => any;
  asset?: Partial<Asset>;
  onChange?: (asset?: Partial<Asset> | undefined) => any;
  form: UseFormReturn<Partial<Asset>>;
  compact?: boolean;
  cancelable?: boolean;
  showDetailsHeader?: boolean;
  disableAllFields?: boolean;
  onCancel?: () => void;
}
export interface Breakpoints {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
export const AssetForm = ({
  onSubmit,
  asset = {},
  onChange,
  form,
  compact = false,
  showDetailsHeader = false,
  cancelable = true,
  onCancel,
  disableAllFields = false,
  ...props
}: AssetFormProps) => {
  const formTheme = useFormTheme();
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { userRolePermissions } = useAuthContext();
  const customer_orgs_id = form.watch("customer_orgs_id");
  const isFindAvailableDevicesQueryEnabled =
    userRolePermissions.device.view && !!customer_orgs_id;
  const findAvailableDevicesQuery = useFindDevicesQuery(
    { input: { orgId: customer_orgs_id, isLinked: false } },
    { enabled: isFindAvailableDevicesQueryEnabled }
  );
  const findAssetDeviceQuery = useFindDevicesQuery(
    { input: { imei: asset?.imei } },
    { enabled: userRolePermissions.device.view && !!asset?.imei }
  );

  const isLoadingDevices =
    findAvailableDevicesQuery.isFetching || findAssetDeviceQuery.isFetching;

  const availableDevices = useMemo(() => {
    const devices =
      (findAvailableDevicesQuery.data?.findDevices as DeviceData[]) || [];
    const result = [...devices];
    const assetDevice = findAssetDeviceQuery.data?.findDevices?.[0];
    if (assetDevice) {
      result.push(assetDevice);
    }
    return result;
  }, [
    findAvailableDevicesQuery.data?.findDevices,
    findAssetDeviceQuery.data?.findDevices,
  ]);

  useEffect(() => {
    const values = omitBy(form.getValues(), (value) => value === "");

    if (form.formState.isDirty) {
      onChange && onChange(values);
    }
  }, [form, onChange, form.formState.isDirty]);

  const breakpoints: Breakpoints = compact
    ? { xs: 12 }
    : { xs: 12, md: 6, lg: 3, xl: 3 };

  const findDeviceById = useCallback(
    (deviceId: string) => {
      return availableDevices.find((device) => device.imei === deviceId);
    },
    [availableDevices]
  );

  const onChangeDevice = useCallback(
    (autoCompleatValue: AutocompleteOption) => {
      if (autoCompleatValue?.id) {
        const device = findDeviceById(autoCompleatValue.id);
        setSelectedAssetId(device?.assets_id ?? null);
        setShowModal(true);
      }
    },
    [findDeviceById]
  );

  const onVinEntered = (data: VinCheckData) => {
    form.setValue("manufacturer", data.manufacturerName ?? "");
    form.setValue("assetModel", data.model ?? "");
    form.setValue(
      "metadata.year",
      data.modelYear ? Number(data.modelYear) : undefined
    );
  };

  const onModalClose = () => {
    form.resetField("imei");
    setShowModal(false);
  };

  const onAssociateModalBtn = () => {
    setShowModal(false);
  };

  const renderSeparatorInDrawer = useCallback(
    () => !showDetailsHeader && <Box sx={{ marginBottom: "24px" }} />,
    [showDetailsHeader]
  );

  return (
    <ThemeProvider theme={formTheme}>
      <form
        {...props}
        autoComplete="off"
        onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
        // add padding top when we're in asset drawer
        className={!showDetailsHeader ? "pt-10" : ""}
        id="asset-info-form"
      >
        <Box sx={sectionContainerStyles}>
          <AssetInformation
            availableDevices={availableDevices}
            isLoadingDevices={isLoadingDevices}
            onChangeDevice={onChangeDevice}
            onVinEntered={onVinEntered}
            form={form}
            showDetailsHeader={showDetailsHeader}
            asset={asset}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
          />

          {renderSeparatorInDrawer()}

          <AssetDetails
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />

          {renderSeparatorInDrawer()}

          <Summary
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />
        </Box>

        <Box sx={sectionContainerStyles}>
          {showDetailsHeader && (
            <Box sx={sectionHeaderContainerStyles}>
              <SectionHeader
                title={"Liftgate/TCU/Rail Compliance Details"}
                header
                showDetailsHeader={showDetailsHeader}
              />
            </Box>
          )}

          {renderSeparatorInDrawer()}

          <Liftgate
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />

          {renderSeparatorInDrawer()}

          <TemperatureControlledUnit
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />

          {renderSeparatorInDrawer()}

          <RailCompliance
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
          />
        </Box>

        <Box sx={sectionContainerStyles}>
          {showDetailsHeader && (
            <Box sx={sectionHeaderContainerStyles}>
              <SectionHeader
                title={"Spec/Component Details"}
                header
                showDetailsHeader={showDetailsHeader}
              />
            </Box>
          )}

          {renderSeparatorInDrawer()}

          <ABSDetails
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />

          {renderSeparatorInDrawer()}

          <ATISDetails
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
          />

          {renderSeparatorInDrawer()}

          <OtherDetails
            form={form}
            showDetailsHeader={showDetailsHeader}
            breakpoints={breakpoints}
            disableAllFields={disableAllFields}
            numberChangeHandler={numberInputChangeHandler}
          />
        </Box>

        {onCancel && (
          <div className="pt-11 bg-background">
            <DrawerActions
              cancelBtnTestId="asset-form-cancel-btn"
              classNames="drawerActions"
              disabled={!cancelable}
              onCancel={onCancel}
            />
          </div>
        )}

        <FindDeviceModal
          selectedAssetId={selectedAssetId}
          isModalVisible={showModal}
          onModalCloseHandler={onModalClose}
          onModalAssociateHandler={onAssociateModalBtn}
        />
      </form>
    </ThemeProvider>
  );
};
